
import {Injectable, EventEmitter} from '@angular/core';

import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ManageInternalpageService {

  constructor(private httpclient:HttpClient) { }

  getHtml(url){
    console.log('url', url)
      const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
      const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
      var body=JSON.stringify({"token":token, "templateId":"5bb22a6ca376a50030347a0f"});
      return this.httpclient.get('/api2/templates/html/'+url,  {headers: headers});
  }

}
