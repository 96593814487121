import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, Input, Output, AfterViewInit } from '@angular/core';
import { ChatService } from './chat.service';
import * as io from "socket.io-client";
import { ToastService } from 'ng-uikit-pro-standard';
import { EventEmitter } from '@angular/core';
import { LogoutComponent } from '../auth/logout/logout.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewChecked {

  @ViewChild('scrollMe', {static:true}) private myScrollContainer: ElementRef;
  @Input() myRoom: String
  @Input() myName: String
  @Output() logOut=new EventEmitter();

  chats: any=[];
  joinned: boolean = false;
  status: String = "online";
  newUser = { nickname: '', room: '' };
  msgData = { room: '', nickname: '', message: '', status: this.status, action: '', avatar: '', thread: '', subject:'' };
  ioToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  socket = io('http://localhost:3283',{query: {token: this.ioToken}});
  manageSocket = io('http://localhost:3284',{query: {token: this.ioToken}});
  userRecord = JSON.parse(localStorage.getItem('userRecord'));
  avatar="http://placehold.it/50/55C1E7/fff&text=";
  chatQueue=JSON.parse(localStorage.getItem("_12ma_chat_queue"))

  constructor(private chatService: ChatService, private _service: ToastService) {
    this.status="online";
  }

  ngDestroy(){
    console.log('destroyed')
  }

  ngAfterViewInit(){
    this.chatQueue = localStorage.getItem('_12ma_chat_queue');
    console.log('chatQueue',this.chatQueue);
  }

  ngOnInit() {
   
    if (this.userRecord.avatar){
      this.avatar=this.userRecord.avatar;
    } else {
      try {
        var res = this.myName.split(" ");
        var first=res[0].charAt(0);
        var second=res[1].charAt(0);
        this.avatar="http://placehold.it/50/55C1E7/fff&text="+first+second;
      } catch(e){
        this.avatar="http://placehold.it/50/55C1E7/fff&text=ok"
      }
    }
    
    this.joinRoom(this.myRoom, this.myName);
    var user = JSON.parse(localStorage.getItem("_12ma_chat_user"));
  
   // this.socket.on('chatstatus', function (data) {
   //   console.log('manage data in secure', data)
    //}.bind(this));

    this.socket.on('new-message', function (data) {
      var user = JSON.parse(localStorage.getItem("_12ma_chat_user"));
      
      if(data['message'].room === user.room) {
        this.chats.push(data['message']);
        this.msgData = { room: user.room, nickname: user.nickname, message: '', status: data['message'].status, action:'', avatar: this.avatar, thread:this.chatQueue.thread, subject: this.chatQueue.subject  }
        
        if (data['message'].status=="offline"){
          var date = new Date();
          var user = JSON.parse(localStorage.getItem("_12ma_chat_user"));
          this.status="offline";
          this.joinned = false;
          this.logOut.emit('true');
          this.socket.close();
      
        }
        this.scrollToBottom();
      }
    }.bind(this));
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }
  }

  getChatByRoom(room) {
    this.chatService.getChatByRoom(room)
    .subscribe(
      data => {
        this.chats = data;
      },
      error => this._service.error(error.error['message'], error['title']),
    ); 
  }

  joinRoom(room,name) {
    
    this.status = "online";
    this.newUser.room=room;
    this.newUser.nickname=name;
    this.msgData = { room: this.newUser.room, nickname: this.newUser.nickname, message: 'Has Joined the Room', status: this.status,action:'joined',avatar: this.avatar, thread:this.chatQueue.thread, subject: this.chatQueue.subject  };
    console.log(this.msgData);
    var date = new Date();
    localStorage.setItem("_12ma_chat_user", JSON.stringify(this.newUser));
   /* this.chatService.saveChat(this.msgData).then((result) => {
      console.log(result);
      this.socket.emit('save-message',  this.msgData);
    }, (err) => {
      console.log(err);
    }); */


    this.chatService.saveChat(this.msgData)
    .subscribe(
      data => {
        this.socket.emit('save-message',  this.msgData);
      },
      error => this._service.error(error.error['message'], error['title']),
    ); 
    
  }

  sendMessage() {
    this.chatQueue=JSON.parse(localStorage.getItem("_12ma_chat_queue"))
    this.msgData.thread=this.chatQueue.thread
    this.msgData.subject=this.chatQueue.subject;
    console.log("send:",this.msgData);
    /*this.chatService.saveChat(this.msgData).then((result) => {
      console.log(result);
      this.socket.emit('save-message', result);
    }, (err) => {
      console.log(err);
    }); */
    this.chatService.saveChat(this.msgData)
    .subscribe(
      data => {
        console.log('mydata',data)
        this.socket.emit('save-message', data)
      },
      error => this._service.error(error.error['message'], error['title']),
    ); 
  }

  logout() {
    var date = new Date();
    var user = JSON.parse(localStorage.getItem("_12ma_chat_user"));
    this.chatQueue=JSON.parse(localStorage.getItem("_12ma_chat_queue"))
    this.status="offline";
    this.joinned = false;
    this.msgData = { room: this.newUser.room, nickname: this.newUser.nickname, message: 'Has Left the Room', status: this.status, action:'logout',avatar: this.avatar, thread:this.chatQueue.thread, subject: this.chatQueue.subject };
    console.log(this.msgData);

    this.chatService.saveChat(this.msgData)
    .subscribe(
      data => {
        console.log('mydata1',data)
        this.socket.emit('save-message', this.msgData)
      },
      error => this._service.error(error.error['message'], error['title']),
    ); 
    
  }

}