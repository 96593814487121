import { Component, OnInit, ElementRef,  OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
  private future: Date;
  private futureString: string;
  private diff: number;
  private $counter: Observable<number>;
  private subscription: Subscription;
  public message: string;
  constructor(elm: ElementRef) {
    this.futureString = elm.nativeElement.getAttribute('inputDate');
  }

  dhms(t) {
    var days, hours, minutes, seconds;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    if (minutes < 10 ){
      minutes="0"+minutes;
    } 

    if (seconds < 10){
      seconds="0"+seconds;
    }
    
    return [
        days + 'd',
        hours + ':',
        minutes + ':',
        seconds + ''
    ].join(' ');
}


  ngOnInit() {
    console.log(this.futureString);
    this.future = new Date(this.futureString);
    console.log(this.future);
    /*this.$counter = Observable.interval(1000).map((x) => {
        this.diff = Math.floor((this.future.getTime() - new Date().getTime()) / 1000);
      //  console.log(this.diff);
      // console.log(this.diff);
        return x;
    });*/

    this.subscription = this.$counter.subscribe((x) => this.message = this.dhms(this.diff));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
