import { Component, OnInit } from '@angular/core';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import {Systempreference} from "../shared/systempreference/systempreference";
import { ToastService } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-affiliatehome',
  templateUrl: './affiliatehome.component.html',
  styleUrls: ['./affiliatehome.component.css']
})
export class AffiliatehomeComponent implements OnInit {

  username;
  webinarid;
  webinarlink;
  mpd_link;
  constructor(private systempreferenceService:SystempreferenceService, private toastrService: ToastService) { 

    const systempreference1 = new Systempreference(null, "webinar_link", null, null);
      this.systempreferenceService.getKey(systempreference1)
        .subscribe(
          data => {
            this.webinarlink=data['obj'].value;
            const systempreference = new Systempreference(null, "webinarid", null, null);
              this.systempreferenceService.getKey(systempreference)
                .subscribe(
                  webinarID => {
                    console.log(webinarID['obj'].value);
                    this.webinarid=webinarID['obj'].value;
                    const systempreference2 = new Systempreference(null, "mpd_link", null, null);
                    this.systempreferenceService.getKey(systempreference2)
                      .subscribe(
                        mpdLink => {
                          console.log(mpdLink['obj'].value);
                          this.mpd_link=mpdLink['obj'].value;
                          this.webinarlink=this.webinarlink.replace(/\[WEBINARID\]/g, this.webinarid );
                          this.webinarlink=this.webinarlink.replace(/\[USERNAME\]/g, this.username );
                          this.webinarlink=this.webinarlink.replace(/\[MPD_LINK\]/g, this.mpd_link );
                          this.webinarlink=this.webinarlink.replace(/\[PRODUCT\]/g, '200' );
                        },
                        error => {
                          this.toastrService.error(error.error['message'], error['title'], {opacity:1});
                        }
                      );
                  },
                  error => {
                    this.toastrService.error(error.error['message'], error['title'], {opacity:1});
                  }
                );
            
          },
          error => {
            this.toastrService.error(error.error['message'], error['title'], {opacity:1});
          }
        );

    

      
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

}
