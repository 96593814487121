<p align="center" class="ral-48blue">Group Coaching Calls</p><br>
<p class="normal">
    Approximately every two weeks or so, we give our members the opportunity to train with us LIVE! This is a chance for you to get the training and motivation you need to accelerate your success. If you miss a call, no worries we’ve got them archived for you below (but do your best to join us live).
</p>
<p class="normal">
    These calls are mostly group Q & A. So if you’ve got a question you want answered live then this is the 2nd best place to have it answered (the 1st best place is 1-on-1 coaching) 
</p>
<p class="t2" style="text-align:center">
    Our Next Group Training Call Is on 
</p>
<p style="text-align:center;" class="t2 bold">
        {{webinar_time}}
        </p>
<p style="text-align:center">
  <button mat-button class="buy-button" (click)="navigate()" style="font-size:18pt;padding:20px 20px 20px 20px;background-color:#eee;border-radius:10px;" color="primary" type="button">
      CLICK HERE TO RESERVE YOUR SPOT
  </button>
</p>
<br><br>
<div *ngFor="let data of datas">
    <div [innerHTML]="data.body | decodeHtml:''"></div>
</div>
<br><br>

