import {Injectable, EventEmitter} from '@angular/core';
import {Links} from "./links";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class LinksService {
  private hostName = window.location.protocol + '//' + window.location.host;
  links: Links[] = [];
  linksIsEdit = new EventEmitter<Links>();
  constructor(private http: HttpClient) { }

  editLinks(Links: Links) {
    console.log(Links)
    this.linksIsEdit.emit(Links);
  }

  addRecord(Links: Links) {
    const body = JSON.stringify(Links);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/links/addrecord' + token, body, {headers: headers})
    
  }

  deleteRecord(Links: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/links/deleterecord/' + Links._id + token, {headers: headers})
  
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/links/getallrecords'+token,  {headers: headers})
      
  }

  getMyRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/links/getmyrecords'+token,  {headers: headers})
     
  }


  updateRecord(Links: Links){
    const body = JSON.stringify(Links);
    console.log(Links);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/links/updaterecord/' + Links.linkId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/links/getrecord/'+id+token,  {headers: headers})
     
  }

}
