//import component, ElementRef, input and the oninit method from angular core
import {Component, OnInit, ElementRef, Input, ChangeDetectorRef} from '@angular/core';
//import the file-upload plugin
//import the native angular http and respone libraries

//import the do function to be used with the http library.
import "rxjs/add/operator/do";
//import the map function to be used with the http library
import "rxjs/add/operator/map";
import { ToastService } from 'ng-uikit-pro-standard';
import {UploadService} from "./upload.service";
//import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, transition, style, animate } from '@angular/animations'

const URL = '/api/upload/upload';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class UploadComponent implements OnInit {
  url;
  thumbUrl;
  uploaded= true;
  sigingrequest;
  progress = false;
  public file_srcs: string[] = [];
  uploadedImage: File;
  imagePreview: string;

visiblityState = 'hidden';
messageTitle;
messageBody;
icon;
step:String=null;
showMessage(messageTitle:String,messageBody:String,step:String, icon:string, cb) {
    this.visiblityState = 'shown';
    messageTitle=messageTitle;
    this.messageBody=this.messageBody;
    this.icon=icon;
    this.step=step;
    
    setTimeout(() => {
      this.visiblityState = 'hidden';
      cb();
    },5000) 
      
  //  if (this.visiblityState === 'hidden')
  //    this.visiblityState = 'shown'
  //  else
  //    this.visiblityState = 'hidden'
  }

  constructor(public sanitizer: DomSanitizer, private el: ElementRef, private _service: ToastService, private uploadService: UploadService, private changeDetectorRef: ChangeDetectorRef) { }

  // The next two lines are just to show the resize de;bug
  // they can be removed
  public debug_size_before: string[] = [];
  public debug_size_after: string[] = [];

  preUpload(){
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    let thumbEl: HTMLInputElement = this.el.nativeElement.querySelector('#thumb');
   // this.upload(inputEl);
  }

  // This is called when the user selects new files from the upload button
  /*fileChange(){
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    
    this.readFiles(inputEl.files);
  }*/

  /*readFile(file, reader, callback){
    // Set a callback funtion to fire after the file is fully loaded
    reader.onload = () => {
      // callback with the results
      callback(reader.result);
    }

    // Read the file
    
    reader.readAsDataURL(file);
  } */

   /*readFiles(files, index=0){
    // Create the file reader
    let reader = new FileReader();

    // If there is a file
    if (index in files){
      
      // Start reading this file
      this.readFile(files[index], reader, (result) =>{
        // Create an img element and add the image file data to it
        var img = document.createElement("img");
        img.src = result;
        this.url=img.src;
        // Send this img to the resize function (and wait for callback)
        this.resize(img, 250, 250, (resized_jpeg, before, after)=>{
          // For debugging (size in bytes before and after)
          this.debug_size_before.push(before);
          this.debug_size_after.push(after);

          let randomNumber = this.randomNumber();
         // let thumbName = Date.now() + Math.random() + "." + randomNumber + "." + "thumb.jpg";
         // this.uploadService.getSignedRequest(thumbName, "image/jpeg")
        ///    .subscribe(
         //     data => {
          //      this.sigingrequest=data;
          //      this.finalUpload(this.sigingrequest, resized_jpeg)
          //    },
          //    error => this._service.error(error.error['message'], error['title']),
          //  );
          
          let fileName = Date.now() + Math.random() + "." + randomNumber + "." + "image.jpg";
          this.uploadService.getSignedRequest(fileName, "image/jpeg")
            .subscribe(
              data => {
                this.sigingrequest=data;
                this.finalUpload(this.sigingrequest, files[index])
              },
              error => this._service.error(error.error['message'], error['title']),
            );
          // Add the resized jpeg img source to a list for preview
          // This is also the file you want to upload. (either as a
          // base64 string or img.src = resized_jpeg if you prefer a file).
          this.file_srcs.push(resized_jpeg);

          // Read the next file;
          //this.readFiles(files, index+1);
        });
      });
    }else{
      // When all files are done This forces a change detection
      this.changeDetectorRef.detectChanges();
      this.uploaded=false;
    }
  } */
  
  /*uploadSingle() {
    //locate the file element meant for the file upload.
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    //get the total amount of files attached to the file input.
    let fileCount: number = inputEl.files.length;
    //create a new fromdata instance
    let formData = new FormData();

    console.log(inputEl.files.item(0));
    //check if the filecount is greater than zero, to be sure a file was selected.
    if (fileCount > 0) { // a file was selected
      //append the key name 'photo' with the first file in the element
      formData.append('photo', inputEl.files.item(0));
      //call the angular http method
      console.log(formData);
      this.http
      //post the form data to the url defined above and map the response. Then subscribe //to initiate the post. if you don't subscribe, angular wont post.
        .post(URL, formData).map((res:Response) => res.json()).subscribe(

        //map the success function and alert the response
        (success) => {
          console.log(success);
          this._service.success(success['title'], success['message']);
          this.uploaded=true;
        },
        (error) => {this._service.error(error['title'], error['message'])}
      )
    }
  } */

  onImageChange(event) {
    let image = event.target.files[0];
    let file: File = image;
    let formData:FormData = new FormData();
    console.log(file);
    this.uploadedImage = file;
    this.getImagePreview(this.uploadedImage);
    
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      this.uploaded=false;
    };
  }

  upload(){
    this.progress=true;
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    console.log(inputEl);
    var fileType="image/jpeg";
    //var file = inputEl.files.item(0);
    var file = this.uploadedImage;
    console.log(file);
    let fileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
    this.uploadService.getSignedRequest(fileName, fileType)
      .subscribe(
        data => {
          this.sigingrequest=data;
          this.finalUpload(this.sigingrequest, file, fileType)
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  finalUpload(data, file, fileType){
    this.uploadService.upload(data['signedRequest'], file, fileType)
      .subscribe(
        data => {
          console.log(data);
          if (data['status']==200){
            this.progress=false;
            this.uploaded=true;
            this.uploadService.addRecord(data)
              .subscribe(
                data => {
                  console.log(data);
                  this.showMessage(data['title'],data['message'],'success', 'check_circle', function(){})
                }
              );
              
          }
        },
        error => {
          console.log(error)
          this.showMessage(error['title'],error['message'],'warn', 'warning', function(){})
        },
      );
  }

  previewImage(event){
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e:any) => {
        var image = new Image();
        image.src = reader.result as string;
      
        this.url = e.target.result;

        image.onload = function() {
        };

      };

      reader.readAsDataURL(event.target.files[0]);
      this.uploaded=false;

    }
  }




  resize(img, MAX_WIDTH:number, MAX_HEIGHT:number, callback){
    // This will wait until the img is loaded before calling this function
    return img.onload = () => {

      // Get the images current width and height
      var width = img.width;
      var height = img.height;

      // Set the WxH to fit the Max values (but maintain proportions)
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // create a canvas object
      var canvas = document.createElement("canvas");

      // Set the canvas to the new calculated dimensions
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0,  width, height);

      // Get this encoded as a jpeg
      // IMPORTANT: 'jpeg' NOT 'jpg'
      var dataUrl = canvas.toDataURL('image/jpeg');

      // callback with the results
      callback(dataUrl, img.src.length, dataUrl.length);
    };
  }

  getAllRecords(){
    this.uploadService.getAllRecords()
    .subscribe(
      data => {
        console.log(data);
      }
    );
  }


  ngOnInit() {
    this.getAllRecords();
  }

}
