import {Injectable, EventEmitter} from '@angular/core';
import {Types} from "./types";
import {HttpHeaders, HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TypesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  types: Types[] = [];
  typesIsEdit = new EventEmitter<Types>();
  constructor(private http: HttpClient) { }

  editTypes(types: Types) {
    console.log(types)
    this.typesIsEdit.emit(types);
  }

  getTypeByName(model){
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    var body=JSON.stringify(model);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post('/api2/types/gettypebyname', body, {headers: headers});
  }

  
  addRecord(types: Types) {
    const body = JSON.stringify(types);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/types/addrecord' + token, body, {headers: headers})
    
  }

  deleteRecord(types: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/types/deleterecord/' + types._id + token, {headers: headers})
   
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/types/getallrecords'+token,  {headers: headers})
    
  }

  getAllRecordsByRole(role: any) {
    const body = JSON.stringify(role);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/types/getallrecordsbyrole' + token, body, {headers: headers})
    
  }

  getRecordByType(type: any) {
    const body = JSON.stringify(type);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/types/getrecordbytype' + token, body, {headers: headers})
    
  }

  updateRecord(types: Types){
    const body = JSON.stringify(types);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/types/updaterecord/' + types.typesId + token, body, {headers: headers})
    
  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/types/getrecordl/'+id+token,  {headers: headers})
    
  }

}
