<button mat-flat-button color="primary" type="button" (click)="addImage()">Media Manager</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="clear()">Clear</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="undo()">Undo</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="changeColor()">Change Color</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="savePNG()">Download as PNG</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="saveJPG()">Download as JPG</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="saveSVG()">Download as SVG</button>&nbsp;
<button mat-flat-button color="primary" type="button" (click)="save()" *ngIf="showSave">Save</button>&nbsp;
<button mat-flat-button color="primary" type="button" mat-dialog-close>Cancel</button>&nbsp;
<br>
<br>
Draw your Signature in the space below using your mouse. Click Save When Done.

<div class="signature-pad">
  <div class="signature-pad--body">
    <canvas #sPad width="900" height="600" style="touch-action: none;"></canvas>
  </div>
</div>
<br>
<app-notifications></app-notifications>