<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
 
    <h4>Template Id: <span style="font-weight:normal">{{templates?._id}}</span>  Template name: <span style="font-weight:normal">{{templates?.name}}</span>&nbsp;&nbsp;Created using: <span style="font-weight:normal">{{templates?.created_source}}</span></h4>
    
    <div class="options" *ngIf="showCategories" style="position:relative;width:100%;">
      <div style="border:1px solid #eee; padding:5px;margin-bottom:5px;border-radius:5px;position:relative;">
        <div style="background-color:#fff;position:absolute;z-index:10;top:-10px;font-size:9pt;font-weight:900">Choose Editor</div>
        <span *ngIf="showCodeEditor">
          <input type="radio" [checked]="selectedEditor=='ace'" name="door" (click)="setDoor('ace')" /> Code Editor &nbsp;
        </span>
        <span *ngIf="showInline">
          <input type="radio" [checked]="selectedEditor=='inline'" name="door" (click)="setDoor('inline')" /> Inline Editor&nbsp;
        </span>
        <span *ngIf="showSovrn">
          <input type="radio" [checked]="selectedEditor=='sovrn'" name="door" (click)="setDoor('sovrn')" /> Sovrn Web Builder&nbsp;
        </span>
      </div>
      
      <button mat-flat-button color="primary" type="button" (click)="addImage()">Media Manager</button>&nbsp;
      <button mat-flat-button color="primary" type="button" (click)="clearCode(e)">Clear</button>&nbsp;
      <button mat-flat-button color="primary" type="button" (click)="usePrebuilt()" *ngIf="showPrebuilt">Prebuilt Template</button>&nbsp;
      <button mat-flat-button color="primary" type="button" (click)="preview()">Preview</button>&nbsp;
      <button mat-flat-button color="primary" type="button" (click)="createSignature()">Create a Signature Image</button>&nbsp;
      <button mat-flat-button color="primary" type="submit" [disabled]="!f.valid" style="width:100px;"><div *ngIf="!showLoader">Save</div><div *ngIf="showLoader">Saving...</div></button>&nbsp;
      <button mat-flat-button color="primary" type="button" mat-dialog-close (click)="closeEdit()">Close</button>&nbsp;
      <div *ngIf="showMe==4" style="text-align:right;width:100%;font-size:12pt;font-weight:bold;color:red"><span>Press save HERE to save your WEB page  &#8681; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </span></div>
      <div *ngIf="showMe==1" style="font-size:12pt;font-weight:bold;color:red">If the document was created in the code editor, formating may get messed up if saved from the HTML Editor. You have been warned :). </div>
      <strong>Master Template ID:</strong>&nbsp;{{masterTemplate}}
    </div>
    <div *ngIf="showMe =='inline' || showMe=='sovrn'">
      <iframe  id="mainFrame" #mainFrame [src]="frameSrc" frameborder=0 style="width:100%;height:85vh;border:solid 2px;"></iframe>
    </div>
    
    
   
    
    <div *ngIf="showMe =='ace'" style="height:80vh">
     

      <ace [config]="options" name="body"  #editor id="editor" [mode]="'javascript'" [theme]="'cobalt'"  style="height:80vh;" [(value)]="preBuilt" ngDefaultControl></ace>

      
    </div>
    <br><br>
    <input type="hidden" name="templatesId" #templatesId="ngModel" [ngModel]="templates?._id" id="templatesId">
    <input type="hidden" name="type" #type="ngModel" [ngModel]="templates?.type" id="type">
    <span style="color:red">* Indicates a required field</span><br><br>
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="templates?.name" type="text" id="name" placeholder="Enter the Name or Title" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-checkbox name="redirect" #redirect="ngModel" [ngModel]="templates?.redirect" type="text" id="redirect" placeholder="Just a Redirect">Is this just a redirect page?</mat-checkbox>
    <br>
    <mat-form-field class="example-full-width">
      <input matInput name="redirect_url" #redirect_url="ngModel" [ngModel]="templates?.redirect_url" type="text" id="redirect_url" placeholder="If this is a redirect page, What is the url?">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input (keyup)="checkDomain($event)" matInput name="wid" #wid="ngModel" [ngModel]="templates?.wid" type="text" id="wid" placeholder="Enter the WID (this is the path of the page. for example /mypage" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="description" #description="ngModel" [ngModel]="templates?.description" type="text" id="description" placeholder="Enter the Description (personal Reference)">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input (keyup)="checkDomain($event)" matInput name="domain" #domain="ngModel" [ngModel]="templates?.domain" type="text" id="domain" placeholder="Enter a Domain for this template. (if no domain, enter www.12minuteaffiliate.com)">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <textarea matInput name="sovrn_info" #hsovrn_info="ngModel" [ngModel]="templates?.sovrn_info" type="text" id="sovrn_info" placeholder="sovrn edited text will show here."></textarea>
      </mat-form-field>

    <mat-form-field class="example-full-width">
      <textarea matInput name="headline" #headline="ngModel" [ngModel]="templates?.headline" type="text" id="headline" placeholder="inline edited text will show here."></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput name="displayorder" #displayorder="ngModel" [ngModel]="templates?.displayorder" type="text" id="displayorder" placeholder="Enter A Display order (This field is not curently used">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="image" #image="ngModel" [ngModel]="templates?.image" type="text" id="image" placeholder="Image Path">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="background" #background="ngModel" [ngModel]="templates?.background" type="text" id="image" placeholder="Background Image">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="config" #config="ngModel" [ngModel]="templates?.config" type="text" id="config" placeholder="Configuration">
    </mat-form-field>


    <div>
      
      <mat-form-field class="example-full-width">
        <mat-select *ngIf="showCategories" name="category" #category [ngModel]="templates?.category?._id"  id="category" placeholder="Choose a Category for a Template"  floatPlaceholder="auto" class="example-full-width" ngDefaultControl required>
          <mat-option  *ngFor="let category of categories" value="{{category._id}}">{{category.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br><br>
    
      <mat-form-field class="example-full-width">
        <mat-select *ngIf="showTypes" name="type" #type [ngModel]="templates?.type?._id"  id="type" placeholder="Choose a type for the page"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let type of types" value="{{type._id}}">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br><br>
      <mat-form-field class="example-full-width">
        <mat-select *ngIf="showSubtypes" name="subtype" #subtype [ngModel]="templates?.subtype?._id"  id="subtype" placeholder="Choose a subtype for the page"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let subtype of subtypes" value="{{subtype._id}}">{{subtype.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br><br>
      
      <mat-form-field class="example-full-width">
        
          <mat-select  name="layout" #layout [ngModel]="templates?.layout"  id="layout" placeholder="Choose a layout for the page"  floatPlaceholder="auto"  ngDefaultControl>
            <mat-option  *ngFor="let layout of layouts" value="{{layout._id}}">{{layout.name}}</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
  <br/><br/>

  
  
</form>

<div [@visibilityChanged]="visiblityState" style="width:400px;;color:white;position:fixed;bottom:50px;right:20px;border-radius:5px;" [ngClass]="{'warn':step=='warn', 'success':step=='success'}">
  <div style="font-size:20pt;padding:10px;"><mat-icon style="font-size:30pt;position:relative;top:9px">{{icon}}</mat-icon>&nbsp;&nbsp;&nbsp;{{messageTitle}}</div>
  <div style="font-size:14pt;padding:10px;">{{this.messageBody}}</div>
</div>