import {Injectable, EventEmitter} from '@angular/core';
import {Autoresponder} from "./autoresponder";import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AutoresponderService {
  private hostName = window.location.protocol + '//' + window.location.host;
  autoresponder: Autoresponder[] = [];
  autoresponderIsEdit = new EventEmitter<Autoresponder>();
  constructor(private http: HttpClient) { }

  editAutoresponder(autoresponder: any) {
    console.log(autoresponder)
    this.autoresponderIsEdit.emit(autoresponder);
  }

  addFrontRecord(autoresponder: Autoresponder) {
    const body = JSON.stringify(autoresponder);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/clientautoresponders/addfrontrecord' + token, body, {headers: headers})
     
  }

  addRecord(autoresponder: Autoresponder) {
    const body = JSON.stringify(autoresponder);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/clientautoresponders/addrecord' + token, body, {headers: headers})
    
  }

  addRecordForSomeoneElse(autoresponder: any) {
    const body = JSON.stringify(autoresponder);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/clientautoresponders/addrecordforsomeoneelse' + token, body, {headers: headers})
    
  }

  deleteRecord(autoresponder: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/clientautoresponders/deleterecord/' + autoresponder._id + token, {headers: headers})
     
  }

  deleteRecordForSomeoneElse(autoresponder: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/clientautoresponders/deleterecordforsomeoneelse/' + autoresponder._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/clientautoresponder/getallrecords'+token);
    return this.http.get(this.hostName +'/api/clientautoresponders/getallrecords'+token,  {headers: headers})
     
  }

  getMyRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/clientautoresponder/getmyrecords'+token);
    return this.http.get(this.hostName +'/api/clientautoresponders/getmyrecords'+token,  {headers: headers})
     
  }

  updateRecord(autoresponder: Autoresponder){
    const body = JSON.stringify(autoresponder);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/clientautoresponders/updaterecord/' + autoresponder.autoresponderId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/clientautoresponders/getrecordl/'+id+token,  {headers: headers})
     
  }

}
