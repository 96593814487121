<button mat-icon-button mat-mini-fab color="warn" mat-dialog-close style="position:absolute;top:20px;right:20px;">
  <mat-icon  class="material-icons">cancel</mat-icon>
</button>


<h2 mat-dialog-title>Edit Record</h2>
<table class="example-full-width" border=1>
  <tr><th colspan=2 style="text-align:center">Email Variables</th></tr>
  <tr><th>[LEADS NAME]</th><td>Will be replaced with the leads name</td></tr>
  <tr><th>[EMPLOYEES NAME]</th><td>Will be replaced with the Employees name</td></tr>
  <tr><th>[EMPLOYEES PHONE]</th><td>Will be replaced with the Employees phone</td></tr>
  <tr><th>[EMPLOYEES EMAIL]</th><td>Will be replaced with the Employees email</td></tr>
  <tr><th>[LEADS PHONE]</th><td>Will be replaced with the leads phone number</td></tr>
</table>
<br/><br/>
    <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
      <mat-dialog-content>
        <input type="hidden" name="emailId" #emailId="ngModel" [ngModel]="emailsection?._id" id="emailId">
        
      <mat-form-field class="example-full-width">
        <input matInput name="name" #name="ngModel" [ngModel]="emailsection?.name" type="text" id="name" placeholder="Enter the Name of This Email" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput name="subject" #subject="ngModel" [ngModel]="emailsection?.subject"  type="text" id="subject" placeholder="Enter The Subject of the Email" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <textarea style="height:200px;" matInput name="body" #body="ngModel" [ngModel]="emailsection?.body"  type="text" id="body" placeholder="Enter The Body of the Email" required></textarea>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <br><br>


      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="userActionButtonGroup">
          <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
            <mat-icon  class="material-icons">done</mat-icon>
          </button>
          <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
            <mat-icon  class="material-icons">cancel</mat-icon>
          </button>
        </div>
      </mat-dialog-actions>
    </form>
