// Import the core angular services.
import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DomainsService } from '../src/app/domains/domains.service';
import { NotificationsService } from '../src/app/shared/notifications/notifications.service';

@Injectable()
export class CommService {
    public guarantee: any;
    public drawerContent:number;
    private messageSource = new BehaviorSubject<string>("default message");
    returnMessage;
    currentMessage = this.messageSource.asObservable();

    private autoResponderYes = new BehaviorSubject<string>("default message");
    autoResponderGetYes = this.autoResponderYes.asObservable();

    private freeCoachingYes = new BehaviorSubject<string>("default message");
    freeCoachingGetYes = this.freeCoachingYes.asObservable();

    private autoResponderData = new Subject<any>();
    sendAutoResponderData(message){
      this.autoResponderData.next(message)
    }

    onAutoResponderData(): Observable<any>{
      return this.autoResponderData.asObservable();
    }

    constructor(private domainsService:DomainsService, private notificationService:NotificationsService){}

    //color change messages
    private colorSubject = new Subject<any>();
    sendColorMessage(message){
      this.subject.next(message)
    }

    onColorMessage(): Observable<any> {
      return this.colorSubject.asObservable();
    }


    private subject = new Subject<any>();
    sendMessage(message){
      this.subject.next(message)
    }

    onMessage(): Observable<any> {
      return this.subject.asObservable();
    }
    
    changeMessage(message: string){
        this.messageSource.next(message);
    }

    setAutoresponder(message: string){
        this.autoResponderYes.next(message);
    }

    setFreecoaching(message: string){
        this.freeCoachingYes.next(message);
    }

    goDaddyCall(data, thisArg, cb){
        //var data=model;
       
      this.domainsService.addHerokuDomain(data)
        .subscribe(
          returnData => {
          
          var domainData=JSON.parse(returnData['domain'])
          var forwardData=JSON.parse(returnData['forward']);
          var recordData=returnData['record'];
          
          var checkDomain=0;
          var checkForward=0;
          var check12MA=0;
          if (domainData[0]){
            if (domainData[0].data){checkDomain++}
            if (domainData[0].name=="www"){checkDomain++}
            if (domainData[0].type=="CNAME"){checkDomain++}
          } else {
            this.returnMessage={'status':'warn','title':'Failed','icon':'warning','message':"Unfortunaely something failed while setting up the domain with "+data.registrar+"."};
          }
          if (forwardData[0]){
            if (forwardData[0].fqdn==this.extractHostname(recordData.domain)){checkForward++}
            if (forwardData[0].type=="PERMANENT_REDIRECT"){checkForward++}
            if (forwardData[0].url=="https://"+recordData.domain){checkForward++}
          } else {
            this.returnMessage={'checkDomain':checkDomain, 'checkForward':checkForward,'status':'warn','title':'Failed','icon':'warning','message':"Unfortunaely something failed while setting up the forwarding of your domain with "+data.registrar+"."};
          }

          if (recordData){
            if (recordData.heroku_domain_id){check12MA++}
          }
          
          this.returnMessage={};
          
          if (checkDomain==3 && checkForward==3){
            this.returnMessage={'check12MA':check12MA,'checkDomain':checkDomain, 'checkForward':checkForward, 'status':'success','title':'Success','icon':'check_circle','message':'Looks like you domain, ' + data.domain + ", has been successfully setup with "+data.registrar+"."};
          } else if (checkDomain==0 && checkForward==0) { 
            this.returnMessage={'check12MA':check12MA,'checkDomain':checkDomain, 'checkForward':checkForward,'status':'success','title':'Success','icon':'check_circle','message':'Looks like you domain, ' + data.domain + ", has been successfully setup with 12ma."};
          } else if (checkDomain < 3){
            this.returnMessage={'check12MA':check12MA,'checkDomain':checkDomain, 'checkForward':checkForward,'status':'warn','title':'Failed','icon':'warning','message':"Unfortunaely something failed while setting up the domain with "+data.registrar+"."};
          } else if (checkForward < 3){
            this.returnMessage={'check12MA':check12MA,'checkDomain':checkDomain, 'checkForward':checkForward,'status':'warn','title':'Failed','icon':'warning','message':"Unfortunaely something failed while setting up the forwarding of your domain with "+data.registrar+"."};
          }
            return cb(this.returnMessage, thisArg, returnData);
          },
          error => {
            this.returnMessage={'check12MA':0,'checkDomain':0, 'checkForward':0,'status':'warn','title':error['title'],'icon':'check_circle', 'message':error['message']+" - "+error.error.errmsg};
            return cb(this.returnMessage, thisArg, '');
          }

        )
    }

    public extractHostname(url) {
        var hostname;
      
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
      
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        var tmpHostname = hostname.split('.');
        
        hostname = tmpHostname[tmpHostname.length-2]+'.'+tmpHostname[tmpHostname.length-1];
        return hostname;
      }
}