import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {LevelsService} from "../levels.service";
import {Levels} from "../levels";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-levels-input',
  templateUrl: './levels-input.component.html',
  styleUrls: ['./levels-input.component.css']
})
export class LevelsInputComponent implements OnInit {
  levels:Levels = null;

  constructor(private levelsService:LevelsService, private authService: AuthService, private _service: ToastService) {
    levelsService.levelsIsEdit.subscribe(
      levels => {
        this.levels = levels;
        console.log(this.levels);
      })

  }

  save(f:NgForm, model: Levels, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.levelsId){

      this.levelsService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.levelsService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
