import { Component, OnInit } from '@angular/core';
import {Record} from "../record";
import {CrmService} from "../crm.service";
import {Crm} from "../crm";
import { MatInputModule } from '@angular/material/input';
import { ToastService } from 'ng-uikit-pro-standard';
import {Router} from "@angular/router";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {EmailsectionService} from "../../emailsection/emailsection.service";
import * as moment from 'moment';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';

@Component({
  selector: 'app-crm-input',
  templateUrl: './crm-input.component.html',
  styleUrls: ['./crm-input.component.css']


})
export class CrmInputComponent implements OnInit {
  crm:Crm = null;
  emails: any;
  emailSubject: string = null;
  emailBody: string = null;
  emailName: string = null;
  emailId: number = null;
  crmFirstname: string = null;
  crmLastname: string = null;
  crmEmail: string = null;
  crmPhone: string = null;
  notesClick = 0;
  privateNotesClick = 0;
  gmailUsername;
  gmailPassword;
  action;
  fromEmail:string="help@easysupportnow.com";
  doc
  emailType:string="sendgrid";

  

  constructor(private systempreferenceService: SystempreferenceService, private crmService: CrmService, private _service: ToastService,private router:Router, private dialogRef: MatDialogRef<CrmInputComponent>, private emailsectionService: EmailsectionService) {
    crmService.crmIsEdit.subscribe(
      crm => {
        console.log(crm)
        this.action=crm['action']
        if (crm['action']=="fromCRM"){
          crmService.getRecord(crm._id).subscribe( crmData => {
            console.log(crmData)
          this.crm = crmData['obj'];
          console.log(this.crm);
          this.crmFirstname = this.crm.firstname;
          this.crmLastname = this.crm.lastname;
          this.crmEmail = this.crm.email;
          this.crmPhone = this.crm.phone; 
          })
        } else if (crm['action']=="fromUser"){
          crmService.getRecordByUser(crm._id).subscribe( crmData => {
            console.log(crmData)
          this.crm = crmData['obj'];
          console.log(this.crm);
          this.crmFirstname = this.crm.firstname;
          this.crmLastname = this.crm.lastname;
          this.crmEmail = this.crm.email;
          this.crmPhone = this.crm.phone; 
          })
        }

        this.doc = JSON.parse(localStorage.getItem('userRecord'));
        console.log("doc",this.doc);
        this.gmailUsername = this.doc['gmail_username'];
        this.gmailPassword = this.doc['gmail_password'];
       /* const systempreference = new Systempreference(null, this.doc.username+"_gmail", null, null);
        console.log(systempreference);
        this.systempreferenceService.getKey(systempreference)
          .subscribe(
            data => {
              console.log(data['obj'].value);
             // this.webinarid=data['obj'].value;
             var mailArgs = data['obj'].value.split('|') ;
             this.gmailUsername = mailArgs[0];
             this.gmailPassword = mailArgs[1];

          },
          error => {
            this._service.error(error.error['message'], error['title'],{opacity:1});
          }
        ); */
    })
  }

  onStatusChange(event){
    console.log(event);
    var value=event.value;
    let element="notes";
    let el = document.getElementById(element) as HTMLTextAreaElement;
    let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
    let username = localStorage.getItem('username');
    el.focus();
    el.scrollTop = 0;
    el.value = timeStamp + " - " + username + "\n\nStatus Changed: " + value + "\n\n" + el.value;
  }

  save(model: Crm, isValid: boolean) {
    
    this.notesClick = 0;
    this.privateNotesClick = 0;
    this.crmService.updateRecord(model)
      .subscribe(
        data => {
          console.log(data);
          this._service.success(data['message'], data['title'],{opacity:1})
        //  this.router.navigate(['/manage/crm', {login: 'Success'}]);
        },
        error => this._service.error(error['message'], error['title'],{opacity:1}),

      )
    
  }

  sendEmail(email, isValid: boolean) {
   
        if (this.emailType=="gmail"){
          this.fromEmail=this.gmailUsername
        } 
        
        email.from=this.fromEmail
         email.type = this.emailType;
         email.myFirstname = this.doc.firstname;
         email.myLastname = this.doc.lastname;
         email.myEmail = this.doc.email;
         email.myPhone = this.doc.phone;
         email.username = this.gmailUsername;
         email.password = this.gmailPassword;
         console.log(email);
         this.crmService.sendEmail(email)
           .subscribe(
             data => {
              
               this._service.success(data['message'], data['title'],{opacity:1})
             },
             error => this._service.error(error['message'], error['title'],{opacity:1}),
     
           )

    

  }

  onChange(event){
    console.log(event);
    var value=event.value;
    if (value=="all"){

    } else {
      this.emailsectionService.getEmail(value)
        .subscribe(
          email => {
            console.log(email);
           // this.pages = pages;
          //  this.pageService.pages = pages;
            this.emailSubject = email['obj'].subject;
            this.emailBody = email['obj'].body;
          },
          error => this._service.error("error", error['title'])
        );
    }


  }

  ngOnInit() {
    this.emailsectionService.getAllEmails()
      .subscribe(
        data => {
          this.emails = data['obj'];
        },
        error => this._service.error(error['message'], error['title'],{opacity:1}),

      )

  }

  addNotesTime(element){


    if (this.notesClick==0) {
      let el = document.getElementById(element) as HTMLTextAreaElement;
      let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
      let username = localStorage.getItem('username');
      console.log(timeStamp + " - " + username + "\n\n" + el.value);
      el.focus();
      el.scrollTop = 0;
      el.value = timeStamp + " - " + username + "\n\n" + el.value;
      this.notesClick++;
    }


  }

  addPrivateNotesTime(element){


    if (this.privateNotesClick==0) {
      let el = document.getElementById(element) as HTMLTextAreaElement;
      let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
      let username = localStorage.getItem('username');
      console.log(timeStamp + " - " + username + "\n\n" + el.value);
      el.focus();
      el.scrollTop = 0;
      el.value = timeStamp + " - " + username + "\n\n" + el.value;
      this.privateNotesClick++;
    }


  }

}
