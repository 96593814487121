
<div style="width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
  <span style="font-size:14pt;">Here you can setup your own domain name to work with 12MA. Please read the documentation first.</span>
  <hr>
  <div style="width:100%;height:40px;">
      <div style="float:left;height:40px;">
        <button mat-flat-button color="primary" (click)="refresh()">Refresh the Grid</button>&nbsp; <button mat-flat-button color="primary" (click)="addRecord()">Add a Domain (Expert Mode)</button>&nbsp; <button mat-flat-button color="primary" (click)="wizard()">Run The Domain Wizard</button>&nbsp;&nbsp;<a href="https://www.easiestsystemever.com/my-domains-help" target="_blank" title="Help" ><img alt="help" src="https://esev2.s3.amazonaws.com/images/instructions-icon.png" style="width:40px"></a>&nbsp;<img style="width:200px;" src="https://esev2uploaded.s3.amazonaws.com/dsloan254/1570801755809.8342.opsgKPjTrF.pleasereadthis1.png">
      </div>
      <div style="float:right;height:40px;font-size:18pt;font-weight:bold;">
        My Domains
      </div>
    </div>
<ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);;" class="ag-theme-dave" id="myGrid"
                 [gridOptions]="gridOptions"
                 [columnDefs]="columnDefs"
                 
                 enableColResize
                 enableSorting
                 autoSizeColumns="true"
                 enableRangeSelection="true"
                 suppressRowClickSelection
                 enableFilter
                 toolPanelSuppressValues
                 toolPanelSuppressGroups
                 debug="false"
                 draggable="false"
                 rowHeight="150"
                 (rowClicked)="catchClick($event)"
                 rowSelection='multiple'>
</ag-grid-angular>
<app-notifications></app-notifications>
</div>



