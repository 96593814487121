<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="systempreferenceId" #newsId="ngModel" [ngModel]="systempreference?._id" id="systempreferenceId">
    <mat-form-field class="example-full-width">
      <input matInput name="key" #key="ngModel" [ngModel]="systempreference?.key" type="text" id="key" placeholder="Enter the Key for the Systempreference" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <textarea matInput name="value" #value="ngModel" [ngModel]="systempreference?.value"  id="value" placeholder="Enter the Value" required style="height:300px;"></textarea>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    
      <mat-checkbox class="example-margin" [ngModel]="systempreference?.encrypted" name="encrypted" #encrypted="ngModel" id="encrypted">Encrypted?</mat-checkbox>
    
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
