<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="serversId" #serversId="ngModel" [ngModel]="servers?._id" id="serversId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="servers?.name" level="text" id="name" placeholder="Enter the Name of The Server (This is for information only)" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="server" #server="ngModel" [ngModel]="servers?.server" level="text" id="server" placeholder="Enter the server or App name" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field style="width:80%">
      <input matInput name="count" #count="ngModel" [ngModel]="servers?.count" level="text" id="count" placeholder="If you need to adjust the count. Change this figure" [disabled]="disableField">
    </mat-form-field>
    &nbsp;<button mat-flat-button (click)="editField()" color="primary" type="button">
      <mat-icon  class="material-icons">edit</mat-icon>Edit
    </button>
    <mat-form-field class="example-full-width">
      <input matInput name="order" #order="ngModel" [ngModel]="servers?.order" level="text" id="order" placeholder="Enter the rotate order for this server" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

   <mat-checkbox matInput name="active" #active="ngModel" [ngModel]="servers?.active" level="text" id="active" placeholder="Is this server the current one?">Is this server the current one?</mat-checkbox>
      
    


    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-flat-button color="primary" level="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon> Save
      </button>
      <button mat-flat-button color="primary" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon> Close
      </button>
    </div>
  </mat-dialog-actions>
</form>
