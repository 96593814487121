import { Component, OnInit } from '@angular/core';
import { FunnelsService } from '../funnels/funnels.service';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { Systempreference } from '../shared/systempreference/systempreference';
import { WebinarService } from '../webinar/webinar.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-coaching',
  templateUrl: './coaching.component.html',
  styleUrls: ['./coaching.component.css']
})
export class CoachingComponent implements OnInit {
  datas:any;
  webinar_time:any;
  
  webinar_url:any;
  constructor(private router: Router, private webinarService:WebinarService, private systempreferenceService: SystempreferenceService, private funnelsService: FunnelsService, private _service: ToastService) { 
    this.funnelsService.getAllRecordsSubtypes('past_coaching_calls')
      .subscribe(
        data => {

          this.datas = data['obj'];
          console.log(this.datas);
         // this.body = atob(data['obj'].body);
         // this._service.success(data['title'], data['message'])
        },
       // error => this._service.error(error.error['message'], error['title']),
      );
      const systempreference = new Systempreference(null, "default_training_call_webinar", null, null);
      this.systempreferenceService.getKey(systempreference)
      .subscribe(
        sys => {

          console.log(sys['obj'].value);
          this.webinarService.getRecordByName(sys['obj'].value)
          .subscribe (
            webinar => {
              console.log(webinar);
              this.webinar_time=webinar['obj'].webinar_time;
              this.webinar_url=webinar['obj'].url;
            }
          )
         // this.body = atob(data['obj'].body);
         // this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  ngOnInit() {
  }

  

  navigate(){
    window.open(this.webinar_url, "_blank");
  }

}
