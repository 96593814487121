import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {CategoriesService} from "../categories.service";
import {Categories} from "../categories";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-categories-input',
  templateUrl: './categories-input.component.html',
  styleUrls: ['./categories-input.component.css']
})
export class CategoriesInputComponent implements OnInit {
  categories:Categories = null;

  constructor(private categoriesService:CategoriesService, private authService: AuthService, private _service: ToastService) {
    categoriesService.categoriesIsEdit.subscribe(
      categories => {
        this.categories = categories;
        console.log(this.categories);
      })

  }

  save(f:NgForm, model: Categories, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.categoriesId){

      this.categoriesService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.categoriesService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
