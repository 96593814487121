import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PageloaderService } from './pageloader.service';

@Component({
  selector: 'app-pageloader',
  templateUrl: './pageloader.component.html',
  styleUrls: ['./pageloader.component.css']
})
export class PageloaderComponent implements OnInit {
  isLoading: Subject<boolean>;
  constructor(private loaderService: PageloaderService) {}

  ngOnInit() {
    
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoading = this.loaderService.isLoading;
      console.log(this.isLoading);
    });
  }

}
