export class Contact {

  firstname: string;
  lastname: string;
  email: string;
  subject: string;
  message: string;


  constructor (firstname: string, lastname: string, email: string,  subject: string, message: string) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.subject = subject;
    message = message;

  }
}
