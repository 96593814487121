import { Component, OnInit } from '@angular/core';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {
  public options = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 0,
    maxStack: 7,
    showProgressBar: true,
    pauseOnHover: true,
    preventDuplicates: false,
    preventLastDuplicates: 'visible',
    rtl: false,
    animate: 'fromRight',
    position: ['right', 'bottom']

  }

  backgroundColor:string;
  logo: string

  current_year:number = new Date().getFullYear();
  constructor(private systempreferenceService:SystempreferenceService) { 
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          
          var myJson = JSON.parse(systemPref['obj'].value);
          
          this.logo=myJson.logo;
          this.backgroundColor=myJson.sideNavBackgroundColor;
        },
        error => {
          
        }
      );
  }

  ngOnInit() {
  }

}
