import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../../auth/auth.service";
import {NewsService} from "../news.service";
import {News} from "../news";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-news-input',
  templateUrl: './news-input.component.html',
  styleUrls: ['./news-input.component.css']
})
export class NewsInputComponent implements OnInit {
  news:News = null;

  constructor(private newsService:NewsService, private authService: AuthService, private _service: ToastService) {
    newsService.newsIsEdit.subscribe(
      news => {
        this.news = news;
        console.log(this.news);
      })

  }

  save(f:NgForm, model: News, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.newsId){

      this.newsService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.newsService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
