import { Component, AfterViewInit, OnInit, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { TemplatesService } from '../templates/templates.service';
import { DomSanitizer } from '@angular/platform-browser';
import {NewsService} from "../shared/news/news.service";

import { Systempreference } from '../shared/systempreference/systempreference';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { MenusService } from '../menus/menus.service';
import { Subscription } from 'rxjs';
import { CommService } from '../../../services/comm.service';
import { ManageInternalpageService } from './manageinternalpage.service';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-manageinternalpage',
  template: `
  
  <div id="ad54"></div>
  <!--begin media manager modal -->
<div mdbModal #mediaManagerModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: false}">
    <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
        <div class="modal-content">
            <div class="modal-header light-blue">
            <table>
              <tr>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect   (click)="processMediaEmitter($event)" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Refresh</button>
              </td>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="mediaManagerModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
              </td>
              </tr>
            </table>
              <span style="width:98%;text-align:right;"><h4 style="font-weight:900;color:#fff">MEDIA MANAGER</h4></span>
              
            </div>
            <div class="modal-body">
              <app-files (imagePath)="processImage($event)" (closeButtonClick)="onCloseMediaMediaModal($event)"></app-files>
            </div>
            
        </div>
    </div > 
  </div >
  <!--end medai manager modal -->

  <!--begin config modal -->
  <div mdbModal #configManagerModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: false}">
      <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
          <div class="modal-content">
              <div class="modal-header light-blue">
              <table>
                <tr>
                <td>
                  <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect   (click)="processMediaEmitter($event)" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Refresh</button>
                </td>
                <td>
                  <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="configManagerModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
                </td>
                </tr>
              </table>
                <span style="width:98%;text-align:right;"><h4 style="font-weight:900;color:#fff">CONFIGURATION</h4></span>
                
              </div>
              <div class="modal-body">
                <app-templatesconfig></app-templatesconfig>
              </div>
              
          </div>
      </div > 
    </div >
    <!--end config modal -->

  <div *ngIf="showNews" style="height:25px;width:100%;background-color:rgba(6,31,64,0.8);position:fixed;bottom:0px;left:0px;text-align:left;color:#fff;font-weight:900;padding-left:250px" [innerHtml]="newsHtml"></div> 
  `,
  styleUrls: ['./manageinternalpage.component.css']
})
export class ManageInternalpageComponent implements OnInit {
  page;
  myVar;
  sub;
  data;
  frameSrc;
  myquery;
  newsHtml;
  showNews=false;
  newsArray: any[]=[];
  menu; 
  newsInterval=30000;
  navShown=true;
  title;
  HTMLToIsolate='';
  pageTitle='';
  newIframe;

  receiveMessage: any = (event: any) =>  {
    console.log(event);
    if (event.data=="toggleMenu"){
      //this.toggleSideNav();
    }
  }

  sendPageInfo=()=>{
    this.commService.sendMessage({'action':'pageInfo', 
    'pagetitle':this.pageTitle, 
    });
  }

  @ViewChild('mediaManagerModal') mediaManagerModal: ModalDirective;
  @ViewChild('configManagerModal') configManagerModal: ModalDirective;
  
  @HostListener('window:message', ['$event'])
  onMessage(e) {
   
   /* if (e.origin != "http://www.tomato.com:4100") { // set your origin
      return false;
    } */

    console.log(e.data);
    console.log(e.data.action)
    switch(e.data.action){
      case "openMediaManager":
        console.log(e.data.action)
        this.mediaManagerModal.show();
        break;
      case "openConfigManager":
        this.configManagerModal.show();
        break;
    }
    //if (e.data.action == "openMediaManager") {
    //  this.mediaManagerModal.show();
    //}
  }

  processMediaEmitter(event){}
  

  openModal(){
    this.mediaManagerModal.show();
  }

  onCloseMediaMediaModal($event){};

  onCloseConfigModal($event){};

  processImage(event){}
  

  constructor(private internalPageService: ManageInternalpageService, private commService: CommService, private menusService: MenusService, private systempreferenceService: SystempreferenceService, private newsService: NewsService, private route: ActivatedRoute, private _service: ToastService, private templatesService:TemplatesService, private sanitizer: DomSanitizer) { 
    this.setUrl(function(thus){})
    this.sendPageInfo();
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
       (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    this.receiveMenuCommand();
    
    
  }

  subscription: Subscription;
  receiveMenuCommand(){
    this.subscription = this.commService.onColorMessage().subscribe(message=>{
      console.log("colormessage", message)
    })
    
    this.subscription = this.commService.onMessage().subscribe(message=>{
      

      console.log("my message",message)
      switch(message.action){
        case ("pageBackgroundUrl"):
            console.log("url:",message.url);
            this.newIframe.contentWindow.postMessage({action:'backgroundImage', url: message.url}, "*");
            break;
        case ("removeBackgroundUrl"):
          console.log("url:",message.url);
          this.newIframe.contentWindow.postMessage({action:'removeBackgroundImage', url: ""}, "*");
          break;
        case ("backgroundColor"):
          this.newIframe.contentWindow.postMessage({action:'backgroundColor', color: message.color}, "*");
          break;
        case ("useAweberNameField"):
          this.newIframe.contentWindow.postMessage({action:'useAweberNameField', value: message.value}, "*");
          break;
        case ("toggleSideNav"):
          this.navShown=message.value;
          break;
      }
    })
  }

  ngOnInit() {
    this.pageTitle="";
    //this.sendPageInfo();
    /*this.sub = this.route.params.subscribe(params => {
      this.page = params['page'];
      this.myquery = params['myparams'];
     
      this.templatesService.getRecord(this.page)
      .subscribe(
        data => {
         
          var querySep="?";
          const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
       
          var queryString;
            if (this.myquery){
               queryString=this.myquery.replace("?", "&");this.myquery;
            }else {
               queryString='';
            }
            
            var url = '/api/templates/html/'+data['obj']._id+token+queryString;
            
            this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(url);
       
           this.myVar = data['obj'].body;
        
        },
        error => this._service.error(error.error['message'], error['title']),
      );
    }); */
  
  }

  getNews(start){
    this.newsService.getNewsTicker()
    .subscribe(
      data => {
        if (data['status']=="success") {
          this.newsArray=data['obj'];
          if (start=="firstnews"){
            this.newsHtml=data['obj'][0].body;
          }
          this.showNews = true;
        } else {
          this.showNews = false
          this.newsArray=data['obj'];
        }
      },
      error => this._service.error(error['message'], error['title']),

    ) 
   
  }

  QueryStringToJSON=(nameValue)=>{     
        console.log("nameValue",nameValue)
      var pairs = nameValue.slice(1).split('&');
      
      var result = {};
      pairs.forEach(function(pair) {
          pair = pair.split('=');
          result[pair[0]] = decodeURIComponent(pair[1] || '');
      });

      return JSON.parse(JSON.stringify(result));
   
  }

  setUrl=(cb)=>{
    
    this.route.queryParams
    .subscribe (queryParams => {
        this.route.params.subscribe(params => {
        
        
        this.page = params['page'];
        
        var formattedParams;

        if (params.myparams){
          formattedParams=this.QueryStringToJSON(params.myparams);
        } else {
          formattedParams=queryParams;
        }

       
          var queryString;
          if (Object.keys(formattedParams).length>0){
            queryString = "&"+Object.keys(formattedParams).map(key => key + '=' + formattedParams[key]).join('&');
            queryString=queryString.replace("&","?");
            const last = queryString.charAt(queryString.length - 1);
            
            if (last=="&"){
              queryString=queryString.slice(0,-1);
            }
          } else {
            queryString='';
          }
        ///this.title=this.QueryStringToJSON(this.myquery) ? this.QueryStringToJSON(this.myquery).title : '';
        //}
       
        if (formattedParams.title){
          this.pageTitle=formattedParams.title;
          this.sendPageInfo()
        }
        
        
        const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
        
        this.setCookie("","","", function(){

        });
        
        var url = this.page+queryString;
            console.log("urlnoe",url)
        this.internalPageService.getHtml(url)
        .subscribe(
          data => {
            
            var html=data['html'];
              
              this.HTMLToIsolate = html;
              var parentContainerRef = document.getElementById("ad54"); //.body; 
              parentContainerRef.innerHTML="";
              this.newIframe = document.createElement('iframe');
              this.newIframe.setAttribute("style","width:100%;height:100vh;")
              this.newIframe.style.left="0px";
              this.newIframe.setAttribute("frameborder","0");
              parentContainerRef.appendChild(this.newIframe)
              this.newIframe.contentWindow.document.open('text/html', 'replace');
              this.newIframe.contentWindow.document.write(this.HTMLToIsolate);
              this.newIframe.contentWindow.document.close();
              
          
          },
          error => {},
        );
        cb(this);
      }); 
    });
  }

  sendtoiframe(){
    console.log('hello')
    this.newIframe.contentWindow.postMessage({call:'sendValue', value: "hello"}, "*");
  }
    

  bindEvent(element, eventName, eventHandler) {
    if (element.addEventListener){
        element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventHandler);
    }
  }

  setCookie=(cname, cvalue, exdays, cb) => {
    var token=localStorage.getItem('token');
    
    var d = new Date();
   // d.setTime(d.getTime() + (exdays*24*60*60*1000));
    d.setTime(d.getTime() + (5*1000));
    var expires = "expires="+ d.toUTCString();
    cname="tokenSend";
    cvalue=token;
    
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    cb();
}

  ngAfterViewInit() {
  }

}
