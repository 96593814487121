
<button mat-icon-button mat-mini-fab color="warn" mat-dialog-close style="position:absolute;top:20px;right:20px;">
  <mat-icon  class="material-icons">cancel</mat-icon>
</button>


<h2 mat-dialog-title>Add Record</h2>
<form (ngSubmit)="save(f, f.value, f.valid)" #f="ngForm">
  <mat-dialog-content>
    <div class="form-group">

      <mat-form-field class="example-full-width">
        <input name="firstname" matInput #firstname [ngModel]="crm?.firstname" type="text" id="firstname" placeholder="First Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="lastname" matInput #lastname [ngModel]="crm?.lastname" type="text" id="lastname" placeholder="Last Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="email" matInput #email [ngModel]="crm?.email" type="text" id="email" placeholder="Email" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="phone" matInput #phone [ngModel]="crm?.phone" type="text" id="phone" placeholder="Phone" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="skype" matInput #skype [ngModel]="crm?.skype" type="text" id="skype" placeholder="Skype">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="city" matInput #city [ngModel]="crm?.city" type="text" id="city" placeholder="City" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="state" matInput #state [ngModel]="crm?.state" type="text" id="state" placeholder="State" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="country" matInput #country [ngModel]="crm?.country" type="text" id="country" placeholder="Country" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="howlong" matInput #howlong [ngModel]="crm?.howlong" type="text" id="howlong" placeholder="How Long Have Your Been Doing This?" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="about" matInput #about [ngModel]="crm?.about" type="text" id="about" placeholder="About You" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="affiliate_id" matInput #affiliate_id [ngModel]="crm?.affiliate_id" type="text" id="affiliate_id" placeholder="Affiliate ID">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="tracking_id" matInput #tracking_id [ngModel]="crm?.tid" type="text" id="tracking_id" placeholder="Tracking Id">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>


      <div>
        <mat-select name="status"  #status [ngModel]="crm?.status"  id="status" placeholder="Status"  floatPlaceholder="auto" class="example-full-width">
          <mat-option value="Contact 1">Contact 1</mat-option>
          <mat-option value="Contact 2">Contact 2</mat-option>
          <mat-option value="Unable to Contact">Unable to Contact</mat-option>
          <mat-option value="NI/NQ">NI/NQ</mat-option>
          <mat-option value="Appointment Set">Appoitment Set</mat-option>
          <mat-option value="Appointment Kept">Appoitment Kept</mat-option>
          <mat-option value="Sold">Sold</mat-option>
          <mat-option value="Not Interested">Not Interested</mat-option>
          <mat-option value="In Fullfillment">In Fullfillment</mat-option>
          <mat-option value="Duplicate">Duplicate</mat-option>
          <mat-option value="No Show">No Show</mat-option>
        </mat-select>
      </div>


      <mat-form-field class="example-full-width">
        <textarea (click)="addNotesTime('notes')" name="notes" matInput #notes [ngModel]="crm?.notes"  id="notes" placeholder="notes" style="height:200px;"></textarea>
      </mat-form-field>
      <br/><br/>
      <mat-form-field class="example-full-width">
        <textarea (click)="addPrivateNotesTime('private_notes')" name="private_notes" matInput #private_notes [ngModel]="crm?.private_notes"  id="private_notes" placeholder="Private Notes" style="height:200px;"></textarea>
      </mat-form-field>


    </div>
    <p></p><p></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
<br/><br/>
