export class Userpreference {
  userpreferenceId: string;
  key: string;
  value: string;
  group: string;
  subgroup: string;
  username: string;
  userId: string;
  _id: string;

  constructor (userpreferenceId?: string, key?: string, value?: string, userId?: string, _id?: string, group?: string, username?: string, subgroup?: string) {
    this.userpreferenceId = userpreferenceId;
    this.key = key;
    this.value = value;
    this.userId = userId;
    this._id = _id;
    this.group = group;
    this.username = username;
    this.subgroup = subgroup;
  }
}
