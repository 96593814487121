import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {FilesService} from "../files.service";
import {Images} from "../files";
import {NgForm} from "@angular/forms";
import {CategoriesService} from "../../categories/categories.service";
import {Categories} from "../../categories/categories";
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-files-input',
  templateUrl: './files-input.component.html',
  styleUrls: ['./files-input.component.css']
})
export class FilesInputComponent implements OnInit {
  images:Images = null;
  categories: Categories = null;
  showMe=0;




  constructor(private categoriesService: CategoriesService, private imagesService:FilesService, private authService: AuthService, private toastrService: ToastService) {
    
    
    imagesService.imagesIsEdit.subscribe(
      images => {
        console.log(images);
        try {
          images.body = atob(images.body);
        } catch(e) {
          console.log(e);
        }
        this.images = images;
        console.log(this.images);
      })
  }

  uploadFile($event){
    console.log($event)
  }

  setDoor(val){
    this.showMe=val;
  }

  save(f:NgForm, model: Images, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);

    if (model.imagesId){
      console.log(model);
      this.imagesService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this.toastrService.success(data['message'], data['title'], {opacity:1})
          },
          error => this.toastrService.error(error['message'], error['title'], {opacity:1}),

        )
      console.log(model, isValid);
    } else {

      this.imagesService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this.toastrService.success(data['message'], data['title'], {opacity:1});
            f.reset();
          },
          error => this.toastrService.error(error['message'], error['title'], {opacity:1}),

        )
    }
  }

  ngOnInit() {
    this.categoriesService.getAllRecords()
      .subscribe(
        data => {
          this.categories = data['obj'];
          console.log(this.categories);
        },
        error => this.toastrService.error(error.error['message'], error['title'], {opacity:1}),
      );


  }


  onChange($event){}
  onReady($event){}
  onBlur($event){}
  onFocus($event){}

}
