import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommService } from '../../../services/comm.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as $ from 'jquery';
import * as moment from 'moment';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-specialtrafficoffer',
  templateUrl: './specialtrafficoffer.component.html',
  styleUrls: ['./specialtrafficoffer.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class SpecialtrafficofferComponent implements OnInit {
  show=false;
  showSalesPitch=true;
  visiblityState = 'hidden';
  token;
  frameSrc;
  clockValue;
  timestamp;
  @Output() onClose = new EventEmitter<String>();

  public trafficOptions = {
    price:"79.20",
    product: 63,
    type: "single",
    gateway: 4,
    invoice: 1,
    owner: 0,
    commissions:0,
    quantity: 1,
    backdrop:'hellosign',
    templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
    item: "ESE Traffic Option 1 - 90-100 Top Quality Visitors",
    msgTitle:"Congratulations",
    msgBody:"Your Product will be delivered soon",
    visitors:"90-100",
    package:"1"
  }
  @Input() mainFields: any;
  constructor(private commService:CommService, private sanitizer: DomSanitizer) { 
    
  }

  get stateName() {
    return this.show ? 'show' : 'hide'
  }


  toggle() {
    console.log('hello');
    this.show = !this.show;
  }

  openDrawer(drawerContent: string){
    this.commService.guarantee.toggle();
    console.log(drawerContent);
    this.commService.changeMessage(drawerContent);
  }

  cancel(){
    
    if (this.visiblityState === 'hidden')
      this.visiblityState = 'shown'
    else
      this.visiblityState = 'hidden'

      this.showSalesPitch=true;
  }

  traffic1(){
    this.trafficOptions = {
      price:"79.20",
      product: 63,
      type: "single",
      gateway: 4,
      invoice: 1,
      owner: 0,
      commissions:0,
      quantity: 1,
      backdrop:'hellosign',
      templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
      item: "ESE Traffic Option 1 - 90-100 Top Quality Visitors",
      msgTitle:"Congratulations",
      msgBody:"Your Product will be delivered soon",
      visitors:"90-100",
      package:"1"
    }

    console.log(this.trafficOptions);
    this.showSalesPitch=false;
    if (this.visiblityState === 'hidden')
      this.visiblityState = 'shown'
    else
      this.visiblityState = 'hidden'
  }

  traffic2(){
     this.trafficOptions = {
      price:"159.20",
      product: 64,
      type: "single",
      gateway: 4,
      invoice: 1,
      owner: 0,
      commissions: 0,
      quantity: 1,
      backdrop:'hellosign',
      templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
      item: "ESE Traffic Option 2 - 175-199 Top Quality Visitors",
      msgTitle:"Congratulations",
      msgBody:"Your Product will be delivered soon",
      visitors:"175-199",
      package:"2"
    }
    this.showSalesPitch=false;
    if (this.visiblityState === 'hidden')
      this.visiblityState = 'shown'
    else
      this.visiblityState = 'hidden'
  }

  traffic3(){
    this.trafficOptions = {
      price:"317.60",
      product: 65,
      type: "single",
      gateway: 4,
      invoice: 1,
      owner: 0,
      commissions:0,
      quantity: 1,
      backdrop:'hellosign',
      templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
      item: "ESE Traffic Option 3 - 350-399 Top Quality Visitors",
      msgTitle:"Congratulations",
      msgBody:"Your Product will be delivered soon",
      visitors:"350-399",
      package:"3"
   }
   this.showSalesPitch=false;
   if (this.visiblityState === 'hidden')
     this.visiblityState = 'shown'
   else
     this.visiblityState = 'hidden'
 }

 traffic4(){
  this.trafficOptions = {
    price:"477.60",
    product: 66,
    type: "single",
    gateway: 4,
    invoice: 1,
    owner: 0,
    commissions:0,
    quantity: 1,
    backdrop:'hellosign',
    templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
    item: "ESE Traffic Option 4 - 550-599 Top Quality Visitors",
    msgTitle:"Congratulations",
    msgBody:"Your Product will be delivered soon",
    visitors:"550-599",
    package:"4"
 }
  this.showSalesPitch=false;
  if (this.visiblityState === 'hidden')
    this.visiblityState = 'shown'
  else
    this.visiblityState = 'hidden'
}

traffic5(){
  this.trafficOptions = {
    price:"797.60",
    product: 67,
    type: "single",
    gateway: 4,
    invoice: 1,
    owner: 0,
    commissions:0,
    quantity: 1,
    backdrop:'hellosign',
    templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
    item: "ESE Traffic Option 5 - 910-997 Top Quality Visitors",
    msgTitle:"Congratulations",
    msgBody:"Your Product will be delivered soon",
    visitors:"910-997",
    package:"5"
 }
  this.showSalesPitch=false;
  if (this.visiblityState === 'hidden')
    this.visiblityState = 'shown'
  else
    this.visiblityState = 'hidden'
}

traffic6(){
  this.trafficOptions = {
    price:"1597.60",
    product: 68,
    type: "single",
    gateway: 4,
    invoice: 1,
    owner: 0,
    commissions:0,
    quantity: 1,
    backdrop:'hellosign',
    templateId:'28d796d0e6bdac609ca9a07fafc885414911595c',
    item: "ESE Traffic Option 6 - 1800-2000 Top Quality Visitors",
    msgTitle:"Congratulations",
    msgBody:"Your Product will be delivered soon",
    visitors:"1800-2000",
    package:"6"
 }
  this.showSalesPitch=false;
  if (this.visiblityState === 'hidden')
    this.visiblityState = 'shown'
  else
    this.visiblityState = 'hidden'
}


  closeDrawer(event, closeMe: string){
    this.onClose.emit(closeMe);
  }

  ngOnInit() {
    this.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl('/api/api/getsimpletimer?days=0&hours=0&minutes=60&seconds=0&style=simple2&cookiename=myClock&token='+this.token);
   
    
  }

}
