import { Component, OnInit, ViewChild } from '@angular/core';
import { CommService } from '../../../services/comm.service';

@Component({
  selector: 'app-oneoneoncoaching',
  templateUrl: './oneoneoncoaching.component.html',
  styleUrls: ['./oneoneoncoaching.component.css']
})
export class OneoneoncoachingComponent implements OnInit {
  @ViewChild('guarantee',{static:true}) public guarantee;
  constructor(private commService: CommService) { }

  openDrawer(drawerContent: string){
    this.commService.guarantee.toggle();
    console.log(drawerContent);
    this.commService.changeMessage(drawerContent);
  }

  ngOnInit() {
  }

  onClose(event){
    console.log(event);
    this.guarantee.close();
  }

}
