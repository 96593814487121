import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import { ToastService } from 'ng-uikit-pro-standard';
import {MyapplicationsService} from "./myapplications.service";
import {GridOptions} from "ag-grid-community";
//import {RedcomponentComponent} from "../redcomponent/redcomponent.component";
//import {NumericEditorComponent} from "./numeric-editor.component";
//import {TextEditorComponent} from "./text-editor.component";
//import {SelectEditorComponent} from "./select-editor.component";
import {Record} from "./record";
declare var $:any;
import { MatDialog } from '@angular/material/dialog';
import {MyapplicationsInputComponent} from "./myapplications-input/myapplications-input.component";
import {MyapplicationsAddComponent} from "./myapplications-add/myapplications-add.component";
import {UserpreferenceService} from "../shared/userpreference/userpreference.service";
import {Userpreference} from "../shared/userpreference/userpreference";


@Component({
  selector: 'app-myapplications',
  templateUrl: './myapplications.component.html',
  styleUrls: ['./myapplications.component.css']
})
export class MyapplicationsComponent implements OnInit {
  data;
  private params: any;
  public value: any;
  private databaseRecord:string;
  private columnName: any;
  theme;
  paginationSize;
  public gridOptions: GridOptions;
  currentStatus;
  loading: boolean = false;
  record: Record[] = [];
  paginationFlag: boolean = false;
  private gridApi;
  statuses = [
    {name:"No Status Selected", value:""},
    {name:"Unable to Contact", value:"Unable to Contact"},
    {name:"Contact 1", value: "Contact 1"},
    {name:"Contact 2", value: "Contact 2"},
    {name:"NI/NQ", value: "NI/NQ"},
    {name:"Appoitment Set", value: "Appoitment Set"},
    {name: "Appoitment Kept", value: "Appoitment Kept"},
    {name: "Sold", value: "Sold"},
    {name: "In Fullfillment", value: "In Fullfillment"},
    {name: "Duplicate", value: "Duplicate"},
    {name: "No Show", value: "No Show"}
  ];

  columnDefs = [
    {headerName: "Affiliate ID", field: "affiliate_id", pinned: 'left',width: 100, editable: true, cellStyle: this.changeRowColor},
    {
      headerName: "First Name",
      field: "firstname",
      width: 100,
      editable: true,
      pinned: 'left',
      cellStyle: this.changeRowColor,
      sortable: true, filter:true, resizable:true
    },
    {headerName: "Last Name", field: "lastname", width: 100, pinned: 'left',editable: true,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Email", field: "email", width: 100, pinned: 'left',editable: true, cellStyle: this.changeRowColor, tooltipField:'email',sortable: true, filter:true, resizable:true},
    {headerName: "Phone", field: "phone", width: 100, editable: true, pinned: 'left',cellStyle: this.changeRowColor, tooltipField:"phone",sortable: true, filter:true, resizable:true},
    {headerName: "Domain", field: "domain", width: 100, editable: true, pinned: 'left',cellStyle: this.changeRowColor, tooltipField:"domain",sortable: true, filter:true, resizable:true},
    {
      headerName: "Appt Time",
      field: "meetingtime",
      editable: false,
      tooltipField: 'meetingtime',
      cellStyle: this.changeRowColor,
      sortable: true, filter:true, resizable:true
    },
    {headerName: "Timezone", field: "timezone",  editable: false,  tooltipField:'timezone' , cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "City", field: "city",  editable: false,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "State", field: "state",  editable: false,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Country", field: "country", editable: false,  cellStyle: this.changeRowColor},
    {headerName: "How Long", field: "howlong",  editable: false,  cellStyle: this.changeRowColor, tooltipField:'howlong',sortable: true, filter:true, resizable:true},
    {headerName: "About", field: "about",  editable: false,  cellStyle: this.changeRowColor, tooltipField:'about',sortable: true, filter:true, resizable:true},
    {headerName: "Tracking Id", field: "tracking",  editable: false, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Status", field: "status",  editable: false,  cellEditorParams: {
      values: ['Contact 1', 'Contact 2', 'Unable to Contact', 'NI/NQ', 'Appoitment Set', 'Appoitment Kept',  'Sold', 'In Fullfillment','Duplicate','No Show']
    }, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "created_at", field: "created_at",  cellStyle: this.changeRowColor, sort: 'desc', cellRenderer: this.renderDate,sortable: true, filter:true, resizable:true},
    {headerName: "updated_at", field: "updated_at",  cellStyle: this.changeRowColor, cellRenderer: this.renderDate,sortable: true, filter:true, resizable:true},
   // {headerName: "Notes", field: "notes", width: 100, cellStyle: this.changeRowColor, editable: false},
    {headerName: "Actions", width: 100, cellStyle: this.changeRowColorForButton, sortable: true, filter:true, resizable:true,template: `<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
    <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
         </button>
     `},
  ];
  constructor(private myapplicationsService: MyapplicationsService,
              private _service: ToastService,
              public dialog: MatDialog,
              private userpreferenceService:UserpreferenceService) {
    this.theme="ag-theme-dave";
    this.paginationSize="50";
    const userpreference = new Userpreference(null, "myapplications_pagination", null, null);
    this.userpreferenceService.getKey(userpreference)
      .subscribe(
        data => {
         // console.log(data['obj'].value);
          this.paginationFlag = data['obj'].value == "true";
          this.gridOptions.pagination = true;
          this.gridOptions.api.refreshView();
        },
        error => {
         // this._service.error(error.error['message'], error['title']);
        }
      );

    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      floatingFilter: false,
      onRowEditingStarted: function (event) {
        //console.log(event);
      },
      onCellEditingStopped: function (params) {
        /*this.params = params;
        this.value = this.params.value;
        this.databaseRecord=this.params.node.data.id;
        this.columnName = this.params.column.colDef.field;
        const record = new Record(this.columnName, this.value, this.databaseRecord)
        console.log(record);
        this.loading=true;
        myapplicationsService.updateField(record)
          .subscribe(
            data => {
              _service.success(data['title'], data['message']);
              params.api.recomputeAggregates();
              params.api.refreshView();
              this.loading=false;
            },
            error => {
              _service.error('Error', error)
              this.loading=false;
            }
          ),
          error => {
            _service.error('Error', error);
            this.loading=false;
          }
        return this.value;
        */
      }
    };
    this.loading=true;



    


  }

  getStatusLeads(){
    console.log(this.currentStatus);
    localStorage.setItem('myapplicationsLeadStatusFlag', this.currentStatus);
    if (this.currentStatus){
    this.myapplicationsService.getallleadsbystatus(this.currentStatus)
      .subscribe(
        data => {
          this.data = data['rows'];
          console.log(this.data);
         
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
          this.loading=false;
        }
      );
    } else {
      localStorage.removeItem('myapplicationsLeadStatusFlag');
      this.myapplicationsService.getleads()
        .subscribe(
          data => {
            this.data = data['rows'];
            console.log(this.data);
           // this._service.success(data['title'], data['message'])
            this.gridOptions.api.setRowData(this.data);
            this.loading=false;
          },
          error => {
           // this._service.error(error.error['message'], error['title']);
            this.loading=false;
          }
        );
    }
  }

  setStatus(){
    var statusFilterComponent = this.gridApi.getFilterInstance("status");
    statusFilterComponent.selectNothing();
    statusFilterComponent.selectValue("Ireland");
    statusFilterComponent.selectValue("Great Britain");
    this.gridApi.onFilterChanged();
    
  }

  public checkPaginationFlag(event){
    const userpreference = new Userpreference(null, "myapplications_pagination", String(this.paginationFlag), null);
    this.userpreferenceService.updateKey(userpreference)
      .subscribe(
        data => {
          this.paginationFlag = data['obj'].value == "true";
          this.gridOptions.api.refreshView();
          this.gridOptions.pagination = true;

        },
          error => {
            //this._service.error(error.error['message'], error['title']);
          }
      );

  }

  addComments(event){
    console.log(event);
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data, event);
        case "remove":
          return this.onActionRemoveClick(data);
      }
    }
  }

  public onActionViewClick(data: any, event){
    console.log("View action clicked", event);
    let dialogRef = this.dialog.open(MyapplicationsInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.myapplicationsService.editLead(data);
    this.myapplicationsService.editLead(data);

    console.log(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log('result: ' + result);
      dialogRef = null;
      //this.refreshOne(null,event);
      this.refresh();
    });


  }

  public onActionRemoveClick(data: any){
    console.log("Remove action clicked", data);
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  changeRowColor(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red', 'color':'#ffffff'};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474', 'color':'#ffffff'};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3', 'color':'#000000'};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac', 'color':'#ffffff'};
      }
      case 'Sold': {
        return {'background-color': '#87c947', 'color':'#000000'};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513', 'color':'#ffffff'};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B', 'color':'#ffffff'};
      }
      case 'No Show': {
        return {'background-color': '#F88F09', 'color':'#ffffff'};
      }
    }
  }

  changeRowColorForButton(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red'};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow'};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow'};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474'};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3'};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac'};
      }
      case 'Sold': {
        return {'background-color': '#87c947'};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513'};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B'};
      }
      case 'No Show': {
        return {'background-color': '#F88F09'};
      }
    }
  }

  onGridReady(event){
    this.gridApi = event.api;
    var myapplicationsLeadStatusFlag = localStorage.getItem('myapplicationsLeadStatusFlag') ? localStorage.getItem('myapplicationsLeadStatusFlag') : '';

    if (myapplicationsLeadStatusFlag){
      this.currentStatus=myapplicationsLeadStatusFlag;
      this.myapplicationsService.getallleadsbystatus(myapplicationsLeadStatusFlag)
      .subscribe(
        data => {
          this.data = data['rows'];
         // console.log(this.data);
         // this._service.success(data['title'], data['message'])
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
          this.loading=false;
        }
      );
    } else {
      this.myapplicationsService.getleads()
        .subscribe(
          data => {
            this.data = data['rows'];
           // console.log(this.data);
          //  this._service.success(data['title'], data['message'])
            this.gridOptions.api.setRowData(this.data);
            this.loading=false;
          },
          error => {
            this._service.error(error.error['message'], error['title']);
            this.loading=false;
          }
        );
    }
  }

  refreshOne(id: number, event){
    console.log('id');

    /*this.myapplicationsService.getleads()
      .subscribe(
        data => {
          this.data = data['rows'];
          console.log(this.data);
          this._service.success(data['title'], data['message'])
          this.gridOptions.api.setRowData(this.data);
        },
        error => this._service.error(error.error['message'], error['title']),
      ); */
  }

  refresh(){
    var myapplicationsLeadStatusFlag = localStorage.getItem('myapplicationsLeadStatusFlag') ? localStorage.getItem('myapplicationsLeadStatusFlag') : '';
    if (myapplicationsLeadStatusFlag){
      this.currentStatus=myapplicationsLeadStatusFlag;
      this.myapplicationsService.getallleadsbystatus(myapplicationsLeadStatusFlag)
      .subscribe(
        data => {
          this.data = data['rows'];
          this._service.success(data['title'], data['message'])
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
          this.loading=false;
        }
      );
    } else {
      this.myapplicationsService.getleads()
        .subscribe(
          data => {
            this.data = data['rows'];
            this._service.success(data['title'], data['message'])
            this.gridOptions.api.setRowData(this.data);
            this.loading=false;
          },
          error => this._service.error(error.error['message'], error['title']),
        );
    }
  }

  ngOnInit() {

  }

  addRecord(){
    let dialogRef = this.dialog.open(MyapplicationsAddComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

}
