import {Injectable, EventEmitter} from '@angular/core';
import {Products} from "./products";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ProductsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  products: Products[] = [];
  requestIsEdit = new EventEmitter<Products>();
  constructor(private http: HttpClient) { }

  editProducts(products: Products) {
    console.log(products)
    this.requestIsEdit.emit(products);
  }

  addRecord(products: Products) {
    const body = JSON.stringify(products);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/products/addrecord' + token, body, {headers: headers})
     
  }

  deleteRecord(products: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/products/deleterecord/' + products._id + token, {headers: headers})
      
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/products/getallrecords'+token,  {headers: headers})
     
  }

  getRecordsByProductlineRecommeded(products: any) {
    const body = JSON.stringify(products);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/products/getrecordsbyproductlinerecommended'+token, body, {headers: headers})  
  }

  getAllRecordsByProductlineRecommeded(products: any) {
    const body = JSON.stringify(products);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/products/getallrecordsbyproductlinerecommended'+token, body, {headers: headers}) 
  }

  updateRecord(products: Products){
    const body = JSON.stringify(products);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/products/updaterecord/' + products.productId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/products/getrecordl/'+id+token,  {headers: headers})
     
  }

}
