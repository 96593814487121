import { Component, OnInit } from '@angular/core';
import {User} from "../user";
import { ToastService } from 'ng-uikit-pro-standard';
import {Router, ActivatedRoute} from "@angular/router";
import {AuthService} from "../auth.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {
  user: any;
  showAds=this.isFreeMember();
  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private _service: ToastService
  ) { }

  onSubmit(form: NgForm) {
    const user = new User(null, form.value.username, form.value.password, null,null,null,null,null,null,null,null,null,null,null,null, null, null );
    console.log(user);
    this.authService.changePassword(user)
      .subscribe(
        data => this._service.success(data['title'], data['message']),
        error => this._service.error('Error', error)
      ),
      error => this._service.error('Error', error);
  }

  ngOnInit() {
    this.authService.getUser()
      .subscribe(
        user => {
          this.user = user['obj'];
         // this.authService.user = user;
          console.log(user);
          user['password']='';
        },
        error => this._service.error(error.error['message'], error['title'])
      );
  }
  cancel(){
    this.router.navigateByUrl('/members/account');
  }

  isFreeMember() {
    return this.authService.isFreeMember();
  }




}
