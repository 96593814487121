import {Injectable, EventEmitter} from '@angular/core';
import {Funnels} from "./funnels";

import { Observable } from "rxjs";
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class FunnelsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  funnels: Funnels[] = [];
  funnelsIsEdit = new EventEmitter<Funnels>();
  constructor(private http: HttpClient) { }

  editFunnels(funnels: Funnels) {
    this.funnelsIsEdit.emit(funnels);
  }



  addRecord(funnels: Funnels) {
    const body = JSON.stringify(funnels);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/funnels/addrecord' + token, body, {headers: headers})
      
  }

  addRecordForSomeoneElse(funnels: Funnels) {
    const body = JSON.stringify(funnels);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/funnels/addrecordforsomeoneelse' + token, body, {headers: headers})
      
  }

  createUserFunnelsForSomeONeElse(data: any) {
    const body = JSON.stringify(data);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/funnels/createuserfunnelsforsomeoneelse' + token, body, {headers: headers})
      
  }

  deleteRecord(funnels: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/funnels/deleterecord/' + funnels._id + token, {headers: headers})
    
  }

  deleteInstantFunnels(data: any) {
    const body = JSON.stringify(data);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/funnels/deleteinstantfunnels' + token, body, {headers: headers})
      
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/funnels/getallrecords'+token,  {headers: headers})
     
  }

  getUserRecordsByTypeAndCategory(funnels:any) {
    const body = JSON.stringify(funnels);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/funnels/getuserrecordsbytypeandcategory'+token, body, {headers: headers})
     
  }

  getMyRecords() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/funnels/getmyrecords'+token,  {headers: headers})
  }

  getAllRecordsSubtypes(subtype) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/funnels/getallrecordssubtypes/'+subtype+token,  {headers: headers})
      
  }

  

  updateRecord(funnels: Funnels){
    const body = JSON.stringify(funnels);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/funnels/updaterecord/' + funnels.funnelsId + token, body, {headers: headers})
      

  }

  getRecord(id) {
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/funnels/getrecordl/'+id+token,  {headers: headers})
     
  }

  getRecordV2(id) {
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.get(this.hostName +'/api2/funnels/getrecord/'+id,  {headers: headers})
     
  }

  updateFunnelOrder(payload) {
    let body = JSON.stringify(payload)
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post(this.hostName +'/api2/funnels/updatefunnelorder', body, {headers: headers})
     
  }

}
