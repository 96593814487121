import { Component, OnInit, Renderer2, Inject, ViewChild } from '@angular/core';
import { SidenavComponent, ToastService } from 'ng-uikit-pro-standard';
import { CommService } from '../../../../services/comm.service';
import { AuthService } from '../../auth/auth.service';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent
  navShown=true;
  public options = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 0,
    maxStack: 7,
    showProgressBar: true,
    pauseOnHover: true,
    preventDuplicates: false,
    preventLastDuplicates: 'visible',
    rtl: false,
    animate: 'fromRight',
    position: ['right', 'bottom']

  }

  showClasses() {

  }

  toggleSideNav(event){
   
    this.sidenav.toggle();
    let myShown = this.sidenav;
   
    if (myShown.isShown==true){
      this.navShown=false;
    } else if (myShown.isShown==false) {
      this.navShown=true;
    }
  }

  logo: string;
  isAdmin=false;
  isSuper=false;
  backgroundColor: string;
  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private systempreferenceService: SystempreferenceService, public commService:CommService, private authService:AuthService,  private _service: ToastService) {
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    
    this.checkSuper(['super']);
    this.checkAdmin(['admin']);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
         
          var myJson = JSON.parse(systemPref['obj'].value);
        
          this.backgroundColor=myJson.sideNavBackgroundColor;
          this.logo="assets/images/LOGO-4.png";//myJson.logo;
          
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );
   }

   checkSuper(roles){
   
     this.authService.has1Role(JSON.stringify({roles:roles}))
     .subscribe(
      data => {
       
        if (data['success']==true)
          this.isSuper = true;
        else 
          this.isSuper = false
       
      },
      error => this._service.error(error['message'], error['title']),

    ) 
  }

  checkAdmin(roles){
    
     this.authService.has1Role(JSON.stringify({roles:roles}))
     .subscribe(
      data => {
      
        if (data['success']==true)
          this.isAdmin = true;
        else 
          this.isAdmin = false
       
      },
      error => this._service.error(error['message'], error['title']),

    ) 
  }

  ngOnInit() {
    const s = this.renderer2.createElement('script');
    // s.type = 'text/javascript';
     s.src = 'https://static.zdassets.com/ekr/snippet.js?key=27f89c26-e38e-44e8-9181-708c8b1392a8';
      s.id='ze-snippet'
     this.renderer2.appendChild(this._document.body, s);
  
     const y = this.renderer2.createElement('script');
     y.innerHTML=`var myName="dave Sloan is great"`;
     this.renderer2.appendChild(this._document.body, y);
  
     const t = this.renderer2.createElement('script');
     t.src='https://www.12minuteaffiliate.com/jsfile/zendesk';
     t.id="zendesk_js"
     this.renderer2.appendChild(this._document.body, t);
  }

}
