import { Component, OnInit } from '@angular/core';
import { CommService } from '../../../services/comm.service';
import { Systempreference } from '../shared/systempreference/systempreference';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';

@Component({
  selector: 'app-doneforyoutraffic',
  templateUrl: './doneforyoutraffic.component.html',
  styleUrls: ['./doneforyoutraffic.component.css']
})
export class DoneforyoutrafficComponent implements OnInit {
  couponValid:string;
  couponText: string;
  constructor(private commService: CommService, private systempreferenceService: SystempreferenceService) { }

  openDrawer(drawerContent: string){
    this.commService.guarantee.toggle();
    console.log(drawerContent);
    this.commService.changeMessage(drawerContent);
  }

  ngOnInit() {
    const systempreference = new Systempreference(null, "traffic_coupon_valid", null, null);
    console.log(systempreference);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        data => {
          console.log(data['obj'].value);
          var myJson=JSON.parse(data['obj'].value);
          console.log(myJson);
          this.couponValid = myJson.valid;
          this.couponText = myJson.text;
        },
        error => {
          //this._service.error(error.error['message'], error['title']);
        }
      );
  }

}
