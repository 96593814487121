

<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <button mat-flat-button color="primary" mat-dialog-close>Cancel</button>&nbsp;&nbsp;<button type="submit" [disabled]="!f.valid" mat-flat-button color="primary">Save</button>
  <br><br>  
  <mat-dialog-content>
      <input type="hidden" name="educationId" #educationId="ngModel" [ngModel]="education?._id" id="educationId">
      <mat-form-field class="example-full-width">
        <input matInput name="title" #title="ngModel" [ngModel]="education?.title" type="text" id="title" placeholder="Enter the Name or Title" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput name="video" #video="ngModel" [ngModel]="education?.video" type="text" id="video" placeholder="Enter the video code" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-form-field">
        <input matInput name="thumb" #thumb="ngModel" [ngModel]="education?.thumb" type="text" id="thumb" placeholder="Enter the url of the thumbnail image" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>&nbsp;&nbsp;
      <button style="" type="button" mat-flat-button color="primary" (click)="addImage()">Media Manager</button>
      <mat-form-field class="example-full-width">
        <input matInput name="domain" #domain="ngModel" [ngModel]="education?.domain" type="text" id="domain" placeholder="Enter the Domain for this website" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput name="length" #length="ngModel" [ngModel]="education?.length" type="text" id="length" placeholder="Enter the Length of the Video">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      
      <label for="category">Enter the Category for this video</label>
      <select #category="ngModel" id="category" [ngModel]='education?.category' name="category" class="browser-default custom-select" required>
        <option value="Team Training" >Team Training</option>
        <option value="How-To">How-To</option>
      </select>
      <br><br>
      <mat-form-field class="example-full-width">
        <input matInput name="body" #body="ngModel" [ngModel]="education?.body" type="text" id="body" placeholder="Enter the any other infor for this video">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      
        <mat-checkbox name="sticky" #sticky="ngModel" [ngModel]="education?.sticky" type="text" id="sticky" placeholder="Sticky?">Sticky?</mat-checkbox>
      
      <br><br>
  
  
    </mat-dialog-content>
    
  </form>
  