import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {DomainsService} from "../domains.service";
import {Domains} from "../domains";
import {NgForm} from "@angular/forms";
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { CommService } from '../../../../services/comm.service';
import { TemplatesService } from '../../templates/templates.service';
import { TemplatesInputComponent } from '../../templates/templates-input/templates-input.component';
import { MatDialog } from '@angular/material/dialog';
import { TypesService } from '../../types/types.service';
import { CategoriesService } from '../../categories/categories.service';
import { SubtypesService } from '../../subtypes/subtypes.service';
import { ConfirmService } from '../../shared/confirm/confirm.service';
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import { ThrowStmt } from '@angular/compiler';

interface Niche {
  value: string;
  viewValue: string;
  isChecked:boolean
}

@Component({
  selector: 'app-domains-input',
  templateUrl: './domains-input.component.html',
  styleUrls: ['./domains-input.component.css'],
})
export class DomainsInputComponent implements OnInit {
  domains:Domains = null;
step:String=null;
disableDomain:boolean=false;
showDefaultRegistrar=false;
level;
niches: Niche[] = [];
selected;
templates;
nullValue = null;
categories;
subtypes;
types;
templateSelected;
showTemplateLoader=true;
selectedNiches=[];
myDomains;
data;
confirmPressed;
addOrEdit="add";
creatHomePage=false;
createHomePageChecked:boolean=false;
hasTemplate:boolean=false;

  constructor(private confirmService: ConfirmService, private subtypesService: SubtypesService, private typesService: TypesService, private categoriesService: CategoriesService, public dialog: MatDialog,private templatesService: TemplatesService, private commService: CommService, private notificationService: NotificationsService,private domainsService:DomainsService, private authService: AuthService) {
    var userRecord = JSON.parse(localStorage.getItem('userRecord'));
    this.level=userRecord['level'];

    if (this.level.includes('gold')){
      this.niches = [
      {value: 'hb', viewValue: 'Home Business', isChecked: false},
      {value: 'wl', viewValue: 'Weight Loss', isChecked: false},
      {value: 'pd', viewValue: 'Personal Development', isChecked: false}
      ];
    } else if (this.level=="basic|Home Business" || this.level=="basic-lifetime|Home Business"){
      this.niches = [
        {value: 'hb', viewValue: 'Home Business', isChecked: false},
      ];
    } else if (this.level=="basic|Weight Loss"||this.level=="basic-lifetime|Weight Loss"){
      this.niches = [
        {value: 'wl', viewValue: 'Weight Loss', isChecked: false},
      ];
    } else if (this.level=="basic|Personal Development"||this.level=="basic-lifetime|Personal Development"){
      this.niches = [
        {value: 'pd', viewValue: 'Personal Development', isChecked: false}
      ];
    }

    
    domainsService.domainsIsEdit.subscribe(
      domains => {
        this.addOrEdit="edit";
        this.domains = domains;
        console.log(this.templateSelected, this.domains.template)
        this.templateSelected = this.domains.template;
        console.log(this.templateSelected, this.domains.template)
        if (this.templateSelected){
          this.hasTemplate=true;
        } else {
          this.hasTemplate=false;
        }

        this.disableDomain=true;
        this.showDefaultRegistrar=true;
        
        
        var myDomains = domains.niche.replace('[','');
        
        myDomains = myDomains.replace(']','');
        
        myDomains = myDomains.replace(/"/g, '');
        
        let myTmpDomains=myDomains.split(",").filter(x => x)
        
        this.selectedNiches=myTmpDomains;
        
        this.niches = [
          {value: 'hb', viewValue: 'Home Business', isChecked: false},
          {value: 'wl', viewValue: 'Weight Loss', isChecked: false},
          {value: 'pd', viewValue: 'Personal Development', isChecked: false}
        ];
        for (var i=0;i<this.niches.length;i++){
          myTmpDomains.forEach(domain => {
            var tmpDomain=domain.split('|');
            if (tmpDomain[0]==this.niches[i].value){
              this.niches[i]={value: tmpDomain[0], viewValue: tmpDomain[1], isChecked: true}
            }
          })
        }
      })
      
      this.domainsService.getAllMyRecords()
      .subscribe(
        data1 => {
          
          this.myDomains = data1['obj'];
        },
        error => {},
      );



  }

  public clickHomePage($event){
    console.log(this.selectedNiches, this.selectedNiches.length);
    if (this.selectedNiches.length < 1){
      this.notificationService.updateMessage("Error","We cannot create a homepage because no niche has been assigned",'warn', 'warning', 5000,function(){});
      $event.preventDefault();
    }
  }

  public changeHomePage($event){
    console.log($event);
    this.createHomePageChecked=$event.checked
    
  }

  public createHomepageClick(){
    this.showTemplateLoader=true;
    this.onActionCreateHomepageClick(this.domains, (data) => {
      this.getAllMyTemplates(()=>{ 
          this.domains.template=data['obj'];
          this.showTemplateLoader=false;
          this.hasTemplate=true;
          this.templateSelected=this.domains.template
      });
    });
  }

  public onActionCreateHomepageClick(data: any, cb){
    console.log(data);
    this.domainsService.createHomepage(data)
    .subscribe(
     myData => {
        this.data = myData;
        this.notificationService.updateMessage("Success","Looks like your domain has been setup with a homepage",'success', 'check_circle',  5000,function(){})
        cb(myData);
      },
      error => {
        console.log("error:",error);
        this.notificationService.updateMessage(error.error['title'],error.error['message']+" "+error.error['error'],'warn', 'warning', 5000,function(){});
        cb('');
      }
    );
  
  }

  clickNiche($event, value, viewValue, isChecked){
    if (this.myDomains==undefined) return;
    if (isChecked==true) return;
    for (var i = 0; i < this.myDomains.length; i++) {
      var niche=this.myDomains[i].niche;
      if (niche){
         if (niche.includes(value)){
            this.notificationService.updateMessage("Error","You  already have a domain associated with the " +viewValue+" niche.",'warn', 'warning',  5000,function(){})
            return $event.checked=false;
          }
      }
    }
  }

  getNiche($event, viewValue){
    
    if ($event.checked==true){
      this.selectedNiches.push($event.source.value+"|"+viewValue)
    } else if ($event.checked==false){
      
      var filteredAry = this.selectedNiches.filter(e => e !== $event.source.value+"|"+viewValue)
      this.selectedNiches=filteredAry;
    }
    
    if (this.selectedNiches.length<1) this.creatHomePage=false;
  }

  templateSelected1($event){
    console.log($event);
    this.templateSelected=$event.value;
    if ($event.value==null){
      this.hasTemplate=false
    } else {
      this.hasTemplate=true;
    }
    
  }

  registrarSelected1($event){
    console.log($event.value)
    if ($event.value=="godaddy"){
      this.showDefaultRegistrar=true;
    } else {
      this.showDefaultRegistrar=false;
    }
  }

  nicheSelected1($event){
    console.log($event.value)
    if ($event.value=="godaddy"){
      this.showDefaultRegistrar=true;
    } else {
      this.showDefaultRegistrar=false;
    }
  }

  save(f:NgForm, model: Domains, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
   
    model.niche=JSON.stringify(this.selectedNiches);
    if (model.domainsId){

      this.domainsService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            if (this.createHomePageChecked==true){
              if (data['obj'].niche.length>5){
                console.log(data['obj'].niche)
                this.onActionCreateHomepageClick(data['obj'], (data) =>{})
              }
            }
            this.notificationService.updateMessage(data['title'],data['message'],'success', 'check_circle',  5000,function(){})
          },
          error => this.notificationService.updateMessage(error['title'],error['message'],'warn', 'warning',  5000,function(){})

        )
      console.log(model, isValid);
    } else {
      this.commService.goDaddyCall(model,this,function(returnMessage, thisArg, returnData){
        if (thisArg.createHomePageChecked==true){
          if (returnData.record.niche.length>5){
            console.log(returnData.record.niche)
            thisArg.onActionCreateHomepageClick(returnData.record, (data) =>{})
          }
        }
        f.reset();
        thisArg.notificationService.updateMessage(returnMessage['title'],returnMessage['message'],'success', 'check_circle',  5000,function(){})
      })

    }
  }

  public extractHostname(url) {
    var hostname;
  
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }
  
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    var tmpHostname = hostname.split('.');
    
    hostname = tmpHostname[tmpHostname.length-2]+'.'+tmpHostname[tmpHostname.length-1];
    return hostname;
  }

  ngOnInit() {
    this.getAllMyTemplates(()=>{});
  }

  getAllMyTemplates(cb){
    console.log('dave sloan')
    this.templatesService.getAllMyRecords()
      .subscribe(
        data => {
          this.templates = data['obj'];
          this.showTemplateLoader=false;
          cb();
        },
      );

  }

  editTemplate(){
    console.log(this.hasTemplate);
    this.templatesService.getRecord(this.templateSelected)
    .subscribe(
      data => {
       var myData=data['obj'];
       let dialogRef = this.dialog.open(TemplatesInputComponent, {
      
        width: '95%',
        height: '95vh'
  
      });
      //dialogRef.componentInstance.lead = this.crmService.editLead(data);
     
      this.templatesService.editTemplates(myData);
      this.templatesService.setType(this.types);
      this.templatesService.setSubType(this.subtypes);
      this.templatesService.setCategory(this.categories);

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
      });
  
      },
       error => {
         console.log(error);
         this.notificationService.updateMessage(error.error['title'],error.error['message'],'warn', 'warning',  5000,function(){})
       }
    );

    
  }

  ngAfterViewInit() {
    this.categoriesService.getAllRecords()
    .subscribe(
      data => {
        this.categories = data['obj'];
      }
    );

    this.typesService.getAllRecords()
      .subscribe(
        data1 => {
          this.types = data1['obj'];
          //console.log(this.types);
          
        },
        error => {
         
        },
      );

    this.subtypesService.getAllRecords()
    .subscribe(
      data2 => {
        this.subtypes = data2['obj'];
        console.log(this.subtypes);
      },
      error => {
        
      },
    );
  }

}
