<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="funnelsId" #funnelsId="ngModel" [ngModel]="funnels?._id" id="funnelsId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="funnels?.name" type="text" id="name" placeholder="Enter the Name" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="wid" #wid="ngModel" [ngModel]="funnels?.wid" type="text" id="wid" placeholder="Enter the WID or funnel number" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="domain" #domain="ngModel" [ngModel]="funnels?.domain" type="text" id="domain" placeholder="Enter the domain">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="username" #username="ngModel" [ngModel]="funnels?.username" type="text" id="username" placeholder="Enter the username">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="affiliate_link" #affiliate_link="ngModel" [ngModel]="funnels?.affiliate_link" type="text" id="affiliate_link" placeholder="Enter the affiliate link">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="image" #image="ngModel" [ngModel]="funnels?.image" type="text" id="image" placeholder="optin image">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <textarea matInput name="tracking" #tracking="ngModel" [(ngModel)]="preBuilt" type="text" id="tracking" placeholder="Enter Your Tracking Codes"></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select name="category" #category [ngModel]="funnels?.category"  id="category" placeholder="Choose a Category"  floatPlaceholder="auto" class="example-full-width" ngDefaultControl>
        <mat-option  value="none">None</mat-option>
        <mat-option  *ngFor="let category of categories" value="{{category._id}}">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field class="example-full-width">
      <mat-select name="type" #type [ngModel]="funnels?.type"  id="type" placeholder="Choose a type for the page"  floatPlaceholder="auto"  ngDefaultControl>
        <mat-option  value="none">None</mat-option>
        <mat-option  *ngFor="let type of types" value="{{type._id}}">{{type.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field class="example-full-width">
      <mat-select  name="subtype" #subtype [ngModel]="funnels?.subtype"  id="subtype" placeholder="Choose a subtype for the page"  floatPlaceholder="auto"  ngDefaultControl>
        <mat-option  selected value="none">None</mat-option>
        <mat-option  *ngFor="let subtype of subtypes" value="{{subtype._id}}">{{subtype.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-checkbox name="use_optin" #use_optin [ngModel]="funnels?.use_optin" id="use_optin" checked="true">Use Optin Page</mat-checkbox>

    <mat-form-field class="example-full-width">
        
      <mat-select name="optin" #optin [ngModel]="funnels?.optin"  id="optin" placeholder="Choose a page for the Optin"  floatPlaceholder="auto"  ngDefaultControl>
        <mat-option  selected value="undefined">None</mat-option>
        <mat-option  *ngFor="let optin of optins" value="{{optin._id}}">{{optin.name}}</mat-option>
      </mat-select>
  </mat-form-field>

    
    <mat-form-field class="example-full-width">
        
        <mat-select name="fe" #fe [ngModel]="funnels?.fe"  id="fe" placeholder="Choose a page for the Frontend"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  selected value="">None</mat-option>
          <mat-option  *ngFor="let fe of fes" value="{{fe._id}}">{{fe.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="checkout" #checkout [ngModel]="funnels?.checkout"  id="checkout" placeholder="Choose a page for the Checkout"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let checkout of checkouts" value="{{checkout._id}}">{{checkout.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select name="checkout2" #checkout2 [ngModel]="funnels?.checkout2"  id="checkout2" placeholder="Choose a page for the Checkout2"  floatPlaceholder="auto"  ngDefaultControl>
        <mat-option  *ngFor="let checkout2 of checkout2s" value="{{checkout2._id}}">{{checkout2.name}}</mat-option>
      </mat-select>
  </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="oto1" #oto1 [ngModel]="funnels?.oto1"  id="oto1" placeholder="Choose a page for the Oto1"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let oto1 of oto1s" value="{{oto1._id}}">{{oto1.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="oto2" #oto2 [ngModel]="funnels?.oto2"  id="oto2" placeholder="Choose a page for the Oto2"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let oto2 of oto2s" value="{{oto2._id}}">{{oto2.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="download" #download [ngModel]="funnels?.download"  id="download" placeholder="Choose a page for the Download"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let download of downloads" value="{{download._id}}">{{download.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select name="download2" #download2 [ngModel]="funnels?.download2"  id="download2" placeholder="Choose a page for the Download2"  floatPlaceholder="auto"  ngDefaultControl>
        <mat-option  *ngFor="let download2 of download2s" value="{{download2._id}}">{{download2.name}}</mat-option>
      </mat-select>
  </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="oto1_download" #oto1_download [ngModel]="funnels?.oto1_download"  id="oto1_download" placeholder="Choose a page for the Oto1 Download"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let oto1_download of oto1_downloads" value="{{oto1_download._id}}">{{oto1_download.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="oto2_download" #oto2_download [ngModel]="funnels?.oto2_download"  id="oto2_download" placeholder="Choose a page for the Oto2 Download"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let oto2_download of oto2_downloads" value="{{oto2_download._id}}">{{oto2_download.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="thank_you" #thank_you [ngModel]="funnels?.thank_you"  id="thank_you" placeholder="Choose a page for the thank You Page"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let thank_you of thank_yous" value="{{thank_you._id}}">{{thank_you.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-select name="marketing" #marketing [ngModel]="funnels?.marketing"  id="thank_you" placeholder="Choose a page for the marketing Description"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let marketing of marketings" value="{{marketing._id}}">{{marketing.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-flat-button  color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>&nbsp;Save
      </button>&nbsp;
      <button mat-flat-button color="primary" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>&nbsp;Cancel
      </button>
    </div>
  </mat-dialog-actions>
</form>


<app-notifications></app-notifications>