
<p align="center" class="ral-48blue">Done-For You Traffic</p>

<div class="videoWrapper" style="display:none">
 
        <iframe   src="https://www.youtube.com/embed/LLbQmOhRaJs" frameborder="0" allowfullscreen></iframe>
    

</div>
<br><br>
<p class="normal">
Getting traffic to your Easiest System Ever business is LITERALLY easier than ordering a pizza.
</p>
<p class="normal">
We've partnered up with some of the top traffic providers on the entire internet. Our traffic providers ONLY deal with what's known as TOP-TIER traffic (traffic from English speaking countries, like the USA, Canada, England, & Australia). This traffic is guaranteed to be real! 
</p>
<p class="normal">
One of the biggest mistakes people make is to buy cheap/fake traffic. If you do this, you will get ZERO results. That's why we offer to take care of getting the traffic for you! 
</p>
<p class="normal">
<strong>Here's all you have to do:</strong>
</p>
<p class="normal">
1.) Choose one of the traffic options below (based on your budget). Remember, the more traffic you get, the more people get on your email list, and the more likely you are to make sales! 
</p>
<p class="normal">
2.) After your order is placed, sign the receipt we send you which authorizes your purchase. 
</p>
<p class="normal">
3.) Sit back and relax! We'll take your order to the traffic suppliers and place it FOR YOU, so that you won't have to worry about anything. 
</p>


<br>


<div style="width:100%;position:relative;padding:25px;border:dashed 1px ;" *ngIf="couponValid=='1'">
  <img
    src="/assets/images/starburst.png"
    style="width:100px;position:absolute;left:-60px;top:-60px;"
  >

  <p class="ral-24blue" style="font-size:40pt;text-align:center;padding-bottom:10px" [innerHTML]="couponText|keepHtml">
    
  </p>

  
</div>

<br>
<br>
<br>
<br>

<div style="display:block;margin:auto;width:760px;">
  <div
    class="btn-sec"
    style="position:relative"
  >
    <img
      src="/assets/images/starburst.png"
      style="width:100px;position:absolute;left:-60px;top:-60px;display:block" *ngIf="couponValid=='1'"
    >

    <p
      align="center"
      class="ral-24blue"
    >TRAFFIC OPTION 1</p>

    <p
      align="center"
      class="ral-20blk"
    >90-100 TOP QUALITY VISITORS</p>

    <p
      align="center"
      class="ral-24red"
    >
      Only

      <s>$150</s>

      99.00 (USD)
    </p>

    <p align="center">
      <a
        (click)="openDrawer('7')"
        target="_blank"
      >
        <img
          src="/assets/images/cart23.png"
          alt=""
          width="290"
          height="98"
          border="0"
        >
      </a>
    </p>

    <p
      align="center"
      class="body-txt"
    >
      I understand that there are NO refunds

      <br>
      once the traffic has been delivered
    </p>
  </div>

  <div
    class="btn-sec"
    style="position:relative"
  >
    <img
      src="/assets/images/starburst.png"
      style="width:100px;position:absolute;left:-60px;top:-60px;display:block" *ngIf="couponValid=='1'"
    >

    <p
      align="center"
      class="ral-24blue"
    >TRAFFIC OPTION 2</p>

    <p
      align="center"
      class="ral-20blk"
    >175-199 TOP QUALITY VISITORS</p>

    <p
      align="center"
      class="ral-24red"
    >
      Only

      <s>$299</s>

      199.00 (USD)
    </p>

    <p align="center">
      <a
        (click)="openDrawer('8')"
        target="_blank"
      >
        <img
          src="/assets/images/cart23.png"
          alt=""
          width="290"
          height="98"
          border="0"
        >
      </a>
    </p>

    <p
      align="center"
      class="body-txt"
    >
      I understand that there are NO refunds

      <br>
      once the traffic has been delivered
    </p>
  </div>

  <div
    class="btn-sec"
    style="position:relative"
  >
    <img
      src="/assets/images/starburst.png"
      style="width:100px;position:absolute;left:-60px;top:-60px;display:block" *ngIf="couponValid=='1'"
    >

    <p
      align="center"
      class="ral-24blue"
    >TRAFFIC OPTION 3</p>

    <p
      align="center"
      class="ral-20blk"
    >350-399 TOP QUALITY VISITORS</p>

    <p
      align="center"
      class="ral-24red"
    >
      Only

      <s>$599</s>

      397.00 (USD)
    </p>

    <p align="center">
      <a
      (click)="openDrawer('9')"
        target="_blank"
      >
        <img
          src="/assets/images/cart23.png"
          alt=""
          width="290"
          height="98"
          border="0"
        >
      </a>
    </p>

    <p
      align="center"
      class="body-txt"
    >
      I understand that there are NO refunds

      <br>
      once the traffic has been delivered
    </p>
  </div>

  <div
    class="btn-sec"
    style="position:relative"
  >
    <img
      src="/assets/images/starburst.png"
      style="width:100px;position:absolute;left:-60px;top:-60px;display:block" *ngIf="couponValid=='1'"
    >

    <p
      align="center"
      class="ral-24blue"
    >TRAFFIC OPTION 4</p>

    <p
      align="center"
      class="ral-20blk"
    >550-599 TOP QUALITY VISITORS</p>

    <p
      align="center"
      class="ral-24red"
    >
      Only

      <s>$999</s>

      597.00 (USD)
    </p>

    <p align="center">
      <a
      (click)="openDrawer('10')"
        target="_blank"
      >
        <img
          src="/assets/images/cart23.png"
          alt=""
          width="290"
          height="98"
          border="0"
        >
      </a>
    </p>

    <p
      align="center"
      class="body-txt"
    >
      I understand that there are NO refunds

      <br>
      once the traffic has been delivered
    </p>
  </div>

  <div style="height:200px;width:100%;float:left;text-align:center;display:none;">
    <div style="margin:0 auto;width:80%">
      <br>

      <p
        class="ral-24blue"
        style="font-size:20pt;"
      >
        Looking At Traffic Option 5 or 6?
      </p>

      <br>

      <p
        class="ral-24blue"
        style="font-size:20pt;"
      >
        You Should Probably Consider Our

        <a href="/traffic/guaranteedoptins">GUARANTEED OPT-INS Program</a>
      </p>
    </div>
  </div>

  <br>
  <br>
  <br>
  <br>

  <div
    class="btn-sec"
    style="position:relative"
  >
    <img
      src="/assets/images/starburst.png"
      style="width:100px;position:absolute;left:-60px;top:-60px;display:block" *ngIf="couponValid=='1'"
    >

    <p
      align="center"
      class="ral-24blue"
    >TRAFFIC OPTION 5</p>

    <p
      align="center"
      class="ral-20blk"
    >910-997 TOP QUALITY VISITORS</p>

    <p
      align="center"
      class="ral-24red"
    >
      Only

      <s>1497</s>

      997.00 (USD)
    </p>

    <p align="center">
      <a
      (click)="openDrawer('11')"
        target="_blank"
      >
        <img
          src="/assets/images/cart23.png"
          alt=""
          width="290"
          height="98"
          border="0"
        >
      </a>
    </p>

    <p
      align="center"
      class="body-txt"
    >
      I understand that there are NO refunds

      <br>
      once the traffic has been delivered
    </p>
  </div>

  <div
    class="btn-sec"
    style="position:relative"
  >
    <img
      src="/assets/images/starburst.png"
      style="width:100px;position:absolute;left:-60px;top:-60px;display:block" *ngIf="couponValid=='1'"
    >

    <p
      align="center"
      class="ral-24blue"
    >TRAFFIC OPTION 6</p>

    <p
      align="center"
      class="ral-20blk"
    >
      1800-2000 TOP QUALITY VISITORS
    </p>

    <p
      align="center"
      class="ral-24red"
    >
      Only

      <s>$2,997</s>

      1,997.00 (USD)
    </p>

    <p align="center">
      <a
      (click)="openDrawer('12')"
        target="_blank"
      >
        <img
          src="/assets/images/cart23.png"
          alt=""
          width="290"
          height="98"
          border="0"
        >
      </a>
    </p>

    <p
      align="center"
      class="body-txt"
    >
      I understand that there are NO refunds

      <br>
      once the traffic has been delivered
    </p>
  </div>
</div>

<div class="clx"></div>

<br><br>
<div class="redbox">
<p
  align="center"
  class="ral-20blk"
>IMPORTANT - PLEASE NOTE THE FOLLOWING</p>

<p class="normal">
  <strong>1.</strong>

  You;re buying CLICKS (a.k.a. visitors) NOT opt-ins (a.k.a. leads). Buying 100 clicks does not mean that 100 people will opt in to your email list. A "fair" opt in rate is above 20% or 25%.
 
</p>

<p class="normal">
  <strong>2.</strong>

  We can NOT guarantee opt-ins or sales. We can only guarantee that the traffic will be REAL. If we could guarantee that a certain number of people would buy (or opt-in), then we'd gladly make that guarantee. But we cannot guarantee what a real person will do
</p>

<p class="normal">
  <strong>3.</strong>

  Traffic USUALLY starts to be delivered within 14 days. Sometimes it takes longer because our traffic supplier is backed up. But rest assured that we're going to get you your traffic as quickly as possible. 
</p>

<p class="normal">
  <strong>4.</strong>

  Your traffic may start, and then stop, and then start back up again. So if, for example, you order 175 -199 clicks, and you see that the opt ins have started coming... but then you see that after 15 opt ins the traffic stops; don't freak out. The traffic is often delivered in spurts. 
</p>

<p class="normal">
    <strong>5.</strong>
  
    Traffic is the life-blood of your business. Order <strong>AS MUCH</strong> traffic as you can, <strong>AS OFTEN</strong> as you can.
  </p>
  </div>