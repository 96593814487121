import { Component, OnInit } from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  frameSrc:any;
  webinarid:string;
  default_essid;
  default_ontraport_gateway: any;
  constructor(private _service: ToastService,private systempreferenceService:SystempreferenceService, private sanitizer: DomSanitizer, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      
    });
    
   }

  ngOnInit() {
    const systempreference = new Systempreference(null, "default_eseid", null, null);
    console.log(systempreference);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        data => {
          console.log(data['obj'].value);
          this.default_essid = data['obj'].value;
          
          this.webinarid = localStorage.getItem('_ese_webinar_id') ?  localStorage.getItem('_ese_webinar_id') : "";
          this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl("/api/webinar/success?webinarid="+this.webinarid);
          console.log("/api/webinar/success?webinarid="+this.webinarid);
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );

      
  }

}
