import {Component, OnInit, EventEmitter, Output, ViewChild} from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import {ConfirmService} from "./confirm.service";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  data;
  title;
  body;
  icon;
  class;
  link;
  linkTxt;
  target;
  okButtonTxt;
  cancelButtonTxt;
 
  constructor(private confirmService: ConfirmService) {
    this.confirmService.textData.subscribe(data => {
      this['title']=data['title'];
      this.body=data.body;
      this.icon=data.icon;
      this.class=data.class;
      this.link=data.link;
      this.linkTxt=data.linkTxt;
      this.target=data.target;
      if (!data.okButton){
        this.okButtonTxt="Confirm?"
      } else {
        this.okButtonTxt=data.okButtonData
      }

      if (!data.cancelButton){
        this.okButtonTxt="Close?"
      } else {
        this.cancelButtonTxt=data.cancelButtonData
      }


      this.okButtonTxt=data.okButtonTxt;
      this.cancelButtonTxt=data.cancelButtonTxt;
    })

  }

  ngOnInit() {
    
  }

  pressed(data){
    this.confirmService.confirmPressed(data);
  }

  afterViewInit(){
   
  }

}
