import {Injectable, EventEmitter} from '@angular/core';
import {News} from "./news";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class NewsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  news: News[] = [];
  newsIsEdit = new EventEmitter<News>();
  constructor(private http: HttpClient) { }

  editNews(news: News) {
    console.log(news)
    this.newsIsEdit.emit(news);
  }

  addRecord(news: News) {
    const body = JSON.stringify(news);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/news/addrecord' + token, body, {headers: headers})
    
  }

  deleteRecord(news: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/news/deleterecord/' + news._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/news/getallrecords'+token,  {headers: headers})
      
  }

  getNewsTicker() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/news/getnewsticker'+token,  {headers: headers})
     
  }

  updateRecord(news: News){
    const body = JSON.stringify(news);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/news/updaterecord/' + news.newsId + token, body, {headers: headers})
    

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/news/getrecordl/'+id+token,  {headers: headers})
     
  }

}
