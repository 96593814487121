

  <div style="width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
    <div style="width:100%;height:40px;">
      <div style="float:left;height:40px;">
        <button mat-flat-button color="primary" (click)="refresh()">Refresh</button>&nbsp; <button mat-flat-button color="primary" (click)="addRecord()">Add</button>
      </div>
      <div style="float:right;height:40px;font-size:18pt;font-weight:bold;">
        System Preferences
      </div>
    </div>
    <div style="clear:both"></div>
    <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);;" class="ag-theme-blue" id="myGrid"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     
                     enableColResize
                     enableSorting
                     autoSizeColumns="true"
                     enableRangeSelection="true"
                     suppressRowClickSelection
                     enableFilter
                     toolPanelSuppressValues
                     toolPanelSuppressGroups
                     debug="false"
                     draggable="true"
                     rowHeight="57"
                     (rowClicked)="catchClick($event)"
                     rowSelection='multiple'>
    </ag-grid-angular>
  </div>


