import { BrowserModule } from '@angular/platform-browser';
import {routing, appRoutingProviders} from "./app.routing";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NarikCustomValidatorsModule } from '@narik/custom-validators';
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthService} from "./auth/auth.service";
import {SigninComponent} from "./auth/signin/signin.component";
import {AuthComponent} from "./auth/auth.component";
import {SignupComponent} from "./auth/signup/signup.component";
import {AccountComponent} from "./auth/account/account.component";
import {PasswordresetComponent} from "./auth/passwordreset/passwordreset.component";
import {PasswordresetbyemailComponent} from "./auth/passwordresetbyemail/passwordresetbyemail.component";
import {LogoutComponent} from "./auth/logout/logout.component";
//import {SimpleNotificationsModule} from "angular2-notifications";
//import { ToastService } from 'ng-uikit-pro-standard';
import { AppComponent } from './app.component';
import {FooterComponent} from "./shared/footer/footer.component";
import { HeaderComponent } from './shared/header/header.component';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { DecodeHtmlPipe } from './pipes/decode-html.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogTitle, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HomeComponent } from './home/home.component';
import {LoggedInGuard} from "./auth/logged-in.guard";
//import {AdminGuard} from "./auth/admin.guard";
import { ToastModule } from 'ng-uikit-pro-standard';
import { CarouselModule, IconsModule, MDBBootstrapModulesPro,ModalModule } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import {ContactComponent} from "./contact/contact.component";
import {ContactService} from "./contact/contact.service";
import { PublicComponent } from './layout/public/public.component';
import { SecureComponent } from './layout/secure/secure.component';
import { BlankComponent } from './layout/blank/blank.component';
import { JvinviteComponent } from './pages/jvinvite/jvinvite.component';
import { LeadsComponent } from './leads/leads.component';
import {LeadsService} from "./leads/leads.service";
import { RedcomponentComponent } from './redcomponent/redcomponent.component';
import {AgGridModule} from "ag-grid-angular";
import { CrmComponent } from './crm/crm.component';
import {CrmService} from "./crm/crm.service";
import { ManageComponent } from './layout/manage/manage.component';
import { CrmInputComponent } from './crm/crm-input/crm-input.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { MarketingComponent } from './marketing/marketing.component';
import { NotesComponent } from './leads/notes/notes.component';
import { HomepageComponent } from './homepage/homepage.component';
import { EmailsectionComponent } from './emailsection/emailsection.component';
import { EmailsectionService } from './emailsection/emailsection.service';
import { EmailsectionInputComponent } from './emailsection/emailsection-input/emailsection-input.component';
import { EmailsectionSingleComponent } from './emailsection/emailsection-single/emailsection-single.component';
import { EmailsectionListComponent } from './emailsection/emailsection-list/emailsection-list.component';
import { ContactIframeComponent } from './contact/contact-iframe/contact-iframe.component';
import { ConfirmComponent } from './shared/confirm/confirm.component';
import {ConfirmService} from "./shared/confirm/confirm.service";
import { UsersComponent } from './auth/users/users.component';
import { UsersInputComponent } from './auth/users/users-input/users-input.component';
import { LeadsLegendComponent } from './leads/leads-legend/leads-legend.component';
import { NewsComponent } from './shared/news/news.component';
import { NewsInputComponent } from './shared/news/news-input/news-input.component';
import { CrmAddComponent } from './crm/crm-add/crm-add.component';
import { FaqComponent } from './faq/faq.component';
import { NewsTickerComponent } from './shared/news/news-ticker/news-ticker.component';
import { NewsService } from './shared/news/news.service';
import { UserpreferenceComponent } from './shared/userpreference/userpreference.component';
import {UserpreferenceService} from "./shared/userpreference/userpreference.service";
import { UserpreferenceInputComponent } from './shared/userpreference/userpreference-input/userpreference-input.component';
import { ZapierTestComponent } from './leads/zapier-test/zapier-test.component';
import { WebinarFrontComponent } from './webinar/webinar-front/webinar-front.component';
import { WebinarService } from './webinar/webinar.service';
import { SuccessComponent } from './webinar/success/success.component';
import { ApplicationComponent } from './webinar/application/application.component';
import { ApplicationService } from './webinar/application/application.service';
import { SystempreferenceComponent } from './shared/systempreference/systempreference.component';
import { SystempreferenceInputComponent } from './shared/systempreference/systempreference-input/systempreference-input.component';
import { SystempreferenceService } from './shared/systempreference/systempreference.service';
import { ThankyouComponent } from './webinar/application/thankyou.component';
import { CongratulationsComponent } from './auth/signup/congratulations.component';
import { PresentationComponent } from './pages/presentation/presentation.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { SidedrawerComponent } from './shared/sidedrawer/sidedrawer.component';
import { ImagesInputComponent } from './images/images-input/images-input.component';
import { ImagesComponent } from './images/images.component';
import { UploadComponent } from './upload/upload.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplatesInputComponent } from './templates/templates-input/templates-input.component';
import { MastertemplatesComponent } from './mastertemplates/mastertemplates.component';
import { TypesComponent } from './types/types.component';
import { TypesInputComponent } from './types/types-input/types-input.component';
import { CategoriesService } from './categories/categories.service';
import { TemplatesService } from './templates/templates.service';
import { TypesService } from './types/types.service';
import { ImagesService } from './images/images.service';
import { UploadService } from './upload/upload.service';
import { LinksInputComponent } from './links/links-input/links-input.component';
import { LinksComponent } from './links/links.component';
import { LinksStepsComponent } from './links/links-steps/links-steps.component';
import { LinksService } from './links/links.service';
import { CategoriesComponent } from './categories/categories.component';
import { CategoriesInputComponent } from './categories/categories-input/categories-input.component';
import { ClipboardService } from "../../services/clipboard.service";
//import { Ng2ImgMaxModule } from 'ng2-img-max';
import { DraganddropComponent } from './upload/draganddrop/draganddrop.component';
import { DraganddropService } from './upload/draganddrop/draganddrop.service';
import { SubtypesInputComponent } from './subtypes/subtypes-input/subtypes-input.component';
import { SubtypesComponent } from './subtypes/subtypes.component';
import { SubtypesService } from './subtypes/subtypes.service';
import { FunnelsComponent } from './funnels/funnels.component';
import { FunnelsInputComponent } from './funnels/funnels-input/funnels-input.component';
import { FunnelsService } from './funnels/funnels.service';
import { ManagemenuComponent } from './layout/managemenu/managemenu.component';
import { MainmenuComponent } from './layout/mainmenu/mainmenu.component';
import { CommService } from '../../services/comm.service';
import { HellosignComponent } from './hellosign/hellosign.component';
import { AutoresponderComponent } from './autoresponder/autoresponder.component';
import { AutoresponderInputComponent } from './autoresponder/autoresponder-input/autoresponder-input.component';
import { AutoresponderFrontComponent } from './autoresponder/autoresponder-front/autoresponder-front.component';
import { AutoresponderService } from './autoresponder/autoresponder.service';
import { StepsComponent } from './steps/steps.component';
import { TimerComponent } from './shared/timer/timer.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { GettingstartedComponent } from './gettingstarted/gettingstarted.component';
import { FunnelsFrontComponent } from './funnels/funnels-front/funnels-front.component';
import { DoneforyoutrafficComponent } from './doneforyoutraffic/doneforyoutraffic.component';
import { GuaranteedoptinsComponent } from './guaranteedoptins/guaranteedoptins.component';
import { OthertrafficComponent } from './othertraffic/othertraffic.component';
import { ImlComponent } from './iml/iml.component';
import { GuaranteedoptinsService } from './guaranteedoptins/guaranteedoptins.service.service';
import { ImagesUploadComponent } from './images/images-upload.component.ts/images-upload.component';
import { TemplatesInlineEditorComponent } from './templates/templates-inline-editor/templates-inline-editor.component';
import { PdlComponent } from './pdl/pdl.component';
import { CommissionsComponent } from './commissions/commissions.component';
import { CoachingComponent } from './coaching/coaching.component';
import { OneoneoncoachingComponent } from './oneoneoncoaching/oneoneoncoaching.component';
import { AdvancedcoachingComponent } from './advancedcoaching/advancedcoaching.component';
import { MyautoresponderComponent } from './autoresponder/myautoresponder/myautoresponder.component';
import { FreecoachingComponent } from './freecoaching/freecoaching.component';
import { SystemoverviewComponent } from './systemoverview/systemoverview.component';
import { SpecialtrafficofferComponent } from './specialtrafficoffer/specialtrafficoffer.component';
import { FreecoachingService } from './freecoaching/freecoaching.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { RequestComponent } from './request/request.component';
import { RequestService } from './request/request.service';
import { RequestInputComponent } from './request/request-input/request-input.component';
import { WebinarComponent } from './webinar/webinar/webinar.component';
import { WebinarInputComponent } from './webinar/webinar/webinar-input/webinar-input.component';
import { CheckoutService } from './checkout/checkout.service';
import { ProcesscheckoutComponent } from './checkout/processcheckout.component';
import { HellosignService } from './hellosign/hellosign.service';
import { CheckemailComponent } from './auth/signup/checkemail.component';
import { CheckemailerrorComponent } from './auth/signup/checkemailerror.component';
import { CookieService } from 'ngx-cookie-service';
import { AdminhelpComponent } from './adminhelp/adminhelp.component';
import { AffiliatesComponent } from './affiliates/affiliates.component';
import { EseaffiliatesComponent } from './eseaffiliates/eseaffiliates.component';
import { AffiliateOnlyGuard } from './auth/affiliate-only.guard';
import { CheckRoleGuard } from './auth/check-role.guard';
import { AffiliateonlymenuComponent } from './layout/affiliateonlymenu/affiliateonlymenu.component';
import { AffiliatehomeComponent } from './affiliatehome/affiliatehome.component';
import { Affiliatehome1Component } from './affiliatehome1/affiliatehome1.component';
import { Affiliatehome3Component } from './affiliatehome1/affiliatehome3.component';
import { HeaderWhiteComponent } from './shared/headerwhite/headerwhite.component';
import { TestComponent } from './test/test.component';
//import { AceEditorModule } from 'ng2-ace-editor';
import { UrlshortenerComponent } from './urlshortener/urlshortener.component';
import { UrlshortenerInputComponent } from './urlshortener/urlshortener-input/urlshortener-input.component';
import { UrlshortenerService } from './urlshortener/urlshortener.service';
import { MyapplicationsInputComponent } from './myapplications/myapplications-input/myapplications-input.component';
import { MyapplicationsComponent } from './myapplications/myapplications.component';
import { MyapplicationsAddComponent } from './myapplications/myapplications-add/myapplications-add.component';
import { MyapplicationsService } from './myapplications/myapplications.service';
import { TextEditorComponent } from './crm/text-editor.component';
import { NumericEditorComponent } from './crm/numeric-editor.component';
import { SelectEditorComponent } from './crm/select-editor.component';
import { Mainmenu1Component } from './layout/mainmenu1/mainmenu1.component';
import { InternalpageComponent } from './internalpage/internalpage.component';
import { MenusService } from './menus/menus.service';
import { MenusComponent } from './menus/menus.component';
import { MenusInputComponent } from './menus/menus-input/menus-input.component';
import { CrmnavComponent } from './layout/crmnav/crmnav.component';
import { Affiliateonlymenu1Component } from './layout/affiliateonlymenu1/affiliateonlymenu1.component';
import { ShortcodesComponent } from './shortcodes/shortcodes.component';
import { ShortcodesInputComponent } from './shortcodes/shortcodes-input/shortcodes-input.component';
import { ShortcodesService } from './shortcodes/shortcodes.service';
import { AdminmenuComponent } from './layout/adminmenu/adminmenu.component';
import { LinksFrontComponent } from './links/links-front.component';
import { ProductsInputComponent } from './products/products-input/products-input.component';
import { ProductsComponent } from './products/products.component';
import { ProductsService } from './products/products.service';
import { IpageComponent } from './ipage/ipage.component';
import { IpageService } from './ipage/ipage.service';
import { LevelsInputComponent } from './levels/levels-input/levels-input.component';
import { LevelsComponent } from './levels/levels.component';
import { LevelsService } from './levels/levels.service';
import { TemplatessubtypeComponent } from './templates/templatessubtype/templatessubtype.component';
import { TemplatesMyComponent } from './templates/templates-my/templates-my.component';
import { DomainsComponent } from './domains/domains.component';
import { MyDomainsComponent } from './domains/mydomains.component';
import { DomainsInputComponent } from './domains/domains-input/domains-input.component';
import { DomainsService } from './domains/domains.service';
import { AceModule } from 'ngx-ace-wrapper';
import { ACE_CONFIG } from 'ngx-ace-wrapper';
import { AceConfigInterface } from 'ngx-ace-wrapper';
import { PageloaderComponent } from './shared/pageloader/pageloader.component';
import { PageloaderService } from './shared/pageloader/pageloader.service';
import { PageloaderInterceptor } from './interceptors/pageloader.interceptor';
import { SignatureComponent } from './signature/signature.component';
import { MyfunnelsComponent } from './funnels/myfunnels/myfunnels.component';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { MyfunnelsInputComponent } from './funnels/myfunnels-input/myfunnels-input.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChatComponent } from './chat/chat.component';
import { ChatService } from './chat/chat.service';
import { SupportComponent } from './support/support.component';
import { SupportInputComponent } from './support/support-input/support-input.component';
import { SupportService } from './support/support.service';
import { StatusInputComponent } from './status/status-input/status-input.component';
import { StatusComponent } from './status/status.component';
import { StatusService } from './status/status.service';
import { SupportRespondComponent } from './support/support-input/support-respond.component';
import { SupportAddComponent } from './support/support-input/support-add.component';
import { MySupportComponent } from './support/mysupport.component';
import { ChatQueueComponent } from './chat/chatqueue.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { EducationComponent } from './education/education.component';
import { EducationInputComponent } from './education/education-input/education-input.component';
import { EducationService } from './education/education.service';
import { ServersInputComponent } from './servers/servers-input/servers-input.component';
import { ServersComponent } from './servers/servers.component';
import { ServersService } from './servers/servers.service';
import { DomainsStepperComponent } from './domains/domains-input/domains-stepper.component';
import { DomainsStepperIframeComponent } from './domains/domains-input/domains-stepper-iframe.component';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { IncomeDisclaimerComponent } from './layout/public/disclaimer.component';
import { JvComponent } from './layout/public/jv.component';
import { PrivacyPolicyComponent } from './layout/public/privacy.component';
import { TermsComponent } from './layout/public/terms.component';
import { InternalpageService } from './internalpage/internalpage.service';
import { FilesComponent } from './webbuilder/files.component';
import { FileUploadComponent } from './webbuilder/fileupload/fileupload.component';
import { FilesUploadComponent } from './webbuilder/files-upload.component.ts/files-upload.component';
import { FilesInputComponent } from './webbuilder/files-input/files-input.component';
import { FileDraganddropComponent } from './webbuilder/filedraganddrop/filedraganddrop.component';
import { FilesService } from './webbuilder/files.service';
import { FileUploadService } from './webbuilder/fileupload/fileupload.service';
import { FileDraganddropService } from './webbuilder/filedraganddrop/filedraganddrop.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { HtmlColorPickerComponent } from './webbuilder/htmlcolorpicker.component';
import { ActivateFunnelsDialog } from './crm/activate.component';
import { TemplatesConfigComponent } from './templates/templatesconfig.component';
import { GrapesjsComponent } from './webbuilder/grapesjs/grapesjs.component';
import { PrebuiltComponent } from './webbuilder/prebuilt/prebuilt.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ManageInternalpageComponent } from './manageinternalpage/manageinternalpage.component';
import { ManageInternalpageService } from './manageinternalpage/manageinternalpage.service';
import { ContestonlymenuComponent } from './layout/contest/contestonlymenu.component';
import { GrapesjsTestComponent } from './webbuilder/grapesjs/grepestest.component';
import { MenumakerComponent } from './menumaker/menumaker.component';
import { SetupComponent } from './setup/setup.component';
import { SetuplayoutComponent } from './setup/setuplayout/setuplayout.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

@NgModule({
  declarations: [
    ContestonlymenuComponent,
    FilesComponent,
    FileUploadComponent,
    FilesUploadComponent,
    FilesInputComponent,
    FileDraganddropComponent,
    UrlshortenerComponent,
    UrlshortenerInputComponent,
    EscapeHtmlPipe,
    DecodeHtmlPipe,
    SanitizeUrlPipe,
    AppComponent,
    SigninComponent,
    AuthComponent,
    SignupComponent,
    AccountComponent,
    PasswordresetComponent,
    PasswordresetbyemailComponent,
    LogoutComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    ContactComponent,
    PublicComponent,
    SecureComponent,
    BlankComponent,
    JvinviteComponent,
    LeadsComponent,
    RedcomponentComponent,
    NumericEditorComponent,
    TextEditorComponent,
    CrmComponent,
    ManageComponent,
    SelectEditorComponent,
    CrmInputComponent,
    LoaderComponent,
    MarketingComponent,
    NotesComponent,
    HomepageComponent,
    EmailsectionComponent,
    EmailsectionInputComponent,
    EmailsectionSingleComponent,
    EmailsectionListComponent,
    ContactIframeComponent,
    ConfirmComponent,
    UsersComponent,
    UsersInputComponent,
    LeadsLegendComponent,
    NewsComponent,
    NewsInputComponent,
    CrmAddComponent,
    FaqComponent,
    NewsTickerComponent,
    UserpreferenceComponent,
    UserpreferenceInputComponent,
    ZapierTestComponent,
    WebinarComponent,
    SuccessComponent,
    ApplicationComponent,
    SystempreferenceComponent,
    SystempreferenceInputComponent,
    ThankyouComponent,
    CongratulationsComponent,
    PresentationComponent,
    SidenavComponent,
    SidedrawerComponent,
    ImagesComponent,
    ImagesInputComponent,
    UploadComponent,
    TemplatesComponent,
    TemplatesInputComponent,
    MastertemplatesComponent,
    TypesComponent,
    TypesInputComponent,
    LinksComponent,
    LinksInputComponent,
    LinksStepsComponent,
    CategoriesComponent,
    CategoriesInputComponent,
    UploadComponent,
    DraganddropComponent,
    SubtypesComponent,
    SubtypesInputComponent,
    FunnelsComponent,
    FunnelsInputComponent,
    ManagemenuComponent,
    MainmenuComponent,
    HellosignComponent,
    AutoresponderComponent,
    AutoresponderInputComponent,
    AutoresponderFrontComponent,
    StepsComponent,
    TimerComponent,
    ChecklistComponent,
    GettingstartedComponent,
    FunnelsFrontComponent,
    DoneforyoutrafficComponent,
    GuaranteedoptinsComponent,
    OthertrafficComponent,
    ImlComponent,
    ImagesUploadComponent,
    TemplatesInlineEditorComponent,
    PdlComponent,
    CommissionsComponent,
    CoachingComponent,
    OneoneoncoachingComponent,
    AdvancedcoachingComponent,
    MyautoresponderComponent,
    FreecoachingComponent,
    SystemoverviewComponent,
    SpecialtrafficofferComponent,
    CheckoutComponent,
    ProcesscheckoutComponent,
    RequestComponent,
    RequestInputComponent,
    WebinarInputComponent,
    WebinarFrontComponent,
    CheckemailComponent,
    CheckemailerrorComponent,
    AdminhelpComponent,
    AffiliatesComponent,
    EseaffiliatesComponent,
    AffiliateonlymenuComponent,
    AffiliatehomeComponent,
    Affiliatehome1Component,
    Affiliatehome3Component,
    HeaderWhiteComponent,
    TestComponent,
    MyapplicationsComponent,
    MyapplicationsInputComponent,
    MyapplicationsAddComponent,
    Mainmenu1Component,
    InternalpageComponent,
    MenusComponent,
    MenusInputComponent,
    CrmnavComponent,
    Affiliateonlymenu1Component,
    ShortcodesComponent,
    ShortcodesInputComponent,
    AdminmenuComponent,
    LinksFrontComponent,
    ProductsComponent,
    ProductsInputComponent,
    IpageComponent,
    LevelsComponent,
    LevelsInputComponent,
    TemplatessubtypeComponent,
    TemplatesMyComponent,
    DomainsComponent,
    DomainsInputComponent,
    DomainsStepperIframeComponent,
    DomainsStepperComponent,
    MyDomainsComponent,
    PageloaderComponent,
    SignatureComponent,
    MyfunnelsComponent,
    NotificationsComponent,
    MyfunnelsInputComponent,
    ChatComponent,
    SupportComponent,
    SupportInputComponent,
    SupportRespondComponent,
    SupportAddComponent,
    MySupportComponent,
    StatusInputComponent,
    StatusComponent,
    ChatQueueComponent,
    EducationComponent,
    EducationInputComponent,
    ServersComponent,
    ServersInputComponent,
    IncomeDisclaimerComponent,
    JvComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    TermsComponent,
    HtmlColorPickerComponent,
    ActivateFunnelsDialog,
    TemplatesConfigComponent,
    GrapesjsComponent,
    PrebuiltComponent,
    ManageInternalpageComponent,
    GrapesjsTestComponent,
    MenumakerComponent,
    SetupComponent,
    SetuplayoutComponent

    
    
  ],
  imports: [
  //  SignaturePad,
  CKEditorModule,
  ColorPickerModule,
  ReactiveFormsModule,
  MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    NarikCustomValidatorsModule,
    CarouselModule.forRoot(),
    IconsModule,
    ToastModule.forRoot(),
    MatStepperModule,
    MatBottomSheetModule,
    AceModule,
    DragDropModule,
    ScrollingModule,
    //AceEditorModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressBarModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatGridListModule,
    MatRadioModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatSlideToggleModule,
    routing,
    BrowserAnimationsModule,
    FlexLayoutModule,
    //Ng2ImgMaxModule ,
    BrowserAnimationsModule,
    //SimpleNotificationsModule.forRoot(),
    AgGridModule.withComponents(
      [
        DomainsStepperIframeComponent,
        DomainsStepperComponent,
        ServersInputComponent,
        EducationInputComponent,
        SignatureComponent,
        RedcomponentComponent, 
        NumericEditorComponent, 
        TextEditorComponent, 
        SelectEditorComponent, 
        CrmInputComponent, 
        CrmAddComponent, 
        LoaderComponent, 
        NotesComponent, 
        EmailsectionInputComponent, 
        ConfirmComponent, 
        UsersInputComponent, 
        NewsInputComponent, 
        UserpreferenceInputComponent, 
        SystempreferenceInputComponent, 
        TemplatesInputComponent,
        TemplatesComponent,
        MastertemplatesComponent,
        LinksInputComponent,
        LinksStepsComponent,
        ImagesComponent,
        ImagesInputComponent,
        UploadComponent,
        SubtypesInputComponent,
        FunnelsInputComponent,
        MyfunnelsInputComponent,
        RequestInputComponent,
        WebinarInputComponent,
        UrlshortenerInputComponent,
        CategoriesInputComponent,
        MyapplicationsInputComponent,
        MyapplicationsAddComponent,
        MenusInputComponent,
        TypesInputComponent,
        ShortcodesInputComponent,
        ProductsInputComponent,
        IpageComponent,
        LevelsInputComponent,
        TemplatesMyComponent,
        DomainsComponent,
        DomainsInputComponent,
        MyDomainsComponent,
        SupportInputComponent,
        SupportRespondComponent,
        SupportAddComponent,
        StatusInputComponent,
        ActivateFunnelsDialog
      ]
    )
  ],
  providers: [
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG
    },
    ManageInternalpageService,
    UploadService,
    FilesService,
    FileUploadService,
    FileDraganddropService,
    InternalpageService,
    EducationService,
    StatusService,
    ChatService,
    SupportService,
    appRoutingProviders, 
    AuthService, 
    //NotificationsService, 
    LoggedInGuard, 
   // AdminGuard,
    CheckRoleGuard,
    AffiliateOnlyGuard,
    ContactService, 
    LeadsService, 
    CrmService, 
    EmailsectionService, 
    ConfirmService, 
    NewsService, 
    UserpreferenceService, 
    WebinarService, 
    ApplicationService,
    SystempreferenceService, 
    CategoriesService, 
    TemplatesService,
    TypesService,
    ImagesService,
    UploadService,
    LinksService,
    ClipboardService,
    UploadService,
    DraganddropService,
    SubtypesService,
    FunnelsService,
    CommService,
    AutoresponderService,
    GuaranteedoptinsService,
    FreecoachingService,
    RequestService,
    CheckoutService,
    HellosignService,
    CookieService,
    UrlshortenerService,
    MyapplicationsService,
    MenusService,
    ShortcodesService,
    ProductsService,
    IpageService,
    LevelsService,
    DomainsService,
    PageloaderService,
    ServersService,
    { provide: HTTP_INTERCEPTORS, useClass: PageloaderInterceptor, multi: true },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true, width:"375px",height:"275px"}},
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

//test git
