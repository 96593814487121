import { Component, OnInit, Output, EventEmitter, Renderer2, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl} from "@angular/forms";
import {AuthService} from "../auth.service";
import {User} from "../user";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  public user: User;
  url;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  edited=false;
  verifyemail:String;
  loggedIn:boolean=false;
  cardForm: FormGroup;
  emailForm: FormGroup;
  passForm: FormGroup;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _service: ToastService,
    private fb: FormBuilder,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document
    
  ) { 
    this.cardForm = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      
    });

    this.emailForm = fb.group({
      email: ['', Validators.required]
      
    });

    this.passForm = fb.group({
      email: ['', Validators.required],
      username: ['', Validators.required]
      
    });
  }

  get f(){
    return this.cardForm.controls;
  }

  get e(){
    return this.emailForm.controls;
  }

  get p(){
    return this.passForm.controls;
  }

  changePass(){
    console.log(this.passForm.value);
    var body ={
      username:this.passForm.value.username,
      email:this.passForm.value.email
    }
    this.authService.requestPassword(body)
    .subscribe(
      data => {
        console.log(data)
        if (data['title']=="Success"){
          this._service.success(data['message'],data['title'],{opacity:1});
          
            this.router.navigateByUrl('/verify');
          
      } else {
          this._service.warning(data['message'],data['title'],{opacity:1});
      }
      }
    )
  }

  verifyEmail(){
    console.log(this.emailForm.value.email)
    this.authService.verifyemail(this.emailForm.value.email)
    .subscribe(
      data => {
        if (data['message']=="Success"){
          this._service.success(data['message'],data['title'],{opacity:1});
          
            this.router.navigateByUrl('/verify');
          
      } else {
          this._service.error(data['error'].message,data['title'],{opacity:1});
      }
      }
    )
  }

  onSubmit() {
    console.log(this.cardForm.value);
    this.authService.signin(this.cardForm.value)
      .subscribe(
        data => {
          if (data['message']=='Success') {
          
            localStorage.setItem('token', data['token']);
            localStorage.setItem('userId', data['userId']);
            localStorage.setItem('roles', data['roles']);
            localStorage.setItem('email', data['email']);
            localStorage.setItem('username', data['username']);
            localStorage.setItem('userRecord', JSON.stringify(data['doc']));
  
            this.loggedIn = true;
            this._service.success(data['title'], data['message']);
            
            var systemPreference=JSON.parse(data['systempreference'].value);
            
            if (data['roles']=="affiliate_only"){
              this.router.navigateByUrl(systemPreference.affiliateStart);
            } else {
              this.router.navigateByUrl(systemPreference.memberStart);
            }
          } else {
            this._service.warning(data['title'], data['message'],{opacity:1});
          }
          
        },
        error => this._service.error(error.err, error['title'],{opacity:1}),

      );
  }

  showVerifyF(){
    this.edited=true;
  }

  submitVerify(){
    console.log(this.verifyemail)
    this.authService.verifyemail(this.verifyemail)
      .subscribe(
        data => {
          console.log(data);
          this._service.success(data['message'], data['title'])
        },
        error => this._service.error(error['title'], error.error['message'],),

      );
  }

  ngOnInit() {
    
   
  }

  showAds() {
    let isFreeMember = this.authService.isFreeMember();
    let isLoggedIn = this.authService.isLoggedIn();
    
    if (isLoggedIn == true && isFreeMember == true) {
      return true;
    } else {
      return false;
    }

  }

}
