import {Injectable, EventEmitter} from '@angular/core';
import {Shortcodes} from "./shortcodes";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class ShortcodesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  news: Shortcodes[] = [];
  newsIsEdit = new EventEmitter<Shortcodes>();
  constructor(private http: HttpClient) { }

  editNews(shortcodes: Shortcodes) {
    console.log(shortcodes)
    this.newsIsEdit.emit(shortcodes);
  }

  getGroupRecords(group: string) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/shortcodes/getgrouprecords/'+group+token,  {headers: headers})
     
  }

  addRecord(shortcodes: Shortcodes) {
    const body = JSON.stringify(shortcodes);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/shortcodes/addrecord' + token, body, {headers: headers})
   
  }

  deleteRecord(shortcodes: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/shortcodes/deleterecord/' + shortcodes._id + token, {headers: headers})
    
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/shortcodes/getallrecords'+token,  {headers: headers})
    
  }

  updateKey(shortcodes: any){
    const body = JSON.stringify(shortcodes);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/shortcodes/updatekey' + token, body, {headers: headers})
    
  }

  getKey(shortcodes: Shortcodes) {
    const body = JSON.stringify(shortcodes);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/shortcodes/getkey' + token, body, {headers: headers})
     
  }

  getKeyByUser(user, key) {
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const uri = "/"+user+"/"+key;
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/shortcodes/getkeybyuser' + uri+token);
    return this.http.get(this.hostName +'/api/shortcodes/getkeybyuser' + uri+token, {headers: headers})
     
  }

  updateRecord(shortcodes: Shortcodes){
    const body = JSON.stringify(shortcodes);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/shortcodes/updaterecord/' + shortcodes.shortcodesId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/shortcodes/getrecordl/'+id+token,  {headers: headers})
     
  }

}
