import { Injectable } from '@angular/core';
import { Subject, Observable }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private messageBodySource = new Subject<string>();
  private messageTitleSource = new Subject<string>();
  private messageTypeSource = new Subject<string>();
  private iconSource = new Subject<string>();
  subscription;

  getMessage(): Observable<string> {
    return this.messageBodySource.asObservable();
  }

 updateMessage(messageTitle: string, messageBody: string,  messageType:string, icon:string,duration: number, cb) {
    var dataString = {'title':messageTitle, 'body':messageBody, 'type':messageType, 'icon':icon, 'duration':duration}
    this.messageBodySource.next(JSON.stringify(dataString));
    this.messageTitleSource.next(messageTitle);
    this.messageTypeSource.next(messageType);
    this.iconSource.next(icon);
    cb();
  }

  constructor() {}
}
