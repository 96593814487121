import { Component, OnInit } from '@angular/core';
import {Record} from "../record";
import {CrmService} from "../crm.service";
import {Crm} from "../crm";
import { MatInputModule } from '@angular/material/input';
import { ToastService } from 'ng-uikit-pro-standard';
import {Router} from "@angular/router";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from "@angular/forms";
import * as moment from 'moment';

@Component({
  selector: 'app-crm-add',
  templateUrl: './crm-add.component.html',
  styleUrls: ['./crm-add.component.css']
})
export class CrmAddComponent implements OnInit {
  crm:Crm = null;
  notesClick = 0;
  privateNotesClick = 0;

  constructor(private crmService: CrmService, private _service: ToastService,private router:Router, private dialogRef: MatDialogRef<CrmAddComponent>) { }

  ngOnInit() {
  }

  save(f:NgForm, model: Crm, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);

      this.crmService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )

  }

  addNotesTime(element){


    if (this.notesClick==0) {
      let el = document.getElementById(element) as HTMLTextAreaElement;
      let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
      let username = localStorage.getItem('username');
      console.log(timeStamp + " - " + username + "\n\n" + el.value);
      el.focus();
      el.scrollTop = 0;
      el.value = timeStamp + " - " + username + "\n\n" + el.value;
      this.notesClick++;
    }


  }

  addPrivateNotesTime(element){


    if (this.privateNotesClick==0) {
      let el = document.getElementById(element) as HTMLTextAreaElement;
      let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
      let username = localStorage.getItem('username');
      console.log(timeStamp + " - " + username + "\n\n" + el.value);
      el.focus();
      el.scrollTop = 0;
      el.value = timeStamp + " - " + username + "\n\n" + el.value;
      this.privateNotesClick++;
    }


  }

}
