import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {SubtypesService} from "../subtypes.service";
import {Subtypes} from "../subtypes";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-subtypes-input',
  templateUrl: './subtypes-input.component.html',
  styleUrls: ['./subtypes-input.component.css']
})
export class SubtypesInputComponent implements OnInit {
  subtypes:Subtypes = null;

  constructor(private subtypesService:SubtypesService, private authService: AuthService, private _service: ToastService) {
    subtypesService.subtypesIsEdit.subscribe(
      subtypes => {
        this.subtypes = subtypes;
        console.log(this.subtypes);
      })

  }

  save(f:NgForm, model: Subtypes, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.subtypesId){

      this.subtypesService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.subtypesService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
