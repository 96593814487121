import { Injectable } from '@angular/core';
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class GuaranteedoptinsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  addRecord(guaranteedoptins: any) {
    const body = JSON.stringify(guaranteedoptins);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/addguaranteedoptin' + token, body, {headers: headers})
      
  }

}
