import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {ProductsService} from "../products.service";
import {Products} from "../products";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-products-input',
  templateUrl: './products-input.component.html',
  styleUrls: ['./products-input.component.css']
})
export class ProductsInputComponent implements OnInit {
  products:Products = null;

  constructor(private requestService:ProductsService, private authService: AuthService, private _service: ToastService) {
    requestService.requestIsEdit.subscribe(
      products => {
        this.products = products;
        console.log(this.products);
      })

  }

  onStatusChange(event){
    
  }

  save(f:NgForm, model: Products, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.productId){

      this.requestService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.requestService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
