import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FreecoachingService } from './freecoaching.service';
import {NgForm} from "@angular/forms";
import { ToastService } from 'ng-uikit-pro-standard';
import { CommService } from '../../../services/comm.service';

@Component({
  selector: 'app-freecoaching',
  templateUrl: './freecoaching.component.html',
  styleUrls: ['./freecoaching.component.css']
})
export class FreecoachingComponent implements OnInit {
  @Output() onClose = new EventEmitter<String>();
  constructor(private commService: CommService, private freecoachingService: FreecoachingService, private _service: ToastService) { }

  closeDrawer(event, closeMe: string){
    event.preventDefault();
    this.onClose.emit(closeMe);
  }
  
  save (f:NgForm, model: any, isValid: boolean){
    model.type="Free 1-on-1 Walkthrough";
    model.username=localStorage.getItem('username') ?  localStorage.getItem('username') : "";
    this.freecoachingService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this.commService.setFreecoaching("1");
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        ) 
  }
  ngOnInit() {
  }

}
