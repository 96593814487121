import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { Emailsection } from "../emailsection";
import {EmailsectionService} from "../emailsection.service";
import {AuthService} from "../../auth/auth.service";
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-emailsection-single',
  templateUrl: './emailsection-single.component.html',
  styleUrls: ['./emailsection-single.component.css']
})
export class EmailsectionSingleComponent implements OnInit {
  @Input() emailsection:Emailsection;
  @Output() editClicked = new EventEmitter<string>();

  constructor(private emailsectionService:EmailsectionService, private authService: AuthService, private _service: ToastService) { }

  ngOnInit() {
  }

  onEdit(){

  }

  onDelete(){

  }

}
