import { Component, OnInit, AfterViewInit, ViewChild, Inject, Renderer2 } from '@angular/core';
import { CommService } from '../../../../services/comm.service';
import { AuthService } from '../../auth/auth.service';
import {NewsService} from "../../shared/news/news.service";
import { SidenavComponent, ToastService } from 'ng-uikit-pro-standard';
import { Observable, Subscription } from 'rxjs';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';

import { MatDialog } from '@angular/material/dialog';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';

import * as io from "socket.io-client";
import { DOCUMENT } from '@angular/common';
import { SidenavModule, WavesModule, AccordionModule, IconsModule } from 'ng-uikit-pro-standard'
import { throwToolbarMixedModesError } from '@angular/material/toolbar';


@Component({
  selector: 'app-secure',
  templateUrl: './secure2.component.html',
  styleUrls: ['./secure.component.css']
})
export class SecureComponent implements OnInit, AfterViewInit {
  @ViewChild('guarantee',{static:true}) public guarantee;
  @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent
  drawerContent: string;
  mainFields:any = [];
  helloSignOptions:any = [];
  newsHtml;
  showNews=false;
  userRecord = JSON.parse(localStorage.getItem('userRecord'));
  pageMenu;
  navShown=true;

  toggleSideNav(){
    
    this.sidenav.toggle();
    let myShown = this.sidenav;
  
    if (myShown.isShown==true){
      this.navShown=false;
    } else if (myShown.isShown==false) {
      this.navShown=true;
    }

    this.commService.sendMessage({'action':'toggleSideNav', 'value':this.navShown});
  }

  onSideNavClose($event){
   
  }

  isAdmin = false;
  isMember = false;
  isSuper = false;
  isAffiliateOnly = false;
  isContestOnly = false;
  profile_active=false;
  message: string;
  logo: string;
  backgroundColor: string;
  templateId='a32c10bf7e0b46bde09bf909f1b4773d54960b42';
  sideNavBackground:string="fixed blue darken-4";
  isDataAvailable:boolean=false;
  myRoom:String
  myName:String;

  ioToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  
  askQuestion;
  showMessageTxt=false;
  subscription:Subscription;
  pageTitle:String="";


  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document, 
    private bottomSheet: MatBottomSheet, 
    public dialog: MatDialog, 
    private newsService: NewsService, 
    private systempreferenceService: SystempreferenceService, 
    public commService:CommService, 
    private authService:AuthService,  
    private _service: ToastService,
  
    ) { 
      
    
      this.getSystemPreferences(()=>{
        this.isDataAvailable=true;
        console.log(this.isDataAvailable)
        this.beginInit(()=>{

        })
       })
      this.subscription = this.commService.onMessage().subscribe(message=>{
       
         switch(message.action){
           case ("pageInfo"):
               this.pageTitle=message.pagetitle;
           case ("setVariable"):
               switch(message.variable){
                 
               }
               break;
         }
       })

       

   
  }

  getSystemPreferences=(cb)=>{
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    return this.systempreferenceService.getKey(systempreference)
    .subscribe(
      systemPref => {
        
        var myJson = JSON.parse(systemPref['obj'].value);
        console.log(myJson)
        this.logo=myJson.logo;
      //  this.sideNavBackground=myJson.sideNavBackgroundColor;
        console.log(this.sideNavBackground);
        cb();
      },
      error => {
        this._service.error(error.error['message'], error['title']);
      }
    );
  }
  

  ngOnInit() {}
    //this.sideNavBackground="fixed blue darken-4";
    
  beginInit=(cb)=>{  
   

   

   const s = this.renderer2.createElement('script');
  // s.type = 'text/javascript';
   s.src = 'https://static.zdassets.com/ekr/snippet.js?key=27f89c26-e38e-44e8-9181-708c8b1392a8';
    s.id='ze-snippet'
   this.renderer2.appendChild(this._document.body, s);

   const y = this.renderer2.createElement('script');
   y.innerHTML=`var myName="dave Sloan is great"`;
   this.renderer2.appendChild(this._document.body, y);

   const t = this.renderer2.createElement('script');
   t.src='https://www.12minuteaffiliate.com/jsfile/zendesk';
   t.id="zendesk_js"
   this.renderer2.appendChild(this._document.body, t);
   

   

    this.commService.guarantee = this.guarantee;
    this.commService.currentMessage.subscribe(message => this.drawerContent = message)
   
    this.authService.getUser()
      .subscribe(
        user => {

          console.log(user['obj']);
          
          if (user['obj'].roles.includes('super')){
            this.isSuper = true;
            this.isMember = true;
            console.log("super")
          } 

          if (user['obj'].roles.includes('member')){
            this.isMember = true;
            console.log("member")
          } 

          if (user['obj'].roles.includes('admin')){
            this.isAdmin = true;
            this.isMember = true;
            console.log("admin");
            
          } 

          if (user['obj'].roles.includes('affiliate_only')){
            this.isAffiliateOnly = true;
            console.log("affiliate");
            console.log(this.isAdmin);
            console.log(this.isMember);
            console.log(this.isSuper)
            console.log(this.isAffiliateOnly)
          }

          if (user['obj'].roles.includes('contest_only')){
            this.isContestOnly = true;
            console.log("contest",this.isContestOnly)
          }


          
          this.mainFields={
          };

          this.helloSignOptions={
            'subject':'ESEv2: Guarantee Form',
            'message':'Please Sign the ESE Guaarantee Form'
          }

         cb();
        },
        error => this._service.error(error['title'], error['message'])
      );
   
      
  }

  ngAfterViewInit() {

  }

  public logOut($event){
  }

  
}
