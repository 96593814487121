export class User {
  userId: string;
  username: string;
  password: string;
  password_check: string;
  firstname: string;
  lastname: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  skype: string;
  phone: string;
  paypal: string;
  roles: string;
  valid: string;
  active: string;
  loggedin: string;
  domain: string;
  specialoffer: string;
  _id: string;
  url: string;
  level: string;
  avatar: string;
  gmail_username: string;
  gmail_password: string;

constructor ( userId?: string,
                gmail_username?: string,
                gmail_password?: string,
                username?: string,
                password?: string,
                level?: string,
                password_check?: string,
                firstname?: string,
                lastname?: string,
                email?: string,
                address?: string,
                address2?: string,
                city?: string,
                state?: string,
                zip?: string,
                country?: string,
                skype?: string,
                phone?: string,
                paypal?: string,
                roles?: string,
                valid?:string,
                active?:string,
                loggedin?: string,
                specialoffer?: string,
                domain?: string,
                _id?: string,
                url?: string,
                avatar?: string
  ) {
    this.userId = userId;
    this.gmail_username = gmail_username;
    this.gmail_password = gmail_password;
    this.username = username;
    this.password = password;
    this.password_check = password_check;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.address = address;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.country=country;
    this.skype = skype;
    this.phone = phone;
    this.paypal = paypal;
    this.roles = roles;
    this.valid = valid;
    this.active = active;
    this.loggedin = loggedin;
    this.specialoffer = specialoffer;
    this.domain = domain;
    this._id = _id;
    this.url = url;
    this.level = level;
    this.avatar = avatar;

  }
}
