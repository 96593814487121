import { Component, OnInit, Inject } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {DomainsService} from "../domains.service";
import { trigger, state, transition, style, animate } from '@angular/animations'
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-domains-stepper-iframe',
  template: `
    <button mat-flat-button color="primary" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon> Close
    </button><br>
    <iframe  [src]="frameSrc" frameborder=0 style="width:100%;height:100vh;border:none "></iframe>
  `,
  styleUrls: ['./domains-input.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class DomainsStepperIframeComponent implements OnInit {
  visiblityState = 'hidden';
    messageTitle;
    messageBody;
    icon;
    step:String=null;
    frameSrc;
    
  
showMessage(messageTitle:String,messageBody:String,step:String, icon:string, cb) {
    this.visiblityState = 'shown';
    this.messageTitle=messageTitle;
    this.messageBody=this.messageBody;
    this.icon=icon;
    this.step=step;
    
    setTimeout(() => {
      this.visiblityState = 'hidden';
      cb();
    },5000) 
  }

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DomainsStepperIframeComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private route: ActivatedRoute,private sanitizer: DomSanitizer, private domainsService:DomainsService, private authService: AuthService, private _service: ToastService) {
     
  }

  ngOnInit() {
    console.log("data:",this.data)
        this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
   
  }

}
