import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { Systempreference } from '../shared/systempreference/systempreference';

@Pipe({ name: 'decodeHtml', pure: false })
export class DecodeHtmlPipe implements PipeTransform {
  mpd_link;
  constructor(private sanitizer: DomSanitizer, private systempreferenceService: SystempreferenceService) {
  }

  transform(content, arg1) {
      try{
        var html=atob(content);
        var eseid = localStorage.getItem('username') ?  localStorage.getItem('username') : '';
        html=html.replace(/\[ESEID\]/g, eseid );
      
        html=html.replace(/\[MPD_LINK\]/g, arg1 );
      } catch(err){

      }
      
      
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}