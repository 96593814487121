import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {SupportService} from "../support.service";
import {Support} from "../support";
import {NgForm} from "@angular/forms";
import { StatusService } from '../../status/status.service';

@Component({
  selector: 'app-support-input',
  template: `
    <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
      
    <mat-dialog-content>
      <input type="hidden" name="supportId" #supportId="ngModel" [ngModel]="support?._id" id="supportId">
      <input type="hidden" name="username" #username="ngModel" [ngModel]="support?.username"  id="username">

      <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>What is The Message for Support</mat-label>
        <textarea matInput name="message" #message="ngModel" [ngModel]="support?.message" type="text" rows="10" id="message" placeholder="What is The Message for Support"></textarea>
        <mat-icon matSuffix>edit</mat-icon>
      </mat-form-field>

      
    

      <br><br>


    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="userActionButtonGroup">
        <button mat-flat-button  color="primary" type="submit" [disabled]="!f.valid">
          <mat-icon  class="material-icons">done</mat-icon>&nbsp;Save
        </button>&nbsp;
        <button mat-flat-button color="primary" mat-dialog-close>
          <mat-icon  class="material-icons">cancel</mat-icon>&nbsp;Cancel
        </button>
      </div>
    </mat-dialog-actions>
  </form>
  <br><br>
  <strong>{{mysubject}}</strong>
  <hr>
  
  <div  *ngFor="let mythread of mythreads">
  <div id="wrapper">
    <div id="left">
      <img class="avatar" [src]="mythread.avatar!=null ? mythread.avatar : defaultAvatar">
    </div>
    <div id="right">
      <span style="color:lightblue">{{mythread.firstname}} {{mythread.lastname}}</span>
      <br>
      <span>created: {{mythread.created_at}}</span>
    </div>
  </div>
    <br>
    {{mythread.message}}
    <br><hr><br>
  </div>

  <app-notifications></app-notifications>
  `,
  styleUrls: ['./support-input.component.css']
})
export class SupportRespondComponent implements OnInit {
  support:Support = null;
  username: string; 
  statuses;
  response:boolean=false;
  thread_id;
  mythreads;
  mysubject;
  user;
  defaultAvatar="https://esev2uploaded.s3.amazonaws.com/dsloan254/1570632315445.2131.YIRbHKaedM.no_avatar.jpg";
  avatar=null;
  
  constructor(private statusService: StatusService, private supportService:SupportService, private authService: AuthService, private _service: ToastService) {
    this.username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let user = localStorage.getItem('userRecord') ? localStorage.getItem('userRecord') : '';
    this.user=JSON.parse(user);
    this.avatar=this.user.avatar;
    supportService.supportIsEdit.subscribe(
      support => {
        this.response=support.response;
        console.log('dave');
        this.support=support;
        
      })

  }
  

  getThread(data,cb){
    console.log('data1', data, data.thread)
    this.supportService.getRecordsByThread(data.thread)
      .subscribe(
        data => {
          console.log("data2",data)
          this.mythreads=data['obj']
          cb(data)
        }
      )
  }

  save(f:NgForm, model: Support, isValid: boolean) {

      model.thread=this.thread_id;
    
      model.username = this.username;
      model.firstname = this.user.firstname;
      model.lastname = this.user.lastname;
      model.avatar = this.avatar;
      
      this.supportService.addRecord(model)
        .subscribe(
          data => {
            
           // this.threadHtml=this.postHtml+this.threadHtml;
           this.getThread(model, function(mydata){
            
            console.log(mydata);
          })
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  ngOnInit() {
    this.statusService.getAllRecords()
      .subscribe(
        data => {
          this.statuses = data['obj'];
        }
      )
      if (this.support.response==true){
        this.mysubject=this.support.subject;
        this.thread_id=this.support.thread;
        this.getThread(this.support, function(data){
          
          console.log(data);
        })
        this.support=null;
      } else {
       
      }
  }

}
