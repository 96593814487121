
import {Component, OnInit, EventEmitter, Output, ViewChild} from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { AutoresponderService } from '../autoresponder/autoresponder.service';
import { CategoriesService } from '../categories/categories.service';
import { ProductsService } from '../products/products.service';
import {ConfirmService} from "../shared/confirm/confirm.service";
import { UserpreferenceService } from '../shared/userpreference/userpreference.service';
import { SubtypesService } from '../subtypes/subtypes.service';
import { TemplatesService } from '../templates/templates.service';
import { TypesService } from '../types/types.service';
import { CrmService } from './crm.service';
import { FunnelsService } from "../funnels/funnels.service";

@Component({
  selector: 'activate-funnels-dialog',
  template: `<div style="display:flex;align-items:center;font-size:20pt;font-weight:bold;"><mat-icon style="font-size:32pt;display:flex;align-items:center;margin-right:22px">check</mat-icon>Activate Funnels</div>
  <hr>
  <div style="background-color:#ccc;width:100%;height:1px;margin-bottom:10px;border-radius:20px;"></div>
  <form (ngSubmit)="save(f.value, f.valid)" #f="ngForm">
  <mat-dialog-content>
        <label for="niche">Please Choose a Niche?</label>
        <select id="niche" [(ngModel)]='niche' name="niche" class="browser-default custom-select" (change)="selectNiche()" required>
            <option value="" disabled selected>Choose your niche</option>
            <option *ngFor="let niche of niches" [value]="niche.value" >{{niche.name}}</option>
        </select>
        <br><br>

        <label for="autoresponder">Which Autoresponder are you using?</label><br>
  
     
        <select id="autoresponder" [(ngModel)]='autoresponder' name="autoresponder" class="browser-default custom-select" (change)="selectAutoresponder()" required>
            <option value="" disabled selected>Choose your autoresponder</option>
            <option value="aweber">Aweber</option>
            <option value="getresponse">Get Response</option>
        </select>
        <br><br>

        <div class="form-group" style="width:100%">
            <label>{{listnameText}}</label>
            <div class="input-group" style="width:100%">
                
            <div class="input-group-prepend">
                <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="listname" [(ngModel)]="listname" type="text" id="listname" class="form-control form-elements" aria-label="With textarea" required>
            </div>
           
        </div>

        <div class="form-group" style="width:100%" *ngIf="showApiFields">
            <label>Account ID</label>
            <div class="input-group" style="width:100%">
                
            <div class="input-group-prepend">
                <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="account" [(ngModel)]="account" type="text" id="account" class="form-control form-elements" aria-label="With textarea">
            </div>
           
        </div>

        <div class="form-group" style="width:100%" *ngIf="showApiFields">
            <label>Secret</label>
            <div class="input-group" style="width:100%">
                
            <div class="input-group-prepend">
                <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="consumer_secret" [(ngModel)]="consumer_secret" type="text" id="consumer_secret" class="form-control form-elements" aria-label="With textarea">
            </div>
           
        </div>

        <div class="form-group" style="width:100%" *ngIf="showApiFields">
        <label>API Key</label>
        <div class="input-group" style="width:100%">
            
        <div class="input-group-prepend">
            <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
        </div>
        <input style="" name="consumer_key" [(ngModel)]="consumer_key" type="text" id="consumer_key" class="form-control form-elements" aria-label="With textarea">
        </div>
       
      </div>

        <div class="form-group" style="width:100%">
            <label>Clickbank Id</label>
            <div class="input-group" style="width:100%">
                
            <div class="input-group-prepend">
                <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="clickbankId" [(ngModel)]="clickbankId" type="text" id="clickbankId" class="form-control form-elements" aria-label="With textarea" required>
            </div>
        </div>
    
  </mat-dialog-content>
  <mat-dialog-actions>
  
  <div style="background-color:#ccc;width:100%;height:1px;margin-bottom:10px;"></div>
  
  <button mdbBtn color="info" mdbWavesEffect (click)="pressed(false)" mat-dialog-close>Cancel</button>
  &nbsp;&nbsp;
  <button mdbBtn type="submit" color="info" mdbWavesEffect mat-dialog-close [disabled]="!f.valid">Activate?</button>
  </mat-dialog-actions>
  </form> 
  
  

<div mdbModal #instantModal="mdbModal" class="modal fade top" id="instantmodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-notify modal-warning" role="document">
  <!--Content-->
  <div class="modal-content">
    <!--Header-->
    <div class="modal-header">
      <p class="heading lead">Instant Funnel Warning</p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="instantModal.hide()">
        <span aria-hidden="true" class="white-text">&times;</span>
      </button>
    </div>
    <!--Body-->
    <div class="modal-body">
      <div class="text-center">
        <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
        <p>{{categoryName}} Already has instant funnels setup. Do you want to delete them so you can set them up again?</p>
      </div>
    </div>
    <!--Footer-->
    <div class="modal-footer justify-content-center">
      <a type="button" mdbBtn color="warning" class="waves-effect" mdbWavesEffect (click)="deleteInstantFunnels()">Delete Instant Funnels
        <mdb-icon far icon="gem" class="ml-1"></mdb-icon>
      </a>
      <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="instantModal.hide()">No, thanks</a>
    </div>
  </div>
  <!--/.Content-->
</div>
</div>
  `,

  styleUrls: ['./crm.component.css']
})
export class ActivateFunnelsDialog implements OnInit {
  listname;
  clickbankId;
  autoresponder;
  listnameText="Unique List Id"
  @ViewChild('instantModal') instantModal: ModalDirective;
  niches=[
      {name:"Home Business",value:"hb"},
      {name:"Weight loss", value:"wl"},
      {name:"Personal Development",value:"pd"}
    ]
  niche;
  username
  domain=document.location.hostname;
  userIdl
  crmUser;
  masterTemplateId
  categoryRecords;
  totalRecord:any=[];
  optinSubtypeId;
  feSubtypeId;
  recordType;
  hbId:any
  wlId:any;
  pdId:any;
  categoryName;
  categoryId;
  clickBankIdExist:boolean=false;
  clickBankData:any
  showApiFields:boolean=false;
  account;
  consumer_secret;
  consumer_key

 
  constructor(
    private funnelService:FunnelsService, 
    private subTypeService:SubtypesService, 
    private categoriesService:CategoriesService, 
    private templatesService:TemplatesService, 
    private typesService:TypesService, 
    private productsService:ProductsService, 
    private autoresponderService:AutoresponderService, 
    private crmService:CrmService, 
    private confirmService: ConfirmService,
    private userPreferenceService:UserpreferenceService,
    private _service: ToastService,
    ) {
    this.confirmService.textData.subscribe(data => {
     console.log(data)
    })

    crmService.crmIsEdit.subscribe(
        crm => {
           
            this.crmUser=crm
            console.log(this.crmUser)
            this.userPreferenceService.getKeyByUser(this.crmUser.user,"clickbankId").subscribe(
              data => {console.log("datatst",data);
                this.clickbankId=data['obj'].value;
                this.clickBankIdExist=true;
                this.clickBankData=data['obj']!==null?data['obj']:{_id:"xxxx"};
                console.log(this.clickBankData)
              })
        })


    this.subTypeService.getRecordBySubtype({"subtype":"optin"}).subscribe(data=>{this.optinSubtypeId=data['obj']._id})
    this.subTypeService.getRecordBySubtype({"subtype":"fe"}).subscribe(data=>{this.feSubtypeId=data['obj']._id})
    this.typesService.getRecordByType({"type":"instant_funnel"}).subscribe(data=>{this.recordType=data['obj']._id})
    this.categoriesService.getRecordByIdentiifer({'identifier':'hb'}).subscribe(data=>{this.hbId=data['obj']._id})
    this.categoriesService.getRecordByIdentiifer({'identifier':'wl'}).subscribe(data=>{this.wlId=data['obj']._id})
    this.categoriesService.getRecordByIdentiifer({'identifier':'pd'}).subscribe(data=>{this.pdId=data['obj']._id})

    

  }

  selectAutoresponder=()=>{
    if (this.autoresponder=="getresponse"){
      this.showApiFields=true;
      this.listnameText="List Id";
    } else {
      this.showApiFields=false;
      this.listnameText="Unique List Id"
    }
    console.log(this.autoresponder)
  }

  selectNiche=()=>{
    this.categoriesService.getRecordByIdentiifer({"identifier":this.niche}).subscribe(
      categoryData => {
        //var categoryId = categoryData['obj']._id;
        this.categoryId=categoryData['obj']._id;
        this.categoryName = categoryData['obj'].name;
        this.funnelService.getUserRecordsByTypeAndCategory({'userId':this.crmUser.user,"type":this.recordType,"category":categoryData['obj']._id}).subscribe(
          funnelData => {
            console.log(funnelData['obj'].length)
            if (funnelData['obj'].length > 0){
              this.niche="";
              
              this.instantModal.show();
            } else {
              this.getTemplates(this.categoryId)
            }
        })
    })
  }

  deleteInstantFunnels=()=>{
    this.funnelService.deleteInstantFunnels({userId:this.crmUser.user,type:this.recordType,category:this.categoryId}).subscribe(
      data=>{
        console.log(data);
        this._service.success(data['message'], data['title'],{opacity:1});
        this.instantModal.hide();
      }
    )
  }


  getTemplates=(categoryId)=>{
    console.log(this.hbId,this.wlId,this.pdId)
    
    console.log(this.niche);
    
          this.typesService.getRecordByType({type:"master_template"}).subscribe(
            typesData => {
            
            
            var typeId = typesData['obj']._id;
            console.log(categoryId,typesData['obj'])
            this.templatesService.getCategoryAndMasterRecords({'categoryid':categoryId,'typeid':typeId}).subscribe(
              data => {
                console.log("my Tempkates", data)
                this.categoryRecords=data['obj']
              })
          })
        
     
  }

  

  findTemplate=(x,data, autoresponder_id, index, niche)=>{
    
    this.username=this.crmUser.username;
  
      var record: any={};
     
      record.wid=x;
      //record.category=$("#"+niche+"-listname").attr('data-categories-id');
      record.user=this.crmUser.user;
      record.display_order=index;
      record.username=this.crmUser.username;
      record.domain=this.domain;
      record.autoresponder=autoresponder_id;
      record.pages_used="both";
      record.affiliate_link=data.product_link;
      record.use_optin="true";
      record.type=this.recordType;
      record.name="instant-"+x+"-"+this.username;
    
      let y:any;
      
      for (y in this.categoryRecords){
      //for (y=0;y<this.categoryRecords.length;y++){
        
          if (this.categoryRecords[y].wid==x){
            
              if (this.categoryRecords[y].subtype==this.optinSubtypeId){
                  
                  record.optin=this.categoryRecords[y]._id;
                  record.image1=this.categoryRecords[y].image;
                  record.category=this.categoryRecords[y].category;
              } else if (this.categoryRecords[y].subtype==this.feSubtypeId){
                  record.category=this.categoryRecords[y].category;
                  record.fe=this.categoryRecords[y]._id;
                  record.image=this.categoryRecords[y].image;
              }
             // console.log (categoryRecords[x].wid, categoryRecords[x].subtype,categoryRecords[x]._id)
          }
      }  
     
          this.totalRecord.push(record);
      
  }

  processTotalRecord=(totalRecord,cb)=>{
    for (let index=0;index<totalRecord.length; index++){
      this.funnelService.addRecordForSomeoneElse(totalRecord[index]).subscribe(
        userFunnelData => {
          
          this._service.success(userFunnelData['message'], userFunnelData['title'],{opacity:1});
        }
      )
    }
  }

  save(model: any, isValid: boolean) {
    model.listname=this.listname;
    model.niche=this.niche;
    model.autoresponder=this.autoresponder;
    model.clickbankId=this.clickbankId;
    model.consumer_secret = this.consumer_secret;
    model.consumer_key = this.consumer_key;
    model.account = this.account;
    console.log("model",model)
    let that = this;
    this.addClickBankId(model, function(clickbankData){
       
        that.addAutoResponder(model, function(autoresponderData){
            
            let autoresponder_id=autoresponderData['obj']._id;
            that.getRecommendedProducts(model,function(data){
             
              let products = data, index=0, n
              for (n=0;n<products.obj.length; ++n){
                
                that.findTemplate(products.obj[n].wid,products.obj[n], autoresponder_id, products.obj[n].display_order, that.niche);
              }

              that.processTotalRecord(that.totalRecord, function(){})

            })

        })
    })
    

  }

  addClickBankId=(data,cb)=>{
    
  console.log("exist",this.clickBankIdExist )
   
    if (this.clickBankIdExist==false){
        let body={
          domain: this.domain,
          key: "clickbankId",
          value: data.clickbankId,
          group: 'programs',
          subgroup: 'clickbank',
          username: this.crmUser.username,
          userId: this.crmUser.user
          
        };

        console.log(body)
        this.userPreferenceService.addRecordForSomeoneElse(body).subscribe(
            data => {
                console.log("exists", "false", data)
                cb(data)
            }
        ) 
      } else if (this.clickBankIdExist==true){
        let body={
          domain: this.domain,
          key: "clickbankId",
          value: data.clickbankId,
          group: 'programs',
          subgroup: 'clickbank',
          username: this.crmUser.username,
          userId: this.crmUser.user,
          userpreferenceId:this.clickBankData._id
        };
        this.userPreferenceService.updateRecord(body).subscribe(
          data => {
            console.log("exists", "true",data)
            cb(data)
          }
        )
      }
  }

  addAutoResponder=(data,cb)=>{
    let body={
        domain: this.domain,
        autoresponder:data.autoresponder,
        listname:data.listname,
        consumer_secret:data.consumer_secret,
        consumer_key:data.consumer_key,
        account:data.account,
        username: this.crmUser.username,
        _id: this.crmUser.user,
        name:"instant_funnels"
    };

  
    this.autoresponderService.addRecordForSomeoneElse(body).subscribe(
        data => {
            console.log(data);
            cb(data)
        }
    ) 
  }

  getRecommendedProducts=(data,cb)=>{
    let body={
      product_line: data.niche,
      product_type:"recommended"
    };
    
    this.productsService.getAllRecordsByProductlineRecommeded(body).subscribe(
      data => {
        
        cb(data)
      }
    )
  }

  ngOnInit() {
    
  }

  pressed(data){
    this.confirmService.confirmPressed(data);
  }

  afterViewInit(){
   
  }

}
