
<div class="menulabel">WELCOME</div>
<button Type="button" mat-button class="btnlink" [routerLink]="['/members/gettingstarted']" routerLinkActive="activated-button">
        <mat-icon>home</mat-icon> Getting Started
</button>
<button Type="button" mat-button class="btnlink" [routerLink]="['/members/stepbystep']" routerLinkActive="activated-button">
        <mat-icon>question_answer</mat-icon> Step-By-Step
</button>
<button mat-button [routerLink]="['/members/account']" class="btnlink" routerLinkActive="activated-button"><mat-icon>account_circle</mat-icon> 
         Account
</button>
<button mat-button [routerLink]="['/members/logout']" class="btnlink" *ngIf="isLoggedIn()"><mat-icon>exit_to_app</mat-icon> Logout | {{username}}   </button>
<br><br>
<div class="menulabel">MAKE MONEY</div>
<button mat-button class="btnlink" [routerLink]="['/members/myfunnels']" routerLinkActive="activated-button" class="btnlink"><mat-icon>show_chart</mat-icon> My Sales Funnels</button>
<button mat-button class="btnlink" [routerLink]="['/members/commissions']" routerLinkActive="activated-button" class="btnlink"><mat-icon>attach_money</mat-icon> My Commissions</button>
<br><br>
<div class="menulabel">TRAFFIC</div>
<button mat-button class="btnlink" [routerLink]="['/members/doneforyoutraffic']" routerLinkActive="activated-button" class="btnlink"><mat-icon>traffic</mat-icon> Done-For-You Traffic</button>
<button mat-button class="btnlink" style="display:none;" [routerLink]="['/members/guaranteedoptins']" routerLinkActive="activated-button" class="btnlink"><mat-icon>people</mat-icon> Guaranteed Optins</button>
<button mat-button class="btnlink" [routerLink]="['/members/othertraffic']" routerLinkActive="activated-button" class="btnlink"><i class="material-icons">airport_shuttle</i> Other Traffic Methods</button>
<br><br>
<div class="menulabel">RESOURCES</div>
<button mat-button class="btnlink" [routerLink]="['/members/internetmarketinglibrary']" routerLinkActive="activated-button" class="btnlink"><mat-icon>language</mat-icon> Internet Marketing Library</button>
<button mat-button class="btnlink" [routerLink]="['/members/personaldevelopmentlibrary']" routerLinkActive="activated-button" class="btnlink"><mat-icon>looks</mat-icon> Personal Development Library</button>
<button mat-button class="btnlink" [routerLink]="['/members/marketing']" routerLinkActive="activated-button" class="btnlink"><mat-icon>contact_mail</mat-icon> Email Swipe File</button>
<button mat-button class="btnlink" [routerLink]="['/members/coaching']" routerLinkActive="activated-button" class="btnlink"><mat-icon>call</mat-icon> Bi-Weekly Coaching Calls</button>
<button mat-button class="btnlink" [routerLink]="['/members/oneononecoaching']" routerLinkActive="activated-button" class="btnlink"><mat-icon>school</mat-icon> 1-on-1 Coaching</button>
<br><br>

<div class="menulabel">CONTACT</div>
<button Type="button" mat-button class="btnlink" [routerLink]="['/members/faq']" routerLinkActive="activated-button">
        <mat-icon>question_answer</mat-icon> Faq
</button>
<button mat-button [routerLink]="['/members/contact']"  class="btnlink" routerLinkActive="activated-button" ><mat-icon>email</mat-icon> Contact</button>