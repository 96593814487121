<div class="container">
    <div class="row">
        <div style="width:100%">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <span class="glyphicon glyphicon-comment"></span><span style="padding-top:15px;padding-left:15px;">User:&nbsp; {{ msgData.room }}</span>
                    <div class="btn-group pull-right">
                        <button type="button" mat-button color="primary" class="btn btn-default btn-xs" (click)="logout()">
                            End Chat
                        </button>
                    </div>
                </div>
                <div #scrollMe class="panel-body">
                    <ul class="chat">
                        <li *ngFor="let c of chats">
                          <div class="left clearfix" *ngIf="c.nickname===msgData.nickname; else rightchat">
                            <span class="chat-img pull-left">
                              <img src="{{ c.avatar }}" alt="User Avatar" class="img-circle" />
                            </span>
                            <div class="chat-body clearfix">
                                <div class="header">
                                    <strong class="primary-font">{{ c.nickname }}</strong> <small class="pull-right text-muted">
                                        <span class="glyphicon glyphicon-time"></span>{{ c.updated_at | date: 'medium' }}</small>
                                </div>
                                <p>{{ c['message'] }}</p>
                            </div>
                          </div>
                          <ng-template #rightchat>
                            <div class="right clearfix">
                              <span class="chat-img pull-right">
                                <img src="{{c.avatar}}" alt="User Avatar" class="img-circle" />
                              </span>
                              <div class="chat-body clearfix">
                                  <div class="header">
                                      <small class=" text-muted"><span class="glyphicon glyphicon-time"></span>{{ c.updated_at | date: 'medium' }}</small>
                                      <strong class="pull-right primary-font">{{ c.nickname }}</strong>
                                  </div>
                                  <p>{{ c['message'] }}</p>
                              </div>
                            </div>
                          </ng-template>
                        </li>
                    </ul>
                </div>
                <div class="panel-footer">
                  <div style="padding:10px;">
                    <form (ngSubmit)="sendMessage()" #msgForm="ngForm">
                      <div class="input-group">
                          <input type="hidden" [(ngModel)]="msgData.room" name="room" />
                          <input type="hidden" [(ngModel)]="msgData.nickname" name="nickname" />
                          <input type="hidden" [(ngModel)]="msgData.action" name="action" />
                          <input type="hidden" [(ngModel)]="msgData.avatar" name="avatar" />
                          <input type="hidden" [(ngModel)]="msgData.thread" name="thread" />
                          <input id="btn-input" type="text" [(ngModel)]="msgData['message']" name="message" class="form-control input-sm" placeholder="Type your message here..." required="" />
                          <span class="input-group-btn">
                              <button mat-button color="primary" class="btn btn-sm" id="btn-chat" [disabled]="!msgForm.form.valid">
                                  Send</button>
                          </span>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
            
        </div>
    </div>
</div>