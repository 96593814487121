import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {ShortcodesService} from "../shortcodes.service";
import {Shortcodes} from "../shortcodes";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-shortcodes-input',
  templateUrl: './shortcodes-input.component.html',
  styleUrls: ['./shortcodes-input.component.css']
})
export class ShortcodesInputComponent implements OnInit {

  shortcodes:Shortcodes = null;

  constructor(private shortcodesService:ShortcodesService, private authService: AuthService, private _service: ToastService) {
    shortcodesService.newsIsEdit.subscribe(
      shortcodes => {
        this.shortcodes = shortcodes;
        console.log(this.shortcodes);
      })

  }

  save(f:NgForm, model: Shortcodes, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.shortcodesId){

      this.shortcodesService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.shortcodesService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
