import { Component, OnInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {User} from "../user";
import { ToastService } from 'ng-uikit-pro-standard';
import {Router, ActivatedRoute} from "@angular/router";
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-passwordresetbyemail',
  templateUrl: './passwordresetbyemail.component.html',
  styleUrls: ['./passwordresetbyemail.component.css']
})
export class PasswordresetbyemailComponent implements OnInit {
  public user: User;
  email:string;
  username:string;
  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private _service: ToastService) { }

  onSubmit(model: any, isValid: boolean) {
   // console.log(form.value.email);
   // const user = new User(null, form.value.username, null, null,null,null,form.value.email,null,null,null,null,null,null,null,null,null, null );
    console.log(model);
    this.authService.requestPassword(model)
      .subscribe(
        data => this._service.success(data['title'], data['message']),
        error => this._service.error('Error', error)
      ),
      error => this._service.error('Error', error); 
  }

  back(){
    this.router.navigate(['/signin']);
  }

  ngOnInit() {
  /*  this.user = {
      userId: "",
      username: "",
      password: "",
      password_check: "",
      firstname: "",
      lastname: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      skype: "",
      phone: "",
      paypal: "",
      roles: ""

    }*/
  }

}
