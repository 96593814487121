

        <div style="position:relative;height:400px;width:100%;">
            <div *ngIf="showImagePreview">
              <img  *ngIf="imagePreview" style="position:absolute;left:0%;max-width:70%;height:100%" [src]="sanitizer.bypassSecurityTrustUrl(imagePreview)">
            </div>
          <div style="width:100%"    (click)="imgFileInput.click()" (drop)="onDrop($event)" (dragover)="onDragOver($event)" style="border:dotted #0000ff;text-align:center;height:100%">
            
            <div *ngFor="let list of myFileList">
             
              <span><img src="https://s3.amazonaws.com/mediaguild/images/{{list.extension}}.png" >{{list.filename}}</span>
            </div>
            <input style="display:none" id="photo" type="file" #imgFileInput (change)="onImageChange($event)"  />
            <br><br>
            <button style="width:200px;position:absolute;top:40px;left:50%;margin-left:-100px;z-index:999;" mat-button type="button" color="primary" class="large-button" (click)="upload()" [disabled]="uploaded" *ngIf="showUploadButton">
              Upload
            </button>
            <mat-progress-spinner style="position:absolute;left:50%;margin-left:-50px;top:50%;margin-top:-50px;" mode="indeterminate" *ngIf="progress"></mat-progress-spinner>
          </div>
        </div>
   
        
        