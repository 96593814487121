import { Component, OnInit } from '@angular/core';
import { CommService } from '../../../services/comm.service';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {Observable} from 'rxjs'
import 'rxjs/add/observable/timer'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/take'
import { UserpreferenceService } from '../shared/userpreference/userpreference.service';
import { HellosignService } from '../hellosign/hellosign.service';
import * as moment from 'moment';

import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {
  guarantee=false;
  aweber=false;
  coaching=false;
  overview=false;
  offer=false;
  message:string;
  username;
  data: any;
  eseid: any;
  showTimer=true;
  targetDate;
  diff;
  future;
  token;
  frameSrc;
  clockValue;
  timestamp;


   
  constructor(private sanitizer: DomSanitizer, private cookieService:CookieService, private hellosignService: HellosignService, private userPreference: UserpreferenceService, private commService: CommService, private systempreferenceService:SystempreferenceService, private _service: ToastService) { 
    
  }

  openDrawer(drawerContent: string){
    
    this.clockValue = atob(localStorage.getItem(btoa('myClock')) ? localStorage.getItem(btoa('myClock')): '');
    
     
    if (drawerContent=="5"){
      if (moment().isBefore(this.clockValue)){
        this.commService.guarantee.toggle();
        this.commService.changeMessage(drawerContent);
      } else {
        this.offer=true;
        this._service.error('ERROR','You Have Already Used Your Special Traffic Offer');
      }
    } else {
        this.commService.guarantee.toggle();
        this.commService.changeMessage(drawerContent);
    }
    
  } 

  openCoachingDrawer(drawerContent: string){
    if (this.coaching==true){
      this._service.error("Already Done!", "You have already redeemed your coaching session");
      return;
    } else {
      this.commService.guarantee.toggle();
      this.commService.changeMessage(drawerContent);
    }
  }

  openAweberDrawer(drawerContent: string){
    if (this.aweber==true){
      this._service.error("Already Done!", "You have already submitted you autoresponder information");
      return;
    } else {
      this.commService.guarantee.toggle();
      this.commService.changeMessage(drawerContent);
    }
  }

  ngOnInit() {
    
    this.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl('/api/api/getsimpletimer?days=0&hours=0&minutes=60&seconds=0&cookiename=myClock&token='+this.token);
  
    this.commService.currentMessage.subscribe(message => message = message);
    //check the sutoresponder box yes if it has just been submitted
    this.commService.autoResponderGetYes.subscribe(message => {
      if (message=="1"){
        this.aweber=true;
      }
      console.log(message)
    })

    this.commService.freeCoachingGetYes.subscribe(message => {
      if (message=="1"){
        this.coaching=true;
      }
      console.log(message)
    })
    this.username = localStorage.getItem('username');

    this.eseid ={'eseid':this.username};
    this.hellosignService.verifySigned(this.eseid)
    .subscribe(
      data => {
        console.log(data);
        if (data['status']=="success"){
          this.guarantee=true;
        }
      }
    )

    this.userPreference.getGroupRecords('checklist')
      .subscribe(
        data => {
          this.data = data['obj'];
          console.log(data['obj'])
          var i;
          for (i=0; i < data['obj'].length; i++){
            switch (data['obj'][i].key ){
              case "autoresponder":
                if (data['obj'][i].value==1){
                  this.aweber=true;
                } else {
                  this.aweber=false;
                }
                break;
                case "guarantee":
                if (data['obj'][i].value==1){
                  this.guarantee=true;
                } else {
                  this.guarantee=false;
                }
                break;
                case "freecoaching":
                if (data['obj'][i].value==1){
                  this.coaching=true;
                } else {
                  this.coaching=false;
                }
                break;

            }
          }
        },
        //error => this._service.error(error.error['message'], error['title']),
      );

      
    
  }

}
