export class Record {
  fieldname: string;
  fieldvalue: string;
  row_id: string;

  constructor(fieldname: string, fieldvalue: string, row_id: string) {
    this.fieldname = fieldname;
    this.fieldvalue = fieldvalue;
    this.row_id = row_id
  }
}
