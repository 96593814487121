import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { Systempreference } from '../shared/systempreference/systempreference';
import { FunnelsService } from '../funnels/funnels.service';
@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})
export class MarketingComponent implements OnInit {
  username;
  webinarid;
  webinarlink;
  datas:any;
  body:any;
  constructor(private funnelsService:FunnelsService, private systempreferenceService:SystempreferenceService, private _service: ToastService) { 
    this.funnelsService.getAllRecordsSubtypes('email_swipes')
      .subscribe(
        data => {

          this.datas = data['obj'];
         // console.log(this.datas[0]);
          //this.body = atob(this.datas[0].body);
         // this._service.success(data['title'], data['message'])
        },
       // error => this._service.error(error.error['message'], error['title']),
      );
    const systempreference = new Systempreference(null, "webinarid", null, null);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        data => {
          console.log(data['obj'].value);
          this.webinarid=data['obj'].value;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );

      const systempreference1 = new Systempreference(null, "webinar_link", null, null);
      this.systempreferenceService.getKey(systempreference1)
        .subscribe(
          data => {
            this.webinarlink=data['obj'].value;
            this.webinarlink=this.webinarlink.replace(/\[WEBINARID\]/g, this.webinarid );
            this.webinarlink=this.webinarlink.replace(/\[USERNAME\]/g, this.username );
            console.log(this.webinarlink);
          },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
        );
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

}
