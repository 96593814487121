import { Component, OnInit } from '@angular/core';
import { FunnelsService } from '../funnels.service';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-funnels-front',
  templateUrl: './funnels-front.component.html',
  styleUrls: ['./funnels-front.component.css']
})
export class FunnelsFrontComponent implements OnInit {
  datas:any;
  body;
  mpd_link;
  token1;
  rootUrl="http://localhost:4000/api/templates/html/";
  constructor(private route: ActivatedRoute, private funnelsService:FunnelsService, private systempreferenceService: SystempreferenceService) { 
    this.route.params.subscribe( params => {
      console.log(params.templates) ;
      this.funnelsService.getAllRecordsSubtypes(params.templates)
        .subscribe(
          data => {
            console.log(data);
            this.token1 = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
        //  const systempreference = new Systempreference(null, "mpd_link", null, null);
          const systempreference = new Systempreference(null, document.location.hostname, null, null);
            this.systempreferenceService.getKey(systempreference)
              .subscribe(
                data1 => {
                  var myJson = JSON.parse(data1['obj'].value);
                  console.log(myJson);
                  
                  this.mpd_link=myJson.mpd_link;
                  console.log(this.mpd_link);
                  this.datas = data['obj'];
                  console.log(this.datas);
                },
                error => {
                  return "error";
                }
              );

            
          // this.body = atob(data['obj'].body);
          // this._service.success(data['title'], data['message'])
          },
        // error => this._service.error(error.error['message'], error['title']),
        );
    });
  }

  selectTxt(funnel){
    console.log(funnel)
  }

  ngOnInit() {
  }

}
