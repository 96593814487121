import { Component, OnInit } from '@angular/core';
import {Emailsection} from "../emailsection";
import {EmailsectionService} from "../emailsection.service";
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-emailsection-list',
  templateUrl: './emailsection-list.component.html',
  styleUrls: ['./emailsection-list.component.css']
})
export class EmailsectionListComponent implements OnInit {
  emailsections: Emailsection[] = [];
  constructor(private emailsectionService:EmailsectionService, private authService: AuthService, private _service: ToastService) { }

  ngOnInit() {
    this.emailsectionService.getAllEmails()
      .subscribe(
        emailsections => {
          console.log(emailsections);
          this.emailsections = emailsections['obj'];
          console.log(emailsections['obj']);
          this.emailsectionService.emailsections = emailsections['obj'];

        },
        error => {
          console.log(error);
          this._service.error("error", "You have no links setup")
        }
      );
  }

}
