
<div style="width:100%;background-color:#000;position:relative;">
    <button mat-icon-button mat-mini-fab color="primary" mat-dialog-close style="position:absolute;top:-20px;right:-20px;z-index:999">
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
</div>
<h2 mat-dialog-title>View Record</h2>
<mat-dialog-content>
  <b>Notes</b><br/>
  <hr>
  {{notes}}
  <hr>
</mat-dialog-content>
<mat-dialog-actions></mat-dialog-actions>
