import {Injectable, EventEmitter} from '@angular/core';
import {Menus} from "./menus";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class MenusService {
  private hostName = window.location.protocol + '//' + window.location.host;
  menus: Menus[] = [];
  menusIsEdit = new EventEmitter<Menus>();
  constructor(private http: HttpClient) { }

  editMenus(menus: Menus) {
    console.log(menus)
    this.menusIsEdit.emit(menus);
  }

  addRecord(menus: Menus) {
    const body = JSON.stringify(menus);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/menus/addrecord' + token, body, {headers: headers})
     
  }

  deleteRecord(menus: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/menus/deleterecord/' + menus._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/menus/getallrecords'+token,  {headers: headers})
     
  }

  updateRecord(menus: any){
    console.log(menus)
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.patch(this.hostName +'/api2/menus/updaterecord/' + menus.menusId, menus, {headers: headers})
      

  }

  getRecord(id) {
    console.log(id)
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.get(this.hostName +'/api2/menus/getrecord/'+id,  {headers: headers})
     
  }

  getRecordByName(name) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/menus/getrecordbyname/'+name+token,  {headers: headers})
      
  }

}
