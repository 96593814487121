import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class ChatService {
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private httpclient: HttpClient) { }

  getChatByRoom(room){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/chat'+room+token)
  }

  saveChat(data){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/chat'+token, data, {headers: headers})
  }

  getAllQueueRecords(){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/chat/getallqueuerecords'+token)
    return this.httpclient.post(this.hostName +'/api/chat/getallqueuerecords'+token,  {headers: headers})
  }

  getAllQueueRecordsByStatus(status){
    const body=JSON.stringify(status)
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/chat/getallqueuerecords'+token)
    return this.httpclient.post(this.hostName +'/api/chat/getallqueuerecordsbystatus'+token, body,  {headers: headers})
  }

  deleteQueueRecord(id){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.delete(this.hostName +'/api/chat/deletequeuerecord/' + id + token, {headers: headers})
  }

  addQueueRecord(chatstuff){
    const body=JSON.stringify(chatstuff)
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/chat/addqueuerecord' + token);
    return this.httpclient.post(this.hostName +'/api/chat/addqueuerecord' + token, body, {headers: headers})
  }

  updatestatusonqueuerecord(chatstuff){
    const body=JSON.stringify(chatstuff)
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/chat/updatestatusonqueuerecord' + token);
    return this.httpclient.post(this.hostName +'/api/chat/updatestatusonqueuerecord' + token, body, {headers: headers})
  }

}