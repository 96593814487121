import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
//import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {FunnelsService} from "../funnels.service";
import {Funnels} from "../funnels";
import {FormControl, NgForm, NgModel, Validators} from "@angular/forms";
import { Templates } from '../../templates/templates';
import {CategoriesService} from "../../categories/categories.service";
import {Categories} from "../../categories/categories";
import { TypesService } from '../../types/types.service';
import { Types } from '../../types/types';
import { Subtypes } from '../../subtypes/subtypes';
import { SubtypesService } from '../../subtypes/subtypes.service';
import { FunnelsComponent } from '../funnels.component';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { TemplatesService } from '../../templates/templates.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { MatDialog } from '@angular/material/dialog';
import {TemplatesInputComponent} from "../../templates/templates-input/templates-input.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { GrapesjsComponent } from '../../webbuilder/grapesjs/grapesjs.component';
import { ConfirmComponent } from '../../shared/confirm/confirm.component';
import { ConfirmService } from '../../shared/confirm/confirm.service';


@Component({
  selector: 'app-myfunnels-input',
  templateUrl: './myfunnels-input.component.html',
  styleUrls: ['./myfunnels-input.component.css']
})
export class MyfunnelsInputComponent implements OnInit {
  
 
  @ViewChild('myCategory', { static: false }) public myCategory: ElementRef;

  @ViewChild('order', { static: false }) public myOrder: ElementRef;

  @ViewChild('configModal') configModal: ModalDirective;
  
  onCloseConfigModal($event){ this.configModal.hide();}
  onOpenConfigModal(){console.log('dave sloan');this.configModal.show();}
  onConfigModalClosed($event){};

  funnels:Funnels = null;
  optins: Templates = null;
  fes: Templates = null;
  checkouts: Templates = null;
  checkout2s: Templates = null;
  oto1s: Templates = null;
  oto2s: Templates = null;
  downloads: Templates = null;
  download2s: Templates = null;
  oto1_downloads: Templates = null;
  oto2_downloads: Templates = null;
  thank_yous: Templates = null;
  marketings: Templates = null;
  categories: any = null;
  types: any = null;
  subtypes: any = null;
  preBuilt;
  optinImage
  optinType
  optinName
  bridgeImage
  bridgeName
  bridgeType
  templates=[] 
  templatesMainArray=[]
  allTemplatesArray=[]
  myOrderArray=[]
  ioToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  categoryName="hb";
  subTypeName="optin";
  typeName="master-template-v2";
  categoryId;
  typeId;
  subtypeId;
  galleryData=[];
  layouts;
  funnelId;
  arrayOfSubtypeObj:any = [];
  arrayOfTypeObj:any=[];
  arrayOfCatgoryObj:any=[];
  subTypeObj=[];
  typeObj=[];
  categoryObj=[];
  showAllButtons=[];
  showMainButtons=[];
  testArray=[];
  funnelsOrder;
  confirmPressed = false;

  subjectControl = new FormControl("", Validators.required);
  
  
  
  constructor(
    public dialog: MatDialog, 
    private templateService: TemplatesService, 
    private notificationService: NotificationsService, 
    private subtypesService: SubtypesService, 
    private typesService: TypesService,
    private categoriesService: CategoriesService,
    private funnelsService:FunnelsService, 
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService:ToastService,
    private confirmService:ConfirmService) {
      this.funnelId = this.route.snapshot.params.funnelid
     
      this.funnelsService.getRecordV2(this.funnelId).subscribe(
        funnels =>  {
          this.funnels = funnels['obj'];
          this.funnelsOrder=this.funnels.order;
          this.initFunnel(()=>{})
        }
      )

      this.confirmService.pressedConfirm.subscribe(
        data => {
          this.confirmPressed = data;
        })
  }

  initFunnel = (cb) => {
    this.getCategory(this.categoryName,this,function(categoryObj,thus){
      
      thus.categoryId=categoryObj._id
     
      thus.getSubtype(thus.subTypeName, thus,function(subtypeObj,thus){
        
        thus.subtypeId=subtypeObj._id;
        thus.getType(thus.typeName,thus, function(typeObj, thus){
          
          thus.typeId=typeObj._id;
          
          thus.processTemplates(typeObj,subtypeObj,categoryObj)
        })
      })
    }) 
  }


  getCategory=(category,thus,cb)=>{
    this.categoriesService.getRecordByIdentiifer({identifier:category}).subscribe(category => {
    
      cb(category['obj'],thus)
    })
  }

  getSubtype=(subtype,thus,cb)=>{
    this.subtypesService.getRecordBySubtype({subtype:subtype}).subscribe(subtype => {
     
      cb(subtype['obj'],thus)
    })
  }

  getType=(type,thus,cb)=>{
    this.typesService.getRecordByType({type:type}).subscribe(type => {
      cb(type['obj'],thus)
    
    })
  }

  checkMainArray=(cb)=>{
    for (let i = 0; i<this.templatesMainArray.length; i++){
      if (this.templatesMainArray[i].typeType=="my_templates"){
        this.showMainButtons[i]=true;
      } else {
        this.showMainButtons[i]=false;
      }
    }
    cb();
  }

  checkAllArray=(cb)=>{
    for (let i = 0; i<this.allTemplatesArray.length; i++){
      if (this.allTemplatesArray[i].typeType=="my_templates"){
        this.showAllButtons[i]=true;
      } else {
        this.showAllButtons[i]=false;
      }
    }
    cb();
  }

  processTemplates=(type,subtype,category)=>{
   
    this.processAllArray(type, subtype, category,() => {
      console.log(this.allTemplatesArray)
      this.processMainArray( () => {
        this.removeDups(this.allTemplatesArray, this.templatesMainArray, (allArray) => {
          this.allTemplatesArray=allArray;
          this.checkMainArray(() => {
            this.checkAllArray(()=>{})
          })
        }) 
      })
    });
    
    this.templates.push(this.funnels.optin);
    this.templates.push(this.funnels.fe);
    this.templates.push(this.funnels.checkout);
  }

  refresh(){}

  filter(event){
    this.processFilter (()=>{
      
      this.processTemplates(this.typeObj,this.subTypeObj,this.categoryObj)
    })
  }

  processFilter(cb){
   for (let i=0;i<this.categories.length;i++){
    if (this.categories[i]._id==this.categoryId){
      this.categoryObj=this.categories[i]
    }
   }

   for (let i=0;i<this.subtypes.length;i++){
    if (this.subtypes[i]._id==this.subtypeId){
      this.subTypeObj=this.subtypes[i]
    }
   }

   for (let i=0;i<this.types.length;i++){
    if (this.types[i]._id==this.typeId){
      this.typeObj=this.types[i];
      
    }
   }
   
   cb();
    
  }

  @Output() public closeButtonClick:EventEmitter<any> = new EventEmitter();
  //onCloseButtonClick($event){ this.closeButtonClick.emit('close');}

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   
    return dateString;;
  }

  public addWebBuilder(){
    let dialogRef = this.dialog.open(GrapesjsComponent, {
      
      width: '100%',
      height: '100vh',
      panelClass: 'bg-color' 

    });
    var data={"_id":"new"}
   
    this.templateService.editTemplates(data);

    dialogRef.afterClosed().subscribe(result => {
      localStorage.removeItem('gjs-css');
      localStorage.removeItem('gjs-html');
      localStorage.removeItem('gjs-components');
      localStorage.removeItem('gjs-styles');
      localStorage.removeItem('gjs-assets');
      localStorage.removeItem('gjsProject');

      dialogRef = null;
    });


  }

  onActionDeleteClick(id){
    console.log(id)
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn",okButtonTxt:"Confirm?", cancelButtonTxt:"Close"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.templateService.deleteRecord({_id:id}).subscribe(
          data => {
            for (let i = 0; i<this.templatesMainArray.length; i++){
              if (this.templatesMainArray[i]._id==id){
                this.templatesMainArray.splice(i,1);
              } 
            }
            this.funnelsOrder=JSON.stringify(this.templatesMainArray);
            this.checkMainArray(() => {
              this.checkAllArray(() => {})
            })
            let body = {_id:this.funnelId,config:this.funnelsOrder}
            this.funnelsService.updateFunnelOrder(body).subscribe(
              data => {
                this.toastrService.success(data['message'],data['title'], {opacity:1})
              },
              error => {
                this.toastrService.error(error['message'],error['error'], {opacity:1})
              }
            )
          }
        )
      }
    });
  }
  

  onActionPreviewClick(params){
    console.log(params)
    window.open("/"+params,'preview');
   }

  public onActionViewSovrnClick(data: any){
    console.log(data)
    let dialogRef = this.dialog.open(GrapesjsComponent, {
      
      width: '100%',
      height: '100vh',
      panelClass: 'bg-color' 

    });

    

    
   
    this.templateService.editTemplates({_id:data});

   // this.templateService.setType(this.types);
   // this.templateService.setSubType(this.subtypes);
   // this.templateService.setCategory(this.categories);

    dialogRef.afterClosed().subscribe(result => {
      
      localStorage.removeItem('gjs-css');
      localStorage.removeItem('gjs-html');
      localStorage.removeItem('gjs-components');
      localStorage.removeItem('gjs-styles');
      localStorage.removeItem('gjs-assets');
      localStorage.removeItem('gjsProject');

      dialogRef = null;
    });

    
  }


  drop(event: CdkDragDrop<string[]>) {
   
    if (event.previousContainer === event.container) {
     
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
                       
      
      
    }
      
    this.funnelsOrder=JSON.stringify(this.templatesMainArray);
    this.checkMainArray(() => {
      this.checkAllArray(() => {})
    })
  }

  return () {
    this.router.navigateByUrl('/members/myfunnels')
  }

  processMainArray = (cb) => {
    if (this.funnelsOrder){
     
      this.templatesMainArray=JSON.parse(this.funnelsOrder);
      cb();
    } else {
      for (let i=0;i<this.templates.length; i++){
        this.templateService.getRecordwithpopulate(this.templates[i])
        .subscribe(
          optinData => {
           
            this.templatesMainArray.push({
              _id:optinData['obj']._id,
              wid:optinData['obj'].wid,
              subtype:optinData['obj'].subtype._id,
              subTypeName:optinData['obj'].subtype.name,
              subTypeSubtype:optinData['obj'].subtype.subtype,
              type:optinData['obj'].type._id,
              typeName: optinData['obj'].type.name,
              typeType: optinData['obj'].type.type,
              category:optinData['obj'].category._id,
              categoryName: optinData['obj'].category.name,
              categoryCategory: optinData['obj'].category.category,
              name:optinData['obj'].name,
              image:optinData['obj'].image, 
              parent:optinData['obj'].parent
            })
            cb();
          },
          error => {
          },
        );
      }
    } 
    console.log(this.templatesMainArray)
  }

  processAllArray = (type, subtype, category, cb) => {
   
    let body = {
      typeid:type._id,
      subtypeid: subtype._id,
      categoryid: category._id
    }
   
    if (type.type=="my_templates"){
      this.templateService.getMyRecordBySubtypeAndTypeAndCategory(body).subscribe(
        data=>{
          console.log(data['obj'])
         this.allTemplatesArray=[];
          for (var i=0; i < data['obj'].length; i++){
            console.log(data['obj'][i])
            
           
            this.allTemplatesArray.push({
              _id:data['obj'][i]._id,
              wid:data['obj'][i].wid,
              subtype:data['obj'][i].subtype._id,
              subTypeName:data['obj'][i].subtype.name,
              subTypeSubtype:data['obj'][i].subtype.subtype,
              type:data['obj'][i].type._id,
              typeName: data['obj'][i].type.name,
              typeType: data['obj'][i].type.type, 
              category:data['obj'][i].category._id,
              categoryName: data['obj'][i].category.name,
              categoryCategory: data['obj'][i].category.category,
              name:data['obj'][i].name,
              image:data['obj'][i].image
            })
          }
          
          cb();
        })
    } else {
      this.templateService.getRecordBySubtypeAndTypeAndCategory(body).subscribe(
        data=>{
        
        this.allTemplatesArray=[];
          for (var i=0; i < data['obj'].length; i++){
            
            this.allTemplatesArray.push({
              _id:data['obj'][i]._id,
              wid:data['obj'][i].wid,
              subtype:data['obj'][i].subtype._id,
              subTypeName:data['obj'][i].subtype.name,
              subTypeSubtype:data['obj'][i].subtype.subtype,
              type:data['obj'][i].type._id,
              typeName: data['obj'][i].type.name, 
              typeType: data['obj'][i].type.type, 
              category:data['obj'][i].category._id,
              categoryName: data['obj'][i].category.name,
              categoryCategory: data['obj'][i].category.category,
              name:data['obj'][i].name,
              image:data['obj'][i].image
            })
          }
          
          cb();
        })
    }
    

    this.checkMainArray(()=>{
      this.checkAllArray(() =>{})
    })
    
  }

  edit(){
    console.log("dave")
  }

  async removeDups(allArray, mainArray, cb){
   
    var myNewArray=[];
    for (var i=0; i<mainArray.length; i++ ){
      for (var j=0; j<allArray.length; j++){
        if (mainArray[i]._id==allArray[j]._id){
         
          await allArray.splice(j,1);
        }
      }
    }
    
    cb(allArray)
  }

  cloneandedit(templates){
   
    this.templateService.copyRecordSetTypeSetSource(templates)
          .subscribe(
            data => {
            
              this.notificationService.updateMessage(data['title'],data['message'],'success','check_circle',  5000,function(){});
            },
            error => this.notificationService.updateMessage(error['title'],error.error['message'],'warn','warning',  5000,function(){})
          );
  }

  @ViewChild('f') infoForm;
  onSubmitForm(){
    if (this.infoForm.form.valid){
     
      this.infoForm.ngSubmit.emit()
    }
  }


  save(f:NgForm, model: Funnels, isValid: boolean) {
    
      model.order=JSON.stringify(this.templatesMainArray);
     
      for (let i=0;i<this.templatesMainArray.length;i++){
        switch (this.templatesMainArray[i].subTypeName){
         
          case "Optin":
            model.optin = this.templatesMainArray[i]._id;
          break;
          
        }
      }
      
      if (model.funnelsId){
        model.tracking = btoa(this.preBuilt);
        this.funnelsService.updateRecord(model)
          .subscribe(
            data => {
            this.notificationService.updateMessage(data['title'],data['message'],'success','check_circle',  5000,function(){});
            this.toastrService.success(data['message'],data['title'], {opacity:1})
            },
              error => this.toastrService.error(error['message'],error['title'], {opacity:1})
          )
      } else {
        model.wid=Date.now() +  "-" + this.randomNumber();
        this.funnelsService.addRecord(model)
          .subscribe(
            data => {
              this.toastrService.success(data['message'],data['title'], {opacity:1})
              f.reset();
            },
            error => this.toastrService.error(error['message'],error['title'], {opacity:1})

          )
      }
  
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  ngOnInit() {

    this.templateService.getLayouts('layout_file')
      .subscribe(
        data3 => {
          this.layouts = data3['obj'];
          
        },
        error => {
         
          
        },
      );
    
    this.categoriesService.getAllRecordsByRole({role:"user"})
      .subscribe(
        data => {
          this.categories = data['obj'];
         
        },
        error => {
       
        },
      );

      this.subtypesService.getAllRecordsByRole({role:"user"})
      .subscribe(
        data => {
          this.subtypes = data['obj'];
        },
        error => {
        
        },
      );


      this.typesService.getAllRecordsByRole({role:"user"})
      .subscribe(
        data => {
          this.types = data['obj'];
         
          
        },
        error => {
       
        },
      );

     
  }

}
