import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {DomainsService} from "../domains.service";
import {Domains} from "../domains";
import {NgForm} from "@angular/forms";
import { trigger, state, transition, style, animate } from '@angular/animations'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomainsStepperIframeComponent } from './domains-stepper-iframe.component';
import { ConfirmService } from '../../shared/confirm/confirm.service';
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import { CommService } from '../../../../services/comm.service';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { SystempreferenceService } from '../..//shared/systempreference/systempreference.service';
import { NoPreloading } from '@angular/router';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { ToastService } from 'ng-uikit-pro-standard';

interface Niche {
  value: string;
  viewValue: string;
  isChecked:boolean;
}

@Component({
  selector: 'app-domains-stepper',
  template: `
  <form #f="ngForm">
    <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="question">Do you have an existing domain name you would like to use with ESE?</div>
        <br>
        <div>
            <button class="wizard-button-no" mat-flat-button color="primary" (click)="openPage('https://www.12minuteaffiliate.com/domain-registration')" matStepperPrevious type="button">No</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" matStepperNext type="button">Yes</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" type="button" mat-dialog-close>Close</button>
        </div>
        <div style="position:relative;width:100%;height:50vh;">
          <div style="position:absolute;bottom:0px;right:0px;width:35%"><img style="width:100%;" src="/assets/images/domain-featured-image.png"></div>
        </div>
    </mat-step>
    <mat-step optional="false">
        <ng-template matStepLabel></ng-template>
        <div class="question">Please Enter the Domain Name Below?</div>
        <mat-form-field class="example-full-width">
            <input matInput name="domain" #domain="ngModel" (keyup)="validateDomain($event)" [ngModel]="domains?.domain" type="text" id="domain" class="example-full-width" required>
        </mat-form-field>
        <div>
            <button class="wizard-button-no" mat-flat-button color="primary" matStepperPrevious type="button">Back</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" matStepperNext type="button" [disabled]="isDomainValid">Next</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" type="button" mat-dialog-close>Close</button>
        </div>
        <div style="position:relative;width:100%;height:40vh;">
          <div style="position:absolute;bottom:0px;right:0px;width:35%"><img style="width:100%;" src="/assets/images/deskgirl.png"></div>
        </div>
    </mat-step>
    
    <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="question">Check the box (or boxes) of the niche you want to attach this domain to.</div>
        <br>
          <mat-checkbox *ngFor="let niche of niches" (click)="clickNiche($event, niche.viewValue, niche.viewValue, niche.isChecked)" (change)="getNiche($event,niche.viewValue )" style="padding-left:20px;" [value]="niche.value" [checked]="niche.isChecked" [attr.data-description]="niche.viewValue">{{niche.viewValue}}</mat-checkbox>
        <br><br>
        <div>
            <button class="wizard-button-no" mat-flat-button color="primary" matStepperPrevious type="button">Back</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" matStepperNext type="button" >Next</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" type="button" mat-dialog-close>Close</button>
        </div>
        <div style="position:relative;width:100%;height:40vh;">
          <div style="position:absolute;bottom:0px;right:0px;width:35%"><img style="width:100%;" src="https://esev2uploaded.s3.amazonaws.com/dsloan254/1588687936968.3257.VdpMAAn1WO.choosefunnel.png"></div>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="question">Sometimes we can configure the domain name registrar to work with ESE. We currently can do this for godaddy. Do you want us to give it a shot?</div>
        <br>
        
     
        <div>
            <button class="wizard-button-no" mat-flat-button color="primary" matStepperPrevious type="button" >Back</button>&nbsp;
            <button class="wizard-button-no" mat-flat-button color="primary" type="button"  (click)="illDoItMyself(f, f.value, f.valid)">Nope, I'll do it myself</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" matStepperNext type="button" >Yes, Help me out</button>&nbsp;
            <button class="wizard-button-yes" mat-flat-button color="primary" type="button" mat-dialog-close>{{closeTxt}}</button>
        </div>
        <br>
        <div style="position:relative;height:60px;" >
          <div style="width:100%;height:60px;padding:0px;0px;0px;0px" *ngIf="showTwelveOnMyOwn">
            <img [src]="twelveImg" style="height:60px;width:50px;"><span style="position: absolute;left:50px;bottom:7px;color:#000;font-weight:bold;font-size:18pt;" >Configuring domain on ESE</span>    
          </div>
        </div>
        <div style="position:relative;width:100%;height:40vh;">
          <div style="position:absolute;bottom:0px;right:0px;width:30%"><img style="width:100%;" src="/assets/images/cartoon-color-characters-persons-thinking.png"></div>
        </div>
       
    </mat-step>
    
    <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="question">OK, We'll see what we can do. We are going to need a couple of pieces of information from your godaddy account. </div>
        <a style="color:blue;cursor:pointer" (click)="openPage('https://www.12minuteaffiliate.com/domain-registration-info')">Click Here and follow these instructions to get the correct info from GoDaddy</a>.
                  <br>
                  <a style="color:blue;cursor:pointer" (click)="openPage('https://developer.godaddy.com')">Click Here to go to developer.godaddy.com</a>.
                  <br>
            <mat-form-field style="width:50%">
              <mat-label style="font-size:18pt">Your Register is:</mat-label>
              <mat-select (selectionChange)="registrarSelected1($event)" name="registrar" #registrar="ngModel" [ngModel]="selected" id="registrar" style="font-size:18pt;">
                <mat-option value="godaddy">
                    Godaddy
                </mat-option>
              </mat-select>
            </mat-form-field>
              <mat-grid-list cols="2" rowHeight="2:1" style="position:relative">
              
              <mat-grid-tile>
                <div style="width:100%">
                  
                  <mat-form-field style="width:50%">
                      <mat-label>Customer Number</mat-label>
                      <input matInput name="customerNo" #customerNo="ngModel" [ngModel]="domains?.customerNo" type="text" id="customerNo" style="width:50%" required>
                      <mat-error>This is a required field</mat-error>
                  </mat-form-field>
                  <br>
                  <mat-form-field style="width:50%">
                      <mat-label>API Key</mat-label>
                      <input matInput name="apikey" #apikey="ngModel" [ngModel]="domains?.apikey" type="text" id="apikey" required>
                      <mat-error>This is a required field</mat-error>
                  </mat-form-field>
                  <br>
                  <mat-form-field style="width:50%">
                      <mat-label>Secret</mat-label>
                      <input matInput name="apisecret" #apisecret="ngModel" [ngModel]="domains?.apisecret" type="text" id="apisecret" required>
                      <mat-error>This is a required field</mat-error>
                  </mat-form-field>
                  <div>
                    <button class="wizard-button-no" mat-flat-button color="primary" matStepperPrevious type="button">Back</button>&nbsp;
                    <button class="wizard-button-yes" mat-flat-button color="primary" type="button" [disabled]="!f.valid" (click)="confirm(f, f.value, f.valid)">Do It!</button>&nbsp;
                    <button class="wizard-button-yes" mat-flat-button color="primary" type="button" mat-dialog-close>{{closeTxt}}</button>
                  </div>
                </div>
              </mat-grid-tile>
              <mat-grid-tile style="position:relative;">
              
                <div style="position:absolute;top:20px;left:0px;width:100%;height:60px;padding:0px;0px;0px;0px" *ngIf="showTwelve">
                  <img [src]="twelveImg" style="height:60px;width:50px;"><span style="position: absolute;left:50px;bottom:7px;color:#000;font-weight:bold;font-size:18pt;" >Configuring domain on ESE</span>
                  
                </div>
                <div style="position:absolute;top:105px;left:0px;width:100%;height:60px;" *ngIf="showDomain">
                <img [src]="domainImg" style="height:60px;width:50px;"><span style="position: absolute;left:50px;bottom:7px;color:#000;font-weight:bold;font-size:18pt">Configuring GoDaddy DNS</span>
                  
                </div>
                <div style="position:absolute;top:190px;left:0px;width:100%;height:60px;" *ngIf="showForward">
                <img [src]="forwardImg" style="height:60px;width:50px;"><span style="position: absolute;left:50px;bottom:7px;color:#000;font-weight:bold;font-size:18pt">Configuring GoDaddy forwarding</span>
                  
                </div>
              </mat-grid-tile>
            </mat-grid-list> 
    </mat-step>
    </mat-horizontal-stepper>
</form>
<app-notifications></app-notifications>
  `,
  styleUrls: ['./domains-input.component.css']
})

export class DomainsStepperComponent implements OnInit {
  domains:Domains = null;
  visiblityState = 'hidden';
    messageTitle;
    messageBody;
    icon;
    step:String=null;
    isLinear = false;
    confirmPressed = false;
  link;
  target;
  linkTxt;
  showDefaultRegistrar=true;
  completeMessage;
  selected="godaddy";
  currentState = 'initial';
  currentDomainState="initial1";
  currentForwardState="initial2";
  TwelveMASize=1;
  domainSize=1;
  forwardSize=1;
  divMessages="helllo";
  twelveColor="red";
  domainColor="red";
  forwardColor="red";
  showTwelve=true;
  showDomain=true;
  showForward=true;
  twelveMessage='';
  domainMessage='';
  forwardMessage='';
  twelveImg='/assets/images/checkbox.png';
  domainImg='/assets/images/checkbox.png';
  forwardImg='/assets/images/checkbox.png';
  twelveImgCheck="no";
  domainImgCheck="no";
  forwardImgCheck="no";
  closeTxt="Close";
  showTwelveOnMyOwn=false;
  level;
  nullValue = null;
  selectedNiches=[];
  niches: Niche[] = [];
  myDomains;
  myMessage;
  isDomainValid=true;

  constructor(private _service: ToastService,private renderer: Renderer2, private notificationService:NotificationsService, private systempreferenceService:SystempreferenceService,private commService:CommService, private stepperDialogRef:MatDialogRef<DomainsStepperComponent>, private confirmService: ConfirmService, public dialog: MatDialog, private domainsService:DomainsService, private authService: AuthService) {
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
     this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          var myJson = JSON.parse(systemPref['obj'].value);
          this.link=myJson.godaddy_help_page.link;
          this.linkTxt=myJson.godaddy_help_page.linkTxt;
          this.target=myJson.godaddy_help_page.target;
        },
        error => {
          
          this._service.error(error.error['message'],error['title'],{opacity:1})
        }
      );
//test
  /* if (this.level.includes('gold')){
      this.niches = [
        {value: 'hb', viewValue: 'Home Business', isChecked: false},
        {value: 'wl', viewValue: 'Weight Loss', isChecked: false},
        {value: 'pd', viewValue: 'Personal Development', isChecked: false}
      ];
    } else if (this.level=="basic|Home Business" || this.level=="basic-lifetime|Home Business"){
      this.niches = [
        {value: 'hb', viewValue: 'Home Business', isChecked: false},
      ];
    } else if (this.level=="basic|Weight Loss"||this.level=="basic-lifetime|Weight Loss"){
      this.niches = [
        {value: 'wl', viewValue: 'Weight Loss', isChecked: false},
      ];
    } else if (this.level=="basic|Personal Development"||this.level=="basic-lifetime|Personal Development"){
      this.niches = [
        {value: 'pd', viewValue: 'Personal Development', isChecked: false}
      ];
    } */
    
    domainsService.domainsIsEdit.subscribe(
      domains => {
        domains.registrar="godaddy";
        this.domains = domains;
        
        
        console.log(this.domains);
      })

      this.domainsService.getAllMyRecords()
      .subscribe(
        data1 => {
          this.myDomains = data1['obj'];
        },
        error => {},
      );

    this.confirmService.pressedConfirm.subscribe(
    data => {
        this.confirmPressed = data;
    })

  }

  validateDomain($event){
    let regex = new RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g)
    if (regex.test($event.target.value)===true){
      this.isDomainValid=false;
    } else {
      this.isDomainValid=true;
    }
  }


  clickNiche($event, value, viewValue, isChecked){
    if (this.myDomains==undefined) return;
    if (isChecked==true) return;
    for (var i = 0; i < this.myDomains.length; i++) {
      var niche=this.myDomains[i].niche;
      if (niche){
         if (niche.includes(value)){
          
           this._service.error("You already have a domain associated with the " +viewValue+" niche.","ERROR",{opacity:1})
            return $event.checked=false;
          }
      }
    }
  }

  getNiche($event, viewValue){
    if ($event.checked==true){
      this.selectedNiches.push($event.source.value+"|"+viewValue)
    } else if ($event.checked==false){
      
      var filteredAry = this.selectedNiches.filter(e => e !== $event.source.value+"|"+viewValue)
      this.selectedNiches=filteredAry;
    }
  }

  changeState() {
    
    if (this.twelveImgCheck=="yes"){
      this.twelveImg='/assets/images/checkmark.png';
    } else {
      this.twelveImg='/assets/images/x.png';
    }
    setTimeout(() => { 
      if (this.domainImgCheck=="yes"){
          this.domainImg='/assets/images/checkmark.png';
      } else {
        this.domainImg='/assets/images/x.png';
      }
      setTimeout(() => { 
        if (this.forwardImgCheck=="yes"){
          this.forwardImg='/assets/images/checkmark.png';
        } else {
          this.forwardImg='/assets/images/x.png';
        }
        
      }, 1500);
    }, 1500);
   //this.currentState = 'final';
   // const p: HTMLDivElement = this.renderer.createElement('div');
   // var appendElement = 'test Me' ;
   // this.divMessages = appendElement; 
    
   // console.log(this.currentDomainState);
  //  console.log(this.currentState);
  }

  handle12MADone($event){
    console.log("$event",$event)
    if ($event.toState=="final"){
      this.currentDomainState="final1";
    }
  }

  handleDomainDone($event){
    console.log("$event",$event)
    if ($event.toState=="final1"){
      this.currentForwardState="final2";
    }
  }

  handleForwardDone($event){}

  registrarSelected1($event){
    console.log($event.value)
    if ($event.value=="godaddy"){
      this.showDefaultRegistrar=true;
    } else {
      this.showDefaultRegistrar=false;
    }
  }

  nicheSelected1($event){
    console.log($event.value)
    if ($event.value=="godaddy"){
      this.showDefaultRegistrar=true;
    } else {
      this.showDefaultRegistrar=false;
    }
  }

  openPage(url){
    window.open(url,"_blank");
    /*let dialogRef = this.dialog.open(DomainsStepperIframeComponent, {
        height: '90vh',
        width: '90%',
        data: {
            url: url
        }
      });
    dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
      }); */
  }

  getData(event){
    console.log(event);
  }

  illDoItMyself(f:NgForm, model: Domains, isValid: boolean){
    
    let dialogRef = this.dialog.open(ConfirmComponent, {
        height: '275px',
        width: '375px',
  
      });
  
      this.confirmService.getText({title:"READY?", body:"OK, We will create the domain on ESE, but you'll need to configure it through godaddy or another registrar. Check out the docs ->", icon:"check_circle", class:"success", link:this.link, linkTxt:this.linkTxt, target:this.target});
  
      dialogRef.afterClosed().subscribe(result => {
        if (this.confirmPressed == true){
            this.save(f, model, isValid, function(message, thisArg){
              console.log("message",message)
            
              thisArg._service.success(message['message'],message['title'],{opacity:1})
              thisArg.completeMessage=message['message'];
              thisArg.currentState='final';
              thisArg.changeState()
            })
        }
      });

  }

  confirm(f:NgForm, model: Domains, isValid: boolean){
    console.log('confirm me');
    let dialogRef = this.dialog.open(ConfirmComponent, {
        height: '275px',
        width: '375px',
  
      });
  
      this.confirmService.getText({title:"READY?", body:"OK, Ready to create domain?", icon:"check_circle", class:"success"});
  
      dialogRef.afterClosed().subscribe(result => {
        if (this.confirmPressed == true){
            this.save(f, model, isValid, function(message, thisArg, returnData){
              thisArg.domainsService.createHomepage(returnData.record)
              .subscribe(
                data => {
                  thisArg.data = data;
                  console.log(message);
                 
                  console.log("my message:",message)
                 
                  thisArg._service.success(message['message'],message['title'],{opacity:1})
                  thisArg.completeMessage=message['message'];
                  thisArg.currentState='final';
                  thisArg.changeState()
                },
                error => {
                  console.log("error:",error);
                 
                  thisArg._servuce.error(error.error['message']+" "+error.error['error'],error.error['title'],{opacity:1})
                  
                }
              );
            })
        }
      });

  }

  save(f:NgForm, model: Domains, isValid: boolean, cb) {
    model.name=model.domain;
    this.showTwelveOnMyOwn=true;
    this.showTwelve=true;
    this.showDomain=true;
    this.showForward=true;
    this.closeTxt="Close";
    model.niche=JSON.stringify(this.selectedNiches);
    this.commService.goDaddyCall(model, this, function(message, thisArg, returnData){
      
      f.reset();
      
     var checkDomainPercent;
     switch (message.checkDomain){
       case 0:
          checkDomainPercent=1;
          break;
       case 1: 
          checkDomainPercent=25;
          break;
       case 2:
        checkDomainPercent=50;
        break;
       case 3:
        checkDomainPercent=100;
        break;
     }

     var checkForwardPercent;
     switch (message.checkForward){
       case 0:
          checkForwardPercent=1;
          break;
       case 1: 
          checkForwardPercent=25;
          break;
       case 2:
        checkForwardPercent=50;
        break;
       case 3:
        checkForwardPercent=100;
        break;
     }
     console.log('message me', message)
     if (message.check12MA>0){
       thisArg.twelveImgCheck="yes"
       thisArg.twelveMessage = "Your domain has been configuered to work with ESE.";
      // thisArg.TwelveMASize=100;
      // thisArg.twelveColor="green"
     } else {
      thisArg.twelveImgCheck="no"
      thisArg.twelveMessage = "Your domain has NOT been configuered to work with ESE. Please contact support";
     
      thisArg._service.error(thisArg.twelveMessage,'ESE Domain Setup Error',{opacity:1})
      
     // thisArg.TwelveMASize=1;
      //thisArg.twelveColor="red";
     }

     if (message.checkDomain<3){
       thisArg.domainImgCheck='no';
        thisArg.domainMessage = "Your domain's DNS has NOT been configuered at GoDaddy."
       
        thisArg._service.error(thisArg.domainMessage,'GoDaddy DNS ERROR',{opacity:1})
        // thisArg.domainSize=checkDomainPercent;
        //thisArg.domainColor="red";
      } else {
        thisArg.domainMessage = "Your domain's DNS has been configuered at GoDaddy."
        thisArg.domainImgCheck='yes';
        //thisArg.domainSize=checkDomainPercent;
        //thisArg.domainColor="green";
      }

      if (message.checkForward<3){
          thisArg.forwardImgCheck="no";
          thisArg.forwardMessage = "Your domain's Forwarding has NOT been configuered at GoDaddy."
          
          // thisArg.forwardSize=checkForwardPercent;
          //thisArg.forwardColor="red";
        } else {
          thisArg.forwardImgCheck="yes";
          thisArg.forwardMessage = "Your domain's Forwarding has been configuered at GoDaddy."
          //thisArg.forwardSize=checkForwardPercent;
          //thisArg.forwardColor="green";
        }
        
        message['message']=thisArg.twelveMessage+" "+thisArg.domainMessage+" "+thisArg.forwardMessage+" If there are any red X's, check your information and retry the process. If you are having issues, please contact support.";
        cb(message,thisArg, returnData)
    }) 
    
  }

  ngOnInit() {
    var userRecord = JSON.parse(localStorage.getItem('userRecord'));
    this.level=userRecord['level'];
    if (this.level.includes('gold')){
      this.niches = [
        {value: 'hb', viewValue: 'Home Business', isChecked:false},
        {value: 'wl', viewValue: 'Weight Loss', isChecked:false},
        {value: 'pd', viewValue: 'Personal Development', isChecked:false}
      ];
    } else if (this.level=="basic|Home Business"){
      this.niches = [
        {value: 'hb', viewValue: 'Home Business', isChecked:false}
      ];
    } else if (this.level=="basic|Weight Loss"){
      this.niches = [
        {value: 'wl', viewValue: 'Weight Loss', isChecked:false}
      ];
    } else if (this.level=="basic|Personal Development"){
      this.niches = [
        {value: 'pd', viewValue: 'Personal Development', isChecked:false}
      ];
    } else if (this.level=="basic-lifetime|Personal Development"){
      this.niches = [
        {value: 'pd', viewValue: 'Personal Development', isChecked:false}
      ];
    } else if (this.level=="basic-lifetime|Home Business"){
      this.niches = [
        {value: 'hb', viewValue: 'Home Business', isChecked:false}
      ];
    } else if (this.level=="basic-lifetime|Weight Loss"){
      this.niches = [
        {value: 'wl', viewValue: 'Weight Loss', isChecked:false}
      ];
    }
  }

  ngAfterViewInit(){
    
  }

}
