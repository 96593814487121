<section>
 <div class="ral-48blue">SEND US YOUR AUTO-RESPONDER INFO</div>
 <p class="normal">
    <strong>STEP 1:</strong> Click the button below (a new tab will open) and get an Aweber auto-responder account <span class="red italics">(if you already have an Aweber account, skip to step 2)</span>.
 </p>
 <p class="normal">
    Be sure to write down the username you create and the password they send you (do NOT change your password once you’ve given it to us)
 </p>
 <p class="normal center">
   <a class="normal bold" href="http://www.aweber.com/devonbrown.htm?id=287869" target="_blank" >CLICK HERE TO GET AN AWEBER ACCOUNT</a>
 </p>
 <p class="normal">
    <strong>STEP 2:</strong> Enter your Aweber login information into the field’s below. Please verify accuracy before submitting.
 </p>
  <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
    <mat-dialog-content>
      <input  type="hidden" name="autoresponderId" #autoresponderId="ngModel" [ngModel]="autoresponder?._id" id="autoresponderId">
      <mat-form-field class="example-full-width">
        <input  matInput name="autousername" #autousername="ngModel" [ngModel]="autoresponder?.autousername" type="text" id="autousername" placeholder="Please Enter your Autoresponder Username" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field><br><br>
      <mat-form-field class="example-full-width">
        <input matInput name="autopassword" #autopassword="ngModel" [ngModel]="autoresponder?.autopassword" type="text" id="autopassword" placeholder="Please Enter Your Autoresponder Password" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <br><br>


    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="userActionButtonGroup">
        <button mat-button  color="primary" type="submit" [disabled]="!f.valid">
          <mat-icon  class="material-icons">done</mat-icon> Submit My Details
        </button>
        <button mat-button color="primary" type="button" (click)="closeDrawer($event,'1')">
          <mat-icon  class="material-icons">cancel</mat-icon> Return
        </button>
      </div>
    </mat-dialog-actions>
  </form>
  <br><br>
  <span style="font-size:10pt;font-weight:bold;">*Note** - Currently this system only integrates with Aweber. We plan on adding other auto-responders soon
    </span>
</section>
