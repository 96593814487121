import { Component, OnInit } from '@angular/core';
import {Zapier} from "./zapier";
import { ToastService } from 'ng-uikit-pro-standard';
import {LeadsService} from "../leads.service";

@Component({
  selector: 'app-zapier-test',
  templateUrl: './zapier-test.component.html',
  styleUrls: ['./zapier-test.component.css']
})
export class ZapierTestComponent implements OnInit {
  zapier: Zapier;
  constructor(private _service: ToastService, private leadsService: LeadsService) { }

  save(model: Zapier, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    this.leadsService.testzapier(model)
      .subscribe(
        data => {
          console.log(data);
          this._service.success(data['message'], data['title'])
        },
        error => this._service.error(error['message'], error['title']),

      )
    console.log(model, isValid);
  }

  ngOnInit() {
  }

}
