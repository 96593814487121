<!-- Material form login -->
<div class="row">
  <div class="col-md-5 mx-auto my-5">

<mdb-card>

  <mdb-card-header class="info-color white-text text-center py-4">
    <h5>
      <strong>Sign in</strong>
    </h5>
  </mdb-card-header>


  <!--Card content-->
  
    <mdb-card-body>
      <!-- Material form register -->
      <form [formGroup]="cardForm" (ngSubmit)="onSubmit()">
        
  
        <!-- Material input text -->
        <div class="md-form">
          <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
          <input type="text" id="username" formControlName="username" class="form-control" mdbInput>
          <label for="username" class="font-weight-light">Username</label>
          <div *ngIf="f.username.touched && f.username.invalid" class="alert alert-danger">
            <div *ngIf="f.username.errors && f.username.errors.required">Username is required.</div>
          </div>
        </div>
  
        <!-- Material input password -->
        <div class="md-form">
          <mdb-icon fas icon="key" class="prefix grey-text"></mdb-icon>
          <input type="password" id="password" formControlName="password" class="form-control" mdbInput>
          <label for="password" class="font-weight-light">Password</label>
          <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
            <div *ngIf="f.password.errors && f.password.errors.required">Password is required.</div>
          </div>
        </div>
  
        <div class="text-center py-4 mt-3">
          <button  class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit"  mdbWavesEffect [disabled]="!cardForm.valid">Sign In</button>
        </div>
      </form>

      
      <!-- Material form register -->
      <div>
       
        <div class="questions">
          <!-- Forgot password -->
          <mdb-icon fas icon="info-circle" class="prefix grey-text"></mdb-icon><a (click)="forgot_password.toggle()">Trouble signing in? Reset your password?</a>
        </div>
        <div class="" mdbCollapse #forgot_password="bs-collapse">
          <form [formGroup]="passForm" (ngSubmit)="changePass()">
            <div class="md-form">
              <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
              <input type="text" id="username" formControlName="username" class="form-control" mdbInput>
              <label for="username" class="font-weight-light">Username</label>
              <div *ngIf="p.username.touched && p.username.invalid" class="alert alert-danger">
                <div *ngIf="p.username.errors && p.username.errors.required">Username is required.</div>
              </div>
            </div>
            <div class="md-form">
              <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
              <input type="email" id="email" formControlName="email" class="form-control" mdbInput>
              <label for="email" class="font-weight-light">Email</label>
              <div *ngIf="p.email.touched && p.email.invalid" class="alert alert-danger">
                <div *ngIf="p.email.errors && p.email.errors.required">Email is required.</div>
              </div>
            </div>
            <button  class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit"  mdbWavesEffect [disabled]="!passForm.valid">Verify</button>
          </form>
        
        </div>
        <div class="questions">
          <!-- reverify-->
          <mdb-icon fas icon="info-circle" class="prefix grey-text"></mdb-icon><a (click)="reverify.toggle()">Need to Reverify? Click here</a>
        </div>
        <div class="" mdbCollapse #reverify="bs-collapse">
          <form [formGroup]="emailForm" (ngSubmit)="verifyEmail()">
            <div class="md-form">
              <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
              <input type="email" id="email" formControlName="email" class="form-control" mdbInput>
              <label for="email" class="font-weight-light">Email</label>
              <div *ngIf="e.email.touched && e.email.invalid" class="alert alert-danger">
                <div *ngIf="e.email.errors && e.email.errors.required">Email is required.</div>
              </div>
            </div>
            <button  class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit"  mdbWavesEffect [disabled]="!emailForm.valid">Verify</button>
          </form>
        </div>
        
        <hr>
                
        <p>By clicking
            <em>Sign in</em> you agree to our
            <a href="/terms" target="_blank">terms of service</a> and
            <a href="/income-disclaimer" target="_blank">income disaclaimer</a>. </p>
        <!-- Social login -->
        <div style="display:none">
      <p>or sign in with:</p>
      <a type="button" mdbBtn floating="true" color="fb" size="sm">
        <mdb-icon fab icon="facebook"></mdb-icon>
      </a>
      <a type="button" mdbBtn floating="true" color="tw" size="sm">
        <mdb-icon fab icon="twitter"></mdb-icon>
      </a>
      <a type="button" mdbBtn floating="true" color="li" size="sm">
        <mdb-icon fab icon="linkedin"></mdb-icon>
      </a>
      <a type="button" mdbBtn floating="true" color="git" size="sm">
        <mdb-icon fab icon="github"></mdb-icon>
      </a>
    </div>
      </div>
    </mdb-card-body>
  </mdb-card>

</div>
</div>
<!-- Material form login -->


