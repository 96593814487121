import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {ImagesService} from "../images.service";
import {Images} from "../images";
import {NgForm} from "@angular/forms";
import {CategoriesService} from "../../categories/categories.service";
import {Categories} from "../../categories/categories";


@Component({
  selector: 'app-images-input',
  templateUrl: './images-input.component.html',
  styleUrls: ['./images-input.component.css']
})
export class ImagesInputComponent implements OnInit {
  images:Images = null;
  categories: Categories = null;
  showMe=0;




  constructor(private categoriesService: CategoriesService, private imagesService:ImagesService, private authService: AuthService, private _service: ToastService) {
    
    
    imagesService.imagesIsEdit.subscribe(
      images => {
        console.log(images);
        try {
          images.body = atob(images.body);
        } catch(e) {
          console.log(e);
        }
        this.images = images;
        console.log(this.images);
      })
  }

  setDoor(val){
    this.showMe=val;
  }

  save(f:NgForm, model: Images, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);

    if (model.imagesId){
      console.log(model);
      this.imagesService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.imagesService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'], {opacity:1});
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
    this.categoriesService.getAllRecords()
      .subscribe(
        data => {
          this.categories = data['obj'];
          console.log(this.categories);
        },
        error => this._service.error(error.error['message'], error['title']),
      );


  }


  onChange($event){}
  onReady($event){}
  onBlur($event){}
  onFocus($event){}

}
