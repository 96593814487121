import {Injectable, EventEmitter} from '@angular/core';
import {Levels} from "./levels";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class LevelsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  levels: Levels[] = [];
  levelsIsEdit = new EventEmitter<Levels>();
  constructor(private http: HttpClient) { }

  editLevels(levels: Levels) {
    console.log(levels)
    this.levelsIsEdit.emit(levels);
  }

  addRecord(levels: Levels) {
    const body = JSON.stringify(levels);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/levels/addrecord' + token, body, {headers: headers})
     
  }

  deleteRecord(levels: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/levels/deleterecord/' + levels._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/levels/getallrecords'+token,  {headers: headers})
     
  }

  updateRecord(levels: Levels){
    const body = JSON.stringify(levels);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/levels/updaterecord/' + levels.levelsId + token, body, {headers: headers})
      

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/levels/getrecordl/'+id+token,  {headers: headers})
     
  }

}
