import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {Systempreference} from "../shared/systempreference/systempreference";
import { MatSidenav } from '@angular/material/sidenav';
import { CommService } from '../../../services/comm.service';
import {Observable} from 'rxjs'
import 'rxjs/add/observable/timer'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/take'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  username: string = null;
  webinarid;
  webinarlink;
  guarantee=false;
  aweber=false;
  coaching=false;
  overview=false;
  offer=false;
  message:string;
  countDown;
  counter = 60;

  
  constructor(private commService: CommService, private systempreferenceService:SystempreferenceService, private _service: ToastService) { 

    //this.countDown = Observable.timer(0,1000)
    //.take(this.counter)
    //.map(() => --this.counter);
    

     
  }

  openDrawer(drawerContent: string){
    this.commService.guarantee.toggle();
    console.log(drawerContent);
    this.commService.changeMessage(drawerContent);
  }

  ngOnInit() {
    this.commService.currentMessage.subscribe(message => message = message)
    this.username = localStorage.getItem('username');
    const systempreference1 = new Systempreference(null, "webinar_link", null, null);
    this.systempreferenceService.getKey(systempreference1)
      .subscribe(
        data => {
          this.webinarlink=data['obj'].value;

          const systempreference = new Systempreference(null, "webinarid", null, null);
          this.systempreferenceService.getKey(systempreference)
            .subscribe(
              data => {
                this.webinarid=data['obj'].value;
                this.webinarlink=this.webinarlink.replace(/\[WEBINARID\]/g, this.webinarid );
                this.webinarlink=this.webinarlink.replace(/\[USERNAME\]/g, this.username );
                console.log(this.webinarlink);
              },
              error => {
                this._service.error(error.error['message'], error['title']);
              }
            );
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );

  }

}
