import { Injectable } from '@angular/core';
import {Contact} from "./contact";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class ContactService {
  contact: Contact[] = [];
  private hostName = 'http://'+ window.location.host;
  constructor(private http: HttpClient) { }

  sendMail(contact: Contact){
    console.log(contact);
    const body = JSON.stringify(contact);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});


    return this.http.post(this.hostName +'/api/contact/', body, {headers: headers})
      
  }

}
