import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { MenusService } from '../../menus/menus.service';

import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { MatMenuItem } from '@angular/material/menu';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-mainmenu1',
  template: `
  
    <span *ngFor="let menuitem of menu">
      <div *ngIf='menuitem.type=="sectionHead"' class="menulabel" style="margin-top:20px;">{{menuitem.value}}</div>
      <button *ngIf='menuitem.type=="link"' mat-button class="btnlink" [routerLink]="[menuitem.link]" routerLinkActive="activated-button" class="btnlink"><mat-icon>{{menuitem.icon}}</mat-icon> {{menuitem.value}}</button>
      <button *ngIf='getMenuLevel(menuitem)' mat-button class="btnlink" [routerLink]="menuitem.link" routerLinkActive="activated-button" class="btnlink"><mat-icon>{{menuitem.icon}}</mat-icon> {{menuitem.value}}</button>
      <button *ngIf='menuitem.type=="userspecificlink"' mat-button class="btnlink" [routerLink]="[menuitem.link]" routerLinkActive="activated-button" class="btnlink"><mat-icon>{{menuitem.icon}}</mat-icon> {{menuitem.value}} | {{username}}</button>
      <button *ngIf='menuitem.type=="newpage"' #newpage mat-button class="btnlink" (click)="openNewWindow(menuitem.link)" [attr.data-link]="menuitem.link"><mat-icon>{{menuitem.icon}}</mat-icon> {{menuitem.value}}</button>
    </span>
  `,
  styleUrls: ['./mainmenu1.component.css']
})
export class Mainmenu1Component implements OnInit {

  menu;
  menu1
  username;
  level;
  constructor(private systempreferenceService: SystempreferenceService, private authService: AuthService, private menusService: MenusService, private _service: ToastService) {

    
  }

  openNewWindow(url){
    
    window.open(url,'_blank')
  }

  getMenuLevel(menuitem){
    if (menuitem.type==this.level){
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
    
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    
    this.authService.getUser()
    .subscribe(
      userRecord => {
        
        this.level=userRecord['obj'].level;
        
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          
          var myJson = JSON.parse(systemPref['obj'].value);
          
          this.menusService.getRecordByName(myJson.menu)
          .subscribe(
            menuData => {
              
              this.menu=JSON.parse(menuData['obj'].menu);
             
            },
            error => this._service.error("error", error['title'])
          );
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );
    })
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

}
