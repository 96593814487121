import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../auth.service";
import { ToastService } from 'ng-uikit-pro-standard';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import {ConfirmService} from "../../shared/confirm/confirm.service";
import {Location} from '@angular/common';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  public hostName = window.location.protocol + '//' + window.location.host;
  confirmPressed = false;
 
  constructor(private _location: Location, private confirmService: ConfirmService,private authService: AuthService, private router: Router, private _service: ToastService, public dialog: MatDialog) 
  { 
    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })

    
      let dialogRef = this.dialog.open(ConfirmComponent, {
        height: '285px',
        width: '475px',
  
      });

      this.confirmService.getText({title:"Log Out?", body:"Are You Sure You Want To log out?", icon:"exit_to_app", class:"success", okButtonTxt:"CONFIRM", cancelButtonTxt:"No"});
     
      dialogRef.afterClosed().subscribe(result => {
        
        if (this.confirmPressed == true){
                console.log('logging out')
                this.authService.logout()
                .subscribe(
                  data => {
                    console.log(data);
                    localStorage.removeItem('token');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('userRecord');
                    localStorage.removeItem('roles');
                    localStorage.removeItem('email');
                    localStorage.removeItem('username');
                    this._service.success(data['title'], data['message']);
                        setTimeout(function(){
                          window.location.href=window.location.protocol + '//' + window.location.host+"/signin";
                        },3000);
                  }
                )
                
            
        } else {
          this._location.back();
        }
      });
  }

  ngOnInit(){

    
    //
  // this.router.navigate([this.hostName+"/logout"]);
    
   
   console.log(this.hostName+"/signin");
    //this._service.success("You are logged out", "Success");
  }

  ngAfterViewInit(){
    
  }
}
