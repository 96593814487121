import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';

import { TemplatesService } from '../templates.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'ng-uikit-pro-standard';
import {NewsService} from "../../shared/news/news.service";
import {Observable} from 'rxjs';
import { async } from 'q';
import { interval } from 'rxjs';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { MenusService } from '../../menus/menus.service';

@Component({
  selector: 'app-templates-inline-editor',
  templateUrl: './templates-inline-editor.component.html',
  styleUrls: ['./templates-inline-editor.component.css']
})
export class TemplatesInlineEditorComponent implements OnInit {
  page;
  myVar;
  sub;
  data;
  frameSrc;
  myquery;
  newsHtml;
  showNews=false;
  index;
  newsArray: any[]=[];
  menu; 
  newsInterval=30000;
  templates;
  preBuilt;
  constructor(private menusService: MenusService, private systempreferenceService: SystempreferenceService,private newsService: NewsService, private route: ActivatedRoute, private _service: ToastService, private templatesService:TemplatesService, private sanitizer: DomSanitizer) { 

    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    /*this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          console.log(systemPref['obj'].value);
          var myJson = JSON.parse(systemPref['obj'].value);
          this.newsInterval=myJson.news_interval;
          this.getNews("firstnews");
          let count = 0;
          setInterval(() => {
            this.getNews("secondnews")
                if(count === this.newsArray.length ){
                  count = 0;
                }
                this.newsHtml =  this.newsArray[count].body;
                count++
            },this.newsInterval)    
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      ); */
      templatesService.templatesIsEdit.subscribe(
        templates => {
          //console.log(templates.subtype.name);
         // templates.subtype = templates.subtype._id;
          try {
            this.preBuilt = atob(templates.body);
          } catch(e) {
            console.log(e);
          }
          this.templates = templates;
          console.log("templates:",this.templates);
          
         // this.templates.subtype = "none";
          
        })
  }

  ngOnInit() {
      this.sub = this.route.params.subscribe(params => {
      this.page = params['page'];
      this.myquery = params['myparams'];
      var formattedParams=this.route.snapshot.queryParams;
       console.log('myparams',formattedParams);
       var queryString1 = "&"+Object.keys(formattedParams).map(key => key + '=' + formattedParams[key]).join('&');
       console.log(queryString1);
      this.templatesService.getRecord(this.templates._id)
      .subscribe(
        data => {
          console.log("data obj=",data['obj']._id);
          var querySep="?";
          const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
          var queryString;
            if (this.myquery){
               queryString=this.myquery.replace("?", "&");this.myquery;
            }else {
               queryString='';
            }
             var templateId = "&templateId="+data['obj']._id;
             var funnelId="&funnelId=";
             var templateType="&templateType="+data['obj'].type;
             var toolBar = "&toolBar=minimal"
            
            this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl('/api/templates/html/'+data['obj']._id+token+templateId+funnelId+templateType+toolBar+queryString+queryString1);
          
           this.myVar = data['obj'].body;
        },
        error => this._service.error(error.error['message'], error['title']),
      );
    }); 
  }


  ngAfterViewInit() { }

}
