import { Renderer2, Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { HellosignService } from './hellosign.service';
import { ToastService } from 'ng-uikit-pro-standard';
declare function showMyAlert(): any
declare function genHellosign(signurl, skipDomainVerification, clientId): any

@Component({
  selector: 'app-hellosign',
  templateUrl: './hellosign.component.html',
  styleUrls: ['./hellosign.component.css']
})
export class HellosignComponent implements OnInit {
  user: any;
  signUrl:string;
  masterUrl:string;
  frameSrc;
  username: string;
  eseid: any;
  showCompleteMessage: Boolean = false;
  anyElement = document.getElementById('mainHellosign');
  @Input() typeOfSign: any;
  @Input() templateId: any;
  @Input() mainFields: any;
  @Input() helloSignOptions: any;
  @Output() onClose = new EventEmitter<String>();
  loading:boolean=false;
 
  constructor(private sanitizer: DomSanitizer,private hellosignService: HellosignService, @Inject(DOCUMENT) private _document, private _service: ToastService) { 
   // this.masterUrl="&ux_version=1&parent_url=http%3A%2F%2Flocalhost:4000%2Fapi%2Fhellosign%2Ftest&skip_domain_verification=1&client_id=e932879e33df07a7c2d64e2c172cc469&user_culture=en_US&js_version=1.3.2"

    this.username = localStorage.getItem('username');

    this.eseid ={'eseid':this.username};
    
    
  }
  closeDrawer(event, closeMe: string){
    event.preventDefault();
    this.onClose.emit(closeMe);
  }

  verifySigned(){
    this.hellosignService.verifySigned(this.eseid)
    .subscribe(
      data => {
       
        if (data['status']=="success"){
          this.showCompleteMessage=true;
        } else {
          this.showCompleteMessage=false;
          this.getSignedRequest();
        }
      }
    )
  }

  getSignedRequest(){
    var month = localStorage.getItem('__ese__cc_exp_month') ? localStorage.getItem('__ese__cc_exp_month') : '';
    var year = localStorage.getItem('__ese__cc_exp_year') ? localStorage.getItem('__ese__cc_exp_year') : '';
    var type = localStorage.getItem('__ese__cc_cc_type') ? localStorage.getItem('__ese__cc_cc_type') : ''
    console.log('type='+type);
    console.log('exp:'+month+'/'+year);
    this.user={
      "cc_last_four":localStorage.getItem('__ese__cc_last_four') ? localStorage.getItem('__ese__cc_last_four') : '',
      "cc_cvc":localStorage.getItem('__ese__cc_cvc') ? localStorage.getItem('__ese__cc_cvc') : '',
      "cc_type":type,
      "ccexp":month+'/'+year,
      "tid":this.templateId,
      "sub":this.helloSignOptions.subject,
      "msg":this.helloSignOptions['message'],
      'customFields':this.mainFields
    }

    console.log('year = ' + localStorage.getItem('__ese__cc_exp_year') ? localStorage.getItem('__ese__cc_exp_year') : '')
    var myString = encodeURI(JSON.stringify(this.user));
    console.log(myString);
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    var url="/api/hellosign/processHellosign"+token+"&data="+myString;
    console.log(url.length);
    this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(url);
   /* this.hellosignService.getSignedRequest(this.user)
        .subscribe(
          data => {
            this.signUrl=data.sigUrl;
            this.masterUrl=data.masterUrl;
            console.log(data);
           // this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.signUrl+this.masterUrl);
           // genHellosign(this.signUrl, data.skipDomainVerification, data.clientId);
            this.loading=false;
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        ) */
  }

  ngOnInit() {
    
   // this.loading=true;
    if (this.typeOfSign !="traffic")
      this.verifySigned();
      else
      this.getSignedRequest();
    } 

}
