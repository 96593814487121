import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl:'terms.component.html',
  styleUrls: ['./public.component.css']
})
export class TermsComponent implements OnInit {
  
  current_year:number = new Date().getFullYear();
  constructor(private renderer2: Renderer2,@Inject(DOCUMENT) private _document) { }

  ngOnInit() {
  
  }

}







