import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckoutService } from '../checkout/checkout.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../shared/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmService } from '../shared/confirm/confirm.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-processcheckout',
  template: `
   
  

    <section class="col-mat-8 col-mat-offset-2">
   
      <div class="table">
      
        <div class="row header blue">
          <div class="cell center">ITEM</div>
          <div class="cell">QUANTITY</div>
          <div class="cell">PRICE</div>
          <div class="cell">TOTAL</div>
        </div>

        <div class="row">
          <div class="cell img" data-title="item">
            <div class="title">{{priceOptions.item}}{{priceOptions.commissions}}</div>
          </div>
          <div class="cell quantity" data-title="quantity">{{priceOptions.quantity}}</div>
          <div class="cell price" data-title="price">{{myPrice}}</div>
          <div class="cell totel" data-title="total">{{myPrice}}</div>
        </div>
      </div>
    <div class="row">
    <form #fg="ngForm" (ngSubmit)="validate(fg.value, fg.valid)">
    <table class="example-full-width" cellspacing="0">
    <tr>
      <td><mat-form-field class="example-full-width" style="width:100%">
        <input matInput placeholder="Coupon Code" type="text" name="couponcode" #couponcode="ngModel" (ngModel)="couponcode" type="text" id="couponcode" required>
      </mat-form-field></td>
        <td style="padding-left:15px"><button type="submit" style="background-color:#eee;" color="primary" mat-button [disabled]="!fg.valid" *ngIf="alreadySubmitted">Validate Code</button></td>
        </tr>
    </table>
    </form>
    
    
    </div>

    <div class="row">
    
          <img src="assets/images/cc.png">
       
      </div>
      <form #f="ngForm" (ngSubmit)="save(f.value, f.valid)">
      <table class="example-full-width" cellspacing="0">
      <tr>
      <td></td>
      </tr>
      <tr>
        <td><mat-form-field class="example-full-width">
          <input matInput tabindex=1 placeholder="Credit Card Number" type="text" name="ccnum" #ccnum="ngModel" (ngModel)="ccnum" type="text" id="ccnum" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input matInput placeholder="CVC" name="cvc" #cvc="ngModel" (ngModel)="cvc"  type="text" id="cvc" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        </tr>
        <tr>
        <td><mat-form-field class="example-full-width">
        <mat-select placeholder="Expiration Month" name="expire_month" #expire_month="ngModel" (ngModel)="expire_month" id="expire_month" required><mat-option value="">Exp. Month</mat-option><mat-option value="01">01 - January</mat-option><mat-option value="02">02 - February</mat-option><mat-option value="03">03 - March</mat-option><mat-option value="04">04 - April</mat-option><mat-option value="05">05 - May</mat-option><mat-option value="06">06 - June</mat-option><mat-option value="07">07 - July</mat-option><mat-option value="08">08 - August</mat-option><mat-option value="09">09 - September</mat-option><mat-option value="10">10 - October</mat-option><mat-option value="11">11 - November</mat-option><mat-option value="12">12 - December</mat-option></mat-select>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
        <mat-select   placeholder="Expiration Year" name="expire_year" #expire_year="ngModel" (ngModel)="expire_year" id="expire_year" required><mat-option value="">Exp. Year</mat-option><mat-option value="2018">2018</mat-option><mat-option value="2019">2019</mat-option><mat-option value="2020">2020</mat-option><mat-option value="2021">2021</mat-option><mat-option value="2022">2022</mat-option><mat-option value="2023">2023</mat-option><mat-option value="2024">2024</mat-option><mat-option value="2025">2025</mat-option><mat-option value="2026">2026</mat-option><mat-option value="2027">2027</mat-option><mat-option value="2028">2028</mat-option><mat-option value="2029">2029</mat-option><mat-option value="2030">2030</mat-option><mat-option value="2031">2031</mat-option><mat-option value="2032">2032</mat-option><mat-option value="2033">2033</mat-option><mat-option value="2034">2034</mat-option><mat-option value="2035">2035</mat-option><mat-option value="2036">2036</mat-option><mat-option value="2037">2037</mat-option><mat-option value="2038">2038</mat-option><mat-option value="2039">2039</mat-option><mat-option value="2040">2040</mat-option></mat-select>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      

      </tr></table>

      
      <div>
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td>
              
            </td>
          </tr>
        </table>
      </div>




      <button type="submit" style="background-color:#eee;" color="primary" mat-button [disabled]="!f.valid">Submit Transaction</button>  <button style="background-color:#eee;" color="primary" mat-button  class="btn btn-primary" type="button" (click)="closeDrawer($event,'3')">
      <mat-icon  class="material-icons">cancel</mat-icon> Return
    </button>
    </form>
  </section>

  <div class="backdrop" *ngIf="showBackdropYay">
    <div class="center">
      <h1>{{this.priceOptions.msgTitle}}</h1><h2>{{this.priceOptions.msgBody}}</h2>
    </div>
  </div>

  <div class="backdrop" *ngIf="showBackdropNay">
    <div class="center">
      <h1>There was and Error in Processing Your Transaction</h1><h2>Please Contact Support by chooseing contact us in the menu and open a ticket.</h2>
    </div>
  </div>
  <app-loader [loading]="loading"></app-loader>
  
  <div class="backdrop" *ngIf="showBackDropHellosign">
    <app-hellosign [mainFields]="mainFields" [typeOfSign]="typeOfSign" [templateId]="templateId" [helloSignOptions]="helloSignOptions" *ngIf="showHellosign" (onClose)="closeDrawer1($event,'3')"></app-hellosign>
  </div>
  `,
  styles: [`
  body {
    font-family: "Helvetica Neue", Helvetica, Arial;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    background: #2b2b2b;
  }
  @media screen and (max-width: 580px) {
    body {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .backdrop {width:100%;position:absolute;left:0px;top:0px;z-index:999;height:100vh;background-color:#fff}

  .center {text-align:center}
  .price {
      font-size:12pt;
      font-weight:bold;
  }

  .img {text-align:center}
  
  .wrapper {
    margin: 0 auto;
    padding: 40px;
    max-width: 800px;
  }
  
  .table {
    margin: 0 0 40px 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: table;
  }
  @media screen and (max-width: 580px) {
    .table {
      display: block;
    }
  }
  
  .row {
    display: table-row;
    background: #ffffff;
    
  }
  .row:nth-of-type(odd) {
    background: #ffffff;
    
  }
  .row.header {
    font-weight: 900;
    color: #ffffff;
    background: #ea6153;
  }
  .row.green {
    background: #27ae60;
  }
  .row.blue {
    background: #2980b9;
  }
  @media screen and (max-width: 580px) {
    .row {
      padding: 14px 0 7px;
      display: block;
    }
    .row.header {
      padding: 0;
      height: 6px;
    }
    .row.header .cell {
      display: none;
    }
    .row .cell {
      margin-bottom: 10px;
    }
    .row .cell:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #969696;
      display: block;
    }
  }
  
  .cell {
    padding: 6px 12px;
    display: table-cell;
    border:solid 1px #000000;
    vertical-align: top;
  }

  ['title'] {width:100%;font-weight:bold;text-align:center;padding-bottom:10px;}
  @media screen and (max-width: 580px) {
    .cell {
      padding: 2px 16px;
      display: block;
    }
  }
  
  `]
})
export class ProcesscheckoutComponent implements OnInit {
  frameSrc:any;
  confirmPressed = false;
  showBackdropYay=false;
  showBackdropNay=false;
  showHellosign=false;
  showBackDropHellosign=false;
  msg:string;
  loading:boolean=false;
  @Input() priceOptions: any;
  typeOfSign:string = "traffic";
  templateId;
  @Input() mainFields: any;
  helloSignOptions:any = [];
  @Output() onClose = new EventEmitter<String>();
  discount;
  myPrice;
  alreadySubmitted:boolean=true;
  constructor(public authService:AuthService,public dialog: MatDialog, private confirmService: ConfirmService,private checkoutService:CheckoutService, private sanitizer: DomSanitizer, private _service: ToastService) { 
    
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    var url="/api/checkout/oneononecoaching"+token;
    this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    
    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })



      
    }

    closeDrawer(event, closeMe: string){
      event.preventDefault();
      this.onClose.emit(closeMe);
    }

    closeDrawer1(event, closeMe: string){
      this.onClose.emit(closeMe);
    }
  
  validate(model: any, isValid: boolean) {
    console.log(model);
    this.checkoutService.validateCoupon(model)
          .subscribe(
            data => {
              console.log(data);
              var couponCodeData = JSON.parse(data['body'].value);
              this.discount=couponCodeData.discount;
              console.log(this.discount);
              this.myPrice=this.myPrice-(this.myPrice*this.discount/100);
              this.myPrice=this.myPrice.toFixed(2);
              this._service.success(data['title'], data['message']);
              this.alreadySubmitted=false;
            },
            error => this._service.error(error['title'], error['message']),

          )
  }

  save(model: any, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
   
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '50%',
      width: '30%',

    });

    this.confirmService.getText({title:"Confirmation", body:"Do you confirm your purchase?", icon:"info", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        console.log(this.cc_brand_id(model.ccnum))
        localStorage.setItem('__ese__cc_cc_type',this.cc_brand_id(model.ccnum));
        localStorage.setItem('__ese__cc_cvc',model.cvc);
        localStorage.setItem('__ese__cc_exp_month',model.expire_month);
        localStorage.setItem('__ese__cc_exp_year',model.expire_year);
        localStorage.setItem('__ese__cc_last_four',model.ccnum.substr(model.ccnum.length-4));
        this.loading=true;
        this.mainFields.price=this.myPrice;
        this.mainFields.traffic=this.priceOptions.visitors;
        this.mainFields.package=this.priceOptions.package;
        model.price=this.myPrice;
        model.product=this.priceOptions.product;
        model.type=this.priceOptions.type;
        model.gateway=this.priceOptions.gateway;
        model.invoice=this.priceOptions.invoice;
        model.owner=this.priceOptions.owner;
        model.quantity=this.priceOptions.quantity;
        model.commissions=this.priceOptions.commissions;
        model.item=this.priceOptions.item;
         console.log(model);
        this.checkoutService.processTransaction(model)
          .subscribe(
            data => {
              var responseCode = JSON.parse(data['body']);
              console.log(JSON.stringify(data));
             
              this.loading=false;
              
              if (data['status']=="failed"){
                this._service.error(data['title'],responseCode.chargeResult['message'])
              } else {
             
              if (responseCode.data.result_code==1){
                this._service.success(data['message'], data['title'])
                if (this.priceOptions.backdrop=="hellosign"){
                  this.showBackDropHellosign=true;
                  this.showHellosign=true;
                } else {
                  this.showBackdropYay=true;
                }
                
              } else {
                this.showBackdropNay=true;
               
              }
            }
              //this.router.navigate(['/congratulations', {login: 'Success'}]);
            },
            error => this._service.error(error['message'], error['title']),

          )
          
        }
    });

  }

  ngOnInit() {
    console.log(this.priceOptions);
    this.myPrice=this.priceOptions.price;
    this.templateId=this.priceOptions.templateId;
    this.helloSignOptions={
      'subject':'ESEv2: Traffic Form',
      'message':'Please Sign the ESE Traffics Form'
    }
  }

  cc_brand_id(cur_val) {
    var sel_brand;
    
    // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
    // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also
    
    //JCB
    var jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
    // American Express
    var amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
    // Diners Club
    var diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
    // Visa
    var visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    var mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
    var maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    //Discover
    var discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    ////6011, 622126-622925, 644-649, 65
    
    
    // get rid of anything but numbers
    cur_val = cur_val.replace(/\D/g, '');
    
    // checks per each, as their could be multiple hits
    //fix: ordering matter in detection, otherwise can give false results in rare cases
    if (cur_val.match(jcb_regex)) {
        sel_brand = "jcb";
    } else if (cur_val.match(amex_regex)) {
        sel_brand = "amex";
    } else if (cur_val.match(diners_regex)) {
        sel_brand = "diners_club";
    } else if (cur_val.match(visa_regex)) {
        sel_brand = "visa";
    } else if (cur_val.match(mastercard_regex)) {
        sel_brand = "mastercard";
    } else if (cur_val.match(discover_regex)) {
        sel_brand = "discover";
    } else if (cur_val.match(maestro_regex)) {
        if (cur_val[0] == '5') { //started 5 must be mastercard
        sel_brand = "mastercard";
        } else {
        sel_brand = "maestro"; //maestro is all 60-69 which is not something else, thats why this condition in the end
        }
    } else {
        sel_brand = "unknown";
    }
    
    return sel_brand;
  }

}
