import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.css']
})
export class HomepageComponent implements OnInit {

  constructor(private route: ActivatedRoute, private renderer2: Renderer2,@Inject(DOCUMENT) private _document) { }

  
  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  ngOnInit() {
    const clickbank = this.renderer2.createElement('script');
    clickbank.type = 'text/javascript';
    clickbank.src = 'https://cbtb.clickbank.net/?vendor=easiest123&position=header';
    clickbank.id='clickbank-snippet'
    this.renderer2.appendChild(this._document.head, clickbank);
    this.route.queryParams
      .subscribe(params => {
        console.log(params.hop);
        localStorage.setItem(this.b64EncodeUnicode('__esev3_sponsor_id'),this.b64EncodeUnicode(params.hop));
        localStorage.setItem(this.b64EncodeUnicode('__esev3_sponsor_aff_network'),this.b64EncodeUnicode('clickbank'));
      });
    
  }

}
