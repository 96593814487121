
  <section class="col-mat-8 col-mat-offset-2">
      <div class="ral-48blue">Change Your Password</div>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <mat-form-field class="example-full-width">
          <input matInput name="username" #name="ngModel" [ngModel]="user?.username" type="text" id="username" placeholder="username" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput name="password" #name="ngModel" [ngModel]="user?.password"  type="password" id="password" placeholder="Enter new password" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field>
      <br><br>
      <button type="submit" class="btn btn-primary" [disabled]="!f.valid">Update Password</button>&nbsp;&nbsp;<button class="btn btn-primary" (click)="cancel()">cancel</button>
    </form>
  </section>

