

<div>
  <button class="launch" (click)="iBold()"><b>B</b></button>
  <button class="launch" (click)="iItalics()"><i>I</i></button>
  <button class="launch" (click)="save()">S</button>
  <select (change)="selectFont($event)" class="launch">
    <option *ngFor="let font of fonts" value="{{font}}">{{font}}</option>
  </select>
</div>
<div #editor class="editor" contentEditable>
  <h1>A WYSIWYG Editor.</h1>
  <p>Try making some changes here. Add your own text.</p>
</div>


<div #dave1 contentEditable>
    <h1>A WYSIWYG Editor1.</h1>
    <p>Try making some changes here. Add your own text.</p>
  </div>