import {Injectable, EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import 'rxjs/Rx';
import { User } from "./user";

@Injectable()
export class AuthService {
  private loggedIn = false;
  private roles = '';
  private hostName = window.location.protocol + '//' + window.location.host;
  private domainName = window.location.hostname;
  user: User[] = [];
  userIsEdit = new EventEmitter<User>();

  constructor(private http:HttpClient) {

    this.loggedIn = !!localStorage.getItem('token');

  }

  editUser(user: any) {
    this.userIsEdit.emit(user);
  }

  getRecord(user){
   
    const body = {'user_id':user}
    
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post(this.hostName +'/api2/users/getrecord/' , body, {headers: headers})
   
  }

  verifyemail(email){
    const body = {'email':email}
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.hostName +'/api/users/verifyemail', body, {headers: headers})
  }

  sendToGetresponse(){
   
    const jsonBody='{"name":"Dvaid Sloan",' +
      '"email":"me@davidjsloan.com",' +
      '"dayOfCycle":"10",' +
      '"campaign": {' +
      '"campaignId": "nJxp0"},' +
      '"customFieldValues": [' +
      '{' +
        '"customFieldId": "298768603",' +
        '"value": [' +
        '"white"' +
      ']' +
    '}' +
  '],' +
      '"ipAddress": "99.135.2.1"}';
   
    //const body = JSON.stringify(ad);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',

    });
    return this.http.post(this.hostName +'/api/user/sendToGetresponse/' , jsonBody, {headers: headers})
     

  }

  getUserByDomain(user_id) {
     var myUser={'domain':this.domainName,'user_id':user_id};
     
    const body = JSON.stringify(myUser);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
     const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
     return this.http.post(this.hostName +'/api/users/getuserbydomain' + token, body, {headers: headers})
      
   }

  signup(user: User) {
    const body = JSON.stringify(user);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.hostName +'/api/users/', body, {headers: headers})
     
  }

  createuser(user: any) {
    const body = JSON.stringify(user);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.hostName +'/api/users/createuser', body, {headers: headers})
    
  }

  addtransaction(data:any){
    const body = JSON.stringify(data);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.hostName +'/api/users/addTransactionRecord', body, {headers: headers})
  }

  signin(user: User) {
    const body = JSON.stringify(user);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.post(this.hostName +'/api/users/signin', body, {headers: headers})
    
  }

  changePassword(user: any){
    const body = JSON.stringify(user);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';

    return this.http.post(this.hostName +'/api/users/update-password' + token, body, {headers: headers})
     
  }

  requestPassword(user: any){
    const body = JSON.stringify(user);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});


    return this.http.post(this.hostName +'/api/users/request-password', body, {headers: headers})
     
  }

  getUser() {
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/users/' + token)
     
  }

  getAllUsers() {
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/users/getallusers' + token)
      
  }

  updateUser(user: User) {
    const body = JSON.stringify(user);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/users' + token, body, {headers: headers})
    
  }

  updateOtherUser(user: User) {
   
    const body = JSON.stringify(user);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/users/' + user.userId+token, body, {headers: headers})
  
  }

  deleteUser(user: any){
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/users/' + user._id + token, {headers: headers})
     
  }

  getUserById(user){
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/users/getuserbyid/' + user + token, {headers: headers})
   
  }

  logout() {
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/users/logout' + token, {headers: headers})
    
    
  }

  isLoggedIn() {
    this.loggedIn = !!localStorage.getItem('token');
    return this.loggedIn;
  }

  isAdmin() {
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/users/verifyadmin' + token)
    
  }

  hasRole(role){
    const body = role;
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/users/hasrole' + token, body, {headers: headers})
   
  }

  has1Role(role){
    const body = role;
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/users/hasrole' + token, body, {headers: headers})
     
  }

  verifyUsername(username){
    const body = username;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/users/verifyusername' + token, body, {headers: headers})
   
  }




  isFreeMember() {
    this.roles = localStorage.getItem('roles');
    if (this.roles=="free_member") {
      return true;
    } else {
      return false;
    }
  }

}
