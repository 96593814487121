import {Injectable, EventEmitter} from '@angular/core';
import {Subtypes} from "./subtypes";

import { Observable } from "rxjs";
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class SubtypesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  subtypes: Subtypes[] = [];
  subtypesIsEdit = new EventEmitter<Subtypes>();
  constructor(private http: HttpClient) { }

  editSubtypes(subtypes: Subtypes) {
    
    this.subtypesIsEdit.emit(subtypes);
  }

  addRecord(subtypes: Subtypes) {
    const body = JSON.stringify(subtypes);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/subtypes/addrecord' + token, body, {headers: headers})
     
  }

  getAllRecordsByRole(role: any) {
    const body = JSON.stringify(role);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/subtypes/getallrecordsbyrole' + token, body, {headers: headers})
    
  }

  getRecordBySubtype(subtype: any) {
    const body = JSON.stringify(subtype);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/subtypes/getrecordbysubtype' + token, body, {headers: headers})
    
  }

  deleteRecord(subtypes: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/subtypes/deleterecord/' + subtypes._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/subtypes/getallrecords'+token,  {headers: headers})
    
  }

  updateRecord(subtypes: Subtypes){
    const body = JSON.stringify(subtypes);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/subtypes/updaterecord/' + subtypes.subtypesId + token, body, {headers: headers})
    

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/subtypes/getrecordl/'+id+token,  {headers: headers})
     
  }

}
