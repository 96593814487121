import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Systempreference } from "./systempreference";
import {SystempreferenceService} from "./systempreference.service";
import {GridOptions} from "ag-grid-community";
import {SystempreferenceInputComponent} from "./systempreference-input/systempreference-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../confirm/confirm.component";
import {ConfirmService} from "../confirm/confirm.service";


@Component({
  selector: 'app-systempreference',
  templateUrl: './systempreference.component.html',
  styleUrls: ['./systempreference.component.css']
})
export class SystempreferenceComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  public gridOptions: GridOptions;
  constructor(private confirmService: ConfirmService, private systempreferenceService:SystempreferenceService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "ID", field: "_id", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Key", field: "key",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "value", field: "value",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "encrypted", field: "encrypted",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Created At", field: "created_at",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Updated At", field: "updated_at", sort:'desc',editable: true, sortable: true, filter:true, resizable:true},
      {
        headerName: "Actions", width: 100, template: `<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
             <br>
             <button md-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.systempreferenceService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(SystempreferenceInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.systempreferenceService.editNews(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.systempreferenceService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.systempreferenceService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(SystempreferenceInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  ngOnInit() {
  }

}
