<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="categoriesId" #categoriesId="ngModel" [ngModel]="categories?._id" id="categoriesId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="categories?.name" type="text" id="name" placeholder="Enter the Name of Category" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="category" #category="ngModel" [ngModel]="categories?.category" type="text" id="category" placeholder="Enter the Category" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="identifier" #identifier="ngModel" [ngModel]="categories?.identifier" type="text" id="identifier" placeholder="Enter the Identifier" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="role" #role="ngModel" [ngModel]="categories?.role" type="text" id="role" placeholder="Enter the Role that is alowed to view this category.">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput name="img" #name="ngModel" [ngModel]="categories?.img" type="text" id="img" placeholder="Enter the URL of an image to use a an icon">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <textarea matInput name="data" #data="ngModel" [ngModel]="categories?.data" type="text" id="data" placeholder="Enter Some Data in JSON format"></textarea>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
