import { Component, OnInit } from '@angular/core';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {Systempreference} from "../shared/systempreference/systempreference";
@Component({
  selector: 'app-affiliatehome1',
  template: `
  <div class="center top-spacer">
  <span class="t3">USE THIS LINK IF YOU WANT OUR PHONE SALES TEAM TO CLOSE SALES FOR YOU!</span><br>
  <span class="t2 bold">YOUR PHONE SALES AFFILIATE LINK IS:</span>
  <br/>
  <a class="t3 bold" href="{{webinarlink}}" target="_blank">{{webinarlink}}</a>
  <p class="bold"></p>
<br/><br/>
<section style="margin:auto;width:90%">
    <p style="font-size:18pt;color:#c03;font-weight:bold">
    PROMOTIONAL TOOL #1 (EMAIL)
    </p>
    <br>
    <p class="body-txt">
      You can use the following emails to send to your list of subscribers who are interested in making money online.
    </p>
</section>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>mail</mat-icon> Email 1
      </ng-template>
      <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
      SUBJECT: Free 'Daily Commission' Blueprint<br>
      SUBJECT: The EXACT Blueprint For Daily Commissions<br>
      <br>
      <br>
      Wouldn't it be cool to see commission notifications in your email inbox every day?<br>
      <br>
      Well, one marketer has figured out how to do it.<br>
      <br>
      He literally gets both PAYPAL & BANK ACCOUNT deposits on a daily basis from sales he's made online <br>
      <br>
      While he's sleeping, or out at the movies...<br>
      <br>
      It doesn't matter, the commissions come in on pretty much a daily basis <br>
      <br>
      Want to know how it's done?<br>
      <br>
      GREAT!<br>
      <br>
      He's made a really quick demo video to show you the EXACT BLUEPRINT he uses<br>
      <br>
      You can check out the Daily Commission Blueprint Demo Video Here<br>
          ==> {{webinarlink}} <br>


      
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>mail</mat-icon> Email 2
      </ng-template>
      <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
      SUBJECT: BOOM! That just happened ...<br>
      SUBJECT: Awww yeaaahhh! IT HAPPENED<br>
      <br>
      <br>
      What just happened?<br>
      <br>
      I'll tell you...<br>
      <br>
      A top marketer posted a demo video revealing the EXACT BLUEPRINT he uses to pocket daily commissions online. <br>
      <br>
      You can check it out for yourself here<br>
      ==> {{webinarlink}}<br>
      <br>
      But here's the best part...<br>
      <br>
      If you want, he'll even set his ENTIRE blueprint of FOR YOU.<br>
      <br>
      That way, you can start getting results even if you're a COMPLETE NEWBIE and you've never made a dime.<br>
      <br>
      Access the demo here <br>
==> {{webinarlink}} <br>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>mail</mat-icon> Email 3
      </ng-template>
      <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
      SUBJECT: The Story of The BANKRUPT Marketer...<br>
      SUBJECT: Can you relate to this story?<br>
      <br>
      <br>
      Once upon a time, there was an online marketer.<br>
      <br>
      Well, I guess if we're being honest, he wasn't really a marketer *yet*<br>
      <br>
      He was more of an opportunity seeker.<br>
      <br>
      He'd jump around form opportunity to opportunity trying to profit in an online home business...<br>
      <br>
      But no matter what he tried, he kept on failing.<br>
      <br>
      He failed so much that he ended up filing for bankruptcy.<br>
      <br>
      But he didn't give up.<br>
      <br>
      He kept on trying and trying.<br>
      <br>
      3 years (and $38,000) later, he discovered a blueprint for making daily commissions online.<br>
      <br>
      Now, every morning he wakes up deposits directly into his PayPal. <br>
      <br>
      Some days the deposits are small (around $100)...<br>
      <br>
      Other days, the deposits are HUGE (over $6,000)...<br>
      <br>
      But regardless of the amount, he's woken up every day of the past few years with SOMTHING getting put into his PayPal account (or his bank account).<br>
      <br>
      I'm sure you're wondering how he did it.<br>
      <br>
      Well, I'll let him tell you himself.<br>
      <br>
      He recorded a short video detailing the EXACT blueprint he used to make it happen. <br>
      <br>
      And he's willing to share this blueprint video with you for a whopping $0.<br>
      <br>
      Ready to know how he did it?<br>
      <br>
      You can CLICK HERE to find out :-)<br>
          ==> {{webinarlink}} <br>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>mail</mat-icon> Email 4
      </ng-template>
      <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
      SUBJECT: Did you hear about the "Daily Commission Blueprint"?<br>
      SUBJECT: Everyone’s talking about this...<br>
      <br>
      <br>
      Have you heard about the Daily Commission Blueprint yet?<br>
      <br>
      It's this super simple system that was invented by a frustrated marketer so that he could generate online sales on auto-pilot.<br>
      <br>
      It took him about $3 years and $38,000 to crack the code for making daily online commissions.<br>
      <br>
      But don’t worry...<br>
      <br>
      You can literally get his entire system set up for yourself in minutes.<br>
      <br>
      And it case you're wondering <br>
       - No tech skills required<br>
       - No phone sales <br>
       - No e-commerce<br>
       - No blogging or SEO <br>
       - No experience necessary <br>
      <br>
      His system was SPECIFICALLY created for newbies<br>
      <br>
      You can get the full details here <br>
         
==> {{webinarlink}}<br> 
      </div>
    </mat-tab>
    
            
                  

  </mat-tab-group>
</div>

<br><br>
<section style="margin:auto;width:90%">
  <p style="font-size:18pt;color:#c03;font-weight:bold">
  PROMOTIONAL TOOL #2 (BANNER ADS)
  </p>
  <br>
  <p class="body-txt">
  Use the following pre-made banners in as many places as you can on the Internet. Simply copy and paste the banners/banner code you'd like to use form the choices below. You can advertise with banner ads on many different websites a Including: <a href="http://TheBannerExchange.net" target="_blank">http://TheBannerExchange.net</a>, <a href="http://AdBrite.com" target="_blank">http://AdBrite.com</a>, <a href="http://ExchangeAd.com" target="_blank">http://ExchangeAd.com</a>, <a href="http:///TheMediaCrew.com" target="_blank">http:///TheMediaCrew.com</a>, <a href="http://www.Facebook.com/ads" target="_blank">http://www.Facebook.com/ads</a>, and <a href="http://www.AdReady.com" target="_blank">http://www.AdReady.com</a>.
  <br><br>
  And don't forget to place these banners all over <b><u>YOUR</u></b> website or blog!
  <br><br>


  </p>
  <br><br>
  <br>

  <div style="text-align:center">
    <span class="medium">468x60 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-468x60.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-468x60.jpg" border="0"></a>
    </textarea><br><br><br>

    <span class="medium">120x600 Banner</span><br><br>
    <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-120x600.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-120x600.jpg" border="0"></a>
    </textarea><br><br><br>

    <span class="medium">160x600 Banner</span><br><br>
    <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-160x600.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-160x600.jpg" border="0"></a>
    </textarea><br><br><br>

    <span class="medium">200x200 Banner</span><br><br>
    <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-200x200.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-200x200.jpg" border="0"></a>
    </textarea><br><br><br>

    <span class="medium">728x90 Banner</span><br><br>
    <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-728x90.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-728x90jpg" border="0"></a>
    </textarea><br><br><br>

    <span class="medium">300x250 Banner</span><br><br>
    <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-300x250.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-300x250.jpg" border="0"></a>
    </textarea><br><br><br>

    <span class="medium">250x250 Banner</span><br><br>
    <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-250x250.jpg" /></a><br><br>
    "Left Click" to Select Code Then "Right Click" To Copy<br>
    <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
    <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-250x250.jpg" border="0"></a>
    </textarea><br><br><br>
    
    
    
  </div>
</section>
  `,
  styleUrls: ['./affiliatehome1.component.css']
})
export class Affiliatehome3Component implements OnInit {

  username;
  webinarid;
  webinarlink;
  mpd_link;
  constructor(private systempreferenceService:SystempreferenceService, private _service: ToastService) { 
    const systempreference1 = new Systempreference(null, "webinar_link", null, null);
      this.systempreferenceService.getKey(systempreference1)
        .subscribe(
          data => {
            this.webinarlink=data['obj'].value;
            const systempreference = new Systempreference(null, "webinarid", null, null);
              this.systempreferenceService.getKey(systempreference)
                .subscribe(
                  webinarID => {
                    console.log(webinarID['obj'].value);
                    this.webinarid=webinarID['obj'].value;
                    const systempreference2 = new Systempreference(null, "mpd_link", null, null);
                    this.systempreferenceService.getKey(systempreference2)
                      .subscribe(
                        mpdLink => {
                          console.log(mpdLink['obj'].value);
                          this.mpd_link=mpdLink['obj'].value;
                          this.webinarlink=this.webinarlink.replace(/\[WEBINARID\]/g, this.webinarid );
                          this.webinarlink=this.webinarlink.replace(/\[USERNAME\]/g, this.username );
                          this.webinarlink=this.webinarlink.replace(/\[MPD_LINK\]/g, this.mpd_link );
                          this.webinarlink=this.webinarlink.replace(/\[PRODUCT\]/g, '240' );
                        },
                        error => {
                          this._service.error(error.error['message'], error['title']);
                        }
                      );
                  },
                  error => {
                    this._service.error(error.error['message'], error['title']);
                  }
                );
            console.log(this.webinarlink);
          },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
        );
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

}
