import { Component, OnInit, ViewChild } from '@angular/core';
import { AutoresponderService } from '../autoresponder.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-autoresponder-input',
  templateUrl: './autoresponder-input.component.html',
  styleUrls: ['./autoresponder-input.component.css']
})
export class AutoresponderInputComponent implements OnInit {
autoresponder: any = null;
  @ViewChild('f') editFormElement;
  constructor(private autoresponderService: AutoresponderService, private _service: ToastService) { 
    autoresponderService.autoresponderIsEdit.subscribe(
      autoresponder => {
        this.autoresponder = autoresponder;
        console.log("my data:",this.autoresponder);
        if (this.autoresponder['mode']=="add"){
          this.editFormElement.reset();
          
        }
      })
  }

  closeModal(){
    console.log("close")
    this.autoresponderService.editAutoresponder({action:"closeModal"})
  }

  save(f:NgForm, model: any, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.autoresponderId){
    //  model.autoresponder="aweber";
      model.eseid=localStorage.getItem('username') ? localStorage.getItem('username') : '';
      this.autoresponderService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this.autoresponderService.editAutoresponder({action:"refresh"})
            this._service.success(data['message'], data['title'],{opacity:1})
            this.autoresponderService.editAutoresponder({action:"closeModal"})
          },
          error => this._service.error(error['message'], error['title'],{opacity:1}),

        )
      console.log(model, isValid);
    } else {
      //model.autoresponder="aweber";
      model.eseid=localStorage.getItem('username') ? localStorage.getItem('username') : '';
      this.autoresponderService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'],{opacity:1});
            f.reset();
            this.autoresponderService.editAutoresponder({action:"refresh"})
          },
          error => this._service.error(error['message'], error['title'],{opacity:1}),

        )
    } 
  }

  ngOnInit() {
  }

}
