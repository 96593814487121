import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class ConfirmService {
  pressedConfirm = new EventEmitter<any>();
  textData = new EventEmitter<any>();
  constructor() { }

  confirmPressed(data) {
    this.pressedConfirm.emit(data);
  }

  getText(data){
    this.textData.emit(data);
  }

}
