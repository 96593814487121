
<br><Br>
    <div style="width:100%;height:40px;">
        <div style="float:left;height:40px;">
          <button mat-button color="primary" mat-dialog-close>Close</button>
        </div>
        <div style="float:right;height:40px;font-size:18pt;font-weight:bold;">
          Commissions
        </div>
      </div>
<div align="center" style="text-align:center;margin:auto;height:90vh;padding-top:10px;" class="embed-responsive">
    <iframe  class="embed-responsive" frameborder="auto" [src]="commUrl()" style=" width:100%;height:100%;"></iframe>
 </div>