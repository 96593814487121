<div id="mainHellosign"></div>
<div *ngIf="showCompleteMessage" align="center" class="ral-48blue">
    Your Document has been signed.<br> <button style="background-color:#eee;" color="primary" mat-button  class="btn btn-primary" type="button" (click)="closeDrawer($event,'3')">
            <mat-icon  class="material-icons">cancel</mat-icon> Return
          </button>
</div>
<div *ngIf="!showCompleteMessage">
    <app-loader [loading]="loading"></app-loader> 
    
    
        <strong>After signing, click "CONTINUE", then "I AGREE", then click the "RETURN" button. <img src="/assets/images/squiggly-green-arrow.png"></strong>
        <button style="background-color:#eee;" color="primary" mat-button  class="btn btn-primary" type="button" (click)="closeDrawer($event,'3')">
                <mat-icon  class="material-icons">cancel</mat-icon> Return
              </button>
        <br>
        <div id="myHellosign" style="width:100%;height:90vh;border:none;display:none;"></div>
        <iframe  [src]="frameSrc" style="width:100%;height:95vh" frameborder="0"></iframe>
        

   
</div>