import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-affiliateonlymenu',
  templateUrl: './affiliateonlymenu.component.html',
  styleUrls: ['./affiliateonlymenu.component.css']
})
export class AffiliateonlymenuComponent implements OnInit {
  username
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

}
