import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../auth.service";
import {User} from "../../user";
import {NgForm} from "@angular/forms";
import { ToastService } from 'ng-uikit-pro-standard';
import { LevelsService } from '../../../levels/levels.service';

@Component({
  selector: 'app-users-input',
  templateUrl: './users-input.component.html',
  styleUrls: ['./users-input.component.css']
})
export class UsersInputComponent implements OnInit {
  data;
  user: User = new User();
  levels;
  constructor(private levelsService: LevelsService, private authService:AuthService, private _service: ToastService) {
    authService.userIsEdit.subscribe(
      data => {
        console.log(data)
        this.authService.getRecord(data).subscribe(
          userData => {
            this.user=userData['obj'];
          }
        )
      })
  }

  onSubmit(f:NgForm, model: User, isValid: boolean) {
   
    console.log(model);
    if (model.userId){
      this.authService.updateOtherUser(model)
        .subscribe(
          data => this._service.success(data['title'], data['message']),
          error => this._service.error('Error', error)
        ),
    error => this._service.error('Error', error);

      console.log(model, isValid);
    } else {

      this.authService.signup(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
    this.levelsService.getAllRecords()
      .subscribe(
        data => {
          this.levels = data['obj'];
          console.log(data['obj']);
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

}
