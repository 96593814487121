
 <div [@visibilityChanged]="visiblityState" style="position:absolute;top:0px;left:0px;padding:20px 20px 20px 20px; width:100%">
 <app-processcheckout  [mainFields]="mainFields" [priceOptions]="trafficOptions"  (onClose)="closeDrawer($event,'3')" ></app-processcheckout>
 <br>
 <button mat-button color="primary" style="background-color:#eee" (click)="cancel()">Cancel Transaction</button>
 </div>
<section *ngIf="showSalesPitch">
    
    <div class="ral-48blue">SPECIAL ONE-TIME TRAFFIC DEAL</div>
    <div style="display:none">
    <p class="normal">
        With Easiest System Ever, building your business and growing your email list is easy. Simply choose how much traffic you want (based on your budget) and we’ll take care of it for you!
    </p>
    <p class="normal">
        As a special THANK YOU for becoming part of the system, if you decide to place an order for more traffic/website visitors to your system within 60 minutes of when you joined. We’ll send those extra visitors to your system for 20% OFF!!
    </p>
    <p class="normal">
        This is a ONE-TIME deal, and it’s only good until the timer hits “0:00”
    </p>
    <br>
    <button mat-button color="primary" class="example-full-width" style="font-size:14pt">
        Yes, I Want To Take Advantage of Discounted Traffic
    </button>
    </div>
    <div class="infobox center">
        <span class="redtitle">
            SPECIAL OFFER EXPIRES IN: <iframe  [src]="frameSrc" frameborder=0 style="position:relative;top:+2px;display:inline-block;width:100px;height:29px;border:none 1px"></iframe>
        </span>
        <p class="normal center largertxt">
            As a special THANK YOU for becoming part of the Easiest System Ever, we’d like to offer you the chance to get extra traffic to your system for 20% off our normal rates. The traffic prices below are discounted by 20%! Simply order before the countdown timer hits zero, and we’ll take care of the rest!
        </p>
    </div>
    <br><br>
    <p class="normal">
    Getting traffic to your Easiest System Ever business is LITERALLY easier than ordering a pizza.
    </p>
    <p class="normal">
        We’ve partnered up with some of the top traffic providers on the entire internet. Our traffic providers ONLY deal with what’s known as TOP-TIER traffic (traffic from English speaking countries, like the USA, Canada, England, & Australia). This traffic is guaranteed to be real! 
    </p>
    <p class="normal">
        One of the biggest mistakes people make is to buy cheap/fake traffic. If you do this, you will get ZERO results. That’s why we offer to take care of getting the traffic for you! 
    </p>
    <p class="normal">
    <strong>Here’s all you have to do:</strong>
    </p>
    <p class="normal">
        <strong>1.)</strong> Choose one of the traffic options below (based on your budget). Remember, the more traffic you get, the more people get on your email list, and the more likely you are to make sales! 
    </p>
    <p class="normal">
        <strong>2.)</strong> After your order is placed, sign the receipt we send you which authorizes your purchase. 
    </p>
    <p class="normal">
        <strong>3.) </strong>Sit back and relax! We’ll take your order to the traffic suppliers and place it FOR YOU, so that you won’t have to worry about anything. 
    </p>
    <br><br>
    
       
  

    
   
    <!--Start payment options------>
    <div style="display:block;margin:auto;width:760px;">
            <div
              class="btn-sec"
              style="position:relative"
            >
              <img
                src="/assets/images/starburst.png"
                style="width:100px;position:absolute;left:-60px;top:-60px;display:block"
              >
          
              <p
                align="center"
                class="ral-24blue"
              >TRAFFIC OPTION 1</p>
          
              <p
                align="center"
                class="ral-20blk"
              >90-100 TOP QUALITY VISITORS</p>
          
              <p
                align="center"
                class="ral-24red"
              >
                Only
          
                <s>$150</s>
                <s>$99</s><br>
                79.20 (USD)
              </p>
          
              <p align="center">
                <a
                  (click)="traffic1()"
                  target="_blank"
                >
                  <img
                    src="/assets/images/cart23.png"
                    alt=""
                    width="290"
                    height="98"
                    border="0"
                  >
                </a>
              </p>
          
              <p
                align="center"
                class="body-txt"
              >
                I understand that there are NO refunds
          
                <br>
                once the traffic has been delivered
              </p>
            </div>
          
            <div
              class="btn-sec"
              style="position:relative"
            >
              <img
                src="/assets/images/starburst.png"
                style="width:100px;position:absolute;left:-60px;top:-60px;display:block"
              >
          
              <p
                align="center"
                class="ral-24blue"
              >TRAFFIC OPTION 2</p>
          
              <p
                align="center"
                class="ral-20blk"
              >175-199 TOP QUALITY VISITORS</p>
          
              <p
                align="center"
                class="ral-24red"
              >
                Only
          
                <s>$299</s>
          
                <s>199.00</s><br>>
                159.20 (USD)
              </p>
          
              <p align="center">
                <a
                (click)="traffic2()"
                  target="_blank"
                >
                  <img
                    src="/assets/images/cart23.png"
                    alt=""
                    width="290"
                    height="98"
                    border="0"
                  >
                </a>
              </p>
          
              <p
                align="center"
                class="body-txt"
              >
                I understand that there are NO refunds
          
                <br>
                once the traffic has been delivered
              </p>
            </div>
          
            <div
              class="btn-sec"
              style="position:relative"
            >
              <img
                src="/assets/images/starburst.png"
                style="width:100px;position:absolute;left:-60px;top:-60px;display:block"
              >
          
              <p
                align="center"
                class="ral-24blue"
              >TRAFFIC OPTION 3</p>
          
              <p
                align="center"
                class="ral-20blk"
              >350-399 TOP QUALITY VISITORS</p>
          
              <p
                align="center"
                class="ral-24red"
              >
                Only
          
                <s>$599</s>
          
                <s>397.00</s> <br>
                317.60 (USD)
              </p>
          
              <p align="center">
                <a
                (click)="traffic3()"
                  target="_blank"
                >
                  <img
                    src="/assets/images/cart23.png"
                    alt=""
                    width="290"
                    height="98"
                    border="0"
                  >
                </a>
              </p>
          
              <p
                align="center"
                class="body-txt"
              >
                I understand that there are NO refunds
          
                <br>
                once the traffic has been delivered
              </p>
            </div>
          
            <div
              class="btn-sec"
              style="position:relative"
            >
              <img
                src="/assets/images/starburst.png"
                style="width:100px;position:absolute;left:-60px;top:-60px;display:block"
              >
          
              <p
                align="center"
                class="ral-24blue"
              >TRAFFIC OPTION 4</p>
          
              <p
                align="center"
                class="ral-20blk"
              >550-599 TOP QUALITY VISITORS</p>
          
              <p
                align="center"
                class="ral-24red"
              >
                Only
          
                <s>$999</s>
          
                <s>597.00</s> <br>
                477.60 (USD)
              </p>
          
              <p align="center">
                <a
                (click)="traffic4()"
                  target="_blank"
                >
                  <img
                    src="/assets/images/cart23.png"
                    alt=""
                    width="290"
                    height="98"
                    border="0"
                  >
                </a>
              </p>
          
              <p
                align="center"
                class="body-txt"
              >
                I understand that there are NO refunds
          
                <br>
                once the traffic has been delivered
              </p>
            </div>
          
            <div style="height:200px;width:100%;float:left;text-align:center;display:none;">
              <div style="margin:0 auto;width:80%">
                <br>
          
                <p
                  class="ral-24blue"
                  style="font-size:20pt;"
                >
                  Looking At Traffic Option 5 or 6?
                </p>
          
                <br>
          
                <p
                  class="ral-24blue"
                  style="font-size:20pt;"
                >
                  You Should Probably Consider Our
          
                  <a href="/traffic/guaranteedoptins">GUARANTEED OPT-INS Program</a>
                </p>
              </div>
            </div>
          
            <br>
            <br>
            <br>
            <br>
          
            <div
              class="btn-sec"
              style="position:relative"
            >
              <img
                src="/assets/images/starburst.png"
                style="width:100px;position:absolute;left:-60px;top:-60px;display:block"
              >
          
              <p
                align="center"
                class="ral-24blue"
              >TRAFFIC OPTION 5</p>
          
              <p
                align="center"
                class="ral-20blk"
              >910-997 TOP QUALITY VISITORS</p>
          
              <p
                align="center"
                class="ral-24red"
              >
                Only
          
                <s>1497</s>
          
                <s>997.00</s><br>
                797.60 (USD)
              </p>
          
              <p align="center">
                <a
                (click)="traffic5()"
                  target="_blank"
                >
                  <img
                    src="/assets/images/cart23.png"
                    alt=""
                    width="290"
                    height="98"
                    border="0"
                  >
                </a>
              </p>
          
              <p
                align="center"
                class="body-txt"
              >
                I understand that there are NO refunds
          
                <br>
                once the traffic has been delivered
              </p>
            </div>
          
            <div
              class="btn-sec"
              style="position:relative"
            >
              <img
                src="/assets/images/starburst.png"
                style="width:100px;position:absolute;left:-60px;top:-60px;display:block"
              >
          
              <p
                align="center"
                class="ral-24blue"
              >TRAFFIC OPTION 6</p>
          
              <p
                align="center"
                class="ral-20blk"
              >
                1800-2000 TOP QUALITY VISITORS
              </p>
          
              <p
                align="center"
                class="ral-24red"
              >
                Only
          
                <s>$2,997</s>
          
                <s>1,997.00</s><br>
                 1597.60 (USD)
              </p>
          
              <p align="center">
                <a
                (click)="traffic6()"
                  target="_blank"
                >
                  <img
                    src="/assets/images/cart23.png"
                    alt=""
                    width="290"
                    height="98"
                    border="0"
                  >
                </a>
              </p>
          
              <p
                align="center"
                class="body-txt"
              >
                I understand that there are NO refunds
          
                <br>
                once the traffic has been delivered
              </p>
            </div>
          </div>
          
          <div class="clx"></div>
    <!--End Payment Options-------->
    <br><br>
    <div class="redinfobox">
            <p class="normal center">
            <strong>IMPORTANT – PLEASE NOTE THE FOLLOWING</strong>
            </p>
            <p class="normal">
            1.) You’re buying CLICKS (a.k.a. visitors) NOT opt-ins (a.k.a. leads). Buying 100 clicks does not mean that 100 people will opt in to your email list. A "fair" opt in rate is above 20%.
            </p>
            <p class="normal">
            2.)  We can NOT guarantee opt-ins or sales. We can only guarantee that the traffic will be REAL. If we could guarantee that a certain number of people would buy (or opt-in), then we'd gladly make that guarantee. But we cannot guarantee what a real person will do
            </p>
            <p class="normal">
            3.) Traffic USUALLY starts to be delivered within 14 days. Sometimes it takes longer because our traffic supplier is backed up. But rest assured that we're going to get you your traffic as quickly as possible. 
            </p>
            <p class="normal">
            4.) Your traffic may start, and then stop, and then start back up again. So if, for example, you order 175 -199 clicks, and you see that the opt-ins have started coming... but then you see that after 15 opt ins the traffic stops; don't freak out. The traffic is often delivered in spurts. 
            </p>
            <p class="normal">
            5.) Traffic is the life-blood of your business. Order <strong>AS MUCH</strong> traffic as you can, <strong>AS OFTEN</strong> as you can.
            </p>
            
    </div>
    <br>
    
</section>
