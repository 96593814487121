import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Products } from "./products";
import {ProductsService} from "./products.service";
import {GridOptions} from "ag-grid-community";
import {ProductsInputComponent} from "./products-input/products-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  theme;
  paginationSize;
  public gridOptions: GridOptions;
  constructor(private confirmService: ConfirmService, private requestService:ProductsService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.theme="ag-theme-dave";
    this.paginationSize="50";
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Image", field: "product_image", cellRenderer: this.renderImage, cellStyle: {"text-align":"center"}, editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "created_at", field: "created_at", cellRenderer: this.renderDate, cellStyle: this.changeRowColor, sort: 'desc', editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Product Name", field: "product_name",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Product Type", field: "product_type",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Product Line", field: "product_line",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Product Vendor", field: "product_vendor",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Product Link", field: "product_link",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "WID", field: "wid",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Display Order", field: "display_order",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Visible", field: "show", cellRenderer: this.changeTruetoYes,editable: true, sortable: true, filter:true, resizable:true},
      {
        headerName: "Actions",  template: `<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
             
             <button md-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.requestService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          console.log(this.data);
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  renderImage(params){
    if (params.value){
      return '<img style="height:100px" src="'+params.value+'">';
    } else {
      return '<img style="height:100px" src="https://esev2.s3.amazonaws.com/images/No_Image_Available.png">'
    }
  }

  changeTruetoYes(params){
    console.log("params = " , params);
    if (params.data.show==false){
      return "No";
    } else {
      return "Yes";
    }
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(ProductsInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.requestService.editProducts(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.requestService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.requestService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          console.log(this.data);
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(ProductsInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }


  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  changeRowColor(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red', 'color':'#ffffff'};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474', 'color':'#ffffff'};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3', 'color':'#000000'};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac', 'color':'#ffffff'};
      }
      case 'Sold': {
        return {'background-color': '#87c947', 'color':'#000000'};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513', 'color':'#ffffff'};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B', 'color':'#ffffff'};
      }
      case 'No Show': {
        return {'background-color': '#F88F09', 'color':'#ffffff'};
      }
    }
  }

  ngOnInit() {
  }

}
