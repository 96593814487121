<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="shortcodesId" #newsId="ngModel" [ngModel]="shortcodes?._id" id="shortcodesId">
    <mat-form-field class="example-full-width">
      <input matInput name="key" #key="ngModel" [ngModel]="shortcodes?.key" type="text" id="key" placeholder="Enter the Key for the Shortcodes" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <textarea matInput name="value" #value="ngModel" [ngModel]="shortcodes?.value" type="text" id="value" placeholder="Enter the Value" required></textarea>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="description" #description="ngModel" [ngModel]="shortcodes?.description" type="text" id="description" placeholder="Enter the description (Not required)">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput name="group" #group="ngModel" [ngModel]="shortcodes?.group" type="text" id="group" placeholder="Enter the group (Not required)">
      </mat-form-field>
      <mat-form-field class="example-full-width">
          <input matInput name="subgroup" #subgroup="ngModel" [ngModel]="shortcodes?.subgroup" type="text" id="subgroup" placeholder="Enter the subgroup (Not required)">
        </mat-form-field>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
