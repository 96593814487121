<link href="https://www.webcopycat.com/css/newmain/style.css" rel="stylesheet" type="text/css" />

<p align="center" class="ral-48blue">Guaranteed Optins</p><br>
<p align="center" class="ral-48blue" style="font-size:20pt;">Who Else Wants To Sit Back & Relax While We Grow Your Email List To 3,000… 5,000 … 10,000 Subscribes or More!</p>

<p align="center" class="ral-20red">Plus, We'll Give You Free 1-On-1 Coaching & Over $1,900 In Bonuses</p>


<div align="center">
    <iframe width="100%" height="445" src="https://www.youtube.com/embed/5LvK_8K438I?rel=0&amp;showinfo=0&autoplay=0" frameborder="0" allowfullscreen></iframe>
</div>

<p class="body-txt"><strong>Answers To Frequently Asked Questions:</strong></p>

<p class="body-txt"><strong>Q</strong> Devon. How is this different from when I buy “clicks” or “traffic” or “visitors”</p>
<p class="body-txt"><strong>A.</strong> Normally, when you buy “clicks” or “traffic” you’re paying to get someone to your opt-in page, but there’s no guarantee they’ll opt-in. With this, you’re paying for the Guaranteed Opt In.</p>

<p class="body-txt"><strong>Q</strong> How much is this?</p>
<p class="body-txt"><strong>A.</strong> We’ll talk about that over the phone. It all depends on how big you want your list. But needless to say, for an offer like this, it’s not cheap. So if you only have a few hundred dollars, then your best bet is to buy some of the top quality guaranteed done-for-you clicks/traffic/visitors we offer.</p>

<p class="body-txt"><strong>Q</strong> Why would I do this as opposed to buying traffic?</p>
<p class="body-txt"><strong>A.</strong> Because this method is more cost effective (because we’re dealing in bulk).  Normally, when you drive traffic to your opt-in page, it costs about $1 to get a GOOD visitor to your page, but only about 1 out of every 2 or 3 visitors will opt-in. That means you’re easily paying $2.50 - $3.00 to get one person on your list.  But with this (because we’re dealing in bulk) we can get GURANTEED OPT INS for almost the same price as you’d normally pay for the click/visitor.</p>

<p class="body-txt"><strong>Q</strong> Who is this offer for?</p>
<p class="body-txt"><strong>A.</strong> This offer is for you if you understand that building your email list is the most important part of your success as an internet marketer. This is for you if you understand that the “secret” that allows the “gurus” to make money on demand is that we have big lists. This is for you if want the most cost effective way to build your list. This is for you if you want us to put THOUSANDS of people on your list for you. This is for you if you understand that this offer is not cheap.</p>

<p class="body-txt"><strong>Q</strong> How do I get started?</p>
<p class="body-txt"><strong>A.</strong> Simply fill out an application form below and a member of the WebCopyCat staff will give you a call and walk you through getting started.</p>
<br><br>
<div class="blueborder" id="id_contact_me_message" style="display:none;">
    <p align="center" class="ral-20red" style="font-size:20pt;">CONGRATULATIONS!</p>
    <p align="center" class="ral-20red">A Member Of The WebCopyCat Team Will Be Calling You Soon!</p>
</div>
<div class="blueborder" id="id_contact_me" style="position:relative">

    <div id="custom_message_contact" style="color:#c03;font-weight:bold;font-size:18pt;"></div>
    <div >

        <div class="rows01" >
            <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
                <mat-form-field class="example-full-width">
                  <input matInput #firstname="ngModel" name="firstname" (ngModel)="guaranteedoptin?.firstname" type="text"  id="id_contact_firstname"  placeholder="First Name" required/>
                  <mat-error>This is a required field</mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput #lastname="ngModel" name="lastname" (ngModel)="guaranteedoptin?.lastname" type="text"  id="id_contact_lastname"  placeholder="Last Name" required/>
                    <mat-error>This is a required field</mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput #email="ngModel" name="email" (ngModel)="guaranteedoptin?.email" type="text"  id="id_contact_email"  placeholder="Email" required/>
                    <mat-error>This is a required field</mat-error>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                      <input matInput #phone="ngModel" name="phone" (ngModel)="guaranteedoptin?.phone" type="text"  id="id_contact_phone"  placeholder="Phone" required/>
                      <mat-error>This is a required field</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <input matInput #skype="ngModel" name="skype" (ngModel)="guaranteedoptin?.skype" type="text"  id="id_contact_skype"  placeholder="Skype"/>
                        <mat-error>This is a required field</mat-error>
                      </mat-form-field>

                      <mat-form-field class="example-full-width">
                          <input matInput #city="ngModel" name="city" (ngModel)="guaranteedoptin?.city" type="text"  id="id_contact_city"  placeholder="City" required/>
                          <mat-error>This is a required field</mat-error>
                        </mat-form-field>
          
                        <mat-form-field class="example-full-width">
                            <input matInput #state="ngModel" name="state" (ngModel)="guaranteedoptin?.state" type="text"  id="id_contact_state"  placeholder="State" required/>
                            <mat-error>This is a required field</mat-error>
                          </mat-form-field>

                          <mat-form-field class="example-full-width">
                              <input matInput #country="ngModel" name="country" (ngModel)="guaranteedoptin?.country" type="text"  id="id_contact_country"  placeholder="country" required/>
                              <mat-error>This is a required field</mat-error>
                            </mat-form-field>

           
                            <mat-form-field class="example-full-width">
                                <label style="font-size:12pt;">How Big Are You Looking To Grow Your List?</label>
                                <textarea  name="size" matInput #size (ngModel)="guaranteedoptin?.size"  id="size"  name="size" required></textarea>
                                <mat-error>This is a required field</mat-error>
                              </mat-form-field>

                              <mat-form-field class="example-full-width">
                                  <label style="font-size:12pt;">Do You Understand That Our Guaranteed Opt-ins Program Is Not Cheap?</label>
                                  <textarea  name="cheap" matInput #cheap (ngModel)="guaranteedoptin?.cheap"  id="cheap"  name="cheap" required></textarea>
                                  <mat-error>This is a required field</mat-error>
                                </mat-form-field>
           
           
          
          
                <button matButton type="submit"   id="id_contact_submit" [disabled]="!f.valid" class="btn btn-primary example-full-width">
                  Submit My Information
                  <div class="mdl-spinner mdl-js-spinner is-active" id="id_spinner" style="display:none;position:absolute;right:2px;"></div>
                </button>
            </form>
        </div>
    </div>
</div>