import { Component, OnInit, ViewChild } from '@angular/core';
import {  AuthService } from '../../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';

import {GridOptions} from "ag-grid-community";
import {AutoresponderInputComponent} from "./../autoresponder-input/autoresponder-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import {ConfirmService} from "../../shared/confirm/confirm.service";
import { AutoresponderService } from '../autoresponder.service';
import { ModalDirective } from 'ng-uikit-pro-standard'
import { CommService } from '../../../../services/comm.service';
import { TouchSequence } from 'selenium-webdriver';
import { Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-myautoresponder',
  templateUrl: './myautoresponder.component.html',
  styleUrls: ['./myautoresponder.component.css']
})
export class MyautoresponderComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  data;
  columnDefs;
  confirmPressed = false;
  public gridOptions: GridOptions;
  constructor(private commService: CommService, private confirmService: ConfirmService, private autoresponderService:AutoresponderService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Autoresponder Id", field: "_id",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Name", field: "name",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Group", field: "group",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Type", field: "autoresponder",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Actions", cellRenderer: this.renderActions},
      {headerName: "Created At", cellRenderer: this.renderDate, field: "created_at",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Updated At", cellRenderer: this.renderDate, field: "updated_at",editable: true,sortable: true, filter:true, resizable:true},

    ];
    this.gridOptions = {
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.autoresponderService.getMyRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          //this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title'],{opacity:1}),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })

    this.autoresponderService.autoresponderIsEdit.subscribe(
      data => {
        console.log(data)
        switch (data['action']) {
          case "closeModal":
            this.editModal.hide();
            this.refresh();
            break;
          case "refresh":
            this.refresh();
            break;
        }
      }
    )
  }

  renderActions(params){
    var buttons = `<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
    <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;View/Edit Autoresponder
         </button>
         <br>
         <button md-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
         </button>
       
 `

    return buttons;
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }


  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    console.log(data)
    this.editModal.show();
    data.mode="edit"
    this.autoresponderService.editAutoresponder(data)


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn", okButtonTxt:"CONFIRM", cancelButtonTxt:"No"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.autoresponderService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message'],{opacity:1});

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title'],{opacity:1}),
          );
      }
    });

  }

  refresh(){
    this.autoresponderService.getMyRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
         // this._service.success(data['title'], data['message'],{opacity:1});
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title'],{opacity:1}),
      );
  }

  addRecord(){
    
    this.editModal.show();
    var data = {mode:"edit"}
    this.autoresponderService.editAutoresponder(data)
  }

  ngOnInit() {
  }

}
