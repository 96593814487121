<div class="menulabel">Manage (Super)</div>
                <button Type="button" mat-button class="btnlink" [matMenuTriggerFor]="appMenu">
                        <i class="material-icons icon">settings</i>&nbsp;Super Admin
                </button>
                <mat-menu #crmMenu="matMenu">
                  <button  mat-menu-item [routerLink]="['/manage/crm']" routerLinkActive="activated-button">
                      <mat-icon>dashboard</mat-icon>
                      <span>CRM</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/request']" routerLinkActive="activated-button">
                    <mat-icon>image</mat-icon>
                    <span>Submitted Request</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/myapplications']" routerLinkActive="activated-button">
                    <mat-icon>view_list</mat-icon>
                    <span>Applications</span>
                  </button>
                </mat-menu>
                <mat-menu #appMenu="matMenu" class="matmenu">
                    <button  mat-menu-item [routerLink]="['/manage/adminhelp']" routerLinkActive="activated-button">
                      <mat-icon>info</mat-icon>
                      <span>Help</span>
                    </button>
                    <button  mat-menu-item [matMenuTriggerFor]="crmMenu" >
                      <mat-icon>dashboard</mat-icon>
                      <span>CRM</span>
                    </button>
                    
                   
                  <button  mat-menu-item [routerLink]="['/manage/categories']" routerLinkActive="activated-button">
                    <mat-icon>info</mat-icon>
                    <span>Categories Management</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/links']" routerLinkActive="activated-button">
                    <mat-icon>link</mat-icon>
                    <span>Links Management</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/urlshortener']" routerLinkActive="activated-button">
                    <mat-icon>link</mat-icon>
                    <span>Url Shortener</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/shortcodes']" routerLinkActive="activated-button">
                    <mat-icon>short_text</mat-icon>
                    <span>Shortcodes</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/autoresponders']" routerLinkActive="activated-button">
                    <mat-icon>email</mat-icon>
                    <span>Autoresponder Management</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/webinar']" routerLinkActive="activated-button">
                    <mat-icon>video_label</mat-icon>
                    <span>Webinar Management</span>
                  </button>
                    <button  mat-menu-item [routerLink]="['/manage/types']" routerLinkActive="activated-button">
                      <mat-icon>settings</mat-icon>
                      <span>Template Types</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/subtypes']" routerLinkActive="activated-button">
                      <mat-icon>settings</mat-icon>
                      <span>Template Subtypes</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/templates']" routerLinkActive="activated-button">
                      <mat-icon>pages</mat-icon>
                      <span>Templates</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/funnels']" routerLinkActive="activated-button">
                      <mat-icon>view_column</mat-icon>
                      <span>Funnel Configuration</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/email']" routerLinkActive="activated-button">
                        <mat-icon>email</mat-icon>
                        <span>Email Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/users']" routerLinkActive="activated-button">
                        <mat-icon>supervisor_account</mat-icon>
                        <span>User Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/news']" routerLinkActive="activated-button">
                        <mat-icon>list</mat-icon>
                        <span>News Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/systempreference']" routerLinkActive="activated-button">
                        <mat-icon>settings</mat-icon>
                        <span>System Preferences</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/userpreference']" routerLinkActive="activated-button">
                        <mat-icon>people_outlined</mat-icon>
                        <span>User Preferences</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/products']" routerLinkActive="activated-button">
                        <mat-icon>format_underlined</mat-icon>
                        <span>Products Management</span>
                      </button>
                    <button  mat-menu-item [routerLink]="['/manage/menus']" routerLinkActive="activated-button">
                      <mat-icon>apps</mat-icon>
                      <span>Menu Management</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/menumaker']" routerLinkActive="activated-button">
                      <mat-icon>apps</mat-icon>
                      <span>Menu Maker</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/levels']" routerLinkActive="activated-button">
                      <mat-icon>art_track</mat-icon>
                      <span>Level Management</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/status']" routerLinkActive="activated-button">
                      <mat-icon>settings</mat-icon>
                      <span>Status Management</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/domains']" routerLinkActive="activated-button">
                      <mat-icon>web</mat-icon>
                      <span>Domain Management</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/status']" routerLinkActive="activated-button">
                      <mat-icon>settings</mat-icon>
                      <span>Support Status Management</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/support']" routerLinkActive="activated-button">
                      <mat-icon>contact_support</mat-icon>
                      <span>Support System</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/chatqueue']" routerLinkActive="activated-button">
                      <mat-icon>chat</mat-icon>
                      <span>Chat Queue</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/servers']" routerLinkActive="activated-button">
                      <mat-icon>computer</mat-icon>
                      <span>Servers</span>
                    </button>
                </mat-menu>