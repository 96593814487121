import { Component, Inject, OnInit, Renderer2, } from '@angular/core';
import { FormGroup, NgForm, FormControlName, FormBuilder, Validators } from "@angular/forms";
import {AuthService} from "../auth.service";
import {User} from "../user";
import {Router, ActivatedRoute} from "@angular/router";
import { ToastService } from 'ng-uikit-pro-standard';
import { MatInputModule } from '@angular/material/input';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public user: User;
  source: string;
  private sub: any;
  private nospam: string="true";
  public hostName = window.location.host;
  cardForm: FormGroup;
  validUserName:boolean = false;
  level;
  saleAmount;
  network;
  sponsor;
  cbreceipt;
  returnString

  get f(){
    return this.cardForm.controls;
  }

  constructor(private authService: AuthService,  private _service: ToastService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private renderer2: Renderer2,@Inject(DOCUMENT) private _document) { 
    this.cardForm = fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      address: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  onSubmit() {
    console.log(this.cardForm.value)
    
    console.log(decodeURIComponent(this.network))
    let body=
          {
              username: this.cardForm.value.username,
              password: this.cardForm.value.password,
              firstname: this.cardForm.value.firstname,
              lastname: this.cardForm.value.lastname,
              address: this.cardForm.value.address,
              address2: this.cardForm.value.address2,
              city: this.cardForm.value.city,
              state: this.cardForm.value.state,
              zip: this.cardForm.value.zip,
              phone: this.cardForm.value.phone,
              email: this.cardForm.value.email,
              domain: "www.easiestsystemever.com",
              roles: ['member'],
              level: this.level,
              origin: 'clickbank',
              accountId: "274066",
              listId: "5217569",
              mailchimpId: "5718f88926",
              template_id: "5bf323ad6556860031df2e35",
              to: this.cardForm.value.email,
              from: 'support@easiestsystemever.com',
              subject: 're: easiestsystemever.com - Please Verify Your Email Address',
              network: this.network,
              sponsor: this.sponsor,
              notification_subject: "Notification: New easiestsystemever Member",
              notification_to: "support@easiestsystemever.com",
              notification_from: this.cardForm.value.email,
              notification_template_id: "5c5afbb911b9b90032bae03a"
              
          }

          console.log(body)

          this.authService.createuser(body)
            .subscribe(
              data => {
                console.log(data);
                  this._service.success(data['message'], data['title'])
                  this.router.navigateByUrl('/congratulations');
                  
              },
              error => {
                console.log(error);
                this._service.error(error['title'], error.error['message'])
              },

          ) 
    
  }

  checkUsername(event){
    
    this.authService.verifyUsername({username:this.cardForm.value.username})
    .subscribe(
      data=>{
        if (data['status']=="success"){
          this.validUserName=true;
        } else {
          this.validUserName=false;
        }
      }
    )
  }

  ngOnInit() {
    const clickbank = this.renderer2.createElement('script');
    clickbank.type = 'text/javascript';
    clickbank.src = 'https://cbtb.clickbank.net/?vendor=easiest123&position=header';
    clickbank.id='clickbank-snippet'
    this.renderer2.appendChild(this._document.head, clickbank);
      this.route.queryParams
      .subscribe(params => {
        this.cbreceipt=params.cbreceipt;
        this.returnString=params;
        this.network= this.b64DecodeUnicode(localStorage.getItem(this.b64EncodeUnicode('__esev3_sponsor_aff_network')) ? localStorage.getItem(this.b64EncodeUnicode('__esev3_sponsor_aff_network')) : '');
        this.sponsor= this.b64DecodeUnicode(localStorage.getItem(this.b64EncodeUnicode('__esev3_sponsor_id')) ? localStorage.getItem(this.b64EncodeUnicode('__esev3_sponsor_id')) : '');
        
        switch (params.item){
          case "2":
              this.level="basic";
              this.saleAmount=47;
              break;
          case "15":
              this.level="basic";
              this.saleAmount=47;
              break;
          case "3":
              this.level="gold";
              this.saleAmount=97
              break;
          case "4":
              this.level="basic-lifetime";
              this.saleAmount=397
              break;
          case "18":
              this.level="basic-lifetime";
              this.saleAmount=376
              break;
          case "5":
              this.level="gold-lifetime"
              this.saleAmount=797
              break;
          case "20":
            this.level="basic-lifetime"
            this.saleAmount=997
            break;
          default:
              this.level="basic";
      }
        
        
      }
    );
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }



}
