<div style="width:85%;text-align:left;margin:auto;">
  <mat-dialog-content>
    <div>
      <mat-form-field style="width:50%">
        <mat-select  (selectionChange)="getTemplates($event)" name="category" #category  [(ngModel)]="currentCategory" id="category" placeholder="Choose a Category for a Template"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let category of categories" value="{{category._id}}">{{category.name}}</mat-option>
        </mat-select>
        
      </mat-form-field>
      <button mat-button color="primary" type="button" mat-dialog-close>Cancel</button>
    </div>
  </mat-dialog-content>
</div>
<div class="container">
  <div class="row sm-gutters">
      <div class="col-md-3" *ngFor="let thumb of thumbs">
        <mat-card style="width:100%">
            
            <mat-card-title><div mat-card-avatar class="example-header-image"></div>{{thumb.name}}</mat-card-title>
            <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            <mat-card-content>
              <img mat-card-image src="{{thumb.image}}" >
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="chooseTemplate(thumb._id)" mat-dialog-close>Choose</button>
             
            </mat-card-actions>
        </mat-card>
    </div>
  </div>
</div>
  