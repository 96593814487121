// logged-in.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class CheckRoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private _service: ToastService) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let roles = route.data["roles"] as Array<string>;
   
    return this.authService.hasRole({roles:roles})
      .pipe(map(data => {
        if (data['success']==true)
          return true;
        else 
          this._service.error(data['message'], data['title'],{opacity:1});
          return false;
      }
    ))
    
    
      
  }
}
