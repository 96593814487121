import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {NgForm} from "@angular/forms";
import { ToastService } from 'ng-uikit-pro-standard';
import { AutoresponderService } from '../autoresponder.service';
import { CommService } from '../../../../services/comm.service';

@Component({
  selector: 'app-autoresponder-front',
  templateUrl: './autoresponder-front.component.html',
  styleUrls: ['./autoresponder-front.component.css']
})
export class AutoresponderFrontComponent implements OnInit {
  @Output() onClose = new EventEmitter<String>();
  autoresponder: any = null;
  constructor(private commService: CommService, private autoresponderService: AutoresponderService, private _service: ToastService) { }

  closeDrawer(event, closeMe: string){
    event.preventDefault();
    this.onClose.emit(closeMe);
  }

  save(f:NgForm, model: any, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    model.autoresponder="aweber";
    model.eseid=localStorage.getItem('username') ? localStorage.getItem('username') : '';
    console.log(model);
    

      this.autoresponderService.addFrontRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this.commService.setAutoresponder("1");
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
  
  }

  ngOnInit() {
  }

}
