import {Injectable, EventEmitter} from '@angular/core';
import {Images} from "./images";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ImagesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  images: Images[] = [];
  imagesIsEdit = new EventEmitter<Images>();
  constructor(private http: HttpClient) { }

  editImages(images: Images) {
    console.log(images)
    this.imagesIsEdit.emit(images);
  }

  getSignedRequest(fileName, fileType) {
    const headers = new HttpHeaders({'Content-Type': 'image/jpeg'});
    const token = localStorage.getItem('token') ? '&token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/upload/sign-s3?fileName='+fileName+"&fileType="+fileType+token,  {headers: headers})
      
  }

  getAllObjects() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/s3/getmyobjects'+token,  {headers: headers})
     
  }

  upload (signedRequest, file){
    const headers = new HttpHeaders({'Content-Type': 'image/jpeg'});
    
    return this.http.put(signedRequest, file, {headers:headers})
     
  }

  getS3Credentials() {
    var body='';
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/images/getS3Credentials/' + token, body, {headers: headers})
    
  }

  addRecord(images: any) {
    const body = JSON.stringify(images);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/images/addrecord/' + token, body, {headers: headers})
     
  }

  deleteRecord(images: any){
    const body = JSON.stringify(images);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/s3/deleteImage/' + token, body, {headers: headers})
      
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/images/getallrecords/' + token,  {headers: headers})
    
  }

  getAllMyRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/images/getallmyrecords/' + token,  {headers: headers})
     
  }

  getCategoryRecords(value) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/images/getcategoryrecords/'+value+token,  {headers: headers})
     
  }

  updateRecord(images: Images){
    const body = JSON.stringify(images);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/images/updaterecord/' + images.imagesId + token, body, {headers: headers})
    

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/images/getrecord/'+id+token,  {headers: headers})
      
  }

}
