import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { MenusService } from '../../menus/menus.service';

import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-adminmenu',
  templateUrl: './adminmenu.component.html',
  styleUrls: ['./adminmenu.component.css']
})
export class AdminmenuComponent implements OnInit {
  menu;
  menu1
  username;
  admin_help_page='999';
  params="&action=now";
  constructor(private systempreferenceService: SystempreferenceService, private authService: AuthService, private menusService: MenusService, private _service: ToastService) { 
    this.menu1 = [
      {type:'sectionHead',value:"Welcome",paddingtop:'0px'},
      {type:'link',value:"Getting Started",link:'/members/gettingstarted',icon:'home',page:'home'},
      {type:'link',value:"Step-By-Step",link:'/members/internalpage/5ad94c3cc739a7aa536b647d',icon:'question_answer',page:'home'},
      {type:'link',value:"Account",link:'/members/account',icon:'account_circle',page:'home'},
      {type:'userspecificlink',value:"Logout",link:'/members/logout',icon:'exit_to_app',page:'home'},
      {type:'sectionHead',value:"Make Money",paddingtop:'20px'},
      {type:'link',value:"My Sales Funnels",link:'/members/internalpage/5acfb594e588790d938d1213',icon:'show_chart',page:'home'},
      {type:'link',value:"My Commissions",link:'/members/commissions',icon:'attach_money',page:'home'},
      {type:'link',value:"My Commissions",link:'/members/commissions',icon:'attach_money',page:'home'},
      {type:'sectionHead',value:"Traffic",paddingtop:'20px'},
      {type:'link',value:"My Commissions",link:'/members/commissions',icon:'attach_money',page:'home'},
      {type:'link',value:"My Commissions",link:'/members/commissions',icon:'attach_money',page:'home'},
    ]

    
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
    
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
   
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          
          var myJson = JSON.parse(systemPref['obj'].value);
         
          this.admin_help_page=myJson.admin_help_page
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      ); 
    
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

}
