<mat-toolbar  style="background-color:#fff;position:fixed;z-index:999;width:100%">
   
    <span class="app-toolbar-filler"></span>
  
    <div class="topnav" id="myTopnav">
       
  
        <button mat-button [routerLink]="['/signin']" style="color:#000" ><mat-icon>business_center</mat-icon> Sign In</button>
        
    </div>
  </mat-toolbar>
<div class="container main-container">
  <button mat-button [routerLink]="['/signin']" class="white"><mat-icon>business_center</mat-icon> Signin</button>
  <section class="col-mat-2"><span class="highlight" style="font-weight:bold;font-size:14pt">Attention JV's & Affiliates...</span></section>
  <section class="col-mat-8">

    <h1 class="center  t1" style="font-size:48pt !important;">Hot New Webinar + U.S. Based Phone Team = $150 - $1,000 Commissions In Your 
        Pocket For Each Sale We Make FOR YOU!
        
    </h1>
    <br/>
    <div class="center">
      <span class="red t5 bold" style="font-size:16pt !important;">
          You'll CRUSH IT with this offer if the traffic you can send is looking for the FASTEST & EASIEST way to grow their list and make done-for-you affiliate commissions!
      </span>
    </div>
    <br/><br/>
    
    <div class="center t3">
      <div class="smallvid">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/69bjLfAemnc?rel=0&amp;controls=1&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <br/><br/>
    
   
    <div class="center bold t1">
      How It Works
    </div>
    <br/><br/>
    <div class="t3 center">
      <span class="t2 red bold center">
          STEP 1: Create Your Affiliate Account Below (FREE)
      </span> <br>
      <p style="text-align:left !important">
        Just fill out the form below and we'll INSTANTLY create a free affiliate account for you. You'll be able to start promoting
        the Easiest System Ever within minutes of creating your free affiliate account!
      </p>
      <br><br>
    </div>
    <div class="t3 center">
        <span class="t2 red bold center">
            STEP 2: Promote Your Affiliate Link
        </span> <br>
        <p style="text-align:left !important">
            Your affiliate link will take people to our powerful webinar-on-demand! This webinar will do all of the telling, selling and explaining of how the Easiest System Ever will provide them with a 
            done-for-you online business; including an email list, products to sell, and 1-on-1 coaching!
            <br><br>
            Marketing materials are provided for you in your affiliate member's area. The more you promote your affiliate link, the more sales you'll make! We follow up with your leads automatically!
            
        </p>
        <br><br>
      </div>
    <p style="width:100%;text-align:center;font-size:16pt;font-weight:bold;">
      <a href="{{webinarlink}}" target="_blank"><u>CLICK HERE TO SEE THE PAGE YOU’LL BE PROMOTING</u></a>
    </p>
    <br><br>
    <div class="t3 center">
        <span class="t2 red bold center">
            STEP 3: Our Phone Team Calls/Closes Your Leads
        </span> <br>
        <p style="text-align:left !important">
            After someone has watched our high-converting webinar, they are taken to a page where they can request a call back from one of our team! When someone fills out a "call-back request form", you instantly see that lead in your affiliate back office (so you have FULL TRANSPARENCY on the number of leads you've created & the progress of each lead).
            <br><br>
            Our sales team then calls the lead, and offers them one of our done-for-you business packages. Depending on which package your lead purchases, you make a commission of between $150 and $1,000!! 

            
        </p>
        <br><br>
      </div>
      
      <div class="t3 center">
          <span class="t2 red bold center">
              STEP 4: Receive Your $150 - $1,000 Commissions
          </span> <br>
          <p style="text-align:left !important">
              Once the sale has cleared and the grace period has passed. We'll automatically deposit your commissions into your PayPal account on the 1st or the 15th of the month. No need to request payout or anything like that... we just send you your commissions when it's time!
              <br><br>
              Note: Wire transfers available for commissions over $5,000 USD!!

  
              
          </p>
          <br><br>
        </div>
        
        <div class="t3 center">
            <span class="t2 red bold center">
                THE ONE CATCH...
            </span> <br>
            <p style="text-align:left !important">
                The only thing we ask is that you send QUALITY/LEGIT traffic to this offer. Traffic from the USA, Canada, Australia, New Zealand, and the UK is preferred, as the sales team that will be working for you only speaks English. Any affiliate caught sending crappy traffic will have their affiliate account terminated.
  
    
                
            </p>
            
          </div>
    <hr>
    

    
    <div class="center bold t1" style="font-size:3em !important;">
      Ready To Get Started?<br>
      Create Your Affiliate Account Now
    </div>
    <br/><br/>
    <form #f="ngForm" (ngSubmit)="save(f.value, f.valid)">

      <mat-form-field class="example-full-width">
        <input class="t5" name="username" matInput #name="ngModel" (ngModel)="user?.username" type="text" id="username" placeholder="Enter a Username" required>
        <mat-error>This is a required field</mat-error>
        <mat-hint>Enter a Username. This will be you affiliate Id</mat-hint>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="password" matInput #password="ngModel" (ngModel)="user?.password" type="password" id="password" placeholder="Password" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="password_check" matInput #password_check="ngModel" (ngModel)="user?.password_check" type="password" id="password_check" placeholder="Password Again" [equalTo]="password" required>
        <mat-error>This is a required field</mat-error>
        <mat-error *ngIf="password_check.errors?.equalTo">Passwords Do Not Match</mat-error>
      </mat-form-field>

      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput placeholder="First name" type="text" name="firstname" #name="ngModel" (ngModel)="user?.firstname" type="text" id="firstname" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput placeholder="Last Name" name="lastname" #name="ngModel" (ngModel)="user?.lastname"  type="text" id="lastname" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
        <input class="t5" name="email" matInput #email="ngModel" (ngModel)="user?.email" type="text" id="email" placeholder="Email" required email/>
        <mat-error>This is a required field</mat-error>
        <mat-error *ngIf="email.errors?.email">Please Enter an email in the correct format</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="address" matInput #name="ngModel" (ngModel)="user?.address" type="text" id="address" placeholder="Address" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="address2" matInput #name="ngModel" (ngModel)="user?.address2" type="text" id="address2" placeholder="Address 2">
      </mat-form-field>

      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput placeholder="City" type="text" name="city" #name="ngModel" (ngModel)="user?.city" type="text" id="city" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput placeholder="State" name="state" #name="ngModel" (ngModel)="user?.state"  type="text" id="state" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput placeholder="Zip" name="zip" #name="ngModel" (ngModel)="user?.zip"  type="text" id="zip" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
        <input class="t5" name="skype" matInput #skype="ngModel" (ngModel)="user?.skype" type="text" id="skype" placeholder="Skype">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="phone" matInput #phone="ngModel" (ngModel)="user?.phone" type="text" id="phone" placeholder="Best Phone">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="paypal" matInput #paypal="ngModel" (ngModel)="user?.paypal" type="text" id="paypal" placeholder="Paypal">
      </mat-form-field>


      <div style='text-align:center;width:100%;'>
      <button type="submit" class="btn btn-primary" style="width:50%;font-size:2em;" [disabled]="!f.valid">Create Affiliate Account</button>
      </div>
    </form>
  </section>
  <section class="col-mat-2"></section>
</div>
<br/><br/><br/><br/>
