import { Injectable } from '@angular/core';
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";
import 'rxjs/Rx';
import {Record} from "../crm/record";
import {Zapier} from "./zapier-test/zapier";

@Injectable()
export class LeadsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  deleteRecord(leads: any){
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.delete(this.hostName +'/api2/leads/deleterecord/' + leads._id, {headers: headers})
    
  }

  getleads() {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/getleads'+token, body, {headers: headers})
      
  }

  getallleads() {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username});
    
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post(this.hostName +'/api2/leads/getallleads', body, {headers: headers})
      
  }

  testzapier(zapier: Zapier){
    const body = JSON.stringify(zapier);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/testzapier'+token, body, {headers: headers})
      
  }
}
