<style>
    strong {font-weight:900;}
</style>
<div class="container">
<p><strong>WWW.easiestsystemever.COM<br /> TERMS OF SERVICE </strong></p>
<p><strong><em>Updated 1_/2019</em></strong></p>
<p><strong>PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY BEFORE ACCESSING ANY PART OF THE WEBSITE.</strong></p>
<p>The following are the terms and conditions for use of www.easiestsystemever.com and its services (collectively, the "Website"). The Website is owned and operated by Easiest System Ever, L.L.C. in partnership with MediaGuild LLC ("ESE/MediaGuild" or &ldquo;us,&rdquo; or &ldquo;we&rdquo;).</p>
<p>By accessing the Website, continuing to access the Website, or submitting any information through the Website, and in consideration for the services ESE/MediaGuild provides to you, YOU (the terms "you" or "yours" includes the person or entity that is accessing the Website as well as any third-parties accessing the Website on your behalf) ARE STATING THAT YOU AGREE TO BE BOUND BY ALL OF THESE/MediaGuild TERMS AND CONDITIONS GOVERNING THE USE OF THE WEBSITE AND ITS SERVICES (the "Terms"). Use of the Website is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein.</p>
<ol>
<li><strong> OVERVIEW; ACTIVE PARTICIPATION AND EARNINGS DISCLAIMER</strong></li>
</ol>
<p>ESE/MediaGuild operates easiestsystemever (ESE) is an online system where</p>
<p><strong><br /> Member Program. </strong></p>
<p>The EasiestSystemEver Member Program helps online entrepreneurs to build their email list and earn commissions when they sell any number of digital products through affiliate marketplaces such as Clickbank, Warrior Plus, or JVZoo. The digital products that members can promote cover a wide range of topics; including (but not limited to): Internet Marketing, Entrepreneurship, Personal Development, and Weight Loss.</p>
<p>ESE does this by providing its members with done-for-you webpages and pre-written email sequences that are designed to help them build their email list, and promote any affiliate product they want to.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Affiliate Program. </strong></p>
<p>Anyone can promote ESE for free. &nbsp;There is never any charge to be a ESE Affiliate. Affiliates earn commissions on sales of the ESE program through their affiliate link. An affiliate need not be a member of the Member Program in order to earn commissions on the sale.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Earnings Disclaimer.</strong></p>
<p>ESE/MediaGuild does not make any representation or guarantee that any participants in either the Member Program or the Affiliate Program can, or are likely to, achieve any specific level of revenue.</p>
<p>You should not expect to experience the results contained in any testimonials.</p>
<p>ESE/MediaGuild does not guarantee you will earn any income from your use of any ESE/MediaGuild products or services.</p>
<p>ESE/MediaGuild does not guarantee you will earn income in any particular timeframe.</p>
<p>ESE/MediaGuild does not guarantee you will earn any particular level of income.</p>
<p>ESE/MediaGuild does not guarantee that you will recoup the cost of the Member Program.</p>
<p>ESE/MediaGuild does not guarantee that generating leads will result in sales or commissions. In other words, whether you generate a lead or pay ESE/MediaGuild to generate traffic for you, ESE/MediaGuild cannot and does not guarantee that any such leads or traffic will result in actual sales or commissions.</p>
<p>ESE/MediaGuild does not guarantee any particular conversion rates for leads or commissions.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Leads, Lists, and Contacts.</strong></p>
<p>Both you and ESE/MediaGuild have the right to use all leads, lists, or contacts generated or submitted through any ESE/MediaGuild services. Specifically, you grant to ESE/MediaGuild a perpetual license to use all leads, lists, or contacts generated or submitted through any ESE/MediaGuild services by you, or on your behalf, for any and all lawful purposes.</p>
<ol>
<li><strong> USER ACCOUNTS, REGISTRATION, AND INFORMATION SUBMISSION</strong></li>
</ol>
<p><strong>Registration. </strong>In order to utilize some of the Website&rsquo;s services, you must register and create an account. In order to create an account, you must complete the registration process by providing ESE/MediaGuild, or a third-party engaged by ESE/MediaGuild, with complete and accurate information as prompted by the registration form, including contact information, a username, and password. You shall protect your password and take full responsibility for your own and third party activities that occur under your account. You agree to notify ESE/MediaGuild immediately of any unauthorized use of your account or any other breach of security. If you create an account on behalf of an entity, thESE/MediaGuild Terms bind both you and the entity.</p>
<p><strong>Refusal of Service. </strong>ESE/MediaGuild rESE/MediaGuildrves the right, with or without notice, to terminate the account of, or refuse service to, any persons that violate thESE/MediaGuild Terms, violate any party's intellectual property rights, abuse other Website users, misuse the Website or its services, or otherwise engage in inappropriate conduct, as determined by ESE/MediaGuild in its sole discretion.</p>
<p><strong>III. MODIFICATIONS TO TERMS OF SERVICE AND OTHER POLICIES</strong></p>
<p>ESE/MediaGuild rESE/MediaGuildrves the right to change or modify any of the terms and conditions contained in thESE/MediaGuild Terms or any policy governing the use of the Website or its services, at any time, by posting the new terms on the Website. The most current version of thESE/MediaGuild Terms will be located on this page. You understand and agree that your access to or use of the Website is governed by the then-current Terms that are effective at the time of your access to or use of the Website. If we make material changes to thESE/MediaGuild Terms, we will notify you by updating the "Updated" date indicated at the top of this page with the date that revisions to thESE/MediaGuild Terms were last made. You should revisit thESE/MediaGuild Terms on a regular basis as revised versions will be binding upon you. Any such modification will be effective upon our posting of such new Terms. <strong>You understand and agree that your continued access to or use of the Website after the effective date of modifications to the Terms indicates your acceptance of the modifications </strong>.</p>
<ol>
<li><strong> PROPRIETARY RIGHTS</strong></li>
</ol>
<p>ESE/MediaGuild expressly ESE/MediaGuild all right, title, and interest in and to the Website and the Website's content, including any copyright, patent, or trademark or other intellectual property right, or federal or state right, pertaining thereto.</p>
<ol>
<li><strong> USER-GENERATED/THIRD-PARTY CONTENT</strong></li>
</ol>
<p><strong>Overview and Age Restrictions. </strong>The Website and its services may, from time to time, allow users and third-parties to upload, post, or otherwise transmit user-generated content, including but not limited to comments, recommendations, pictures, reviews, images, and other forms of data or communications. ("User Content"). By submitting User Content, you represent that you are 13 years of age or older, and, if under the age of 19, you are either an emancipated minor, or have obtained the legal consent of your parent or legal guardian to enter into thESE/MediaGuild Terms and use the Website and its services.</p>
<p><strong>User Obligations and License</strong> ; <strong>Intellectual Property.</strong> You alone own and are solely responsible for all User Content you post, upload, or otherwise transmit (collectively "Post") through the Website and once Posted, it cannot always be withdrawn. You agree not to Post through the Website any User Content which is false, defamatory, infringes upon another party's intellectual property, or is otherwise illegal. By Posting User Content on the Website, you warrant and represent that you have the right to post such information and that such information is truthful and accurate.</p>
<p>By Posting User Content, you automatically grant, and warrant and represent that you have the right to grant, ESE/MediaGuild a perpetual, irrevocable, royalty-free, worldwide, non-exclusive, assignable, sublicensable, transferable license to use, copy, perform, display, and distribute such User Content for any purpose and to prepare derivative works of, or incorporate into other works, such User Content.</p>
<p>You assume all risks associated with your Posted User Content on the Website, including anyone's reliance on its quality or reliability. By Posting User Content you may expose yourself to liability if, for example, your Posted User Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation.</p>
<p><strong>Rules of Conduct. </strong>In addition to all other obligations and terms stated in thESE/MediaGuild Terms, you agree to abide by any rules of conduct or community guidelines posted on the Website. ESE/MediaGuild rESE/MediaGuildrves the right to exclude or suspend any user who violates thESE/MediaGuild rules of conduct, and rESE/MediaGuildrves the right, but does not undertake an affirmative obligation, to remove, screen or edit any content which is contrary to thESE/MediaGuild rules without prior notice. User Content does not necessarily reflect the opinion of ESE/MediaGuild.</p>
<p><strong>Communications Decency Act</strong> . As provided in 47 U.S.C. &sect; 230(c)(1), ESE/MediaGuild is only a distributer, and not the publisher or speaker, of any User Content. As such, ESE/MediaGuild cannot be held liable for making available any User Content which may be false or inaccurate. Any information or opinions contained in the User Content made available through the services are those of their respective authors alone. ESE/MediaGuild does not guarantee the accuracy, completeness, or truthfulness of any User Content. Under no circumstances will ESE/MediaGuild be responsible for any loss or damage resulting from any person's reliance on any User Content.</p>
<p><strong>RESE/MediaGuildrvation of Rights</strong> . Subject to Section VI below, regarding the DMCA, ESE/MediaGuild rESE/MediaGuildrves the right to remove or not remove any User Content from the Website for any reason or no reason at all, in ESE/MediaGuild&rsquo;s sole discretion. This rESE/MediaGuildrvation includes the exclusive right to decide whether to publish, withdraw, postpone, or alter any User Content. ESE/MediaGuild rESE/MediaGuildrves the right, but does not undertake any affirmative obligation, to screen, monitor, or filter User Content.</p>
<p><strong>Information Storage and Access. </strong>ESE/MediaGuild rESE/MediaGuildrves the right, in its sole discretion, to determine whether and how long to store User Content and user data. ESE/MediaGuild will not be responsible for any liability related to the deletion or removal or any data or content maintained on the Website or ESE/MediaGuild's servers.</p>
<p><strong>Suggestions</strong> . By sending us any ideas, suggestions, documents or proposals ("Feedback"), you agree that (i) your Feedback does not contain the confidential or proprietary information of third parties, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, and (iv) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify, prepare derivative works, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against ESE/MediaGuild and its users any claims and assertions of any rights, whether intellectual property rights or otherwise, contained in such Feedback.</p>
<ol>
<li><strong> DIGITAL MILLENNIUM COPYRIGHT ACT</strong></li>
</ol>
<p>ESE/MediaGuild respects the intellectual property rights of others and complies with safe harbor provisions of the Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;). Anyone who believes that their work has been reproduced on the Website in a way that constitutes copyright infringement may notify ESE/MediaGuild&rsquo;s copyright agent in accordance with Title 17, United States Code, Section 512(c)(2), by providing the following information:</p>
<ol>
<li>Identification of the copyrighted work that you claim has been infringed;</li>
<li>Identification of the material that you claim is infringing and needs to be removed, including a description of where it is located on the Website so that the copyright agent can locate it;</li>
<li>Your address, telephone number, and, if available, e-mail address, so that the copyright agent may contact you about your complaint; and</li>
<li>A signed statement that the above information is accurate; that you have a good faith belief that the identified use of the material is not authorized by the copyright owner, its agent, or the law; and, under penalty of perjury, that you are the copyright owner or are authorized to act on the copyright owner&rsquo;s behalf in this situation.</li>
</ol>
<p>Notifications of claimed infringement should be forwarded to:</p>
<p><strong>[Insert name of DMCA Agent] [Also, need to file the name of the DMCA agent with the U.S. Copyright Office.</strong></p>
<p><br /> If you give notice of copyright infringement by text e-mail, ESE/MediaGuild&rsquo;s copyright agent may begin investigating the alleged copyright infringement; however, we must receive your signed statement by mail or as an attachment to your e-mail before we are required to take any action.</p>
<p>ESE/MediaGuild rESE/MediaGuildrves the right, in its sole discretion, to terminate accounts for users that are deemed to be repeat copyright infringers.</p>
<p><strong>VII. PAYMENT AND REFUND POLICY</strong></p>
<p>If you purchase ESE/MediaGuild's premium services, or any other product or service available for purchase through the Website or ESE/MediaGuild's services ("Premium Services"), then you consent to ESE/MediaGuild storing your payment information. You agree to pay the applicable fees for the Premium Services as they become due and to reimburse ESE/MediaGuild for all collection costs and interest. The failure to pay may result in the termination of your account or subscription. Your obligation to pay fees continues through the end of the subscription period during which you cancel your subscription. ESE/MediaGuild does not guarantee refunds for lack of usage or dissatisfaction. Prices are subject to change.</p>
<p>Easiest Sales System is a division of Easiest System Ever L.L.C., all credit card charges for purchases made through the Website will appear as Easiest System Ever L.L.C.</p>
<p>If you purchase the Member Program, your credit card will be charged the applicable price and then the same amount every 30 days or 180 days based on your chosen billing schedule. You can choose to cancel at any time by giving ESE/MediaGuild 10 days' advanced notice.</p>
<p>ESE/MediaGuild does not guarantee any refunds. ESE/MediaGuild, may, in its discretion, provide you a limited refund if ESE/MediaGuild receives your request to terminate your account before ESE/MediaGuild charges your account for the following term. The maximum refund you could receive would be the amount charged to your account after you provided ESE/MediaGuild notice that you want to terminate your account.</p>
<p><strong>VIII. THIRD-PARTY SERVICES</strong></p>
<p>The use of one or more third parties may be necessary for you to take full advantage of the ESE/MediaGuild Programs.</p>
<p>You may need to obtain and pay for accounts with third-party service providers such as Get Response and AWeber in order to utilize some or all of ESE/MediaGuild's services.</p>
<p>The Website or its services may use or provide access to one or more third-parties to process payments, process account or user registrations, or provide other services. The use of such third-party services are subject to the third-party's terms of service. Any disputes arising regarding a third-party's services must be resolved directly between user and the third-party. ESE/MediaGuild disclaims all warranties or representations regarding any third-party services.</p>
<ol>
<li><strong> REPRESENTATIONS, WARRANTIES, OR DISCLAIMERS</strong></li>
</ol>
<p>ESE/MediaGuild does <u>not</u> represent or warrant and expressly <u>disclaims</u> any warranty that (i) any information provided by the Website will be accurate, (ii) the Website or related services will be error-free or accessible at all times, (iii) defects will be corrected, (iv) the Website or the server that makes it available, are free of viruses or other harmful component, or (v) the use or the results of the use of the Website or the materials made available as part of the Website will be correct, accurate, timely, or otherwise reliable.</p>
<p>Further, ESE/MediaGuild expressly <u>disclaims</u> any representation or warranty relating to any products, services, or companies displayed, described, advertised, reviewed, recommended, linked to, or pictured on the Website, including any MLM companies or services. ESE/MediaGuild does not endorse any such products, services, or companies and does not warrant that any such products, services, or companies are available, will meet the user's needs, are appropriate for any particular use, or are of any certain quality. Likewise, ESE/MediaGuild does not guarantee that any product, service, or company descriptions, reviews, portrayals, specifications, or the like are accurate. Under no circumstances will ESE/MediaGuild be responsible for any loss or damage resulting from any person's reliance on any such product, service, or company descriptions, reviews, or specifications contained on the Website.</p>
<p>THE WEBSITE AND ITS SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND THERE ARE NO WARRANTIES, CLAIMS OR REPRESENTATIONS/MediaGuildNTATIONS MADE BY ESE/MediaGuild EITHER EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO THE WEBSITE, INCLUDING WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE USAGE. ESE/MediaGuild DOES NOT WARRANT THAT THIS WEBSITE'S SERVICES WILL MEET YOUR NEEDS OR BE FREE FROM ERRORS, THAT THE OPERATION OF THE WEBSITE WILL BE UNINTERRUPTED, AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE WEBSITE, OR AS TO THE ACCURACY, RELIABILITY, OR CONTENT OF ANY INFORMATION, PRODUCTS, OR SERVICES PROVIDED THROUGH THE WEBSITE. THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THIS AGREEMENT AND FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED FOR THE SERVICES. SOME STATES DO NOT ALLOW EXCLUSION OF AN IMPLIED WARRANTY, SO THIS DISCLAIMER MAY NOT APPLY TO YOU.</p>
<p>If you access the Website or any of its related services through a mobile device, your wireless service carrier may charge you data rates and related fees.</p>
<ol>
<li><strong> HYPERLINKS</strong></li>
</ol>
<p>A link from the Website to a non-ESE/MediaGuild website (including an MLM company) does not mean that ESE/MediaGuild endorses or accepts any responsibility for the content, functioning, policies, or use of such website, and you enter any such website at your own risk. It is your responsibility to take precautions to ensure that whatever websites or other online materials that you select for use are free of viruses and other items of a destructive nature. Additionally, we suggest you review the linked site&rsquo;s terms of service and privacy policy, and if you do not agree to be bound by the terms of that site, terminate your visit to that site. We are not responsible for the privacy policies and practices of the sites operated by our business partners or other third parties. ESE/MediaGuild expressly disclaims any liability related to such sites. ESE/MediaGuild also prohibits unauthorized hypertext links to the Website or the framing of any content available through the Website. ESE/MediaGuild rESE/MediaGuildrves the right to disable any unauthorized links or frames.</p>
<ol>
<li><strong> COMPLIANCE AND PROHIBITED ACTIONS</strong></li>
</ol>
<p>You will not use the Website or its services in any way or for any purpose that would violate, or would have the effect of violating, any applicable laws, rules or regulations or any rights of any third-parties, including without limitation, any law or right regarding any copyright, patent, trademark, trade secret, or other proprietary or property right, false advertising, unfair competition, defamation, invasion of privacy, rights of celebrity, or other federal or state law, rule, or regulation.</p>
<p>You may not attempt to try to &ldquo;trick&rdquo; the ESE/MediaGuild system, manually add the names of people who have not asked to be contacted, or, engage in any other behavior that is deemed dishonest or against best practices. Such conduct will result in the immediate cancellation of your account without refund.</p>
<p><strong>XII. INDEMNIFICATION </strong></p>
<p>You agree to indemnify, hold harmless and defend ESE/MediaGuild, at your expense, against any and all third-party claims, actions, proceedings, and suits brought against ESE/MediaGuild or any of its officers, directors, employees, agents or affiliates, and all related liabilities, damages, settlements, penalties, fines, costs or expenses (including, without limitation, reasonable attorneys&rsquo; fees) incurred by ESE/MediaGuild or any of its officers, directors, employees, agents or affiliates, arising out of or relating to (i) your breach of any term or condition of thESE/MediaGuild Terms, (ii) your posting of User Content, (iii) your use of the Website, or (iv) your unauthorized use of the Website. In such a case, ESE/MediaGuild will provide you with written notice of such claim, suit or action. You shall cooperate as fully as reasonably required in the defense of any claim. ESE/MediaGuild rESE/MediaGuildrves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you.</p>
<p><strong>XIII. LIMITATIONS OF LIABILITY </strong></p>
<p>ESE/MediaGuild WILL NOT BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY (A) INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR INCIDENTAL DAMAGES, (B) LOSS OF PROFITS, (C) LOSS OF DATA OR INFORMATION, OR (D) REPUTATIONAL HARM, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY OR CONTRIBUTION, OR OTHERWISE, EVEN IF ESE/MediaGuild HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE LIMITATIONS OR EXCLUSIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.</p>
<p>NOTWITHSTANDING ANYTHING SET FORTH IN THIS AGREEMENT TO THE CONTRARY, ESE/MediaGuild'S CUMULATIVE LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY LOSS OR DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS ARISING OUT OF OR RELATING TO THIS AGREEMENT SHALL NOT EXCEED THE GREATER OF (1) THE AGGREGATE FEES PAID BY YOU FOR THE WEBSITE'S SERVICES DURING THE PREVIOUS SIX MONTHS, OR (2) ONE HUNDRED AND 00/100 DOLLARS ($100). YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE WEBSITE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE WEBSITE</p>
<p><strong>XIV. TERMS AND TERMINATION</strong></p>
<p>You may terminate thESE/MediaGuild Terms at any time by closing your account, discontinuing your use of the Website and providing ESE/MediaGuild with a notice of termination. Please review our Privacy Policy for information about what we do with your account when terminated. We may terminate your account, suspend your ability to use certain portions of the Website and/or ban you altogether from the Website for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your account, the Website, your User Content, other content on the Website, or any other related information. In the event of any termination of thESE/MediaGuild Terms, whether by you or by ESE/MediaGuild, the preamble and Sections IV, V, IX, XI-XIII, and XVII-XVIII will continue in full force and effect, including our right to use your User Content as detailed in Section V.</p>
<ol>
<li><strong> PRIVACY</strong></li>
</ol>
<p>The Website is governed by the Easiest System Ever L.L.C. Privacy Policy which is incorporated into ESE/MediaGuild Terms by this reference. Please read the Privacy Policy before accessing the Website or providing any personal information through the Website or its services.</p>
<p>My submitting your contact information to ESE/MediaGuild, you consent to ESE/MediaGuild contacting you via any of the contact information you provide, including telephone or email.</p>
<p>By submitting any information to ESE/MediaGuild, you consent to ESE/MediaGuild disclosing that same information to any affiliate that referred you to ESE/MediaGuild through the use of a hyperlink associated with that affiliate.</p>
<p><strong>XVI. SPECIAL ADMONITIONS FOR INTERNATIONAL USE</strong></p>
<p>Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct and acceptable content. Specifically, you agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside and to comply with any other local laws affecting the transmission or posting of content or affecting the privacy of persons.</p>
<p><strong>XVII. APPLICABLE LAW AND VENUE</strong></p>
<p>ThESE/MediaGuild Terms shall be governed by and construed under the laws of the state of Georgia without reference to its conflicts of law principles. In the event of any conflicts between foreign law, rules, and regulations, and Georgia law, rules, and regulations, Georgia law, rules and regulations shall prevail and govern. Each party agrees to submit to the exclusive and personal jurisdiction of the courts located in the State of Georgia. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions Act shall not apply to thESE/MediaGuild Terms. The services provided by ESE/MediaGuild hereunder are controlled by U.S. Export Regulations, and they may not be exported to or used by embargoed countries or individuals. Any notices to ESE/MediaGuild must be sent to:</p>
<p>Easiest System Ever LLC</p>
<p>10945 State Bridge Rd Suite 401-274</p>
<p>Alpharetta, GA 30022</p>
<p>via first class or air mail or overnight courier, and are deemed given upon receipt.</p>
<p><strong>XVIII. MISCELLANEOUS</strong></p>
<p>ThESE/MediaGuild Terms (including any amendment agreed upon by the parties in writing) represent the complete agreement between us concerning its subject matter, and supersedes all prior agreements and representations between the parties. If any provision of thESE/MediaGuild Terms are held to be unenforceable for any reason, such provision shall be reformed to the extent necessary to make it enforceable to the maximum extent permissible so as to affect the intent of the parties, and the remainder of thESE/MediaGuild Terms shall continue in full force and effect. A waiver of any default is not a waiver of any subsequent default. The relationship between ESE/MediaGuild and you is not one of a legal partnership relationship, but is one of independent contractors. ThESE/MediaGuild Terms shall be binding upon and inure to the benefit of the respective successors and assigns of the parties hereto.</p>
<p>ESE/MediaGuild may be contacted at any of the following:</p>
<p>Easiest System Ever LLC</p>
<p>10945 State Bridge Rd Suite 401-274</p>
<p>Alpharetta, GA 30022</p>
<p>Telephone: 770-573-4694</p>
<p>Email: <a href="mailto:easiestsystemever@gmail.com">easiestsystemever@gmail.com</a></p>
<p>&nbsp;Copyright &copy; 2019 easiestsystemever.com <br /> A Division Of Easiest System Ever LLC</p>
</div>