import {Component, OnInit, Inject, EventEmitter, Output, ViewChild} from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { Images } from "./files";
import {FilesService} from "./files.service";
import {GridOptions} from "ag-grid-community";
import {FilesInputComponent} from "./files-input/files-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import {FileUploadComponent} from "./fileupload/fileupload.component";
import {DOCUMENT} from "@angular/common";
import {ClipboardService} from "../../../services/clipboard.service";
import { trigger, state, transition, style, animate } from '@angular/animations'
import { LightBoxModule, ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { FileUploadService } from './fileupload/fileupload.service';
import * as screenfull from 'screenfull';
import { CommService } from '../../../services/comm.service';
import { Subscription } from 'rxjs';
//import { ImagesUploadComponent } from './images-upload.component/images-upload.component';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class FilesComponent implements OnInit {
  username=localStorage.getItem('username')?localStorage.getItem('username'):'';
  category="hb";
  imageType="backgrounds"; //this.username;
  filterPath="defaults/"+this.category+"/"+this.imageType;; //this.username;
  showUpload=false;
  @Output() imagePath = new EventEmitter<any>();
  
 changeColor(){
   this.commService.sendMessage({'action':'backgroundColor', 'color':this.color });
 }

  color="#cccccc"
  data;
  columnDefs;
  confirmPressed = false;
  clipUrl:string = "dave";
  @Output() public closeButtonClick:EventEmitter<any> = new EventEmitter();
  onCloseButtonClick($event){ console.log('dave now');this.closeButtonClick.emit('close');}

  showUploadComponent:boolean=false;
  @ViewChild('addMediaModal') addMediaModal: ModalDirective;
  onCloseAddMediaModal($event){ this.showUploadComponent=false;this.addMediaModal.hide();this.refresh(this.username);}
  onOpenAddMediaModal($event){ this.showUploadComponent=true;this.addMediaModal.show();}

   //Show Confirm Delete Modal
   @ViewChild('confirmModal') confirmModal: ModalDirective;
   confirmObj={title:'',body:'',icon:'',class:'',link:'',linkTxt:'',target:''};
   recordData;
   public onShowConfirmModal(data: any){
     this.confirmModal.show();
      this.confirmObj={title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn", link:"",linkTxt:"",target:""}
      this.recordData=data;
   }

   @ViewChild('colorModal') colorModal: ModalDirective;
   public onShowColorModal(){
    this.colorModal.show();
   }

   processEmitter(data){
     this.refresh(this.filterPath)
   }

   processFilter(){
    
     if (this.imageType==this.username){
       this.filterPath=this.imageType;
       this.showUpload=true;
     } else {
       this.filterPath="defaults/"+this.category+"/"+this.imageType;
       this.showUpload=false;
     }
    
     this.refresh(this.filterPath)
   }

   getImage(el){
     console.log("none elk:",el);
     if (el.description=="no-background"){
      this.commService.sendMessage({'action':'removeBackgroundUrl', 'url':"" });
     } else {
      this.commService.sendMessage({'action':'pageBackgroundUrl', 'url':el.url });
    }
   }

   confirmDelete(val:boolean){
    this.imagesService.deleteRecord(this.recordData)
          .subscribe(
            data => {
              this.data = data['obj'];
              this.toastrService.success(data['title'], data['message'], {opacity:1});

              this.refresh(this.filterPath);
            },
            error => this.toastrService.error(error.error['message'], error['title'], {opacity:1}),
          );
    }

  /*Clipboard Stuff*/
  public copyEvent: EventEmitter<string>;
  public errorEvent: EventEmitter<Error>;
  private clipboardService: ClipboardService;
  /*End Clipboard Stuff*/

  gridOptions: GridOptions;

visiblityState = 'hidden';
messageTitle;
messageBody;
icon;
step:String=null;
paginationSize=50;
galleryData=[]
myView="gallery"

changeViewToGallery(){
  this.myView="gallery"
}

changeViewToList(){
  this.myView="list";
  this.refresh(this.filterPath);
}

clickImage(event,el){
  console.log("myel:",el)
  this.imagePath.emit({data:el})
}

subscription:Subscription;

  constructor(
    private toastrService: ToastService, 
    clipboardService: ClipboardService, 
    private confirmService: ConfirmService, 
    private imagesService:FilesService,  
    private authService: AuthService, 
    public dialog: MatDialog,
    private uploadService:FileUploadService,
    private commService:CommService
    
    ) {

      this.subscription = this.commService.onMessage().subscribe(message=>{
        this.processEmitter(this.data);
        console.log('my message', message)

      })

    this.gridOptions = <GridOptions>{};

    /*Clipboard Stuff*/
    this.clipboardService = clipboardService;
    this.copyEvent = new EventEmitter();
    this.errorEvent = new EventEmitter();
    /*End Clipboard Stuff*/

    this.columnDefs = [
      {headerName: "Image", field: "url", cellRenderer: this.renderImage, cellStyle: {"text-align":"center"}},
      {headerName: "File Name", field: "name", sortable: true, filter:true, resizable:true},
      {headerName: "File Size", field: "size", cellRenderer: this.formatFileSize, sortable: true, filter:true, resizable:true},
      {headerName: "Updated At", field: "lastModified",cellRenderer: this.renderDate, sortable: true, filter:true, resizable:true, sort:'desc'},
      
     

      {
        headerName: "Actions", template: `
             
              <button mat-button color="primary" type="button" data-action-type="copy" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="https://funld.s3.amazonaws.com/images/primary-copy.png" style="width:15px;height:15px;">&nbsp;Copy
              </button>
              <br>
              <button mat-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
                <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
              </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      enableRangeSelection: true,
      getContextMenuItems: this.getContextMenuItems,
      allowContextMenuWithControlKey: true,
      
      getRowHeight: function(params) {
        // assuming 50 characters per line, working how how many lines we need
        return 300;
      }


    };
    this.refresh(this.filterPath);
    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

 formatFileSize(params) {
   
   var decimalPoint=2;
   var bytes=params.data.size;
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
 }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  getContextMenuItems(params) {
    var result = [
      { // custom item
        name: 'Alert ' + params.value,
        action: function () {window.alert('Alerting about ' + params.value); },
        cssClasses: ['redFont', 'bold']
      },
      { // custom item
        name: 'Always Disabled',
        disabled: true,
        tooltip: 'Very long tooltip, did I mention that I am very long, well I am! Long!  Very Long!'
      }, // built in copy item
      'copy'
    ];

    return result;
 }

  autoSizeAll() {
    var allColumnIds = [];
    this.columnDefs.forEach( function(columnDef) {
      allColumnIds.push(columnDef.field);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  renderImage(params){
    
    var index = params.value.indexOf('?');
    if (index!=-1){
      var url = params.value.substring(0,index);
    } else {
      url=params.value;
      var indexOfFileType=url.lastIndexOf('.');
      var fileExt=url.substring(indexOfFileType);
      
      switch (fileExt){
        case ".pdf":
          url="https://funld.s3.amazonaws.com/dsloan254/1580908802123.1892.cLJeyMr44Y.pdfext.png";
          break;
        case ".doc":
          url="https://funld.s3.amazonaws.com/dsloan254/1580908738398.7544.A4qcaCuhtA.docext.png";
          break;
        case ".txt":
          url="https://funld.s3.amazonaws.com/dsloan254/1580909199031.5188.uZiSviu577.txtext.png";
          break;
        case ".mp3":
          url="https://funld.s3.amazonaws.com/dsloan254/1580934010677.343.PI50OBddzL.mp3ext.png";
          break;
          
      }

    }
    return '<img style="height:300px" src="'+url+'">';
  }

  renderUrl(params){
    var index = params.value.indexOf('?');
    if (index!=-1){
      var url = params.value.substring(0,index);
    } else {
      url=params.value;
    }
    return '<a href="'+url+'" target="_blank">' + url + '</a>';
  }

  catchClick(event){
    
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onShowConfirmModal(data);
        case "copy":
          return this.onActionCopyClick(data, "myContainer");
        default:
          return this.imagePath.emit(event)
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(FilesInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    
    this.imagesService.editImages(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh(this.filterPath);

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.imagesService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this.toastrService.success(data['title'], data['message'], {opacity:1});

              this.refresh(this.filterPath);
            },
            error => this.toastrService.error(error.error['message'], error['title'], {opacity:1}),
          );
      }
    });

  }

  public onActionCopyClick(data: any, containerid){

    let index = data.url.indexOf('?');
   
    let url = data.url;
    if (index != -1){
     
      url = data.url.substring(0,index);
    }
    this.clipUrl=url;
    this.clipboardService
      .copy( url )
      .then(
        ( value: string ) : void => {
          this.copyEvent.emit( value );
          this.toastrService.success("Data Copied", "Success", {opacity:1});
        }
      )
      .catch(
        ( error: Error ) : void => {
          this.errorEvent.emit( error );
          this.toastrService.error("Failed","Data Did Not Copy",{opacity:1})
        }
      );


  }

  refresh(path){
    var body = {path:path};
    this.uploadService.getMyObjects(body)
      .subscribe(
        data => {
          var category='';
          this.galleryData=[{url:"/assets/images/no-background.png",thumb:"",description:"no-background"}];
          for (let i=0;i<data['imageInfo'].length; i++){
            var feed={'category':category,'src':data['imageInfo'][i].url}
            var galleryFeed={url:data['imageInfo'][i].url, thumb:data['imageInfo'][i].url,description:category,lastmodified:this.renderDate({value:data['imageInfo'][i].lastModified}),size:data['imageInfo'][i].size}
            //addData.push(feed);
            this.galleryData.push(galleryFeed);
          }
          
          const galleryData1=this.galleryData.sort((a,b)=>{
            
            var c: any = new Date(a.lastmodified)
            var d: any = new Date(b.lastmodified)
            return d-c
          });
          
          if (this.myView=="list"){
            this.data = data['imageInfo'];
            this.gridOptions.api.setRowData(data['imageInfo']);
            this.gridOptions.api.sizeColumnsToFit();
          }
        //  this.toastrService.success(data['title'],data['message'],{opacity:1})
        },
        error => this.toastrService.error(error['title'],error['message'],{opacity:1})
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(FilesInputComponent, {
      height: '95vh',
      width: '80%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh(this.filterPath);

      dialogRef = null;
    });
  }

  /*addImage(){
    let dialogRef = this.dialog.open(UploadComponent, {
      height: '95vh',
      width: '80%',

    });

    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  } */

  ngOnInit() {
  }

}
