import { Component, OnInit, SecurityContext } from '@angular/core';
import { WebinarService } from '../webinar.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApplicationService } from './application.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-application',
  templateUrl: './thankyou.component.html'
})
export class ThankyouComponent implements OnInit {
  isPopState = false;

  constructor(private router: Router, private locStrat: LocationStrategy, private route: ActivatedRoute, private applicationService: ApplicationService, private _service: ToastService,private sanitizer: DomSanitizer ) { 
   }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  

}
