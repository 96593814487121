import { Component, OnInit, } from '@angular/core';
import { NgForm, FormControlName } from "@angular/forms";
import {AuthService} from "../auth.service";
import {User} from "../user";
import {Router} from "@angular/router";
import { ToastService } from 'ng-uikit-pro-standard';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-congratulations',
  template: ` 
  <div class="row">
  <div class="col-md-8 mx-auto my-5">

<mdb-card>

  <mdb-card-header class="info-color white-text text-center py-4">
    <h5>
      <strong>Please Verify Your Account</strong>
    </h5>
  </mdb-card-header>

    <mdb-card-body>
    <h1>Please verify your account by clicking the link in the email that was just sent</h1><br>
    <h3>The Email Should Be From easiestsystemever.com And The Subject Line Should Say: <span style="color:red">"Please Verify Your Email Address"</span></h3><br>
    <h5>Sometimes emails can be picky. If you can't find it in your inbox, please check your SPAM Folder.
    If you are using Gmail and have tabs setup (for example updates, promotions and social), please be sure to check the different tabs for the verification email.</h5><br>
    <div style="margin:auto;width:75%;"><h4>Need Help? Email <a href="mailTo:support@easiestsystemever.com"><span>support@easiestsystemever.com</span></a> or Call: <strong>+1-678-894-1982 (M-F 10AM to 6PM EST)</strong></h4></div>

      
    </mdb-card-body>
  </mdb-card>

</div>
</div>
      `,
  styleUrls: ['./signup.component.css']
})
export class CongratulationsComponent implements OnInit {
  public user: User;
  constructor(private authService: AuthService,  private _service: ToastService, private router: Router) { }

  ngOnInit() {

  }
}
