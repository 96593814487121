
<div style="width:100%;background-color:#000;position:relative;">
    <button mat-icon-button mat-mini-fab color="primary" mat-dialog-close style="position:absolute;top:-20px;right:-20px;z-index:999">
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
</div>

<h2 mat-dialog-title>Edit Record</h2>
  <form (ngSubmit)="save(f.value, f.valid)" #f="ngForm">
    <mat-dialog-content>
    <div class="form-group">
      <input type="hidden" name="_id" [ngModel]="crm?._id">

      <mat-form-field class="example-full-width">
        <input name="username" matInput #username [ngModel]="crm?.username" type="text" id="username" placeholder="Username" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="user" matInput #username [ngModel]="crm?.user" type="text" id="user" placeholder="User ID">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="firstname" matInput #firstname [ngModel]="crm?.firstname" type="text" id="firstname" placeholder="First Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="lastname" matInput #lastname [ngModel]="crm?.lastname" type="text" id="lastname" placeholder="Last Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="email" matInput #email [ngModel]="crm?.email" type="text" id="email" placeholder="Email" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="source_url" matInput #source_url [ngModel]="crm?.source_url" type="text" id="source_url" placeholder="source_url">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="source_id" matInput #source_url [ngModel]="crm?.source_id" type="text" id="source_id" placeholder="source_id">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <label for="niche">Member type</label>
      <select id="niche" [ngModel]='crm?.type' name="type" class="browser-default custom-select" required>
          <option value="member" >Member</option>
          <option value="lead">Lead</option>
      </select>
      <br><br>

      <mat-form-field class="example-full-width">
        <input name="phone" matInput #phone [ngModel]="crm?.phone" type="text" id="phone" placeholder="Phone" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="skype" matInput #skype [ngModel]="crm?.skype" type="text" id="skype" placeholder="Skype" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="city" matInput #city [ngModel]="crm?.city" type="text" id="city" placeholder="City" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="state" matInput #state [ngModel]="crm?.state" type="text" id="state" placeholder="State" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="country" matInput #country [ngModel]="crm?.country" type="text" id="country" placeholder="Country" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="howlong" matInput #howlong [ngModel]="crm?.howlong" type="text" id="howlong" placeholder="How Long Have Your Been Doing This?" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="about" matInput #about [ngModel]="crm?.about" type="text" id="about" placeholder="About You" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="affiliate_id" matInput #affiliate_id [ngModel]="crm?.affiliate_id" type="text" id="affiliate_id" placeholder="Affiliate ID">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="tid" matInput #tid [ngModel]="crm?.tid" type="text" id="tid" placeholder="Tracking Id">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="query_string" matInput #query_string [ngModel]="crm?.query_string" type="text" id="query_string" placeholder="Query String">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      
          <mat-checkbox name="lifetime" #lifetime [ngModel]="crm?.lifetime" type="text" id="lifetime" placeholder="Lifetime Member">Lifetime Member</mat-checkbox>
          <br><br>
          <mat-checkbox name="cancelled_recurring" #cancelled_recurring [ngModel]="crm?.cancelled_recurring" type="text" id="cancelled_recurring" placeholder="Cancelled Recurring">Cancelled Recurring</mat-checkbox>
          <br><br>
          <mat-form-field class="example-full-width">
              <input name="traffic_level" matInput #traffic_level [ngModel]="crm?.traffic_level" type="text" id="traffic_level" placeholder="Traffic Levels">
              <mat-error>This is a required field</mat-error>
            </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="created_at" matInput #created_at [ngModel]="crm?.created_at" type="text" id="created_at" placeholder="Created At" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="updated_at" matInput #updated_at [ngModel]="crm?.updated_at" type="text" id="updated_at" placeholder="Updated At" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>


      <div>
        <mat-select name="status"  (selectionChange)="onStatusChange($event)" #status [ngModel]="crm?.status"  id="status" placeholder="Status"  floatPlaceholder="auto" class="example-full-width">
          <mat-option value="Contact 1">Contact 1</mat-option>
          <mat-option value="Contact 2">Contact 2</mat-option>
          <mat-option value="Unable to Contact">Unable to Contact</mat-option>
          <mat-option value="NI/NQ">NI/NQ</mat-option>
          <mat-option value="Appoitment Set">Appointment Set</mat-option>
          <mat-option value="Appoitment Kept">Appointment Kept</mat-option>
          <mat-option value="Sold">Sold</mat-option>
          <mat-option value="Not Interested">Not Interested</mat-option>
          <mat-option value="In Fullfillment">In Fullfillment</mat-option>
          <mat-option value="Duplicate">Duplicate</mat-option>
          <mat-option value="No Show">No Show</mat-option>
        </mat-select>
      </div>


      <mat-form-field class="example-full-width">
        <textarea (click)="addNotesTime('notes')" name="notes" matInput #notes [ngModel]="crm?.notes"  id="notes" placeholder="notes" style="height:200px;"></textarea>
      </mat-form-field>
      <br/><br/>
      <mat-form-field class="example-full-width">
        <textarea (click)="addPrivateNotesTime('private_notes')" name="private_notes" matInput #private_notes [ngModel]="crm?.private_notes"  id="private_notes" placeholder="Private Notes" style="height:200px;"></textarea>
      </mat-form-field>


    </div>
    <p></p><p></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
    </mat-dialog-actions>
  </form>
<br/><br/>

<mat-dialog-content>
  <form (ngSubmit)="sendEmail(g.value, g.valid)" #g="ngForm" name="emailForm">

    <input name="crm_firstname"  #crm_firstname type="hidden" ngModel='{{crmFirstname}}' id="crm_firstname" required>
    <input name="crm_lastname"  #crm_lastname type="hidden" ngModel='{{crmLastname}}' id="crm_lastname" required>
    <input name="crm_email"  #crm_email type="hidden" ngModel='{{crmEmail}}' id="crm_email" required>
    <input name="crm_phone"  #crm_phone type="hidden" ngModel='{{crmPhone}}' id="crm_phone" required>
    <mat-form-field appearance="fill" style="width:100%">
      <mat-label>From Email:</mat-label>
      <mat-select id="emailType" [(ngModel)]='emailType' name="emailType" class="example-full-width" >
        <mat-option value="sendgrid" selected>help@easysupportnow.com</mat-option>
        <mat-option value="gmail" selected>{{gmailUsername}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    
    <mat-form-field appearance="fill" style="width:100%">
      <mat-label>Email Template:</mat-label>
      <mat-select id="myEmail"  (selectionChange)="onChange($event)" #myEmail [(ngModel)]='emailName' name="emailName" class="example-full-width" >
        <mat-option [value]="'--'">Select Email</mat-option>
        <mat-option *ngFor="let email of emails" [value]=email._id>{{email.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
      <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Email Subject:</mat-label>
        <input name="email_subject" matInput #email_subject type="text" [(ngModel)]='emailSubject' id="email_subject"required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Email Body:</mat-label><br>
        <textarea  style="height:200px;border:solid 1px #ccc;border-radius:5px;padding:5px;" matInput name="email_body" #body  type="text" id="email_body" [(ngModel)]='emailBody' placeholder="" required></textarea>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
    <button mat-icon-button mat-mini-fab color="primary" type="submit">
      Send
    </button>
  </form>
</mat-dialog-content>
<script>

</script>

