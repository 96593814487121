import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Routes, RouterModule, Router, Params } from "@angular/router";
import {  AuthService } from '../../auth/auth.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { Templates } from "../templates";
import {TemplatesService} from "../templates.service";
import {GridOptions} from "ag-grid-community";
import {TemplatesInputComponent} from "../templates-input/templates-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import {ConfirmService} from "../../shared/confirm/confirm.service";
import {UploadComponent} from "../../upload/upload.component";
import { ImagesComponent } from '../../images/images.component';
import { CategoriesService } from '../../categories/categories.service';
import { DraganddropComponent } from '../../upload/draganddrop/draganddrop.component';
import { TemplatesInlineEditorComponent } from '../templates-inline-editor/templates-inline-editor.component';
import { TypesService } from '../../types/types.service';
import { SubtypesService } from '../../subtypes/subtypes.service';
import { GrapesjsComponent } from '../../webbuilder/grapesjs/grapesjs.component';

@Component({
  selector: 'app-templates-my',
  templateUrl: '../templates.component.html',
  styleUrls: ['../templates.component.css']
})
export class TemplatesMyComponent implements OnInit {
  categories;
  types;
  subtypes;
  data;
  columnDefs;
  confirmPressed = false;
  gridOptions: GridOptions;
  page_type;
  message:string;
  pageTitle:string;
  theme;
  paginationSize;
  constructor(private subtypesService: SubtypesService, private typesService: TypesService, private categoriesService: CategoriesService, private route: ActivatedRoute, private confirmService: ConfirmService, private templatesService:TemplatesService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.theme="ag-theme-dave";
    
    this.paginationSize="50";
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Image", field: "image", width: 520, cellRenderer: this.renderImage, cellStyle: {"text-align":"center"}},
      {headerName: "ID", field: "_id", editable: true},
      {headerName: "Created", field: "created_at", cellRenderer: this.renderDate},
      {headerName: "Updated", field: "updated_at", cellRenderer: this.renderDate, sort:'desc'},
      {headerName: "Category", field: "category.name",  editable: true},
      {headerName: "Name", field: "name"},
      {headerName: "Wid", field: "wid"},
      {headerName: "Domain", field: "domain"},
      {headerName: "Type", field: "type.name"},
      {headerName: "Sub Type", field: "subtype.name"},
      {headerName: "Created Using", field: "created_source"},
      {
        headerName: "Actions",  template: `<button mat-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="/assets/images/edit3.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
             <br>
             <button mat-button color="primary" type="button" data-action-type="inline" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Inline Editing
            </button>
            <br>
             <button mat-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
             <br>
             <button mat-button color="primary" type="button" data-action-type="copy" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="https://esev2.s3.amazonaws.com/images/primary-copy.png" style="width:15px;height:15px;">&nbsp;Clone
             </button>
             <br>
             <button mat-button color="primary" type="button" data-action-type="preview" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="https://esev2.s3.amazonaws.com/images/view.png" style="width:15px;height:15px;">&nbsp;Preview
             </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      
      getRowHeight: function(params) {
        return 130;
      }


    };
//test
    this.templatesService.getAllMyRecords()
      .subscribe(
        data => {

          this.data = data['obj'];
          console.log(this.data);
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  @ViewChild('sovrnModal') sovrnModal: ModalDirective;
  onCloseSovrnModal($event){ this.sovrnModal.hide();}
  onOpenSovrnModal(){
    this.sovrnModal.show();
    var data={"_id":"new"}
    this.templatesService.editTemplates(data);
  }
  onSovrnModalClosed($event){}

  public addWebBuilder(){
    let dialogRef = this.dialog.open(GrapesjsComponent, {
      
      width: '95%',
      height: '95vh'

    });
    var data={"_id":"new"}
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    console.log(data);
    this.templatesService.editTemplates(data);

    this.templatesService.setType(this.types);
    this.templatesService.setSubType(this.subtypes);
    this.templatesService.setCategory(this.categories);

    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  autoSizeAll() {
    var allColumnIds = [];
    this.columnDefs.forEach( function(columnDef) {
      allColumnIds.push(columnDef.field);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  renderImage(params){
    if (params.value){
      return '<img style="height:130px" src="'+params.value+'">';
    } else {
      return '<img style="height:130px" src="/assets/images/No_Image_Available.png">'
    }
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
        case "copy":
          return this.onActionCopyClick(data);
        case "preview":
          return this.onActionPreviewClick(data);
        case "inline":
          return this.onActionInlineClick(data);
      }
    }
  }

  public onActionInlineClick(data: any){
    let dialogRef = this.dialog.open(TemplatesInlineEditorComponent, {
      
      width: '98%',
      height: '95vh'

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    console.log(data);
    this.templatesService.editTemplates(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  onActionPreviewClick(params){
   window.open("/"+params.wid,'preview');
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(TemplatesInputComponent, {
      
      width: '95%',
      height: '95vh'

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    console.log(data);
    this.templatesService.editTemplates(data);

    this.templatesService.setType(this.types);
    this.templatesService.setSubType(this.subtypes);
    this.templatesService.setCategory(this.categories);
    

    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '200px',
      width: '300px',
      hasBackdrop: true,
      panelClass: 'myapp-no-padding-dialog'

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.templatesService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  public onActionCopyClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '200px',
      width: '300px',
      hasBackdrop: true,
      panelClass: 'myapp-no-padding-dialog'

    });

    this.confirmService.getText({title:"COPY?", body:"Really Copy It?", icon:"info", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.templatesService.copyRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.templatesService.getAllMyRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
   
    let dialogRef = this.dialog.open(TemplatesInputComponent, {
      height: '95vh',
      width: '80%',

    });

    this.templatesService.setType(this.types);
    this.templatesService.setSubType(this.subtypes);
    this.templatesService.setCategory(this.categories);

    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  addImage(){
    let dialogRef = this.dialog.open(ImagesComponent, {
      height: '95vh',
      width: '80%',

    });
  }

  ngOnInit() {
    this.route.params.forEach(params => {
      this.page_type=params.type;
      console.log(this.page_type);
      switch(this.page_type){
        case "splashtemplates":
          this.pageTitle="Splash Templates";
          break;
        case "templates":
          this.pageTitle="Document Templates";
          break;
      }
      this.templatesService.changeMessage(this.page_type);
      this.templatesService.currentMessage.subscribe(message => message = message)
      
    })

    this.categoriesService.getAllRecordsByRole({'role':'user'})
      .subscribe(
        data => {
          this.categories = data['obj'];
          console.log('my cat:',this.categories)
        },
        error => this._service.error('error','error'),
      );

      
  }

  ngAfterViewInit() {

    this.typesService.getAllRecordsByRole({'role':'user'})
      .subscribe(
        data1 => {
          this.types = data1['obj'];
          //console.log(this.types);
          
        },
        error => {
          //this.types={_id:"1",name:"",type:"",typesId:'1'};
          this._service.error(error.error['message'], error['title'])
        },
      );

    this.subtypesService.getAllRecordsByRole({'role':'user'})
    .subscribe(
      data2 => {
        this.subtypes = data2['obj'];
        console.log(this.subtypes);
      },
      error => {
        // this.subtypes={_id:"1",name:"",subtype:"",subtypesId:'1'};
        this._service.error(error.error['message'], error['title'])
      },
    );
  }

}
