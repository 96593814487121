//import component, ElementRef, input and the oninit method from angular core
import {Component, OnInit, ElementRef, Input, ChangeDetectorRef, EventEmitter, Output} from '@angular/core';
//import the file-draganddrop plugin
//import the native angular http and respone libraries

//import the do function to be used with the http library.
import "rxjs/add/operator/do";
//import the map function to be used with the http library
import "rxjs/add/operator/map";
import { ToastService } from 'ng-uikit-pro-standard';
import {DraganddropService} from "./draganddrop.service";
//import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { DraganddropEvent, DraganddropFile } from 'ngx-file-drop';

const URL = '/api/draganddrop/draganddrop';

@Component({
  selector: 'app-draganddrop',
  templateUrl: './draganddrop.component.html',
  styleUrls: ['./draganddrop.component.css']
})
export class DraganddropComponent implements OnInit {
  @Output() draganddropedId = new EventEmitter<any>();
  //public files: DraganddropFile[] = [];
  url;
  thumbUrl;
  draganddroped= true;
  sigingrequest;
  progress = false;
  public file_srcs: string[] = [];
  draganddropedImage: File;
  imagePreview: string;
  fileType;
  whatToDo = "Click or Drag Here to Draganddrop Attachments";
  myFileList=[];
  showUploadButton = false;
  showImagePreview = false;
  fileExtension;

  constructor(public sanitizer: DomSanitizer,  private el: ElementRef, private _service: ToastService, private draganddropService: DraganddropService, private changeDetectorRef: ChangeDetectorRef) { }

  // The next two lines are just to show the resize de;bug
  // they can be removed
  public debug_size_before: string[] = [];
  public debug_size_after: string[] = [];

  preDraganddrop(){
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    let thumbEl: HTMLInputElement = this.el.nativeElement.querySelector('#thumb');
   // this.draganddrop(inputEl);
  }

  onDrop(event) {
    event.preventDefault();
    console.log(event.dataTransfer.files[0].name);
    let image = event.dataTransfer.files[0];
    this.processFiles(image);
  }
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onImageChange(event){
    let image = event.target.files[0];  
    this.processFiles(image);
  }
 

  processFiles(image) {
    var imageFileType =['png','jpg','jpeg','gif'];
    
    console.log(image);
    var fileParts = image.name.split('.');
    console.log(fileParts[1]);
    
    this.fileExtension=image.name.substring(image.name.lastIndexOf('.')+1);
    console.log(this.fileExtension);
    this.fileType=image.type;
    //this.ng2ImgMax.resizeImage(image, 400, 300).subscribe(
      if (imageFileType.indexOf(this.fileExtension) >= 0){
       /* this.ng2ImgMax.compressImage(image, 0.100).subscribe(
          result => {
            this.draganddropedImage = new File([result], result.name);
            console.log(this.draganddropedImage.type);
            this.getImagePreview(this.draganddropedImage);
            //this.draganddrop();
            this.showUploadButton=true;
          },
          error => {
            console.log('😢 Oh no!', error);
          }
        );*/
      } else {
        console.log('noe');
        this.draganddropedImage = image;
        this.draganddroped=false;
        this.whatToDo=image.name;
        this.upload();
      }
      
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      this.showImagePreview=true;
      this.draganddroped=false;
    };
  }

  upload(){
    var fileTypes = {htm: 'text/html'};
    this.progress=true;
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    console.log(inputEl);
    console.log("name = " + this.draganddropedImage.name + "  type = " + this.draganddropedImage.type);
    
    //var file = inputEl.files.item(0);
    var file = this.draganddropedImage;
    console.log(file);
    console.log(this.fileType);
    let fileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
    this.draganddropService.getSignedRequest(fileName, this.fileType)
      .subscribe(
        data => {
          this.sigingrequest=data;
          this.finalDraganddrop(this.sigingrequest, file, this.fileType)
        },
        error => {
          this.progress=false;
          this._service.error(error.error['message'], error['title'])
        },
      );
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  finalDraganddrop(data, file, fileType){
    this.draganddropService.draganddrop(data.signedRequest, file, fileType)
      .subscribe(
        data => {
          console.log(data);
          if (data['status']==200){
            this.progress=false;
            this.draganddroped=true;
            this.draganddropService.addRecord(data)
              .subscribe(
                data => {
                  console.log(data['obj']._id);
                  data['obj'].extension=this.fileExtension;
                  this.myFileList.push(data['obj']);
                  console.log(this.myFileList);
                  this.draganddropedId.emit(data['obj']._id);
                  this.showUploadButton=false;
                  this.showImagePreview=false;
                  
                }
              );
            this._service.success("Success", "Thanks for Sharing")
          }
        },
        error => {
          console.log(error)
          this._service.error(error)
        },
      );
  }

  previewImage(event){
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e:any) => {
        var image = new Image();
        image.src = reader.result as string;
      
        this.url = e.target.result;

        image.onload = function() {
        };

      };

      reader.readAsDataURL(event.target.files[0]);
      this.draganddroped=false;

    }
  }




  resize(img, MAX_WIDTH:number, MAX_HEIGHT:number, callback){
    // This will wait until the img is loaded before calling this function
    return img.onload = () => {

      // Get the images current width and height
      var width = img.width;
      var height = img.height;

      // Set the WxH to fit the Max values (but maintain proportions)
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // create a canvas object
      var canvas = document.createElement("canvas");

      // Set the canvas to the new calculated dimensions
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0,  width, height);

      // Get this encoded as a jpeg
      // IMPORTANT: 'jpeg' NOT 'jpg'
      var dataUrl = canvas.toDataURL('image/jpeg');

      // callback with the results
      callback(dataUrl, img.src.length, dataUrl.length);
    };
  }

  getAllRecords(){
    this.draganddropService.getAllRecords()
    .subscribe(
      data => {
        console.log(data);
      }
    );
  }


  ngOnInit() {
    //this.getAllRecords();
  }

}
