import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { SubtypesService } from '../../subtypes/subtypes.service';
import { CategoriesService } from '../../categories/categories.service';
import { TypesService } from '../../types/types.service';
import { FileUploadService } from '../fileupload/fileupload.service';
import { ThisReceiver } from '@angular/compiler';
import { TemplatesService } from '../../templates/templates.service';
import { TypeModifier } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-prebuilt',
  templateUrl: './prebuilt.component.html',
  styleUrls: ['./prebuilt.component.css']
})
export class PrebuiltComponent implements OnInit {

  galleryData=[];
  filterPath="defaults/hb/optin";
  category="hb";
  imageType="optin";
  type="master-template-v2";
  categoryId;
  typeId;
  subtypeId;
  @Output() imagePath = new EventEmitter<any>();
  @Output() public closeButtonClick:EventEmitter<any> = new EventEmitter();
  
  onCloseButtonClick($event){ this.closeButtonClick.emit('close');}

  constructor(
    private uploadService: FileUploadService,
    private toastrService: ToastService,
    private typeService: TypesService,
    private categoryService: CategoriesService,
    private subtypeService: SubtypesService,
    private templatesService: TemplatesService
  ) {
    this.getCategory(this.category,this,function(categoryId,thus){
      
      thus.categoryId=categoryId
      thus.getSubtype(thus.imageType, thus,function(subtypeId,thus){
        
        thus.subtypeId=subtypeId
        thus.getType(thus.type,thus, function(typeId, thus){
          
          thus.typeId=typeId;
          console.log(thus.typeId,thus.subtypeId,thus.categoryId)
          thus.processTemplates(thus.typeId,thus.subtypeId,thus.categoryId, function(){

          })
        })

      })
    })
   // this.refresh(this.filterPath);
   }

  ngOnAfterViewInit(): void {
    
  }

  ngOnInit(){}

  clickImage(event,el){
   
    this.imagePath.emit({data:el})
  }

  getCategory=(category,thus,cb)=>{
    this.categoryService.getRecordByIdentiifer({identifier:category}).subscribe(category => {
     
      cb(category,thus)
    })
  }

  getSubtype=(subtype,thus,cb)=>{
    this.subtypeService.getRecordBySubtype({subtype:subtype}).subscribe(subtype => {
    
      cb(subtype,thus)
    })
  }

  getType=(type,thus,cb)=>{
    this.typeService.getRecordByType({type:type}).subscribe(type => {
      cb(type,thus)
    
    })
  }

  processTemplates=(type,subtype,category)=>{
   
    let body = {
      typeid:type['obj']._id,
      subtypeid: subtype['obj']._id,
      categoryid: category['obj']._id
    }

    console.log(body)
    this.galleryData=[];
    this.templatesService.getRecordBySubtypeAndTypeAndCategory(body).subscribe(templates => {
     
      let templateInfo = templates['obj']
      for (let i=0; i < templateInfo.length ; i++){
      

        var galleryFeed={_id:templateInfo[i]._id,url:templateInfo[i].image, thumb:templateInfo[i].image,wid:templateInfo[i].wid,type:templateInfo[i]['subtype'].name,description:templateInfo[i].description,lastmodified:this.renderDate({value:templateInfo[i].updated_at}),size:""}
        this.galleryData.push(galleryFeed);
      }
    })
  }

  processFilter(){
    this.filterPath="defaults/"+this.category+"/"+this.imageType;
    console.log(this.typeId,this.subtypeId,this.categoryId);
    this.processTemplates(this.typeId,this.subtypeId,this.categoryId)
    //this.refresh(this.filterPath)
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
  
    return dateString;;
  }

  refresh(path){
    var body = {path:path};
  
    this.uploadService.getMyObjects(body)
      .subscribe(
        data => {
         
          this.galleryData=[];
          for (let i=0;i<data['imageInfo'].length; i++){
            var fileName = data['imageInfo'][i].url.split('/').pop();
            var funnelNumber = fileName.split('-')[0];
            var typeName = this.imageType.charAt(0).toUpperCase() + this.imageType.slice(1);
            var tmpName=fileName.split('-');
            var categoryName=tmpName[3]+" "+tmpName[4];
            var wid=tmpName[1]+"-"+tmpName[0]+"-v"+[2];
            var description=categoryName+" Funnel"+" "+funnelNumber
            
            var galleryFeed={url:data['imageInfo'][i].url, thumb:data['imageInfo'][i].url,wid:wid,type:this.imageType,description:description,lastmodified:this.renderDate({value:data['imageInfo'][i].lastModified}),size:data['imageInfo'][i].size}
            this.galleryData.push(galleryFeed);
          }
          
          const galleryData1=this.galleryData.sort((a,b)=>{
           // var c: any = new Date(a.lastmodified)
           // var d: any = new Date(b.lastmodified)
           var c: any = a.url;
           var d: any = b.url;
            return d-c
          });
         
        },
        error => this.toastrService.error(error['title'],error['message'],{opacity:1})
      );
  }


}
