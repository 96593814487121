<header style="margin-bottom:30px">
<!--Navbar-->

<mdb-navbar SideClass="navbar navbar-expand-lg blue darken-4 navbar-dark" [containerInside]="false">
  
    <!-- Navbar brand -->
    <mdb-navbar-brand>
        <a class="navbar-brand" href="#">
            <img [src]="this.logo" height="50" alt="">
        </a>
    </mdb-navbar-brand>
  
    <!-- Collapsible content -->
    <links>
  
      <!-- Links -->
      <ul class="navbar-nav mr-auto" style="display:none">
        <li class="nav-item active">
          <a [routerLink]="['/']" class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/signup']" class="nav-link waves-light" mdbWavesEffect>Signup</a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/signin']" class="nav-link waves-light" mdbWavesEffect>Signin</a>
        </li>
  
       
      </ul>
      <!-- Links -->
      
      <ul class="navbar-nav ml-auto nav-flex-icons" style="display:none">
        <li class="nav-item">
          <a [routerLink]="['/signin']" class="nav-link waves-light" mdbWavesEffect>Member Login</a>
        </li>
        
       
      </ul>

      <ul class="navbar-nav ml-auto nav-flex-icons" style="display:none">
        <li class="nav-item">
          <a class="nav-link">
            <mdb-icon fab icon="twitter"></mdb-icon>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <mdb-icon fab icon="google-plus"></mdb-icon>
          </a>
        </li>
        <li class="nav-item">
            <a class="nav-link">
              <mdb-icon fab icon="facebook"></mdb-icon>
            </a>
          </li>
       
      </ul>
    </links>
    <!-- Collapsible content -->
  
  </mdb-navbar>
  <!--/.Navbar-->
  </header>

    <main>
        <router-outlet></router-outlet>
    </main>
    <footer class="page-footer font-small blue darken-4 navbar-dark" style="margin-top:30px">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <img src="assets/images/LOGO-4.png" class="elIMG ximg imgOpacity1" width="200">
                </div>
                <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0" style="text-align: right; font-size: 20px; color: rgb(172, 172, 172);" ><a [routerLink]="['/income-disclaimer']" target="_blank" style="color: rgb(172, 172, 172);">DISCLAIMER</a> | <a [routerLink]="['/jv']" target="_blank" style="color: rgb(172, 172, 172);">AFFILIATES</a> | <a [routerLink]="['/privacy-policy']" target="_blank" style="color: rgb(172, 172, 172);">PRIVACY POLICY</a> | <a [routerLink]="['/contact']" target="_blank" style="color: rgb(172, 172, 172);display:none">CONTACT US</a><a [routerLink]="['/signin']" target="_blank" style="color: rgb(172, 172, 172);">LOG IN</a>
                    </div>
            </div>
            <div><br></div>
            <div><br></div>
            <div class="row">
                <div class="ne elHeadline lh3 elMargin0 elBGStyle0 hsTextShadow0 hsSize0" style="text-align: left; color: rgb(172, 172, 172);">
                
                For Product Support, please contact the vendor <a href="http://easysupportnow.com/" target="_blank">HERE.</a><br>
                For Order Support, please contact ClickBank <a href="https://www.clkbank.com/#!/" target="_blank">HERE.</a><br>
                <br>
                Earnings Disclaimer: Every effort has been made to accurately represent this product and it's potential. Even though this industry is one of the few where one can write their own check in terms of earnings, there is no guarantee that you will earn any money using the techniques and ideas in this product.
                <br><br>
                Examples in these materials are not to be taken as a promise or guarantee of earnings. Earning potential is entirely dependent on the person using our product, ideas, techniques and most importantly the effort put forth. We do not purport this as a "get rich scheme", and nor should you view it as such.
                <br><br>
                The content of this message has been created by the applicable Vendor and not by ClickBank. Accordingly, ClickBank is not responsible for any information contained in this message, including, but not limited to, any product information, promotions, incentives, expected returns or other information contained herein. In addition, ClickBank is not responsible for any links to third party websites contained in this message. Such links do not imply any endorsement by ClickBank of such websites or the content, products or services available from such websites. By clicking on or accessing a third party website listed in this message, you acknowledge sole responsibility for and assume all risk arising from your use of any such websites.
                <br><br>
                ClickBank is the retailer of products on this site. CLICKBANK is a registered trademark of Click Sales Inc., a Delaware corporation located at 1444 S. Entertainment Ave., Suite 410 Boise, ID 83709, USA and used by permission. ClickBank's role as retailer does not constitute an endorsement, approval or review of these products or any claim, statement or opinion used in promotion of these products.
                </div>
                
                <div><br></div>
                <div></div>
                <div class="" style="margin-top:20px;width:100%;text-align: center; color: rgb(172, 172, 172);" data-bold="inherit" contenteditable="false">Copyright <mdb-icon fas icon="copyright"></mdb-icon> {{current_year}} Easiest System Ever All Rights Reserved.
                </div>
            </div>
        </div>
    </footer>