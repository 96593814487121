
<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
    <img src="../../../assets/images/weblink.jpg" align="left" style="width:50px;height:50px;border-radius:50%"><h1 mat-dialog-title style="font-size:2em;position:relative;top:10px;">&nbsp;&nbsp;Links {{links?._id}}</h1>
  <mat-dialog-content>
    
    
    <br/><br/>
    <input type="hidden" name="linkId" #linkId="ngModel" [ngModel]="links?._id" id="linkId">
    <mat-form-field class="example-full-width">
      <input matInput name="title" #title="ngModel" [ngModel]="links?.title" type="text" id="title" placeholder="Enter the Name or Title" >
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="wid" #wid="ngModel" [ngModel]="links?.wid" type="text" id="wid" placeholder="Enter the Unique Indentifier for this link">
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="headline" #headline="ngModel" [ngModel]="links?.headline" type="text" id="headline" placeholder="Enter the Headline">
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="link" #link="ngModel" [ngModel]="links?.link" type="text" id="link" placeholder="Enter the Link Destination" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width" style="display:none">
      <input matInput name="linktext" #linktext="ngModel" [ngModel]="links?.linktext" type="text" id="linktext" placeholder="Enter the Link Text">
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <div>
      <mat-select name="category" id="category" placeholder="Choose a Category for a Template"  floatPlaceholder="auto" class="" (selectionChange)="getTemplates($event)">
        <mat-option  *ngFor="let category of categories" value="{{category._id}}">{{category.name}}</mat-option>
      </mat-select>
    </div>
    <br/><br/>
    <div>
      <mat-select name="page"  #page [ngModel]="links?.page"  id="page" placeholder="Choose a Template"  floatPlaceholder="auto" class="">
        <mat-option style="height:210px;" *ngFor="let template of templates" value="{{template._id}}"><img style="height:auto; width:200px" src="{{template.image}}">&nbsp;&nbsp;{{template.name}}</mat-option>
      </mat-select>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
