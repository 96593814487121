import {Injectable, EventEmitter} from '@angular/core';
import {Systempreference} from "./systempreference";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class SystempreferenceService {
  private hostName = window.location.protocol + '//' + window.location.host;
  news: Systempreference[] = [];
  newsIsEdit = new EventEmitter<Systempreference>();
  constructor(private http: HttpClient) { }

  editNews(systempreference: Systempreference) {
   
    this.newsIsEdit.emit(systempreference);
  }

  addRecord(systempreference: Systempreference) {
    const body = JSON.stringify(systempreference);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/systempreference/addrecord' + token, body, {headers: headers})
     
  }

  deleteRecord(systempreference: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/systempreference/deleterecord/' + systempreference._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/systempreference/getallrecords'+token,  {headers: headers})
     
  }

  updateKey(systempreference: any){
    const body = JSON.stringify(systempreference);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/systempreference/updatekey' + token, body, {headers: headers})
     

  }

  getKey(systempreference: any) {
    const body = JSON.stringify(systempreference);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/systempreference/getkey' + token, body, {headers: headers})
     
  }

  updateRecord(systempreference: Systempreference){
    const body = JSON.stringify(systempreference);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/systempreference/updaterecord/' + systempreference.systempreferenceId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/systempreference/getrecordl/'+id+token,  {headers: headers})
     
  }

}
