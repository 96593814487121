import {Injectable, EventEmitter} from '@angular/core';
import {Servers} from "./servers";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class ServersService {
  private hostName = window.location.protocol + '//' + window.location.host;
  servers: Servers[] = [];
  serversIsEdit = new EventEmitter<Servers>();
  constructor(private http: HttpClient) { }

  editServers(servers: Servers) {
    console.log(servers)
    this.serversIsEdit.emit(servers);
  }

  addRecord(servers: Servers) {
    const body = JSON.stringify(servers);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/servers/addrecord' + token, body, {headers: headers})
     
  }

  deleteRecord(servers: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/servers/deleterecord/' + servers._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/servers/getallrecords'+token,  {headers: headers})
    
  }

  updateRecord(servers: Servers){
    const body = JSON.stringify(servers);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/servers/updaterecord/' + servers.serversId + token, body, {headers: headers})
     
  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/servers/getrecordl/'+id+token,  {headers: headers})
     
  }

}
