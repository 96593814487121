<div class="menulabel">Manage</div>
                <button Type="button" mat-button class="btnlink" [matMenuTriggerFor]="appMenu">
                        <i class="material-icons icon">settings</i>&nbsp;Administration
                </button>
                <mat-menu #crmMenu="matMenu">
                  <button  mat-menu-item [routerLink]="['/manage/crm']" routerLinkActive="activated-button">
                      <mat-icon>dashboard</mat-icon>
                      <span>CRM</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/request']" routerLinkActive="activated-button">
                    <mat-icon>image</mat-icon>
                    <span>Submitted Request</span>
                  </button>
                  <button  mat-menu-item [routerLink]="['/manage/myapplications']" routerLinkActive="activated-button">
                    <mat-icon>view_list</mat-icon>
                    <span>Applications</span>
                  </button>
                 
                </mat-menu>
                <mat-menu #appMenu="matMenu" class="matmenu">
                    <button  mat-menu-item [routerLink]="['/manage/adminhelp/',admin_help_page, params]" routerLinkActive="activated-button">
                      <mat-icon>info</mat-icon>
                      <span>Help</span>
                    </button>
                    <button  mat-menu-item [matMenuTriggerFor]="crmMenu" >
                      <mat-icon>dashboard</mat-icon>
                      <span>CRM</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/shortcodes']" routerLinkActive="activated-button">
                      <mat-icon>short_text</mat-icon>
                      <span>Shortcodes</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/internalpage/63b8163eb4c8660015744cb7']" routerLinkActive="activated-button">
                      <mat-icon>email</mat-icon>
                      <span>Dynamic Payment Form</span>
                    </button>
                    <button  mat-menu-item [routerLink]="['/manage/email']" routerLinkActive="activated-button">
                        <mat-icon>email</mat-icon>
                        <span>Email Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/users']" routerLinkActive="activated-button">
                        <mat-icon>supervisor_account</mat-icon>
                        <span>User Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/domains']" routerLinkActive="activated-button">
                        <mat-icon>web</mat-icon>
                        <span>Domain Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/news']" routerLinkActive="activated-button">
                        <mat-icon>list</mat-icon>
                        <span>News Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/autoresponders']" routerLinkActive="activated-button">
                        <mat-icon>email</mat-icon>
                        <span>Autoresponder Management</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/userpreference']" routerLinkActive="activated-button">
                        <mat-icon>format_underlined</mat-icon>
                        <span>User Preferences</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/templatesbysubtype']" routerLinkActive="activated-button">
                        <mat-icon>pages</mat-icon>
                        <span>Templates</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/urlshortener']" routerLinkActive="activated-button">
                        <mat-icon>link</mat-icon>
                        <span>URL Redirects/Shortener</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/education']" routerLinkActive="activated-button">
                        <mat-icon>ondemand_video</mat-icon>
                        <span>Manage Videos</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/servers']" routerLinkActive="activated-button">
                        <mat-icon>computer</mat-icon>
                        <span>Servers</span>
                      </button>
                      <button  mat-menu-item [routerLink]="['/manage/leads']" routerLinkActive="activated-button">
                        <mat-icon>email</mat-icon>
                        <span>Leads</span>
                      </button>
                     
                      
                   
                </mat-menu>