
  <div class="{{class}}" mat-dialog-title style="display:flex;align-items:center;font-size:20pt;font-weight:bold;"><mat-icon style="font-size:32pt;display:flex;align-items:center;margin-right:22px">{{icon}}</mat-icon>{{title}}</div>
  <hr>
<div style="background-color:#ccc;width:100%;height:1px;margin-bottom:10px;border-radius:20px;"></div>
<mat-dialog-content>
  <span style="font-size:16pt;font-weight:bold;">{{body}}&nbsp;<a href="{{link}}" target="{{target}}">{{linkTxt}}</a></span>
</mat-dialog-content>
<mat-dialog-actions>
  
  <div style="background-color:#ccc;width:100%;height:1px;margin-bottom:10px;"></div>
  
  <button mdbBtn color="info" mdbWavesEffect (click)="pressed(false)" mat-dialog-close>{{cancelButtonTxt}}</button>
  &nbsp;&nbsp;
  <button mdbBtn color="info" mdbWavesEffect (click)="pressed(true)" mat-dialog-close>{{okButtonTxt}}</button>
</mat-dialog-actions>
