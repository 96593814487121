<button mat-icon-button mat-mini-fab color="warn" mat-dialog-close style="position:absolute;top:20px;right:20px;">
  <mat-icon  class="material-icons">cancel</mat-icon>
</button>

<h2 mat-dialog-title>User</h2>

    <form #f="ngForm" (ngSubmit)="onSubmit(f, f.value, f.valid)">
      <mat-dialog-content>
      <input name="userId"  #userId="ngModel" [(ngModel)]="user._id" type="text" id="userId" readonly>
      <mat-form-field class="example-full-width" >
        <input name="username" matInput #name="ngModel" [(ngModel)]="user.username" type="text" id="username" placeholder="Your Username" required>
        <mat-error>This is a required field</mat-error>
        <mat-hint>Username. This is Your affiliate Id</mat-hint>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="domain" matInput #domain="ngModel" [(ngModel)]="user.domain" type="text" id="domain" placeholder="Domain">
      </mat-form-field>


      <mat-form-field class="example-full-width">
        
        <mat-select name="level" #level [ngModel]="user?.level"  id="level" placeholder="Choose a Level"  floatPlaceholder="auto"  ngDefaultControl>
          <mat-option  *ngFor="let level of levels" value="{{level.level}}">{{level.name}}</mat-option>
        </mat-select>
    </mat-form-field>
      

<br/><br/>
      <label style="color:#ccc;font-weight:bold;font-size:12pt;" >Role</label>
      <select id="roles" #roles [(ngModel)]='user.roles' name="roles" placeholder="Role" class="example-full-width">
        <option value="--">Select Role</option>
        <option value="admin">Admin</option>
        <option value="member">Member</option>
        <option value="affiliate_only">Affiliate Only</option>
        <option value="contest_only">Contest Only</option>
      </select>
<br/><br/>
<label style="color:#ccc;font-weight:bold;font-size:12pt;" >Has this client been verified?</label>
      <select id="valid" #valid [(ngModel)]='user.valid' name="valid" placeholder="Valid" class="example-full-width">
        <option value="--">Verified?</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
<br/><br/>
<label style="color:#ccc;font-weight:bold;font-size:12pt;" >Is this client active?</label>
      <select id="active" #active [(ngModel)]='user.active' name="active" placeholder="Active" class="example-full-width">
        <option value="--">Active?</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
<br/><br/>
<label style="color:#ccc;font-weight:bold;font-size:12pt;" >Has this client logged in before?</label>
      <select id="loggedin" #loggedin [(ngModel)]='user.loggedin' name="loggedin" placeholder="Logged In" class="example-full-width">
        <option value="--">Verified?</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
      <br/><br/>
      <label style="color:#ccc;font-weight:bold;font-size:12pt;" >Has this client seen the 1 time traffic buy?</label>
      <select id="specialoffer" #specialoffer [(ngModel)]='user.specialoffer' name="lspecialoffer" placeholder="Special Offer" class="example-full-width">
        <option value="--">Special Offer?</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
<br/><br/>
      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input matInput placeholder="First name" type="text" name="firstname" #name="ngModel" [(ngModel)]="user.firstname" type="text" id="firstname" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input matInput placeholder="Last Name" name="lastname" #name="ngModel" [(ngModel)]="user.lastname"  type="text" id="lastname" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
        <input name="email" matInput #email="ngModel" [(ngModel)]="user.email" type="text" id="email" placeholder="Email" required email/>
        <mat-error>This is a required field</mat-error>
        <mat-error *ngIf="email.errors?.email">Please Enter an email in the correct format</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="address" matInput #name="ngModel" [(ngModel)]="user.address" type="text" id="address" placeholder="Address" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="address2" matInput #name="ngModel" [(ngModel)]="user.address2" type="text" id="address2" placeholder="Address 2">
      </mat-form-field>

      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input matInput placeholder="City" type="text" name="city" #name="ngModel" [(ngModel)]="user.city" type="text" id="city" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input matInput placeholder="State" name="state" #name="ngModel" [(ngModel)]="user.state"  type="text" id="state" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input matInput placeholder="Zip" name="zip" #name="ngModel" [(ngModel)]="user.zip"  type="text" id="zip" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
        <input name="skype" matInput #skype="ngModel" [(ngModel)]="user.skype" type="text" id="skype" placeholder="Skype">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="phone" matInput #phone="ngModel" [(ngModel)]="user.phone" type="text" id="phone" placeholder="Best Phone">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="paypal" matInput #paypal="ngModel" [(ngModel)]="user.paypal" type="text" id="paypal" placeholder="Paypal">
      </mat-form-field>
      </mat-dialog-content>
      

    <mat-dialog-actions>
      <div class="userActionButtonGroup">
        <button mat-button color="primary" type="submit" style="background-color:#ddd">
          <mat-icon  class="material-icons">done</mat-icon>
        </button>
        <button mat-button  color="warn" mat-dialog-close style="background-color:#ddd">
          <mat-icon  class="material-icons">cancel</mat-icon>
        </button>
      </div>
    </mat-dialog-actions>
    </form>

