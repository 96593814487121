
  <section class="col-md-10 col-md-offset-1">
      <p class="normal" align="center" class="ral-48blue">Frequently Asked Questions</p>
    <p class="normal"><br/> <br/></p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;How long will it take for you to set my system up?
      <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;Once you get us your auto-responder information, your system will usually be set up within 3-5 business days (depending on how many people are ahead of you).</p>
    <p class="normal"><br/> -----------------------------------------------------------------------------------------------------</p>
    <p class="normal"></p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;How long before I start seeing people on my list & commissions?<br/> <br/> <strong class="red" >ANSWER:</strong>&nbsp;
      That’s impossible for us to say with certainty, but one thing is for sure; if you’ve gotten us your auto-responder login information, then assuming it’s correct, you will start to see people on your list and your first commission notification within 30 days, or your money back.</p>
      
    
    <p class="normal"><br/> -----------------------------------------------------------------------------------------------------
    </p>
    <p class="normal"><strong class="red">QUESTION:</strong> Can I use a different auto-responder other than the one(s) you want me to use?<br/> <br/> 
      <strong class="red">ANSWER:</strong>&nbsp;No. Our staff would be working 20X more to try to figure out how to integrate the system with different auto-responders (especially ones we’re not familiar with). Also, when we do trainings, it is way more helpful if everyone is using the same auto-responder.</p>
    <p class="normal"><br/> -----------------------------------------------------------------------------------------------------</p>
    <p class="normal"></p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;You guys have set up my account and started my initial traffic that you promised me. What do I do now?<br/> <br/>
      <strong class="red">ANSWER:</strong>&nbsp;First. Order more traffic. Traffic is the life-blood of your internet business. Its’ what grows your list and makes you sales. So get as much as you can as often as you can.</p>
      <p class="normal">
      Second. Take 15 minutes a day to find additional income streams and offer them to your list. The system is pre-set to follow up with people on your list for an entire year, but anything you do to supplement this is just going to make you MORE money
      </p>
      <p class="normal">
          Third Get 1-on-1 coaching!! If you don’t know how to email your list (or find additional income streams to promote to your list for free in just 15 minutes a day), then you NEED to get 1-on-1 coaching. 
          </p><p class="normal">
          Fourth: Engage with us. Join our inner circle Facebook group and be part of the ongoing trainings. 
          
      </p>
    <p class="normal"><br/>---------------------------------------------------------------------------------------------------</p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;: If I leave the Easiest System Ever, then what happens?
      <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;If you leave, then we close your account, and you no longer have access to all of the sales-funnels and done-for-you services that ESE offers. However, since all of the leads/opt-ins are going onto YOUR Aweber, you get to keep your list. That’s why we have you get your own Aweber account; so that you have full control over your email list and so that you can keep your email list even if you cancel with us.
      </p>
    <p class="normal"><br/>---------------------------------------------------------------------------------------------------</p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;How will I know when my traffic has started arriving?
      <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;When your traffic starts being sent to your page, the first thing you’ll notice is that you’re getting opt-ins on your list. You can log into your Aweber and see that your list is growing. If this is happening then you know everything is working correctly. If you have questions, you can always contact customer support.</p>
        
      
    
    <p class="normal"><br/>---------------------------------------------------------------------------------------------------</p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;How will I know when I’ve made a sale?
      <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;When you make a sale, you’ll get an email notification. Also, you’ll see all sales in the “My Commissions” section here in your member’s area.</p>
    <p class="normal"><br/>---------------------------------------------------------------------------------------------------</p>
    <p class="normal"></p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;How do I get paid, and when do I get paid?<br/> <br/>
      <strong class="red">ANSWER:</strong>&nbsp;We pay commissions directly to your PayPal on the 1st and the 15th of every month (after a 14 day hold). For example: You make a sale on June 10th. The sale will “lock” on June 24th (14 days later), then you’ll get paid on July 1st. 
      </p>
      
    
    <p class="normal"><br/>--------------------------------------------------------------------------------------------------<br/></p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;When I order traffic from you, where do you send it?<br/> <br/>
      <strong class="red">ANSWER:</strong>&nbsp;All traffic goes to the opt-in page for one of your sales funnels. We NEVER send traffic directly to one of your sales pages because if we were to do that, you wouldn’t get people on your email list.</p>
     
    
    <p class="normal"><br/>--------------------------------------------------------------------------------------------------</p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;Can I send my own traffic to my opt-in pages?
       <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;Absolutely. However there is one thing you should know. Most people who are new go and look for “cheap” traffic. They order from people who offer ridiculous things like “1,000 visitors for $50”. Then, the fake traffic comes and they get no opt-ins or sales. So please, we beg you, do NOT order traffic from unapproved sources just because they sound good. If you order 1,000 visitors from someone other than us, and you end up getting 2 people on your email list and zero sales, then we PROMISE that it was because you ordered fake traffic.
       </p>
       <p class="normal">
       If you want to try to order traffic yourself, you can <a href="http://www.undergroundtrafficreport.com/" target="_blank">click here</a>
       </p>

       <p class="normal"><br/>--------------------------------------------------------------------------------------------------</p>
    <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;How often should I order traffic, and how much should I order?
       <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;You should order as much traffic as you can as often as you can (based on your budget), here’s why… When you order traffic, the 1st thing the website visitors see is your opt-in page. If they choose to opt-in, they go on your email list. Building your list is the single most important part of this whole equation. Imagine having a list of 5,000 or 10,000 people. Imagine being able to reach out to that many people with the push of a button and asking them to buy something having to do with making money online. That’s how all of the “gurus” make money…they have big lists. So order as much traffic as you can as often as you can.
       </p>

       <p class="normal"><br/>--------------------------------------------------------------------------------------------------</p>
       <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;What if I need to cancel my account?
          <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;We hate to see you go, but if you need to, just click on <a routerLink="/members/contact">CONTACT US</a> and submit a support ticket and we’ll take care of it for you. As long as your cancellation request came in BEFORE your monthly bill, then you’re good to go and you won’t be billed. If, however, something weird happens (like you email us on Sunday night at 11:59 pm and then you get billed on Monday morning before we get into the office) then just let us know what you were charged and we’ll refund it because you actually sent the request BEFORE you were charged
          </p>

          <p class="normal"><br/>--------------------------------------------------------------------------------------------------</p>
       <p class="normal"><strong class="red">QUESTION:</strong>&nbsp;What’s the #1 piece of advice for people just starting out!
          <br/> <br/> <strong class="red">ANSWER:</strong>&nbsp;I’ll give you four pieces of advice.</p>
          <ol style="font-size:14pt">
            <li> Engage with the system </li>
            <li>Focus on traffic/building your list </li>
            <li>Get 1-on-1 coaching to accelerate your success</li>
            <li>Practice patience and consistency!</li>
          </ol>
          
         
       
  </section>

