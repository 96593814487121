import { Component, OnInit } from '@angular/core';
import {CrmService} from "../../crm/crm.service";
import { ToastService } from 'ng-uikit-pro-standard';
import {Router} from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import {Crm} from "../../crm/crm";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  notes;
  crm:Crm = null;
  constructor(private crmService: CrmService, private _service: ToastService,private router:Router, private dialogRef: MatDialogRef<NotesComponent>) {
    crmService.crmIsEdit.subscribe(
      crm => {
        this.crm = crm;
        this.notes = this.crm;
        this.notes=this.notes.notes;
      })
  }

  ngOnInit() {
  }

}
