import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crmnav',
  templateUrl: './crmnav.component.html',
  styleUrls: ['./crmnav.component.css']
})
export class CrmnavComponent implements OnInit {
  showAppButton=false;
  constructor() { }

  ngOnInit() {
  }

}
