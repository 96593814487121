import {Injectable, EventEmitter} from '@angular/core';
//import {Http, Headers, RequestOptions, Response} from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class FileDraganddropService {
  private hostName = window.location.protocol + '//' + window.location.host;

  constructor(private http: HttpClient) { }

  getSignedRequest(fileName, fileType) {
    const headers = new HttpHeaders({'Content-Type': 'image/jpeg'});
    const token = localStorage.getItem('token') ? '&token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/draganddrop/sign-s3?fileName='+fileName+"&fileType="+fileType+token,  {headers: headers})
   
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/draganddrop/getallrecords'+token, {headers: headers})
   
  }

  draganddrop (signedRequest, file, fileType){
    const headers = new HttpHeaders({'Content-Type': fileType});
    //const options = new RequestOptions({ headers: headers });
    return this.http.put(signedRequest, file, {headers: headers})
   
  }

  verifyImage(data: any){
   
    var body = {"value":data.event.target.checked, "id":data.data._id};
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    //const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/draganddrop/verifyrecord', body, {headers: headers})
     
  }

  addRecord(imageInfo: any) {
    const body = JSON.stringify(imageInfo);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/draganddrop/addrecord' +token , body, {headers: headers})
   
  }

}
