<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="urlshortenerId" #urlshortenerId="ngModel" [ngModel]="urlshortener?._id" id="urlshortenerId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="urlshortener?.name" type="text" id="name" placeholder="Enter a Name for the Redirect" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput name="originalurl" #originalurl="ngModel" [ngModel]="urlshortener?.originalurl" type="text" id="originalurl" placeholder="Enter the Destination URL" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
          <input matInput name="urlcode" #urlcode="ngModel" [ngModel]="urlshortener?.urlcode" type="text" id="urlcode" placeholder="Enter the url code or WID">
          <mat-hint>Leave blank for system generated</mat-hint>
      </mat-form-field>

      <mat-form-field class="example-full-width">
          <input matInput name="shorturl" #shorturl="ngModel" [ngModel]="urlshortener?.shorturl" type="text" id="shorturl" placeholder="This will be your affiliate url." disabled>
      </mat-form-field>

      <mat-form-field class="example-full-width">
          <input matInput name="template" #template="ngModel" [ngModel]="urlshortener?.template" type="text" id="template" placeholder="What redirect template do you want to use? If you have no idea, contact support. (The default should be t)">
      </mat-form-field>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
