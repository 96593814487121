import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class ApplicationService {
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  addRecord(application: any) {
    const body = JSON.stringify(application);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/applications/addrecord' + token, body, {headers: headers})
    
  }

  verifyRecord(application: any) {
    const body = JSON.stringify(application);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/users/verify', body, {headers: headers})
    
  }

}
