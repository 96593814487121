import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../../auth/auth.service";
import {UserpreferenceService} from "../userpreference.service";
import {Userpreference} from "../userpreference";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-userpreference-input',
  templateUrl: './userpreference-input.component.html',
  styleUrls: ['./userpreference-input.component.css']
})
export class UserpreferenceInputComponent implements OnInit {

  userpreference:Userpreference = null;
  addRecord=false;
  constructor(private userpreferenceService:UserpreferenceService, private authService: AuthService, private _service: ToastService) {
    userpreferenceService.newsIsEdit.subscribe(
      userpreference => {
        this.userpreference = userpreference;
        if (this.userpreference._id=="none"){
          this.addRecord=true;
          this.userpreference._id=null;
        } else {
          try {
            this.userpreference.userId=this.userpreference['user']._id;
          } catch {
            console.log("Error: no user id")
          }
        }
        console.log(this.userpreference);
      })

  }

  save(f:NgForm, model: Userpreference, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.userpreferenceId){

      this.userpreferenceService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.userpreferenceService.addRecordForSomeoneElse(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
