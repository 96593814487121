import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../../auth/auth.service";
import {WebinarService} from "../../webinar.service";
import {Webinar} from "../webinar";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-webinar-input',
  templateUrl: './webinar-input.component.html',
  styleUrls: ['./webinar-input.component.css']
})
export class WebinarInputComponent implements OnInit {
  webinar:Webinar = null;

  constructor(private webinarService:WebinarService, private authService: AuthService, private _service: ToastService) {
    webinarService.webinarIsEdit.subscribe(
      webinar => {
        this.webinar = webinar;
        console.log(this.webinar);
      })

  }

  save(f:NgForm, model: Webinar, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.webinarId){

      this.webinarService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.webinarService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
