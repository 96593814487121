
<div class="container main-container">
  <section class="col-mat-8 col-mat-offset-2">
    <form #f="ngForm" (ngSubmit)="save(f.value, f.valid)">
      <mat-form-field class="example-full-width">
        <input name="offername" mdInput #offername="ngModel" [(ngModel)]="zapier.offername" type="text" id="offername" placeholder="Offer Name"/>
      </mat-form-field>
      <div>
        <mat-select name="test" #test="ngModel" [ngModel]="zapier.test"  id="test" placeholder="Tests"  floatPlaceholder="auto" class="example-full-width" required>
          <mat-option value="aweber">Aweber</mat-option>
          <mat-option value="getresponse">Get Response</mat-option>
        </mat-select>
        <mat-error>This is a required field</mat-error>
      </div>
      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input mdInput placeholder="First name" type="text" name="firstname" #firatname="ngModel" [(ngModel)]="zapier.firstname" type="text" id="firstname">
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input mdInput placeholder="Last Name" name="lastname" #lastname="ngModel" [(ngModel)]="zapier.lastname"  type="text" id="lastname">
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>



      <mat-form-field class="example-full-width">
        <input name="email" mdInput #email="ngModel" [(ngModel)]="zapier.email" type="text" id="email" placeholder="Email" required email/>
        <mat-error>This is a required field</mat-error>
        <mat-error *ngIf="email.errors?.email">Please Enter an email in the correct format</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="streetaddress" mdInput #streetaddress="ngModel" [(ngModel)]="zapier.streetaddress" type="text" id="streetaddress" placeholder="Address">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>


      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input mdInput placeholder="City" type="text" name="city" #name="ngModel" [(ngModel)]="zapier.city" type="text" id="city">
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input mdInput placeholder="State" name="state" #name="ngModel" [(ngModel)]="zapier.state"  type="text" id="state">
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input mdInput placeholder="Postal Code" name="postalcode" #postalcode="ngModel" [(ngModel)]="zapier.postalcode"  type="text" id="postalcode">
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
        <input name="mobilephone" mdInput #mobilephone="ngModel" [(ngModel)]="zapier.mobilephone" type="text" id="mobilephone" placeholder="mobilephone">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="homephone" mdInput #homephone="ngModel" [(ngModel)]="zapier.homephone" type="text" id="homephone" placeholder="Home Phone">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="businessphone" mdInput #businessphone="ngModel" [(ngModel)]="zapier.businessphone" type="text" id="businessphone" placeholder="Business Phone">
      </mat-form-field>



      <button type="submit" class="btn btn-primary" [disabled]="!f.valid">Test It!</button>
    </form>
  </section>
</div>
