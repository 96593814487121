import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managemenu',
  templateUrl: './managemenu.component.html',
  styleUrls: ['./managemenu.component.css']
})
export class ManagemenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
