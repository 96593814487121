import { Component, OnInit } from '@angular/core';
//import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {FunnelsService} from "../funnels.service";
import {Funnels} from "../funnels";
import {NgForm} from "@angular/forms";
import { Templates } from '../../templates/templates';
import {CategoriesService} from "../../categories/categories.service";
import {Categories} from "../../categories/categories";
import { TypesService } from '../../types/types.service';
import { Types } from '../../types/types';
import { Subtypes } from '../../subtypes/subtypes';
import { SubtypesService } from '../../subtypes/subtypes.service';
import { FunnelsComponent } from '../funnels.component';
import { NotificationsService } from '../../shared/notifications/notifications.service';


@Component({
  selector: 'app-funnels-input',
  templateUrl: './funnels-input.component.html',
  styleUrls: ['./funnels-input.component.css']
})
export class FunnelsInputComponent implements OnInit {
  funnels:Funnels = null;
  optins: Templates = null;
  fes: Templates = null;
  checkouts: Templates = null;
  checkout2s: Templates = null;
  oto1s: Templates = null;
  oto2s: Templates = null;
  downloads: Templates = null;
  download2s: Templates = null;
  oto1_downloads: Templates = null;
  oto2_downloads: Templates = null;
  thank_yous: Templates = null;
  marketings: Templates = null;
  categories: Categories = null;
  types: Types = null;
  subtypes: Subtypes = null;
  preBuilt;

  constructor(private notificationService: NotificationsService, private subtypesService: SubtypesService, private typesService: TypesService,private categoriesService: CategoriesService,private funnelsService:FunnelsService, private authService: AuthService) {
    funnelsService.funnelsIsEdit.subscribe(
      funnels => {
       // funnels.tracking = atob(funnels.tracking);
       try {
          this.preBuilt = atob(funnels.tracking);
        } catch(e) {
          
        }
        this.funnels = funnels;
        
      }) 

  }


  ngAfterViewInit() {

  
  }

  save(f:NgForm, model: Funnels, isValid: boolean) {
    
    console.log(model);
    if (model.funnelsId){
      model.tracking = btoa(this.preBuilt);
      this.funnelsService.updateRecord(model)
        .subscribe(
          data => {
           this.notificationService.updateMessage(data['title'],data['message'],'success','check_circle',  5000,function(){});
          },
            error => this.notificationService.updateMessage(error['title'],error['message'],'warn','warning',  5000,function(){})

        )
    } else {

      this.funnelsService.addRecord(model)
        .subscribe(
          data => {
            this.notificationService.updateMessage(data['title'],data['message'],'success','check_circle',  5000,function(){});
            f.reset();
          },
            error => this.notificationService.updateMessage(error['title'],error['message'],'warn','warning',  5000,function(){})

        )
    }
  }

  ngOnInit() {
    
    this.categoriesService.getAllRecords()
      .subscribe(
        data => {
          this.categories = data['obj'];
        },
        error => {
        //  this.categories={_id:"1",name:"",categoriesId:"1",img:""};
       //   this._service.error(error.error['message'], error['title'])
        },
      );

      this.typesService.getAllRecords()
      .subscribe(
        data1 => {
          this.types = data1['obj'];
          //console.log(this.types);
          
        },
        error => {
          //this.types={_id:"1",name:"",type:"",typesId:'1'};
       //   this._service.error(error.error['message'], error['title'])
        },
      );

      this.subtypesService.getAllRecords()
      .subscribe(
        data2 => {
          this.subtypes = data2['obj'];
        },
        error => {
         // this.subtypes={_id:"1",name:"",subtype:"",subtypesId:'1'};
        //  this._service.error(error.error['message'], error['title'])
        },
      );

    this.funnelsService.getAllRecordsSubtypes('optin')
      .subscribe(
        data => {
          this.optins = data['obj'];
        },
 //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('fe')
      .subscribe(
        data => {
          this.fes = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('checkout')
      .subscribe(
        data => {
          this.checkouts = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('checkout2')
      .subscribe(
        data => {
          this.checkout2s = data['obj'];
        },
       // error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('oto1')
      .subscribe(
        data => {
          this.oto1s = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('oto2')
      .subscribe(
        data => {
          this.oto2s = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('download')
      .subscribe(
        data => {
          this.downloads = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('download2')
      .subscribe(
        data => {
          this.download2s = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('oto1_download')
      .subscribe(
        data => {
          this.oto1_downloads = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('oto2_download')
      .subscribe(
        data => {
          this.oto2_downloads = data['obj'];
        },
      //  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('thank_you')
      .subscribe(
        data => {
          this.thank_yous = data['obj'];
        },
      ///  error => this._service.error(error.error['message'], error['title']),
      );

      this.funnelsService.getAllRecordsSubtypes('funnel_descriptions')
      .subscribe(
        data => {
          this.marketings = data['obj'];
        },
     //   error => this._service.error(error.error['message'], error['title']),
      ); 
      
  } 

}
