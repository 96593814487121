import { Routes, RouterModule } from "@angular/router";
import {AppComponent} from "./app.component";
import {SignupComponent} from "./auth/signup/signup.component";
import {SigninComponent} from "./auth/signin/signin.component";
import {LoggedInGuard} from "./auth/logged-in.guard";
import {LogoutComponent} from "./auth/logout/logout.component";
import {AccountComponent} from "./auth/account/account.component";
import {PasswordresetComponent} from "./auth/passwordreset/passwordreset.component";
import {PasswordresetbyemailComponent} from "./auth/passwordresetbyemail/passwordresetbyemail.component";
import { ModuleWithProviders} from '@angular/core'
import {HomeComponent} from "./home/home.component";
import {ContactComponent} from "./contact/contact.component";
import { SecureComponent, SECURE_ROUTES } from './layout/secure';
import { PublicComponent, PUBLIC_ROUTES } from './layout/public';
import { BlankComponent } from './layout/blank';
import {JvinviteComponent} from "./pages/jvinvite/jvinvite.component";
import {LeadsComponent} from "./leads/leads.component";
import {ManageComponent} from "./layout/manage/manage.component";
import {CrmComponent} from "./crm/crm.component";
import {MarketingComponent} from "./marketing/marketing.component";
import { HomepageComponent } from './homepage/homepage.component';
import { EmailsectionComponent } from './emailsection/emailsection.component';
import {ContactIframeComponent} from "./contact/contact-iframe/contact-iframe.component";
import {UsersComponent} from "./auth/users/users.component";
import {FaqComponent} from "./faq/faq.component";
import {NewsComponent} from "./shared/news/news.component";
import {UserpreferenceComponent} from "./shared/userpreference/userpreference.component";
import {ZapierTestComponent} from "./leads/zapier-test/zapier-test.component";
import { WebinarFrontComponent } from "./webinar/webinar-front/webinar-front.component";
import { SuccessComponent } from "./webinar/success/success.component";
import { ApplicationComponent } from "./webinar/application/application.component";
import { SystempreferenceComponent } from "./shared/systempreference/systempreference.component";
import { ThankyouComponent } from "./webinar/application/thankyou.component";
import { CongratulationsComponent } from "./auth/signup/congratulations.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";
import { TemplatesComponent } from "./templates/templates.component";
import { CategoriesComponent } from "./categories/categories.component";
import { TypesComponent } from "./types/types.component";
import { MastertemplatesComponent } from "./mastertemplates/mastertemplates.component";
import { ImagesComponent } from "./images/images.component";
import { LinksComponent } from "./links/links.component";
import { DraganddropComponent } from "./upload/draganddrop/draganddrop.component";
import { SubtypesComponent } from "./subtypes/subtypes.component";
import { FunnelsComponent } from "./funnels/funnels.component";
import { AutoresponderInputComponent } from "./autoresponder/autoresponder-input/autoresponder-input.component";
import { AutoresponderFrontComponent } from "./autoresponder/autoresponder-front/autoresponder-front.component";
import { AutoresponderComponent } from "./autoresponder/autoresponder.component";
import { StepsComponent } from "./steps/steps.component";
import { GettingstartedComponent } from "./gettingstarted/gettingstarted.component";
import { FunnelsFrontComponent } from "./funnels/funnels-front/funnels-front.component";
import { DoneforyoutrafficComponent } from "./doneforyoutraffic/doneforyoutraffic.component";
import { GuaranteedoptinsComponent } from "./guaranteedoptins/guaranteedoptins.component";
import { OthertrafficComponent } from "./othertraffic/othertraffic.component";
import { ImlComponent } from "./iml/iml.component";
import { PdlComponent } from "./pdl/pdl.component";
import { CommissionsComponent } from "./commissions/commissions.component";
import { CoachingComponent } from "./coaching/coaching.component";
import { OneoneoncoachingComponent } from "./oneoneoncoaching/oneoneoncoaching.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { TestComponent } from "./test/test.component";
import { RequestComponent } from "./request/request.component";
import { WebinarComponent } from "./webinar/webinar/webinar.component";
import { CheckemailComponent } from "./auth/signup/checkemail.component";
import { CheckemailerrorComponent } from "./auth/signup/checkemailerror.component";
import { ProcesscheckoutComponent } from "./checkout/processcheckout.component";
import { AdminhelpComponent } from "./adminhelp/adminhelp.component";

import { AffiliatesComponent } from "./affiliates/affiliates.component";
import { EseaffiliatesComponent } from "./eseaffiliates/eseaffiliates.component";
//import { AdminGuard } from "./auth/admin.guard";
import { CheckRoleGuard } from "./auth/check-role.guard";
import { AffiliatehomeComponent } from "./affiliatehome/affiliatehome.component";
import { Affiliatehome1Component } from "./affiliatehome1/affiliatehome1.component";
import { UrlshortenerComponent } from "./urlshortener/urlshortener.component";
import { Affiliatehome3Component } from "./affiliatehome1/affiliatehome3.component";
import { InternalpageComponent } from "./internalpage/internalpage.component";
import { MenusComponent } from "./menus/menus.component";
import { MyapplicationsComponent } from "./myapplications/myapplications.component";
import { ShortcodesComponent } from "./shortcodes/shortcodes.component";
import { LinksFrontComponent } from "./links/links-front.component";
import { MyautoresponderComponent } from "./autoresponder/myautoresponder/myautoresponder.component";
import { ProductsComponent } from "./products/products.component";
import { IpageComponent } from "./ipage/ipage.component";
import { LevelsComponent } from "./levels/levels.component";
import { TemplatesInlineEditorComponent } from "./templates/templates-inline-editor/templates-inline-editor.component";
import { TemplatessubtypeComponent } from "./templates/templatessubtype/templatessubtype.component";
import { TemplatesMyComponent } from "./templates/templates-my/templates-my.component";
import { DomainsComponent } from "./domains/domains.component";
import { MyfunnelsComponent } from './funnels/myfunnels/myfunnels.component';
import { ChatComponent } from './chat/chat.component';
import { SupportComponent } from './support/support.component';
import { StatusComponent } from './status/status.component';
import { MyDomainsComponent } from './domains/mydomains.component';
import { MySupportComponent } from './support/mysupport.component';
import { ChatQueueComponent } from './chat/chatqueue.component';
import { EducationComponent } from './education/education.component';
import { ServersComponent } from './servers/servers.component';
import { IncomeDisclaimerComponent } from "./layout/public/disclaimer.component";
import { JvComponent } from "./layout/public/jv.component";
import { PrivacyPolicyComponent } from "./layout/public/privacy.component";
import { TermsComponent } from "./layout/public/terms.component";
import { GrapesjsComponent } from "./webbuilder/grapesjs/grapesjs.component";
import { MyfunnelsInputComponent } from "./funnels/myfunnels-input/myfunnels-input.component";
import { GrapesjsTestComponent } from "./webbuilder/grapesjs/grepestest.component";
import { MenumakerComponent } from "./menumaker/menumaker.component";
import { SetupComponent } from "./setup/setup.component";
import { SetuplayoutComponent } from "./setup/setuplayout/setuplayout.component";


const APP_ROUTES: Routes = [
  { path: '', component: PublicComponent,
    children: [
      {path: '', component: HomepageComponent},
      {path: 'start', component: HomepageComponent},
      {path: 'letmein', component: ApplicationComponent},
      {path: 'presentation', component: PresentationComponent},
      {path: 'affiliates', component: AffiliatesComponent},
      {path: 'eseaffiliates', component: EseaffiliatesComponent},
      {path: 'webinar/:webinarid/:eseid', component: WebinarFrontComponent},
      {path: 'webinar/success', component: SuccessComponent},
      {path: 'webinar/thankyou', component: ThankyouComponent},
      {path: 'webinar/application', component: ApplicationComponent},
      
    ]
  },
  {
    path: '', component: SetuplayoutComponent,
    children: [
      {path: 'setup', component: SetupComponent},
    ]
  },
  {
    path: '', component: PublicComponent,
    children: [
      {path: 'terms', component: TermsComponent},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
      {path: 'jv', component: JvComponent},
      {path: 'income-disclaimer', component: IncomeDisclaimerComponent},
      {path: 'signupesev3', component: SignupComponent},
      {path: 'signinesev3', component: SigninComponent},
      {path: 'checkout', component: CheckoutComponent },
      {path: 'congratulations', component: CongratulationsComponent},
      {path: 'verify', component: CheckemailComponent},
      {path: 'checkemailerror', component: CheckemailerrorComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'password-reset-by-email', component: PasswordresetbyemailComponent}
    ]
  },
  {path: 'members', component: SecureComponent,
  children: [
    {path: 'home', component: HomeComponent, canActivate: [LoggedInGuard]},
    {path: 'chat/:room', component: ChatComponent, canActivate: [LoggedInGuard]},
    {path: 'logout', component: LogoutComponent, canActivate: [LoggedInGuard]},
    {path: 'account', component: AccountComponent, canActivate: [LoggedInGuard]},
    {path: 'marketing', component: MarketingComponent, canActivate: [LoggedInGuard]},
    {path: 'password-reset', component: PasswordresetComponent, canActivate: [LoggedInGuard]},
    {path: 'faq', component: FaqComponent, canActivate: [LoggedInGuard]},
    {path: 'autoresponder-input', component: AutoresponderInputComponent, canActivate: [LoggedInGuard]},
    {path: 'autoresponder', component: AutoresponderComponent, canActivate: [LoggedInGuard]},
    {path: 'myautoresponder', component: MyautoresponderComponent, canActivate: [LoggedInGuard]},
    {path: 'stepbystep', component: StepsComponent, canActivate: [LoggedInGuard]},
    {path: 'gettingstarted', component: GettingstartedComponent, canActivate: [LoggedInGuard]},
    {path: 'myfunnels1/:templates', component: FunnelsFrontComponent, canActivate: [LoggedInGuard]},
    {path: 'sovren/:templateid/:funnelid', component:GrapesjsComponent, canActivate: [LoggedInGuard]},
    {path: 'sovrentest', component:GrapesjsTestComponent, canActivate: [LoggedInGuard]},
    {path: 'myfunnels', component: MyfunnelsComponent, canActivate: [LoggedInGuard]},
    {path: 'myfunnels-input/:funnelid', component: MyfunnelsInputComponent, canActivate: [LoggedInGuard]},
    {path: 'doneforyoutraffic', component: DoneforyoutrafficComponent, canActivate: [LoggedInGuard]},
    {path: 'guaranteedoptins', component: GuaranteedoptinsComponent, canActivate: [LoggedInGuard]},
    {path: 'othertraffic', component: OthertrafficComponent, canActivate: [LoggedInGuard]},
    {path: 'internetmarketinglibrary', component: ImlComponent, canActivate: [LoggedInGuard]},
    {path: 'personaldevelopmentlibrary', component: PdlComponent, canActivate: [LoggedInGuard]},
    {path: 'commissions', component: CommissionsComponent, canActivate: [LoggedInGuard]},
    {path: 'coaching', component: CoachingComponent, canActivate: [LoggedInGuard]},
    {path: 'test', component: TestComponent, canActivate: [LoggedInGuard]},
    {path: 'oneononecoaching', component: OneoneoncoachingComponent, canActivate: [LoggedInGuard]},
    {path: 'contact', component: ContactIframeComponent, canActivate: [LoggedInGuard]},
    {path: 'processcheckout', component: ProcesscheckoutComponent, canActivate: [LoggedInGuard]},
    {path: 'affiliatehome', component: AffiliatehomeComponent, canActivate: [LoggedInGuard]},
    {path: 'affiliatehome1', component: Affiliatehome1Component, canActivate: [LoggedInGuard]},
    {path: 'affiliatehome3', component: Affiliatehome3Component, canActivate: [LoggedInGuard]},
    {path: 'internalpage/:page', component: InternalpageComponent, canActivate: [LoggedInGuard]},
    {path: 'internalpage/:page/:myparams', component: InternalpageComponent, canActivate: [LoggedInGuard]},
    {path: 'ipage/:page/:myparams', component: IpageComponent, canActivate: [LoggedInGuard]},
    {path: 'ipage/:page', component: IpageComponent, canActivate: [LoggedInGuard]},
    {path: 'templateinline/:page/:myparams', component: TemplatesInlineEditorComponent, canActivate: [LoggedInGuard]},
    {path: 'templateinline/:page', component: TemplatesInlineEditorComponent, canActivate: [LoggedInGuard]},
    {path: 'links', component: LinksFrontComponent, canActivate: [LoggedInGuard]},
    {path: 'mytemplates', component: TemplatesMyComponent, canActivate: [LoggedInGuard]},
    {path: 'mydomains', component: MyDomainsComponent, canActivate: [LoggedInGuard]},
    {path: 'support', component: MySupportComponent, canActivate: [LoggedInGuard]},
    { path: '**', redirectTo: 'home' }

  ]},
  { path: 'new',
    children: [
      {path: 'chat/:room', component: ChatComponent, canActivate: [LoggedInGuard]},
    ]
  },
  { path: 'manage', component: ManageComponent,
    children: [
      {path: 'leads', component: LeadsComponent, canActivate: [LoggedInGuard]},
      {path: 'crm', component: CrmComponent,canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'users', component: UsersComponent,canActivate: [LoggedInGuard, CheckRoleGuard],data:{roles:['admin']}},
      {path: 'email', component: EmailsectionComponent,canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'news', component: NewsComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'zapier-test', component: ZapierTestComponent,canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'userpreference', component: UserpreferenceComponent,canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'systempreference', component: SystempreferenceComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'templates', component: TemplatesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'templatesbysubtype', component: TemplatessubtypeComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'categories', component: CategoriesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'types', component: TypesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'levels', component: LevelsComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'servers', component: ServersComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'subtypes', component: SubtypesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'request', component: RequestComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'funnels', component: FunnelsComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'mastertemplates', component: MastertemplatesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'images', component: ImagesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'links', component: LinksComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'draganddrop', component: DraganddropComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'webinar', component: WebinarComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'autoresponders', component: AutoresponderComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'adminhelp/:page/:myparams', component: AdminhelpComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
      {path: 'urlshortener', component: UrlshortenerComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'menus', component: MenusComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'myapplications', component: MyapplicationsComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'products', component: ProductsComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'shortcodes', component: ShortcodesComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['admin']}},
      {path: 'support', component: SupportComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'status', component: StatusComponent, canActivate: [LoggedInGuard,CheckRoleGuard],data:{roles:['super']}},
      {path: 'domains', component: DomainsComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
      {path: 'chatqueue', component: ChatQueueComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
      {path: 'education', component: EducationComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
      {path: 'internalpage/:page', component: InternalpageComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
      {path: 'internalpage/:page/:myparams', component: InternalpageComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
      {path: 'menumaker/:id', component: MenumakerComponent, canActivate: [LoggedInGuard],data:{roles:['admin']}},
    ]
  },
];

export const appRoutingProviders: any[] = [

];

export const routing = RouterModule.forRoot(APP_ROUTES);
