import { Component, OnInit } from '@angular/core';
import {GridOptions} from "ag-grid-community";
import { MatDialog } from '@angular/material/dialog';
import {  AuthService } from '../../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {ConfirmService} from "../../shared/confirm/confirm.service";
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import {UsersInputComponent} from "./users-input/users-input.component";
import { CrmInputComponent } from '../../crm/crm-input/crm-input.component';
import { CrmService } from '../../crm/crm.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public gridOptions: GridOptions;
  columnDefs;
  data;
  confirmPressed = false;
  public paginationSize = 50;
  public theme="ag-theme-dave";
  constructor(private crmService:CrmService, private confirmService: ConfirmService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "ID",field: "_id", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: 'Created', field: "created_at", cellRenderer: this.renderDate,editable: true, sortable: true, filter:true, resizable:true},
      {headerName: 'Updated', field: "updated_at", cellRenderer: this.renderDate,editable: true, sortable: true, filter:true, resizable:true},
      {headerName: 'Last Logged In', field: "last_loggedin", cellRenderer: this.renderDate,editable: true, sortable: true, filter:true, resizable:true},
      {headerName: 'Domain', field: "domain",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "First Name",field: "firstname",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Last Name",field: "lastname",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "User name",field: "username",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "ESE User Name",field: "sponsor",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "ESE User ID",field: "user",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Email",field: "email",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Phone",field: "phone",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Zip",field: "zip",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Active",field: "active",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Role",field: "roles",editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Level", field: "level" ,editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Actions",editable: true, sortable: true, filter:true, resizable:true,  cellRenderer: this.renderActions},

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: false,
      suppressNoRowsOverlay: true,
    };

    this.authService.getAllUsers()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),

      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  renderActions(params){
    var html = `
            <button mat-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
            <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
           </button>
           <br>
           <button mat-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
           <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Delete
           </button>
           <br>
           <button mat-button color="primary" type="button" data-action-type="crm" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
           <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;View CRM Record
           </button>
    `
    return html;
  }

  renderDate(params){
    if (!params.value){
      return '';
    }
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
        case "crm":
          return this.onActionViewCrmClick(data)
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(UsersInputComponent, {
      height: '600px',
      width: '60%',

    });

    console.log(data);
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.authService.editUser(data._id);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionViewCrmClick(data: any){
    console.log("View action clicked", event);
    let dialogRef = this.dialog.open(CrmInputComponent, {
      height: '600px',
      width: '60%',

    });
    data.action="fromUser";
    //dialogRef.componentInstance.crm = this.crmService.editCrm(data);
    this.crmService.editCrm(data);

  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.authService.deleteUser(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  addRecord(){
    let dialogRef = this.dialog.open(UsersInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  refresh(){
    this.authService.getAllUsers()
      .subscribe(
        data => {
          this.data = data['obj'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  ngOnInit() {
  }

}
