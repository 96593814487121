import { Component, OnInit, ViewChild } from '@angular/core';
import {  AuthService } from '../../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Funnels } from "../funnels";
import {FunnelsService} from "../funnels.service";
import {GridOptions} from "ag-grid-community";
import {FunnelsInputComponent} from "../funnels-input/funnels-input.component";
import { MatDialog} from  '@angular/material/dialog';
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import {ConfirmService} from "../../shared/confirm/confirm.service";
import { CommService } from '../../../../services/comm.service';
import { MyfunnelsInputComponent } from '../myfunnels-input/myfunnels-input.component';

@Component({
  selector: 'app-myfunnels',
  templateUrl: './myfunnels.component.html',
  styleUrls: ['./myfunnels.component.css']
})
export class MyfunnelsComponent implements OnInit {
  data;
  columnDefs;
  confirmPressed = false;
  gridOptions: GridOptions;
  paginationSize=50;
  theme="ag-theme-dave";
  //drawer;
  @ViewChild('drawer',{static:true}) public drawer;
  //guarantee=false;
  constructor(public commService: CommService, private confirmService: ConfirmService, private funnelsService:FunnelsService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Optin", field: "image1", cellRenderer: this.renderImage, cellStyle: {"text-align":"center"}},
      {headerName: "Bridge", field: "image", cellRenderer: this.renderImage, cellStyle: {"text-align":"center"}},
      {headerName: "id", field: "_id", editable: true},
      {headerName: "username", field: "username"},
      {headerName: "Name", field: "name"},
      {headerName: "Wid", field: "wid"},
      {headerName: "Type", field: 'type'},
      {headerName: "Created At", field: 'created_at', cellRenderer: this.renderDate},
      {headerName: "Updated At", field: 'updated_at', cellRenderer: this.renderDate, sort:'desc'},
      {
        headerName: "Actions", template: `<button md-button color="primary" subtype="button" data-action-subtype="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
             
             <button md-button color="primary" subtype="button" data-action-subtype="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>

            
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: false,
      suppressNoRowsOverlay: true,
      getRowHeight: function(params) {
        // assuming 50 characters per line, working how how many lines we need
        return 100;
      }
    };

    this.funnelsService.getMyRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  showSideNav(): void {
   this.drawer.toggle();
  }

  renderImage(params){
    if (params.value){
      return '<img style="height:100px" src="'+params.value+'">';
    } else {
      return '<img style="height:100px" src="/assets/images/No_Image_Available.png">'
    }
  }

  renderDate(params){
   
   var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }


  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionSubtype = event.event.target.getAttribute("data-action-subtype");

      switch(actionSubtype) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(MyfunnelsInputComponent, {
      width: '98%',
      height: '95vh'

    }); 
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    //this.drawer.toggle();
    this.funnelsService.editFunnels(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    }); 


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.funnelsService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.funnelsService.getMyRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(MyfunnelsInputComponent, {
      width: '98%',
      height: '95vh'

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }


  ngOnInit() {
  }

}
