<header>
  
  
  <!-- Sidebar navigation -->
  <mdb-side-nav #sidenav class="fixed blue darken-4" [fixed]="true">
    <!-- Logo -->
    <li>
        <div style="width:240px;text-align:center;margin-top:20px;margin-bottom:20px;">
            <img [src]="this.logo" style="width:85%">
        </div>
    </li>
    <!--/. Logo -->
   
    <!-- Side navigation links -->
    
    <!--/. Side navigation links -->
    <app-mainmenu1></app-mainmenu1>
    <app-adminmenu *ngIf="isAdmin"></app-adminmenu>
    <app-managemenu *ngIf="isSuper"></app-managemenu>
    <div class="sidenav-bg mask-strong"></div>
  </mdb-side-nav>
  <!--/. Sidebar navigation -->
  
  <!-- Navbar -->
         
             
            <div [ngClass]="{'hamburger':navShown==true, 'hamburger_toggled':navShown==false}">
              &nbsp;<a (click)="toggleSideNav($event)"  dbWavesEffect class="button-collapse"><i class="fa fa-bars"></i></a>
          </div>

         
<!-- /.Navbar -->
   
  </header>
  <!--Main layout-->
      <div class="black-skin divmeMain">
          
          <div class="container-fluid" >
            
            <router-outlet></router-outlet>
          
          </div>
          
        </div>
      <!--/Main layout-->
      <!--Footer-->
      <footer class="page-footer text-center text-md-left pt-4" style="margin-top:50px;">
  
          
  
         
  
      </footer>
   