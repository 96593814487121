<mat-toolbar  style="position:fixed;z-index:999" class="toolbar">
  <img src="/assets/images/ese_logo1.png" style="height:50px;">

  <!-- This fills the remaining space of the current row -->
  <span class="app-toolbar-filler"></span>

  <div class="topnav" id="myTopnav" style="display:none">
      <button mat-button [routerLink]="['/members/home']" *ngIf="isLoggedIn()"><mat-icon>home</mat-icon> Home</button>
    <button mat-button [routerLink]="['/members/leads']" *ngIf="isLoggedIn()"><mat-icon>show_chart</mat-icon> My Leads</button>
    <button mat-button [routerLink]="['/']" *ngIf="!isLoggedIn()"><mat-icon>home</mat-icon> Home</button>
      <button mat-button [matMenuTriggerFor]="menu" *ngIf="isAdmin">
        <mat-icon>settings</mat-icon> Manage <span class="caret"></span>
      </button>
      <mat-menu #menu="matMenu">
        <button  mat-menu-item [routerLink]="['/manage/crm']">
          <mat-icon>image</mat-icon>
          <span>CRM</span>
        </button>
        <button  mat-menu-item [routerLink]="['/manage/email']">
          <mat-icon>email</mat-icon>
          <span>Email Management</span>
        </button>
        <button  mat-menu-item [routerLink]="['/manage/users']">
          <mat-icon>supervisor_account</mat-icon>
          <span>User Management</span>
        </button>
        <button  mat-menu-item [routerLink]="['/manage/news']">
          <mat-icon>list</mat-icon>
          <span>News Management</span>
        </button>
        <button  mat-menu-item [routerLink]="['/manage/systempreference']">
          <mat-icon>settings</mat-icon>
          <span>System Preferences</span>
        </button>
        <button  mat-menu-item [routerLink]="['/manage/userpreference']">
          <mat-icon>settings</mat-icon>
          <span>User Preferences</span>
        </button>
      </mat-menu>

      <button mat-button [routerLink]="['/members/marketing']" class="white" *ngIf="isLoggedIn()"><mat-icon>show_chart</mat-icon> Marketing</button>

      <button mat-button [routerLink]="['/contact']"  class="white" ><mat-icon>email</mat-icon> Contact</button>

    <mat-menu #menu2="matMenu">
      <button mat-button [routerLink]="['/contact']"  class="white" ><mat-icon>email</mat-icon> Contact</button><br><br>
      <button mat-button [routerLink]="['/members/faq']"  class="white" *ngIf="isLoggedIn()"><mat-icon>question_answer</mat-icon> Faq</button>
    </mat-menu>

      <button mat-button [routerLink]="['/signin']" class="white" *ngIf="!isLoggedIn()" ><mat-icon>business_center</mat-icon> Signin</button>
      <button mat-button [routerLink]="['/checkout']" class="white" *ngIf="!isLoggedIn()" ><mat-icon>thumb_up</mat-icon> Signup</button>
      <button mat-button [routerLink]="['/members/account']" class="white" *ngIf="isLoggedIn()"><mat-icon>account_circle</mat-icon> Account</button>
      <button mat-button [routerLink]="['/members/logout']" class="white" *ngIf="isLoggedIn()"><mat-icon>exit_to_app</mat-icon> Logout {{logged_in_email}}   </button>
  </div>



  <div class="mobilenav">
    <button mat-button [matMenuTriggerFor]="menu1">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu1="matMenu">
      <button mat-button [routerLink]="['/members/home']" *ngIf="isLoggedIn()" class="full-width left"><mat-icon>home</mat-icon> Home</button>
      <button mat-button [routerLink]="['/members/leads']" *ngIf="isLoggedIn()" class="full-width left"><mat-icon>show_chart</mat-icon> My Leads</button>
      <button mat-button [matMenuTriggerFor]="menu" *ngIf="isAdmin" class="full-width left">
        <mat-icon>settings</mat-icon> Manage <span class="caret"></span>
      </button>

      <button mat-button [routerLink]="['/members/marketing']" class="white full-width left" *ngIf="isLoggedIn()"><mat-icon>show_chart</mat-icon> Marketing</button>
      <button mat-button [routerLink]="['/contact']"  class="white full-width left" ><mat-icon>email</mat-icon> Contact</button>
      <button mat-button [routerLink]="['/signin']" class="white full-width left" *ngIf="!isLoggedIn()" ><mat-icon>business_center</mat-icon> Signin</button>
      <button mat-button [routerLink]="['/checkout']" class="white full-width left" *ngIf="!isLoggedIn()" ><mat-icon>thumb_up</mat-icon> Signup</button>
      <button mat-button [routerLink]="['/members/account']" class="white full-width left" *ngIf="isLoggedIn()"><mat-icon>account_circle</mat-icon> Account</button>
      <button mat-button [routerLink]="['/members/logout']" class="white full-width left" *ngIf="isLoggedIn()"><mat-icon>exit_to_app</mat-icon> Logout {{logged_in_email}}  </button>

    </mat-menu>
  </div>
</mat-toolbar>
