<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="menusId" #menusId="ngModel" [ngModel]="menus?._id" id="menusId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="menus?.name" type="text" id="name" placeholder="Enter the Name of Category" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput name="img" #name="ngModel" [ngModel]="menus?.img" type="text" id="img" placeholder="Enter the URL of an image to use a an icon">
      </mat-form-field>

      <mat-form-field class="example-full-width">
          <textarea  style="height:200px;" matInput name="menu" #menu="ngModel"  type="text" id="menu" [ngModel]='menus?.menu' placeholder="Enter The menu in JSON format" required></textarea>
          <mat-error>This is a required field</mat-error>
        </mat-form-field>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
