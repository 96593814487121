import { Component, OnInit, ViewChild } from '@angular/core';
//import {FunnelsService} from "../funnels/funnels.service";
//import * as grapesjs from 'grapesjs';
//import { CommService } from '../../../services/comm.service';
//import 'grapesjs-preset-webpage';
//import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
//import { UploadService } from '../images/upload/upload.service';

//import { TemplatesService } from '../templates/templates.service';
//import { ActivatedRoute, Router } from '@angular/router';
//import { CategoriesService } from '../categories/categories.service';
//import { TypesService } from '../types/types.service';
//import { SubtypesService } from '../subtypes/subtypes.service';
declare const grapesjs:any;

@Component({
  selector: 'app-grapestestjs',
  template: `
  <div class="panel__top">
        <div class="panel__basic-actions"></div>
      </div>
      <div class="editor-row">
        <div class="editor-canvas" >
            <div id="gjs" ></div>
        </div>
        <div class="panel__right" style="display:none;">
        <div style="width:100%;background-color:#039be5;color:white;font-weight:900;padding-left:10px;">Layers</div>
            <div class="layers-container"></div>
        </div>
      </div>
      <div id="blocks"></div>
  `
 ,
  styleUrls: ['./grapesjstest.component.css']
})
export class GrapesjsTestComponent implements OnInit {
    editor;
    constructor(){
        console.log('hello dave')
         
    }
    ngOnInit(){
        this.editor = grapesjs.init({
            colorPicker: { appendTo: 'parent', offset: { top: 26, left: -156, }, },
            allowScripts: 1,
            avoidInlineStyle: true,
            noticeOnUnload: true,
            showOffsets: true,
            showOffsetsSelected: false,
            
            baseCss:'',
            protectedCss:'',
            canvasCss: '',
            height: '100vh',
            mediaCondition: 'min-width',
            container: '#gjs',
            fromElement: true,
            width: 'auto',
            // Disable the storage manager for the moment
            storageManager: { type: null },
            
            layerManager: {
                appendTo: '.layers-container'
            },
            plugins: [
                'gjs-preset-webpage',
                'gjs-blocks-basic'
            ],
            // Avoid any default panel
            
            
        });

        var blockManager = this.editor.BlockManager;
        

        

        
            
    }
}