import {Injectable, EventEmitter} from '@angular/core';
import {Urlshortener} from "./urlshortener";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class UrlshortenerService {
  private hostName = window.location.protocol + '//' + window.location.host;
  urlshortener: Urlshortener[] = [];
  urlshortenerIsEdit = new EventEmitter<Urlshortener>();
  constructor(private http: HttpClient) { }

  editUrlshortener(urlshortener: Urlshortener) {
    console.log(urlshortener)
    this.urlshortenerIsEdit.emit(urlshortener);
  }

  addRecord(urlshortener: Urlshortener) {
    const body = JSON.stringify(urlshortener);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/urlshortener/addrecord' + token, body, {headers: headers})
     
  }

  deleteRecord(urlshortener: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/urlshortener/deleterecord/' + urlshortener._id + token, {headers: headers})
    
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/urlshortener/getallrecords'+token,  {headers: headers})
   
  }

  updateRecord(urlshortener: Urlshortener){
    const body = JSON.stringify(urlshortener);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/urlshortener/updaterecord/' + urlshortener.urlshortenerId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/urlshortener/getrecordl/'+id+token,  {headers: headers})
    
  }

}
