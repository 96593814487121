<div style="position:relative;width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
    <button mat-flat-button color="primary" (click)="refresh()">Refresh</button>&nbsp; <button mat-flat-button color="primary" (click)="addRecord()">Add HTML Page</button>&nbsp; <button mat-flat-button color="primary" (click)="addWebBuilder()">Use Web Builder</button>&nbsp;<button mat-flat-button color="primary" (click)="addImage()">Media Manager</button>&nbsp;&nbsp;<a href="https://www.12minuteaffiliate.com/my-templates-help" target="_blank" title="Help" ><img alt="help" src="/assets/images/instructions-icon.png" style="width:40px"></a>
    <span class="pageTitle">{{pageTitle}}</span>
    <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);" [class]="theme" id="myGrid"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     
                     autoSizeColumns="true"
                     enableRangeSelection="true"
                    
                     [paginationPageSize]= "paginationSize"
                     suppressRowClickSelection
                     debug="false"
                     draggable="false"
                     rowHeight="30"
                     (rowClicked)="catchClick($event)"
                     rowSelection='multiple'>
    </ag-grid-angular>
  </div>


