<div style="background-color:#eee">
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8" style="background-color:#fff;padding-left:50px;padding-right:50px;">
                    <h1 style="font-size:34px; color: #C00; line-height:45px" align="center">

                            <strong>CONGRATULATIONS!!</strong><br>
                            You've Successfully Submitted Your Application to Become Part of the<br>
                            <strong>EASIEST SYSTEM EVER!</strong><br>
                            Expect a Member of My Team to Be Calling You Soon
                            
                    </h1>

                    <br><Br>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item"   src="https://www.youtube.com/embed/3gDAhrWnCnc?rel=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                        </div>
                            <br><br>
                    
                    <br />
                    <div style="border:dashed 3px #000;padding:30px;text-align:left;">
                    <div style="font-size:18pt;font-weight:bold;text-align:center;width:100%">An Important Message for Anyone Who is 
                            Dead-Serious About Having A Done-For-You Email List & Auto-Pilot Affiliate Commissions
                    </div>
                    <br /><br />
                    <div style="font-size:14pt;">
                    Applications to join the Easiest System Ever are taken on a first-come, first-serve basis. Spots are limited, as we're limited to the number of people whose lists we can build at once.<br>
                    <br>
                    So...<br>
                    <br>
                    If you want in, make sure you answer when we call (or you'll be put to the back of the line)<br>
                    <br>
                    However<br>
                    <br>
                    Every once in a while, I come across people who are different from the rest of the pack. They're ultra-serious about building a laptop business and escaping the 9-to-5. <br>
                    <br>
                    If that's you, I invite you to call my office at <span class="highlight">+1-678-894-1982</span> (Mon- Fri 10Am - 6PM Eastern Time) to let my staff know you're one of those rare individuals and <span class="highlight">we'll move you up the line.</span><br>
                    <br>
                    <div style="text-align:center;width:100%">
                        <strong style="margin:auto">GUARANTEE YOUR SPOT - CALL US NOW AND LET US KNOW YOU WANT IN!</strong><br>
                    </div>
                    <br>
                    If we don't answer, it means you've called after hours or we're on the other line. Rest assured we'll be calling you back ASAP!
                    For any other questions, I invite you to reach out via email <a href="closesalesforyou7@gmail.com"> closesalesforyou7@gmail.com</a> <br>

                    </div>
                    </div>

                    <br>
                
                    <br>

                    
                    <p>
                        <span style="font-size:14pt;"><strong>To YOUR Success</strong></span><br>
                        <img src="/assets/images/devononstage.png" style="width:200px"><br>
                        <span style="font-size:16pt;"><strong>Devon Brown</strong></span><br>
                    </p>
                    <br>
                
                    <br>
                    
                    <h1 style="font-size:34px; color: #C00; line-height:45px;width:80%;margin:auto;" align="center"><strong>This System Has Worked For These People - 
                            Are You Next?
                            </strong></h1>
                    <br/><br/>
                    <div align="center">
                            
                            <img src="/assets/images/test1.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test2.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test3.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test4.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test5.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test6.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test7.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test8.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test9.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test10.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test11.png" style="width:100%">
                            <br><br><hr><br><br>
                            <img src="/assets/images/test12.png" style="width:100%">
                            
                            
                        
                        </div>


            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>