import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Emailsection } from "./emailsection";
import {EmailsectionService} from "./emailsection.service";
import {GridOptions} from "ag-grid-community";
import {EmailsectionInputComponent} from "./emailsection-input/emailsection-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";

@Component({
  selector: 'app-emailsection',
  templateUrl: './emailsection.component.html',
  styleUrls: ['./emailsection.component.css']
})
export class EmailsectionComponent implements OnInit {
  data;
  columnDefs;
  confirmPressed = false;
  public gridOptions: GridOptions;
  constructor(private confirmService: ConfirmService, private emailsectionService:EmailsectionService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "name",field: "name"},
      {headerName: "subject",field: "subject"},
      {headerName: "Actions", width: 100,  template: `<button mat-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
             
             <button mat-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
           
     `},

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.emailsectionService.getAllEmails()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),

      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(EmailsectionInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.emailsectionService.editEmailsection(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.emailsectionService.deleteEmail(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.emailsectionService.getAllEmails()
      .subscribe(
        data => {
          this.data = data['obj'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(EmailsectionInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }



  ngOnInit() {
  }

}
