// logged-in.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Injectable()
export class AffiliateOnlyGuard implements CanActivate {
  constructor(private authService: AuthService, private _service: ToastService) {}

  canActivate() {
    return true
  }
}
