import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {StatusService} from "../status.service";
import {Status} from "../status";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-status-input',
  templateUrl: './status-input.component.html',
  styleUrls: ['./status-input.component.css']
})
export class StatusInputComponent implements OnInit {
  status:Status = null;

  constructor(private statusService:StatusService, private authService: AuthService, private _service: ToastService) {
    statusService.statusIsEdit.subscribe(
      status => {
        this.status = status;
        console.log(this.status);
      })

  }

  save(f:NgForm, model: Status, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.statusId){

      this.statusService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.statusService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
