import {Component, OnInit, Inject, EventEmitter} from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Templates } from "./templates";
import {TemplatesService} from "./templates.service";
import {GridOptions} from "ag-grid-community";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import {UploadComponent} from "../upload/upload.component";
import {DOCUMENT} from "@angular/common";
import {ClipboardService} from "../../../services/clipboard.service";
import { trigger, state, transition, style, animate } from '@angular/animations'
import { CommService } from '../../../services/comm.service';
//import { ImagesUploadComponent } from './images-upload.component/images-upload.component';

@Component({
  selector: 'app-templatesconfig',
  template: `
    <section class="example-section">
        <mat-checkbox [(ngModel)]="useName" class="example-margin" (change)="sendConfig()">Use Aweber Name Field.</mat-checkbox>
        
    </section>
  `,
  styleUrls: ['./templates.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class TemplatesConfigComponent implements OnInit {
    useName:boolean=false;

    sendConfig(){
        this.commService.sendMessage({'action':'useAweberNameField', 'value':this.useName });
    }

    constructor(
        private templatesSerevice:TemplatesService,
        private commService:CommService
        ){}
    ngOnInit(){}
}