import {Injectable, EventEmitter} from '@angular/core';
import {Templates} from "./templates";
import { Observable } from "rxjs";
import { BehaviorSubject } from 'rxjs';
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class TemplatesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  templates: Templates[] = [];
  templatesIsEdit = new EventEmitter<Templates>();
  getType = new EventEmitter<any>();
  getSubType = new EventEmitter<any>();
  getCategory = new EventEmitter<any>();

  private messageSource = new BehaviorSubject<any>("default message");
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient, private httpclient: HttpClient) { }

  setType(data){
    this.getType.emit(data);
  }

  setSubType(data){
    this.getSubType.emit(data);
  }

  setCategory(data){
    this.getCategory.emit(data);
  }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  editTemplates(templates: any) {
    this.templatesIsEdit.emit(templates);
  }

  addRecord(templates: any) {
    const body = JSON.stringify(templates);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/templates/addrecord' + token, body, {headers: headers})
    
  }
  addRecordV2(templates: any) {
    const body = JSON.stringify(templates);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post(this.hostName +'/api2/templates/addrecord', body, {headers: headers})
    
  }

  getRecordV2(id: any){
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.get('/api2/templates/getrecord/' + id, {headers: headers});
  }

  updateRecordV2(templates: any){
    const body = JSON.stringify(templates);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.patch(this.hostName +'/api2/templates/updaterecord/' + templates.templatesId, body, {headers: headers})
     

  }


  deleteRecord(templates: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/templates/deleterecord/' + templates._id + token, {headers: headers})
    
  }

  copyRecord(templates: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/templates/copyrecord/' + templates._id + token, {headers: headers})
    
  }

  copyRecordSetTypeSetSource(templates: any){
    const body = JSON.stringify(templates);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/templates/copyrecordsettypesetsource/' + token, body, {headers: headers})
  }

  getAllRecords() {
    //const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getallrecords'+token,  {headers: headers})
  }

  getAllMyRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getallmyrecords'+token,  {headers: headers})
  }

  getAllRecordsBySubtype(subtype: any) {
    const body = JSON.stringify(subtype);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/templates/getallrecordsbysubtype'+token, body, {headers: headers});
  }

  getAllRecordsBySubtypeLimitedData(subtype: any) {
    const body = JSON.stringify(subtype);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/templates/getallrecordsbysubtypelimiteddata'+token, body, {headers: headers});
  }

  getAllRecordsByCategory(category) {
    const body = JSON.stringify(category);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/templates/getallrecordsbycategory'+token, body, {headers: headers})
   
  }

  getAllRecordsByIdentifier(identifier: any) {
    const body = JSON.stringify(identifier);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/templates/getallrecordsbyidentifier'+token, body, {headers: headers})
   
  }

  verifyRecord(record) {
    const body = JSON.stringify(record);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/templates/verifyrecord'+token, body, {headers: headers})
    
  }

  getCategoryRecords(value) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/templates/getcategoryrecords/'+value+token,  {headers: headers})
    
  }

  getCategoryRecordsLimitedData(value) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getcategoryrecordslimiteddata/'+value+token,  {headers: headers})
  }

  getCategoryMasterRecords(value) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/templates/getcategorymasterrecords/'+value+token,  {headers: headers})
     
  }

  getCategoryAndMasterRecords(value) {
    const body = JSON.stringify(value);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/templates/getcategoryandmasterrecords/'+token, body,  {headers: headers})
     
  }

  getSubtypeRecordsById(value) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getallsubtyperecordsbyid/'+value+token,  {headers: headers})
  }

  getSubtypeRecordsByIdLimitedData(value) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getallsubtyperecordsbyidlimiteddata/'+value+token,  {headers: headers})
  }

  getLayouts(name) {
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/templates/getrecordbyname/'+name+token,  {headers: headers})
     
  }

  getRecordBySubtypeAndWid(record) {
    const body = JSON.stringify(record);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/templates/getrecordbysubtypeandwid', body, {headers: headers})
  }

  getRecordBySubtypeAndTypeAndCategory(record: any) {
    const body = JSON.stringify(record);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/templates/getrecordbysubtypeandtypeandcategory', body, {headers: headers})
  }

  getMyRecordBySubtypeAndTypeAndCategory(record: any) {
    const body = JSON.stringify(record);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/templates/getmyrecordbysubtypeandtypeandcategory', body, {headers: headers})
  }

  updateRecord(templates: any){
    const body = JSON.stringify(templates);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/templates/updaterecord/' + templates.templatesId + token, body, {headers: headers})
     

  }

  //not using at the moment
  addEditedData(templates: Templates){
    const body = JSON.stringify(templates);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/templates/updaterecord/' + templates.templatesId + token, body, {headers: headers})
     
  }

  getRecord(id) {
    //const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getrecord/'+id+token,  {headers: headers}) 
  }

  getRecordwithpopulate(id) {
    //const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/templates/getrecordwithpopulate/'+id+token,  {headers: headers}) 
  }

}
