import { Component, OnInit, } from '@angular/core';
import { NgForm, FormControlName } from "@angular/forms";
import {AuthService} from "../auth.service";
import {User} from "../user";
import {Router} from "@angular/router";
import { ToastService } from 'ng-uikit-pro-standard';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-checkemailerror',
  template: ` 
      <div style="width:100%;text-align:center">
        <h1>Whoops!</h1><br>
        <span style="font-size:18pt">Something went wrong, please contact support by going to <a href="easiestsupportever.com" target="_blank">easiestsupportever.com</a>.</span>
        
      </div>
      `,
  styleUrls: ['./signup.component.css']
})
export class CheckemailerrorComponent implements OnInit {
  public user: User;
  constructor(private authService: AuthService,  private _service: ToastService, private router: Router) { }

  ngOnInit() {

  }
}
