import { Component, OnInit } from '@angular/core';
import {Record} from "../record";
import {MyapplicationsService} from "../myapplications.service";
import {Lead} from "../lead";
import { MatInputModule } from '@angular/material/input';
import { ToastService } from 'ng-uikit-pro-standard';
import {Router} from "@angular/router";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {EmailsectionService} from "../../emailsection/emailsection.service";
import * as moment from 'moment';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { CrmService } from '../../crm/crm.service';
import { Systempreference } from '../../shared/systempreference/systempreference';

@Component({
  selector: 'app-myapplications-input',
  templateUrl: './myapplications-input.component.html',
  styleUrls: ['./myapplications-input.component.css']


})
export class MyapplicationsInputComponent implements OnInit {
  lead:Lead = null;
  emails: any;
  emailSubject: string = null;
  emailBody: string = null;
  emailName: string = null;
  emailId: number = null;
  leadFirstname: string = null;
  leadLastname: string = null;
  leadEmail: string = null;
  leadPhone: string = null;
  notesClick = 0;
  privateNotesClick = 0;
  gmailUsername;
  gmailPassword;
  fromEmail:string="help@easysupportnow.com";
  doc
  emailType:string="sendgrid";

  constructor(private systempreferenceService: SystempreferenceService, private crmService: CrmService, private myapplicationsService: MyapplicationsService, private _service: ToastService,private router:Router, private dialogRef: MatDialogRef<MyapplicationsInputComponent>, private emailsectionService: EmailsectionService) {
    myapplicationsService.leadIsEdit.subscribe(
      lead => {
        this.lead = lead;
        this.leadFirstname = this.lead.firstname;
        this.leadLastname = this.lead.lastname;
        this.leadEmail = this.lead.email;
        this.leadPhone = this.lead.phone;
      })

      this.doc = JSON.parse(localStorage.getItem('userRecord'));
        console.log("doc",this.doc);
        this.gmailUsername = this.doc['gmail_username'];
        this.gmailPassword = this.doc['gmail_password'];
  }

  onStatusChange(value){
    let element="notes";
    let el = document.getElementById(element) as HTMLTextAreaElement;
    let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
    let username = localStorage.getItem('username');
    el.focus();
    el.scrollTop = 0;
    el.value = timeStamp + " - " + username + "\n\nStatus Changed: " + value + "\n\n" + el.value;
  }

  save(model: Lead, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model, isValid);
    this.notesClick = 0;
    this.privateNotesClick = 0;
    this.myapplicationsService.updateRecord(model)
      .subscribe(
        data => {
          console.log(data);
          this._service.success(data['message'], data['title'])
        },
        error => this._service.error(error['message'], error['title']),

      )
    
  }

  sendEmail(email, isValid: boolean) {
    console.log(email);
    // check if model is valid
    // if valid, call API to save customer

    if (this.emailType=="gmail"){
      this.fromEmail=this.gmailUsername
    } 
    let doc = JSON.parse(localStorage.getItem('userRecord'));
    console.log("email type",this.emailType)
   
          email.from=this.fromEmail
          email.type = this.emailType;
         email.myFirstname = doc.firstname;
         email.myLastname = doc.lastname;
         email.myEmail = doc.email;
         email.myPhone = doc.phone;
         email.username = this.gmailUsername;
         email.password = this.gmailPassword;
         email.crm_lastname=email.lead_lastname;
         email.crm_firstname=email.lead_firstname;
         email.crm_email=email.lead_email;
         email.crm_phone=email.lead_phone;
         console.log(email);
         this.crmService.sendEmail(email)
           .subscribe(
             data => {
               console.log(data);
               this._service.success(data['message'], data['title'])
             },
             error => this._service.error(error['message'], error['title']),
     
           )

       

  }

  onChange(event){
    var value=event.value;
    console.log(value);

    if (value=="all"){

    } else {
      this.emailsectionService.getEmail(value)
        .subscribe(
          email => {
            console.log(email);
           // this.pages = pages;
          //  this.pageService.pages = pages;
            this.emailSubject = email['obj'].subject;
            this.emailBody = email['obj'].body;
          },
          error => this._service.error("error", error['title'])
        );
    }


  }

  ngOnInit() {
    this.emailsectionService.getAllEmails()
      .subscribe(
        data => {
          this.emails = data['obj'];
        },
        error => this._service.error(error['message'], error['title']),

      )

  }

  addNotesTime(element){


    if (this.notesClick==0) {
      let el = document.getElementById(element) as HTMLTextAreaElement;
      let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
      let username = localStorage.getItem('username');
      console.log(timeStamp + " - " + username + "\n\n" + el.value);
      el.focus();
      el.scrollTop = 0;
      el.value = timeStamp + " - " + username + "\n\n" + el.value;
      this.notesClick++;
    }


  }

  addPrivateNotesTime(element){


    if (this.privateNotesClick==0) {
      let el = document.getElementById(element) as HTMLTextAreaElement;
      let timeStamp = moment().format('MMM Do YYYY, h:mm:ss a');
      let username = localStorage.getItem('username');
      console.log(timeStamp + " - " + username + "\n\n" + el.value);
      el.focus();
      el.scrollTop = 0;
      el.value = timeStamp + " - " + username + "\n\n" + el.value;
      this.privateNotesClick++;
    }


  }

}
