<div class="container">
  
      <div class="row" style="height:60px">
        <div class="col-md-3">
          <select class="browser-default custom-select" style="width:100%" [(ngModel)]="category" (change)="processFilter()">
            <option selected>Category</option>
            <option value="hb">Home Business</option>
            <option value="wl">Weight Loss</option>
            <option value="pd">Personal Development</option>
          </select>
        </div>
       
         <div class="col-md-3">
           <button (click)="onShowColorModal()" style="width:100%">Color Picker</button>
         </div>

        <div class="col-md-3" >
          <select class="browser-default custom-select" style="width:100%" [(ngModel)]="imageType" (change)="processFilter()">
            <option value="backgrounds">Stock Backgrounds</option>
            <option value="{{username}}">My Files</option>
          </select>
        </div>
          
        <div class="col-md-3">
          <div *ngIf="showUpload"><app-filedraganddrop style="width:100%" (draganddropedId)="processEmitter($event)"></app-filedraganddrop></div>
        </div>
          
      </div>
     
  <div class="row">
    <mat-icon (click)='refresh(filterPath)' style="cursor:pointer">refresh</mat-icon>&nbsp; <mat-icon style="cursor:pointer" mdbTooltip="Grid View" placement="top" mdbWavesEffect (click)="changeViewToGallery()">apps</mat-icon>&nbsp;<mat-icon style="cursor:pointer" mdbTooltip="List View" placement="top" mdbWavesEffect (click)="changeViewToList()">view_headline</mat-icon>
  </div>
  <div class="row">
    <div class="col">
      <div class="row" *ngIf='myView=="gallery"'>
        <div class="col-md-4 mx-auto my-3"  *ngFor="let el of galleryData; let i = index" style="height:200px;overflow:hide;">
          <div class="view overlay zoom" mdbWavesEffect style='width:100%;height:100%;background-repeat:no-repeat;background-size: 100% 100%;cursor:pointer'  (click)="clickImage($event,el)">
            <img src="{{el.url}}" style="width:100%;max-height:100%" (click)="getImage(el)">
          </div>
        </div>
      </div>
      
      <ag-grid-angular *ngIf='myView=="list"'  #agGrid style="width: 100%; height: calc(100vh - 200px);;" class="ag-theme-dave" id="myGrid"
                      [gridOptions]="gridOptions"
                      [columnDefs]="columnDefs"
                      
                      enableColResize
                      enableSorting
                      autoSizeColumns="true"
                      enableRangeSelection="true"
                      suppressRowClickSelection
                      enableFilter
                      
                      [pagination]="true"
                        [paginationPageSize]= "paginationSize"
                      debug="false"
                      draggable="false"
                      rowHeight="300"
                      (rowClicked)="catchClick($event)"
                      rowSelection='multiple'>
      </ag-grid-angular>
    </div>
    
  </div>
</div>

<div mdbModal #colorModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: false}">
  <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-quarter-width" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <span style="width:98%;text-align:right;"><h4 style="font-weight:900"></h4></span>
            
              <button type="button" class="close pull-right" aria-label="Close" (click)="colorModal.hide()">
                  <span aria-hidden="true">×</span>
              </button>
          </div>
          <div class="modal-body">
            <app-htmlcolorpicker></app-htmlcolorpicker>
          </div>
          
      </div>
  </div > 
</div >

<div mdbModal #addMediaModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <span style="width:98%;text-align:right;"><h4 style="font-weight:900">UPLOAD MEDIA</h4></span>
            
              <button type="button" class="close pull-right" aria-label="Close" (click)="addMediaModal.hide()">
                  <span aria-hidden="true">×</span>
              </button>
          </div>
          <div class="modal-body">
              <app-filedraganddrop (draganddropedId)="processEmitter($event)" (closeButtonClick)="onCloseAddMediaModal($event)"></app-filedraganddrop>
          </div>
          
      </div>
  </div > 
</div >

<div mdbModal #confirmModal="mdbModal" class="modal fade top" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel1" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog modal-notify modal-danger" role="document">
      <div class="modal-content">
          <div class="modal-header d-flex justify-content-start">
            <p class="heading"><mat-icon style="font-size:30pt;">{{confirmObj.icon}}</mat-icon><span style="position:relative;top:-8px;font-size:26pt">&nbsp;&nbsp;&nbsp;{{confirmObj.title}}</span></p>
          </div>
          <div class="modal-body">
            <span style="font-size:12pt">{{confirmObj.body}}&nbsp;<a href="{{confirmObj.link}}" target="{{confirmObj.target}}">{{confirmObj.linkTxt}}</a></span>
          </div>
          <div class="modal-footer justify-content-end">
            <a type="button" (click)="confirmModal.hide();confirmDelete(true)" mdbBtn color="danger" outline="true" class="waves-effect"
              mdbWavesEffect>Yes</a>
            <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="confirmModal.hide();">No</a>
          </div>
      </div>
  </div>
</div>



