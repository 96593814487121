import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../auth/auth.service";
import {NgForm} from "@angular/forms";
import { GuaranteedoptinsService } from './guaranteedoptins.service.service';

@Component({
  selector: 'app-guaranteedoptins',
  templateUrl: './guaranteedoptins.component.html',
  styleUrls: ['./guaranteedoptins.component.css']
})
export class GuaranteedoptinsComponent implements OnInit {
  guaranteedoptin:any = null;
  constructor(private guaranteedoptinsService: GuaranteedoptinsService, private _service: ToastService) { }

  save(f:NgForm, model: any, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    model.request="guaranteedoptins";
    model.username=localStorage.getItem('username') ?  localStorage.getItem('username') : "";
    console.log(model);
    if (model.funnelsId){

      /*this.guaranteedoptinsService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        ) */
      console.log(model, isValid); 
    } else {

      this.guaranteedoptinsService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        ) 
    }
  }

  ngOnInit() {
  }

}
