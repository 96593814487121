import { Component, OnInit, ViewChild } from '@angular/core';
import {AuthService} from "../auth.service";
//import {ErrorService} from "../../errors/error.service";
import {User} from "../user";
import { NgForm } from "@angular/forms";
//import {NotificationService} from "../../notifications/notification.service";
import { ModalDirective, ToastRef, ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommService } from '../../../../services/comm.service';
import { ConfirmService } from '../../shared/confirm/confirm.service';
import { ConfirmComponent } from '../../shared/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  showAds=this.isFreeMember();
  newPassWord='';
  newPassWordAgain="";
  passwordType="password";
  passwordAgainType="password"

  confirmPressed:boolean=false;
  @ViewChild('passModal') passModal: ModalDirective;
  constructor(private systempreferenceService:SystempreferenceService, private commService:CommService,private router: Router, private authService: AuthService,  private _service: ToastService,private confirmService: ConfirmService,public dialog: MatDialog, ) { 
    this.sendPageInfo();
    this.receiveMenuCommand();
    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
        console.log('data',data)
      })
  }

  sendPageInfo(){
    this.commService.sendMessage({'action':'pageInfo', 
    'pagetitle':"Account Info", 
    });
  }
  
  subscription: Subscription;
  receiveMenuCommand(){
    
    this.subscription = this.commService.onMessage().subscribe(message=>{
      console.log(message)
      switch(message.action){
        case ("refreshGrid"):
            
            break;
        
      }
    })
  }

  changePasswordType(){
    if(this.passwordType== 'password'){
      this.passwordType= 'text'
    }else if (this.passwordType=="text") {
      this.passwordType= 'password'
    }
    
  }

  changePasswordAgainType(){
    if(this.passwordAgainType== 'password'){
      this.passwordAgainType= 'text'
    }else if (this.passwordAgainType=="text") {
      this.passwordAgainType= 'password'
    }
    
  }

  public confirmChangePassword(){
    if (this.newPassWord !== this.newPassWordAgain){
      this._service.error("The Passwords do not match. Please Try again", "Error: Password Mismatch", {opacity:1})
      return;
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"RESET PASSWORD?", body:"Really RESET It?", icon:"email", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      
      if (this.confirmPressed == true){
        console.log(this.confirmPressed)
        this.onChangePassword()
      }
    });
  }

  onChangePassword(){
    console.log("now changing oass");
    
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
      console.log(systempreference);
      this.systempreferenceService.getKey(systempreference)
        .subscribe(
          configData => {
            
            var config, templateId;
            try {
              config=JSON.parse(configData['obj'].value);
              console.log(config)
              templateId=config.updatePasswordEmailTemplate;
              let body={
                domain: document.location.hostname,
                template_id: templateId,
                subject: "Notification: Easiest System Ever Password Reset",
                eseid:localStorage.getItem('username') ? localStorage.getItem('username') : '',
                to:this.user.email,
                from:"help@easysupportnow.com",
                password: this.newPassWord,
                username: localStorage.getItem('username') ? localStorage.getItem('username') : ''
              }
              this.authService.changePassword(body)
                .subscribe (
                  passwordData => {
                    console.log(passwordData)
                    this._service.success(passwordData['message'], passwordData['title'], {opacity:1})
                  }
                ),
                error => this._service.error("There was an error. Please Try again", "Error: Password Not Changed", {opacity:1})

                
              console.log(body)
            } catch (error) {
              console.error(error);
            }
        })
  }

  changePass(){
    console.log("newpass",this.newPassWord);
    this.passModal.show();
  }

 

  user: User;
  isAdmin = false;

  ngOnInit() {
    
    let user_id = localStorage.getItem('userId') ? localStorage.getItem('userId') : '';
   
    this.authService.getRecord(user_id)
      .subscribe(
        user => {
          this.user = user['obj'];
          //this.authService.user = user;
          console.log(user);
        },
        error => this._service.error(error['title'], error['message'])
      );
  }

  save(f:NgForm, user: any, isValid: boolean) {
    //const user = new User(null, form.value.username, null, null, form.value.firstname, form.value.lastname, form.value.email, form.value.address, form.value.address2, form.value.city, form.value.state, form.value.zip, form.value.skype, form.value.phone, form.value.paypal, null, null);
    console.log(user);
    this.authService.updateUser(user)
      .subscribe(
        data => {
          if (data['status']=="email"){
            this._service.warning(data['title'], data['message'],{opacity:1})
            setTimeout(() => this.router.navigate(['/members/logout', {login: 'Success'}]), 6000);
          } else {
            this._service.success(data['title'], data['message'],{opacity:1})
          }
        },
        error => {
          console.log(error);
          this._service.error(error['title'], error['message']);
          
          
        }
      ),
      error => this._service.error(error['title'], error['message']);

  }

  isFreeMember() {
    return this.authService.isFreeMember();
  }
}
