<div style="width:100%;background-color:#000;position:relative;">
    <button mat-icon-button mat-mini-fab color="primary" mat-dialog-close style="position:absolute;top:-20px;right:-20px;z-index:999">
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
</div>
  
  <h2 mat-dialog-title>Request</h2>
<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="requestId" #requestId="ngModel" [ngModel]="request?._id" id="requestId">
    <mat-form-field class="example-full-width">
        <input matInput name="username" #username="ngModel" [ngModel]="request?.username" type="text" id="username" placeholder="Enter the Username" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="firstname" #firstname="ngModel" [ngModel]="request?.firstname" type="text" id="firstname" placeholder="Enter the First Name" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="lastname" #lastname="ngModel" [ngModel]="request?.lastname" type="text" id=lastname placeholder="Enter the Last Name" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput name="phone" #phone="ngModel" [ngModel]="request?.phone" type="text" id=phone placeholder="Enter the Phone" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
          <input matInput name="email" #email="ngModel" [ngModel]="request?.email" type="text" id=email placeholder="Enter the Email" required>
          <mat-error>This is a required field</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput name="type" #type="ngModel" [ngModel]="request?.type" type="text" id=type placeholder="Enter the Request Type" required>
            <mat-error>This is a required field</mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <textarea  name="notes" matInput #notes [ngModel]="request?.notes"  id="notes" placeholder="notes" style="height:200px;"></textarea>
          </mat-form-field>
          <div>
              <mat-select name="status"  (selectionChange)="onStatusChange($event)" #status [ngModel]="request?.status"  id="status" placeholder="Status"  floatPlaceholder="auto" class="example-full-width">
                <mat-option value="complete">Completed</mat-option>
                <mat-option value="processed">Processed</mat-option>
                <mat-option value="onhold">On Hold</mat-option>
                <mat-option value="Unable to Contact">Unable to Contact</mat-option>
                <mat-option value="NI/NQ">NI/NQ</mat-option>
                <mat-option value="Appoitment Set">Appointment Set</mat-option>
                <mat-option value="Appoitment Kept">Appointment Kept</mat-option>
                <mat-option value="Sold">Sold</mat-option>
                <mat-option value="Not Interested">Not Interested</mat-option>
                <mat-option value="In Fullfillment">In Fullfillment</mat-option>
                <mat-option value="Duplicate">Duplicate</mat-option>
                <mat-option value="No Show">No Show</mat-option>
              </mat-select>
            </div>
      
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
