<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="webinarId" #webinarId="ngModel" [ngModel]="webinar?._id" id="webinarId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="webinar?.name" type="text" id="name" placeholder="Enter the Name or Title" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="url" #url="ngModel" [ngModel]="webinar?.url" type="text" id="url" placeholder="Enter the URL for the webinar" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
   

    <mat-form-field class="example-full-width">
        <input matInput name="webinar_time" #webinar_time="ngModel" [ngModel]="webinar?.webinar_time" type="text" id="webinar_time" placeholder="Enter the Time of the Webinar" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
