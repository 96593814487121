import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {LinksService} from "../links.service";
import {Links} from "../links";
import {NgForm} from "@angular/forms";
import {TemplatesService} from "../../templates/templates.service";
import {Templates} from "../../templates/templates";
import {CategoriesService} from "../../categories/categories.service";
import {Categories} from "../../categories/categories";

@Component({
  selector: 'app-links-input',
  templateUrl: './links-input.component.html',
  styleUrls: ['./links-input.component.css']
})
export class LinksInputComponent implements OnInit {
  links:Links = null;
  templates:Templates = null;
  categories: Categories = null;




  constructor(private categoriesService: CategoriesService, private pageService: TemplatesService, private linksService:LinksService, private authService: AuthService, private _service: ToastService) {

    linksService.linksIsEdit.subscribe(
      links => {
        this.links = links;
        console.log(links);
        this.pageService.getRecord(links.page)
        .subscribe(
          page => {
            console.log(page['obj'].headline);
            console.log(this.links.headline);
            if (this.links.headline==""){
              console.log(page['obj'].headline);
              this.links.headline=page['obj'].headline;
            }
           // this._service.success(data['message'], data['title'])
          },
          //error => this._service.error(error['message'], error['title']),

        )
      })



  }

  save(f:NgForm, model: Links, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);

    if (model.linkId){

    //  model.body = btoa(model.body);
     // model.type = "template";
      this.linksService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {
    //  model.body = btoa(model.body);
     // model.type = "template";

      this.linksService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {

    this.categoriesService.getAllRecords()
      .subscribe(
        data => {
          this.categories = data['obj'];
          console.log(this.categories);
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.pageService.getAllRecords()
      .subscribe(
        data => {
          this.templates = data['obj'];
          console.log(this.templates);
        },
        error => this._service.error(error.error['message'], error['title']),
      );

  }

  

  getTemplates(event){
    console.log(event);
    this.pageService.getCategoryRecords(event.value)
      .subscribe(
        data => {
          this.templates = data['obj'];
          console.log(this.templates);
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }


  onChange($event){}
  onReady($event){}
  onBlur($event){}
  onFocus($event){}

}
