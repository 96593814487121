<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="levelsId" #levelsId="ngModel" [ngModel]="levels?._id" id="levelsId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="levels?.name" level="text" id="name" placeholder="Enter the Name of Category" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="level" #level="ngModel" [ngModel]="levels?.level" level="text" id="level" placeholder="Enter the Level" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>


    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" level="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
