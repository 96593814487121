<article class="panel panel-default" id="tour">

  <div class="panel-body">
    <b>Subject:</b> {{ emailsection.subject }}
  </div>
  <div class="panel-body">
    <b>Body:</b> {{ emailsection.body }}
  </div>
  <footer class="panel-footer">
    <div class="title">
      <b>{{ emailsection.name }}</b>
    </div>
    <div class="config">
      <a (click)="onEdit()">Edit</a>
      <a (click)="onDelete()">Delete</a>
    </div>
  </footer>
</article>
