import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {ServersService} from "../servers.service";
import {Servers} from "../servers";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-servers-input',
  templateUrl: './servers-input.component.html',
  styleUrls: ['./servers-input.component.css']
})
export class ServersInputComponent implements OnInit {
  servers:Servers = null;
  disableField:boolean=true;
  constructor(private serversService:ServersService, private authService: AuthService, private _service: ToastService) {
    serversService.serversIsEdit.subscribe(
      servers => {
        this.servers = servers;
        console.log(this.servers);
      })

  }

  save(f:NgForm, model: Servers, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.serversId){

      this.serversService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.serversService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  editField(){this.disableField=false;}

  ngOnInit() {
  }

}
