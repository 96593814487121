import { Component, OnInit, Inject, Optional } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.css']
})
export class CommissionsComponent implements OnInit {
  token;
  userId;

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    //this.userId=this.route.snapshot.params.userid;
   // this.userId=data.id;
   // console.log(this.userId);
    console.log(data);
    if (data==null){
      this.userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '';
    } else {
      this.userId = data.id;
    }
   }

  ngOnInit() {
  }

  commUrl(){
    this.token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    //this.userId = localStorage.getItem('userId') ? localStorage.getItem('userID') : ''
    console.log(this.userId);
    return this.sanitizer.bypassSecurityTrustResourceUrl("/api/api/getontraportcommissions/"+this.userId+this.token);
  }

}
