import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../../auth/auth.service";
import {SystempreferenceService} from "../systempreference.service";
import {Systempreference} from "../systempreference";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-systempreference-input',
  templateUrl: './systempreference-input.component.html',
  styleUrls: ['./systempreference-input.component.css']
})
export class SystempreferenceInputComponent implements OnInit {

  systempreference:Systempreference = null;

  constructor(private systempreferenceService:SystempreferenceService, private authService: AuthService, private _service: ToastService) {
    systempreferenceService.newsIsEdit.subscribe(
      systempreference => {
        this.systempreference = systempreference;
        console.log(this.systempreference);
      })

  }

  save(f:NgForm, model: Systempreference, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.systempreferenceId){

      this.systempreferenceService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.systempreferenceService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
