import { Component, OnInit, Input } from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import { ToastService } from 'ng-uikit-pro-standard';
import { Systempreference } from '../../shared/systempreference/systempreference';
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() loggedin_email: string;
  isAdmin = false;
  logo;
  backgroundColor;
  username;
  constructor(private systempreferenceService: SystempreferenceService, private authService:AuthService,  private _service: ToastService) { }

  ngOnInit() {
    this.checkAdmin();
    this.username = localStorage.getItem('username');
    console.log(document.location.hostname);
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    console.log(systempreference);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          console.log(systemPref['obj'].value);
          var myJson = JSON.parse(systemPref['obj'].value);
          console.log(myJson);
          this.logo="assets/images/LOGO-4.png";//myJson.logo;
          this.backgroundColor=myJson.sideNavBackgroundColor;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );
  }

  isLoggedIn() {
    //this.logged_in_email = localStorage.getItem('email');
    return this.authService.isLoggedIn();
  }

  checkAdmin(){
    this.authService.isAdmin()
      .subscribe(
        data => {
          this.isAdmin=data['success'];
          console.log(data);
        }
      );
  }

}
