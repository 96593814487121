import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {SupportService} from "../support.service";
import {Support} from "../support";
import {NgForm} from "@angular/forms";
import { StatusService } from '../../status/status.service';
import {Status} from '../../status/status'

@Component({
  selector: 'app-support-input',
  templateUrl: './support-input.component.html',
  styleUrls: ['./support-input.component.css']
})
export class SupportInputComponent implements OnInit {
  support:Support = null;
  username: string; 
  mystatus;
  response:boolean=false;
  thread_id;
  _id;
  statuses:Status=null;

  

  constructor(private statusService: StatusService, private supportService:SupportService, private authService: AuthService, private _service: ToastService) {
    this.username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    this.statusService.getAllRecords()
      .subscribe(
        data => {
          this.statuses = data['obj'];
        }
      )

    supportService.supportIsEdit.subscribe(
      support => {
       
        this.support=support;
        this.mystatus=this.support.status['_id'];
        console.log("support:",this.support.status['_id']);
      })

  }

  

  save(f:NgForm, model: Support, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.supportId){

      this.supportService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {
     
        this.thread_id = Date.now() + Math.random() + "." + this.randomNumber();
        model.thread=this.thread_id;
        model.level="1";
     
      model.username = this.username;
      console.log(model);
      this.supportService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  ngOnInit() {
    
      
  }

}
