import { Component, OnInit, ViewChild, EventEmitter, ElementRef  } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations'
import { UploadService } from '../upload/upload.service';
import { ImagesComponent } from '../images/images.component';
import { MatDialog } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';
import { NotificationsService } from '../shared/notifications/notifications.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class SignatureComponent implements OnInit {
  
  @ViewChild('sPad', {static:true}) signaturePadElement;
  signaturePad: any;
  onAdd = new EventEmitter();
  
  visiblityState = 'hidden';
  messageTitle;
  messageBody;
  icon;
  step:String=null;
  progress = false;
  uploadedImage: File;
  sigingrequest;
  uploaded= true;
  showSave:boolean=false;

  constructor(private notificationService: NotificationsService, private uploadService: UploadService, private el: ElementRef, private dialog:MatDialog) {
    
   }

  clearPad(){
    console.log('clearing...')
  //  this.signaturePad.clear();
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
   // this.signaturePad.set('minWidth', 5); 
   // this.signaturePad.clear(); 
   this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  addImage(){
    let dialogRef = this.dialog.open(ImagesComponent, {
      height: '95vh',
      width: '85%',

    });
  }

  save(){
    // will be notified of szimek/signature_pad's onEnd event
    
    // Base64 url of image trimmed one without data:image/png;base64
   // var splitImage=this.signaturePad.toDataURL().split(',');
  //  var base64=splitImage[1];
    // Naming the image
    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(Math.floor(Math.random() *    possibleText.length));
    }
    // Replace extension according to your media type
      const fileName = date + '.' + text + '.png';
    // call method that creates a blob from dataUri
      const dataURL = this.signaturePad.toDataURL();
      this.upload(dataURL, fileName)
   // const imageBlob = this.dataURItoBlob(base64);
  //  const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
   // this.uploadedImage = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //this.upload();
  }
 
  drawComplete() {
    
  }

  dataURItoBlob(dataURI) {
    const parts = dataURI.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const byteString = window.atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });    
    return blob;
 }

 upload(dataURL, fileName){
   
  this.progress=true;
  const blob = this.dataURItoBlob(dataURL);
  
  var contentType="image/png";
  //var file = inputEl.files.item(0);
   
  var file = new File([blob], fileName, { type: contentType });
  
  console.log(file);
  let completeFileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
  console.log("complete file name", completeFileName)
  
  this.uploadService.getSignedRequest(completeFileName, contentType)
    .subscribe(
      data => {
        this.sigingrequest=data;
        console.log("sign1",this.sigingrequest)
        this.finalUpload(this.sigingrequest, file, 'image/png')
      },
      error => this.notificationService.updateMessage(error['title'],error['message'],'warn', 'warning',  5000,function(){})
    );
}

 randomNumber(){
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

 finalUpload(data, file, fileType){
   console.log("sign2",data.signedRequest)
  this.uploadService.upload(data.signedRequest, file, fileType)
    .subscribe(
      data => {
        console.log(data);
        if (data['status']==200){
          console.log('noe')
          this.progress=false;
          this.uploaded=true;
          this.notificationService.updateMessage('Success','Your Signature Image Has Been Uploaded. You can access it for use in your webpages by going to the media manager','success', 'check_circle',  5000,function(){})
            
        }
      },
      error => {
        console.log(error)
        this.notificationService.updateMessage(error['title'],error['message'],'warn', 'warning',  5000,function(){})
      },
    );
}
 
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  ngOnInit() {
    //this.signaturePad.set('minWidth', 5);
    //this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  changeColor() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const color = 'rgb(' + r + ',' + g + ',' + b + ')';
    console.log(color)
    this.signaturePad.penColor = color;
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  download(dataURL, filename) {
    console.log(dataURL);
    
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      window.open(dataURL);
    } else {
      const blob = this.dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
  }

  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    console.log("content type:",contentType);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  savePNG() {
    if (this.signaturePad.isEmpty()) {
      this.notificationService.updateMessage('Error','Please Provide a Signature first','success', 'check_circle',  5000,function(){})
    } else {
      this.notificationService.updateMessage('Success','Downloading','success', 'check_circle',  5000,function(){})
      const dataURL = this.signaturePad.toDataURL();
      this.download(dataURL, 'signature.png');
    }
  }

  saveJPG() {
    if (this.signaturePad.isEmpty()) {
      this.notificationService.updateMessage('Error','Please Provide a Signature first','success', 'check_circle',  5000,function(){})
    } else {
      this.notificationService.updateMessage('Success','Downloading','success', 'check_circle',  5000,function(){})
      const dataURL = this.signaturePad.toDataURL('image/jpeg');
      this.download(dataURL, 'signature.jpg');
    }
  }

  saveSVG() {
    if (this.signaturePad.isEmpty()) {
      this.notificationService.updateMessage('Error','Please Provide a Signature first','success', 'check_circle',  5000,function(){})
    } else {
      this.notificationService.updateMessage('Success','Downloading','success', 'check_circle',  5000,function(){})
      const dataURL = this.signaturePad.toDataURL('image/svg+xml');
      this.download(dataURL, 'signature.svg');
    }
  }

  

}
