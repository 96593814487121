<div class="row">
  <div class="col-md-6 mx-auto my-5">
    <div style="width:100%;text-align:center;font-size:1.5rem">CONGRATULATIONS ON BECOMING AN EASIEST SYSTEM EVER MEMBER!</div>
      <mdb-card>

        <mdb-card-header class="info-color white-text text-center ">
          <h5  style="position:relative;padding:0px 0px 0px 0px">
            <div style="font-size:1.7rem"><strong>Please Create Your Account Below</strong></div>
            <div>
                Your credit card statement will show a charge from CLKBANK*<br>
                QUESTIONS? <a href="/contact" target="_blank" style="color:#fff"><u>Click Here to Contact Support</u></a>
            </div>
        </h5>
        </mdb-card-header>


        
          <mdb-card-body>
           
            <form [formGroup]="cardForm" (ngSubmit)="onSubmit()">
              
        
              <div class="md-form">
              
                <input type="text" id="firstname" formControlName="firstname" class="form-control" mdbInput>
                <label for="firstname" class="font-weight-light">First Name</label>
                <div *ngIf="f.firstname.touched && f.firstname.invalid" class="alert alert-danger">
                  <div *ngIf="f.firstname.errors && f.firstname.errors.required">First Name is required.</div>
                </div>
              </div>

              <div class="md-form">
                
                <input type="text" id="lastname" formControlName="lastname" class="form-control" mdbInput>
                <label for="lastname" class="font-weight-light">Last Name</label>
                <div *ngIf="f.lastname.touched && f.lastname.invalid" class="alert alert-danger">
                  <div *ngIf="f.lastname.errors && f.lastname.errors.required">Last Name is required.</div>
                </div>
              </div>
              
              <div class="md-form">
                
                <input type="text" id="address" formControlName="address" class="form-control" mdbInput>
                <label for="address" class="font-weight-light">Address</label>
                <div *ngIf="f.address.touched && f.address.invalid" class="alert alert-danger">
                  <div *ngIf="f.address.errors && f.address.errors.required">Address is required.</div>
                </div>
              </div>
              
              <div class="md-form">
                
                <input type="text" id="address2" formControlName="address2" class="form-control" mdbInput>
                <label for="address2" class="font-weight-light">Address 2</label>
              
              </div>

              <div class="form-row">
                <div class="md-form  col-md-4">
                
                  <input type="text" id="city" formControlName="city" class="form-control " mdbInput>
                  <label for="city" class="font-weight-light">City</label>
                  <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger">
                    <div *ngIf="f.city.errors && f.city.errors.required">City is required.</div>
                  </div>
                </div>

                <div class="md-form  col-md-4">
                
                  <input type="text" id="state" formControlName="state" class="form-control " mdbInput>
                  <label for="state" class="font-weight-light">State</label>
                  <div *ngIf="f.state.touched && f.state.invalid" class="alert alert-danger">
                    <div *ngIf="f.state.errors && f.state.errors.required">State is required.</div>
                  </div>
                </div>

                <div class="md-form  col-md-4">
                
                  <input type="text" id="zip" formControlName="zip" class="form-control " mdbInput>
                  <label for="zip" class="font-weight-light">Zip</label>
                  <div *ngIf="f.zip.touched && f.zip.invalid" class="alert alert-danger">
                    <div *ngIf="f.zip.errors && f.zip.errors.required">Zip is required.</div>
                  </div>
                </div>
              </div>

              <div class="md-form">
                <mdb-icon fas icon="envelope" class="prefix blue-text"></mdb-icon>
                <input type="text" id="email" formControlName="email" class="form-control " mdbInput>
                <label for="email" class="font-weight-light">Email</label>
                <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                  <div *ngIf="f.email.errors && f.email.errors.required">Email is required.</div>
                  <div *ngIf="f.email.errors.email && f.email.errors.email">Email is not in the correct form.</div>
                </div>
              </div>

              <div class="md-form">
                <mdb-icon fas icon="user" class="prefix blue-text"></mdb-icon>
                <input type="text" id="phone" formControlName="phone" class="form-control " mdbInput>
                <label for="phone" class="font-weight-light">Phone</label>
                <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
                  <div *ngIf="f.phone.errors && f.phone.errors.required">Phone is required.</div>
                </div>
              </div>
              
              

              <div class="md-form">
                <mdb-icon fas icon="user" class="prefix blue-text"></mdb-icon>
                <input type="text" (keyup)="checkUsername($event)" id="username" formControlName="username" class="form-control" mdbInput>
                <label for="username" class="font-weight-light">Username</label>
                <div *ngIf="validUserName" class="alert alert-danger">Username is taken.</div>
                <div *ngIf="f.username.touched && f.username.invalid" class="alert alert-danger">
                  <div *ngIf="f.username.errors && f.username.errors.required">Username is required.</div>
                  
                </div>
              </div>
        
              
              <div class="md-form">
                <mdb-icon fas icon="key" class="prefix blue-text"></mdb-icon>
                <input type="password" id="password" formControlName="password" class="form-control" mdbInput>
                <label for="password" class="font-weight-light">Password</label>
                <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                  <div *ngIf="f.password.errors && f.password.errors.required">Password is required.</div>
                </div>
              </div>

             
              
              <div class="text-center py-4 mt-3">
                <button  class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit"  mdbWavesEffect [disabled]="!cardForm.valid">Sign Up</button>
              </div>
            </form>

          
              <p>By clicking
                  <em>Sign in</em> you agree to our
                  <a [routerLink]="['/terms']" target="_blank">terms of service</a> and
                  <a [routerLink]="['/income-disclaimer']" target="_blank">income disaclaimer</a>. </p>
              <!-- Social login -->
          
          </mdb-card-body>
        </mdb-card>
        
  </div>
  
</div>
<div style="width:100%;color:#ccc;text-align:center">{{network}}&nbsp;{{sponsor}}</div>
<!-- Material form login -->