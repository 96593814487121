import { Injectable, EventEmitter } from '@angular/core';
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";
import 'rxjs/Rx';
import {Record} from "./record";
import {Crm} from "./crm";



@Injectable()
export class CrmService {
  crmIsEdit = new EventEmitter<Crm>();
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  editCrm(crm: Crm) {
    this.crmIsEdit.emit(crm);
  }

  sendTemplateEmail(emailOptions){
    const body = JSON.stringify(emailOptions);
    console.log(body);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    console.log(token)
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post(this.hostName +'/api2/crm/sendtemplateemail', body, {headers: headers})
      
  }

  sendEmail(email){
    const body = JSON.stringify(email);
   
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/sendEmail' + token, body, {headers: headers})
      
  }

  getRecords() {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/getallrecords'+token, body, {headers: headers})
      
  }

  getRecord(data) {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify(data);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/crm/getrecord/'+data+token, {headers: headers})
      
  }

  getRecordByUser(data) {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify(data);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/crm/getrecordbyuser/'+data+token, {headers: headers})
      
  }

  getRecordsByDomain() {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/getallrecordsbydomain'+token, body, {headers: headers})
      
  }

  getRecordsByDomainV2(){
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    console.log('hello')
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.http.post('/api2/crm/getallrecordsbydomain' , {headers: headers});
  }

  getallcrmsbystatus(status) {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username, status:status});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/getallcrmsbystatus'+token, body, {headers: headers})
     
  }

  updateField(record: Record){
    const body = JSON.stringify(record);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/updatefield' + token, body, {headers: headers})
     
  }

  updateRecord(crm: Crm){
    const body = JSON.stringify(crm);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/crm/updaterecord/'+crm._id + token, body, {headers: headers})
     

  }

  addRecord(crm: Crm) {
    const body = JSON.stringify(crm);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/crm/addrecord' + token, body, {headers: headers})
    
  }

}
