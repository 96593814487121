<div class="container">
  <section class="col-mat-8 col-mat-offset-2">
      <div class="ral-48blue">Your Account Information</div>
    <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
        <input name="roles"  #roles="ngModel" [ngModel]="user?.roles" type="hidden" id="roles" placeholder="roles">
        <input name="domain"  #domain="ngModel" [ngModel]="user?.domain" type="hidden" id="domain" placeholder="domain">

        <div class="form-group" style="width:100%">
          <label>Username</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="ban"></mdb-icon></span>
            </div>
            <input style="background-color:#ccc"   name="username" #username="ngModel" [ngModel]="user?.username" id="username" type="text" class="form-control form-elements" aria-label="With textarea" readonly>
          </div>
        </div>
      
        <div class="form-group" style="width:100%">
          <label>First Name</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="firstname" #firstname="ngModel" [ngModel]="user?.firstname" type="text" id="firstname"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Last Name</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="lastname" #lastname="ngModel" [ngModel]="user?.lastname" type="text" id="lastname"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Email</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="email" #email="ngModel" [ngModel]="user?.email" type="text" id="email"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Address</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="address" #address="ngModel" [ngModel]="user?.address" type="text" id="address"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Address 2</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="address2" #address2="ngModel" [ngModel]="user?.address2" type="text" id="address2"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>City</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="city" #city="ngModel" [ngModel]="user?.city" type="text" id="city"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>State</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="state" #state="ngModel" [ngModel]="user?.state" type="text" id="state"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Postal Code</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="zip" #zip="ngModel" [ngModel]="user?.zip" type="text" id="zip"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  
        
        <div class="form-group" style="width:100%">
          <label>Country</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="country" #country="ngModel" [ngModel]="user?.country" type="text" id="country"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Phone</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="phone" #phone="ngModel" [ngModel]="user?.phone" type="text" id="phone"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Gmail Username</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="gmail_username" #gmail_username="ngModel" [ngModel]="user?.gmail_username" type="text" id="gmail_username"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Gmail Password</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="gmail_password" #gmail_password="ngModel" [ngModel]="user?.gmail_password" type="text" id="gmail_password"  class="form-control form-elements" aria-label="With textarea"  required>
          </div>
        </div>  

        

      <button type="submit" class="btn btn-primary">Update Account Information</button>&nbsp;<a (click)="changePass()"  class="white">Change Password</a>
    </form>

  </section>
</div>

<!-- New PAss Modal -->
<div mdbModal class="modal fade right" #passModal="mdbModal" id="editModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog modal-full-height modal-right modal-notify modal-info modal-lg " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="color:#fff">Change Password</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="passModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group" style="width:100%">
          <label>Please enter your new password.</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="newpass" #newpass="ngModel" [(ngModel)]="newPassWord" [type]="passwordType" id="newpass"  class="form-control form-elements" aria-label="With textarea"  required>
            <button style="border:none" (click)="changePasswordType()"><img style="width:30px;height:30px" src="/assets/images/view.png"></button>
          </div>
        </div>  

        <div class="form-group" style="width:100%">
          <label>Please enter your new password again.</label>
          <div class="input-group" style="width:100%">
              
            <div class="input-group-prepend">
              <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
            </div>
            <input style="" name="newpassagain" #newpassagain="ngModel" [(ngModel)]="newPassWordAgain" [type]="passwordAgainType" id="newpassagain"  class="form-control form-elements" aria-label="With textarea"  required>
            <button style="border:none" (click)="changePasswordAgainType()"><img style="width:30px;height:30px" src="/assets/images/view.png"></button>
          </div>
        </div>  
        <button type="button" class="btn btn-primary" (click)="passModal.hide()">Cancel</button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="confirmChangePassword()">Change Password</button>
    </div>
  </div>
 
</div>
<!-- End New Pass Modal -->

