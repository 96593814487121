import {Injectable, EventEmitter} from '@angular/core';
import {Emailsection} from "./emailsection";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class EmailsectionService {
  private hostName = window.location.protocol + '//' + window.location.host;
  emailsections: Emailsection[] = [];
  emailsectionIsEdit = new EventEmitter<Emailsection>();
  constructor(private http: HttpClient) { }

  editEmailsection(emailsection: Emailsection) {
    console.log(emailsection)
    this.emailsectionIsEdit.emit(emailsection);
  }

  addRecord(emailsection: Emailsection) {
    const body = JSON.stringify(emailsection);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/emailsection/addemail' + token, body, {headers: headers})
     
  }

  deleteEmail(emailsection: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/emailsection/deleterecord/' + emailsection._id + token, {headers: headers})
     
  }

  getAllEmails() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/emailsection/getallemails'+token,  {headers: headers})
      
  }

  updateRecord(emailsection: Emailsection){
    const body = JSON.stringify(emailsection);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/emailsection/updaterecord/' + emailsection.emailId + token, body, {headers: headers})
      

  }

  getEmail(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/emailsection/getemail/'+id+token,  {headers: headers})
      
  }

}
