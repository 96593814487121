import {Component, ViewContainerRef, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {Record} from "./record";
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {CrmService} from "../crm/crm.service";
import { ToastService } from 'ng-uikit-pro-standard';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'select-cell',
  template: `<Select ngDefaultControl name="status" #select (change)="onChange($event)" [(ngModel)]="value"><option value="Completed">Completed</option><option value="Called">Called</option></Select>`
})
export class SelectEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any;
  private databaseRecord:string;
  private columnName: any;
  private cancelBeforeStart: boolean = false;
  record: Record[] = [];
  SelectedValue: string = null;
  @ViewChild('status', {read: ViewContainerRef, static:true}) public input;
  constructor(private leadsService: CrmService, private _service: ToastService){}

  ngOnInit() {
  }

  agInit(params: any): void {
    console.log(params);
    this.params = params;
    this.value = this.params.value;
    this.databaseRecord=this.params.node.data.id;
    this.columnName = this.params.column.colDef.field;
  }

  getValue(): any {
    console.log(this.value);
    console.log(this.databaseRecord);
    console.log(this.columnName);
    const record = new Record(this.columnName, this.value, this.databaseRecord)
    console.log(record);
    this.leadsService.updateField(record)
      .subscribe(
        data => {
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error('Error', error)
      ),
      error => this._service.error('Error', error);
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  };

  cellEditingStopped(event){
    //console.log(event.stopEditing.node.data.id);
    console.log(event);
  }

  onChange(event): any {
    console.log(this.databaseRecord);
    console.log(this.columnName);
    console.log(this.value);
    const record = new Record(this.columnName, this.value, this.databaseRecord)
    console.log(record);
    this.leadsService.updateField(record)
      .subscribe(
        data => {
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error('Error', error)
      ),
      error => this._service.error('Error', error);
    return this.value;
  }

  onKeyDown(event): void {
    return null;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {

    //this.input.element.nativeElement.focus();
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
  }

}
