import { Component, OnInit } from '@angular/core';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {Systempreference} from "../shared/systempreference/systempreference";
@Component({
  selector: 'app-affiliatehome1',
  templateUrl: './affiliatehome1.component.html',
  styleUrls: ['./affiliatehome1.component.css']
})
export class Affiliatehome1Component implements OnInit {

  username;
  webinarid;
  webinarlink;
  mpd_link;
  constructor(private systempreferenceService:SystempreferenceService, private _service: ToastService) { 
    const systempreference1 = new Systempreference(null, "webinar_link", null, null);
      this.systempreferenceService.getKey(systempreference1)
        .subscribe(
          data => {
            this.webinarlink=data['obj'].value;
            const systempreference = new Systempreference(null, "webinarid", null, null);
              this.systempreferenceService.getKey(systempreference)
                .subscribe(
                  webinarID => {
                    console.log(webinarID['obj'].value);
                    this.webinarid=webinarID['obj'].value;
                    const systempreference2 = new Systempreference(null, "mpd_link", null, null);
                    this.systempreferenceService.getKey(systempreference2)
                      .subscribe(
                        mpdLink => {
                          console.log(mpdLink['obj'].value);
                          this.mpd_link=mpdLink['obj'].value;
                          this.webinarlink=this.webinarlink.replace(/\[WEBINARID\]/g, this.webinarid );
                          this.webinarlink=this.webinarlink.replace(/\[USERNAME\]/g, this.username );
                          this.webinarlink=this.webinarlink.replace(/\[MPD_LINK\]/g, this.mpd_link );
                          this.webinarlink=this.webinarlink.replace(/\[PRODUCT\]/g, '220' );
                        },
                        error => {
                          this._service.error(error.error['message'], error['title']);
                        }
                      );
                  },
                  error => {
                    this._service.error(error.error['message'], error['title']);
                  }
                );
            console.log(this.webinarlink);
          },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
        );
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

}
