import { Component, OnInit, EventEmitter } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { Domains } from "./domains";
import {DomainsService} from "./domains.service";
import {GridOptions} from "ag-grid-community";
import {DomainsInputComponent} from "./domains-input/domains-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import {ClipboardService} from "../../../services/clipboard.service";
import { DomainsStepperComponent } from './domains-input/domains-stepper.component';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { TypesService } from '../types/types.service';
import { CategoriesService } from '../categories/categories.service';
import { SubtypesService } from '../subtypes/subtypes.service';
import { TemplatesService } from '../templates/templates.service';
import { TemplatesInputComponent } from '../templates/templates-input/templates-input.component';

@Component({
  selector: 'app-mydomains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class MyDomainsComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  clipUrl:string = "dave";
  visiblityState = 'hidden';
  messageTitle;
  messageBody;
  icon;
  step:String=null; 
  showWizard='false';
  homepageButton="Create Homepage";
  categories;
  subtypes;
  types;
   /*Clipboard Stuff*/
   public copyEvent: EventEmitter<string>;
   public errorEvent: EventEmitter<Error>;
   
   /*End Clipboard Stuff*/

  public gridOptions: GridOptions;
  constructor(private templatesService: TemplatesService, private subtypesService: SubtypesService, private typesService: TypesService, private categoriesService: CategoriesService, private route: ActivatedRoute,private notificationService:NotificationsService, private clipboardService: ClipboardService, private confirmService: ConfirmService, private domainsService:DomainsService,  private authService: AuthService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Domain Id", field: "_id", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Name", field: "name", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Domain", field: "domain",cellRenderer: this.renderDomain, editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Associated Niche(s)", field: "niche", cellRenderer: this.renderNiche, editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Configured on ESE", field: "heroku_domain_status", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Cname", field: "heroku_cname", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Server", field: "server", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Configured at your Domain DNS Provider", field: "heroku_acm_status", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Template", field: "template", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "created_at", field: "created_at", cellRenderer: this.renderDate, cellStyle: this.changeRowColor, sort: 'desc', editable: true, sortable: true, filter:true, resizable:true},
    {headerName: "updated_at", field: "updated_at", cellRenderer: this.renderDate, cellStyle: this.changeRowColor, editable: true, sortable: true, filter:true, resizable:true},
      {
        headerName: "Actions", cellRenderer: this.renderActions },

    ];

    this.showWizard=this.route.snapshot.queryParams.wizard;

    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.domainsService.getAllMyRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
         // this.notificationService.updateMessage(data['title'],data['message'],'success', 'check_circle',function(){});
        },
        error => this.notificationService.updateMessage(error['title'],error.error['message'],'warn', 'warning', 5000,function(){})
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  renderNiche(params){
    if (!params.value){return}
    var myDomains = params.value.replace('[','');
    myDomains = myDomains.replace(']','');
    myDomains = myDomains.replace(/"/g, '');
    myDomains=myDomains.split(",")
    
    var html='';
    
    myDomains.forEach(domain => {
      if (domain){
        var tmpDomain=domain.split('|');
        html = html +
        `<img src="/assets/images/green-check-bubble.png" style="width:15px;height:15px">&nbsp;${tmpDomain[1]}<br>`
      }
      
    })
    
    return html;
  }

  renderActions(params){
    
    var homepageButton
    if (params.data.template){
      homepageButton=`<button md-button color="primary" type="button" data-action-type="editHomepage" style="height:25px;font-size:8pt;background-color:transparent;border:none;">
      <img src="/assets/images/create-new.png" style="width:15px;height:15px;">&nbsp;Edit Homepage
      </button>`
    } else {
      homepageButton=`<button md-button color="primary" type="button" data-action-type="createHomepage" style="height:25px;font-size:8pt;background-color:transparent;border:none;">
      <img src="/assets/images/create-new.png" style="width:15px;height:15px;">&nbsp;Create Homepage
      </button>`

    }
    var template=`<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
         </button>
        <br>
         <button md-button color="primary" type="button" data-action-type="status" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/refresh.png" style="width:15px;height:15px;">&nbsp;Refresh Status
         </button>
         <br>
         <button mat-button color="primary" type="button" data-action-type="copy" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
          <img src="https://esev2.s3.amazonaws.com/images/primary-copy.png" style="width:15px;height:15px;">&nbsp;Copy CNAME
          </button>
          <br>
         <button md-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
         </button>
         <br>
         ${homepageButton}
         <br>
         <button md-button color="primary" type="button" data-action-type="preview" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/view.png" style="width:15px;height:15px;">&nbsp;Open Page
         </button>`;
    return template;
  }

  changeRowColor(params){

  }

  renderDomain(params){
    var domain='<a href="https://'+params.value+'" target="_blank">https://'+params.value+'</a>';
    return domain;
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "status":
          return this.onActionStatusClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
        case "copy":
          return this.onActionCopyClick(data, "myContainer");
        case "preview":
          return this.onActionPreviewClick(data);
        case "createHomepage":
          return this.onActionCreateHomepageClick(data);
        case "editHomepage":
          return this.editTemplate(data);
      }
    }
  }

  public onActionCreateHomepageClick(data: any){

    if (data.template){
      return  this.notificationService.updateMessage("An Error Occured","This domain already has a homepage associated with it.",'warn', 'warning', 5000,function(){});
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"REALLY CREATE HOMEPAGE?", body:"THie will create a home page for this domain", icon:"check_circle", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.domainsService.createHomepage(data)
        .subscribe(
          data => {
            this.data = data;
            this.notificationService.updateMessage(data['title'],data['message'],'success', 'check_circle', 5000,function(){});
            this.refresh();
          },
          error => {
            console.log("error:",error);
            this.notificationService.updateMessage(error.error['title'],error.error['message']+" "+error.error['error'],'warn', 'warning', 5000,function(){});
            
          }
        );
      }
    });
  }

  public onActionPreviewClick(data: any){
    console.log(data);
    window.open("https://"+data.domain, "_blank");
  }

  public onActionCopyClick(data: any, containerid){

    //let index = data.url.indexOf('?');
    console.log(data);
    if (data.heroku_cname){

      let url = data.heroku_cname;
  
    
      this.clipboardService
          .copy( url )
          .then(
            ( value: string ) : void => {
              this.notificationService.updateMessage("Success","Data Copied",'success', 'check_circle', 5000,function(){});
            }
          )
          .catch(
            ( error: Error ) : void => {
            
              this.notificationService.updateMessage("Failed","Data Did Not Copy",'warn', 'warning', 5000,function(){})
            }
          );
    } else {
      this.notificationService.updateMessage("Failed","Data Did Not Copy",'warn', 'warning',  5000,function(){})
    }


  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(DomainsInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.domainsService.editDomains(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It? If you delete it, this domain will no longer function", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.domainsService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this.notificationService.updateMessage(data['title'],data['message'],'success', 'check_circle', 5000,function(){});
              this.refresh();
            },
            error => this.notificationService.updateMessage(error['title'],error.error['message'],'warn', 'warning',  5000,function(){})
          );
      }
    });

  }

  public onActionStatusClick(data: any){
    console.log(data);
    this.domainsService.checkStatus(data)
    .subscribe(
      data => {
       // var body = JSON.parse(data._body);
      //  console.log("data:",JSON.parse(data._body));
      console.log(data);
        this.refresh();
         // this._service.success(body['title'], body['message']);
       // f.reset();
      },
     // error => this.showMessage(error['title'],error['message'],'warn', 'warning',function(){})

    ) 

  }

  refresh(){
    this.domainsService.getAllMyRecords()
      .subscribe(
        data => {
          this.data = data['obj']
          //this.notificationService.updateMessage(data['title'],"Domains Information Updated",'success', 'check_circle',function(){});
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this.notificationService.updateMessage(error['title'],error.error['message'],'warn', 'warning',  5000,function(){})
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(DomainsInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  wizard(){
    let dialogRef = this.dialog.open(DomainsStepperComponent, {
      height: '90vh',
      width: '80%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  editTemplate(data){
    console.log(data);
    this.templatesService.getRecord(data.template)
    .subscribe(
      data => {
       console.log("my data:",data)
       var myData=data['obj'];
       let dialogRef = this.dialog.open(TemplatesInputComponent, {
      
        width: '95%',
        height: '95vh'
  
      });
      //dialogRef.componentInstance.lead = this.crmService.editLead(data);
      console.log(myData);
      console.log('categories',this.categories)
      this.templatesService.editTemplates(myData);
      this.templatesService.setType(this.types);
      this.templatesService.setSubType(this.subtypes);
      this.templatesService.setCategory(this.categories);

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
      });
  
      },
       error => {
         console.log(error);
         this.notificationService.updateMessage(error.error['title'],error.error['message'],'warn', 'warning',  5000,function(){})
       }
    );

    
  }



  ngOnInit() {
    if (this.showWizard=='true'){
      this.wizard();
    }
    
  }

  ngAfterViewInit() {
    this.categoriesService.getAllRecords()
    .subscribe(
      data => {
        this.categories = data['obj'];
      }
    );

    this.typesService.getAllRecords()
      .subscribe(
        data1 => {
          this.types = data1['obj'];
          //console.log(this.types);
          
        },
        error => {
         
        },
      );

    this.subtypesService.getAllRecords()
    .subscribe(
      data2 => {
        this.subtypes = data2['obj'];
        console.log(this.subtypes);
      },
      error => {
        
      },
    );
  }

}
