<div class="center top-spacer">
    <span class="t2 bold">YOUR AFFILIATE LINK IS:</span>
    <br/>
    <a class="t3 bold" href="{{webinarlink}}" target="_blank">{{webinarlink}}</a>
    <p class="bold"></p>
  <br/><br/>
  <section style="margin:auto;width:90%">
      <p style="font-size:18pt;color:#c03;font-weight:bold">
      PROMOTIONAL TOOL #1 (EMAIL)
      </p>
      <br>
      <p class="body-txt">
        You can use the following emails to send to your list of subscribers who are interested in making money online.
      </p>
  </section>
  
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 1
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: Copy This $347/Day System In JUST 6 MINUTES
            <br><br>
            I know...<br>
            <br>
            It sounds crazy <br>
            <br>
            To be able to copy a fully-automated $347/day system in just 6 minutes.<br>
            <br>
            So I'll tell you what...<br>
            <br>
            Don't take my word for it<br>
            <br>
            There's an online demo that's about to start<br>
            <br>
            So go ahead and see for yourself<br>
            <br>
            Click here to grab your seat for the demo<br>
            ==> {{webinarlink}} <br>
  
  
        
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 2
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: 3 Steps + 6 Minutes = PayPal Commissions<br>
            <br>
Here's what this is about<br>
<br>
A fully automated system...<br>
<br>
That can be completed in just 3 simple steps.<br>
<br>
And the steps... they only take 6 quick minutes to do!<br>
<br>
This is EASIER THAN "COPY & PASTE"<br>
<br>
But donít take my word for it <br>
<br>
Check out the online demonstration and see for yourself<br>
==> {{webinarlink}} <br>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 3
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: No, No, No, No, No,<br>
            <br>
            <br>
            NO technical experience needed AT ALL!!!<br>
            NO "downline" or "qualifying sales" needed<br>
            NO prior experience necessary<br>
            NO having to build your own site or blog<br>
            NO SEO or copywriting skills necessary<br>
            NO - it doesn't matter where you live<br>
            NO creating your own products<br>
            NO needing to be an expert at anything<br>
            NO shipping physical products<br>
            <br><br>
            YES - this only takes 6 MINUTES TO SET UP<br>
            <br>
            Interested? Click here<br>
            ==> {{webinarlink}} <br>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 4
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: Online DEMONSTRATION About to Start<br>
            <br>
            Want to know how to copy a $347/day system in LITERALLY 6 minutes flat?<br>
            <br>
            If so, there's an online demo that's about to start.<br>
            <br>
            You can grab your spot for the demo here<br>
           
==> {{webinarlink}}<br> 
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 5
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: This gets you results, or your money back...<br>
            <br>

I'm going to keep this simple <br>
<br>
What you're about to discover is GUARANTEED to get you results - or your money back<br>
==> {{webinarlink}} <br>
<br>
Here's what to do<br>
Step 1: Watch this life-changing online training right now<br>
Step 2: Get started <br>
Step 3: Get results, or your money back <br>
<br>
You can potentially start seeing people on your list and seeing your first commission <br>
notifications THIS WEEK<br>
<br>
Click here to continue >>><br>
===> {{webinarlink}}<br> 
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 6
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: IMPORTANT<br>
            <br>

I had to let you know about this. <br>
<br>
It's THAT important.<br>
<br>
This new system is LITERALLY changing lives. <br>
<br>
It's brand new, so only a handful of people even know about it.<br>
<br>
And the BEST PART...<br>
<br>
This system can be set up in just 6 MINUTES FLAT!<br>
<br>
Click here to check out the online demonstration<br>
==> {{webinarlink}}<br>
<br>

This this system requires...<br>
<br>
NO technical experience needed AT ALL!!!<br>
NO "downline" or "qualifying sales" needed<br>
NO prior experience necessary<br>
NO having to build your own site or blog<br>
NO SEO or copywriting skills necessary<br>
NO - it doesn't matter where you live<br>
NO creating your own products<br>
NO needing to be an expert at anything<br>
NO shipping physical products<br>
<br>
I know, it almost sounds too good to be true. <br>
<br>
But it's NOT!<br>
<br>
Check out the online demo for yourself and see you can copy this EXACT system in LITERALLY 6 MINUTES!<br>
==> {{webinarlink}}<br> 
        </div>
      </mat-tab>
      <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>mail</mat-icon> Email 7
          </ng-template>
          <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
              SUBJECT: The 6 MINUTE System - (Online Demo)<br> 
              <br> 
Have you seen the demonstration of this system yet?<br> 
<br> 
If not, you need to check it out<br> 
==> {{webinarlink}}<br> 
<br> 
Here's the deal...<br> 
<br> 
There's a hot new system that people are JUST NOW finding out about <br> 
<br> 
It requires zero experience, zero tech skills, and zero having to do any "hard stuff"<br> 
<br> 
But here's the kicker...<br> 
<br> 
You can do EVERYTHING you need to do in order to start getting results with this system, in LITERALLY 6 minutes.<br> 
<br> 
During the demonstration, you're actually going to get to see the 6 Minute Fast Track process for yourself.<br> 
<br> 
So go ahead and grab your spot for the online demo now, and get all the details of this system for yourself!<br> 
<br> 
==> {{webinarlink}}<br>
          </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>mail</mat-icon> Email 8
            </ng-template>
            <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
Checkout the online demo for yourself here<br> 

<br> 
If you're a former member of MOBE, Empower, or Digital Altitude, you need to see this<br> 
<br> 
==> [{{webinarlink}}<br> 
<br> 
It's got many of the benefits of those systems, but without some of the draw backs.<br> 
<br> 
It's simple, easy, and it only takes 6 MINUTES to get set up.<br> 
<br> 
Checkout the online demo for yourself here<br> 
<br> 
==> {{webinarlink}}<br> 
            </div>
          </mat-tab>
          <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon>mail</mat-icon> Email 9
              </ng-template>
              <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                  SUBJECT: The END of the Information Age.<br>
                  <br>
It's true <br>
<br>
The information age is coming to an end.<br>
<br>
Want to know why?<br>
<br>
It's because there's LITEREALLY more information than you could ever possibly learn - yet alone implement.<br>
<br>
So what does that mean?<br>
<br>
It means that information, while still a commodity, is less valuable than it was 50 years ago.<br>
<br>
It also means that a shift is happening.<br>
<br>
No longer are those who are most successful paying for INFORMATION...<br>
<br>
Instead, they're paying for CLARITY, and for SYSTEMS that deliver ACTUAL RESULTS. <br>
<br>
Systems like this one<br>
==> {{webinarlink}} <br>
<br>
So if someone is still trying to sell you information, just know that they are behind the times - still selling yesterdayís newspaper.<br>
<br>
Truth be told, less than 2% of the population even realize this.<br>
<br>
So, congratulations, you're now "in the know" - as they say.<br>
<br>
The next question is, what you do about it.<br>
<br>
Here's the answer <br>
<br> 
==> {{webinarlink}} 
<br> 
              </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon>mail</mat-icon> Email 10
                </ng-template>
                <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                    SUBJECT: you coming?<br> 
                    <br> 
Are you coming?<br> 
<br> 
We're about to start the online demo that reveal how to copy a $347/day system <br> 
just 6 MINUTES FLAT!<br> 
<br> 
Grab your spot here<br> 
==> {{webinarlink}}<br> 
<br> 
On this free training, you'll also discover...<br> 
<br> 
1. THE HIDDEN TRUTH REVEALED: The real reason 99% of ìmake-moneyî systems donít work. (And The 1 and only fix that GUARANTEES RESULTS).<br> 
<br> 
2. The Never Before Seen $347/Day System that literally deposits money into my bank account even when Iím sleeping or on vacation<br> 
<br> 
3. The Super Simple Fast Track Process that allows you to ACTIVATE the system in JUST 6 QUICK MINUTES!<br> 
<br> 
So, are you ready?<br> 
<br> 
Grab your spot for the online demo here<br> 
<br> 
==> {{webinarlink}}<br> 

<br> 
                </div>
              </mat-tab>
              
                    
  
    </mat-tab-group>
  </div>
  
  <br><br>
  <section style="margin:auto;width:90%">
    <p style="font-size:18pt;color:#c03;font-weight:bold">
    PROMOTIONAL TOOL #2 (BANNER ADS)
    </p>
    <br>
    <p class="body-txt">
    Use the following pre-made banners in as many places as you can on the Internet. Simply copy and paste the banners/banner code you'd like to use form the choices below. You can advertise with banner ads on many different websites a Including: <a href="http://TheBannerExchange.net" target="_blank">http://TheBannerExchange.net</a>, <a href="http://AdBrite.com" target="_blank">http://AdBrite.com</a>, <a href="http://ExchangeAd.com" target="_blank">http://ExchangeAd.com</a>, <a href="http:///TheMediaCrew.com" target="_blank">http:///TheMediaCrew.com</a>, <a href="http://www.Facebook.com/ads" target="_blank">http://www.Facebook.com/ads</a>, and <a href="http://www.AdReady.com" target="_blank">http://www.AdReady.com</a>.
    <br><br>
    And don't forget to place these banners all over <b><u>YOUR</u></b> website or blog!
    <br><br>
  
  
    </p>
    <br><br>
    <br>
  
    <div style="text-align:center">
      <span class="medium">468x60 Banner</span><br><br>
        <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-468x60.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-468x60.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">120x600 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-120x600.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-120x600.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">160x600 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-160x600.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-160x600.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">200x200 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-200x200.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-200x200.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">728x90 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-728x90.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-728x90jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">300x250 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-300x250.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-300x250.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">250x250 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese2-250x250.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese2-250x250.jpg" border="0"></a>
      </textarea><br><br><br>
      
      
      
    </div>
  </section>