import { Component, OnInit } from '@angular/core';
import { Emailsection } from "../emailsection";
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {EmailsectionService} from "../emailsection.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-emailsection-input',
  templateUrl: './emailsection-input.component.html',
  styleUrls: ['./emailsection-input.component.css']
})
export class EmailsectionInputComponent implements OnInit {

  emailsection:Emailsection = null;

  constructor(private emailsectionService:EmailsectionService, private authService: AuthService, private _service: ToastService) {

    emailsectionService.emailsectionIsEdit.subscribe(
       emailsection => {
       this.emailsection = emailsection;
     })
  }

  save(f:NgForm, model: Emailsection, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.emailId){

      this.emailsectionService.updateRecord(model)
       .subscribe(
       data => {
       console.log(data);
       this._service.success(data['message'], data['title'])
       },
       error => this._service.error(error['message'], error['title']),

       )
       console.log(model, isValid);
    } else {

      this.emailsectionService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }


  ngOnInit() {

  }



}
