import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {SupportService} from "../support.service";
import {Support} from "../support";
import {NgForm} from "@angular/forms";
import { StatusService } from '../../status/status.service';

@Component({
  selector: 'app-support-add',
  template: `
      <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
        
      <mat-dialog-content>
        <input type="hidden" name="supportId" #supportId="ngModel" [ngModel]="support?._id" id="supportId">
        <input type="hidden" name="username" #username="ngModel" [ngModel]="support?.username"  id="username">

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Please Enter Yiour First Namel</mat-label>
          <input matInput name="firstname" #firstname="ngModel" [ngModel]="support?.firstname" type="text" id="firstname" placeholder="Please Enter Your First Name" required>
          <mat-icon matSuffix>edit</mat-icon>
          <mat-error>This is a required field</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Please Enter Your Last Name</mat-label>
          <input matInput name="lastname" #lastname="ngModel" [ngModel]="support?.lastname" type="text" id="lastname" placeholder="Please Enter Your Last Name" required>
          <mat-icon matSuffix>edit</mat-icon>
          <mat-error>This is a required field</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Please Enter Your Email</mat-label>
          <input matInput name="email" #email="ngModel" [ngModel]="support?.email" type="text" id="email" placeholder="Please Enter Your Email">
          <mat-icon matSuffix>edit</mat-icon>
        </mat-form-field>


        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Subject of your Message</mat-label>
          <input matInput name="subject" #subject="ngModel" [ngModel]="support?.subject" type="text" id="subject" placeholder="Subject of your Message">
          <mat-icon matSuffix>edit</mat-icon>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>What is The Message for Support</mat-label>
          <textarea matInput name="message" #message="ngModel" [ngModel]="support?.message" type="text" rows="10" id="message" placeholder="What is The Message for Support"></textarea>
          <mat-icon matSuffix>edit</mat-icon>
        </mat-form-field>

        
      

        <br><br>


      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="userActionButtonGroup">
          <button mat-flat-button  color="primary" type="submit" [disabled]="!f.valid">
            <mat-icon  class="material-icons">done</mat-icon>&nbsp;Save
          </button>&nbsp;
          <button mat-flat-button color="primary" mat-dialog-close>
            <mat-icon  class="material-icons">cancel</mat-icon>&nbsp;Cancel
          </button>
        </div>
      </mat-dialog-actions>
    </form>
    
    <app-notifications></app-notifications>
  `
  
  ,
  styleUrls: ['./support-input.component.css']
})
export class SupportAddComponent implements OnInit {
  support:Support = null;
  username: string; 
  statuses;
  response:boolean=false;
  thread_id;
  new_status;
  defaultAvatar="https://esev2uploaded.s3.amazonaws.com/dsloan254/1570632315445.2131.YIRbHKaedM.no_avatar.jpg";
  avatar=null;
  user;

  constructor(private statusService: StatusService, private supportService:SupportService, private authService: AuthService, private _service: ToastService) {
    this.username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let user = localStorage.getItem('userRecord') ? localStorage.getItem('userRecord') : '';
    this.user=JSON.parse(user);
    this.avatar=this.user.avatar;
    supportService.supportIsEdit.subscribe(
      support => {
       
        this.support=support;
        
      })

  }

  

  save(f:NgForm, model: Support, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
     
        this.thread_id = Date.now() + Math.random() + "." + this.randomNumber();
        model.thread=this.thread_id;
        model.level="1";
        model.status=this.new_status._id;
        model.avatar = this.avatar;

      model.username = this.username;
      console.log(model);
      this.supportService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  ngOnInit() {
    this.statusService.getAllRecords()
      .subscribe(
        data => {
          this.statuses = data['obj'];
        }
      )

    this.statusService.getRecordsByName('New')
    .subscribe(
      data => {
        this.new_status = data['obj'];
        console.log("new",this.new_status)
      }
    )
      
  }

}
