export class Links {
  link: string;
  title: string;
  headline: string;
  wid: string;
  username: string;
  userId: string;
  linkId: string;
  page: string;
  autoredirect: boolean;
  redirecttimeout: string;
  linktext: string;
  _id: string;


  constructor (linkId?: string,link?: string, title?: string, wid?: string, headline?: string, username?: string, page?: string, autoredirect?: boolean, redirecttimeout?: string, userId?: string, linktext?: string, _id?: string) {
    this.link = link;
    this['title'] = title;
    this.linkId = linkId;
    this.headline = headline;
    this.username = username;
    this.wid = wid;
    this.page = page;
    this.autoredirect = autoredirect;
    this.redirecttimeout = redirecttimeout;
    this.userId = userId;
    this.linktext = linktext;
    this._id = _id;
  }
}
