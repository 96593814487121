import { Component, OnInit } from '@angular/core';
import { WebinarService } from '../webinar.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApplicationService } from './application.service';
import {Router} from "@angular/router";
import { SystempreferenceService } from '../../shared/systempreference/systempreference.service';
import { Systempreference } from '../../shared/systempreference/systempreference';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  application:any = null;
  default_eseid;
  eseid_check;
  loaded_eseid;
  set_eseid;
  showLoader=false;
  constructor(private systempreferenceService: SystempreferenceService, private router: Router, private applicationService: ApplicationService, private _service: ToastService ) { }

  ngOnInit() {
    this.loaded_eseid = localStorage.getItem('_ese_affiliate_id') ?  localStorage.getItem('_ese_affiliate_id') : this.default_eseid;
    this.set_eseid=this.loaded_eseid;
    
    const userRecord = JSON.parse('{"username":'+'"'+this.loaded_eseid+'"}');
    this.applicationService.verifyRecord(userRecord)
      .subscribe(
        data => {
          console.log(data);
          this.eseid_check = data['status'];
        },
        error => {
          //this._service.error(error.error['message'], error['title']);
        }
      );

    const systempreference = new Systempreference(null, "default_eseid", null, null);
    console.log(systempreference);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        data => {
          console.log(data['obj'].value);
          this.default_eseid = data['obj'].value;
        },
        error => {
          //this._service.error(error.error['message'], error['title']);
        }
      );
  }

  save(model: any, isValid: boolean) {
    //check to see if the value entered is actually a user.
    this.showLoader=true;
    if (this.eseid_check==0){
      this.set_eseid = this.default_eseid;
    } else {
      this.set_eseid = this.loaded_eseid;
    }
    
    model.affiliate_id=this.set_eseid;
    // check if model is valid
    // if valid, call API to save customer
    model.tid = localStorage.getItem('_ese_tid') ? localStorage.getItem('_ese_tid') : '';
   
    console.log(model);
    this.applicationService.addRecord(model)
      .subscribe(
        data => {
          console.log(data);
          this._service.success(data['message'], data['title'])
          this.showLoader=false;
          this.router.navigate(['/webinar/thankyou', {login: 'Success'}]);
          
        },
        error => {
          console.log("error = " + JSON.stringify(error));
          this._service.error(error['message'], error['title'])
        },

      )
    console.log(model, isValid);
  }

}
