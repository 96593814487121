import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.css']
})
export class MainmenuComponent implements OnInit {
  username;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

}
