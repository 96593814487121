import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {MenusService} from "../menus.service";
import {Menus} from "../menus";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-menus-input',
  templateUrl: './menus-input.component.html',
  styleUrls: ['./menus-input.component.css']
})
export class MenusInputComponent implements OnInit {
  menus:Menus = null;

  constructor(private menusService:MenusService, private authService: AuthService, private _service: ToastService) {
    menusService.menusIsEdit.subscribe(
      menus => {
        this.menus = menus;
        console.log(this.menus);
      })

  }

  save(f:NgForm, model: Menus, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.menusId){

      this.menusService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.menusService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
