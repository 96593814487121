<div class="container">
    <div class="row">
      <div style="width:95%;height:40px;padding-left:10px;">
        <div style="height:40px;">
          <button mat-flat-button color="primary" (click)='refresh(filterPath)'><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;  <button mat-flat-button color="primary" (click)="onCloseButtonClick($event)"><mat-icon>close</mat-icon> Cancel</button>&nbsp;
            <select class="browser-default custom-select" style="width:auto" [(ngModel)]="category" (change)="processFilter()">
              <option selected>Category</option>
              <option value="hb">Home Business</option>
              <option value="wl">Weight Loss</option>
              <option value="pd">Personal Development</option>
            </select>
            &nbsp;
            <select class="browser-default custom-select" style="width:auto" [(ngModel)]="imageType" (change)="processFilter()">
              <option selected>Type</option>
              <option value="optin">Optin</option>
              <option value="bridge">Bridge</option>
            </select>
            &nbsp;
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-4 mx-auto my-1"  *ngFor="let el of galleryData; let i = index" style="height:200px;overflow:hide;">
              <div class="view overlay zoom" style='width:100%;height:100%;background-repeat:no-repeat;background-size: 100% 100%;cursor:pointer' mdbWavesEffect (click)="clickImage($event,el)">
                <img src="{{el.url}}" style="width:100%;max-height:100%">
                <div class="mask flex-center rgba-blue-strongt">
                    <p class="white-text">{{el.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
  
  
  
  
  