import { Injectable, EventEmitter } from '@angular/core';
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";
import 'rxjs/Rx';
import {Record} from "./record";
import {Lead} from "./lead";



@Injectable()
export class MyapplicationsService {
  leadIsEdit = new EventEmitter<Lead>();
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  editLead(lead: Lead) {
    this.leadIsEdit.emit(lead);
  }

  sendEmail(email){
    const body = JSON.stringify(email);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/sendEmail' + token, body, {headers: headers})
     
  }

  getleads() {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/getallleads'+token, body, {headers: headers})
      
  }

  getallleadsbystatus(status) {
    const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let body=JSON.stringify({username:username, status:status});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/getallleadsbystatus'+token, body, {headers: headers})
     
  }

  updateField(record: Record){
    const body = JSON.stringify(record);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/updatefield' + token, body, {headers: headers})
      
  }

  updateRecord(lead: Lead){
    const body = JSON.stringify(lead);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/updaterecord/' + lead.id + token, body, {headers: headers})
      

  }

  addRecord(lead: Lead) {
    const body = JSON.stringify(lead);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/leads/addrecord' + token, body, {headers: headers})
     
  }

}
