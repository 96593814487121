//import component, ElementRef, input and the oninit method from angular core
import {Component, OnInit, ElementRef, Input, ChangeDetectorRef} from '@angular/core';


import {FilesService} from "../files.service";
//import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'ng-uikit-pro-standard';

const URL = '/api/upload/upload';

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.css']
})
export class FilesUploadComponent implements OnInit {
  url;
  thumbUrl;
  uploaded= true;
  sigingrequest;
  progress = false;
  public file_srcs: string[] = [];
  uploadedImage: File;
  imagePreview: string;

  constructor(
    public sanitizer: DomSanitizer, 
    //private ng2ImgMax: Ng2ImgMaxService, 
    private el: ElementRef, 
    private toastrService: ToastService, 
    private imagesService: FilesService, 
    private changeDetectorRef: ChangeDetectorRef
    ) { }

  // The next two lines are just to show the resize de;bug
  // they can be removed
  public debug_size_before: string[] = [];
  public debug_size_after: string[] = [];

  preUpload(){
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    let thumbEl: HTMLInputElement = this.el.nativeElement.querySelector('#thumb');
   // this.upload(inputEl);
  }

  onImageChange(event) {
    let image = event.target.files[0];
  
    //this.ng2ImgMax.resizeImage(image, 400, 300).subscribe(
    /*  this.ng2ImgMax.compressImage(image, 0.100).subscribe(
      result => {
        this.uploadedImage = new File([result], result.name);
        console.log(this.uploadedImage.type);
        this.getImagePreview(this.uploadedImage);
        
      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );*/
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      this.uploaded=false;
    };
  }

  upload(){
    this.progress=true;
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    console.log(inputEl);
    var fileType="image/jpeg";
    //var file = inputEl.files.item(0);
    var file = this.uploadedImage;
    console.log(file);
    let fileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
    this.imagesService.getSignedRequest(fileName, fileType)
      .subscribe(
        data => {
          this.sigingrequest=data;
          this.finalUpload(this.sigingrequest, file)
        },
        error => this.toastrService.error(error.error.message, error.title, {opacity:1}),
      );
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  finalUpload(data, file){
    this.imagesService.upload(data.signedRequest, file)
      .subscribe(
        data => {
          console.log(data);
          if (data['status']==200){
            this.progress=false;
            this.uploaded=true;
            this.imagesService.addRecord(data)
              .subscribe(
                data => {
                  console.log(data);
                }
              );
            this.toastrService.success("Success", "Thanks for Sharing", {opacity:1})
          }
        },
        error => {
          console.log(error)
          this.toastrService.error(error)
        },
      );
  }

  previewImage(event){
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e:any) => {
        var image = new Image();
        image.src = reader.result as string;
      
        this.url = e.target.result;

        image.onload = function() {
        };

      };

      reader.readAsDataURL(event.target.files[0]);
      this.uploaded=false;

    }
  }




  resize(img, MAX_WIDTH:number, MAX_HEIGHT:number, callback){
    // This will wait until the img is loaded before calling this function
    return img.onload = () => {

      // Get the images current width and height
      var width = img.width;
      var height = img.height;

      // Set the WxH to fit the Max values (but maintain proportions)
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // create a canvas object
      var canvas = document.createElement("canvas");

      // Set the canvas to the new calculated dimensions
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0,  width, height);

      // Get this encoded as a jpeg
      // IMPORTANT: 'jpeg' NOT 'jpg'
      var dataUrl = canvas.toDataURL('image/jpeg');

      // callback with the results
      callback(dataUrl, img.src.length, dataUrl.length);
    };
  }

  getAllRecords(){
    this.imagesService.getAllRecords()
    .subscribe(
      data => {
        console.log(data);
      }
    );
  }


  ngOnInit() {
    this.getAllRecords();
  }

}
