<div class="container main-container">
  <section class="col-mat-8 col-mat-offset-2">
    <form #f="ngForm" (ngSubmit)="onSubmit(f.value, f.valid)">
      <mat-form-field class="example-full-width">
          <input matInput name="email" #email="ngModel" (ngModel)="email" type="email" id="email" placeholder="Please Enter Your Email" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput name="username" #username="ngModel" (ngModel)="username" type="email" id="username" placeholder="Please Enter Your Username" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <br><br>
      <button mat-button type="submit" class="btn btn-primary" [disabled]="!f.valid">Request Password Change</button> <button mat-button type="button" class="btn btn-primary" (click)="back()">Cancel</button>
    </form>
    
  </section>
</div>
