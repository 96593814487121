<div class="container">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8" style="text-align:center;margin-top:20px;">
        <span class="red t5 bold" style="font-size:2em !important;">
            Introducing: A 100% Newbie Friendly Method Adds 1,000’s of Subscribers to Your Email List Each Month, & Generates Commissions of Up To $997 Per Sale - All on Complete Auto-Pilot!
        </span>
        <br><br>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/MPAU4pgl2dk?rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
      <br><br>
      <a routerLink="/webinar/i3kce4th59/devon">
        <img src="/assets/images/green-getstarted-btn.png" style="width:100%">
      </a>
      <br><br>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>