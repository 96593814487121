import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplatesService } from '../templates/templates.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-adminhelp',
  templateUrl: './adminhelp.component.html',
  styleUrls: ['./adminhelp.component.css']
})
export class AdminhelpComponent implements OnInit {
  page;
  myVar;
  sub;
  data;
  frameSrc;
  myquery;
  newsHtml;
  showNews=false;
  index;
  newsArray: any[]=[];
  menu; 
  newsInterval=30000;
  constructor(private route: ActivatedRoute, private templatesService:TemplatesService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    //test
    this.sub = this.route.params.subscribe(params => {
      this.page = params['page'];
      this.myquery = params['myparams'];
      var formattedParams=this.route.snapshot.queryParams;
       console.log('myparams',formattedParams);
       var queryString1 = "&"+Object.keys(formattedParams).map(key => key + '=' + formattedParams[key]).join('&');
       console.log(queryString1);
      this.templatesService.getRecord(this.page)
      .subscribe(
        data => {
          console.log("data obj=",data['obj']._id);
          var querySep="?";
          const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
          var queryString;
            if (this.myquery){
               queryString=this.myquery.replace("?", "&");this.myquery;
            }else {
               queryString='';
            }
            
            console.log("queryString = ",token+queryString);
            this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl('/api/templates/html/'+data['obj']._id+token+queryString+queryString1);
          console.log('/api/templates/html/'+this.page+token)
           this.myVar = data['obj'].body;
        },
      );
    }); 
  }

}
