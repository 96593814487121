import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import { ToastService } from 'ng-uikit-pro-standard';
import {LeadsService} from "./leads.service";
import {GridOptions} from "ag-grid-community";
import {RedcomponentComponent} from "../redcomponent/redcomponent.component";
import {NumericEditorComponent} from "../crm/numeric-editor.component";
import {TextEditorComponent} from "../crm/text-editor.component";
import {NotesComponent} from "./notes/notes.component";
import { MatDialog } from '@angular/material/dialog';
import {CrmService} from "../crm/crm.service";
import { ConfirmService } from '../shared/confirm/confirm.service';
import {ConfirmComponent} from "../shared/confirm/confirm.component";

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})
export class LeadsComponent implements OnInit {
  data;
  columnDefs;
  loading: boolean = false;
  public theme;
  public paginationSize;
  public gridOptions: GridOptions;
  confirmPressed = false;

  constructor(private leadsService: LeadsService,
              private _service: ToastService,
              public dialog: MatDialog,
              private crmService: CrmService,
              private confirmService: ConfirmService,) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Clickbank Id",field: "affiliate_id", cellStyle: this.changeRowColor},
      {headerName: "User Id",field: "user", cellStyle: this.changeRowColor},
      {headerName: "First Name",field: "firstname", cellStyle: this.changeRowColor},
      {headerName: "Last Name",field: "lastname", cellStyle: this.changeRowColor},
      {headerName: "Email",field: "email", cellStyle: this.changeRowColor},
      {headerName: "Status",field: "status", cellStyle: this.changeRowColor},
      {headerName: "Tracking Id",field: "tid",width: 100, cellStyle: this.changeRowColor},
      {headerName: "Created At",field: "created_at", cellStyle: this.changeRowColor, sort: 'desc', cellRenderer: this.renderDate},
      {headerName: "Updated At",field: "updated_at", cellStyle: this.changeRowColor, cellRenderer: this.renderDate},
      {headerName: "Actions", width: 100, cellStyle: this.changeRowColorForButton, template: `<button type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
      <img src="/assets/images/view.png" atl="View Notes" style="width:15px;height:15px;">&nbsp;View
             </button><br>
             <button md-button color="primary" subtype="button" data-action-subtype="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>

     `},

    ];
    this.theme="ag-theme-dave";
    this.paginationSize="50";
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      getRowHeight: function(params) {
        // assuming 50 characters per line, working how how many lines we need
        return 100;
      }
    };
    this.loading=true;
    this.leadsService.getallleads()
      .subscribe(
        data => {
          
          this.data = data['rows'];
          console.log(this.data)
          this._service.success(data['title'], data['message'])
          //this.notify.emit("I am logged in"),
          // this.router.navigateByUrl('/members/home');
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this.loading=false;
        },
        error => this._service.error(error.error['message'], error['title']),

      );

      this.confirmService.pressedConfirm.subscribe(
        data => {
          this.confirmPressed = data;
        })

  }

  

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionSubtype = event.event.target.getAttribute("data-action-subtype");

      switch(actionSubtype) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn", okButtonTxt:"CONFIRM", cancelButtonTxt:"No"});
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (this.confirmPressed == true){
        this.leadsService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message'],{opacity:1});

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title'],{opacity:1}),
          );
      }
    });

  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  changeRowColor(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red', 'color':'#ffffff'};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474', 'color':'#ffffff'};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3', 'color':'#000000'};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac', 'color':'#ffffff'};
      }
      case 'Sold': {
        return {'background-color': '#87c947', 'color':'#000000'};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513', 'color':'#ffffff'};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B', 'color':'#ffffff'};
      }
      case 'No Show': {
        return {'background-color': '#F88F09', 'color':'#ffffff'};
      }
    }
  }

  changeRowColorForButton(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red'};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow'};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow'};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474'};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3'};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac'};
      }
      case 'Sold': {
        return {'background-color': '#87c947'};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513'};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B'};
      }
      case 'No Show': {
        return {'background-color': '#F88F09'};
      }
    }
  }

  addComments(event){
    console.log(event);
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);

      }
    }
  }

  public onActionViewClick(data: any){
    console.log("View action clicked", data);
    let dialogRef = this.dialog.open(NotesComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.crmService.editCrm(data);


    dialogRef.afterClosed().subscribe(result => {
      console.log('result: ' + result);
      dialogRef = null;
    });


  }


  refresh(){
    
    this.loading=true;
    this.leadsService.getallleads()
      .subscribe(
        data => {
          this.data = data['rows'];
          
          this._service.success(data['title'], data['message'])
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  ngOnInit() {

  }

  getLeads(){

  }



}
