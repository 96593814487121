import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Links } from "./links";
import {LinksService} from "./links.service";
import {GridOptions} from "ag-grid-community";
import {LinksInputComponent} from "./links-input/links-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
//import { IframeComponent } from '../shared/iframe/iframe.component';
import { LinksStepsComponent } from '../links/links-steps/links-steps.component';
import { TemplatesService } from '../templates/templates.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  public model: Links;
  data;
  columnDefs;
  confirmPressed = false;
  gridOptions: GridOptions;
  constructor(private templatesService: TemplatesService, private confirmService: ConfirmService, private linksService:LinksService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Id", field: "_id", cellRenderer: this.renderLink, cellStyle: {"text-align":"center"}},
      {headerName: "Title", field: "title"},
      {headerName: "Link", field: "link"},

      {
        headerName: "Actions", template: `<button mat-button color="primary" type="button" data-action-type="visual" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Visual
       </button>

       <button mat-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit3.png" style="width:15px;height:15px;">&nbsp;Edit
       </button>
       
       <button mat-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
       </button>
             
           
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      getRowHeight: function(params) {
        // assuming 50 characters per line, working how how many lines we need
        return 100;
      }
    };

    this.linksService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['links'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  renderLink(params){
  console.log(params.data.image);
    var url = params.value;
   console.log(url);
   /*params.context.templatesService.getRecord(url)
      .subscribe(
        data => {
         console.log(data);

        },
        error => this._service.error(error.error['message'], error['title']),
      ); */
    return '<a target="_blank" href="/l/js/' + url+'"><img src="'+params.data.image+'" style="height:100px"></a>';
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "visual":
          return this.onActionVisualEdit(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionVisualEdit(data: any){
    this.model = new Links();
    this.model.page = data._id;
   
    this._service.success(data['message'], data['title']);
    let dialogRef = this.dialog.open(LinksStepsComponent, {
      width: "90vw",
      height: "85%",
      hasBackdrop: true,
      //data: { _id: data._id }
      data: {url: '/api/templates/editinline/', action: "edit", _id: data._id }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      });
  };

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(LinksInputComponent, {
      height: '90vh',
      width: '85%',
      hasBackdrop: true,
      panelClass: "padding-right:0px"

    });
    
    this.linksService.editLinks(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null; 
    });
    
  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '25vh',
      width: '35vw',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.linksService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.linksService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['links'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(LinksInputComponent, {
      height: '85%',
      width: '95vw',
      hasBackdrop: true,
      data: {url: '/api/templates/addinline/' }
    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  addRecordVisually(){
    let dialogRef = this.dialog.open(LinksStepsComponent, {
      height: '85%',
      width: '90vw',
      hasBackdrop: true,
      panelClass: "padding-right:0px",
      data: {url: '/api/templates/addinline/', action: "add" }

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }



  ngOnInit() {
  }

}
