import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {User} from "../auth/user";
import {Router} from "@angular/router";
import { ToastService } from 'ng-uikit-pro-standard';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import {Systempreference} from "../shared/systempreference/systempreference";

@Component({
  selector: 'app-eseaffiliates',
  templateUrl: './eseaffiliates.component.html',
  styleUrls: ['./eseaffiliates.component.css']
})
export class EseaffiliatesComponent implements OnInit {

  webinarid;
  webinarlink;
  mpd_link;
  username: string = null;
  public user: User;
  public hostName = window.location.host;
  
  constructor(private systempreferenceService:SystempreferenceService, private authService: AuthService,  private _service: ToastService, private router: Router) {
    const systempreference = new Systempreference(null, "webinarid", null, null);
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        data => {
          this.webinarid=data['obj'].value;
          const systempreference2 = new Systempreference(null, "default_eseid", null, null);
          this.systempreferenceService.getKey(systempreference2)
            .subscribe(
              data => {
                this.username=data['obj'].value;
                const systempreference1 = new Systempreference(null, "webinar_link", null, null);
                this.systempreferenceService.getKey(systempreference1)
                .subscribe(
                  data => {
                    this.webinarlink=data['obj'].value;
                    const systempreference2 = new Systempreference(null, "mpd_link", null, null);
                    this.systempreferenceService.getKey(systempreference2)
                      .subscribe(
                        data => {
                          this.mpd_link=data['obj'].value;
                          this.webinarlink=this.webinarlink.replace(/\[MPD_LINK\]/g, this.mpd_link);
                          this.webinarlink=this.webinarlink.replace(/\[WEBINARID\]/g, this.webinarid );
                          this.webinarlink=this.webinarlink.replace(/\[USERNAME\]/g, this.username );
                          this.webinarlink=this.webinarlink.replace(/\[PRODUCT\]/g, '200' );
                          console.log(this.webinarlink);
                        },
                        error => {
                          this._service.error(error.error['message'], error['title']);
                        }
                      );
                  },
                  error => {
                    this._service.error(error.error['message'], error['title']);
                  }
                );
              },
              error => {
                this._service.error(error.error['message'], error['title']);
              }
            );
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      );

    

      
   }

  save(model: User, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    model.roles="affiliate_only";
    model.valid="true";
    model.domain=this.hostName;
    if (model.url==undefined){
    console.log(model);
    this.authService.signup(model)
      .subscribe(
        data => {
          console.log(data);
          this._service.success(data['message'], data['title'])
          this.router.navigate(['/signin', {login: 'Success'}]);
        },
        error => {
          console.log(error);
          this._service.error(error['message'], error['title']

          )
        },

      )
    console.log(model, isValid);
  }
}

  ngOnInit() {

  }

}
