import { Component, OnInit } from '@angular/core';
import {Contact} from "./contact";
import {NgForm} from "@angular/forms";
import { ToastService } from 'ng-uikit-pro-standard';
import {ContactService} from "./contact.service";
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contact:Contact = null;
  showAds=this.isFreeMember();
  constructor(private contactService:ContactService, private _service: ToastService, private authService: AuthService) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
//form
    const contact:Contact = new Contact(form.value.firstname, form.value.lastname,form.value.email,form.value.subject,form.value['message']);

    this.contactService.sendMail(contact)
      .subscribe(
        data => {
          this._service.success('Your Mail was Sent', 'Success');
          form.reset();
        },
        error => {
            this._service.error(error['title'], error['message'])
            console.log(error.error)
        }
      );

  }

  reset(form: NgForm) {
    form.reset();
  }

  isFreeMember() {
    return this.authService.isFreeMember();
  }

}
