import {Injectable, EventEmitter} from '@angular/core';
import {Domains} from "./domains";

import {HttpHeaders, HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class DomainsService {
  private hostName = window.location.protocol + '//' + window.location.host;
  domains: Domains[] = [];
  domainsIsEdit = new EventEmitter<Domains>();
  constructor(private http: HttpClient) { }

  editDomains(domains: Domains) {
    console.log(domains)
    this.domainsIsEdit.emit(domains);
  }

  checkStatus (domains: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/domains/checkstatus/'+domains._id+token, {headers: headers})
     
  }

  processRegistrar(domains: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/domains/processgodaddy/'+domains._id+token,  {headers: headers})
  }

  addHerokuDomain (domains: Domains){
    var body=JSON.stringify(domains)
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/domains/addrecord/'+token,body,  {headers: headers})
  }

  createHomepage (domains: any){
    var body=JSON.stringify(domains)
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/domains/createhomepage/'+token,body,  {headers: headers})
  }

  addRecord(domains: Domains) {
    const body = JSON.stringify(domains);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/domains/addrecord' + token, body, {headers: headers})
  }

  deleteRecord(domains: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/domains/deleterecord/' + domains._id + token, {headers: headers})
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/domains/getallrecords'+token,  {headers: headers})
     
  }

  getAllMyRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/domains/getallmyrecords'+token,  {headers: headers})
      
  }

  updateRecord(domains: Domains){
    const body = JSON.stringify(domains);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/domains/updaterecord/' + domains.domainsId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/domains/getrecordl/'+id+token,  {headers: headers})
     
  }

}
