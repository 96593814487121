
<div class="menulabel">WELCOME</div>
<button Type="button" mat-button class="btnlink" [routerLink]="['/members/affiliatehome']" routerLinkActive="activated-button">
        <mat-icon>home</mat-icon> Affiliate Link Version 1
</button>
<button Type="button" mat-button class="btnlink" [routerLink]="['/members/affiliatehome1']" routerLinkActive="activated-button">
        <mat-icon>home</mat-icon> Affiliate Link Version 2
</button>
<button Type="button" mat-button class="btnlink" [routerLink]="['/members/affiliatehome3']" routerLinkActive="activated-button">
        <mat-icon>home</mat-icon> Phone Sales Affiliate Link
</button>

<button mat-button [routerLink]="['/members/account']" class="btnlink" routerLinkActive="activated-button"><mat-icon>account_circle</mat-icon> 
         Account
</button>
<button mat-button [routerLink]="['/members/logout']" class="btnlink" *ngIf="isLoggedIn()"><mat-icon>exit_to_app</mat-icon> Logout | {{username}}   </button>
<br><br>
<div class="menulabel">MAKE MONEY</div>
<button mat-button class="btnlink" [routerLink]="['/members/commissions']" routerLinkActive="activated-button" class="btnlink"><mat-icon>attach_money</mat-icon> My Commissions</button>
<br><br>


<div class="menulabel">CONTACT</div>
<button mat-button [routerLink]="['/members/contact']"  class="btnlink" routerLinkActive="activated-button" ><mat-icon>email</mat-icon> Contact</button>