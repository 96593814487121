

  <div class="row" style="">
    <div style="width:100%;padding-left:10px;margin-top:20px;">
      <div style="">
        
        <button mat-flat-button color="primary" (click)='refresh()'><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;<button mat-flat-button color="primary" (click)="f.ngSubmit.emit()" [disabled]="!f.valid"><mat-icon>save</mat-icon> Save</button>&nbsp;<button mat-flat-button color="primary" (click)='return()'><mat-icon>close</mat-icon> Cancel</button>&nbsp;<button mat-flat-button color="primary" (click)='addWebBuilder()'><mat-icon>add_circle</mat-icon> Add Template</button>&nbsp;<button mat-flat-button color="primary" (click)='onOpenConfigModal()'><mat-icon>info</mat-icon> Config</button>&nbsp;
        
        
        
          <mat-form-field appearance="fill">
            <mat-label>Template Category</mat-label>
            <mat-select   [(ngModel)]="categoryId" (selectionChange)="filter($event)">
              <mat-option  *ngFor="let category of categories" [value]="category._id">{{category.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill">
            <mat-label>Template Type</mat-label>
            <mat-select [(ngModel)]="typeId" (selectionChange)="filter($event)">
              <mat-option  *ngFor="let type of types" [value]="type._id">{{type.name}}</mat-option>
            </mat-select>
         </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill">
            <mat-label>Template Subtype</mat-label>
            <mat-select [(ngModel)]="subtypeId" (selectionChange)="filter($event)">
              <mat-option  *ngFor="let subtype of subtypes" [value]="subtype._id">{{subtype.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
      </div>
    </div>
  </div>
 
    <div cdkDropListGroup style="width:100%;text-align:center" clas="row">
      
      <div class="example-container col-md-3">
        <div 
          cdkDropList 
          [cdkDropListData]="templatesMainArray" 
          class="example-list" 
          (cdkDropListDropped)="drop($event)" style="text-align:left;padding:15px;">
                
               
            <div *ngFor="let templates of templatesMainArray; let i = index" cdkDrag>
              <div class="card example-box"   style="margin-top:15px;border:solid 2px #ccc;border-radius:10px;">
                <ng-template [ngIf]="templates.image" [ngIfElse]="noMainImage">
                  <img class="card-img-top" src="{{templates.image}}" alt="Optin Page" id="optinImage">
                </ng-template>
                <ng-template #noMainImage>
                  <img src="/assets/images/400x200-No_Image_Available.png" style="width:100%;max-height:100%;">
                </ng-template>

                <div class="card-body" style="overflow-wrap: break-word;margin-bottom:15px;">
                  <h5 class="card-title">{{templates.name}}</h5>
                  <div class="card-text"><b>Template WID:</b><span id="optinType">{{templates.wid}}</span></div>
                  <div class="card-text"><b>Template Type:</b><span id="optinType">{{templates.typeName}}</span></div>
                  <div class="card-text"><b>Template Sub Type:</b><span id="optinSubType">{{templates.subTypeName}}</span></div>
                  <div class="card-text"><b>Template Id:</b><br><span id="optinName">{{templates._id}}</span></div>
                </div>
               
                <div  style="position:absolute;bottom:0px;text-align:right;width:100%;padding-right:10px;">
                  <a (click)='onActionPreviewClick(templates.wid)' style="cursor:pointer;color:blue"><mat-icon style="font-size:12pt"> remove_red_eye </mat-icon>&nbsp;Preview</a>&nbsp;
                  <span *ngIf="showMainButtons[i]">
                    <a (click)='onActionViewSovrnClick(templates._id)' style="cursor:pointer;color:blue"><mat-icon style="font-size:12pt">edit </mat-icon>&nbsp;Edit</a>&nbsp;
                    <a (click)='onActionDeleteClick(templates._id)' style="cursor:pointer;color:blue;font-size:10pt"><mat-icon style="font-size:10pt">delete </mat-icon>&nbsp;Delete</a>
                  </span>
                </div>
              
                
              </div>
              <div style="width:100%;text-align:center;margin-top:15px;">
                <img src="/assets/images/down-arrow.png" style="width:50%">
              </div>
            </div>
        </div>
      </div>
      
      <div class="example-container col-md-8">
         
          <div
            cdkDropList
            [cdkDropListData]="allTemplatesArray"
            class=""
            (cdkDropListDropped)="drop($event)" style="">
            
           
                <div class="row justify-content-md-start gx-1">
                  <div class="col-md-3 my-1 justify-content-md-start"  *ngFor="let allTemplates of allTemplatesArray; let i = index" style="position:relative;overflow:hide;margin-top:15px;border:solid 1px #ccc;border-radius:10px;padding:0px;margin:5px;" cdkDrag>
                    <div class="view overlay zoom" style='width:100%;height:100%;background-repeat:no-repeat;background-size: 100% 100%;cursor:grab;' mdbWavesEffect>
                      <ng-template [ngIf]="allTemplates.image" [ngIfElse]="noImage">
                        <img src="{{allTemplates.image}}" style="width:100%;max-height:100%;">
                      </ng-template>
                      <ng-template #noImage>
                        <img src="/assets/images/400x200-No_Image_Available.png" style="width:100%;max-height:100%;">
                      </ng-template>
                      <p style="font-size:14pt;padding-left:10px;padding-right:10px;padding-top:10px;">
                        {{allTemplates.name}}
                      </p>
                      <div style="text-align:left;padding-left:20px;padding-right:20px;">
                      
                      <p style="font-size:10pt;">
                        <span style="font-weight:500">Template WID:</span> {{allTemplates.wid}}<br>

                        <span style="font-weight:500">Template ID:</span> {{allTemplates._id}}

                      </p>
                      </div>
                      <p style="margin-bottom:15px;">&nbsp;</p>
                     
                      <div style="position:absolute;bottom:0px;text-align:right;width:100%;padding-right:10px;">
                        <a (click)='onActionPreviewClick(allTemplates.wid)' style="cursor:pointer;color:blue;font-size:10pt"><mat-icon style="font-size:10pt"> remove_red_eye </mat-icon>&nbsp;Preview</a>&nbsp;
                        <span *ngIf="showAllButtons[i]">
                          <a (click)='onActionViewSovrnClick(allTemplates._id)' style="cursor:pointer;color:blue;font-size:10pt"><mat-icon style="font-size:10pt">edit </mat-icon>&nbsp;Edit</a>&nbsp;
                          <a (click)='onActionDeleteClick(allTemplates._id)' style="cursor:pointer;color:blue;font-size:10pt"><mat-icon style="font-size:10pt">delete </mat-icon>&nbsp;Delete</a>
                        </span>
                      </div>
                      
                    </div>
                  </div>
                </div>
            
          </div>
      </div>
      
      
</div>
<br><br><br>


  <!---begin config code model body -->
  <div mdbModal #configModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel1" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header light-blue">
            <table>
              <tr>
              
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="configModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
              </td>

              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="f.ngSubmit.emit()" style="color:#fff;background: none!important"><mat-icon>import_export</mat-icon> Update</button>
              </td>
              </tr>
            </table>
        
        
            <h4 class="modal-title w-100;" style="color:#fff" id="myModalLabel">Funnel Config</h4>
          </div>
          <div class="modal-body">
          
            
            <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
                
              <mat-dialog-content>
                <input type="hidden" name="funnelsId" #funnelsId="ngModel" [ngModel]="funnels?._id" id="funnelsId">
                <div>Funnel ID: {{funnels?._id}}</div>
                <input type="hidden" name="username" #username="ngModel" [ngModel]="funnels?.username"  id="username">
                  

                <mat-form-field class="example-full-width" appearance="outline">
                
                <textarea matInput name="order" #order="ngModel" [(ngModel)]="funnelsOrder" type="text" id="order" placeholder="Order" required></textarea>
                <mat-icon matSuffix>edit</mat-icon>
                
              </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Enter The Name Of The Funnel</mat-label>
                  <input matInput name="name" #name="ngModel" [ngModel]="funnels?.name" type="text" id="name" placeholder="Enter the Name" required>
                  <mat-icon matSuffix>edit</mat-icon>
                  <mat-error>This is a required field</mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Enter the WID or Funnel Number</mat-label>
                  <input matInput name="wid" #wid="ngModel" [ngModel]="funnels?.wid" type="text" id="wid" placeholder="Enter the WID or funnel number" required>
                  <mat-icon matSuffix>edit</mat-icon>
                  <mat-error>This is a required field</mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Enter the Domain Associated With This Page</mat-label>
                  <input matInput name="domain" #domain="ngModel" [ngModel]="funnels?.domain" type="text" id="domain" placeholder="Enter the domain">
                  <mat-icon matSuffix>edit</mat-icon>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Enter the Affiliate Link</mat-label>
                  <input matInput name="affiliate_link" #affiliate_link="ngModel" [ngModel]="funnels?.affiliate_link" type="text" id="affiliate_link" placeholder="Enter the affiliate link">
                  <mat-icon matSuffix>edit</mat-icon>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Enter Your tracking Codes</mat-label>
                  <textarea matInput name="tracking" #tracking="ngModel" [(ngModel)]="preBuilt" type="text" id="tracking" placeholder="Enter Your Tracking Codes"></textarea>
                  <mat-icon matSuffix>edit</mat-icon>
                </mat-form-field>

              



              </mat-dialog-content>
            
            </form>
          </div>
        </div>
    </div>
</div>
<!--end config model body -->