<div class="wrapper">
    <p align="center" class="ral-48blue">Internet Marketing Library</p>
    <p class="center t4"><strong>**These products are ONLY for ESE Members. Anyone caught sharing these products will have their account terminated</strong></p>
    <br>
    <div class="table">
      
      <div class="row header blue">
        <div class="cell center">
          ITEM
        </div>
        <div class="cell">
          DESCRIPTION
        </div>
        <div class="cell">
          ACTUAL VALUE
        </div>
      </div>

      <div class="row">
        
        <div class="cell img">
          <div class="title">The Home Run Copywriting Course</div>
          <img src="https://webcopycat.com/images/platinummembership/image008.png">
        </div>
        <div class="cell description">
            Discover how to hit your sales out of the par with this massive 5 hour 47 minute copywriting video course
            <br><br>
            Learning how to write so that others (like people on your list) are compelled to take action is a skill that can LITERALLY make you MILLIONS OF DOLLARS
            <br><br>
            This powerful video training course will teach you this most valuable of skills step-by-step
        </div>
        <div class="cell price" data-title="Occupation">
          $97<br>
          <a href="https://stuff-renegade.s3.amazonaws.com/Homerun%20Copy%20Writers%20-%20Product%20Delivery%20Files-Folders.zip" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadzip.png"></a><br>
        </div>
        
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">The $5K Per Month Blueprint</div>
            <img src="https://webcopycat.com/images/platinummembership/image010.png">
          </div>
          <div class="cell description">
              The EXACT model one marketer personally uses, to run a full time online business that generates him over $5,000 per month… all while working an average about 3 hours per day
          </div>
          <div class="cell price" data-title="Occupation">
            $497<br>
            <a href="http://webcopycat.s3.amazonaws.com/newwcc/funnel4/5K%20per%20Month%20Blueprint.zip" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadzip.png"></a><br>
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">The 9 Profit Pillars</div>
            <img src="https://webcopycat.com/images/platinummembership/image012.png">
          </div>
          <div class="cell description">
              The 9 Profit Pillars" is a step-by-step video series where you discover 9 ultra-powerful income streams! Discover things like how to get a SURGE of customers into your business… the #1 tool you need to make AUTO-PIOT income… and even one strategy for creating $500 to $2,000 paydays
          </div>
          <div class="cell price" data-title="Occupation">
            $97<br>
            <a href="http://webcopycat.s3.amazonaws.com/newwcc/funnel5/oto1/9%20Pillars%20of%20Profit.zip" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadzip.png"></a><br>
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Email Profits Max</div>
            <img src="https://webcopycat.com/images/platinummembership/image014.png">
          </div>
          <div class="cell description">
              The step-by-step process for creating HUGE profits by simply sending emails.
              <br><br>
              Including: How to get more people to open your emails, how to increase the levels of response you get from the emails you write, and how to build a relationship with your list so that you get MAX profits!
          </div>
          <div class="cell price" data-title="Occupation">
            $197<br>
            <a href="http://webcopycat.s3.amazonaws.com/newwcc/funnel6/oto1/Email%20Profits%20Max.zip" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadzip.png"></a><br>
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">The Cash Explosion Formula Video Training</div>
            <img src="https://webcopycat.com/images/platinummembership/image016.png">
          </div>
          <div class="cell description">
              The “Cash Explosion Formula” course is a step-by-step blueprint on how to explode your bank account with large sums of cash by leveraging the power of no-cost product launches!
          </div>
          <div class="cell price" data-title="Occupation">
            $97<br>
            <a href="http://webcopycat.s3.amazonaws.com/newwcc/funnel7/oto1-Video%20Series%20for%20Cash%20Explosion%20Formula/Video%20Series%20-%20Cash%20Explosion%20Formula.zip" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadzip.png"></a><br>
          </div>
          
      </div>
      
      <div class="row">
          <div class="cell img">
            <div class="title">The $10K Per Month Blueprint</div>
            <img src="https://webcopycat.com/images/platinummembership/image018.png">
          </div>
          <div class="cell description">
              TThe “$10 K Per Month Blueprint” is a complete 18 hour step by step training program that will teach you every step of the process to build a complete information business!
              <br><br>
 

              Topics include traffic driving, list building, product creation, affiliate marketing, email marketing, and much much more!
          </div>
          <div class="cell price" data-title="Occupation">
            $997<br>
            <a href="http://webcopycat.s3.amazonaws.com/newwcc/funnel6/oto2/The%20$10K%20Per%20Month%20Blueprint.zip" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadzip.png"></a><br>
          </div>
          
      </div>
      
      
    </div>
    
    
    
  </div>

  <div style="padding-bottom:30px;text-align:center;width:100%;font-size:32pt;font-weight:bold">
      That’s A Total Value Of $1,982!
  </div>