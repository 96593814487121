

  
  <div style="width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
    <div style="width:100%;padding-left:13px" class="row">
        
          <button type="button" mdbBtn color="info" mdbWavesEffect (click)="refresh()">Refresh</button> &nbsp;<button type="button" mdbBtn color="info" mdbWavesEffect  (click)="addRecord()"><i class="fa fa-plus" aria-hidden="true"></i> Add Autoresponder</button>
        <div style="display:flex;align-items:center"><a href="https://esev3.s3.us-east-1.amazonaws.com/downloads/ese-Integrations-Autoresponders.pdf" target="_blank"><img style="width:40px;" src="/assets/images/instructions-icon.png"></a></div>
        <div style="font-size:18pt;font-weight:bold;display:flex;justify-content:flex-end;flex-grow:1;align-items: center;">
          Autoresponders
        </div>
      </div>
    <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);;" class="ag-theme-dave" id="myGrid"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     
                     enableColResize
                     enableSorting
                     autoSizeColumns="true"
                     enableRangeSelection="true"
                     suppressRowClickSelection
                     enableFilter
                     toolPanelSuppressValues
                     toolPanelSuppressGroups
                     debug="false"
                     draggable="true"
                     rowHeight="27"
                     (rowClicked)="catchClick($event)"
                     rowSelection='multiple'>
    </ag-grid-angular>
  </div>


