import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {EducationService} from "../education.service";
import {Education} from "../education";
import {NgForm} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ImagesComponent } from '../../images/images.component';

@Component({
  selector: 'app-education-input',
  templateUrl: './education-input.component.html',
  styleUrls: ['./education-input.component.css']
})
export class EducationInputComponent implements OnInit {
  education:Education = null;

  constructor(private educationService:EducationService, private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    educationService.educationIsEdit.subscribe(
      education => {
        this.education = education;
        console.log(this.education);
      })

  }

  addImage(){
    let dialogRef = this.dialog.open(ImagesComponent, {
      height: '95vh',
      width: '80%',

    });
  }

  save(f:NgForm, model: Education, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.educationId){

      this.educationService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.educationService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
