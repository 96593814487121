<div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
  <input hidden type="file" #fileInput >
</div>


<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <span style="font-weight:bold;font-size:18pt;">Image Id</span> {{images?._id}}
    <br><br>
    <input type="hidden" name="imagesId" #imagesId="ngModel" [ngModel]="images?._id" id="imagesId">


    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="images?.name" type="text" id="name" placeholder="Enter the Name or Title" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput name="url" #url="ngModel" [ngModel]="images?.url" type="text" id="url" placeholder="Enter the Url" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>


  <br/><br/>




  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button md-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button md-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
