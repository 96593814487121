<div class="wrapper">
    <p align="center" class="ral-48blue">Bonus: Email Swipe File</p>
    <p class="t3">
      When it comes to promoting products to your email list, having a bundle of QUALITY pre-written emails (aka a “swipe file”) is an invaluable resource! A swipe file saves you from the headache of having to create promotional emails to send your list from scratch. 
    </p>
    <p class="t3">
      Professional email copywriters charge anywhere from $30 - $300 to write quality emails. But as part of ESE, you’ll get access to top-quality email swipes at no extra cost!
    </p>
    <p class="t3">
      On a regular basis, we’ll be adding to the email swipe bundles below. <strong>Each email swipe bundle contains approximately five to ten emails that you can use to promote virtually ANY offer you want!</strong> As long as the offer/product you’re promoting has to do with making money online, you’ll be able to use these swipes!
    </p>
    <p class="t3">
      <span class="red"><strong>IMPORTANT:</strong></span> These swipes are for ESE members ONLY! Anyone caught sharing these will have their account terminated!
    </p>
    <div *ngFor="let data of datas">
        <div [innerHTML]="data.body | decodeHtml:''"></div>
    </div>

   
</div>