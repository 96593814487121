import {Injectable, EventEmitter} from '@angular/core';
import {Request} from "./request";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class RequestService {
  private hostName = window.location.protocol + '//' + window.location.host;
  request: Request[] = [];
  requestIsEdit = new EventEmitter<Request>();
  constructor(private http: HttpClient) { }

  editRequest(request: Request) {
    console.log(request)
    this.requestIsEdit.emit(request);
  }

  addRecord(request: Request) {
    const body = JSON.stringify(request);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/request/addrecord' + token, body, {headers: headers})
      
  }

  deleteRecord(request: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/request/deleterecord/' + request._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/request/getallrecords'+token,  {headers: headers})
     
  }

  getAllRecordsByDomain() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/request/getallrecordsbydomain'+token,  {headers: headers})
     
  }

  updateRecord(request: Request){
    const body = JSON.stringify(request);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/request/updaterecord/' + request.requestId + token, body, {headers: headers})
     
  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/request/getrecordl/'+id+token,  {headers: headers})
      
  }

}
