<div style="width:100%;background-color:#000;position:relative;">
    <button mat-icon-button mat-mini-fab color="primary" mat-dialog-close style="position:absolute;top:-20px;right:-20px;z-index:999">
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
</div>
  
  <h2 mat-dialog-title>Products</h2>
<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="productId" #productId="ngModel" [ngModel]="products?._id" id="productId">
    <mat-form-field class="example-full-width">
        <input matInput name="product_name" #product_name="ngModel" [ngModel]="products?.product_name" type="text" id="product_name" placeholder="Enter the Product Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="product_code" #product_code="ngModel" [ngModel]="products?.product_code" type="text" id="product_code" placeholder="Enter the Product Code">
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="product_line" #product_line="ngModel" [ngModel]="products?.product_line" type="text" id="product_line" placeholder="Enter the Product Line">
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput name="product_vendor" #product_vendor="ngModel" [ngModel]="products?.product_vendor" type="text" id="product_vendor" placeholder="Enter the Product Vendor">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
          <input matInput name="product_link" #product_link="ngModel" [ngModel]="products?.product_link" type="text" id="product_link" placeholder="Enter the Product Link" >
          <mat-error>This is a required field</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput name="product_email" #product_email="ngModel" [ngModel]="products?.product_email" type="text" id="product_email" placeholder="Enter the Product Email">
            <mat-error>This is a required field</mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput name="product_image" #product_image="ngModel" [ngModel]="products?.product_image" type="text" id="product_image" placeholder="Enter the Product Image Link">
            <mat-error>This is a required field</mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <textarea  name="product_description" matInput #product_description [ngModel]="products?.product_description"  id="product_description" placeholder="Product_Description" style="height:200px;"></textarea>
          </mat-form-field>
          <div>
              <mat-select name="product_type" #product_type [ngModel]="products?.product_type"  id="product_type" placeholder="Product Type"  floatPlaceholder="auto" class="example-full-width">
                <mat-option value="recommended">Recommended</mat-option>
                <mat-option value="marketplace">Marketplace</mat-option>
              </mat-select>
            </div>
            <br>
            <mat-form-field class="example-full-width">
              <input matInput name="buy_price" #buy_price="ngModel" [ngModel]="products?.buy_price" type="text" id="buy_price" placeholder="Enter the Buy Price" required>
              <mat-error>This is a required field</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input matInput name="msrp" #msrp="ngModel" [ngModel]="products?.msrp" type="text" id="msrp" placeholder="Enter the MSRP">
              <mat-error>This is a required field</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input matInput name="display_order" #display_order="ngModel" [ngModel]="products?.display_order" type="text" id="display_order" placeholder="Enter the Display Order" >
              <mat-error>This is a required field</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input matInput name="wid" #wid="ngModel" [ngModel]="products?.wid" type="text" id="wid" placeholder="Enter the WID" >
            </mat-form-field>
            <mat-checkbox name="show" #show="ngModel" [ngModel]="products?.show" id="show" checked="true">Visiable as a Product?</mat-checkbox>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-flat-button  color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon> Save
      </button>
      &nbsp;
      <button mat-flat-button  color="primary" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon> Cancel
      </button>
    </div>
  </mat-dialog-actions>
</form>
