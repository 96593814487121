
<div style="width:100%;background-color:#000;position:relative;">
    <button mat-icon-button mat-mini-fab color="primary" mat-dialog-close style="position:absolute;top:-20px;right:-20px;z-index:999">
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
</div>


<h2 mat-dialog-title>Edit Record</h2>
  <form (ngSubmit)="save(f.value, f.valid)" #f="ngForm">
    <mat-dialog-content>
    <div class="form-group">
      <input type="hidden" name="id" [ngModel]="lead?._id">

      <mat-form-field class="example-full-width">
        <input name="affiliate_id" matInput #affiliate_id [ngModel]="lead?.affiliate_id" type="text" id="affiliate_id" placeholder="Affiliate_Id" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="meetingtime" matInput #meetingtime [ngModel]="lead?.meetingtime" type="text" id="meetingtime" placeholder="Appointment Time">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="timezone" matInput #timezone [ngModel]="lead?.timezone" type="text" id="timezone" placeholder="Appt Timezone">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="firstname" matInput #firstname [ngModel]="lead?.firstname" type="text" id="firstname" placeholder="First Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="lastname" matInput #lastname [ngModel]="lead?.lastname" type="text" id="lastname" placeholder="Last Name" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="email" matInput #email [ngModel]="lead?.email" type="text" id="email" placeholder="Email" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="phone" matInput #phone [ngModel]="lead?.phone" type="text" id="phone" placeholder="Phone" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="skype" matInput #skype [ngModel]="lead?.skype" type="text" id="skype" placeholder="Skype" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="city" matInput #city [ngModel]="lead?.city" type="text" id="city" placeholder="City" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="state" matInput #state [ngModel]="lead?.state" type="text" id="state" placeholder="State" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="country" matInput #country [ngModel]="lead?.country" type="text" id="country" placeholder="Country" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="howlong" matInput #howlong [ngModel]="lead?.howlong" type="text" id="howlong" placeholder="How Long Have Your Been Doing This?" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="about" matInput #about [ngModel]="lead?.about" type="text" id="about" placeholder="About You" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="affiliate_id" matInput #affiliate_id [ngModel]="lead?.affiliate_id" type="text" id="affiliate_id" placeholder="Affiliate ID">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="tracking" matInput #tracking [ngModel]="lead?.tracking" type="text" id="tracking"  placeholder="Tracking Id">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="query_string" matInput #query_string [ngModel]="lead?.query_string" type="text" id="query_string" placeholder="Query String">
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="created_at" matInput #created_at [ngModel]="lead?.created_at" type="text" id="created_at" placeholder="Created At" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input name="updated_at" matInput #updated_at [ngModel]="lead?.updated_at" type="text" id="updated_at" placeholder="Updated At" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>


      <div>
        <mat-select name="status"  (selectionChange)="onStatusChange(status._control)" #status [ngModel]="lead?.status"  id="status" placeholder="Status"  floatPlaceholder="auto" class="example-full-width">
          <mat-option value="Contact 1">Contact 1</mat-option>
          <mat-option value="Contact 2">Contact 2</mat-option>
          <mat-option value="Unable to Contact">Unable to Contact</mat-option>
          <mat-option value="NI/NQ">NI/NQ</mat-option>
          <mat-option value="Appoitment Set">Appointment Set</mat-option>
          <mat-option value="Appoitment Kept">Appointment Kept</mat-option>
          <mat-option value="Sold">Sold</mat-option>
          <mat-option value="Not Interested">Not Interested</mat-option>
          <mat-option value="In Fullfillment">In Fullfillment</mat-option>
          <mat-option value="Duplicate">Duplicate</mat-option>
          <mat-option value="No Show">No Show</mat-option>
        </mat-select>
      </div>


      <mat-form-field class="example-full-width">
        <textarea (click)="addNotesTime('notes')" name="notes" matInput #notes [ngModel]="lead?.notes"  id="notes" placeholder="notes" style="height:200px;"></textarea>
      </mat-form-field>
      <br/><br/>
      <mat-form-field class="example-full-width">
        <textarea (click)="addPrivateNotesTime('private_notes')" name="private_notes" matInput #private_notes [ngModel]="lead?.private_notes"  id="private_notes" placeholder="Private Notes" style="height:200px;"></textarea>
      </mat-form-field>


    </div>
    <p></p><p></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
    </mat-dialog-actions>
  </form>
<br/><br/>

<mat-dialog-content>
  <form (ngSubmit)="sendEmail(g.value, g.valid)" #g="ngForm" name="emailForm">

    <input name="lead_firstname"  #lead_firstname type="hidden" ngModel='{{leadFirstname}}' id="lead_firstname" required>
    <input name="lead_lastname"  #lead_lastname type="hidden" ngModel='{{leadLastname}}' id="lead_lastname" required>
    <input name="lead_email"  #lead_email type="hidden" ngModel='{{leadEmail}}' id="lead_email" required>
    <input name="lead_phone"  #lead_phone type="hidden" ngModel='{{leadPhone}}' id="lead_phone" required>
    <div>
      <mat-form-field appearance="fill" style="width:100%">
        <mat-label>From Email:</mat-label>
        <mat-select id="emailType" [(ngModel)]='emailType' name="emailType" class="example-full-width" >
          <mat-option value="sendgrid" selected>help@easysupportnow.com</mat-option>
          <mat-option value="gmail" selected>{{gmailUsername}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Email Template:</mat-label>
        <mat-select id="myEmail"  (selectionChange)="onChange($event)" #myEmail [(ngModel)]='emailName' name="emailName" class="example-full-width" >
          <mat-option [value]="'--'">Select Email</mat-option>
          <mat-option *ngFor="let email of emails" [value]=email._id>{{email.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
    </div>
    <br/>
      <mat-form-field class="example-full-width">
        <input name="email_subject" matInput #email_subject type="text" [(ngModel)]='emailSubject' id="email_subject" placeholder="Email Subject" required>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <textarea  style="height:200px;" matInput name="email_body" #body  type="text" id="email_body" [(ngModel)]='emailBody' placeholder="Enter The Body of the Email" required></textarea>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
    <button mat-icon-button mat-mini-fab color="primary" type="submit">
      Send
    </button>
  </form>
</mat-dialog-content>
<script>

</script>

