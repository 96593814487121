import {Injectable, EventEmitter} from '@angular/core';
import {Userpreference} from "./userpreference";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class UserpreferenceService {
  private hostName = window.location.protocol + '//' + window.location.host;
  news: Userpreference[] = [];
  newsIsEdit = new EventEmitter<Userpreference>();
  constructor(private http: HttpClient) { }

  editNews(userpreference: any) {
    console.log(userpreference)
    this.newsIsEdit.emit(userpreference);
  }

  getGroupRecords(group: string) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/userpreference/getgrouprecords/'+group+token,  {headers: headers})
    
  }

  addRecord(userpreference: any) {
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/userpreference/addrecord' + token, body, {headers: headers})
     
  }

  addRecordForSomeoneElse(userpreference: any) {
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/userpreference/addrecordforsomeoneelse' + token, body, {headers: headers})
     
  }

  deleteRecordForSomeoneElse(userpreference: any) {
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/userpreference/deleterecordforsomeoneelse' + token, body, {headers: headers})
     
  }

  deleteRecord(userpreference: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/userpreference/deleterecord/' + userpreference._id + token, {headers: headers})
    
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/userpreference/getallrecords'+token,  {headers: headers})
    
  }

  updateKey(userpreference: any){
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/userpreference/updatekey' + token, body, {headers: headers})
     
  }

  getKey(userpreference: Userpreference) {
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/userpreference/getkey' + token, body, {headers: headers})
     
  }

  getKeyByUser(user, key) {
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const uri = "/"+user+"/"+key;
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    console.log(this.hostName +'/api/userpreference/getkeybyuser' + uri+token);
    return this.http.get(this.hostName +'/api/userpreference/getkeybyuser' + uri+token, {headers: headers})
    
  }

  updateRecord(userpreference: any){
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/userpreference/updaterecord/' + userpreference.userpreferenceId + token, body, {headers: headers})
     

  }

  updateOrInsertRecord(userpreference: any){
    const body = JSON.stringify(userpreference);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/userpreference/insertorupdaterecord/' + userpreference.userpreferenceId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/userpreference/getrecordl/'+id+token,  {headers: headers})
    
  }

}
