import {Injectable, EventEmitter} from '@angular/core';
import {Status} from "./status";

import { Observable } from "rxjs";
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class StatusService {
  private hostName = window.location.protocol + '//' + window.location.host;
  status: Status[] = [];
  statusIsEdit = new EventEmitter<Status>();
  constructor(private httpclient: HttpClient) { }

  editStatus(status: Status) {
    console.log(status)
    this.statusIsEdit.emit(status);
  }

  addRecord(status: Status) {
    const body = JSON.stringify(status);
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/status/addrecord'+token, body,  {headers: headers})
  }

  deleteRecord(status: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.delete(this.hostName +'/api/status/deleterecord/' + status._id + token, {headers: headers})
  }

  getAllRecords() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/status/getallrecords'+token,  {headers: headers})
  }

  getRecordsByName(name) {
    const body = JSON.stringify({'name':name})
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/status/getrecordsbyname'+token, body,  {headers: headers})
  }

  updateRecord(status: Status){
    const body = JSON.stringify(status);
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.patch(this.hostName +'/api/status/updaterecord/' + status.statusId + token, body, {headers: headers})
  }

  getRecord(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/status/getrecordl/'+id+token,  {headers: headers})
  }

}
