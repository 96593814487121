<div>
  <br><br><br>
  <div class="ral-48blue">
    <strong>SIMPLE STEPS TO SUCCEEDING WITH THE EASIEST SYSTEM EVER</strong>
  </div>
  <br>
  <div style="font-size:14pt">
    <p class="normal"><strong>Step #1:</strong> Complete Your "Getting Started" Checklist. (less than 20 minutes)
    Completing these steps activates your system. These steps only need to be done once. Once you've signed the form activating your Money-Back guarantee, and gotten us your Aweber auto-responder information. We'll set everything up for you and send the initial amount of visitors that were promised to you.
    </p>

    <p class="normal"><strong>Step #2:</strong> Order traffic to your system as frequently as possible. (5 Minutes)
    Just like you MUST frequently put gas in your car in order for it to work properly, with your internet business, you MUST add traffic. Traffic sent through your system builds your email list. Your email list is your biggest asset, and having a HUGE email list will set you up for long-term success. So order as much traffic as you can, as frequently as you can. <a routerLink="/members/doneforyoutraffic">Get traffic here</a>.
    </p>

    <p class="normal"><strong>Step #3:</strong> Join our private inner circle Facebook group. Introduce yourself, support your fellow members, and become part 
   of our Facebook family. <a href="https://www.facebook.com/groups/1120969364594477/" target="_blank">Click here</a> to join the Facebook group.
   </p>

    <p class="normal"><strong>Step #4:</strong> Partake in our regular group coaching calls (1 hour, every 2 weeks)
    Our group coaching calls are the perfect environment for you to engage us and learn the skills that will accelerate your success. So make sure you join us live whenever you can.
    </p>
    <p class="normal"><strong>Step #5:</strong> Email your list regularly (15 min per day or 15 min every other day)
    The Easiest System Ever will AUTOMATICALLY follow up with everyone who comes through the system for you. But if you want to 3X to 10X your results, you'll still email your list regularly. This is a SUPER EASY task (and free to do). If you don't know what to email your list (or how to email them get <a routerLink='/members/oneononecoaching'>1-on-1 coaching</a>)
    </p>

    <p class="normal"><strong>Step #6:</strong> Get 1-on-1 Coaching (3 private 1-hour sessions)
    LITERALLY the BEST thing you can do to ACCELERATE YOUR SUCCESS is to have a human being walk you through the stuff you don't know how to do! Tasks like emailing your list, and finding new income streams are FREE & EASY; but if you don't know how to do them, then you're going to miss out on thousands of dollars in commissions.  <a routerLink='/members/oneononecoaching'>Click Here to inquire about 1-on-1 coaching</a>
    </p>

    <p class="normal"><strong>Step #7:</strong> Be patient and consistent.
    Rome wasn't built in a day. The system will follow-up with your people for you automatically but it will take TIME for your list to get used to receiving emails from you, and to know, like, and trust you. I once had someone on my email list for 5 months before they purchased something from me. But when they did buy, it made me $900 in a single day. I'm not guaranteeing that will happen for you. I'm simply telling you to be patient and consistent because you never know when someone will be ready to buy
    </p>

    <p class="normal"><strong>Step #8:</strong> Just ask us.
    Have a question about coaching? Just want a little clarification on something? Our friendly support staff is here to help. You can either <a href="http://www.easiestsupportever.com" target="_bblank">submit a support ticket here</a>, or call us at +1-678-894-1982  (M-F 10AM to 6PM Eastern Time) 
    </p>

    <p class="normal"><strong>Step #9:</strong> For those of you who are a little more advanced. If you ever want to send traffic to your ESE system, simply click on the "My Sales Funnels" menu item on the left, and use ANY link labeled "Your Opt-in Page Link". This will ensure that the person coming to your system is put into your email list FIRST. If, for example, you're ordering traffic from one of your own trusted sources, you'll want to make sure you use one of your "Opt-In Page" links. If you have an email list already, and you want to promote any of the affiliate products to your list (including ESE itself), simply go to the "My Sales Funnels" page here in your member's area, and use ANY of the links labeled "Your Sales-Page Link". The sales page links are ONLY to be used for people who are already on your list.
      </p>
  </div>
</div>