//import component, ElementRef, input and the oninit method from angular core
import {Component, OnInit, ElementRef, Input, ChangeDetectorRef, EventEmitter, Output} from '@angular/core';
//import the file-draganddrop plugin
//import the native angular http and respone libraries
//import the do function to be used with the http library.

//import the map function to be used with the http library


import {FileDraganddropService} from "./filedraganddrop.service";
//import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from '../fileupload/fileupload.service';
import { ToastService } from 'ng-uikit-pro-standard';
//import { DraganddropEvent, DraganddropFile } from 'ngx-file-drop';
//test
const URL = '/api/draganddrop/draganddrop';

@Component({
  selector: 'app-filedraganddrop',
  templateUrl: './filedraganddrop.component.html',
  styleUrls: ['./filedraganddrop.component.css']
})
export class FileDraganddropComponent implements OnInit {
  @Output() draganddropedId = new EventEmitter<any>();
  //public files: DraganddropFile[] = [];
  url;
  thumbUrl;
  draganddroped= true;
  sigingrequest;
  progress = false;
  public file_srcs: string[] = [];
  draganddropedImage: File;
  imagePreview: string;
  fileType;
  whatToDo = "Click or Drag Here to Draganddrop Attachments";
  myFileList=[];
  showUploadButton = false;
  showImagePreview = false;
  fileExtension;
  uploadedImage: File;

  constructor(
    public sanitizer: DomSanitizer, 
    //private ng2ImgMax: Ng2ImgMaxService, 
    private el: ElementRef,  
    private draganddropService: FileDraganddropService, 
    private changeDetectorRef: ChangeDetectorRef,
    private uploadService: FileUploadService,
    private toastrService: ToastService
    ) { }

  // The next two lines are just to show the resize de;bug
  // they can be removed
  public debug_size_before: string[] = [];
  public debug_size_after: string[] = [];

  preDraganddrop(){
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    let thumbEl: HTMLInputElement = this.el.nativeElement.querySelector('#thumb');
   // this.draganddrop(inputEl);
  }

  onDrop(event) {
    event.preventDefault();
    console.log(event.dataTransfer.files[0].name);
    let image = event.dataTransfer.files[0];
    let file:File=image;
    let formData:FormData = new FormData();

    this.uploadedImage = file;
    this.draganddropedImage = file;
    
    //this.processFiles(image);
    this.getImagePreview(this.draganddropedImage);
  }
  
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onImageChange(event){
    let image = event.target.files[0];  
    this.processFiles(image);
  }
 

  processFiles(image) {
    var imageFileType =['png','jpg','jpeg','gif'];
    
    
    var fileParts = image.name.split('.');
   
    
    this.fileExtension=image.name.substring(image.name.lastIndexOf('.')+1);
   
    this.fileType=image.type;
    //this.ng2ImgMax.resizeImage(image, 400, 300).subscribe(
    /*  if (imageFileType.indexOf(this.fileExtension) >= 0){
        this.ng2ImgMax.compressImage(image, 0.100).subscribe(
          result => {
            this.draganddropedImage = new File([result], result.name);
           
            this.getImagePreview(this.draganddropedImage);
            //this.draganddrop();
            this.showUploadButton=true;
          },
          error => {
            console.log('😢 Oh no!', error);
          }
        );
      } else {
        console.log('noe');
        this.draganddropedImage = image;
        this.draganddroped=false;
        this.whatToDo=image.name;
        this.upload();
      } */
      
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      this.showImagePreview=true;
      this.draganddroped=false;
      this.upload();
    };
  }


  upload(){
    var fileTypes = {htm: 'text/html'};
   
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    
    var file = this.draganddropedImage;
    this.fileType=this.draganddropedImage.type;
   
    let fileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
    this.uploadService.getSignedRequest(fileName, this.fileType)
      .subscribe(
        data => {
          this.sigingrequest=data;
          this.finalDraganddrop(this.sigingrequest, file, this.fileType)
        },
        error => {
          this.progress=false;
          this.toastrService.error(error.error.message, error.title, {opacity:1})
        }
      );
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  finalDraganddrop(data, file, fileType){
    this.uploadService.upload(data.signedRequest, file, fileType)
      .subscribe(
        data => {
          console.log(data);
          if (data['status']==200){
            this.progress=false;
            this.draganddroped=true;
           // this.uploadService.addRecord(data)
            //  .subscribe(
               // data => {
                 // console.log(data['obj']._id);
                //  data['obj'].extension=this.fileExtension;
                //  this.myFileList.push(data['obj']);
                //  console.log(this.myFileList);
                  this.draganddropedId.emit(data);
                  this.showUploadButton=false;
                 // this.showImagePreview=false;
                  
               // }
             // );
             this.showImagePreview=false;
            this.toastrService.success("Success", "Your image has been uploaded",{opacity:1})
          }
        },
        error => {
          this.toastrService.error(error.error.message, error.title, {opacity:1})
        },
      );
  }

  previewImage(event){
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e:any) => {
        var image = new Image();
        image.src = reader.result as string;
      
        this.url = e.target.result;

        image.onload = function() {
        };

      };

      reader.readAsDataURL(event.target.files[0]);
      this.draganddroped=false;

    }
  }




  resize(img, MAX_WIDTH:number, MAX_HEIGHT:number, callback){
    // This will wait until the img is loaded before calling this function
    return img.onload = () => {

      // Get the images current width and height
      var width = img.width;
      var height = img.height;

      // Set the WxH to fit the Max values (but maintain proportions)
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // create a canvas object
      var canvas = document.createElement("canvas");

      // Set the canvas to the new calculated dimensions
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0,  width, height);

      // Get this encoded as a jpeg
      // IMPORTANT: 'jpeg' NOT 'jpg'
      var dataUrl = canvas.toDataURL('image/jpeg');

      // callback with the results
      callback(dataUrl, img.src.length, dataUrl.length);
    };
  }

  getAllRecords(){
    this.draganddropService.getAllRecords()
    .subscribe(
      data => {
        console.log(data);
      }
    );
  }


  ngOnInit() {
    //this.getAllRecords();
  }

}
