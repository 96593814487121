<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="newsId" #newsId="ngModel" [ngModel]="news?._id" id="newsId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="news?.name" type="text" id="name" placeholder="Enter the Name or Title" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="body" #body="ngModel" [ngModel]="news?.body" type="text" id="body" placeholder="Enter the News" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
