import { Injectable } from '@angular/core';
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class CheckoutService {
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  processTransaction(model) {
    const body = JSON.stringify(model);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/checkout/processtransaction' + token, body, {headers: headers})
      
  }

  validateCoupon(model){
    const body = JSON.stringify(model);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/checkout/validatecoupon' + token, body, {headers: headers})
      
  }

}
