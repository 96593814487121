<section>
    <div class="ral-48blue">REDEEM FREE 1-on-1 WALKTHROUGH</div>
    <p class="normal">
        Our team would be more than happy to give you a call and answer any questions you’ve got about the system. &#9786;
    </p>
    <p class="normal">
        Simply verify the information in the fields below, and we’ll give you a call ASAP to schedule a time to chat!
    </p>

    <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
        <mat-dialog-content>
          <mat-form-field class="example-full-width">
            <input matInput #firstname="ngModel" name="firstname" (ngModel)="firstname" type="text"  id="id_contact_firstname"  placeholder="First Name" required/>
            <mat-error>This is a required field</mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
              <input matInput #lastname="ngModel" name="lastname" (ngModel)="lastname" type="text"  id="id_contact_lastname"  placeholder="Last Name" required/>
              <mat-error>This is a required field</mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
              <input matInput #country="ngModel" name="country" (ngModel)="country" type="text"  id="id_contact_country"  placeholder="country" required/>
              <mat-error>This is a required field</mat-error>
            </mat-form-field>

          <mat-form-field class="example-full-width">
              <input matInput #phone="ngModel" name="phone" (ngModel)="phone" type="text"  id="id_contact_phone"  placeholder="Phone" required/>
              <mat-error>This is a required field</mat-error>
          </mat-form-field>
        </mat-dialog-content>
        <br>
        <mat-dialog-actions>
            <div class="userActionButtonGroup">
              <button mat-button  color="primary" type="submit" [disabled]="!f.valid">
                <mat-icon  class="material-icons">done</mat-icon> Submit Request
              </button>
              <button mat-button color="primary" type="button" (click)="closeDrawer($event,'3')">
                <mat-icon  class="material-icons">cancel</mat-icon> Return
              </button>
            </div>
        </mat-dialog-actions>
      </form>
</section>