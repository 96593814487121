<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <mat-dialog-content>
    <input type="hidden" name="userpreferenceId" #newsId="ngModel" [ngModel]="userpreference?._id" id="userpreferenceId">
    <mat-form-field class="example-full-width">
      <input matInput name="key" #key="ngModel" [ngModel]="userpreference?.key" type="text" id="key" placeholder="Enter the Key for the Userpreference" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="value" #value="ngModel" [ngModel]="userpreference?.value" type="text" id="value" placeholder="Enter the Value" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput name="group" #group="ngModel" [ngModel]="userpreference?.group" type="text" id="group" placeholder="Enter the group (Not required)">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput name="username" #username="ngModel" [ngModel]="userpreference?.username" type="text" id="username" placeholder="Enter the username (Not required)">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput name="userId" #userId="ngModel" [ngModel]="userpreference?.userId" type="text" id="userId" placeholder="Enter the Uzser ID (Not Username. This is required for adding a new Record)">
      </mat-form-field>

      <mat-form-field class="example-full-width">
          <input matInput name="subgroup" #subgroup="ngModel" [ngModel]="userpreference?.subgroup" type="text" id="subgroup" placeholder="Enter the subgroup (Not required)">
        </mat-form-field>
    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
