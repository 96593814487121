import {Injectable, EventEmitter} from '@angular/core';
import {Webinar} from "./webinar/webinar";

import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class WebinarService {
  private hostName = window.location.protocol + '//' + window.location.host;
  webinar: Webinar[] = [];
  webinarIsEdit = new EventEmitter<Webinar>();
  constructor(private http: HttpClient) { }

  editWebinar(webinar: Webinar) {
    console.log(webinar)
    this.webinarIsEdit.emit(webinar);
  }

  addRecord(webinar: Webinar) {
    const body = JSON.stringify(webinar);
    
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/webinar/addrecord' + token, body, {headers: headers})
    
  }

  deleteRecord(webinar: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/webinar/deleterecord/' + webinar._id + token, {headers: headers})
  
  }

  getAllRecords() {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/webinar/getallrecords'+token,  {headers: headers})
   
  }

  updateRecord(webinar: Webinar){
    const body = JSON.stringify(webinar);
    console.log(body);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/webinar/updaterecord/' + webinar.webinarId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/webinar/getrecordl/'+id+token,  {headers: headers})
  
  }

  getRecordByName(name) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/webinar/getrecordbyname/'+name+token,  {headers: headers})
   
  }

}
