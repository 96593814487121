import { Component, OnInit, Input } from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-header-white',
  templateUrl: 'headerwhite.component.html',
  styleUrls: ['headerwhite.component.css']
})
export class HeaderWhiteComponent implements OnInit {
  @Input() loggedin_email: string;
  isAdmin = false;
  constructor(private authService:AuthService,  private _service: ToastService) { }

  ngOnInit() {
    this.checkAdmin();
  }

  isLoggedIn() {
    //this.logged_in_email = localStorage.getItem('email');
    return this.authService.isLoggedIn();
  }

  checkAdmin(){
    this.authService.isAdmin()
      .subscribe(
        data => {
          this.isAdmin=data['success'];
          console.log(data);
        }
      );
  }

}
