<table style="width:100%">
  <tr style="background-color:darkblue;color:#ffffff;font-size:14pt">
    <th colspan="3" style="text-align:center">Status Legend</th>
  </tr>
  <tr style="background-color:darkblue;color:#ffffff">
    <th>Color Code</th><th>Status</th><th>Status Description</th>
  </tr>
  <tr>
    <td style="background-color:yellow">Yellow</td>
    <td>Contact 1</td>
    <td>1ST Call /Email Attempt for Appointment made</td>
  </tr>
  <tr>
    <td style="background-color:yellow">Yellow</td>
    <td>Contact 2</td>
    <td>2nd Call /Email Attempt for Appointment made</td>
  </tr>
  <tr>
    <td style="background-color:red;color:#ffffff">Red</td>
    <td>Unable to Contact</td>
    <td>Tried at least 3 times to call/email. Got no response back from lead</td>
  </tr>
  <tr>
    <td style="background-color:#747474;color:#ffffff">Dark Gray</td>
    <td>NI/NQ</td>
    <td>Spoke to lead – Lead not interested or not money qualified</td>
  </tr>
  <tr>
    <td style="background-color:#abbfe3;color:#000000">Light Blue</td>
    <td>Appoitment Set</td>
    <td>Lead has been pre-qualified and an scheduled to speak with our sale team.</td>
  </tr>
  <tr>
    <td style="background-color:#2869ac;color:#ffffff">Dark Blue</td>
    <td>Appoitment Kept</td>
    <td>Sales Appointment Kept – Lead interested & needs to be followed up with in the near future</td>
  </tr>
  <tr>
    <td style="background-color:#87c947;color:#000000">Green</td>
    <td>Sold</td>
    <td>Lead Sold (Date & Amount noted in Notes Field) – Note payment has not cleared yet.</td>
  </tr>
  <tr>
    <td style="background-color:#8B4513;color:#ffffff">Brown</td>
    <td>In Fullfillment</td>
    <td>Payment Cleared – In queue to be fulfilled. </td>
  </tr>
  <tr>
    <td style="background-color:#62148B;color:#ffffff">Purple</td>
    <td>Duplicate</td>
    <td>Duplicate - Lead has already filled out an application form (see notes section for further details) </td>
  </tr>
  <tr>
    <td style="background-color:#F88F09;color:#ffffff">Orange</td>
    <td>No Show</td>
    <td>Lead did not show up for sales call appointment (we will follow up with them) </td>
  </tr>
</table>
