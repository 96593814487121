<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
    
    <mat-dialog-content>
      <input type="hidden" name="supportId" #supportId="ngModel" [ngModel]="support?._id" id="supportId">
      <input type="hidden" name="username" #username="ngModel" [ngModel]="support?.username"  id="username">

      <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Please Enter Yiour First Namel</mat-label>
        <input matInput name="firstname" #firstname="ngModel" [ngModel]="support?.firstname" type="text" id="firstname" placeholder="Please Enter Your First Name" required>
        <mat-icon matSuffix>edit</mat-icon>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
  
      <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Please Enter Your Last Name</mat-label>
        <input matInput name="lastname" #lastname="ngModel" [ngModel]="support?.lastname" type="text" id="lastname" placeholder="Please Enter Your Last Name" required>
        <mat-icon matSuffix>edit</mat-icon>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>
  
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Please Enter Your Email</mat-label>
        <input matInput name="email" #email="ngModel" [ngModel]="support?.email" type="text" id="email" placeholder="Please Enter Your Email">
        <mat-icon matSuffix>edit</mat-icon>
      </mat-form-field>

      <mat-form-field class="example-full-width">
         
        <mat-select name="status" #status [ngModel]="mystatus"  id="status" placeholder="Select a Status"  floatPlaceholder="auto" class="example-full-width" ngDefaultControl>
          <mat-option  *ngFor="let status of statuses" value="{{status._id}}">{{status.name}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Subject of your Message</mat-label>
        <input matInput name="subject" #subject="ngModel" [ngModel]="support?.subject" type="text" id="subject" placeholder="Subject of your Message">
        <mat-icon matSuffix>edit</mat-icon>
      </mat-form-field>
  
      <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>What is The Message for Support</mat-label>
        <textarea matInput name="message" #message="ngModel" [ngModel]="support?.message" type="text" rows="10" id="message" placeholder="What is The Message for Support"></textarea>
        <mat-icon matSuffix>edit</mat-icon>
      </mat-form-field>
  
      
     
  
      <br><br>
  
  
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="userActionButtonGroup">
        <button mat-flat-button  color="primary" type="submit" [disabled]="!f.valid">
          <mat-icon  class="material-icons">done</mat-icon>&nbsp;Save
        </button>&nbsp;
        <button mat-flat-button color="primary" mat-dialog-close>
          <mat-icon  class="material-icons">cancel</mat-icon>&nbsp;Cancel
        </button>
      </div>
    </mat-dialog-actions>
  </form>
 
  
  <app-notifications></app-notifications>