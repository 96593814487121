import { Component, OnInit, EventEmitter } from '@angular/core';
import { CategoriesService } from '../categories/categories.service';
import { Categories } from '../categories/categories';
import { ToastService } from 'ng-uikit-pro-standard';
import { TemplatesService } from '../templates/templates.service';


@Component({
  selector: 'app-mastertemplates',
  templateUrl: './mastertemplates.component.html',
  styleUrls: ['./mastertemplates.component.css']
})
export class MastertemplatesComponent implements OnInit {
  categories: Categories = null;
  thumbs = [];
  currentCategory: String;
  onAdd = new EventEmitter();
  constructor(private pagesService: TemplatesService, private categoriesService:CategoriesService, private _service: ToastService) { }

  ngOnInit() {
    this.categoriesService.getAllRecordsByRole({'role':'user'})
      .subscribe(
        data => {
          this.categories = data['obj'];
          console.log(this.categories);
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  getTemplates(event){
    console.log(this.currentCategory);
    this.pagesService.getCategoryMasterRecords(this.currentCategory)
      .subscribe(
        data => {
          this.thumbs = data['obj'];
          console.log(data['obj']);
        },
        error => this._service.error(error.error['message'], error['title']),
      );

  }

  chooseTemplate(_id){
    this.onAdd.emit(_id);
  }

}
