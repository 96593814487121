import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Support } from "./support";
import {SupportService} from "./support.service";
import {GridOptions} from "ag-grid-community";
import {SupportInputComponent} from "./support-input/support-input.component";
import {SupportRespondComponent} from "./support-input/support-respond.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import { SupportAddComponent } from './support-input/support-add.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  gridOptions: GridOptions;
  pageTitle="Support Tickets";
  
  constructor(private confirmService: ConfirmService, private supportService:SupportService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      
      {headerName: "First Name", field: "firstname"},
      {headerName: "Last Name", field: "lastname"},
      {headerName: "Email", field: "email"},
      {headerName: "Phone", field: "phone"},
      {headerName: "Subject", field: "subject"},
      {headerName: "Status", field: "status.name"},
      {headerName: "Created", field: "created_at", cellRenderer: this.renderDate, sortable: true, filter:true, resizable:true},
      {headerName: "Updated", field: "updated_at", cellRenderer: this.renderDate, sort:'desc', sortable: true, filter:true, resizable:true},
      {
        headerName: "Actions", template: `<button md-button color="primary" type="button" data-action-type="view" style="height:15px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
              <br>
              <button md-button color="primary" type="button" data-action-type="response" style="height:15px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Respond
             </button>
             <br>
             <button md-button color="primary" type="button" style="height:15px;font-size:8pt;background-color:transparent;border:none;" data-action-type="delete" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.supportService.getAllTopLevelRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionLevel = event.event.target.getAttribute("data-action-type");

      switch(actionLevel) {
        case "view":
          return this.onActionViewClick(data);
        case "response":
          return this.onActionResponseClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(SupportInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.supportService.editSupport(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionResponseClick(data: any){
    let dialogRef = this.dialog.open(SupportRespondComponent, {
      height: '600px',
      width: '60%',

    });
    
    data.response=true;
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.supportService.editSupport(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.supportService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.supportService.getAllTopLevelRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          console.log(this.data);
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(SupportAddComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }



  ngOnInit() {
  }

}
