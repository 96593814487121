<header>
  
  
  <!-- Sidebar navigation -->
  <!-- SideNav slide-out button -->

<!--/. SideNav slide-out button -->
  <mdb-side-nav  id="sidenav" #sidenav [class]="sideNavBackground" [fixed]="true" (closed)="onSideNavClose($event)" >
    <!-- Logo -->
    <li>
        <div style="width:240px;text-align:center;margin-top:20px;margin-bottom:20px;" >
            <img [src]="this.logo" style="width:85%">
        </div>
    </li>

    
    <!--/. Logo -->
   
    <!-- Side navigation links -->
    
    <!--/. Side navigation links -->
    <app-affiliateonlymenu1 *ngIf="isAffiliateOnly"></app-affiliateonlymenu1>
    <app-contestonlymenu *ngIf="isContestOnly"></app-contestonlymenu>
    <app-mainmenu1 *ngIf="isMember"></app-mainmenu1>
    <app-adminmenu *ngIf="isAdmin"></app-adminmenu>
    <app-managemenu *ngIf="isSuper"></app-managemenu>
    <div class="sidenav-bg mask-strong"></div>
  </mdb-side-nav>
  <!--/. Sidebar navigation -->
  
  <!-- Navbar -->
          
             
            <div>
                &nbsp;<a (click)="toggleSideNav()"  dbWavesEffect class="button-collapse"><i class="fa fa-bars"></i></a>
            </div>

            <div style="display:none;position:absolute;right:30px;top:10px;font-weight:bold;font-size:20pt;">{{pageTitle}}</div>
                      
            
<!-- /.Navbar -->
   
  </header>
  <!--Main layout-->
      <div class="white-skin divmeMain">
          
          <div class="container-fluid" >
            
            <router-outlet></router-outlet>
          
          </div>
          
        </div>
      <!--/Main layout-->
      <!--Footer-->
      <footer class="page-footer text-center text-md-left pt-4" style="margin-top:50px;">
  
          
  
         
  
      </footer>
   