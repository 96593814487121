<p align="center" class="ral-48blue">1-on-1 Coaching</p><br>
<p class="normal">
As an Easiest System Ever member (and an entrepreneur), you fall into one of two categories.
</p>
<p class="normal">
<strong>CATEGORY ONE:</strong> You’re a “passive” entrepreneur, and you’re content just ordering traffic as often as you can, and letting the system follow up with people for you 
</p>
<p class="normal">
<strong>CATEGORY TWO:</strong> You’re an “active” entrepreneur, and you’re serious about taking success into your own hands and getting creating life on your terms.
</p>
<p class="normal">
If you fall into category number two (which I certainly hope you do), then you should know that the FASTEST way to accelerate your success is to get a HUMAN BEING to help you 1-on-1.
</p>
<p class="normal">
If you don’t already know how to do things like use your auto-responder, or find additional profitable income streams to send to your list (for free in just 15 minutes a day), then you’re going to miss out on thousands of dollars in commissions over the next 12 months.
</p>
<p class="normal">
So, you’re an ACTIVE entrepreneur, who wants to learn the simple skills necessary to be in the top 10% of online home business owners, then go ahead and grab 1-on-1 coaching right now!
</p>
<p style="font-size:18pt;text-align:center;font-weight:bold;">
<span class="red">SPECIAL OFFER:</span> Get THREE 1-hour Coaching Sessions (1-on-1) 
for Just <span style="text-decoration:line-through">$397/Hour</span> $297 - ONE TIME!!
</p>
<p style="text-align:center">
  <button mat-button class="buy-button" (click)="openDrawer('6')" style="font-size:18pt;padding:20px 20px 20px 20px;background-color:#eee;border-radius:10px;" color="primary" type="button">
      YES! I Want To Accelerate My Success <br>
      With Private 1-on-1 Coaching!<br>
      (CLICK HERE NOW TO GET STARTED)
  </button>
</p>

