import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations'
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  template: `
  <div #myNotificationsDiv [@visibilityChanged]="visiblityState" (@visibilityChanged.done)="$event.element.style.display = ($event.toState ? 'block' : 'none')" (@visibilityChanged.start)="$event.element.style.display = 'block'" style="color:#fff;width:400px;position:fixed;top:50px;right:30px;border-radius:5px;" [ngClass]="{'warn':step=='warn', 'success':step=='success'}">
    <img src="/assets/images/closebutton.png" (click)="closeMe()" style="width:25px;height:25px;position:absolute;right:-10px;top:-10px;cursor:pointer">
    <div style="font-size:20pt;padding:10px;"><mat-icon style="font-size:30pt;position:relative;top:9px">{{icon}}</mat-icon>&nbsp;&nbsp;&nbsp;{{messageTitle}}</div>
    <div style="font-size:14pt;padding:10px;" [innerHTML]="this.messageBody"></div>
  </div>
  `,
  styleUrls: ['./notifications.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('* => *', animate('1000ms ease'))
    ])
  ]
})
export class NotificationsComponent implements OnInit {
  visiblityState:boolean=false;
  messageTitle:String="";
  messageBody;
  duration=7000;
  icon;
  step:String=null;
  subscription;
  @ViewChild('myNotificationsDiv') private myNotificationsDivElement: ElementRef;

  constructor(private notificatonsService:NotificationsService) {
    this.subscription = this.notificatonsService.getMessage()
    .subscribe(mymessage => {
      var message = JSON.parse(mymessage);
      this.showMessage(message['title'],message.body,message.type, message.icon, message.duration, function(){})
    })
  }

  closeMe(){
    this.visiblityState = false;
  }

  showMessage(messageTitle:String,messageBody:String,step:String, icon:string, duration, cb) {
    this.visiblityState = true;
    messageTitle=messageTitle;
    this.messageBody=this.messageBody;
    this.icon=icon;
    this.step=step;
    if (duration){
      this.duration=duration;
    }
    setTimeout(() => {
      this.visiblityState = false;
      cb();
      
    },this.duration) 
  }

  onAnimationDone($event){
    console.log($event, this.visiblityState);
   if ($event.fromState==="hidden"){
     console.log("checkoing:",$event.fromState);
     // this.myNotificationsDivElement.nativeElement.remove();
    }
  }

  ngOnInit() {
    //this.showMessage(messageTitle,this.messageBody,'success', 'check_circle', function(){})
  }

  ngOnDestroy() {
    
    this.subscription.unsubscribe();
    
  }

}
