<div class="container main-container">

  <div style="width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
    <div style="width:100%;height:40px;">
      <div style="float:left;height:40px;">
        <button mat-button color="primary" (click)="refresh()">Refresh</button> <button mat-button color="primary" (click)="addRecord()">Add</button>
      </div>
      <div style="float:right;height:40px;font-size:18pt;font-weight:bold;">
        Email Management
      </div>
    </div>
    <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);;" class="ag-theme-blue" id="myGrid"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     
                     enableColResize
                     enableSorting
                     autoSizeColumns="true"
                     enableRangeSelection="true"
                     suppressRowClickSelection
                     enableFilter
                     toolPanelSuppressValues
                     toolPanelSuppressGroups
                     debug="false"
                     draggable="true"
                     rowHeight="27"
                     (rowClicked)="catchClick($event)"
                     rowSelection='multiple'>
    </ag-grid-angular>
  </div>

</div>

<div id="myModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title">Confirmation</h4>
      </div>
      <div class="modal-body">
        <p>Do you want to save changes you made to document before closing?</p>
        <p class="text-warning"><small>If you don't save, your changes will be lost.</small></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
