
<div style="width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
    
    <div style="width:100%;height:40px;">
        <div style="float:left;height:40px;">
          <button mat-flat-button color="primary" (click)="refresh()">Refresh</button>&nbsp; <button mat-flat-button color="primary" (click)="addRecord()">Add</button>&nbsp; <app-crmnav></app-crmnav>
        </div>
        <div style="float:right;height:40px;font-size:18pt;font-weight:bold;">
          CRM
        </div>
      </div>
  
    <mat-select *ngIf="showStatus" color="primary" style="width:25%" (selectionChange)="getStatusCrms()" name="category" #status   id="status" placeholder="Choose a Status"  [(ngModel)]="currentStatus" floatPlaceholder="auto"  ngDefaultControl>
      <mat-option  *ngFor="let status of statuses" value="{{status.value}}">{{status.name}}</mat-option>
    </mat-select>
  
  <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);;" [class]="theme" id="myGrid"
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   
                   enableColResize
                   
                   [pagination]="true"
                   [paginationPageSize]= "paginationSize"
                   
                   debug="false"
                   draggable="false"
                   rowHeight="150"
                   (rowClicked)="addComments($event)"
                   rowSelection='multiple'
                   (gridReady)="onGridReady($event)"
                   >
  </ag-grid-angular>


<app-loader [loading]="loading"></app-loader>



