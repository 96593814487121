export class Systempreference {
  systempreferenceId: string;
  key: string;
  value: string;
  userId: string;
  _id: string;
  encrypted: boolean;

  constructor (systempreferenceId?: string, key?: string, value?: string, userId?: string, _id?: string, encrypted?: boolean) {
    this.systempreferenceId = systempreferenceId;
    this.key = key;
    this.value = value;
    this.userId = userId;
    this._id = _id;
    this.encrypted = encrypted;
  }
}
