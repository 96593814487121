import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Request } from "./request";
import {RequestService} from "./request.service";
import {GridOptions} from "ag-grid-community";
import {RequestInputComponent} from "./request-input/request-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  theme;
  paginationSize;
  public gridOptions: GridOptions;
  constructor(private confirmService: ConfirmService, private requestService:RequestService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.theme="ag-theme-dave";
    this.paginationSize="50";
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "created_at", field: "created_at", cellRenderer: this.renderDate, cellStyle: this.changeRowColor, sort: 'desc'},
      {headerName: "Domain", field: "domain",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Username", field: "username",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Type", field: "type",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "First Name", field: "firstname",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Last Name", field: "lastname",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Phone", field: "phone",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Notes", field: "notes",editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Status", field: "status",editable: true,sortable: true, filter:true, resizable:true},
      {
        headerName: "Actions",  template: `<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
             </button>
             
             <button md-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.requestService.getAllRecordsByDomain()
      .subscribe(
        data => {
          this.data = data['obj'];
          console.log(this.data);
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(RequestInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.requestService.editRequest(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.requestService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    this.requestService.getAllRecordsByDomain()
      .subscribe(
        data => {
          this.data = data['obj'];
          console.log(this.data);
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(RequestInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }


  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  changeRowColor(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red', 'color':'#ffffff'};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow', 'color':'#000000'};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474', 'color':'#ffffff'};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3', 'color':'#000000'};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac', 'color':'#ffffff'};
      }
      case 'Sold': {
        return {'background-color': '#87c947', 'color':'#000000'};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513', 'color':'#ffffff'};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B', 'color':'#ffffff'};
      }
      case 'No Show': {
        return {'background-color': '#F88F09', 'color':'#ffffff'};
      }
    }
  }

  ngOnInit() {
  }

}
