<div class="center top-spacer">
    <span class="t2 bold">YOUR AFFILIATE LINK IS:</span>
    <br/>
    <a class="t3 bold" href="{{webinarlink}}" target="_blank">{{webinarlink}}</a>
    <p class="bold"></p>
  <br/><br/>
  <section style="margin:auto;width:90%">
      <p style="font-size:18pt;color:#c03;font-weight:bold">
      PROMOTIONAL TOOL #1 (EMAIL)
      </p>
      <br>
      <p class="body-txt">
        You can use the following emails to send to your list of subscribers who are interested in making money online.
      </p>
  </section>
  
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 1
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: Easier Than 'Copy & Paste'? 
        <br><br> 

        You've never seen anything this easy!<br> 
        <br> 
        Stop whatever you're doing...<br> 
        <br> 
        Put in a DVD for the kids...<br> 
        <br> 
        Turn off your phone...<br> 
        <br> 
        Remove all distractions because you're REALLY going to want to see this!<br> 
        <br> 
        ==> {{webinarlink}}<br> 
        <br> 
        You're about to discover a 100% newbie-friendly method that can build your<br> 
        email list and generate affiliate commissions...<br> 
        <br> 
        All on COMPLETE auto-pilot!<br> 
        <br> 
        There has literally NEVER been a system for building a cash-sucking,<br> 
        list-building, internet business as simple as this ....PERIOD!!<br> 
        <br> 
        And the best part... is that everything 100% done-for-you (yes, I'm serious)<br> 
        <br> 
        I know -it sounds crazy, but just take a few minutes out of your day and<br> 
        join this free training<br> 
        <br> 
        Here are the details<br> 
        ==> {{webinarlink}}<br> 

        <br> 
        Sincerely, 

  
  
        
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 2
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: The easy way to get PayPal Commissions - [Free Training]<br> 

<br> 
If you really want to start generating auto-pilot sales online<br> 
(and I'm guessing you do or you wouldn't be reading this) then<br> 
this is the ideal first step...<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
Not only will it give you everything you need to start building<br> 
your list and pocketing affiliate commissions, buy you'll also<br> 
have SOMEONE ELSE DOING ALL THE WORK FOR YOU!<br> 
<br> 
Plus, you'll have the potential to earn multiple types of commissions<br> 
(even commissions of up to $397 per sale or more!)<br> 
<br> 
This is only going to be available for a limited time, so make sure<br> 
to check it out now:<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
Sincerely,<br> 
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 3
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: See you in 15 minutes...<br> 
<br> 
Does adding thousands of people to your email on complete auto-pilot<br> 
sound appealing?<br> 
<br> 
How about pocketing affiliate commissions on auto-pilot too... does<br> 
that sound good?<br> 
<br> 
If so, then I’ve got a special training lined up for you today, it's<br> 
starting in about 15 minutes.<br> 
<br> 
JOIN THE TRAINING NOW >>><br> 
==> {{webinarlink}}<br> 
<br> 
On this training, you are going to see a powerful, but simple method<br> 
that ANYONE can do, no matter where you live, how young or old you are,<br> 
and most importantly, your level of experience.<br> 
<br> 
This is PERFECT for beginners, but also works great for intermediate<br> 
to advanced folks.<br> 
<br> 
The reason is because it requires zero online marketing skills.<br> 
<br> 
You are going to learn an online method that can instantly put<br> 
profits in your pocket but requires:<br> 
1) No SEO<br> 
2) No Facebook Ads<br> 
3) No Google Ads<br> 
4) No Techie Stuff<br> 

<br> 
The secret to this method is that someone else is doing all of the<br> 
heavy lifting FOR YOU!<br> 
<br> 
I know, it sounds crazy, but just attend the training right now<br> 
and you'll see exactly what I'm talking about<br> 
<br> 
The great thing is that it will only take you about 20 minutes to<br> 
understand exactly how the system works.<br> 
<br> 
This is l-i-t-e-r-a-l-l-y the Easiest System Ever<br> 
<br> 
See you on the training :-)<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
Sincerely,<br>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 4
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: Why do people who hate their job never quit?<br> 
<br> 

An all to familiar story -<br> 

My dad spent his life working for a giant company - wasting away his life filling out forms,<br> 
signing papers, wading through red tape, waiting for someone to get back to him, bangin'<br> 
his head on a desk...<br> 
<br> 
The pay was ok, but he hated it.<br> 
<br> 
Over dinner, he'd always complain about his boss never thinking outside the box.<br> 
<br> 
He really wanted to get out.<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
But he never did. He worked there as a mid-level employee for 43 years until he retired.<br> 
Upon retirement he was awarded a gratitude certificate and a measly pension which wasn't<br> 
enough to maintain the same lifestyle. He was forced to get a part time job at Wal-Mart.<br> 
<br> 
Sad.<br> 
<br> 
Got me thinking.<br> 
<br> 
What makes someone wake up in the morning and work a job they absolutely hate and do nothing<br> 
to find another job or start a business?<br> 
<br> 
How come most people you know dread what they do and do it anyway... often for decades and<br> 
sometimes for a lifetime?<br> 
<br> 
Is it fear?<br> 
<br> 
Lack of vision?<br> 
<br> 
Lack of self-confidence?<br> 
<br> 
All of the above?<br> 
<br> 
Perhaps.<br> 
<br> 
I don't know what makes people settle for being miserable, but I'm positive if they're<br> 
presented with a an opportunity that makes sense, if they're serious about wanting a change,<br> 
they'll act on it, even if they don't have the experience, the money or the free time to<br> 
do it, at first.<br> 
<br> 
They'll take a calculated risk rather than settle for a guaranteed misery.<br> 
<br> 
What kind of opportunity?<br> 
<br> 
An opportunity like this one...<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
What about it would make a trapped person feel confident to break out?<br> 
<br> 
Few things.<br> 
<br> 
First, the guarantee.<br> 
<br> 
It's pretty much risk-free and fool proof.<br> 
<br> 
Second, it doesn't require you to quit your safe and predictable job.<br> 
<br> 
You can start in your spare time and increase the time input as you go... and as you see results.<br> 
<br> 
Third, it's super beginner friendly.<br> 
<br> 
You don't need to go to school or anything.<br> 
<br> 
Of course, you will have to learn some new skills. It could make some people nervous. Which is<br> 
okay. But soon they'll realize that they can't reach a new income level using the exact same<br> 
skills they've got now.<br> 
<br> 
To find out more about this, just Click Here<br> 
<br> 
==> {{webinarlink}}<br> 
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 5
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: Tired of failing? This system is the solution you need :-)<br> 
<br> 
Congratulations<br> 
<br> 
You may not realize it yet, but you've just stumbled across the EXACT system<br> 
for profiting online that you've been looking for.<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
Whether you've been trying to find success online for 5 days, or for 5 years,<br> 
there are few things that hold true...<br> 
<br> 
1. Most people don't want to do stuff like<br> 
- building your website<br> 
- setting up your opt-in page<br> 
- getting a domain<br> 
- getting traffic<br> 
- creating a give-away<br> 
- writing a follow-up sequence<br> 
- fining products to sell<br> 
- dealing with customer support<br> 
- fighting with merchant accounts<br> 
- using complicated tools to track/optimize<br> 
- etc...<br> 

<br> 
2. If I can hand you a system that builds your list FOR YOU, and generates<br> 
affiliate commissions FOR YOU - then, yes, I did just hand you EXACTLY<br> 
what you were looking for<br> 
<br> 
So again I say...<br> 
<br> 
CONGRATULATIONS!<br> 
<br> 
You've just stumbled across the EXACT system you've been looking for<br> 
<br> 
The only thing left to do is CLICK HERE TO ACCESS IT >>><br> 
<br> 
{{webinarlink}}<br> 
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon> Email 6
        </ng-template>
        <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
            SUBJECT: The LESS Experience You Have - The MORE You'll Probably Make<br> 
<br> 
I know...<br> 
<br> 
It sounds crazy<br> 
<br> 
Normally there's a direct relationship between the amount of experience<br> 
someone has and the amount they earn<br> 
<br> 
But this system is different<br> 
<br> 
Why?<br> 
<br> 
Because what we've found is that the people who know the LEAST are<br> 
more likely to not question what we tell them to do.<br> 
<br> 
And because they don't get in their own way, they end up getting<br> 
better results.<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
We even had one student who grew his list to 500 people and made<br> 
12 affiliate sales, all in his first 30 days!<br> 
<br> 
How'd he do it?<br> 
<br> 
Simple - We told him to sit back and let us do all the heavy lifting,<br> 
and because he was new (and didn't know any better) HE LISTENED!<br> 
<br> 
Am I'm guaranteeing the same results for you?<br> 
<br> 
No.<br> 
<br> 
But what I am saying is that if you can promise not to get in your<br> 
own way, I'll show you the easiest way to add thousands of people<br> 
to your email list every month, and start potentially making affiliate<br> 
sales on full auto-pilot<br> 
<br> 
Sound good?<br> 
<br> 
If so, you can go here to get all the details<br> 
<br> 
==> {{webinarlink}}<br> 
        </div>
      </mat-tab>
      <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>mail</mat-icon> Email 7
          </ng-template>
          <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
              SUBJECT: Open if you NEED results FAST....<br> 

<br> 
Hey,<br> 
<br> 
You need to see this<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
If you're involved in this whole "make money online world", and you're desperate<br> 
to find something that can START WORKING IMMEDIATELY....<br> 
<br> 
Then you need to check out this online web-class<br> 
<br> 
Trust me, it's not what you think...<br> 
<br> 
I promise you... no hype, just 100% real talk<br> 
<br> 
Click here to grab your spot >>><br> 
<br> 
==> {{webinarlink}}<br>
          </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>mail</mat-icon> Email 8
            </ng-template>
            <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                SUBJECT: Webinar Starting...<br> 
<br> 
It's time<br> 

The special webinar training, which reveals the EASIEST SYSTEM EVER<br> 
for getting FAST results in your online business is starting<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
Get ready to have your mind blown, seriously. I<br> 
<br> 
You're probably going to email me after the webinar and thank me for<br> 
inviting you to attend. This is THAT GOOD!<br> 
<br> 
I have never seen something so easy that produces such amazing results<br> 
for everyone that does it, not just one or two people, but literally<br> 
every single person that does it.<br> 
<br> 
One guy even grew his email list to 500 people and made his first<br> 
12 affiliate sales in less than 30 days!<br> 
<br> 
Don’t take my word though, join us now and see for yourself.<br> 
<br> 
==> {{webinarlink}}<br> 
            </div>
          </mat-tab>
          <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon>mail</mat-icon> Email 9
              </ng-template>
              <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                  SUBJECT: Rocket Fuel<br> 
<br> 

You've read the internet marketing success stories.<br> 
<br> 
You've read the blogs of successful internet marketers.<br> 
<br> 
You've seen them at marketing events.<br> 
<br> 
You've watched their videos.<br> 
<br> 
And my guess is...you soooo want to be like them.<br> 
<br> 
You want to be visibly successful too!<br> 
<br> 
Well, it CAN happen.<br> 
<br> 
But you need to understand the ROCKET FUEL that powers<br> 
their success<br> 
<br> 
It has almost nothing to do with advanced strategies or<br> 
so called "expert" techniques...<br> 
<br> 
Click here to see what I mean >>><br> 
<br> 
==> {{webinarlink}} 
<br> 
              </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon>mail</mat-icon> Email 10
                </ng-template>
                <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                    SUBJECT: Are they really GUARANTEEING results?<br> 
<br> 
This is crazy<br> 
<br> 
The creators of this system are so confident that it will work...<br> 
<br> 
That they're willing to GUARANTEE you get results<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
All you need to do is get them a few simple items that shouldn't<br> 
take you more than about 20 minutes<br> 
<br> 
Then, they'll set up a full-automated internet business for you.<br> 
<br> 
And if you don't see people on your list and you haven't made your<br> 
first sale within 30 days of getting them what they need...<br> 
<br> 
They'll give you your money back and let you keep the system for free<br> 
<br> 
What I'm saying might sound a little crazy, but I'll prove to<br> 
you, RIGHT NOW, that it's true<br> 
<br> 
Trust me when I tell that this is ABOSLUTELY worth your time<br> 
<br> 
==> {{webinarlink}}<br> 

<br> 
                </div>
              </mat-tab>
              <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon>mail</mat-icon> Email 11
                  </ng-template>
                  <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                      SUBJECT: Not sure if this is for you, but...<br> 
<br> 
Several people emailed me asking if The Easiest System Ever is a good fit for them<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
I don't know if this is right for you, but here's something that may help get a better idea.<br> 
<br> 
This is for you:<br> 
<br> 
If you've been trying to market income opportunities for at least a year and you're still<br> 
nowhere near close to hitting your goals (or even making your first sale)<br> 
<br> 
If you've attempted several strategies and each time bumped against the same challenge -<br> 
getting people to take you seriously and to pay attention<br> 
<br> 
If you've been following all the advice given by all of the so called "gurus" - but haven't<br> 
gotten any results<br> 
<br> 
If you are tired of guesswork<br> 
<br> 
If you are spending more money than you're earning online... and it's been that way for<br> 
a while<br> 
<br> 
If you desperately need to start putting yourself out there and getting your s*** together,<br> 
but you just can't get around to doing it yourself<br> 
<br> 
Go here for details<br> 
==> {{webinarlink}}<br> 
<br> 
                  </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-icon>mail</mat-icon> Email 12
                    </ng-template>
                    <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                        SUBJECT: Hands-off method makes affiliate sales FOR YOU<br> 
<br> 

Want to pocket affiliate commissions on complete auto-pilot?<br> 
<br> 
This is for you<br> 
<br> 
Need a bigger list?<br> 
<br> 
This is for you<br> 
<br> 
Want to have someone else do all of the set-up, copywriting,<br> 
'heavy-lifting' and "techie stuff"<br> 
<br> 
This is for you<br> 
<br> 
==> {{webinarlink}}<br> 
<br> 
I'll make you ONE PROMISE...<br> 
<br> 
You've never seen anything this easy!<br> 
<br> 
So stop whatever you're doing...<br> 
<br> 
Put in a DVD for the kids...<br> 
<br> 
Turn off your phone...<br> 
<br> 
And remove all distractions because you're REALLY going to want to<br> 
give your undivided attention to this online training<br> 
<br> 
You'll thank me for it :-)<br> 
<br> 
==> {{webinarlink}}<br>  
                    </div>
                  </mat-tab>
                  <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon>mail</mat-icon> Email 13
                      </ng-template>
                      <div style="margin:0 auto;width:50%; text-align:left;margin-top:20px;">
                          SUBJECT: 10 to 1 you thank me for this...<br> 
<br> 
I'd like to make a bet.<br> 
<br> 
I'd like to bet that after you attend, you will thank me for giving you the access<br> 
link to this private training event...<br> 
<br> 
Register here<br> 
==> {{webinarlink}}<br> 
<br> 
On this web-class, Devon Brown (a.k.a. "The King of Making Things Easy") is going<br> 
to show you a DONE-FOR-YOU system that not only adds people to your email list on<br> 
full auto-pilot...<br> 
<br> 
... but also generates automatic affiliate commissions too!<br> 
<br> 
WHAT'S EVEN CRAZIER is that this system is so newbie friendly, that people with LESS<br> 
experience who use it, are often more successful than people with YEARS of experience<br> 
<br> 
I'll make you 2 promises<br> 
<br> 
PROMISE #1: You've NEVER seen anything this simple or easy before<br> 
<br> 
PROMISE #2: Once you've finished watching, you'll thank me for sending you this!<br> 
<br> 
The training is about to start<br> 
<br> 
GRAB YOUR SPOT HERE<br> 
==> {{webinarlink}}<br> 
                      </div>
                    </mat-tab>
                    
  
    </mat-tab-group>
  </div>
  
  <br><br>
  <section style="margin:auto;width:90%">
    <p style="font-size:18pt;color:#c03;font-weight:bold">
    PROMOTIONAL TOOL #2 (BANNER ADS)
    </p>
    <br>
    <p class="body-txt">
    Use the following pre-made banners in as many places as you can on the Internet. Simply copy and paste the banners/banner code you'd like to use form the choices below. You can advertise with banner ads on many different websites a Including: <a href="http://TheBannerExchange.net" target="_blank">http://TheBannerExchange.net</a>, <a href="http://AdBrite.com" target="_blank">http://AdBrite.com</a>, <a href="http://ExchangeAd.com" target="_blank">http://ExchangeAd.com</a>, <a href="http:///TheMediaCrew.com" target="_blank">http:///TheMediaCrew.com</a>, <a href="http://www.Facebook.com/ads" target="_blank">http://www.Facebook.com/ads</a>, and <a href="http://www.AdReady.com" target="_blank">http://www.AdReady.com</a>.
    <br><br>
    And don't forget to place these banners all over <b><u>YOUR</u></b> website or blog!
    <br><br>
  
  
    </p>
    <br><br>
    <br>
  
    <div style="text-align:center">
      <span class="medium">468x60 Banner</span><br><br>
        <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-468x60-1.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-468x60-1.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">120x600 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-120x600.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-120x600.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">160x600 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-160x600.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-160x600.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">200x200 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-200x200.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-200x200.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">728x90 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-728x90-1.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-728x90-1.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">300x250 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-300x250.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-300x250.jpg" border="0"></a>
      </textarea><br><br><br>
  
      <span class="medium">250x250 Banner</span><br><br>
      <a target="_blank" href="{{webinarlink}}"><img border="0" src="http://esev2.s3.amazonaws.com/banners/ese-250x250.jpg" /></a><br><br>
      "Left Click" to Select Code Then "Right Click" To Copy<br>
      <textarea name=mytextarea1 cols=80 rows=5 readonly onClick="this.select()">
      <a href="{{webinarlink}}"><img src="http://esev2.s3.amazonaws.com/banners/ese-250x250.jpg" border="0"></a>
      </textarea><br><br><br>
      
      
      
    </div>
  </section>