import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redcomponent',
  templateUrl: './redcomponent.component.html',
  styleUrls: ['./redcomponent.component.css']
})
export class RedcomponentComponent implements OnInit {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }
  constructor() { }

  ngOnInit() {


  }

}
