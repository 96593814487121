<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <div class="userActionButtonGroup">
    <button mat-flat-button color="primary" type="submit" [disabled]="!f.valid">
      <mat-icon  class="material-icons">done</mat-icon> Save
    </button>
    &nbsp;
    <button mat-flat-button color="primary" mat-dialog-close>
      <mat-icon  class="material-icons">cancel</mat-icon> Close
    </button>
  </div>
  <mat-dialog-content>
    <input type="hidden" name="domainsId" #domainsId="ngModel" [ngModel]="domains?._id" id="domainsId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="domains?.name" type="text" id="name" placeholder="Enter the Name or Title" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput name="domain" #domain="ngModel" [ngModel]="domains?.domain" type="text" id="domain" [disabled]="disableDomain" placeholder="Enter the Your Domain." required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-select  (selectionChange)="registrarSelected1($event)" name="registrar" #registrar [ngModel]="domains?.registrar"  id="registrar" placeholder="Choose a Registrar"  floatPlaceholder="auto"  ngDefaultControl>
        <mat-option value="none">None</mat-option>
        <mat-option value="godaddy">GoDaddy</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox *ngIf="addOrEdit=='add'" name="homepage" (click)="clickHomePage($event)" (change)="changeHomePage($event)" [(ngModel)]="creatHomePage">Do you want to create a home page for this domain?</mat-checkbox><br><br>
      <mat-label style="font-size:18pt">Choose Your Niche</mat-label><br>
      <mat-checkbox *ngFor="let niche of niches" (click)="clickNiche($event, niche.viewValue, niche.viewValue, niche.isChecked)" (change)="getNiche($event,niche.viewValue )" style="padding-left:20px;" [value]="niche.value" [checked]="niche.isChecked" [attr.data-description]="niche.viewValue">{{niche.viewValue}}</mat-checkbox>
      
    <br>
    <div *ngIf="addOrEdit=='edit'">
    <mat-form-field style="width:80%">
      <mat-label style="font-size:18pt">Template for Homepage&nbsp;<img *ngIf="showTemplateLoader" src="/assets/images/ajax-loader-black.gif"></mat-label>
      <mat-select (selectionChange)="templateSelected1($event)" name="template" #template="ngModel" [ngModel]="domains?.template" id="template" class="example-full-width">
        
        <mat-option [value]="nullValue">None</mat-option>
        <mat-option *ngFor="let template of templates" [value]="template._id">
          {{template.name}}
        </mat-option>
      </mat-select>
      
    </mat-form-field>

    &nbsp;<button *ngIf="hasTemplate" mat-flat-button (click)="editTemplate()" color="primary" type="button">
      <mat-icon  class="material-icons">edit</mat-icon>Edit
    </button>

    <button *ngIf="!hasTemplate" mat-flat-button (click)="createHomepageClick()" color="primary" type="button">
      <mat-icon  class="material-icons">create_new_folder</mat-icon>Create
    </button>

    </div>
    <div *ngIf="showDefaultRegistrar">
      <mat-form-field class="example-full-width">
        <mat-label>Customer Number</mat-label>
        <input matInput name="customerNo" #customerNo="ngModel" [ngModel]="domains?.customerNo" type="text" id="customerNo" class="example-full-width">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>API Key</mat-label>
        <input matInput name="apikey" #apikey="ngModel" [ngModel]="domains?.apikey" type="text" id="apikey" class="example-full-width">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>Secret</mat-label>
        <input matInput name="apisecret" #apisecret="ngModel" [ngModel]="domains?.apisecret" type="text" id="apisecret" class="example-full-width">
      </mat-form-field>
    </div>
    <br><br>


  </mat-dialog-content>
  
</form>
<app-notifications></app-notifications>