import { Component, OnInit, AfterViewInit } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {GridOptions} from "ag-grid-community";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import { ChatService } from './chat.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ChatComponent } from '../chat/chat.component';
import * as io from "socket.io-client";
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';

@Component({
  selector: 'app-chatqueue',
  template: `
  <div>
    <app-chat [myRoom]="myRoom" [myName]="myName" *ngIf="showChat" (logOut)="logOut($event)"></app-chat>
  </div>
        <div style="width: 100%;padding-left:10px;padding-right:10px;padding-top:20px;">
        <div style="width:100%;height:40px;">
            <div style="float:left;height:40px;">
                <button mat-button color="primary" (click)="refresh()">Refresh</button>&nbsp;<mat-slide-toggle [checked]="toggleOnlineChecked" (change)="toggleOnline($event)">Chat Online</mat-slide-toggle>
            </div>
            <div style="float:right;height:40px;font-size:18pt;font-weight:bold;">
                Chat Queue
            </div>
            </div>
        <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);;" class="ag-theme-blue" id="myGrid"
                        [gridOptions]="gridOptions"
                        [columnDefs]="columnDefs"
                        enableColResize
                        enableSorting
                        autoSizeColumns="true"
                        enableRangeSelection="true"
                        suppressRowClickSelection
                        enableFilter
                        toolPanelSuppressValues
                        toolPanelSuppressGroups
                        debug="false"
                        draggable="false"
                        rowHeight="27"
                        (rowClicked)="catchClick($event)"
                        rowSelection='multiple'>
        </ag-grid-angular>
        </div>
  `,
  styleUrls: ['./chat.component.css']
})
export class ChatQueueComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  gridOptions: GridOptions;
  myRoom:String
  myName:String;
  showChat:Boolean = false;
  ioToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  socket = io('http://localhost:3283',{query: {token: this.ioToken}});
  managesocket = io('http://localhost:3284',{query: {token: this.ioToken}});
  managesocket2 = io('http://localhost:3285');
  userRecord = JSON.parse(localStorage.getItem('userRecord'));
  toggleOnlineChecked;

  constructor(private systempreferenceService: SystempreferenceService, private bottomSheet: MatBottomSheet,private confirmService: ConfirmService,   private chatService: ChatService, private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "ID", field: "_id", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Room", field: "room", sortable: true, filter:true, resizable:true},
      {headerName: "First Name", field: "firstname", sortable: true, filter:true, resizable:true},
      {headerName: "Last Name", field: "lastname", sortable: true, filter:true, resizable:true},
      {headerName: "Subject", field: "subject", sortable: true, filter:true, resizable:true},
      {headerName: "Status", field: "status", sortable: true, filter:true, resizable:true},
      {headerName: "created", field: "created_at", cellRenderer: this.renderDate, sortable: true, filter:true, resizable:true},
      {
        headerName: "Actions", width: 100, template: `<button md-button color="primary" type="button" data-action-type="chat" style="height:15px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Chat
             </button>
             
             <button md-button color="primary" type="button" style="height:15px;font-size:8pt;background-color:transparent;border:none;" data-action-type="delete" data-toggle="modal" data-target="#myModal">
             <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
             </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };
    var status={status:'waiting'}
    this.chatService.getAllQueueRecordsByStatus(status)
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          this._service.success(data['title'], data['message'])
        },
        error => this._service.error(error.error['message'], error['title']),
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })

    
  }

  public toggleOnline($event){
    console.log($event.checked)
    var chatOnlineVariable;
    if ($event.checked==true){
      chatOnlineVariable=true
    } else {
      chatOnlineVariable=false;
    }

    var systempreference={key:'chatonline',value:chatOnlineVariable}
    this.systempreferenceService.updateKey(systempreference)
      .subscribe(
        data => {
          console.log("testdata:",data);
          this.managesocket.emit('chatstatus', { chatonline: chatOnlineVariable });
          this.managesocket2.emit('chatstatus', { message: "Dave Sloan Just Joined" });
        },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
      );
  }

  public logOut($event){
    this.refresh();
    console.log("event in chatqueu",$event)
    this.showChat=false;
    localStorage.removeItem("_12ma_chat_user");
    localStorage.removeItem("_12ma_chat_queue");
    
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionLevel = event.event.target.getAttribute("data-action-type");

      switch(actionLevel) {
        case "chat":
          return this.onActionChatClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
      }
    }
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  public onActionChatClick(data: any){
    console.log(data);
    this.myRoom=data.room;
    this.myName=this.userRecord.firstname + " " + this.userRecord.lastname;
    this.showChat=true;
    var body={_id:JSON.parse(localStorage.getItem('_12ma_chat_queue'))._id, status:'ended' }
    this.chatService.updatestatusonqueuerecord(body)
          .subscribe(
            data => {
              this.data = data['ob'];
              this._service.success("You are Online", "You have joinded the chat");

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.chatService.deleteQueueRecord(data._id)
          .subscribe(
            data => {
              this.data = data['ob'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  refresh(){
    var status={status:'waiting'}
    console.log('refresh:', status)
    this.chatService.getAllQueueRecordsByStatus(status)
      .subscribe(
        data => {
          console.log(data);
          this.data = data['obj'];
          this._service.success(data['title'], data['message']);
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }



  ngOnInit() {
    var systempreference={key:'chatonline'}
    this.systempreferenceService.getKey(systempreference)
      .subscribe(
        data => {
          console.log("my data:",data,data['obj'].value);
          this.toggleOnlineChecked=data['obj'].value;
          
        },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
      );

    this.managesocket.on('chataction', function (data) {
      console.log('manage data', data)
      if (data['message'].action==="logout"){
        console.log('chatqueue-logout', data)
        this.refresh();
      }
      
    }.bind(this));

    this.socket.on('new-message', function (data) {
      console.log('chatqueuedata', data)
      if (data['message'].action==="joined"){
        this.refresh();
      }

    }.bind(this));
  }

  ngAfterViewInit(){
    
  }

}
