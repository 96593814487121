import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import {AuthService} from "../../auth/auth.service";
import {UrlshortenerService} from "../urlshortener.service";
import {Urlshortener} from "../urlshortener";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-urlshortener-input',
  templateUrl: './urlshortener-input.component.html',
  styleUrls: ['./urlshortener-input.component.css']
})
export class UrlshortenerInputComponent implements OnInit {
  urlshortener:Urlshortener = null;
  username; 
  

  constructor(private urlshortenerService:UrlshortenerService, private authService: AuthService, private _service: ToastService) {
   this.username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
   console.log(this.username);
    urlshortenerService.urlshortenerIsEdit.subscribe(
      urlshortener => {
        this.urlshortener = urlshortener;
      })

  }

  save(f:NgForm, model: Urlshortener, isValid: boolean) {
    // check if model is valid
    // if valid, call API to save customer
    console.log(model);
    if (model.urlshortenerId){

      this.urlshortenerService.updateRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title'])
          },
          error => this._service.error(error['message'], error['title']),

        )
      console.log(model, isValid);
    } else {

      this.urlshortenerService.addRecord(model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            f.reset();
          },
          error => this._service.error(error['message'], error['title']),

        )
    }
  }

  ngOnInit() {
  }

}
