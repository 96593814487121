
<div style="width:80%;margin:auto;position:absolute;top:50px;height:auto;">
    <div style="width:100%;margin:auto;position:relative">
      <img src="{{url}}" style="max-width:100%;height:auto;">
      <img *ngIf="imagePreview" style="position:absolute;right:50px;max-width:70%;height:80vh;" [src]="sanitizer.bypassSecurityTrustUrl(imagePreview)">
  
      
        <div style="position:absolute;top:0px;left:0px;height:83px;width:25%;">
          <button style="width:100%" class="large-button" type="button" mat-button color="primary" (click)="imgFileInput.click()">Choose Media</button>
          <input style="display:none" id="photo" type="file" #imgFileInput (change)="onImageChange($event)" accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.csv,.txt,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
          text/plain, application/pdf" />
          <br>
          <button style="width:100%" mat-button type="button" color="primary" class="large-button" (click)="upload()" [disabled]="uploaded">
            <span *ngIf="!progress">Upload</span><span class="spinner-border text-success" *ngIf="progress"><span class="sr-only">Loading...</span></span>
          </button>
          <br>
          <button style="width:100%" (click)="onCloseButtonClick($event)" mat-button type="button" color="primary" class="large-button" >
            Cancel
          </button>
        </div>
    </div>
  </div>
  <div style="clear:both"></div>
  <div *ngFor="let file_src of file_srcs; let i = index">
    <img [attr.src]='file_src' alt=""/>
  
    <p>{{ debug_size_before[i] }} bytes <b>Before</b> </p>
    <p>{{ debug_size_after[i] }} bytes <b>After</b></p>
  </div>
  
        