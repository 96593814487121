<section>
  <p class="message">
      <strong>Welcome to the Easiest System Ever.</strong> The #1 Easiest Way to Start Making Money Online. 
      Please start by completing the easy Quickstart Checklist items below      
  </p>
  <br><br>
    <div class="table">
      <div class="header">Quickstart Checklist  <span class="required">* Indicates an  item that MUST be completed immediately</span></div>
      <div class="item" (click)="openDrawer('1')"><div class="outcheck"><img *ngIf="guarantee" class="greencheck" src="/assets/images/greencheck1.png"><div class="innercheck"></div></div><div class="item-text">*Activate Guarantee<div class="bluearrowdiv"><img class="bluearrow" src="/assets/images/bluearrow.png"></div></div></div>

      <div class="item" (click)="openAweberDrawer('2')"><div class="outcheck"><img *ngIf="aweber" class="greencheck" src="/assets/images/greencheck1.png"><div class="innercheck"></div></div><div class="item-text">*Get Us Your Aweber Auto-responder Information<div class="bluearrowdiv"><img class="bluearrow" src="/assets/images/bluearrow.png"></div></div></div>

      <div class="item" (click)="openCoachingDrawer('3')"><div class="outcheck"><img *ngIf="coaching" class="greencheck" src="/assets/images/greencheck1.png"><div class="innercheck"></div></div><div class="item-text">Redeem Your FREE 1-on-1 System Walk-Through / Coaching Session<div class="bluearrowdiv"><img class="bluearrow" src="/assets/images/bluearrow.png"></div><div class="bluearrowdiv"><img class="bluearrow" src="/assets/images/bluearrow.png"></div></div></div>

      <div class="item" (click)="openDrawer('4')" style="display:none"><div class="outcheck"><img *ngIf="overview" class="greencheck" src="/assets/images/greencheck1.png"><div class="innercheck"></div></div><div class="item-text">Watch System Overview Video<div class="bluearrowdiv"><img class="bluearrow" src="/assets/images/bluearrow.png"></div></div></div>
      
      <div class="item-bottom" (click)="openDrawer('5')"><div class="outcheck"><img *ngIf="offer" class="greencheck" src="/assets/images/greencheck1.png"><div class="innercheck"></div></div><div class="item-text">Special Traffic Offer Expires in <span *ngIf="!showTimer">00:00</span><iframe *ngIf="showTimer" [src]="frameSrc" frameborder=0 style="position:relative;top:+2px;display:inline-block;width:100px;height:22px;border:none 1px"></iframe><div class="bluearrowdiv"><img class="bluearrow" src="/assets/images/bluearrow.png"></div></div></div>
      
    </div>
    <br><br>
<p style="font-size:14pt">
    <span style="color:#c03;font-weight:bold;">NEW MEMBER NOTES:</span>
<br><br>
1. Please complete the above quick-start checklist right away! The sooner your get this done, the sooner we'll have your system set up, and the sooner you'll start seeing results.
<br><br>
2. If you have questions or need help, please click on the "Contact" menu item on the left, or call us at +1-678-894-1982. Our normal hours of operation are M-F 10AM to 6PM EST (excluding holidays).
<br><br>
3. AFTER you've completed the quick-start checklist above, you can click on the "Step-By-Step" menu item on the left. This will give you your next SUPER-EASY action steps and answer a few questions you might have.
    </p>
</section>