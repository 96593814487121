<div  [formGroup]="addForm">
    <span class="label">Menu Name</span>
    <input id="menuname" type="text" [formControl]="menuname">
    <br>
    <button (click)="onAddRow()" >add row</button>
    &nbsp;<button (click)="save()">Save</button>
    
      <table class="table">
        <thead>
          
        </thead>
        <tbody>
          <tr *ngFor="let row of addDynamicRow?.controls; let index = index;">
        <td>
            <span class="label">Type</span>
            <mat-select class="select" [formControl]="row.get('type')">
                <mat-option *ngFor="let type of typeArray" [value]="type.value">{{ type.name }}</mat-option>
            </mat-select>
        </td>
        <td>
            <span class="label">Name</span> <input [formControl]="row.get('value')">
        </td>
        <td>
            <span class="label">Link</span> <input [formControl]="row.get('link')">
        </td>
        <td>
            <span class="label">Icon</span>
            <mat-select class="select" [formControl]="row.get('icon')">
                <mat-option *ngFor="let icon of iconArray" [value]="icon.value"><mat-icon>{{icon.icon}}</mat-icon>{{ icon.name }}</mat-option>
            </mat-select>
        </td>
        <td>
            <span class="label">Page</span> <input [formControl]="row.get('page')">
        </td>
        <td style="padding-top:30px;">
        <button class="removeButton" (click)="onRemoveRow(index)">Remove</button>
        </td>
          </tr>
        </tbody>
      </table>
  
  
    </div>
  
  
 