
    <button  color="primary" mat-flat-button [routerLink]="['/manage/crm']">
      <mat-icon>image</mat-icon>
      <span>CRM</span>
    </button>&nbsp;
    <button  color="primary" mat-flat-button [routerLink]="['/manage/request']" routerLinkActive="activated-button">
      <mat-icon>image</mat-icon>
      <span>Submitted Request</span>
    </button>&nbsp;
    <button  *ngIf="showAppButton" color="primary" mat-flat-button [routerLink]="['/manage/myapplications']" routerLinkActive="activated-button">
      <mat-icon>view_list</mat-icon>
      <span>Applications</span>
    </button>
