import { Component, OnInit } from '@angular/core';
import { CommService } from '../../../services/comm.service';

@Component({
  selector: 'app-htmlcolorpicker',
  template: `
  <div class="row" style="padding-bottom:10px;">
    <div class="col-md-12">
      <mdb-checkbox [default]="true" (change)="onShowSecondColor($event)">Gradient</mdb-checkbox>
    </div>
  </div>
  <div class="row"  style="padding-bottom:10px;">
    <div class="col-md-12">
        <input type="text" [style.color]="firstColor" [style.borderColor]="firstColor"
      [cpPosition]="'bottom'"
      [cpPositionOffset]="'0%'"
      [cpPositionRelativeToArrow]="true"
      [(colorPicker)]="firstColor"
      [placeholder]="firstColorButtonText"
      (colorPickerChange)="changeFirstColor()" style="text-align:center;width:100% !important;font-size:14pt;font-weight:bold;border:solid 1px;padding:6px;border-radius:5px;cursor:pointer">
    </div>
  </div>
  <div *ngIf="showSecondColor">
    <div class="row" style="padding-bottom:10px;">
      <div class="col-md-12">
      <label style="font-weight:bold;font-size:10pt;">Type of Gradient
        <select (change)="changeTypeOfGradient($event)" [(ngModel)]="typeOfGradient" style="width:100%">
          <option *ngFor="let typesOfGradient of typesOfGradients; let i = index" [value]="typesOfGradient.value">{{typesOfGradient.name}}</option>
        </select>
      </label>
      </div>
    </div>
    <div class="row" style="padding-bottom:10px;" *ngIf="showTypesOfRadialGradients">
      <div class="col-md-12">
        <label style="font-weight:bold;font-size:10pt;">Type of Radial Gradient
          <select [(ngModel)]="gradientDirection" style="width:100%">
            <option *ngFor="let typesOfRadialGradient of typesOfRadialGradients; let i = index" [value]="typesOfRadialGradient.value">{{typesOfRadialGradient.name}}</option>
          </select>
        </label>
      </div>
    </div>
    <div class="row" style="padding-bottom:10px;" >
      <div class="col-md-12">
        <label style="font-weight:bold;font-size:10pt;">Gradient Direction
          <select [(ngModel)]="gradientDirection" style="width:100%">
            <option *ngFor="let gradientsDirection of gradientsDirections; let i = index" [value]="gradientsDirection.value">{{gradientsDirection.name}}</option>
          </select>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <input [style.color]="secondColor" [style.borderColor]="secondColor"
        [cpPosition]="'bottom'"
        [cpPositionOffset]="'0%'"
        [cpPositionRelativeToArrow]="true"
        [(colorPicker)]="secondColor"
        placeHolder="Second Color"
        (colorPickerChange)="changeSecondColor()" style="text-align:center;width:100% !important;font-size:14pt;font-weight:bold;border:solid 1px;padding:6px;border-radius:5px;cursor:pointer">
      </div>
    </div>
  </div>
`,
  styleUrls: ['./files.component.css']
})
export class HtmlColorPickerComponent implements OnInit {

  firstColor="#cccccc";
  secondColor="#cccccc";
  showSecondColor:boolean=false;
  firstColorButtonText="Background Color";
  typeOfGradient="linear-gradient";
  gradientDirection;
  showTypesOfRadialGradients:boolean=false;
  typesOfGradients = [
    {name:"Linear Gradient", value:"linear-gradient"},
    {name:"Radial Gradient", value:"radial-gradient"},
    {name:"Conic Gradient", value:"conic-gradient"}
  ]

  linearGradients = [
    {name:"Simple",value:"simple"},
    {name:"To Left", value:"to left"},
    {name:"To Bottom Left", value:"to bottom left"},
    {name:"To Top",value:"to top"},
    {name:"To Right",value:"to right"}
  ]

  typesOfRadialGradients = [
    {name:"Simple",value:'simple'},
    {name:"Ellipse",value:"ellipse"},
    {name:"Circle",value:"circle"}
  ]

  radialGradients = [
    {name:"Simple",value:"simple"},
    {name:"Closest-Side",value:"closest-side"},
    {name:"Farthest-Corner",value:"farthest-corner"},
  ]

  conicGradients = [
    {name:"Simple",value:"simple"},
    {name:"Closest-Side",value:"closest-side"},
    {name:"Farthest-Corner",value:"farthest-corner"},
  ]

  gradientsDirections=[];

  


  constructor(private commService:CommService) { 
    
  }

  changeTypeOfGradient(event){
    console.log("Gradient:",this.typeOfGradient);
    if (this.typeOfGradient=="radial-gradient"){
      this.gradientsDirections=this.radialGradients;
      this.showTypesOfRadialGradients=true;
    } else if (this.typeOfGradient=="conic-gradient") {
      this.gradientsDirections=this.conicGradients;
    } else if (this.typeOfGradient=="linear-gradient"){
      this.gradientsDirections=this.linearGradients;
      this.showTypesOfRadialGradients=false;
    }
  }

  onShowSecondColor(event){
    this.showSecondColor =! this.showSecondColor
    if (this.showSecondColor===true){
      this.firstColorButtonText="1st Color";
    } else {
      this.firstColorButtonText="Background Color"
    }
    console.log(this.showSecondColor)
    console.log(event)
  }

  changeFirstColor(){
    this.commService.sendMessage({'action':'backgroundColor', 'color':this.firstColor });
  }

  changeSecondColor(){
    var directionWithComma="";
    if (this.gradientDirection!=="simple"){
      directionWithComma = this.gradientDirection + ","
    } else {
      directionWithComma=""
    }
    
    var backgroundConfig = this.typeOfGradient + "(" + directionWithComma + this.firstColor + "," + this.secondColor + ")";
    console.log(backgroundConfig)
    this.commService.sendMessage({'action':'backgroundColor', 'color':backgroundConfig });
  }

  ngOnInit() {
    this.gradientsDirections=this.linearGradients;
    this.gradientDirection="simple";
  }

  ngViewAfterInit(){
   
  }

}
