import {Component, OnInit, Inject, EventEmitter} from '@angular/core';
import {  AuthService } from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Images } from "./images";
import {ImagesService} from "./images.service";
import {GridOptions} from "ag-grid-community";
import {ImagesInputComponent} from "./images-input/images-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import {UploadComponent} from "../upload/upload.component";
import {DOCUMENT} from "@angular/common";
import {ClipboardService} from "../../../services/clipboard.service";
import { trigger, state, transition, style, animate } from '@angular/animations'
//import { ImagesUploadComponent } from './images-upload.component/images-upload.component';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class ImagesComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  clipUrl:string = "dave";

  /*Clipboard Stuff*/
  public copyEvent: EventEmitter<string>;
  public errorEvent: EventEmitter<Error>;
  private clipboardService: ClipboardService;
  /*End Clipboard Stuff*/

  gridOptions: GridOptions;

visiblityState = 'hidden';
messageTitle;
messageBody;
icon;
step:String=null;
paginationSize=50;
showMessage(messageTitle:String,messageBody:String,step:String, icon:string, cb) {
    this.visiblityState = 'shown';
    messageTitle=messageTitle;
    this.messageBody=this.messageBody;
    this.icon=icon;
    this.step=step;
    
    setTimeout(() => {
      this.visiblityState = 'hidden';
      cb();
    },5000) 
      
  //  if (this.visiblityState === 'hidden')
  //    this.visiblityState = 'shown'
  //  else
  //    this.visiblityState = 'hidden'
  }

  constructor(clipboardService: ClipboardService, private confirmService: ConfirmService, private imagesService:ImagesService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {

    this.gridOptions = <GridOptions>{};

    /*Clipboard Stuff*/
    this.clipboardService = clipboardService;
    this.copyEvent = new EventEmitter();
    this.errorEvent = new EventEmitter();
    /*End Clipboard Stuff*/

    this.columnDefs = [
      {headerName: "Image", field: "url", cellRenderer: this.renderImage, cellStyle: {"text-align":"center"}},
      {headerName: "File Name", field: "name", sortable: true, filter:true, resizable:true},
      {headerName: "File Size", field: "size", cellRenderer: this.formatFileSize, sortable: true, filter:true, resizable:true},
      {headerName: "Updated At", field: "lastModified",cellRenderer: this.renderDate, sortable: true, filter:true, resizable:true, sort:'desc'},
      
     

      {
        headerName: "Actions", width: 100, template: `
             
              <button mat-button color="primary" type="button" data-action-type="copy" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
              <img src="https://esev2.s3.amazonaws.com/images/primary-copy.png" style="width:15px;height:15px;">&nbsp;Copy
              </button>
              <br>
              <button mat-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
                <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
              </button>
           
     `
      },

    ];
    this.gridOptions = {
      
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      enableRangeSelection: true,
      getContextMenuItems: this.getContextMenuItems,
      allowContextMenuWithControlKey: true,
      
      getRowHeight: function(params) {
        // assuming 50 characters per line, working how how many lines we need
        return 300;
      }


    };

    this.imagesService.getAllObjects()
      .subscribe(
        data => {
          console.log(data['imageInfo']);
          this.data = data['imageInfo'];
          this.gridOptions.api.setRowData(data['imageInfo']);
          this.gridOptions.api.sizeColumnsToFit();
          this.showMessage(data['title'],data['message'],'success', 'check_circle', function(){})
        },
        error => this.showMessage(error['title'],error['message'],'warn', 'warning', function(){})
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

 formatFileSize(params) {
   console.log("params:",params);
   var decimalPoint=2;
   var bytes=params.data.size;
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
 }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  getContextMenuItems(params) {
    var result = [
      { // custom item
        name: 'Alert ' + params.value,
        action: function () {window.alert('Alerting about ' + params.value); },
        cssClasses: ['redFont', 'bold']
      },
      { // custom item
        name: 'Always Disabled',
        disabled: true,
        tooltip: 'Very long tooltip, did I mention that I am very long, well I am! Long!  Very Long!'
      }, // built in copy item
      'copy'
    ];

    return result;
 }

  autoSizeAll() {
    var allColumnIds = [];
    this.columnDefs.forEach( function(columnDef) {
      allColumnIds.push(columnDef.field);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  renderImage(params){
    
    var index = params.value.indexOf('?');
    if (index!=-1){
      var url = params.value.substring(0,index);
    } else {
      url=params.value;
      var indexOfFileType=url.lastIndexOf('.');
      var fileExt=url.substring(indexOfFileType);
      console.log('fileext',fileExt);
      switch (fileExt){
        case ".pdf":
          url="https://esev2uploaded.s3.amazonaws.com/dsloan254/1580908802123.1892.cLJeyMr44Y.pdfext.png";
          break;
        case ".doc":
          url="https://esev2uploaded.s3.amazonaws.com/dsloan254/1580908738398.7544.A4qcaCuhtA.docext.png";
          break;
        case ".txt":
          url="https://esev2uploaded.s3.amazonaws.com/dsloan254/1580909199031.5188.uZiSviu577.txtext.png";
          break;
        case ".mp3":
          url="https://esev2uploaded.s3.amazonaws.com/dsloan254/1580934010677.343.PI50OBddzL.mp3ext.png";
          break;
          
      }

    }
    return '<img style="height:300px" src="'+url+'">';
  }

  renderUrl(params){
    var index = params.value.indexOf('?');
    if (index!=-1){
      var url = params.value.substring(0,index);
    } else {
      url=params.value;
    }
    return '<a href="'+url+'" target="_blank">' + url + '</a>';
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
        case "copy":
          return this.onActionCopyClick(data, "myContainer");
      }
    }
  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(ImagesInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    console.log(data);
    this.imagesService.editImages(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '175px',
      width: '275px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It?", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.imagesService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          );
      }
    });

  }

  public onActionCopyClick(data: any, containerid){

    let index = data.url.indexOf('?');
    console.log("index = " + index);
    let url = data.url;
    if (index != -1){
      console.log('okthen');
      url = data.url.substring(0,index);
    }
    this.clipUrl=url;
    this.clipboardService
      .copy( url )
      .then(
        ( value: string ) : void => {
          this.copyEvent.emit( value );
          this._service.success("Data Copied", "Success");
          this.showMessage("Success","Data Copied",'success', 'check_circle', function(){})
        }
      )
      .catch(
        ( error: Error ) : void => {
          this.errorEvent.emit( error );
          this.showMessage("Failed","Data Did Not Copy",'warn', 'earning', function(){})
        }
      );


  }

  refresh(){
    this.imagesService.getAllObjects()
      .subscribe(
        data => {
          console.log(data['imageInfo']);
          this.data = data['imageInfo'];
          this.gridOptions.api.setRowData(data['imageInfo']);
          this.gridOptions.api.sizeColumnsToFit();
          this.showMessage(data['title'],data['message'],'success', 'check_circle', function(){})
        },
        error => this.showMessage(error['title'],error['message'],'warn', 'warning', function(){})
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(ImagesInputComponent, {
      height: '95vh',
      width: '80%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  addImage(){
    let dialogRef = this.dialog.open(UploadComponent, {
      height: '95vh',
      width: '80%',

    });

    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  ngOnInit() {
  }

}
