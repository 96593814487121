import {Injectable, EventEmitter} from '@angular/core';
import {Categories} from "./categories";

import {HttpHeaders, HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class CategoriesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  categories: Categories[] = [];
  categoriesIsEdit = new EventEmitter<Categories>();
  constructor(private http: HttpClient) { }

  editCategories(categories: Categories) {
    console.log(categories)
    this.categoriesIsEdit.emit(categories);
  }

  addRecord(categories: Categories) {
    const body = JSON.stringify(categories);
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/categories/addrecord' + token, body, {headers: headers})
      
  }

  deleteRecord(categories: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.delete(this.hostName +'/api/categories/deleterecord/' + categories._id + token, {headers: headers})
      
  }

  getAllRecords() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/categories/getallrecords'+token,  {headers: headers})
     
  }

  getAllRecordsByRole(role: any) {
    const body = JSON.stringify(role);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/categories/getallrecordsbyrole' + token, body, {headers: headers})
      
  }

  getRecordByIdentiifer(identifier: any) {
    const body = JSON.stringify(identifier);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/categories/getrecordbyidentifier' + token, body, {headers: headers})
      
  }

  updateRecord(categories: Categories){
    const body = JSON.stringify(categories);
    console.log(body);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.patch(this.hostName +'/api/categories/updaterecord/' + categories.categoriesId + token, body, {headers: headers})
  }

  getRecord(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.get(this.hostName +'/api/categories/getrecordl/'+id+token,  {headers: headers})
  }

}
