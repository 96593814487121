import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-webinarfront',
  templateUrl: './webinar-front.component.html',
  styleUrls: ['./webinar-front.component.css']
})
export class WebinarFrontComponent implements OnInit {
eseid;
webinarid;
tid;
frameSrc
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { 
    this.route.params.subscribe( params => {
      console.log(params.eseid) ;
      this.eseid = params.eseid;
      this.webinarid = params.webinarid;
      localStorage.setItem('_ese_affiliate_id', this.eseid);
      localStorage.setItem('_ese_webinar_id', this.webinarid);
    });
  }

  ngOnInit() {
   
    this.tid=this.route.snapshot.queryParams['tid'];
    localStorage.setItem('_ese_tid', this.tid);
    this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl('/api/webinar/register?eseid='+this.eseid);
  }

}
