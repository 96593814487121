//import component, ElementRef, input and the oninit method from angular core
import {Component, OnInit, ElementRef, Input, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';

import {FileUploadService} from "./fileupload.service";
//import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, transition, style, animate } from '@angular/animations'
import { ToastService } from 'ng-uikit-pro-standard';

const URL = '/api/upload/upload';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class FileUploadComponent implements OnInit {
  @Output() public closeButtonClick:EventEmitter<any> = new EventEmitter();
  onCloseButtonClick($event){ this.closeButtonClick.emit('close');}
  
  url;
  thumbUrl;
  uploaded= false;
  sigingrequest;
  progress = false;
  public file_srcs: string[] = [];
  uploadedImage: File;
  imagePreview: string;

visiblityState = 'hidden';
messageTitle;
messageBody;
icon;
step:String=null;
fileType;

  constructor(
    private toastrService: ToastService, 
    public sanitizer: DomSanitizer, 
    //private ng2ImgMax: Ng2ImgMaxService, 
    private el: ElementRef, 
    private uploadService: FileUploadService, 
    private changeDetectorRef: ChangeDetectorRef
    ) { }

  // The next two lines are just to show the resize de;bug
  // they can be removed
  public debug_size_before: string[] = [];
  public debug_size_after: string[] = [];

  preUpload(){
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    let thumbEl: HTMLInputElement = this.el.nativeElement.querySelector('#thumb');
  }

  onImageChange(event) {
    let image = event.target.files[0];
    let file: File = image;
    let formData:FormData = new FormData();
   
    this.uploadedImage = file;
    this.getImagePreview(this.uploadedImage);
    
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      this.uploaded=false;
    };
  }

  upload(){
    this.progress=true;
    let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
    console.log(inputEl);
    
    //var file = inputEl.files.item(0);
    var file = this.uploadedImage;
    this.fileType=file.type;
    let fileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
    this.uploadService.getSignedRequest(fileName, this.fileType)
      .subscribe(
        data => {
          this.sigingrequest=data;
          this.finalUpload(this.sigingrequest, file, this.fileType)
        },
        error => this.toastrService.error(error.error.message, error.title, {opacity:1}),
      );
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  finalUpload(data, file, filetype){
    this.uploadService.upload(data.signedRequest, file, filetype)
      .subscribe(
        data => {
          if (data['status']==200){
            this.progress=false;
            this.uploaded=true;
            this.uploadService.addRecord(data)
              .subscribe(
                data => {
                  this.toastrService.success(data['title'],data['message'],{opacity:1})
                }
              );
              
          }
        },
        error => {
          console.log(error)
          this.toastrService.error(error.title,error.message,{opacity:1})
        },
      );
  }

  previewImage(event){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e:any) => {
        var image = new Image();
        image.src = reader.result as string;
      
        this.url = e.target.result;

        image.onload = function() {
        };

      };

      reader.readAsDataURL(event.target.files[0]);
      this.uploaded=false;

    }
  }

  resize(img, MAX_WIDTH:number, MAX_HEIGHT:number, callback){
    // This will wait until the img is loaded before calling this function
    return img.onload = () => {

      // Get the images current width and height
      var width = img.width;
      var height = img.height;

      // Set the WxH to fit the Max values (but maintain proportions)
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // create a canvas object
      var canvas = document.createElement("canvas");

      // Set the canvas to the new calculated dimensions
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0,  width, height);

      // Get this encoded as a jpeg
      // IMPORTANT: 'jpeg' NOT 'jpg'
      var dataUrl = canvas.toDataURL('image/jpeg');

      // callback with the results
      callback(dataUrl, img.src.length, dataUrl.length);
    };
  }

  getAllRecords(){
    this.uploadService.getAllRecords()
    .subscribe(
      data => {
        console.log(data);
      }
    );
  }


  ngOnInit() {
    this.getAllRecords();
  }

}
