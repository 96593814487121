import { Component, OnInit, EventEmitter } from '@angular/core';
import {  AuthService } from '../auth/auth.service';
//import { ToastService } from 'ng-uikit-pro-standard';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { Domains } from "./domains";
import {DomainsService} from "./domains.service";
import {GridOptions} from "ag-grid-community";
import {DomainsInputComponent} from "./domains-input/domains-input.component";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmComponent} from "../shared/confirm/confirm.component";
import {ConfirmService} from "../shared/confirm/confirm.service";
import {ClipboardService} from "../../../services/clipboard.service";
import { DomainsStepperComponent } from './domains-input/domains-stepper.component';
import { trigger, state, transition, style, animate } from '@angular/animations'
import { TemplatesService } from '../templates/templates.service';
import { TemplatesInputComponent } from '../templates/templates-input/templates-input.component';
import { SubtypesService } from '../subtypes/subtypes.service';
import { CategoriesService } from '../categories/categories.service';
import { TypesService } from '../types/types.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('1000ms')),
      transition('hidden => shown', animate('1000ms')),
    ])
  ]
})
export class DomainsComponent implements OnInit {

  data;
  columnDefs;
  confirmPressed = false;
  clipUrl:string = "dave";
  visiblityState = 'hidden';
  messageTitle;
  messageBody;
  icon;
  step:String=null; 
  homepageButton="Create Homepage";
  categories;
  subtypes;
  types;
   /*Clipboard Stuff*/
   public copyEvent: EventEmitter<string>;
   public errorEvent: EventEmitter<Error>;
   
   /*End Clipboard Stuff*/


  public gridOptions: GridOptions;
  constructor(private subtypesService: SubtypesService, private typesService: TypesService, private categoriesService: CategoriesService,private templatesService: TemplatesService,private notificationService: ToastService, private clipboardService: ClipboardService, private confirmService: ConfirmService, private domainsService:DomainsService,  private authService: AuthService, private _service: ToastService, public dialog: MatDialog) {
    this.gridOptions = <GridOptions>{};
    this.columnDefs = [
      {headerName: "Name", field: "name", editable: true, sortable: true, filter:true, resizable:true},
      {headerName: "Username", field: "username", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Domain", field: "domain" , editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Associated Niche(s)", field: "niche", cellRenderer: this.renderNiche, editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Server", field: "server", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Configured on ESE", field: "heroku_domain_status", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Cname", field: "heroku_cname", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Configured at your DNS Provider", field: "heroku_acm_status", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Template", field: "template", editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Date Created", field: "created_at", cellRenderer: this.renderDate, sort:'desc', editable: true,sortable: true, filter:true, resizable:true},
      {headerName: "Actions", cellRenderer: this.renderActions},

    ];
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
    };

    this.domainsService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['obj'];
          this.gridOptions.api.setRowData(this.data);
          this.gridOptions.api.sizeColumnsToFit();
          //this.notificationService.success(data['title'],data['message'],'success','check_circle',  5000,function(){});
        },
        error => this._service.error(error['title'], error.error['message'], {opacity:1})
      );

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
      })
  }

  renderActions(params){
    
    var homepageButton
    if (params.data.template){
      homepageButton=`<button md-button color="primary" type="button" data-action-type="editHomepage" style="height:25px;font-size:8pt;background-color:transparent;border:none;">
      <img src="/assets/images/create-new.png" style="width:15px;height:15px;">&nbsp;Edit Homepage
      </button>`
    } else {
      homepageButton=`<button md-button color="primary" type="button" data-action-type="createHomepage" style="height:25px;font-size:8pt;background-color:transparent;border:none;">
      <img src="/assets/images/create-new.png" style="width:15px;height:15px;">&nbsp;Create Homepage
      </button>`

    }
    var template=`<button md-button color="primary" type="button" data-action-type="view" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
        <img src="/assets/images/edit2.png" style="width:15px;height:15px;">&nbsp;Edit
         </button>
        <br>
         <button md-button color="primary" type="button" data-action-type="status" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/refresh.png" style="width:15px;height:15px;">&nbsp;Refresh Status
         </button>
         <br>
         <button mat-button color="primary" type="button" data-action-type="copy" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
          <img src="https://esev2.s3.amazonaws.com/images/primary-copy.png" style="width:15px;height:15px;">&nbsp;Copy CNAME
          </button>
          <br>
         <button md-button color="primary" type="button" data-action-type="delete" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/delete.png" style="width:15px;height:15px;">&nbsp;Delete
         </button>
         <br>
         ${homepageButton}
         <br>
         <button md-button color="primary" type="button" data-action-type="preview" style="height:25px;font-size:8pt;background-color:transparent;border:none;" data-toggle="modal" data-target="#myModal">
         <img src="/assets/images/view.png" style="width:15px;height:15px;">&nbsp;Open Page
         </button>`;
    return template;
  }

  renderNiche(params){
    if (!params.value){return}
    var myDomains = params.value.replace('[','');
    myDomains = myDomains.replace(']','');
    myDomains = myDomains.replace(/"/g, '');
    myDomains=myDomains.split(",")
    
    var html='';
    
    myDomains.forEach(domain => {
      if (domain){
        var tmpDomain=domain.split('|');
        html = html +
        `<img src="/assets/images/green-check-bubble.png" style="width:15px;height:15px">&nbsp;${tmpDomain[1]}<br>`
      }
      
    })
    
    return html;
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  catchClick(event){
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data);
        case "status":
          return this.onActionStatusClick(data);
        case "delete":
          return this.onActionDeleteClick(data);
        case "copy":
          return this.onActionCopyClick(data, "myContainer");
          case "preview":
            return this.onActionPreviewClick(data);
          case "createHomepage":
            return this.onActionCreateHomepageClick(data);
          case "editHomepage":
            return this.editTemplate(data);
      }
    }
  }

  editTemplate(data){
    console.log(data);
    this.templatesService.getRecord(data.template)
    .subscribe(
      data => {
       console.log("my data:",data)
       var myData=data['obj'];
       let dialogRef = this.dialog.open(TemplatesInputComponent, {
      
        width: '95%',
        height: '95vh'
  
      });
      //dialogRef.componentInstance.lead = this.crmService.editLead(data);
      console.log(myData);
      console.log('categories',this.categories)
      this.templatesService.editTemplates(myData);
      this.templatesService.setType(this.types);
      this.templatesService.setSubType(this.subtypes);
      this.templatesService.setCategory(this.categories);

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
      });
  
      },
       error => {
         console.log(error);
         this._service.error(error.error['title'], error.error['message'],{opacity:1});
       }
    );

    
  }

  public onActionCreateHomepageClick(data: any){

    if (data.template){
      return this._service.success("An Error Occured", "This domain already has a homepage associated with it.",{opacity:1});
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"REALLY CREATE HOMEPAGE?", body:"THie will create a home page for this domain", icon:"check_circle", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.domainsService.createHomepage(data)
        .subscribe(
          data => {
            this.data = data;
            this.notificationService.success(data['title'],data['message'],{opacity:1});
            this.refresh();
          },
          error => {
            console.log("error:",error);
            this.notificationService.error(error.error['title'],error.error['message']+" "+error.error['error'],{opacity:1});
            
          }
        );
      }
    });
  }

  public onActionPreviewClick(data: any){
    console.log(data);
    window.open("https://"+data.domain, "_blank");
  }

  public onActionRegistrarClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '475px',

    });

    this.confirmService.getText({title:"Try to Process with your Registrar?", body:"ok, we're going to try and set it up with your registrar", icon:"check_circle", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.domainsService.processRegistrar(data)
      .subscribe(
        returnData => {
          
          var domainData=JSON.parse(returnData['domain'])
          var forwardData=JSON.parse(returnData['forward']);
          
          var checkDomain=0;
          var checkForward=0;
          if (domainData[0].data==data.heroku_cname){checkDomain++}
          if (domainData[0].name=="www"){checkDomain++}
          if (domainData[0].type=="CNAME"){checkDomain++}

          if (forwardData[0].fqdn==this.extractHostname(data.domain))(checkForward++)
          if (forwardData[0].type=="PERMANENT_REDIRECT")(checkForward++)
          if (forwardData[0].url=="https://"+data.domain)(checkForward++)
          
          if (checkDomain==3 && checkForward==3){
            this.notificationService.success('Success','Looks like you domain, ' + data.domain + ", has been successfully setup with "+data.registrar+".",{opacity:1});
          } else if (checkDomain < 3){
            this.notificationService.success("Failed","Unfortunaely something failed while setting up the domain with "+data.registrar+".",{opacity:1})
          } else if (checkForward < 3){
            this.notificationService.success("Failed","Unfortunaely something failed while setting up the forwarding of your domain with ",{opacity:1})
          }
          
        },
        error => this.notificationService.error(error['title'],error.error['message'],{opacity:1})
      );
        /*this.domainsService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this._service.success(data['title'], data['message']);

              this.refresh();
            },
            error => this._service.error(error.error['message'], error['title']),
          ); */
      }
    });
  }

  public extractHostname(url) {
    var hostname;
  
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }
  
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    var tmpHostname = hostname.split('.');
    
    hostname = tmpHostname[tmpHostname.length-2]+'.'+tmpHostname[tmpHostname.length-1];
    return hostname;
  }

  public onActionCopyClick(data: any, containerid){

    //let index = data.url.indexOf('?');
    console.log(data);
    if (data.heroku_cname){

      let url = data.heroku_cname;
  
    
      this.clipboardService
          .copy( url )
          .then(
            ( value: string ) : void => {
              this.notificationService.success('Success','Data Copied',{opacity:1});
            }
          )
          .catch(
            ( error: Error ) : void => {
              this.notificationService.success('failed','Data Did Not Copy',{opacity:1});
            }
          );
    } else {
      this.notificationService.error('failed','Data Did Not Copy',{opacity:1});
    }


  }

  public onActionViewClick(data: any){
    let dialogRef = this.dialog.open(DomainsInputComponent, {
      height: '600px',
      width: '60%',

    });
    //dialogRef.componentInstance.lead = this.crmService.editLead(data);
    this.domainsService.editDomains(data);


    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });


  }

  public onActionDeleteClick(data: any){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"DELETE?", body:"Really Delete It? If you delete it, this domain will no longer function on 12MA. Please note that this will NOT modify your DNS records.", icon:"delete", class:"warn"});

    dialogRef.afterClosed().subscribe(result => {
      if (this.confirmPressed == true){
        this.domainsService.deleteRecord(data)
          .subscribe(
            data => {
              this.data = data['obj'];
              this.notificationService.success(data['title'],data['message'],{opacity:1});
              this.refresh();
            },
            error => this.notificationService.error(error['title'],error.error['message'],{opacity:1})
          );
      }
    });

  }

  public onActionStatusClick(data: any){
    console.log(data);
    this.domainsService.checkStatus(data)
    .subscribe(
      data => {
       // var body = JSON.parse(data._body);
      //  console.log("data:",JSON.parse(data._body));
      console.log(data);
        this.refresh();
         // this._service.success(body['title'], body['message']);
       // f.reset();
      },
     // error => this.showMessage(error['title'],error['message'],'warn', 'warning',function(){})

    ) 

  }

  refresh(){
    this.domainsService.getAllRecords()
      .subscribe(
        data => {
          this.data = data['obj']
          console.log(this.data);
          this.notificationService.success('Success','Domains Information Updated',{opacity:1});
          this.gridOptions.api.refreshView();
          this.gridOptions.api.setRowData(this.data);

        },
        error => this.notificationService.error(error['title'],error.error['message'],{opacity:1})
      );
  }

  addRecord(){
    let dialogRef = this.dialog.open(DomainsInputComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

  wizard(){
    let dialogRef = this.dialog.open(DomainsStepperComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }



  ngOnInit() {
  }

}
