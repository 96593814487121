import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class HellosignService {
  private hostName = window.location.protocol + '//' + window.location.host;
  constructor(private http: HttpClient) { }

  getSignedRequest(user: any){
    const body = JSON.stringify(user);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/hellosign/getsignedrequest' + token, body, {headers: headers})
      
  }

  verifySigned(eseid){
    const body = JSON.stringify(eseid);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.http.post(this.hostName +'/api/hellosign/verifysigned' + token, body, {headers: headers})
     
  }

}
