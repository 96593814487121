import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { MenusService } from '../menus/menus.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-menumaker',
  templateUrl: './menumaker.component.html',
  styleUrls: ['./menumaker.component.css']
})
export class MenumakerComponent implements OnInit {

  addForm: FormGroup;
  menuname = new FormControl('', [Validators.required, Validators.minLength(3)]);
		rows: FormArray;
		itemForm: FormGroup;
    model;
    menuId=null;
    record;

    typeArray = [
      {name: "Link", value: "link"},
      {name: "Section Head", value: "sectionHead"},
      {name: "User Specific Link", value: "userspecificlink"},
      {name: "New Page", value: "newpage"},
    ]

    iconArray = [
      {name: "Home", value: "home", icon: "home"},
      {name: "People", value: "people", icon: "people"}
    ]
      
    

		constructor(
      private fb: FormBuilder, 
      private menusService:MenusService,  
      private _service: ToastService,
      private route: ActivatedRoute, 
      private router: Router,
      ) {
     
      this.menuId=this.route.snapshot.params.id;
      if (this.menuId != "new"){
        this.menusService.getRecord(this.menuId)
        .subscribe(
          data => {
            this.record=data['obj'];
            console.log(data);
            
            this.onAddMultiRow(this.record)
           
          }
        )
      }

      this.addForm = this.fb.group({
        menuname: [null, Validators.required]
      });
      this.rows = this.fb.array([]);
			
		}

    onAddMultiRow(data){
      console.log(data)
      this.menuname.setValue(data.name)
      let json = JSON.parse(data.menu);
      console.log(json)
      for (let i=0;i<json.length;i++){
        this.rows.push(this.populateItemFormGroup(json[i]));
      }


    }

    populateItemFormGroup(data): FormGroup {
			return this.fb.group({
				type: data.type,
				value: data.value,
				link: data.link,
        icon: data.icon,
        page: data.page
			});
		}

    save() {
      // check if model is valid
      // if valid, call API to save customer
     
      if (this.menuId!="new"){
        this.model = {
          menusId:this.menuId,
          name:this.menuname.value,
          img:null,
          menu:JSON.stringify(this.rows.value)
        }

        console.log(this.model)
        this.menusService.updateRecord(this.model)
          .subscribe(
            data => {
              console.log(data);
              this._service.success(data['message'], data['title'])
            },
            error => this._service.error(error['message'], error['title']),
  
          )
        
      } else { 
      this.model = {
        menusId:"",
        name:this.menuname.value,
        img:null,
        menu:JSON.stringify(this.rows.value)
      }

      
        this.menusService.addRecord(this.model)
          .subscribe(
            data => {
              console.log(data);
              this._service.success(data['message'], data['title']);
             
            },
            error => this._service.error(error['message'], error['title']),
  
          )
       }
    }

    process() {
      console.log(JSON.stringify(this.rows.value));
      console.log(this.menuname.value)
    }

		ngOnInit() {

      this.addForm.addControl('rows', this.rows);
      
			

      //this.onAddRow()
		}

    ngAfterViewInit() {
      
    }

		get addDynamicRow() {
      //console.log(this.addForm.get('rows'));
			return this.addForm.get('rows') as FormArray;
		}

		onAddRow() {
      
			this.rows.push(this.createItemFormGroup());
      console.log(this.rows)
		}

		onRemoveRow(rowIndex:number){
			this.rows.removeAt(rowIndex);
		}

		createItemFormGroup(): FormGroup {
			return this.fb.group({
				type: null,
				value: null,
				link: null,
        icon: null,
        page: null
			});
		}

}
