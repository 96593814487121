
<button mat-icon-button mat-mini-fab color="warn" mat-dialog-close style="position:absolute;top:0px;border:none"><mat-icon  class="material-icons">cancel</mat-icon></button>

    
  <mat-dialog-content>
    
    
    

    <div>
        <div class="row" >
            <div class="col-md-6" style="">
              <mat-form-field>
                <mat-select style="width:100%;" #category name="category" id="category" placeholder="Choose a Category"  floatPlaceholder="auto" class="" (selectionChange)="getTemplates($event)">
                  <mat-option  *ngFor="let category of categories" value="{{category._id}}">{{category.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-select  style="width:100%" #page name="page" [ngModel]="page" id="page" placeholder="Choose a Template"  floatPlaceholder="auto" class="" (selectionChange)="selectTemplate(page)">
                  <mat-option  *ngFor="let template of templates" [value]="template._id" style="height:40vh;padding-top:5px;"><img style="height:100%;width:50%;padding-top:5px" src="{{template.image}}">&nbsp;<span style="font-size:2em">{{template.name}}</span></mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
    </div>
 
  </mat-dialog-content>
  <iframe style="width:100%;height:95%;border:none" [src]="frameSrc"></iframe>
  
