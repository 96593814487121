import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import { ToastService } from 'ng-uikit-pro-standard';
import {CrmService} from "./crm.service";
import {GridOptions} from "ag-grid-community";
//import {RedcomponentComponent} from "../redcomponent/redcomponent.component";
//import {NumericEditorComponent} from "./numeric-editor.component";
import {TextEditorComponent} from "./text-editor.component";
//import {SelectEditorComponent} from "./select-editor.component";
import {Record} from "./record";
declare var $:any;
import { MatDialog } from '@angular/material/dialog';
import {CrmInputComponent} from "./crm-input/crm-input.component";
import {CrmAddComponent} from "./crm-add/crm-add.component";
import {UserpreferenceService} from "../shared/userpreference/userpreference.service";
import {Userpreference} from "../shared/userpreference/userpreference";
import { CommissionsComponent } from '../commissions/commissions.component';
import { UsersInputComponent } from '../auth/users/users-input/users-input.component';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { Systempreference } from '../shared/systempreference/systempreference';
import { FilesService } from '../webbuilder/files.service';
import { ConfirmService } from '../shared/confirm/confirm.service';
import { ConfirmComponent } from '../shared/confirm/confirm.component';
import {ActivateFunnelsDialog} from './activate.component'
import { FunnelsService } from '../funnels/funnels.service';


@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.css']
})
export class CrmComponent implements OnInit {
  data;
  private params: any;
  public value: any;
  private databaseRecord:string;
  private columnName: any;
  public gridOptions: GridOptions;
  currentStatus;
  loading: boolean = false;
  record: Record[] = [];
  paginationFlag: boolean = false;
  private gridApi;
  public showStatus;
  public theme;
  public paginationSize;
  public crmUser;

  statuses = [
    {name:"No Status Selected", value:""},
    {name:"Unable to Contact", value:"Unable to Contact"},
    {name:"Contact 1", value: "Contact 1"},
    {name:"Contact 2", value: "Contact 2"},
    {name:"NI/NQ", value: "NI/NQ"},
    {name:"Appoitment Set", value: "Appoitment Set"},
    {name: "Appoitment Kept", value: "Appoitment Kept"},
    {name: "Sold", value: "Sold"},
    {name: "In Fullfillment", value: "In Fullfillment"},
    {name: "Duplicate", value: "Duplicate"},
    {name: "No Show", value: "No Show"}
  ];

  columnDefs = [
    {
      headerName: "First Name",
      field: "firstname",
      pinned: 'left',
      cellStyle: this.changeRowColor,
      width:125,
      editable: true, sortable: true, filter:true, resizable:true
    },
    {headerName: "Domain", field: "domain", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Last Name", field: "lastname",    width:125,pinned: 'left',editable: true,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "ESEID", field: "username",   editable: true,   pinned: 'left',width:100,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "User ID", field: "user",   editable: true,   pinned: 'left',width:100,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Source", field: "source", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Source ID", field: "source_id", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Source URL", field: "source_url", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Status", field: "status", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Type", field: "type", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    //{headerName: "Funnel", field: "funnel", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
   //{headerName: "Premium",  editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true, cellRenderer: this.renderPremium},
    {headerName: "Email", field: "email", editable: true,cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Phone", field: "phone",  editable: true, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "City", field: "city",  editable: true,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "State", field: "state",  editable: true,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Country", field: "country",  editable: true,  cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    //{headerName: "Hellosign", field: "hellosign",  editable: true,  width:100, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
   // {headerName: "a User", field: "myCustResult",  editable: true,  cellRenderer: this.getAutoresUsername, width:100, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    //{headerName: "a Pass", field: "myCustResult",  editable: true,  cellRenderer: this.getAutoresPassword, width:100, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    //{headerName: "Autoresponder", field: "autoresponder",  width:100, editable: true,  cellRenderer: this.getAutoresponder, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    //{headerName: "Traffic Level", field: "traffic_level",  editable: true,  width:100, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    //{headerName: "Lifetime", field: "lifetime",  width:100, editable: true,  cellRenderer: this.getLifetime, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Cancelled Recurring", field: "cancelled_recurring",  width:100, editable: true,  cellRenderer: this.getCancelledRecurring, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "created_at", field: "created_at", cellRenderer: this.renderDate, cellStyle: this.changeRowColor, sort: 'desc',sortable: true, filter:true, resizable:true},
    {headerName: "updated_at", field: "updated_at", cellRenderer: this.renderDate, cellStyle: this.changeRowColor,sortable: true, filter:true, resizable:true},
    {headerName: "Actions",  cellStyle: this.changeRowColorForButton, cellRenderer: this.renderActionButtons},
  ];
  confirmPressed:boolean=false;
  
  constructor(private crmService: CrmService,
              private _service: ToastService,
              private authService: AuthService,
              public dialog: MatDialog,
              private userpreferenceService:UserpreferenceService,
              private systempreferenceService: SystempreferenceService,
              private filesService: FilesService,
              private confirmService: ConfirmService,
              private funnelsService:FunnelsService) {

       // this.funnelsService.getAllRecords
   /*const userpreference = new Userpreference(null, "crm_pagination", null, null);
    this.userpreferenceService.getKey(userpreference)
      .subscribe(
        data => {
          console.log(data['obj'].value);
          this.paginationFlag = data['obj'].value == "true";
          this.gridOptions.pagination = true;
          this.gridOptions.api.refreshView();
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      ); */
    this.theme="ag-theme-dave";
    this.paginationSize="50";
    this.gridOptions = {
      
      columnDefs: this.columnDefs,
      suppressLoadingOverlay: true,
      suppressNoRowsOverlay: true,
      floatingFilter: false,
    
      
      paginationNumberFormatter: function(params) {
        return '[' + params.value.toLocaleString() + ']';
    },
      onRowEditingStarted: function (event) {
        //console.log(event);
      },
      onCellEditingStopped: function (params) {
        /*this.params = params;
        this.value = this.params.value;
        this.databaseRecord=this.params.node.data.id;
        this.columnName = this.params.column.colDef.field;
        const record = new Record(this.columnName, this.value, this.databaseRecord)
        console.log(record);
        this.loading=true;
        crmService.updateField(record)
          .subscribe(
            data => {
              _service.success(data['title'], data['message']);
              params.api.recomputeAggregates();
              params.api.refreshView();
              this.loading=false;
            },
            error => {
              _service.error('Error', error)
              this.loading=false;
            }
          ),
          error => {
            _service.error('Error', error);
            this.loading=false;
          }
        return this.value;
        */
      }
    };
    //this.loading=true;

    this.confirmService.pressedConfirm.subscribe(
      data => {
        this.confirmPressed = data;
        console.log('data',data)
      })



    


  }

  getAutoresPassword(params){
   
    try {
      
        return params.data.myCustResult[0].autopassword;
     
    } catch(err){
      return '';
    }
  }

  getAutoresUsername(params){
    
    try {
      
        return params.data.myCustResult[0].autousername;
     
    } catch(err){
      return '';
    }
  }

  getCancelledRecurring(params){
    console.log(params.data.cancelled_recurring);
    try {
      if (params.data.cancelled_recurring==true){
        return '<div style="width:100%;text-align:center"><img style="height:25px;" src="/assets/images/greencheck1.png"></div>';
      }
    } catch(err){
      return '';
    }
  }

  getAutoresponder(params){
    try {
      if (params.data.autoresponder.value=="1"){
        return '<div style="width:100%;text-align:center"><img style="height:25px;" src="/assets/images/greencheck1.png"></div>';
      }
    } catch(err){
      return '';
    }
  }

  getLifetime(params){
    
    try {
      if (params.data.lifetime==true){
        
        return '<div style="width:100%;text-align:center"><img style="height:25px;" src="/assets/images/greencheck1.png"></div>';
      }
    } catch(err){
      return'';
    }
  }

  renderPremium(data){
    
    var userPref = data.data.myUserPrefResult;
    
    for (let i=0; i < userPref.length;i++){
      console.log(userPref.key)
      if (userPref[i].key=="premium"){
        return "Yes"
      }
    }
    return "No"
  }

  renderDate(params){
   
    var created = new Date(params.value);
    
  
   var month=created.getMonth();
   var day = created.getDate();
   var hours = created.getHours();
   var minutes=created.getMinutes();
   var seconds=created.getSeconds();

   
   if (month < 12) {
     month=created.getMonth()+1;
   }

   var min;
   if (minutes<10){
     min =  "0"+minutes;
   } else {
     min=minutes;
   }

   var sec;
   if (seconds < 10){
     sec = "0"+seconds;
   } else {
     sec = seconds;
   }

   
   var dateString = month+"-"+day+"-"+created.getFullYear()+' '+hours+':'+min+':'+sec;
   //var dateString=created;
    return dateString;;
  }

  getStatusCrms(){
    this.loading=true;
    
    localStorage.setItem('crmCrmStatusFlag', this.currentStatus);
    if (this.currentStatus){
    this.crmService.getallcrmsbystatus(this.currentStatus)
      .subscribe(
        data => {
          this.data = data['rows'];
          
         
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
          this.loading=false;
        }
      );
    } else {
      localStorage.removeItem('crmCrmStatusFlag');
      this.crmService.getRecords()
        .subscribe(
          data => {
            this.data = data['obj'];
            
           
            this.gridOptions.api.setRowData(this.data);
            this.loading=false;
          },
          error => {
            this._service.error(error.error['message'], error['title']);
            this.loading=false;
          }
        );
    }
  }

  setStatus(){
    var statusFilterComponent = this.gridApi.getFilterInstance("status");
    statusFilterComponent.selectNothing();
    statusFilterComponent.selectValue("Ireland");
    statusFilterComponent.selectValue("Great Britain");
    this.gridApi.onFilterChanged();
    
  }

  public checkPaginationFlag(event){
    const userpreference = new Userpreference(null, "crm_pagination", String(this.paginationFlag), null);
    this.userpreferenceService.updateKey(userpreference)
      .subscribe(
        data => {
          this.paginationFlag = data['obj'].value == "true";
          this.gridOptions.api.refreshView();
          this.gridOptions.pagination = true;

        },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
      );

  }

  addComments(event){
    
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");

      switch(actionType) {
        case "view":
          return this.onActionViewClick(data, event);
        case "viewcommissions":
          return this.onActionViewCommissionsClick(data, event);
        case "remove":
          return this.onActionRemoveClick(data);
        case "viewuser":
          return this.onActionViewUser(data, event);
        case "sendemail":
          return this.confirmSendEmail(data, event);
        case "activate":
          return this.activateFunnels(data, event);
        case "promotetoamp":
          return this.promoteToAmpConfirm(data, event);
        case "demotefromamp":
          return this.demoteFromAmpConfirm(data, event);
      }
    }
  }

  public demoteFromAmpConfirm(data: any, event){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });
    console.log("confirm",data)
    this.confirmService.getText({title:"DEMOTE FROM AMP?", body:"Really Demote?", icon:"arrow", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      
      if (this.confirmPressed == true){
        this.onDemoteFromAmp(data,event)
      }
    });
  }

  public onDemoteFromAmp(data,event){
    console.log("info:",data)
    const userpreference = {
      key: "premium",
      userId: data.user
    }

    console.log(userpreference)
    this.userpreferenceService.deleteRecordForSomeoneElse(userpreference)
      .subscribe(
        data => {
          this._service.success(data['message'], data['title'], {opacity:1});

        },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
      ); 
  }

  public promoteToAmpConfirm(data: any, event){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });
    console.log("confirm",data)
    this.confirmService.getText({title:"PROMOTE TO AMP?", body:"Really Promote?", icon:"arrow", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      
      if (this.confirmPressed == true){
        this.onPromoteToAmp(data,event)
      }
    });
  }

  public onPromoteToAmp(data,event){
    console.log("info:",data)
    const userpreference = {
      key: "premium",
      value: "true",
      group: "",
      subgroup: "",
      domain: "",
      username: data.username,
      userId: data.user
    }

    console.log(userpreference)
    this.userpreferenceService.addRecordForSomeoneElse(userpreference)
      .subscribe(
        data => {
         console.log("data = ",data)
         this._service.success(data['message'], data['title'], {opacity:1});
        },
          error => {
            this._service.error(error.error['message'], error['title']);
          }
      ); 
  }

  public activateFunnels(data,event){
    let dialogRef = this.dialog.open(ActivateFunnelsDialog, {
      height: '575px',
      width: '675px',
    });

    this.crmService.editCrm(data);
  }

  public confirmSendEmail(data: any, event){
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"SEND EMAIL?", body:"Really SEND It?", icon:"email", class:"success"});

    dialogRef.afterClosed().subscribe(result => {
      
      if (this.confirmPressed == true){
        this.onActionSendEmail(data,event)
      }
    });
  }

  public onActionSendEmail(data: any, event){
    
    let user = localStorage.getItem('userRecord') ? JSON.parse(localStorage.getItem('userRecord')) : '';
    
    const systempreference = new Systempreference(null, document.location.hostname, null, null);
     
      this.systempreferenceService.getKey(systempreference)
        .subscribe(
          configData => {
            
            var config, templateId;
            try {
              config=JSON.parse(configData['obj'].value);
              
              templateId=config.crmSendUrlEmailTemplate;
              var emailOptions={
                template_id:templateId,
                domain:document.location.hostname,
                to:data.email,
                from: user.email,
                subject: "Your ESE Get started Link",
                url: data.source_url,
                firstname: data.firstname,
                lastname: data.lastname
              }
              
              this.crmService.sendTemplateEmail(emailOptions).subscribe (
                emailData => {
                  
                  this._service.success(emailData['message'], emailData['title'], {opacity:1});
                }
              )
            } catch (error) {
              console.error(error);
            }
            console.log(templateId)
          }
        )
  }

  public onActionViewClick(data: any, event){
    console.log("View action clicked", event);
    let dialogRef = this.dialog.open(CrmInputComponent, {
      height: '600px',
      width: '60%',

    });
    data.action="fromCRM";
    //dialogRef.componentInstance.crm = this.crmService.editCrm(data);
    this.crmService.editCrm(data);

    console.log(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log('result: ' + result);
      dialogRef = null;
      //this.refreshOne(null,event);
      this.refresh();
    });


  }

  public onActionViewUser(data: any, event){
    console.log(data);
    console.log("View action clicked", event);
    
    let dialogRef = this.dialog.open(UsersInputComponent, {
      height: '600px',
      width: '60%',

    });

    this.authService.editUser(data.user);

  }

  public onActionViewCommissionsClick(data: any, event){
    console.log("View Commissions action clicked", event);
    console.log(event);
    let dialogRef = this.dialog.open(CommissionsComponent, {
      height: '90vh',
      width: '70%',
      data: {id: event.data.user}

    });
    //dialogRef.componentInstance.crm = this.crmService.editCrm(data);
    this.crmService.editCrm(data);

    console.log(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log('result: ' + result);
      dialogRef = null;
      //this.refreshOne(null,event);
      this.refresh();
    });


  }

  public onActionRemoveClick(data: any){
    console.log("Remove action clicked", data);
  }

  renderActionButtons(params){
   // console.log(params);
   // console.log(params.data['status'])
   // console.log('dave');
    var html;
    
    var buttonColor;
    if (params.node.data['type']=="member"){
      buttonColor="#ffffff";
    }
    switch (params.node.data['status']){
      case 'Unable to Contact': {
        buttonColor="#ffffff";
      }
      case 'Contact 1':{
        buttonColor="#000000";
      }
      case 'Contact 2':{
        buttonColor="#000000";
      }
      case 'NI/NQ': {
        buttonColor="#000000";
      }
      case 'Appoitment Set': {
        buttonColor="#000000";
      }
      case 'Appoitment Kept': {
        buttonColor="#ffffff";
      }
      case 'Sold': {
        buttonColor="#000000";
      }
      case 'In Fullfillment': {
        buttonColor="#ffffff";
      }
      case 'Duplicate': {
        buttonColor="#ffffff";
      }
      case 'No Show': {
        buttonColor="#ffffff";
      }
    }

    
    
    
    html=`<button md-button color="primary" type="button"  data-action-type="view" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
    <img src="/assets/images/edit2.png" style="width:20px;height:20px;">&nbsp;Edit
             </button>
           <br>  
    <button md-button color="primary" type="button"  data-action-type="viewcommissions" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
    <img src="/assets/images/viewcommissions.png" style="width:20px;height:20px;">&nbsp;Commissions
            </button>
    `
    if (params.node.data['type']=="member"){
      html = html + `
            <br>
            <button md-button color="primary" type="button"  data-action-type="viewuser" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
    <img src="/assets/images/user.png" style="width:20px;height:20px;">&nbsp;Go to User
            </button>
            <br>
      <button md-button color="primary" type="button"  data-action-type="activate" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
      <img src="/assets/images/green-check-bubble.png" style="width:20px;height:20px;">&nbsp;Activate Instant Funnels
              </button>
            <br>
      `;

      var userPref = params.data.myUserPrefResult;
      let isPremium:boolean = false;
      
     /* for (let i=0; i < userPref.length;i++){
        
        if (userPref[i].key==="premium"){
          isPremium=true;
        }
       
      }  */

   /*   if (isPremium==true){
        html = html + `
          <button md-button color="primary" type="button"  data-action-type="demotefromamp" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
          <img src="/assets/images/promotetoamp.png" style="width:20px;height:20px;">&nbsp;Demote From AMP
                    </button>
        `
      } else {
        html = html + `
          <button md-button color="primary" type="button"  data-action-type="promotetoamp" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
          <img src="/assets/images/promotetoamp.png" style="width:20px;height:20px;">&nbsp;Promote To AMP
                    </button>
        `
      } */

      
    } 

    if (params.node.data['type']=="lead"){
      html = html + `       <br>
    <button md-button color="primary" type="button"  data-action-type="sendemail" style="font-weight:bold;height:20px;font-size:10pt;background-color:transparent;border:none;color:${buttonColor}" data-toggle="modal" data-target="#myModal">
    <img src="/assets/images/email.png" style="width:20px;height:20px;">&nbsp;Send Email
            </button>
            
           
     ` }

    
     

    
     return html;
     
  }

  changeRowColor(params) {

    
    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Sold': {
        return {'background-color': '#87c947', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'In Fullfillment': {
        return {'background-color': '#8B4513', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Duplicate': {
        return {'background-color': '#62148B', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'No Show': {
        return {'background-color': '#F88F09', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
    }

    if (params.node.data['type']=="member"){
      return {'background-color': 'green', 'color':'#ffffff', "border-bottom":"1px solid #fff"};
    }
  }

  changeRowColorForButton(params) {

    switch (params.node.data['status']){
      case 'Unable to Contact': {
        return {'background-color': 'red', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Contact 1':{
        return {'background-color': 'yellow', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'Contact 2':{
        return {'background-color': 'yellow', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'NI/NQ': {
        return {'background-color': '#747474', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Appoitment Set': {
        return {'background-color': '#abbfe3', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'Appoitment Kept': {
        return {'background-color': '#2869ac', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Sold': {
        return {'background-color': '#87c947', 'color':'#000000', "border-bottom":"1px solid #000"};
      }
      case 'In Fullfillment': {
        return {'background-color': '#2869ac', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'Duplicate': {
        return {'background-color': '#2869ac', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
      case 'No Show': {
        return {'background-color': '#2869ac', 'color':'#ffffff', "border-bottom":"1px solid #000"};
      }
    }

    if (params.node.data['type']=="member"){
      return {'background-color': 'green', 'color':'#ffffff', "border-bottom":"1px solid #fff"};
    }
  }

  onGridReady(event){
    this.gridApi = event.api;
    var crmCrmStatusFlag = localStorage.getItem('crmCrmStatusFlag') ? localStorage.getItem('crmCrmStatusFlag') : '';

    if (crmCrmStatusFlag){
      this.currentStatus=crmCrmStatusFlag;
      this.crmService.getallcrmsbystatus(crmCrmStatusFlag)
      .subscribe(
        data => {
          this.data = data['rows'];
          console.log(this.data);
          
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
          this.loading=false;
        }
      );
    } else {
      this.crmService.getRecordsByDomain()
        .subscribe(
          data => {
            console.log(data);
            this.data = data['obj'];
            
            //console.log(this.data);
            this.gridOptions.api.setRowData(this.data);
            this.loading=false;
          },
          error => {
            this._service.error(error.error['message'], error['title']);
            this.loading=false;
          }
        );
    }
  }

  refreshOne(id: number, event){
    console.log('id');

    /*this.crmService.getcrms()
      .subscribe(
        data => {
          this.data = data['rows'];
          console.log(this.data);
          this._service.success(data['title'], data['message'])
          this.gridOptions.api.setRowData(this.data);
        },
        error => this._service.error(error.error['message'], error['title']),
      ); */
  }

  refresh(){
    var crmCrmStatusFlag = localStorage.getItem('crmCrmStatusFlag') ? localStorage.getItem('crmCrmStatusFlag') : '';
    if (crmCrmStatusFlag){
      this.currentStatus=crmCrmStatusFlag;
      this.crmService.getallcrmsbystatus(crmCrmStatusFlag)
      .subscribe(
        data => {
          this.data = data['rows'];
         
          this.gridOptions.api.setRowData(this.data);
          this.loading=false;
        },
        error => {
          this._service.error(error.error['message'], error['title']);
          this.loading=false;
        }
      );
    } else {
      this.loading=true;
      this.crmService.getRecordsByDomain()
        .subscribe(
          data => {
            this.data = data['obj'];
           
            this.gridOptions.api.setRowData(this.data);
            this.loading=false;
            
          },
          error => this._service.error(error.error['message'], error['title']),
        );
    }
  }

  ngOnInit() {

  }

  addRecord(){
    let dialogRef = this.dialog.open(CrmAddComponent, {
      height: '600px',
      width: '60%',

    });



    dialogRef.afterClosed().subscribe(result => {
      this.refresh();

      dialogRef = null;
    });
  }

}



