<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  <input type="hidden" name="eseid" #eseid="ngModel" [ngModel]="autoresponder?.eseid" value="" id="eseid" name="eseid">
  <input type="hidden" name="group" #group="ngModel" [ngModel]="autoresponder?.group" value="" id="group" name="group">
  <input type="hidden" name="subgroup" #subgroup="ngModel" [ngModel]="autoresponder?.subgroup" value="" id="subgroup" name="subgroup">
  <div class="form-group" style="width:100%">
    <label>Autoresponder Id</label>
    <div class="input-group" style="width:100%">
        
      <div class="input-group-prepend">
        <span class="input-group-text"><mdb-icon fas icon="ban"></mdb-icon></span>
      </div>
      <input style="background-color:#ccc"   name="autoresponderId" #autoresponderId="ngModel" [ngModel]="autoresponder?._id" id="autoresponderId" type="text" class="form-control form-elements" aria-label="With textarea" readonly>
    </div>
  </div>

  <div class="form-group" style="width:100%">
    <label>Autoresponder Name</label>
    <div class="input-group" style="width:100%">
        
      <div class="input-group-prepend">
        <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
      </div>
      <input style="" name="name" #name="ngModel" [ngModel]="autoresponder?.name" type="text" id="name"  class="form-control form-elements" aria-label="With textarea"  required>
    </div>
  </div>  

    <label for="autoresponder">Which Autoresponder are you using?</label>
    <select id="autoresponder1" #autoresponder1="ngModel" [ngModel]='autoresponder?.autoresponder' name="autoresponder" class="browser-default custom-select">
      <option value="" disabled selected>Choose your autoresponder</option>
      <option value="aweber">Aweber</option>
      <option value="getresponse">Get Response</option>
      <option value="mailchimp">Mail Chimp (Coming Soon)</option>
      <option value="constantcontact">Constant Contact (Coming Soon)</option>
    </select>
    <br><br>

    <div class="form-group" style="width:100%">
      <label>List Name, List Id, Campaign Id, List Token, or Audience ID</label>
      <div class="input-group" style="width:100%">
          
        <div class="input-group-prepend">
          <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
        </div>
        <input style=""  name="listname" #listname="ngModel" [ngModel]="autoresponder?.listname" type="text" id="listname" class="form-control form-elements" aria-label="With textarea" required>
      </div>
      <small id="" class="form-text text-muted" style="">
              <em>Depends on your autoresponder</em>
        </small>
    </div>

    <div class="form-group" style="width:100%">
      <label>Account Id</label>
      <div class="input-group" style="width:100%">
          
        <div class="input-group-prepend">
          <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
        </div>
        <input style=""  name="account" #account="ngModel" [ngModel]="autoresponder?.account" name="account" type="text" class="form-control form-elements" aria-label="With textarea" id="account">
      </div>
      <small id="" class="form-text text-muted" style="">
              <em>This is Optional and may not be needed</em>
        </small>
    </div>
    
    <div class="form-group" style="width:100%">
      <label>Autoresponder Username</label>
      <div class="input-group" style="width:100%">
          
        <div class="input-group-prepend">
          <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
        </div>
        <input style=""  name="autousername" #autousername="ngModel" [ngModel]="autoresponder?.autousername" type="text" id="autousername" class="form-control form-elements" aria-label="With textarea">
      </div>
      <small id="" class="form-text text-muted" style="">
              <em>Only needed if we are setting up your autoresponder for yo</em>
        </small>
    </div>

    <div class="form-group" style="width:100%">
      <label>Autoresponder Password&nbsp;<img id="view_password" src="https://esev2.s3.amazonaws.com/images/view.png" alt="View Notes" style="width:25px;height:25px;"></label>
      <div class="input-group" style="width:100%">
          
        <div class="input-group-prepend">
          <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
        </div>
        <input style=""  #autopassword="ngModel" [ngModel]="autoresponder?.autopassword" name="autopassword" type="password" class="form-control form-elements" aria-label="With textarea" id="autopassword" >
      </div>
      <small id="" class="form-text text-muted" style="">
              <em>Only needed if we are setting up your autoresponder for you</em>
        </small>
    </div>

      <div class="form-group" style="width:100%">
        <label>Autoresponder API Key&nbsp;<img id="view_consumer_key" src="https://esev2.s3.amazonaws.com/images/view.png" alt="View Notes" style="width:25px;height:25px;"></label>
        <div class="input-group" style="width:100%">
            
          <div class="input-group-prepend">
            <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
          </div>
          <input style=""   #consumer_key="ngModel" [ngModel]="autoresponder?.consumer_key" name="consumer_key" name="consumer_key" type="password" class="form-control form-elements" aria-label="With textarea" id="consumer_key" >
        </div>
        <small id="" class="form-text text-muted" style="">
                <em>Optional</em>
          </small>
    </div>
    
    <div class="form-group" style="width:100%">
        <label>Autoresponder API Secret&nbsp;<img id="view_consumer_secret" src="https://esev2.s3.amazonaws.com/images/view.png" alt="View Notes" style="width:25px;height:25px;"></label>
        <div class="input-group" style="width:100%">
            
          <div class="input-group-prepend">
            <span class="input-group-text"><mdb-icon fas icon="pencil-alt"></mdb-icon></span>
          </div>
          <input style=""   #consumer_secret="ngModel" [ngModel]="autoresponder?.consumer_secret" name="consumer_secret" name="consumer_secret" type="password" class="form-control form-elements" aria-label="With textarea" id="consumer_secret">
        </div>
        <small id="" class="form-text text-muted" style="">
                <em>Optional</em>
          </small>
    </div>
     
    
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="submit" class="btn btn-primary" id="submit_data" [disabled]="!f.valid">Save changes</button>
  
</form>
