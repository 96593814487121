<app-loader *ngIf="showLoader" loading=true></app-loader>
<div style="background-color:#eee">
<div class="container main-container" style="padding-top:0px;margin-top:0px;">
  
  <section class="col-md-2"></section>
  <section class="col-md-8" style="background-color:#fff">

    <h1 class="center t1">Yes, Devon! Please Build My List For Me & Set Me Up to Make Affiliate Commissions On Complete Autopilot!!
    </h1>
    <br/>
    <div align="center" style="font-size:32px; line-height:35px">
        <h3 style="font-size:30px; color: #C00; line-height:43px" align="center">Doors Close In...</h3>
        <br />
        
        <div align="center" style="text-align:center;margin:auto;height:150px;" class="embed-responsive">
            <iframe  class="embed-responsive" frameborder=0 src="/api/api/gettimer?days=0&hours=0&minutes=20&seconds=0" style=" width:100%;height:100%;"></iframe>
         </div>
    </div>
    <br/><br/>
    <div align="center">
        <h3 style="font-size:36px; color: #000" align="center">"I'm Ready To Get Started With The Easiest System Ever"</h3><br/>
        </div>
        <br><br>
    <ul>
      <li>I've watched the webinar, I understand how this works, and I'm ready to get started NOW!</li>
      <li>I understand this is NOT cheap and I have at LEAST a few thousand dollars available to me to make this happen!</li>
      <li>I understand that this is available on a 1st come 1st serve basis, and that you're only looking for a handful of people to work with!</li>
      <li>I understand that your staff will call me between Mon & Friday 10AM - 6PM Eastern get me started! And that you'll be holding my hand and doing ALL of the heavy lifting for me!</li>
    </ul>
    <br><br>
    
   
    <div class="center bold t2">
      <img src="/assets/images/ese_logo1.png" style="width:100%">
    </div>
    <br/><br/>
    <div style="margin:auto;width:90%;background-color:yellow; border: dashed 5px; padding:20px 20px 20px 20px">
        
        <div style="text-align:center;font-size:18pt;font-weight:bold;width:100%">Tell Us The Best Way To Reach You!</div><br>
        <p style="font-size:11pt;text-indent:0px;line-height:16px;">
            Your next step is to submit your accurate contact details below, so my assistant can reach you with details on how to set up the one-on-one chat where we'll discuss if and how we can work together on your business.
            <br><br>
            Please remember this offer is available on first come first served basis and we're only looking for a handful of serious people to work with.
            <br><br>
            This is NOT a free offer. If you're looking for handouts, you're in the wrong place. If you'd like us to help you drastically shortcut your list building learning curve by skipping all the technical work and you recognize the value in having someone as experienced as myself do it for you - I look forward to connecting with you real soon on a potentially life changing phone call.
        </p>
    <form #f="ngForm" (ngSubmit)="save(f.value, f.valid)">

     

      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput  type="text" name="firstname" #name="ngModel" (ngModel)="application?.firstname" type="text" id="firstname" required>
          <mat-placeholder  class="placeholder">First Name</mat-placeholder>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput  name="lastname" #name="ngModel" (ngModel)="application?.lastname"  type="text" id="lastname" required>
          <mat-placeholder  class="placeholder">Last Name</mat-placeholder>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
        <input class="t5" name="email" matInput #email="ngModel" (ngModel)="application?.email" type="text" id="email" required email/>
        <mat-placeholder  class="placeholder">Email</mat-placeholder>
        <mat-error>This is a required field</mat-error>
        <mat-error *ngIf="email.errors?.email">Please Enter an email in the correct format</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="address" matInput #name="ngModel" (ngModel)="application?.address" type="text" id="address"  required>
        <mat-placeholder  class="placeholder">Address</mat-placeholder>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="address2" matInput #name="ngModel" (ngModel)="application?.address2" type="text" id="address2" >
        <mat-placeholder  class="placeholder">Address 2</mat-placeholder>
      </mat-form-field>

      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput  type="text" name="city" #name="ngModel" (ngModel)="application?.city" type="text" id="city" required>
          <mat-placeholder  class="placeholder">City</mat-placeholder>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput  name="state" #name="ngModel" (ngModel)="application?.state"  type="text" id="state" required>
          <mat-placeholder  class="placeholder">State</mat-placeholder>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
        <td><mat-form-field class="example-full-width">
          <input class="t5" matInput  name="zip" #name="ngModel" (ngModel)="application?.zip"  type="text" id="zip" required>
          <mat-placeholder  class="placeholder">Zip</mat-placeholder>
          <mat-error>This is a required field</mat-error>
        </mat-form-field></td>
      </tr></table>

      <mat-form-field class="example-full-width">
          <input class="t5" name="country" matInput #country="ngModel" (ngModel)="application?.country" type="text" id="skype">
          <mat-placeholder  class="placeholder">Country</mat-placeholder>
        </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="skype" matInput #skype="ngModel" (ngModel)="application?.skype" type="text" id="skype">
        <mat-placeholder  class="placeholder">Skype</mat-placeholder>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="phone" matInput #phone="ngModel" (ngModel)="application?.phone" type="text" id="phone"  required>
        <mat-placeholder  class="placeholder">Best Phone</mat-placeholder>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="paypal" matInput #paypal="ngModel" (ngModel)="application?.paypal" type="text" id="paypal">
        <mat-placeholder  class="placeholder">Paypal</mat-placeholder>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input class="t5" name="howlong" matInput #howlong="ngModel" (ngModel)="application?.howlong" type="text" id="howlong"  required>
        <mat-placeholder  class="placeholder">How Long Have You Been Trying to Make Money Online?</mat-placeholder>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <label style="font-size:12pt;">Tell us why we should pick you to be part of the Easiest System Ever. And tell us that you understand that this service is NOT free/cheap</label>
        <textarea  name="about" matInput #about [ngModel]="application?.about"  id="about"  style="height:200px;border-left:solid #3748ac 1px; border-right:solid #3748ac 1px" required></textarea>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>


      <div style="width:100%; text-align:center">
        <button type="submit" class="btn btn-primary" style="width:100%;font-size:20pt;" [disabled]="!f.valid">I'm Ready To Get Started</button>
      </div>
    </form>
    </div>
    <br/><br/><br/><br/>
    <div style="width:100%;color:antiquewhite;text-align:center;">affiliate id:{{set_eseid}}</div>
  </section>
  <section class="col-md-2"></section>
</div>
<br/><br/><br/><br/>
</div>