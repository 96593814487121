import {Injectable, EventEmitter} from '@angular/core';
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient, HttpRequest} from "@angular/common/http";

@Injectable()
export class FileUploadService {
  private hostName = window.location.protocol + '//' + window.location.host;

  constructor(private httpClient:HttpClient) { }

  getSignedRequest(fileName, fileType) {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpClient.get(this.hostName +'/api2/s3/sign-s3?fileName='+fileName+"&fileType="+fileType,  {headers: headers})
  }

  getAllRecords() {
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpClient.get(this.hostName +'/api2/files/getallrecords',  {headers: headers})
  }

  getMyObjects(path){
    const body = JSON.stringify(path);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpClient.post('/api2/s3/getmyobjects', body, {headers: headers})
  }

  upload (signedRequest, file, fileType){
    console.log(signedRequest, file);
    const headers = new HttpHeaders().set('Content-Type', fileType);
    const options = ({ headers: headers, reportProgress: true, observe: 'events' });
    
    //var returnData=this.httpClient.put(signedRequest, file, {headers});
    const req=new HttpRequest('PUT',signedRequest, file, options )
    var returnData=this.httpClient.request(req)
    console.log(returnData, returnData['url'])
    return returnData
  }

  verifyImage(data: any){
    var body = {"value":data.event.target.checked, "id":data.data._id};
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    //const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpClient.post(this.hostName +'/api2/upload/verifyrecord', body, {headers: headers})
  }

  addRecord(imageInfo: any) {
    const body = JSON.stringify(imageInfo);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpClient.post(this.hostName +'/api2/images/addrecord', body, {headers: headers})
  }

}
