<form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
  <mat-dialog-content>
    <input type="hidden" name="subtypesId" #subtypesId="ngModel" [ngModel]="subtypes?._id" id="subtypesId">
    <mat-form-field class="example-full-width">
      <input matInput name="name" #name="ngModel" [ngModel]="subtypes?.name" type="text" id="name" placeholder="Enter the Name" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="subtype" #subtype="ngModel" [ngModel]="subtypes?.subtype" type="text" id="subtype" placeholder="Enter the Subtype" required>
      <mat-error>This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput name="role" #role="ngModel" [ngModel]="subtypes?.role" type="text" id="role" placeholder="Enter the Role that is alowed to view this category.">
    </mat-form-field>

    <br><br>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="userActionButtonGroup">
      <button mat-icon-button mat-mini-fab color="primary" type="submit" [disabled]="!f.valid">
        <mat-icon  class="material-icons">done</mat-icon>
      </button>
      <button mat-icon-button mat-mini-fab color="warn" mat-dialog-close>
        <mat-icon  class="material-icons">cancel</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</form>
