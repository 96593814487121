import { Component, OnInit, Inject } from '@angular/core';
import { TemplatesService } from '../../templates/templates.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { CategoriesService } from '../../categories/categories.service';
//import { IframeComponent } from '../../shared/iframe/iframe.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LinksService } from '../../links/links.service';
import { Links } from '../../links/links';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-links-steps',
  templateUrl: './links-steps.component.html',
  styleUrls: ['./links-steps.component.css']
})
export class LinksStepsComponent implements OnInit {
  templates;
  categories;
  chosenTemplate;
  public model: Links;
  frameSrc:any ;
  category;
  myPage;

  constructor(private sanitizer: DomSanitizer,private linksService:LinksService, private categoriesService:CategoriesService, private pageService:TemplatesService, private _service: ToastService, public dialog: MatDialog, public dialogRef: MatDialogRef<LinksStepsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.myPage="5a872df7abe008cd6b03fef8";
     }

  getTemplates(event){
    console.log(event);
    this.pageService.getCategoryRecords(event.value)
      .subscribe(
        data => {
          this.templates = data['obj'];
          console.log(this.templates);
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  selectTemplate(page){
    console.log(page._value);
    var pageId=page._id;
    this.chosenTemplate = page._value;
    if (this.data.action=="add"){
      this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url+this.chosenTemplate);
    } else if (this.data.action=="edit"){
      this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url+this.data._id+'/'+this.chosenTemplate);
    }
   //this.model = new Links();
    //this.model.page = page._id;
    /*this.linksService.addRecord(this.model)
        .subscribe(
          data => {
            console.log(data);
            this._service.success(data['message'], data['title']);
            let dialogRef = this.dialog.open(IframeComponent, {
              width: "95%",
              height: "90vh",
              hasBackdrop: true,
              data: { _id: data['obj']._id }
            });
        
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
              
            });
          },
          error => this._service.error(error['message'], error['title']),

        ) */
    
  }


  ngOnInit() {
    console.log(this.data);
    this.chosenTemplate="5a872df7abe008cd6b03fef8";
    if (this.data.action=="add"){
      this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url+this.chosenTemplate);
    } else if (this.data.action=="edit"){
      this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url+this.data._id);
    }
    this.categoriesService.getAllRecords()
      .subscribe(
        data => {
          this.categories = data['obj'];
          console.log(this.categories);
        },
        error => this._service.error(error.error['message'], error['title']),
      );
  }

  

}
