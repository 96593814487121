import { Component, OnInit } from '@angular/core';
import {NewsService} from "../news.service";

@Component({
  selector: 'app-news-ticker',
  templateUrl: './news-ticker.component.html',
  styleUrls: ['./news-ticker.component.css']
})
export class NewsTickerComponent implements OnInit {
  data;
  constructor(private newsService:NewsService) {
    this.newsService.getAllRecords()
      .subscribe(
      data => {
          this.data = data['obj'];
          
        }
      );
  }

  ngOnInit() {
  }

}
