<div class="wrapper">
    <p align="center" class="ral-48blue">Personal Development Library</p>
    <p class="center t4"><strong>**These products are ONLY for ESE Members. Anyone caught sharing these products will have their account terminated</strong></p>
    <br>
    <div class="table">
      
      <div class="row header blue">
        <div class="cell center">
          ITEM
        </div>
        <div class="cell">
          DESCRIPTION
        </div>
        <div class="cell">
          ACTUAL VALUE
        </div>
      </div>

      <div class="row">
        
        <div class="cell img">
          <div class="title">Balance: Finding the Perfect Harmony Between Life and Work</div>
          <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Balance/SoftCover.png">
        </div>
        <div class="cell description">
            Whether it's a dad or a mom or both running the business and raising the kids,
it may seem impossible to find a balance between work and family, without
even mentioning the personal time that we all need.
        </div>
        <div class="cell price" data-title="Occupation">
          <a href="http://esev2.s3.amazonaws.com/pdl/Balance/Balance.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
          <img style="width:100px" src="assets/images/downloadaudio.png">
          <a href="http://esev2.s3.amazonaws.com/pdl/Balance/02-Audiobook/Work-Life%20Balance%20-%201.mp3" target="_blank">1</a>
          <a href="http://esev2.s3.amazonaws.com/pdl/Balance/02-Audiobook/Work-Life%20Balance%20-%202.mp3" target="_blank">2</a>
          <a href="http://esev2.s3.amazonaws.com/pdl/Balance/02-Audiobook/Work-Life%20Balance%20-%203.mp3" target="_blank">3</a>
          <a href="http://esev2.s3.amazonaws.com/pdl/Balance/02-Audiobook/Work-Life%20Balance%20-%204.mp3" target="_blank">4</a>
          <a href="http://esev2.s3.amazonaws.com/pdl/Balance/02-Audiobook/Work-Life%20Balance%20-%205.mp3" target="_blank">5</a>
        </div>
        
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Be Heard: Public Speaking Made Easy</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Be%20Heard/SoftCover.png">
          </div>
          <div class="cell description">
              Humans’ ability to communicate using formalized systems of language sets us apart from other living creatures on the Earth. What is important is that the audience understands you and remembers what you have to say. By learning and using the techniques provided in this book, you will discover how to create engaging speeches and present them using your own delivery style. 
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Be%20Heard/BeHeard.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Be%20Heard/02-Audiobook/Public%20Speaking.mp3" target="_blank">1</a>
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Care Less: How To Stop Worrying About What Other People Think About You</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Care%20Less/SoftCover.png">
          </div>
          <div class="cell description">
              The problem is… many people live their lives solely based on what other people might think if they do or say something. Unfortunately, this means they are sitting on the sidelines with their dreams and desires, fearing to take action lest someone take offence or think they are ridiculous. Don't let this fear stop you!</div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/How%20to%20Care%20Less.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/03-WorkBook/CareLessWorkBook.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadworkbook.png"></a><br>
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/02-Audiobook/How%20to%20Care%20Less%20-%201.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/02-Audiobook/How%20to%20Care%20Less%20-%202.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/02-Audiobook/How%20to%20Care%20Less%20-%203.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/02-Audiobook/How%20to%20Care%20Less%20-%204.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/02-Audiobook/How%20to%20Care%20Less%20-%205.mp3" target="_blank">5</a>
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Confident Choices: Making The Right Decisions At The Right Time</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/SoftCover.png">
          </div>
          <div class="cell description">
              Putting more thought into important decisions isn’t a bad thing. It’s something we should do. The problem arises when we go too far and start to fear the decision making. In turn that can lead to procrastination. We simply don’t decide at all.  Don't procrastinate. Decide by making Confident Choices.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/ConfidentChoices.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/02-Audiobook/Confident%20Choices-1.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/02-Audiobook/Confident%20Choices-2.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/02-Audiobook/Confident%20Choices-3.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/02-Audiobook/Confident%20Choices-4.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Confident%20Choices/02-Audiobook/Confident%20Choices-5.mp3" target="_blank">5</a>
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Dealing With Difficult People:</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Dealing%20With%20Difficult%20People/SoftCover.png">
          </div>
          <div class="cell description">
              At some point, we all have to deal with difficult people… at work, in our personal lives, even unexpectedly as we are going about our day-to-day activities. Negativity from difficult people can often feel stifling to those of us who tend to enjoy our lives and have little tolerance for drama. Knowing how to deal with the negativity from different types of difficult people is crucial to your happiness and mental health. </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Dealing%20With%20Difficult%20People/Dealing-With-Difficult-People.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Dealing%20With%20Difficult%20People/02-Audiobook/DifficultPeople.mp3" target="_blank">1</a>
              
          </div>
          
      </div>
      
      <div class="row">
          <div class="cell img">
            <div class="title">Dignity: 10 Steps To Build Your Self-Worth</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Dignity/SoftCover.png">
          </div>
          <div class="cell description">
              This eBook is a guide to encourage and support you to take control of your self-esteem, honor your self-worth and reclaim your dignity. Dignity is not something people talk about that much – but it’s the foundation and signal to the world of self-worth.  Dignity is a powerful concept that lifts ‘self-esteem’ above just feeling good about yourself. Dignity honors your value as a unique human being – it implies honesty and self-awareness as well as positivity.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/Dignity.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/03-Workbook/DignityWorkbook.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadworkbook.png"></a><br>
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/02-Audiobook/Dignity%20-%201.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/02-Audiobook/Dignity%20-%202.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/02-Audiobook/Dignity%20-%203.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/02-Audiobook/Dignity%20-%204.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Dignity/02-Audiobook/Dignity%20-%205.mp3" target="_blank">5</a>
              
          </div>
          
      </div>
      
      <div class="row">
          <div class="cell img">
            <div class="title">Emotional Mastery: Taking Control OF Emotional</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Emotional%20Mastery/SoftCover.png">
          </div>
          <div class="cell description">
              This is a book about emotional mastery. Why mastery? Well, very often, the negative consequences of uncontrolled emotion can have dire effects on how a person is able to live their life.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Emotional%20Mastery/EmotionalMastery.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
             
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Emotional%20Mastery/02-Audiobook/Emotional%20Mastery.mp3" target="_blank">1</a>
              
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">First Impressions: Making A Memorable First Impression On & Offline</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/SoftCover.png">
          </div>
          <div class="cell description">
              First impressions matter in all areas of your life from your career (think job interviews) to your personal life (talking to that cute girl/guy at the coffee shop). In this guide, we’ll take a look at why first impressions have such an impact on us and when it’s important to make sure the impressions are particularly good ones.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/First%20Impressions.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
             
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/02-Audiobook/First%20Impressions-1.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/02-Audiobook/First%20Impressions-2.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/02-Audiobook/First%20Impressions-3.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/02-Audiobook/First%20Impressions-4.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/First%20Impressions/02-Audiobook/First%20Impressions-5.mp3" target="_blank">5</a>
              
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Harmony: Tips For Teams That Thrive</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Harmony/SoftCover.png">
          </div>
          <div class="cell description">
              The concept of team is vital to success in business, and team building is part of every manager's role. By building high performing teams, an organization can harness the power of many individuals to achieve even greater heights. A good team has benefits both for the company and for the individual members of the team. Belonging to a winning team is one of the most satisfying and motivating factors inside and outside of work. Team building is an important part of working life. But what exactly is a team?
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Harmony/Harmony.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
             
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Harmony/02-Audiobook/Harmony-1.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Harmony/02-Audiobook/Harmony-2.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Harmony/02-Audiobook/Harmony-3.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Harmony/02-Audiobook/Harmony-4.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Harmony/02-Audiobook/Harmony-5.mp3" target="_blank">5</a>
              
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Healthy Success: The Link Between Health And Success</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/SoftCover.png">
          </div>
          <div class="cell description">
              When you start to dig a little deeper, success is really about living a happy and fulfilled life. Staying healthy, so you’re able to enjoy the fruits of your labor is a big part of it. The biggest bank account won’t do you much good if you’re not able to enjoy it because you’re sick or in pain. That’s the story behind this guide. It’s about striving for balance and being able to do better work by creating a few healthy habits. 
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/HealthySuccess.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
             
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/02-Audiobook/Healthy%20Success%20-%201.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/02-Audiobook/Healthy%20Success%20-%202.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/02-Audiobook/Healthy%20Success%20-%203.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/02-Audiobook/Healthy%20Success%20-%204.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/02-Audiobook/Healthy%20Success%20-%205.mp3" target="_blank">5</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Healthy%20Success/02-Audiobook/Healthy%20Success%20-%206.mp3" target="_blank">6</a>
              
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">The Introverts Handbook: Surviving The Social Wilderness</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Introverts%20Handbook/SoftCover.png">
          </div>
          <div class="cell description">
              In our hustle and bustle world where often the person who talks the loudest gets the attention, some people would rather not be an introvert. In fact, introverts are misunderstood by many people, including themselves or other introverts. If you are introverted, use this handbook to to survive social situations that usually make you nervous.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Introverts%20Handbook/The%20Introverts%20Handbook.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
             
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Introverts%20Handbook/02-Audiobook/The%20Introverts%20Handbook.mp3" target="_blank">1</a>
              
              
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Looking Back: Reflection & Gratitude In A Busy World</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/Soft%20Cover.png">
          </div>
          <div class="cell description">
              Gratitude is one of the most important components for attracting prosperity and abundance. No matter how brief the experience may be, joy and abundance is the accompanying emotion of gratitude. Feeling abundant, prosperous, joyful, enriched with gratitude makes it a reality. And that is the real key to actually having abundance.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/LookingBack.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/02-Workbook/WorkBook.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadworkbook.png"></a><br>
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/03-Audiobook/Looking%20Back%20-%201.mp3" target="_blank">1</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/03-Audiobook/Looking%20Back%20-%202.mp3" target="_blank">2</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/03-Audiobook/Looking%20Back%20-%203.mp3" target="_blank">3</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/03-Audiobook/Looking%20Back%20-%204.mp3" target="_blank">4</a>
              <a href="http://esev2.s3.amazonaws.com/pdl/Looking%20Back/03-Audiobook/Looking%20Back%20-%205.mp3" target="_blank">5</a>

              
              
          </div>
          
      </div>

      <div class="row">
          <div class="cell img">
            <div class="title">Making Moments: Learning How To Embrace Life</div>
            <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Making%20Moments/SoftCover.png">
          </div>
          <div class="cell description">
              We may not have been given a choice in regard to arriving here. We certainly have little control about when we leave. However, in between those two terminal events, we’re free to do largely as we please. So, lets make some moments.
          </div>
          <div class="cell price" data-title="Occupation">
              <a href="http://esev2.s3.amazonaws.com/pdl/Making%20Moments/MakingMoments.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
             
              <img style="width:100px" src="assets/images/downloadaudio.png">
              <a href="http://esev2.s3.amazonaws.com/pdl/Making%20Moments/02-Audiobook/MakingMoments.mp3" target="_blank">1</a>
          </div>
          
        </div>

        <div class="row">
            <div class="cell img">
              <div class="title">Procrastination: Why We Do It And How To Stop It</div>
              <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Procrastination/SoftCover.png">
            </div>
            <div class="cell description">
                Does it seem like you put things off? Even though you have plenty of time to get a specific job done, do you tend to put things off until the last minute? If your answer to any of these questions was yes, you’re not alone. Learn why we procrastinate and how to stop it. NOW!
            </div>
            <div class="cell price" data-title="Occupation">
                <a href="http://esev2.s3.amazonaws.com/pdl/Procrastination/Procrastination.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                <a href="http://esev2.s3.amazonaws.com/pdl/Procrastination/Unlocking%20Potential%20eBook.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                <img style="width:100px" src="assets/images/downloadaudio.png">
                <a href="http://esev2.s3.amazonaws.com/pdl/Procrastination/02-Audiobook/Procrastination.mp3" target="_blank">1</a>
            </div>
            
          </div>


          <div class="row">
              <div class="cell img">
                <div class="title">Rational: Critical Thinking Skills For Everyone</div>
                <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Rational/SoftCover.png">
              </div>
              <div class="cell description">
                  In this information age, it's becoming more and more important to think critically, but with the amount of information offered it's easy just to move onto the next topic without thinking at all. We’ll take a closer look at what precisely critical thinking is and why it is such an important skill that everyone should work on developing.  
              </div>
              <div class="cell price" data-title="Occupation">
                  <a href="http://esev2.s3.amazonaws.com/pdl/Rational/Rational.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                 
                  <img style="width:100px" src="assets/images/downloadaudio.png">
                  <a href="http://esev2.s3.amazonaws.com/pdl/Rational/02-Audiobook/Critical%20Thinking%20-%201.mp3" target="_blank">1</a>
                  <a href="http://esev2.s3.amazonaws.com/pdl/Rational/02-Audiobook/Critical%20Thinking%20-%202.mp3" target="_blank">2</a>
                  <a href="http://esev2.s3.amazonaws.com/pdl/Rational/02-Audiobook/Critical%20Thinking%20-%203.mp3" target="_blank">3</a>
                  <a href="http://esev2.s3.amazonaws.com/pdl/Rational/02-Audiobook/Critical%20Thinking%20-%204.mp3" target="_blank">4</a>
                  <a href="http://esev2.s3.amazonaws.com/pdl/Rational/02-Audiobook/Critical%20Thinking%20-%205.mp3" target="_blank">5</a>
              </div>
              
            </div>


            <div class="row">
                <div class="cell img">
                  <div class="title">Resolve: Boosting Willpower</div>
                  <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Resolve/SoftCover.png">
                </div>
                <div class="cell description">
                    Let’s talk about willpower. According to the American Psychological Association willpower is "the ability to resist short-term temptations in order to meet long-term goals." It’s what helps us make big strides and reach lofty goals. Without willpower, it’s hard to make serious progress in all areas of our lives - from how we look and feel through to how much money we have in our bank account. To say it’s an important life-skill to master and improve is an understatement.   
                </div>
                <div class="cell price" data-title="Occupation">
                    <a href="http://esev2.s3.amazonaws.com/pdl/Resolve/Resolve.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                   
                    <img style="width:100px" src="assets/images/downloadaudio.png">
                    <a href="http://esev2.s3.amazonaws.com/pdl/Resolve/02-Audiobook/Willpower%20-%201.mp3" target="_blank">1</a>
                    <a href="http://esev2.s3.amazonaws.com/pdl/Resolve/02-Audiobook/Willpower%20-%202.mp3" target="_blank">2</a>
                    <a href="http://esev2.s3.amazonaws.com/pdl/Resolve/02-Audiobook/Willpower%20-%203.mp3" target="_blank">3</a>
                    <a href="http://esev2.s3.amazonaws.com/pdl/Resolve/02-Audiobook/Willpower%20-%204.mp3" target="_blank">4</a>
                    <a href="http://esev2.s3.amazonaws.com/pdl/Resolve/02-Audiobook/Willpower%20-%205.mp3" target="_blank">5</a>
                </div>
                
              </div>

              <div class="row">
                  <div class="cell img">
                    <div class="title">The Miracle Of Meditation: A Beginners Handbook To Achieving Inner Peace</div>
                    <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/The%20Miracle%20Of%20Meditation/SoftCover.png">
                  </div>
                  <div class="cell description">
                      Meditation can be a powerful tool that will improve your life, your mental well-being, and even your physical health. We live in an extremely busy and hectic time. We’re constantly bombarded by information and various stimuli. We need to find balance in our lives and we need to relax. One of the best stress busters out there is the practice of meditation.  
                  </div>
                  <div class="cell price" data-title="Occupation">
                      <a href="http://esev2.s3.amazonaws.com/pdl/The%20Miracle%20Of%20Meditation/MiracleOfMeditation.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                     
                      <img style="width:100px" src="assets/images/downloadaudio.png">
                      <a href="http://esev2.s3.amazonaws.com/pdl/The%20Miracle%20Of%20Meditation/02-Audiobook/Meditation.mp3" target="_blank">1</a>
                      
                  </div>
                  
                </div>


                <div class="row">
                    <div class="cell img">
                      <div class="title">Trust Trifecta: Building Trust - Trusting Others - Trusting Yourself</div>
                      <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/SoftCover.png">
                    </div>
                    <div class="cell description">
                        Trust is the foundation of all human connections. Trust forms the basis of every relationship. Our entire human existence – culture, civilization, and community all depend on trust. We define our lives by how we view ourselves and the types of relationships we create. Trust is at the heart of all that. In this eBook, we will discuss various tips on how to build trust.  
                    </div>
                    <div class="cell price" data-title="Occupation">
                        <a href="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/TrustTrifecta.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                       
                        <img style="width:100px" src="assets/images/downloadaudio.png">
                        <a href="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/02-Audiobook/Trust%20eBook%20-1.mp3" target="_blank">1</a>
                        <a href="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/02-Audiobook/Trust%20eBook%20-2.mp3" target="_blank">2</a>
                        <a href="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/02-Audiobook/Trust%20eBook%20-3.mp3" target="_blank">3</a>
                        <a href="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/02-Audiobook/Trust%20eBook%20-4.mp3" target="_blank">4</a>
                        <a href="http://esev2.s3.amazonaws.com/pdl/Trust%20Trifecta/02-Audiobook/Trust%20eBook%20-5.mp3" target="_blank">5</a>
                    </div>
                    
                  </div>

                  <div class="row">
                      <div class="cell img">
                        <div class="title">Unlocking Potential: Becoming The Best Version Of Yourself</div>
                        <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Unlocking%20Potential/SoftCover.png">
                      </div>
                      <div class="cell description">
                          You know you could do great things… if you could only get some clarity on what you should pursue, what you should do first, and what talents you should develop. In other words, you need help learning how to unlock your hidden potential so you can take the world by storm. This book will help you do just that.
                      </div>
                      <div class="cell price" data-title="Occupation">
                          <a href="http://esev2.s3.amazonaws.com/pdl/Care%20Less/03-WorkBook/CareLessWorkBook.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadworkbook.png"></a><br>
                         
                          <img style="width:100px" src="assets/images/downloadaudio.png">
                          <a href="http://esev2.s3.amazonaws.com/pdl/Unlocking%20Potential/Workbook/UnlockPotentialWorkbook.pdf" target="_blank">1</a>
                          
                      </div>
                      
                    </div>


                    <div class="row">
                        <div class="cell img">
                          <div class="title">Winning PErsonality: How To Attract Friends & Be More Likeable</div>
                          <img style="width:165px" src="http://esev2.s3.amazonaws.com/pdl/Winning%20Personality/SoftCover1.png">
                        </div>
                        <div class="cell description">
                            Being more sociable and able to bring people over to your side or your cause is one of the most important and most useful skills you can acquire. It will make your job, your personal life, and everything in between easier and more fun.  
                        </div>
                        <div class="cell price" data-title="Occupation">
                            <a href="http://esev2.s3.amazonaws.com/pdl/Winning%20Personality/Winning%20Personality.pdf" target="_blank"><img style="width:100px;padding-bottom:15px;" src="assets/images/downloadpdf.png"></a><br>
                           
                            <img style="width:100px" src="assets/images/downloadaudio.png">
                            <a href="http://esev2.s3.amazonaws.com/pdl/Winning%20Personality/02-Audiobook/Winning%20Personality.mp3" target="_blank">1</a>
                            
                        </div>
                        
                      </div>


    </div>
    
    
    
  </div>
