import {Injectable, EventEmitter} from '@angular/core';
import {Images} from "./files";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FilesService {
  private hostName = window.location.protocol + '//' + window.location.host;
  images: Images[] = [];
  imagesIsEdit = new EventEmitter<Images>();
  constructor(private httpclient:HttpClient) { }

  editImages(images: Images) {
    console.log(images)
    this.imagesIsEdit.emit(images);
  }

  getSignedRequest(fileName, fileType) {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.get('/api2/upload/sign-s3?fileName='+fileName+"&fileType="+fileType,  {headers: headers})
     
  }

  getAllObjects() {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.get('/api2/s3/getmyobjects',  {headers: headers})
   
  }

  upload (signedRequest, file){
    const headers = new HttpHeaders({'Content-Type': 'image/jpeg'});
    
    return this.httpclient.put(signedRequest, file, {headers:headers})
      
  }

  getS3Credentials() {
    var body='';
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/upload/getS3Credentials/', body, {headers: headers})
    
  }

  sendTemplateEmail() {
    var body=JSON.stringify({"dave":"sloan"});
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/upload/sendme', body, {headers: headers})
    
  }

  addRecord(images: any) {
    const body = JSON.stringify(images);
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/upload/addrecord/', body, {headers: headers})
    
  }

  deleteRecord(images: any){
    const body = JSON.stringify(images);
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.post('/api2/s3/deleteImage/', body, {headers: headers})
   
  }

  getAllRecords() {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.get('/api2/upload/getallrecords/',  {headers: headers})
  
  }

  getAllMyRecords() {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.get('/api2/upload/getallmyrecords/',  {headers: headers})
 
  }

  getCategoryRecords(value) {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.get('/api2/upload/getcategoryrecords/'+value,  {headers: headers})
     
  }

  updateRecord(images: Images){
    const body = JSON.stringify(images);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.patch('/api2/upload/updaterecord/' + images.imagesId, body, {headers: headers})
    
  }

  getRecord(id) {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this.httpclient.get('/api2/upload/getrecord/'+id,  {headers: headers})
    
  }

}
