import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit, AfterViewInit {
  
  fonts=['arial','helvetica','impact'];
  @ViewChild('dave',{static: true}) dave: ElementRef;
  @ViewChildren("editor") editors: QueryList<any>
  constructor(private elRef: ElementRef) { }

  ngOnInit() {
   
  }

  ngAfterViewInit() {
    document.designMode="true";
   //this.dave.nativeElement.contentEditable = "true";
   ///this.editors.forEach(editor => editor.contentEditable="true");
   var hElement: HTMLElement = this.elRef.nativeElement;

    
  }

  iBold(){
    document.execCommand('bold', false, null);
  }

  iItalics(){
    document.execCommand('italic', false, null);
  }

  selectFont(event){
    console.log(event.target.value);
    document.execCommand("fontName", false, event.target.value);
  }

  save(){
   // console.log(document.getElementById('editor').innerHTML);
    console.log(document.activeElement);
  }

}
