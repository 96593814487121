import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leads-legend',
  templateUrl: './leads-legend.component.html',
  styleUrls: ['./leads-legend.component.css']
})
export class LeadsLegendComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
