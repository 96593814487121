import {Injectable, EventEmitter} from '@angular/core';
import {Support} from "./support";
import { Observable } from "rxjs";
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class SupportService {
  private hostName = window.location.protocol + '//' + window.location.host;
  support: Support[] = [];
  supportIsEdit = new EventEmitter<Support>();
  constructor(private httpclient: HttpClient) { }

  editSupport(support: Support) {
    this.supportIsEdit.emit(support);
  }

  addRecord(support: Support) {
    const body = JSON.stringify(support);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.post(this.hostName +'/api/support/addrecord' + token, body, {headers: headers})
      
  }

  deleteRecord(support: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.delete(this.hostName +'/api/support/deleterecord/' + support._id + token, {headers: headers})
     
  }

  getAllRecords() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/support/getallrecords'+token,  {headers: headers})
      
  }

  getAllTopLevelRecords() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/support/getalltoplevelrecords'+token,  {headers: headers})
      
  }

  getMyTopLevelRecords() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/support/getmytoplevelrecords'+token,  {headers: headers})
      
  }

  updateRecord(support: Support){
    const body = JSON.stringify(support);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.patch(this.hostName +'/api/support/updaterecord/' + support.supportId + token, body, {headers: headers})
     

  }

  getRecord(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/support/getrecord/'+id+token,  {headers: headers})
      
  }

  getRecordsByThread(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    return this.httpclient.get(this.hostName +'/api/support/getrecordsbythread/'+id+token,  {headers: headers})
      
  }

}
