import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {FunnelsService} from "../../funnels/funnels.service";
//import * as grapesjs from 'grapesjs';
import { CommService } from '../../../../services/comm.service';
//import 'grapesjs-preset-webpage';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { FileUploadService } from '../fileupload/fileupload.service';
import { NgForm } from '@angular/forms';
import { htmlPrefilter } from 'jquery';
import { TemplatesService } from '../../templates/templates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from '../../categories/categories.service';
import { TypesService } from '../../types/types.service';
import { SubtypesService } from '../../subtypes/subtypes.service';
import { R3TargetBinder } from '@angular/compiler';
import { DialogPosition, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrebuiltComponent } from '../prebuilt/prebuilt.component';
import { DomainsService } from '../../domains/domains.service';
import { AuthService } from '../../auth/auth.service';
import { ConfirmService } from '../../shared/confirm/confirm.service';
import { ConfirmComponent } from '../../shared/confirm/confirm.component';
declare const grapesjs:any;
declare var css_beautify:any;
declare var html_beautify:any;
import Piklor from './colorPicker';

@Component({
  selector: 'app-grapesjs',
  template: `
  <div *ngIf="isLoading" class="loader-overlay">
  <div>
      <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
  </div>
</div>
  <mat-drawer-container class="example-container" >
    <mat-drawer #drawer class="example-sidenav" mode="over" position="end">
      <app-files (imagePath)="processImage($event)" (closeButtonClick)="onCloseMediaMediaModal($event)"></app-files>
    </mat-drawer>
    <div class="example-sidenav-content">
     
      <div class="panel__top">
        <div class="panel__basic-actions"></div>
      </div>
      <div class="editor-row">
        <div class="editor-canvas" >
            <div id="gjs" ></div>
        </div>
        <div class="panel__right" style="display:none;">
        <div style="width:100%;background-color:#039be5;color:white;font-weight:900;padding-left:10px;">Layers</div>
            <div class="layers-container"></div>
        </div>
      </div>
      <div id="blocks"></div>
    </div>
  </mat-drawer-container>

  <div mdbModal #addModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop:false}">
    <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document" (closed)="onAddModalClosed($event)">
        <div class="modal-content">
            <div class="modal-header light-blue">
                <table>
                  <tr>
                  <td>
                    <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="f.ngSubmit.emit()" [disabled]="!f.form.valid" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Update</button>
                  </td>
                  <td>
                    <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="onCloseAddModal($event)" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
                  </td>
                  </tr>
                </table>
              
              
              <h4 class="modal-title w-100;" style="color:#fff" id="myModalLabel">Template Info</h4>
            </div>
            <!---begin modal body -->
            <div class="modal-body">
              <form #f="ngForm" (ngSubmit)="save(f, f.value, f.valid)">
  
              <span style="font-size:14pt"><span style="font-weight:bold;">Template Id: </span>{{templatesId}}</span><br>
              <span style="font-size:14pt"><span style="font-weight:bold;">Template Name: </span>{{templatesName}}</span><br>
              <span style="font-size:14pt"><span style="font-weight:bold;">User ID: </span>{{templatesUser}}</span>
              
              <br><br>
              <input type="hidden" name="id" #id="ngModel" [(ngModel)]="templatesId" id="id">
              <input type="hidden" name="type" #type="ngModel" [(ngModel)]="templatesType" id="type">
              <span style="color:red">* Indicates a required field</span><br><br>
              <mat-form-field class="example-full-width">
                <input matInput name="name" #name="ngModel" [(ngModel)]="templatesName" type="text" id="name" placeholder="Enter the Name or Title" required>
                <mat-error>This is a required field</mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width" style="display:none">
                <input matInput name="version" #version="ngModel" [(ngModel)]="templatesVersion" type="text" id="version" placeholder="Enter the version of this template. It can be anything.">
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width">
                <input matInput name="description" #description="ngModel" [(ngModel)]="templatesDescription" type="text" id="description" placeholder="Enter the Description (personal Reference)">
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width" style="display:none">
                  <input matInput name="nextstep" #nextstep="ngModel" [(ngModel)]="templatesNextstep" type="text" id="nextstep" placeholder="What is the next step after this page?">
                </mat-form-field>
                <br>
              <div class="row">
                  <div class="col-md-12">
                    <label>What is the funtion of this page?</label>
                    <select class="browser-default custom-select" placeholder="Choose the SubType of Template" [(ngModel)]='templatesSubtypeId' label="subtype" name="subtype" #subtype id="subtype">
                      <option value="noValue">----</option>
                      <option   *ngFor="let subtype of subtypes" [value]="subtype._id">{{ subtype.name }}</option>
                    </select>
                  </div>
              </div>

              <div>
                <mat-checkbox style="display:none" name="redirect" #redirect="ngModel" [(ngModel)]="templatesRedirect" type="text" id="redirect" placeholder="Just a Redirect">Is this just a redirect page?</mat-checkbox>
                <br>

                <mat-form-field class="example-full-width" style="display:none">
                  <input matInput name="redirect_url" #redirect_url="ngModel" [(ngModel)]="templatesRedirect_url" type="text" id="redirect_url" placeholder="If this is a redirect page, What is the url?">
                </mat-form-field>
            
                <mat-form-field class="example-full-width">
                  <input (keyup)="checkDomain($event)" [ngClass]="{disabled:disableWid==true}" [readonly]="disableWid" matInput name="wid" #wid="ngModel" [(ngModel)]="templatesWid" type="text" id="wid" placeholder="Enter the WID (this is the path of the page. for example /mypage" required>
                  <mat-error>This is a required field</mat-error>
                </mat-form-field>
            
                <br>
                  <div class="row">
                  <div class="col-md-12">
                    <label>Choose a Domain or leave blank for all domains</label>
                    <select class="browser-default custom-select" placeholder="Choose a Domain or leave blank for all domains" [(ngModel)]="templatesDomain" label="domain" name="domain" #domain id="domain">
                      <option value="noValue">----</option>
                      <option   *ngFor="let domain of listOfDomains" [value]="domain.value">{{ domain.name }}</option>
                    </select>
                  </div>
              </div>
              
                <div style="display:none">
                  <mat-form-field class="example-full-width">
                    <textarea matInput name="sovrn_info" #hsovrn_info="ngModel" [(ngModel)]="templatesSovrn_info" type="text" id="sovrn_info" placeholder="sovrn edited text will show here."></textarea>
                  </mat-form-field>
                </div>
                  
                <div style="display:none">
                  <mat-form-field class="example-full-width">
                    <textarea matInput name="headline" #headline="ngModel" [(ngModel)]="templatesHeadline" type="text" id="headline" placeholder="inline edited text will show here."></textarea>
                  </mat-form-field>
                </div>
            
                <mat-form-field class="example-full-width" style="display:none">
                    <input matInput name="displayorder" #displayorder="ngModel" [(ngModel)]="templatesDisplayOrder" type="text" id="displayorder" placeholder="Enter A Display order (This field is not curently used">
                    <mat-error>This is a required field</mat-error>
                  </mat-form-field>
            
                <mat-form-field class="example-full-width">
                  <input matInput name="image" #image="ngModel" [(ngModel)]="templatesImage" type="text" id="image" placeholder="Here you can add a url for a thumbnail image of this template.">
                </mat-form-field>

                <mat-form-field class="example-full-width" style="display:none">
                  <input matInput name="config" #config="ngModel" [(ngModel)]="templatesConfig" type="text" id="config" placeholder="Config">
                </mat-form-field>

                <mat-form-field class="example-full-width" style="display:none">
                  <input matInput name="parent" #parent="ngModel" [(ngModel)]="templatesParent" type="text" id="parent" placeholder="Parent">
                </mat-form-field>

               

                <div class="row">
                    <div class="col-md-12">
                      
                      <label>Choose a Layout</label>
                      <select class="browser-default custom-select" placeholder="Choose your layout" [(ngModel)]='templatesLayoutId' label="Layout" name="layout" #layout id="layout">
                        <option value="null">----</option>
                        <option   *ngFor="let layout of layouts" [value]="layout._id">{{ layout.name }}</option>
                      </select>
                    </div>
                  </div>
            
                 
                  
                  <div class="row">
                    <div class="col-md-12">
                      
                      <label>Choose a niche for this template.</label>
                      <select class="browser-default custom-select" placeholder="Choose your autoresponder" [(ngModel)]='templatesCategoryId' label="Category" name="category" #category id="category">
                        <option value="noValue">----</option>
                        <option   *ngFor="let category of categories" [value]="category._id">{{ category.name }}</option>
                      </select>
                    </div>
                  </div>
                  <br>
                
                  <div class="row">
                    <div class="col-md-12">
                      <label>Choose the Type of Template</label>
                      <select class="browser-default custom-select" placeholder="Choose the Type of Template" [(ngModel)]='templatesTypeId' label="type" name="type" #type id="type">
                        <option value="noValue">----</option>
                        <option   *ngFor="let type of types" [value]="type._id">{{ type.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <label>Choose the Type of Template</label>
                      <select class="browser-default custom-select" placeholder="Choose the Sub Type of Template" [(ngModel)]='templatesSubTypeId' label="type" name="type" #type id="type">
                        <option value="noValue">----</option>
                        <option   *ngFor="let subtype of subtypes" [value]="subtype._id">{{ subtype.name }}</option>
                      </select>
                    </div>
                  </div>
            
          
              </div>
               
          
                
              
                <br/><br/>
              </form>
          
            </div>
            <!---end modal body -->
      </div>
    </div> 
  </div> 



<!---begin html code model body -->
  <div mdbModal #htmlModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel1" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header light-blue">
            <table>
              <tr>
              
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="htmlModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
              </td>

              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="importHtml()" style="color:#fff;background: none!important"><mat-icon>import_export</mat-icon> Save</button>
              </td>
              
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="onOpenJsModal()" style="color:#fff;background: none!important"><mat-icon>code</mat-icon> JS</button>
              </td>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="onOpenHeadModal()" style="color:#fff;background: none!important"><mat-icon>title</mat-icon> Head</button>
              </td>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="onOpenCssModal()" style="color:#fff;background: none!important"><mat-icon>title</mat-icon> CSS</button>
              </td>
              </tr>
            </table>
        
        
            <h4 class="modal-title w-100;" style="color:#fff" id="myModalLabel">HTML</h4>
          </div>
          <div class="modal-body">
            
          </div>
          <div class="modal-footer justify-content-end">
            <ace [config]="options" name="htmleditor"  #htmleditor id="htmleditor" [mode]="'javascript'" [theme]="'cobalt'"  style="height:80vh;" [(value)]="templatesHtml" ngDefaultControl></ace>
          </div>
        </div>
    </div>
</div>
<!--end html model body -->

<!---begin javascript model body -->
  <div mdbModal #jsModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel1" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header light-blue">
            <table>
              <tr>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="f.ngSubmit.emit()" [disabled]="!f.form.valid" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Save</button>
              </td>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="jsModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
              </td>
              </tr>
            </table>
        
        
            <h4 class="modal-title w-100;" style="color:#fff" id="myModalLabel">Javascript</h4>
          </div>
          <div class="modal-body">
            
          </div>
          <div class="modal-footer justify-content-end">
            <ace [config]="options" name="body"  #editor id="editor" [mode]="'javascript'" [theme]="'cobalt'"  [(value)]="templatesJavascript" style="height:80vh;" ngDefaultControl></ace>
          </div>
        </div>
    </div>
</div>
<!--end javascript model body -->

<!---begin head model body -->
  <div mdbModal #headModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel1" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header light-blue">
            <table>
              <tr>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="f.ngSubmit.emit()" [disabled]="!f.form.valid" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Save</button>
              </td>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="headModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
              </td>
              </tr>
            </table>
        
        
            <h4 class="modal-title w-100;" style="color:#fff" id="myModalLabel">Head</h4>
          </div>
          <div class="modal-body">
            
          </div>
          <div class="modal-footer justify-content-end">
            <ace [config]="options" name="body"  #editor id="editor" [mode]="'javascript'" [theme]="'cobalt'"  style="height:80vh;" [(value)]="templatesHead" ngDefaultControl></ace>
          </div>
        </div>
    </div>
</div>
<!--end head model body -->

<!---begin CSS model body -->
  <div mdbModal #cssModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel1" aria-hidden="true" style="overflow-y: scroll" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header light-blue">
            <table>
              <tr>
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="importCss()" [disabled]="!f.form.valid" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Save</button>
              </td>
              
              <td>
                <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="cssModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
              </td>
              </tr>
            </table>
        
        
            <h4 class="modal-title w-100;" style="color:#fff" id="myModalLabel">CSS</h4>
          </div>
          <div class="modal-body">
            
          </div>
          <div class="modal-footer justify-content-end">
            <ace [config]="options" name="body"  #editor id="editor" [mode]="'javascript'" [theme]="'cobalt'"  style="height:80vh;" [(value)]="templatesCss" ngDefaultControl></ace>
          </div>
        </div>
    </div>
</div>
<!--end CSS model body -->

<!--begin media manager modal -->
<div mdbModal #mediaManagerModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
      <div class="modal-content">
          <div class="modal-header light-blue">
          <table>
            <tr>
            <td>
              <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect   (click)="processMediaEmitter($event)" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Refresh</button>
            </td>
            <td>
              <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="mediaManagerModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
            </td>
            </tr>
          </table>
            <span style="width:98%;text-align:right;"><h4 style="font-weight:900;color:#fff">UPLOAD MEDIA</h4></span>
            
          </div>
          <div class="modal-body">
            <app-files (imagePath)="processImage($event)" (closeButtonClick)="onCloseMediaMediaModal($event)"></app-files>
          </div>
          
      </div>
  </div > 
</div >
<!--end medai manager modal -->

<!--begin autoresponder chooser modal -->
<div mdbModal #autoresponderChooserModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
      <div class="modal-content">
          <div class="modal-header light-blue">
          <table>
            <tr>
            <td>
              <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect   (click)="processAutoresponderChooserEmitter($event)" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Refresh</button>
            </td>
            <td>
              <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="autoresponderChooserModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
            </td>
            </tr>
          </table>
            <span style="width:98%;text-align:right;"><h4 style="font-weight:900;color:#fff">UPLOAD MEDIA</h4></span>
            
          </div>
          <div class="modal-body">
            <app-files (imagePath)="setBackground($event)" (closeButtonClick)="onCloseAutoresponderChooserModal($event)"></app-files>
          </div>
          
      </div>
  </div > 
</div >
<!--end autoresponder chooser modal -->

<!--begin background chooser modal -->
<div mdbModal #backgroundChooserModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
      <div class="modal-content">
          <div class="modal-header light-blue">
          <table>
            <tr>
            <td>
              <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect   (click)="processAutoresponderChooserEmitter($event)" style="color:#fff;background: none!important"><mat-icon>save</mat-icon> Refresh</button>
            </td>
            <td>
              <button mat-flat-button  class="nav-link waves-light" mdbWavesEffect  (click)="backgroundChooserModal.hide()" style="color:#fff;background: none!important"><mat-icon>close</mat-icon> Close</button>
            </td>
            </tr>
          </table>
            <span style="width:98%;text-align:right;"><h4 style="font-weight:900;color:#fff">UPLOAD MEDIA</h4></span>
            
          </div>
          <div class="modal-body">
            <app-files (imagePath)="setBackground($event)" (closeButtonClick)="onCloseBackgroundChooserModal($event)"></app-files>
          </div>
          
      </div>
  </div > 
</div >
<!--end background chooser modal -->

<!--begin prebuilt manager modal -->
<div mdbModal #prebuiltModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll">
  <div class="modal-dialog  modal-fluid modal-full-height modal-lg modal-right modal-dialog-full-width" role="document">
      <div class="modal-content">
          <div class="modal-header light-blue">
            <span style="width:98%;text-align:right;"><h4 style="font-weight:900;color:#fff">Choose a Prebuilt Template</h4></span>
            
              <button type="button" class="close pull-right" aria-label="Close" (click)="prebuiltModal.hide()">
                  <span aria-hidden="true">×</span>
              </button>
          </div>
          <div class="modal-body">
          <app-prebuilt (imagePath)="processPrebuilt($event)" (closeButtonClick)="onClosePrebuiltModal($event)"></app-prebuilt>
          </div>
          
      </div>
  </div > 
</div >
<!--end prebuilt manager modal -->
  `,
  styleUrls: ['./grapesjs.component.css']
})
export class GrapesjsComponent implements OnInit {
  disableWid:boolean=true;
  listOfDomains = [
    {name:'www.tomato.com' , value:'www.tomato.com'},
    {name:'www.profitacceleratorsystem.com' , value:'www.profitacceleratorsystem.com'},
    {name:'www.getmybestbody.com', value:'www.getmybestbody.com'},
    {name:'www.lifesuccessunlocked.com',value:"www.lifesuccessunlocked.com"},
    {name:'www.easiestsystemever.com',value:"www.easiestsystemever.com"},
    {name:'www.12minuteaffiliate.com',value:"www.12minuteaffiliate.com"},
    {name:'www.esecontest.com',value:"www.esecontest.com"},
    {name:'www.smyrnaoffroad.com',value:"www.smyrnaoffroad.com"},
    {name:'www.davidjsloan.com',value:"www.davidjsloan.com"},
    {name:'www.funld.com',value:"www.funld.com"}
  ]

  isLoading=false;
  username=localStorage.getItem('username')?localStorage.getItem('username'):'';
  @ViewChild('addModal') addModal: ModalDirective;
  
  onCloseAddModal($event){ 
    
    if (this.runWizard==true){
      this.startWizardStepOne();
    }
    this.addModal.hide();
  }
  onOpenAddModal(){this.addModal.show();}
  onAddModalClosed($event){

  };
  @ViewChild('f') infoForm;s
  onSubmitForm=()=>{
    this.isLoading=true;
    if (this.infoForm.form.valid){
      setTimeout(()=> {
        this.infoForm.ngSubmit.emit();
      }
      ,1000);
    }
  }

  @ViewChild('drawer') drawer;

  niche;
  initial;
  createTemplate;
  confirmPressed = false;

  startWizard(){
   
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"Step 1?", body:"Please Enter Some Information about your new template?.", icon:"delete", class:"warn", okButtonTxt:"OK", cancelButtonTxt:"Close"});

    dialogRef.afterClosed().subscribe(result => {
      console.log(this.confirmPressed)
      if (this.confirmPressed == true){
        this.onOpenAddModal()
      }
    });
  }

  startWizardStepOne(){
   
    let dialogRef = this.dialog.open(ConfirmComponent, {
      height: '275px',
      width: '375px',

    });

    this.confirmService.getText({title:"Step 2?", body:"Do you want to user a prebuilt template?.", icon:"delete", class:"warn", okButtonTxt:"YES", cancelButtonTxt:"No"});

    dialogRef.afterClosed().subscribe(result => {
      
      if (this.confirmPressed == true && this.runWizard==true){
        this.onOpenPrebuiltModal()
      }
    });
  }

  sendPageInfo(){
    this.commService.sendMessage({'action':'pageInfo', 
    'value':this.niche, 
    "initial":this.initial,
    "pageMenu":"sovren",
    "createTemplate":this.createTemplate,
    breadcrumbs:[
      {name:'Home',active:false,link:"/members/home"},
      {name:'Niche',active:false,link:"/members/niche/"+this.niche+"/"+this.initial},
      {name:'Home Business',active:false,link:"/members/niche/"+this.niche+"/"+this.initial},
      {name:'Custom',active:false,link:"/members/niche/custom"},
      {name:'Edit Funnel',active:true},
      {name:this.funnelId,active:true}
    ]});
  }

  goReturn(){
   console.log('closeme')
    localStorage.removeItem('gjs-css');
    localStorage.removeItem('gjs-html');
    localStorage.removeItem('gjs-components');
    localStorage.removeItem('gjs-styles');
    localStorage.removeItem('gjs-assets');
    localStorage.removeItem('gjsProjects');
    localStorage.removeItem('gjsProject');
    this.dialogRef.close();
   // this.router.navigate(['/members/myfunnelsedit/',this.funnelId]);
  }

  htmleditor;
  templatesHtml="";
  selectedComponent;
  wasDropped=false;
  currentImage;

  @ViewChild('jsModal') jsModal: ModalDirective;
  onCloseJsModal($event){ this.jsModal.hide();}
  onOpenJsModal(){this.jsModal.show();}
  onJsModalClosed($event){}

  @ViewChild('headModal') headModal: ModalDirective;
  onCloseHeadModal($event){ this.headModal.hide();}
  onOpenHeadModal(){this.headModal.show();}
  onHeadModalClosed($event){}

  @ViewChild('cssModal') cssModal: ModalDirective;
  onCloseCssModal($event){ this.cssModal.hide();}
  onOpenCssModal(){this.cssModal.show();}
  onCssModalClosed($event){}

  @ViewChild('autoresponderChooserModal') autoresponderChooserModal: ModalDirective;
  onCloseAutoresponderChooserModal($event){ this.wasDropped=false; this.drawer.close()}
  onOpenAutoresponderChooserModal(){
    this.autoresponderChooserModal.show();
    //this.drawer.open(); 
  }
  onMediaAutoresponderChooserModalClosed($event){}
  processAutoresponderChooserEmitter($event){
    this.commService.sendMessage({'action':'saveFunnelEdit', 'value':true});
  }

  @ViewChild('mediaManagerModal') mediaManagerModal: ModalDirective;
  onCloseMediaMediaModal($event){ this.wasDropped=false; this.drawer.close()}
  onOpenMediaModal(){
    this.mediaManagerModal.show();
    //this.drawer.open(); 
  }
  onMediaMManagerModalClosed($event){}
  processMediaEmitter($event){
    this.commService.sendMessage({'action':'saveFunnelEdit', 'value':true});
  }

  @ViewChild('backgroundChooserModal') backgroundChooserModal: ModalDirective;
  onCloseBackgroundChooserModal($event){ this.wasDropped=false; this.drawer.close()}
  onOpenBackgroundChooserModal(){
    console.log("open bg")
    this.backgroundChooserModal.show();
    //this.drawer.open(); 
  }
  onBackgroundChooserModalClosed($event){}
  

  setBackground($event){
    console.log("set background",$event);
    var url='url("'+$event.data.url+'")';
    const cmp = this.editor.Components;
    var wrapper = cmp.getWrapper();
    wrapper.set('style', {'background-image': url});
    wrapper.set('attributes', {'title': 'Hello!'});
  }

  processImage($event){
   
    var component=this.selectedComponent;
    

    console.log(component);
  
   
    if (this.selectedComponent.attributes.type=="image"){
      component.addAttributes({'crossorigin':"anonymous"})
      component.set('src',$event.data.url+"?cacheblock=true");
     
      this.currentImage=$event.data.url;
     
    } else {
      component=this.editor.getSelected();
     
      var url='url("'+$event.data.url+'")';
      if (component!=undefined){
        console.log('image access')
        component.addStyle({
          'background-repeat':'repeat',
          'background-position':'left top',
          'background-attachment':'scroll',
          'background-size':'auto',
          'background-image':url
        })
      }
     
    }
   
  }

  

  @ViewChild('htmlModal') htmlModal: ModalDirective;
  
  onCloseHtmlModal($event){ this.htmlModal.hide();}
  onOpenHtmlModal(){

    /*this.templatesService.getRecordV2(this.templatesId).subscribe(
      data => {
        this.templatesJavascript=data['obj'].javascript;
            this.templatesHead=data['obj'].head;
            this.templatesCss=data['obj'].css;
            this.templatesHtml=this.b64DecodeUnicode(data['obj'].body);
      }); */
  
    
    var loadedHtml=this.editor.getHtml();
    var loadedCSS=this.editor.getCss();
    var loadedJS=this.editor.getJs();
   
    this.templatesHtml=html_beautify(loadedHtml);
    this.templatesCss=css_beautify(loadedCSS);
    if (loadedJS){
      this.templatesHtml=this.templatesHtml;
    }
    this.htmlModal.show();
  }
  onHtmlModalClosed($event){}

  importHtml(){
    this.editor.setComponents(this.templatesHtml);
    this.editor.setStyle(this.templatesCss);
    this.onSubmitForm();
    
    
    
  }

  importCss(){
    this.editor.setComponents(this.templatesHtml);
    this.editor.setStyle(this.templatesCss);
    this.onSubmitForm();
    
    
  }

  /***Prebuilt Functions***/
  @ViewChild('prebuiltModal') prebuiltModal: ModalDirective;
  onClosePrebuiltModal($event){ 
    this.runWizard=false;
    this.prebuiltModal.hide();
  }
  
  onOpenPrebuiltModal(){
    this.prebuiltModal.show();
  }
  
  processPrebuilt($event){
   
    var payLoad={_id:$event.data._id,subtype:$event.data.type,wid:$event.data.wid}
    let id=payLoad._id
    
    this.templatesService.getRecordV2(id).subscribe(
      data => {
        var sovrnInfo, parseInfo;
        
        try {
          sovrnInfo = atob(data['obj'].sovrn_info)
        } catch(e){
          sovrnInfo = {};
        }
        
        try {
          parseInfo=JSON.parse(sovrnInfo);
          this.newBody=parseInfo;
          
          var gjsProject = this.newBody['gjsProject'];
         
          localStorage.setItem('gjs-components',this.newBody['gjs-components']);
          localStorage.setItem('gjs-css',this.newBody['gjs-css']);
          localStorage.setItem('gjs-html',this.newBody['gjs-html']);
          localStorage.setItem('gjs-styles',this.newBody['gjs-styles']); 
          localStorage.setItem('gjsProject',gjsProject); 
        } catch(e){
          parseInfo='';
        }
        this.initEditor();
        
        this.typesService.getTypeByName({name:'My Templates'}).subscribe(
          typeData=> {
            
            
            this.templatesName=data['obj'] != undefined ? data['obj'].name+"-"+Date.now() + Math.random():null;
            this.templatesWid=data['obj'] != undefined ? Date.now() + Math.random():null; 
            this.templatesDomain=data['obj'] != undefined ? data['obj'].domain:null;
            this.templatesDescription=data['obj'] != undefined ? data['obj'].description:null;
            this.templatesDisplayOrder=data['obj'] != undefined ? data['obj'].displayorder:null;
            this.templatesImage=data['obj'] != undefined ? data['obj'].image:null;
            this.templatesConfig=data['obj'] != undefined ? data['obj'].config:null;
            this.templatesParent=data['obj'] != undefined ? data['obj'].parent:null;
            this.templatesCategoryId=data['obj'] != undefined ? data['obj'].category._id:null;
            this.templatesTypeId=typeData['obj'] != undefined ? typeData['obj']._id:null;
            this.templatesSubtypeId=data['obj'] != undefined ? data['obj'].subtype._id:null;
            this.templatesVersion=data['obj'] != undefined ? data['obj'].version:null;
            this.templatesNextstep=data['obj'].nextstep != undefined ? data['obj'].nextstep:null;
            this.templatesUser=data['obj'].user != undefined ? data['obj'].user:null;
            if (data['obj'] !=undefined){
              if (data['obj'].layout){
                this.templatesLayoutId=data['obj'].layout._id;
              }
            } else {
              this.templatesLayoutId=null
            }
           // this.templatesLayoutId=data['obj'] != undefined ? data['obj'].layout._id:null;
            this.templatesRedirect=data['obj'] != undefined ? data['obj'].redirect:null;
            this.templatesRedirect_url=data['obj'] != undefined ? data['obj'].redirect_url:null;
            this.templatesJavascript=data['obj'] != undefined ? data['obj'].javascript:null;
            this.templatesHead=data['obj'] != undefined ? data['obj'].head:null;
            
            this.initEditor();
            //this.getMyRecords();
            
          // this.getMyObjects(this.username,'user');
          // this.getMyObjects('defaults/hb/backgrounds','stock');
          }
        ) 
        
      
      },
      error => {
        localStorage.setItem('gjs-components','');
        localStorage.setItem('gjs-css','');
        localStorage.setItem('gjs-html','');
        localStorage.setItem('gjs-styles',''); 
        localStorage.setItem('gjsProject',''); 
        
        this.initEditor();
        //this.getMyRecords();
        
      //  this.getMyObjects(this.username,'user');
      //  this.getMyObjects('defaults/hb/backgrounds','stock');
      //  this.categories={_id:"1",name:"",categoriesId:"1",img:""};
    //   this._service.error(error.error['message'], error['title'])
        }
    )
  }
  /***End Prebuilt Functions***/
  

 options: any = {
    showPrintMargin: false,
    showInvisibles: true,
    highlightGutterLine: false,
    highlightActiveLine: true,
    fadeFoldWidgets: true,
    showLineNumbers: true,
    howGutter: true,
    fontSize: 12,
    liveAutoCompletion: true,
    wrap: true,
    theme: 'cobalt'
};
  
  url;
  thumbUrl;
  uploaded= false;
  sigingrequest;
  progress = false;
  public file_srcs: string[] = [];
  uploadedImage: File;
  imagePreview: string;
  editor;
  inputModal;
  //showLoader=true;
  templatesId;
  templatesType;
  templatesName;
  templatesRedirect;
  templatesRedirect_url;
  templatesWid = null;
  templatesDescription;
  templatesDomain;
  templatesSovrn_info;
  templatesHeadline;
  templatesDisplayOrder;
  templatesImage;
  templatesConfig;
  templatesCategoryId;
  templatesTypeId;
  templatesSubTypeId;
  templatesSubtypeId;
  templatesLayoutId;
  templatesJavascript="";
  templatesHead="";
  templatesCss="";
  templatesVersion;
  templatesNextstep;
  templatesParent;
  templatesUser;
  

  funnelId
  categories;
  types=[];
  subtypes;
  layouts;
  showGrapes=true;

  myBody={}
  myRecord={};

  typeArray=[];
  subTypeArray=[];
  categoryArray=[];
  layoutArray=[];
  enableDomainCheck=true;
  newBody;
  fileType;
  isMember:boolean=false;
  isSuper:boolean=false;
  isAdmin:boolean=false;
  isAffiliateOnly:boolean=false;
  runWizard:boolean = false;
  

  constructor(
    private commService:CommService, 
    private toastrService:ToastService,
    private uploadService: FileUploadService,
    private templatesService: TemplatesService,
    private route: ActivatedRoute, 
    private router: Router,
    private categoriesService: CategoriesService,
    private typesService: TypesService,
    private subtypesService: SubtypesService,
    public dialog: MatDialog,
    public domainService: DomainsService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<GrapesjsComponent>,
    private confirmService: ConfirmService
    
    ) { 

      this.confirmService.pressedConfirm.subscribe(
        data => {
          this.confirmPressed = data;
        })

      this.templatesService.templatesIsEdit.subscribe(
        templatesShort => {
       
        this.templatesId=templatesShort['_id'];

        });
        
      var userRecord=localStorage.getItem('userRecord')?localStorage.getItem('userRecord'):''

      this.domainService.getAllMyRecords()
          .subscribe (
            domainData => {
              if (domainData){
                
                if (domainData['obj']){
                  
                  for (var i = 0; i < domainData['obj'].length; i++) {
                 
                  this.listOfDomains.unshift({name:domainData['obj'][i].name, value:domainData['obj'][i].domain})
                  
                  
                 }
               }
              }
            }
          )
          this.authService.getUser()
        .subscribe(
            
            user => {
                if (user['obj'].roles.includes('super')){
                    this.isSuper = true;
                    this.isMember = true;
                }

                if (user['obj'].roles.includes('member')){
                    this.isMember = true;  
                }

                if (user['obj'].roles.includes('admin')){
                    this.isAdmin = true;
                    this.isMember = true;
                }

                if (user['obj'].roles.includes('affiliate_only')){
                    this.isAffiliateOnly = true;
                }
               
                if (this.isAdmin==true){
                  this.disableWid=false;
                }
            }
            
        );

        
    }

  checkDomain(event){

  }
    
  toggledrawer(){
    this.commService.sendMessage({'action':'toggleSideNav1', 'value':true});
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  async save(f:NgForm, model: any, isValid: boolean) {
    this.isLoading=false;
    this.myBody = {
      'gjs-html': localStorage.getItem('gjs-html'),
      'gjs-components':localStorage.getItem('gjs-components'),
      //'gjs-assets':localStorage.getItem('gjs-assets'),
      'gjs-css':localStorage.getItem('gjs-css'),
      'gjs-styles':localStorage.getItem('gjs-styles'),
      'gjsProject':localStorage.getItem('gjsProject')
    }

    
    var modelCategory = model.category=="noValue" ? null : model.category;
    var modelType = model.type=="noValue" ? null : model.type;
    
    var loadedHtml=this.editor.getHtml();
    var loadedCSS=this.editor.getCss();
    var loadedJS=this.editor.getJs();
    
    var html='<!doctype html><html lang="en"><head><meta charset="utf-8">'+this.templatesHead+'<style>'+this.templatesCss+css_beautify(loadedCSS)+'</style></head><body>';

    html=html+html_beautify(loadedHtml)+"</body>"+this.templatesJavascript;
    html=html+'<html>'
    
    var tmpWid;
    if (model.wid){
      tmpWid=model.wid;
    } else {
      tmpWid = Date.now() + Math.random(); 
    }
    

    this.myRecord = {
      name:model.name,
      body:this.b64EncodeUnicode(html),
      category: modelCategory,
      version: "2",
      type: modelType,
      subtype: model.subtype,
      description: model.description,
      displayorder: model.displayorder,
      domain: model.domain,
      headline: model.headline,
      image: model.image,
      created_source:'sovrn',
      redirect: model.redirect,
      redirect_url: model.redirect_url,
      sovrn_info: this.b64EncodeUnicode(JSON.stringify(this.myBody)),
      wid: tmpWid,
      javascript:this.templatesJavascript,
      head: this.templatesHead,
      css: loadedCSS,
      layout:model.layout=="null"?null:model.layout,
      nextstep:model.nextstep,
      config:model.config
      
    }

    console.log(this.myRecord)
    
    if (model.id != "new"){
     
      this.myRecord['templatesId']=model.id;
      this.templatesService.updateRecordV2(this.myRecord).subscribe(
        data=>{
          this.toastrService.success(data['message'],data['title'], {opacity:1});
          
        }, error => {
          
          this.toastrService.error(error['error'].error.errmsg,error['error'].title, {opacity:1});
          
        }
      )

    } else {
      
      

      this.templatesService.addRecordV2(this.myRecord).subscribe(
        data=>{
         
          this.templatesId=data['obj']._id;
          this.templatesName=data['obj'].name;
          this.toastrService.success(data['message'],data['title'], {opacity:1})
          
        }, error => {
          this.toastrService.error(error['error'],error['title'], {opacity:1});
          
        }
      )
    }

  }

  ngAfterViewInit(){
    
    if (this.isAdmin==true){
      this.disableWid=false;
    }
    
  }

  ngOnInit(): void {
    
    

    

    //this.templatesId=this.route.snapshot.params.templateid;
     // this.funnelId=this.route.snapshot.params.funnelid;
   
      if (this.templatesId=="new"){
        
        this.templatesService.getRecordV2(this.templatesId).subscribe(
          data => {
            
            var sovrnInfo, parseInfo;
           
            this.templatesWid=Date.now() + Math.random();
             
            this.initEditor();
            this.getMyRecords();
            
            this.getMyObjects(this.username,'user');
            this.getMyObjects('defaults/hb/backgrounds','stock');
            this.runWizard=true;
            this.startWizard();
           
          })
      } else {
        
        this.templatesService.getRecordV2(this.templatesId).subscribe(
          data => {
            var sovrnInfo, parseInfo;
           
            try {
              sovrnInfo = atob(data['obj'].sovrn_info)
            } catch(e){
              sovrnInfo = {};
            }
           
            try {
              parseInfo=JSON.parse(sovrnInfo);
              this.newBody=parseInfo;
              console.log(this.newBody)
             // localStorage.setItem('gjs-assets',this.newBody['gjs-assets']);
              localStorage.setItem('gjs-components',this.newBody['gjs-components']);
              localStorage.setItem('gjs-css',this.newBody['gjs-css']);
              localStorage.setItem('gjs-html',this.newBody['gjs-html']);
              localStorage.setItem('gjs-styles',this.newBody['gjs-styles']); 
              localStorage.setItem('gjsProject',this.newBody['gjsProject']); 
            } catch(e){
              parseInfo='';
            }
          
            
            this.templatesName=data['obj'].name;
            this.templatesWid=data['obj'].wid;
            this.templatesDomain=data['obj'].domain;
            this.templatesVersion=data['obj'].version != undefined ? data['obj'].version:null;
            this.templatesDescription=data['obj'].description;
            this.templatesDisplayOrder=data['obj'].displayorder;
            this.templatesImage=data['obj'].image;
            this.templatesConfig=data['obj'].config;
            this.templatesParent=data['obj'].parent;
            this.templatesCategoryId=data['obj'].category != undefined ? data['obj'].category._id:null;
            this.templatesTypeId=data['obj'].type != undefined ? data['obj'].type._id:null;
            this.templatesSubtypeId=data['obj'].subtype != undefined ? data['obj'].subtype._id:null;
            this.templatesLayoutId=data['obj'].layoutid != undefined ? data['obj'].layout._id:null;
            this.templatesRedirect=data['obj'].redirect;
            this.templatesRedirect_url=data['obj'].redirect_url;
            this.templatesJavascript=data['obj'].javascript;
            this.templatesHead=data['obj'].head;
            this.templatesCss=data['obj'].css;
            this.templatesNextstep=data['obj'].nextstep != undefined ? data['obj'].nextstep:null;
            this.templatesLayoutId=data['obj'].parent != undefined ? data['obj'].parent._id:null;
            this.templatesUser=data['obj'].user != undefined ? data['obj'].user:null;
            
            this.initEditor();
            this.getMyRecords();
            
            this.getMyObjects(this.username,'user');
            this.getMyObjects('defaults/hb/backgrounds','stock');
          
          },
          error => {
            localStorage.setItem('gjs-components','');
            localStorage.setItem('gjs-css','');
            localStorage.setItem('gjs-html','');
            localStorage.setItem('gjs-styles',''); 
           
            this.initEditor();
            this.getMyRecords();
            
            this.getMyObjects(this.username,'user');
            this.getMyObjects('defaults/hb/backgrounds','stock');
          //  this.categories={_id:"1",name:"",categoriesId:"1",img:""};
        //   this._service.error(error.error['message'], error['title'])
            }
        )
      }
    
    
  }

  initEditor(){
   
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    this.editor = grapesjs.init({
      colorPicker: { appendTo: 'parent', offset: { top: 26, left: -156, }, },
      allowScripts: 1,
      avoidInlineStyle: true,
      noticeOnUnload: true,
      showOffsets: true,
      showOffsetsSelected: false,
      fromElement: true,
      baseCss:'',
      protectedCss:'',
      canvasCss: '',
      height: '100vh',
      //dragMode:  'translate','absolute'
      dragMode:"",
      mediaCondition: 'min-width',
      container: '#gjs',
      storageManager: {
        id: 'gjs-',             // Prefix identifier that will be used on parameters
       // type: 'local',          // Type of the storage
        autosave: true,         // Store data automatically
        autoload: true,         // Autoload stored data on init
       // stepsBeforeSave: 1,     // If autosave enabled, indicates how many changes are necessary before store method is triggered
        type: 'local',
        stepsBeforeSave: 1,
        urlStore: '/api2/templates/sovren/store/some-id-123',
        urlLoad: '/api2/templates/sovren/load/some-id-123',
        // For custom parameters/headers on requests
        params: { name: this.templatesName },
        headers: { Authorization: token },
      },
      commands: {

      },
      toolbar: {
        
      },
      richTextEditor: {
        // options
        
      },
      
      selectorManager: { componentFirst: true },
      styleManager: {
        sectors: [{
            name: 'General',
            properties:[
              {
                extend: 'float',
                type: 'radio',
                default: 'none',
                options: [
                  { value: 'none', className: 'fa fa-times'},
                  { value: 'left', className: 'fa fa-align-left'},
                  { value: 'right', className: 'fa fa-align-right'}
                ],
              },
              'display',
              { extend: 'position', type: 'select' },
              'top',
              'right',
              'left',
              'bottom',
            ],
          }, {
              name: 'Dimension',
              open: false,
              properties: [
                'width',
                {
                  id: 'flex-width',
                  type: 'integer',
                  name: 'Width',
                  units: ['px', '%'],
                  property: 'flex-basis',
                  toRequire: 1,
                },
                'height',
                'max-width',
                'min-height',
                'margin',
                'padding'
              ],
            },{
              name: 'Typography',
              open: false,
              properties: [
                  'font-family',
                  'font-size',
                  'font-weight',
                  'letter-spacing',
                  'color',
                  'line-height',
                  {
                    extend: 'text-align',
                    options: [
                      { id : 'left',  label : 'Left',    className: 'fa fa-align-left'},
                      { id : 'center',  label : 'Center',  className: 'fa fa-align-center' },
                      { id : 'right',   label : 'Right',   className: 'fa fa-align-right'},
                      { id : 'justify', label : 'Justify',   className: 'fa fa-align-justify'}
                    ],
                  },
                  {
                    property: 'text-decoration',
                    type: 'radio',
                    default: 'none',
                    options: [
                      { id: 'none', label: 'None', className: 'fa fa-times'},
                      { id: 'underline', label: 'underline', className: 'fa fa-underline' },
                      { id: 'line-through', label: 'Line-through', className: 'fa fa-strikethrough'}
                    ],
                  },
                  'text-shadow'
              ],
            },{
              name: 'Decorations',
              open: false,
              properties: [
                'opacity',
                'border-radius',
                'border',
                'box-shadow',
                'background', // { id: 'background-bg', property: 'background', type: 'bg' }
              ],
            },{
              name: 'Extra',
              open: false,
              buildProps: [
                'transition',
                'perspective',
                'transform'
              ],
            },{
              name: 'Flex',
              open: false,
              properties: [{
                name: 'Flex Container',
                property: 'display',
                type: 'select',
                defaults: 'block',
                list: [
                  { value: 'block', name: 'Disable'},
                  { value: 'flex', name: 'Enable'}
                ],
              },{
                name: 'Flex Parent',
                property: 'label-parent-flex',
                type: 'integer',
              },{
                name: 'Direction',
                property: 'flex-direction',
                type: 'radio',
                defaults: 'row',
                list: [{
                  value: 'row',
                  name: 'Row',
                  className: 'icons-flex icon-dir-row',
                  title: 'Row',
                },{
                  value: 'row-reverse',
                  name: 'Row reverse',
                  className: 'icons-flex icon-dir-row-rev',
                  title: 'Row reverse',
                },{
                  value: 'column',
                  name: 'Column',
                  title: 'Column',
                  className: 'icons-flex icon-dir-col',
                },{
                  value: 'column-reverse',
                  name: 'Column reverse',
                  title: 'Column reverse',
                  className: 'icons-flex icon-dir-col-rev',
                }],
              },{
                name: 'Justify',
                property: 'justify-content',
                type: 'radio',
                defaults: 'flex-start',
                list: [{
                  value: 'flex-start',
                  className: 'icons-flex icon-just-start',
                  title: 'Start',
                },{
                  value: 'flex-end',
                  title: 'End',
                  className: 'icons-flex icon-just-end',
                },{
                  value: 'space-between',
                  title: 'Space between',
                  className: 'icons-flex icon-just-sp-bet',
                },{
                  value: 'space-around',
                  title: 'Space around',
                  className: 'icons-flex icon-just-sp-ar',
                },{
                  value: 'center',
                  title: 'Center',
                  className: 'icons-flex icon-just-sp-cent',
                }],
              },{
                name: 'Align',
                property: 'align-items',
                type: 'radio',
                defaults: 'center',
                list: [{
                  value: 'flex-start',
                  title: 'Start',
                  className: 'icons-flex icon-al-start',
                },{
                  value: 'flex-end',
                  title: 'End',
                  className: 'icons-flex icon-al-end',
                },{
                  value: 'stretch',
                  title: 'Stretch',
                  className: 'icons-flex icon-al-str',
                },{
                  value: 'center',
                  title: 'Center',
                  className: 'icons-flex icon-al-center',
                }],
              },{
                name: 'Flex Children',
                property: 'label-parent-flex',
                type: 'integer',
              },{
                name: 'Order',
                property: 'order',
                type: 'integer',
                defaults: 0,
                min: 0
              },{
                name: 'Flex',
                property: 'flex',
                type: 'composite',
                properties  : [{
                  name: 'Grow',
                  property: 'flex-grow',
                  type: 'integer',
                  defaults: 0,
                  min: 0
                },{
                  name: 'Shrink',
                  property: 'flex-shrink',
                  type: 'integer',
                  defaults: 0,
                  min: 0
                },{
                  name: 'Basis',
                  property: 'flex-basis',
                  type: 'integer',
                  units: ['px','%',''],
                  unit: '',
                  defaults: 'auto',
                }],
              },{
                name: 'Align',
                property: 'align-self',
                type: 'radio',
                defaults: 'auto',
                list: [{
                  value: 'auto',
                  name: 'Auto',
                },{
                  value: 'flex-start',
                  title: 'Start',
                  className: 'icons-flex icon-al-start',
                },{
                  value   : 'flex-end',
                  title: 'End',
                  className: 'icons-flex icon-al-end',
                },{
                  value   : 'stretch',
                  title: 'Stretch',
                  className: 'icons-flex icon-al-str',
                },{
                  value   : 'center',
                  title: 'Center',
                  className: 'icons-flex icon-al-center',
                }],
              }]
            }
          ]},
      layerManager: {
       // appendTo: '.layers-container'
      },
      plugins: [
        'grapesjs-preset-webpage',
       'grapesjs-plugin-export',
       'grapesjs-component-countdown',
        'grapesjs-tabs',
        'grapesjs-tui-image-editor',
        'grapesjs-custom-code',
        'grapesjs-plugin-toolbox',
        'grapesjs-rulers',
       'grapesjs-plugin-asset-manager-v2',
       'grapesjs-style-bg',
       'gjs-blocks-basic',
       'grapesjs-plugin-forms',
       'grapesjs-touch',
       'grapesjs-parser-postcss',
       'grapesjs-tooltip',
       'grapesjs-typed',
       '@silexlabs/grapesjs-fonts',
       "grapesjs-table"
       
      ],
      pluginsOpts: {
        
        '@silexlabs/grapesjs-fonts': {
          api_key: 'AIzaSyA5iN5b-_bUIurFfwYRxbr3-_T-TAB7kZs',
        },
        'gjs-blocks-basic': { flexGrid: true },
        'grapesjs-tui-image-editor': {
          config: {
            includeUI: {
              initMenu: 'filter',
            },
          },
          icons: {
            'menu.normalIcon.path': '../icon-d.svg',
            'menu.activeIcon.path': '../icon-b.svg',
            'menu.disabledIcon.path': '../icon-a.svg',
            'menu.hoverIcon.path': '../icon-c.svg',
            'submenu.normalIcon.path': '../icon-d.svg',
            'submenu.activeIcon.path': '../icon-c.svg',
          },
          script: [
            // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
            'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js',
            'https://unpkg.com/@silexlabs/grapesjs-fonts'
          ],
          style: [
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
          ],
        },
        'grapesjs-typed': {
          block: {
            category: 'Extra',
            content: {
              type: 'typed',
              'type-speed': 40,
              strings: [
                'Text row one',
                'Text row two',
                'Text row three',
              ],
            }
          }
        },
        'grapesjs-custom-code': {
          codeBlock: {
            category: 'Extra'
          }
        },
        'grapesjs-lory-slider': {
          sliderBlock: {
            category: 'Extra'
          }
        },
        'grapesjs-tabs':{
          tabsBlock: {category: 'Extra'}
        },
        'gjs-preset-webpage': {
          modalImportTitle: 'Edit or Import Template',
          modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste new HTML/CSS here or make changes to existing. Then click Import</div>',
          modalImportContent: function(editor) {
            return editor.getHtml() + '<style>'+editor.getCss()+'</style>'
          },
          filestackOpts: null, //{ key: 'AYmqZc2e8RLGLE7TGkX3Hz' },
          aviaryOpts: false,
          blocksBasicOpts: { flexGrid: 1 }
        },
        
          
      },
      assetManager: {
        storageType  	: '',
        mbedAsBase64: 1,
        autoAdd:1,
       // dropzone:1,
      //  dropzoneContent: '<div class="dropzone-inner">Drop here your assets</div>',
        storeOnChange  : true,
        storeAfterUpload  : true,
        upload: 'https://localhost/assets/upload',        //for temporary storage
        uploadFile: (e) => {
          
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          
          this.uploadedImage=files[0];
          this.upload();
          var formData = new FormData();
          for(var i in files){
                  formData.append('file-'+i, files[i]) //containing all the selected images from local
          }
        }
      },
      panels: {
        id: 'basic-actions',
        el: '.panel__basic-actions',
        buttons: [
          {
            id: 'alert-button',
            className: 'btn-alert-button',
            label: 'Click my butt(on)',
            command(editor) { alert('Hello World'); }
          },
          {
            attributes: { title: 'Open Code' },
            className: 'fa fa-code',
            command: 'open-code',
            id: 'open-code'
          }
        ],
        defaults: [
          {
            buttons: [
              {
                attributes: { title: 'Open Code' },
                className: 'fa fa-code',
                command: 'open-code',
                id: 'open-code'
              }
            ],
            id: 'views'
          },
          {
            id: 'layers',
            el: '.panel__right',
            // Make the panel resizable
            resizable: {
                maxDim: 350,
                minDim: 200,
                tc: 0, // Top handler
                cl: 1, // Left handler
                cr: 0, // Right handler
                bc: 0, // Bottom handler
                // Being a flex child we need to change `flex-basis` property
                // instead of the `width` (default)
                keyWidth: 'flex-basis',
            }
          },
          
        ]
      }
      
    });

    const rteEl = this.editor.RichTextEditor.getToolbarEl();
  //By default there are 16 buttons, index them from 0 to 15
  //og position [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  //             |  |  |  |  |  |   \  \  \   \   \   \   \  \  \   |  Move to position in second array
  //const order = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13,14,15,16,17,18,19];
  //rteEl.firstChild.childNodes.forEach((child, idx) => child.style.order = order[idx]);
    
  
    this.editor.getConfig().showDevices = 0;
    
    this.editor.Panels.addPanel({ id: "devices-c", className: "myheight" }).get("buttons").add([
        { id: "return-editor", command: (e)=>{ this.goReturn() }, className: "fa fa-chevron-left", label:" Close" },
        //{ id: "toggle-editor", command: (e)=>{ this.toggledrawer() }, className: "fa fa-toggle-on", label:"Toggle" },
        { id: "set-device-desktop", command: function(e) { return e.setDevice("Desktop") }, className: "fa fa-desktop", active: 1 },
        { id: "set-device-tablet", command: function(e) { return e.setDevice("Tablet") }, className: "fa fa-tablet" },
        { id: "set-device-mobile", command: function(e) { return e.setDevice("Mobile portrait") }, className: "fa fa-mobile" },
        { id: "funnelinfo-editor", command: (e)=>{ this.onOpenAddModal() }, className: "fa fa-info", "label":"  Config" },
        { id: "html-editor", command: (e)=>{ this.onOpenHtmlModal() }, className: "fa fa-code", "label":"  Code" },
        {
          id: 'show-json',
          className: 'fa fa-cube',
          context: 'show-json',
          command(editor) {
              editor.Modal.setTitle('Components JSON')
              .setContent(`<textarea style="width:100%; height: 250px;">
                  ${JSON.stringify(editor.getComponents())}
              </textarea>`)
              .open();
          },
          "label":" JSON"
        },
        { id: "save-editor", command: (e)=>{ this.onSubmitForm() }, className: "fa fa-floppy-o", label:" Save" },
        { id: "media-manager", command: (e)=>{ this.onOpenMediaModal() }, className: "fa fa-image", label:" Media" },
        { id: "prebuilt", command: (e)=>{ this.onOpenPrebuiltModal() }, className: "fa fa-file", label:"  Templates" },
        { id: "myfont", command: (e)=>{ this.editor.runCommand('open-fonts') }, className: "fa fa-bars", label:" Fonts" },
        { id: "preview", command: (e)=>{ this.preview() }, className: "fa fa-eye", label:" Preview" },
        { id: "cgbg", command: (e)=>{ this.onOpenBackgroundChooserModal() }, className: "fa fa-file-image", label:" Background" }
        
    ]); 

    this.editor.on('rteToolbarPosUpdate', (pos) => {
      
    /*  if (pos.top <= 0 && pos.top > -8) {
          let toolbarDiv = this.editor.RichTextEditor.getToolbarEl()
          let toolbarHeight = toolbarDiv.scrollHeight
          let rect = this.editor.Canvas.getRect()
          pos.top = (rect.height - toolbarHeight) + (pos.top - pos.canvasOffsetTop)
      } */
  }); 

    //rulers
    this.editor.Panels.addPanel({ id: "views" }).get('buttons').add([{
      attributes: {
        title: 'Toggle Rulers'
      },
      context: 'toggle-rulers', //prevents rulers from being toggled when another views-panel button is clicked 
      label: `<svg width="18" viewBox="0 0 16 16"><path d="M0 8a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5A.5.5 0 0 1 0 8z"/><path d="M4 3h8a1 1 0 0 1 1 1v2.5h1V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v2.5h1V4a1 1 0 0 1 1-1zM3 9.5H2V12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V9.5h-1V12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5z"/></svg>`,
      command: 'ruler-visibility',
      id: 'ruler-visibility'
    }]);

    //disable ruler on preview
    this.editor.on('run:preview', () => this.editor.stopCommand('ruler-visibility'));
    this.editor.Panels.addPanel({ id: "views" }).get("buttons").add([
      { id: "autoresponders", command: (e)=>{ this.onOpenAutoresponderChooserModal() }, className: "fa fa-envelope", attributes: {title: 'Settings'}, togglable: 0 }
    ]); 

    const swv = 'sw-visibility';
    const expt = 'export-template';
    const osm = 'open-sm';
    const otm = 'open-tm';
    const ola = 'open-layers';
    const obl = 'open-blocks';
    const ful = 'fullscreen';
    const prv = 'preview';

    const rte = this.editor.RichTextEditor;
    const opts = {};
    const formatBlock = 'formatBlock';
    
    const options = {
      ...{
          // default options
          base: {
              bold: true,
              italic: true,
              underline: true,
              strikethrough: true,
              link: true,
          },
          //fonts: {
          //  fontName: ['font1',...,'fontn'],
          //  fontSize: true,
          //  //An array of strings representing colors
          //  fontColor: [],
          //  //An array of strings representing colors
          //  hilite: [],
          //}
          fonts: {
              fontColor: true,
              hilite: true,
              fontName: ['verdana','georgia ']
          },
          format: {
              heading1: true,
              heading2: true,
              heading3: true,
              //heading4: false,
              //heading5: false,
              //heading6: false,
              paragraph: true,
              //quote: false,
              clearFormatting: true,
          },
          subscriptSuperscript: false,
          indentOutdent: false,
          list: false,
          align: true,
          //actions: {
          //  copy: true,
          //  cut: true,
          //  paste: true,
          //  delete: true,
          //},
          actions: false,
          undoredo: false,
          extra: false,
          icons: {
            fontColor:true,
            hiliteColor:true
          },
          darkColorPicker: true
      },
      ...opts
  };

  console.log(options.fonts.fontName)

  const { icons } = options;

    
  rte.add('paragraph', {
    icon: '&#182;',
    attributes: {
        title: 'Paragraph'
    },
    result: rte => rte.exec(formatBlock, '<p>')
  });

      rte.add('quote', {
        icon: '<i class="fa fa-quote-left"></i>',
        attributes: {
            title: 'Quote'
        },
        result: rte => rte.exec(formatBlock, '<blockquote>')
      });

      

      let pk1 = null;
    options.fonts && options.fonts.fontColor && rte.add('fontColor', {
        icon: `<b style="pointer-events:none;border-bottom:2px solid">A</b>
      <div id="foreColor-picker"
          class="${options.darkColorPicker ? 'rte-color-picker dark' : 'rte-color-picker light'}">
      </div>`,
        attributes: {
            id: 'rte-font-color',
            title: 'Font Color'
        },
        result: rte => {
            if (!pk1) pk1 = new Piklor("#foreColor-picker", options.fonts.fontColor ?
                (Array.isArray(options.fonts.fontColor) ? options.fonts.fontColor : null) : null, {
                open: "span#rte-font-color.gjs-rte-action",
                closeOnBlur: true
            });
            pk1.colorChosen(col => rte.exec('foreColor', col));
        },
    });

    let pk2 = null;
    options.fonts && options.fonts.hilite && rte.add('hiliteColor', {
        icon: `<b style="pointer-events:none;" class="rte-hilite-btn">A</b>
      <div id="hilite-picker"
        class="${options.darkColorPicker ? 'rte-color-picker dark' : 'rte-color-picker light'}">
      </div>`,
        attributes: {
            id: 'rte-font-hilite',
            title: 'Font Highlight'
        },
        result: rte => {
            if (!pk2) pk2 = new Piklor("#hilite-picker", options.fonts.hilite ?
                (Array.isArray(options.fonts.hilite) ? options.fonts.hilite : null) : null, {
                open: "span#rte-font-hilite.gjs-rte-action",
                closeOnBlur: true
            });
            pk2.colorChosen(col => rte.exec('hiliteColor', col));
        },
    });

    rte.add('indent', {
      icon: '<i class="fa fa-indent"></i>',
      attributes: {
          title: 'Indent'
      },
      result: rte => rte.exec('indent')
    });
    rte.add('outdent', {
        icon: '<i class="fa fa-outdent"></i>',
        attributes: {
            title: 'Outdent'
        },
        result: rte => rte.exec('outdent')
    });
    rte.add('subscript', {
        icon: '<div>X<sub>2</sub></div>',
        attributes: {
            title: 'Subscript'
        },
        result: rte => rte.exec('subscript')
    });
    rte.add('superscript', {
        icon: '<div>X<sup>2</sup></div>',
        attributes: {
            title: 'Superscript'
        },
        result: rte => rte.exec('superscript')
    });

    rte.add('olist', {
      icon: '<i class="fa fa-list-ol"></i>',
      attributes: {
          title: 'Ordered List'
      },
      result: rte => rte.exec('insertOrderedList')
    });
    rte.add('ulist', {
        icon: '<i class="fa fa-list-ul"></i>',
        attributes: {
            title: 'Unordered List'
        },
        result: rte => rte.exec('insertUnorderedList')
    });

    rte.add('line', {
      icon: '<b>&#8213;</b>',
      attributes: {
          title: 'Horizontal Line'
      },
      result: rte => rte.exec('insertHorizontalRule')
  });
  rte.add('undo', {
      icon: '<i class="fa fa-reply"></i>',
      attributes: {
          title: 'Undo'
      },
      result: rte => rte.exec('undo')
  });
  rte.add('redo', {
      icon: '<i class="fa fa-share"></i>',
      attributes: {
          title: 'Redo'
      },
      result: rte => rte.exec('redo')
  });

  rte.add('heading1', {
    icon: '<div>H1</div>',
    attributes: {
        title: 'Heading 1'
    },
    result: rte => rte.exec(formatBlock, '<h1>')
});
rte.add('heading2', {
    icon: '<div>H2</div>',
    attributes: {
        title: 'Heading 2'
    },
    result: rte => rte.exec(formatBlock, '<h2>')
});
rte.add('heading3', {
    icon: '<div>H3</div>',
    attributes: {
        title: 'Heading 3'
    },
    result: rte => rte.exec(formatBlock, '<h3>')
});
rte.add('heading4', {
    icon:  '<div>H4</div>',
    attributes: {
        title: 'Heading 4'
    },
    result: rte => rte.exec(formatBlock, '<h4>')
});
rte.add('heading5', {
    icon: '<div>H5</div>',
    attributes: {
        title: 'Heading 5'
    },
    result: rte => rte.exec(formatBlock, '<h5>')
});

  rte.add('fontSize', {
    icon: `<select style="height:1.4rem;color:#000 !important;background-color:#fff !important;width:40px !important" class="gjs-field gjs-field-select">
        
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="7">7</option>
        </select>`,
      // Bind the 'result' on 'change' listener
    event: 'change',
    result: (rte, action) => rte.exec('fontSize', action.btn.firstChild.value),
    // Callback on any input change (mousedown, keydown, etc..)
    update: (rte, action) => {
      const value = rte.doc.queryCommandValue(action.name);
      if (value != 'false') { // value is a string
        action.btn.firstChild.value = value;
      }
     }
    })

    

    const fontNames = options.fonts.fontName ?
        (Array.isArray(options.fonts.fontName) ? options.fonts.fontName : false) : false;

      //options.fonts.fontName = fontNames;
      let fontOptionsEl = '';
      fontNames && fontNames.forEach(font => {
          fontOptionsEl += '<option>' + font.toString() + '</option>'
      });

    const fontNamesEl = `<select style="height:1.4rem;color:#000 !important;background-color:#fff !important;width:90px !important;margin-left:60px !important" class="gjs-field gjs-field-select">
      ${fontOptionsEl}
    </select>`;

      rte.add('fontName', {
        icon: fontNamesEl,
        // Bind the 'result' on 'change' listener
        event: 'change',
        attributes: {
            style: "padding: 0 4px 2px;",
            title: 'Font Name'
        },
        result: (rte, action) => rte.exec('fontName', action.btn.firstChild.value),
        // Callback on any input change (mousedown, keydown, etc..)
        update: (rte, action) => {
            const value = rte.doc.queryCommandValue(action.name);
            if (value != 'false') { // value is a string
                action.btn.firstChild.value = value;
            }
        }
      });

      
    

    

    

    //this.editor.Panels.addPanel({
    //  id: 'panel-top',
   //   el: '.panel__top',
   //   });

     
    
    this.inputModal = this.editor.modal;

   

    const bm = this.editor.BlockManager;

  bm.add('Divider', {
    label: 'Divider',
    media: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <rect x="2.3" y="10.2" class="st0" width="18.6" height="3.5"/>
    </svg>
 `,
    content: '<hr></hr>',
    category: "Extra",
  });

  bm.add('Unordered List', {
    label: 'Unordered List',
    media: `<?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 27.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
    <rect x="4.5" y="2.7" width="18.6" height="3.5"/>
    <rect x="4.5" y="6.9" width="18.6" height="3.5"/>
    <rect x="4.5" y="11.1" width="18.6" height="3.5"/>
    <rect x="4.5" y="15.3" width="18.6" height="3.5"/>
    <rect x="4.5" y="19.6" width="18.6" height="3.5"/>
    <circle cx="2.2" cy="4.5" r="1.8"/>
    <circle cx="2.2" cy="8.6" r="1.8"/>
    <circle cx="2.2" cy="12.8" r="1.8"/>
    <circle cx="2.2" cy="17" r="1.8"/>
    <circle cx="2.2" cy="21.4" r="1.8"/>
    </svg>
    
 `,
    content: '<ul><li class="el-Y el-A"></li><li class="el-Y el-B"></li></ul>',
    category: "Extra",
  });

  
    var assettarget ='';
    var m=this.editor.AssetManager
    
    

    this.editor.Commands.add('open-assets', (editor, sender, opts) => {
      
     this.drawer.open()
    
    });
  
    
    this.editor.on('component:selected', (e,result) => {
    
      this.selectedComponent=e;
     }) 
  }
  
  getMyObjects(path, category){
    const am = this.editor.AssetManager;
   
    var body = {path:path};
    localStorage.removeItem('gjs-assets');
    this.uploadService.getMyObjects(body).subscribe(
      data => {
       
        var addData=[];
        var galleryData=[]
       
        for (let i=0;i<data['imageInfo'].length; i++){
          var feed={'category':category,'src':data['imageInfo'][i].url}
          var galleryFeed={img:data['imageInfo'][i].url, thumb:data['imageInfo'][i].url,description:category,lastmodified:data['imageInfo'][i].lastModified,size:data['imageInfo'][i].size}
          grapesjs
          addData.push(feed);
          galleryData.push(galleryFeed);
         
        }
       
        am.add(addData)
        am.render();
      }
    )
  }

  preview(){
    window.open('/'+this.templatesWid,'preview')
  }

  getMyRecords(){
   
    var categoriesRole={role:'user'};
    this.categoriesService.getAllRecordsByRole(categoriesRole)
      .subscribe(
        data => {
          this.categories = data['obj'];
        },
        error => {
       
        },
      );

      var typesRole={role:'user'};
      this.types=[];
      this.typesService.getAllRecordsByRole(typesRole)
      .subscribe(
        data1 => {
          console.log(data1)
          for (var i=0; i<data1['obj'].length; i++){
            console.log(data1['obj'][i])
            this.types.push(data1['obj'][i]);
          }
          
        },
        error => {
        
        },
      );

     

      if (this.isSuper==true){
        
        var typesRole={role:'super'};
        this.typesService.getAllRecordsByRole(typesRole)
        .subscribe(
          data1 => {
            
            for (var i=0; i<data1['obj'].length; i++){
              this.types.push(data1['obj'][i]);
            }
          
            
          },
          error => {
       
          },
        );
      }

     
      
      var subTypesRole={role:'user'};
      this.subtypesService.getAllRecordsByRole(subTypesRole)
      .subscribe(
        data2 => {
          
          this.subtypes = data2['obj'];
        },
        error => {
       
        },
      );

      this.templatesService.getLayouts('layout_file')
      .subscribe(
        data3 => {
          this.layouts = data3['obj'];
        
        },
        error => {
         
        } 
      ); 
  }

  upload(){
    this.progress=true;
   
    var fileType="image/jpeg";
   
    var file = this.uploadedImage;
    this.fileType=file.type;
    this.fileType=file.type;
    let fileName = Date.now() + Math.random() + "." + this.randomNumber() + "." + file.name;
    this.uploadService.getSignedRequest(fileName, fileType)
      .subscribe(
        data => {
          this.sigingrequest=data;
          this.finalUpload(this.sigingrequest, file, this.fileType)
        },
        error => this.toastrService.error(error.error.message, error.title, {opacity:1}),
      );
  }

  randomNumber(){
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  finalUpload(data, file, fileType){
    this.uploadService.upload(data.signedRequest, file, fileType)
      .subscribe(
        data => {
          if (data['status']==200){
            this.progress=false;
            this.uploaded=true;
            this.getMyObjects(this.username,'user');
            this.getMyObjects('defaults/hb/backgrounds','stock');
            this.uploadService.addRecord(data)
              .subscribe(
                data => {
                  this.toastrService.success(data['title'],data['message'],{opacity:1})
                }
              );
              
          }
        },
        error => {
          console.log(error)
          this.toastrService.error(error.title,error.message,{opacity:1})
        },
      );
  }

}
