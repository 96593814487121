import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { TemplatesService } from '../templates/templates.service';
import { DomSanitizer } from '@angular/platform-browser';

import {NewsService} from "../shared/news/news.service";
import {Observable} from 'rxjs';
import { async } from 'q';
import { interval } from 'rxjs';
import { Systempreference } from '../shared/systempreference/systempreference';
import { SystempreferenceService } from '../shared/systempreference/systempreference.service';
import { MenusService } from '../menus/menus.service';

@Component({
  selector: 'app-ipage',
  templateUrl: './ipage.component.html',
  styleUrls: ['./ipage.component.css']
})
export class IpageComponent implements OnInit {
  page;
  myVar;
  sub;
  data;
  frameSrc;
  myquery;
  newsHtml;
  showNews=false;
  index;
  newsArray: any[]=[];
  menu; 
  newsInterval=30000;

  constructor(private menusService: MenusService, private systempreferenceService: SystempreferenceService,private newsService: NewsService, private route: ActivatedRoute, private _service: ToastService, private templatesService:TemplatesService, private sanitizer: DomSanitizer) { 

    const systempreference = new Systempreference(null, document.location.hostname, null, null);
    /* this.systempreferenceService.getKey(systempreference)
      .subscribe(
        systemPref => {
          console.log(systemPref['obj'].value);
          var myJson = JSON.parse(systemPref['obj'].value);
          this.newsInterval=myJson.news_interval;
          if (myJson.news_active=='1'){
            this.getNews("firstnews");
            let count = 0;
            setInterval(() => {
              this.getNews("secondnews")
                  if(count === this.newsArray.length ){
                    count = 0;
                  }
                  this.newsHtml =  this.newsArray[count].body;
                  count++
              },this.newsInterval)   
          } 
        },
        error => {
          this._service.error(error.error['message'], error['title']);
        }
      ); */
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.page = params['page'];
      this.myquery = params['myparams'];
      var formattedParams=this.route.snapshot.queryParams;
       console.log('myparams',formattedParams);
       var queryString1 = "&"+Object.keys(formattedParams).map(key => key + '=' + formattedParams[key]).join('&');
       console.log(queryString1);
      this.templatesService.getRecord(this.page)
      .subscribe(
        data => {
          console.log("data obj=",data['obj']._id);
          var querySep="?";
          const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
          var queryString;
            if (this.myquery){
               queryString=this.myquery.replace("?", "&");this.myquery;
            }else {
               queryString='';
            }
            
            console.log("queryString = ",token+queryString);
            this.frameSrc=this.sanitizer.bypassSecurityTrustResourceUrl('/api/templates/html/'+data['obj']._id+token+queryString+queryString1);
          console.log('/api/templates/html/'+this.page+token)
           this.myVar = data['obj'].body;
        },
        error => this._service.error(error.error['message'], error['title']),
      );
    }); 
  }

  getNews(start){
    this.newsService.getNewsTicker()
    .subscribe(
      data => {
        if (data['status']=="success") {
          this.newsArray=data['obj'];
          if (start=="firstnews"){
            this.newsHtml=data['obj'][0].body;
          }
          this.showNews = true;
        } else {
          this.showNews = false
          this.newsArray=data['obj'];
        }
      },
      error => this._service.error(error['message'], error['title']),

    ) 
   
  }

  ngAfterViewInit() { }


}
